import React from 'react';
import cn from 'classnames';
import '@/styles/common/Portfolio/ImagePage/layout_select.scss';

type Props = {
  type: "a" | "b" | "c" | "d";
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  selected: boolean;
};

export function LayoutSelect(props: Props): React.ReactElement {
  return (
    <section className={cn("mbx-layout_select", {["mbx-layout_select-selected"]:props.selected})}>
      <button onClick={props.onClick}>
        <img src={`/images/image-layout-${props.type}.png`} alt=""/>
        <img src={`/images/image-layout-${props.type}-active.png`} alt=""/>
      </button>
    </section>
  );
}
