import React, { useState, useContext } from 'react';
import { AxiosError } from 'axios';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '@/redux/state';
import * as Page from '@/components/common/Page';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import { useForm, FormProvider } from 'react-hook-form';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { FormLabel } from '@/components/common/Form/Label';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import BaseButton from '@/components/common/Button/BaseButton';
import PasswordForm from '@/components/common/Account/PasswordForm';
import { toggleLoading, notificationSuccess, notificationError } from '@/redux/index';
import {
  UserApi,
  PasswordResetParam,
  RequestTokenCheck,
  RequestPassword,
  ResponseError,
} from '@/utils/api-client';
import '@/styles/pages/Account/PasswordChange.scss';
import { useEffect } from 'react';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
type IFormInputs = {
  email: string;
};
type Flow = {
  step1: boolean;
  step2: boolean;
  step3: boolean;
};

type IPasswords = {
  password: string;
  passwordAgain: string;
};

function PasswordChange(): React.ReactElement {
  const methods = useForm<IFormInputs>({
    defaultValues: {
      email: '',
    },
  });
  const defaultFlow = {
    step1: false,
    step2: false,
    step3: false,
  };
  const { handleSubmit, watch } = methods;
  const [pending, setPending] = useState<boolean>(false);
  const [flow, setFlow] = useState<Flow>(defaultFlow);
  const [parameter, setParameter] = useState<string>('');
  const [password, setPassword] = useState<IPasswords>({
    password: '',
    passwordAgain: '',
  });
  const dispatch = useDispatch();
  const loading = useSelector((state: State) => state.loading);
  const history = useHistory();
  const { push } = useContext(DataLayerContext);
  useEffect(() => {
    (async () => {
      const parameter = window.location.search.substring(1);
      if (!parameter) {
        setFlow({
          step1: true,
          step2: false,
          step3: false,
        });
        return;
      }
      setPending(true);
      setParameter(parameter);
      const token: RequestTokenCheck = {
        token: parameter,
      };
      try {
        await new UserApi().postApiV2ApiUserPasswordResetValidateToken(token);
        setFlow({
          step1: false,
          step2: false,
          step3: true,
        });
        setPending(false);
      } catch (error) {
        const e = error as AxiosError<ResponseError>;
        if (e.response) dispatch(notificationError(e.response.data.error_message));
      } finally {
        //
      }
    })();
  }, []);

  const reSetting = async () => {
    if (loading) return;
    dispatch(toggleLoading(true));
    const param: PasswordResetParam = {
      email: watch('email'),
    };
    try {
      await new UserApi().postUserPasswordReset(param);
      setFlow({
        step1: false,
        step2: true,
        step3: false,
      });
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const onPasswordReset = async () => {
    if (loading) return;
    dispatch(toggleLoading(true));
    const data: RequestPassword = {
      token: parameter,
      password: password.password,
      re_password: password.passwordAgain,
    };

    try {
      await new UserApi().postApiV2ApiUserPasswordResetPassword(data);
      push({
        event: 'pwSetting',
        actionType: 'pw_setting',
        actionName: 'pw_setting',
      });
      dispatch(notificationSuccess('パスワードの再設定が完了しました'));
      history.push('/login');
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };
  return (
    <section className="password-change">
      <Page.Wrapper
        header={
          <>
            <HeaderTitle title={'パスワード再設定'}>
              <></>
            </HeaderTitle>
          </>
        }
        disableDataLayerPush
      >
        <>
          {!pending && (
            <section>
              {flow.step1 && (
                <DataLayerPushContainer
                  data={{
                    event: 'pageView',
                    actionType: 'page_view',
                    actionName: 'page_view',
                    virtualPageName: 'step_1',
                  }}
                >
                  <section className="w-308 m-auto mt-48 sm:w-full">
                    <p className="mbx-typography--body_1 text-center">
                      登録したメールアドレスをご入力ください。
                      <br /> パスワード再設定用のリンクをお送りします。
                    </p>
                    <FormProvider {...methods}>
                      <form onSubmit={handleSubmit((data) => console.log(data))}>
                        <FormLayoutFieldset className="mt-24">
                          <FormLabel label="メールアドレス" type="small"></FormLabel>
                          <FormContainerTextfield
                            type="email"
                            name="email"
                            placeholder="例）example@matchbox.com"
                            className="mkt_mask_items"
                          ></FormContainerTextfield>
                        </FormLayoutFieldset>
                      </form>
                    </FormProvider>
                    <BaseButton
                      theme="primary"
                      size="m"
                      className="btn mt-40 sm:mt-48"
                      disabled={loading}
                      onClick={reSetting}
                    >
                      パスワード再設定のリンクを送信する
                    </BaseButton>
                    <BaseButton theme="link" size="m" href="/login" className="btn mt-24 sm:mb-64">
                      ログイン画面へ戻る
                    </BaseButton>
                  </section>
                </DataLayerPushContainer>
              )}
              {flow.step2 && (
                <DataLayerPushContainer
                  data={{
                    event: 'pageView',
                    actionType: 'page_view',
                    actionName: 'page_view',
                    virtualPageName: 'step_2',
                  }}
                >
                  <section className="w-308 m-auto text-center">
                    <p className="mbx-typography--body_1 mt-48">
                      パスワード再設定用のリンクを送信しました。 メールをご確認ください。
                      なお、リンクの有効期限は発行後、24時間です。
                    </p>
                    <BaseButton
                      size={'m'}
                      theme={'link'}
                      href={'/login'}
                      className="mt-64 sm:mb-64"
                    >
                      ログイン画面へ
                    </BaseButton>
                  </section>
                </DataLayerPushContainer>
              )}
              {flow.step3 && (
                <DataLayerPushContainer
                  data={{
                    event: 'pageView',
                    actionType: 'page_view',
                    actionName: 'page_view',
                    virtualPageName: 'step_3',
                  }}
                >
                  <div className="sm:pb-64">
                    <PasswordForm
                      buttonText={'パスワードを再設定'}
                      setPassword={setPassword}
                      onClick={onPasswordReset}
                      disabled={loading}
                    >
                      新しいパスワードを入力して、パスワードを再設定してください。
                    </PasswordForm>
                  </div>
                </DataLayerPushContainer>
              )}
            </section>
          )}
        </>
      </Page.Wrapper>
    </section>
  );
}

export default PasswordChange;
