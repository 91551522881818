import '@/styles/common/Portfolio/ImagePage/add_image.scss';

import React from 'react';

import BaseButton from '@/components/common/Button/BaseButton';

export function AddImage({
  onBack,
  onSelect,
}: {
  onBack: () => void;
  onSelect: (index: number) => void;
}): React.ReactElement {
  return (
    <section className="mbx-add_image">
      <div className="mbx-add_image_inner">
        <h1>画像の見せ方を選択</h1>
        <p className="text-subhead_2 text-center lg:mb-24 md:mb-24 sm:hidden">
          画像の見せ方を３種類から選択できます。
          <br />
          レスポンシブデザインやモバイルの作品があれば、デバイスにはめこまれた画像を作成してみましょう。ドラッグ&ドロップで簡単に作成できます。
        </p>
        <div className="mbx-add_image_buttons sm:space-x-8">
          <button type="button" className="mbx-add_image_button icon" onClick={() => onSelect(0)}>
            <div className="mbx-icon mbx-icon-Large32_Placeholder-image"></div>
          </button>
          <button type="button" className="mbx-add_image_button" onClick={() => onSelect(1)}>
            <img src="/images/mockup-devices.png" alt="" />
          </button>
          <button type="button" className="mbx-add_image_button" onClick={() => onSelect(2)}>
            <img src="/images/mockup-mobile-devices.png" alt="" />
          </button>
        </div>
        <div className="text-center lg:mt-40 md:mt-40 sm:mt-24">
          <BaseButton size="m" theme="link" onClick={onBack}>
            作品タイプの選択に戻る
          </BaseButton>
        </div>
      </div>
    </section>
  );
}
