import React, { ReactNode, VFC } from 'react';
import ReactDOM from 'react-dom';

import Modal from '@/components/common/Modal';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';

const ModalPortal: VFC<{ children: ReactNode }> = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.getElementById('generalAlertModal') as HTMLElement
  );
};

export const AlertModal: VFC<{
  isOpen: boolean;
  text: string;
  onCancel: () => void;
  onClick: () => void;
  onClickText: string;
}> = ({ isOpen, text, onCancel, onClick, onClickText }) => {
  const click = () => {
    onClick();
    onCancel();
  };
  return (
    <ModalPortal>
      <Modal
        isOpen={isOpen}
        isClose={onCancel}
        className={
          'rounded-4 lg:w-624 mb:w-624 lg:h-256 mb:h-256 sm:w-288 sm:p-24 flex flex-col justify-center items-center space-y-24'
        }
      >
        <div className="text-14_20">{text}</div>
        <div className="flex space-x-16 lg:w-308 md:w-308">
          <BaseButton size="m" theme="secondary" className="flex-1" onClick={onCancel}>
            キャンセル
          </BaseButton>
          <BaseButton size="m" theme="primary" className="flex-1" onClick={click}>
            {onClickText}
          </BaseButton>
        </div>
      </Modal>
    </ModalPortal>
  );
};
