import React, { useMemo } from 'react';
import cn from 'classnames';
import { OfferStatusEnum, OfferRateEnum } from '@/utils/api-client';

type Props = {
  status: OfferStatusEnum;
  rate?: OfferRateEnum;
  direct?: boolean;
};

export const UserStatusLabel = (props: Props): React.ReactElement => {
  const rate = () => {
    if (props.rate === 1) return '低';
    if (props.rate === 2) return '中';
    if (props.rate === 3) return '高';
    return '';
  };
  const style = useMemo(() => {
    if (props.direct && (props.status === 3 || props.status === 6 || props.status === 7)) {
      return { background: 'bg-red-200', color: 'text-red-700', text: '一次面接オファー中' };
    }
    if (props.direct && (props.status === 8 || props.status === 9)) {
      return {
        background: 'bg-blue-200',
        color: 'text-blue-700',
        text: 'カジュアル面談オファー中',
      };
    }
    if (props.status === 1) {
      return {
        background: 'bg-success-200',
        color: 'text-success-700',
        text: `ピックアップ${rate()}`,
      };
    }
    if (props.status === 2) {
      return {
        background: 'bg-attention-200',
        color: 'text-attention-700',
        text: 'マイナビワークスに相談中',
      };
    }
    if (props.status === 3 || props.status === 6 || props.status === 7) {
      return { background: 'bg-red-200', color: 'text-red-700', text: '一次面接オファー中' };
    }
    if (props.status === 8 || props.status === 9 || props.status === 11) {
      return {
        background: 'bg-blue-200',
        color: 'text-blue-700',
        text: 'カジュアル面談オファー中',
      };
    }
    if (props.status === 4 || props.status === 5 || props.status === 10) {
      return { background: 'bg-gray-300', color: 'text-gray-600', text: '見送り' };
    }
    if (props.status === 0) {
      return { background: 'bg-gray-300', color: 'text-gray-600', text: '回答期限終了' };
    }
  }, [props.status, props.rate]);

  return (
    <section
      className={cn('flex', 'items-center', 'justify-center', 'w-140', 'h-26', style?.background)}
    >
      <div className={cn('text-10_16', 'font-bold', style?.color)}>{style?.text}</div>
    </section>
  );
};
