import '@/styles/common/Admin/SearchResult/search_conditions.scss';

import React, { useEffect, useMemo } from 'react';

import { BaseButton } from '@/componentsAdmin/Parts/Button';
import { useUserTag } from '@/hooksAdmin/useUserTag';
import { AdminSearchConditionModel } from '@/utils/api-client';
import { searchConditionsToText } from '@/utils/search';

type TProps = {
  saved: boolean;
  title?: string;
  conditions: AdminSearchConditionModel | null;
  onClickChange: React.MouseEventHandler<HTMLDivElement>;
  onClickSave: React.MouseEventHandler<HTMLDivElement>;
};

/**
 * 検索条件表示コンポーネント
 * Directからひっぺがした
 * @author s2 masa
 */
export function SearchConditions({
  saved,
  title,
  conditions,
  onClickChange,
  onClickSave,
}: TProps): React.ReactElement {
  // ====================================================================================================
  //
  // Hooks
  //
  // ====================================================================================================
  const { getUserTags, userTags } = useUserTag();

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const searchConditionsText = useMemo(() => {
    if (conditions) {
      // conditionsにuserTagsが含まれている場合は、検索条件をテキストに変換
      if (conditions.user_tags && conditions.user_tags?.length > 0) {
        if (userTags && userTags?.length > 0) {
          const mutationConditions = { ...conditions };
          mutationConditions.user_tags = conditions.user_tags.map((v) => {
            const userTag = userTags.find((item) => item.id == v);
            return userTag?.name || '';
          });
          return searchConditionsToText(mutationConditions, 'admin');
        } else {
          return '';
        }
      } else {
        return searchConditionsToText(conditions, 'admin');
      }
    }

    return '';
  }, [conditions, userTags]);

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    getUserTags();
  }, []);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <>
      <section className="mbx-search_conditions">
        <div className="mbx-search_conditions__header">現在の検索条件</div>
        <div className="mbx-search_conditions__content">
          {saved && <p className="mbx-search_conditions__title mkt_mask_items">{title}</p>}
          <p className="mbx-search_conditions__text">{searchConditionsText}</p>
        </div>
        <div className="mbx-search_conditions__ui">
          <BaseButton
            size="s"
            theme="primary"
            className="mbx-search_conditions__button"
            onClick={onClickChange}
          >
            条件を変更
          </BaseButton>
          {!saved && (
            <BaseButton
              size="s"
              theme="secondary"
              className="mbx-search_conditions__button"
              onClick={onClickSave}
            >
              この条件を保存
            </BaseButton>
          )}
        </div>
      </section>
    </>
  );
}
