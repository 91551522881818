import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { CompanyTagList } from '@/componentsAdmin/Company/New/CompanyTagList';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { RootState } from '@/reduxAdmin';
import { AdminCompanyPostParam, BlockCompany } from '@/utils/api-client';
import { yupResolver } from '@hookform/resolvers/yup';

type IFormInputs = {
  name: string;
  kana: string;
  tel: string;
  company_user_name: string;
  company_user_furigana: string;
  company_user_section: string;
  company_user_post: string;
  company_user_email: string;
  mw_user_id: string;
};

type Props = {
  className?: string;
  companies: BlockCompany[];
  setCompanies: (value: React.SetStateAction<BlockCompany[]>) => void;
  setModal: React.MouseEventHandler<HTMLDivElement>;
  onSubmit: (params: AdminCompanyPostParam) => Promise<void>;
};

export const CompanyNewFormSection = (props: Props): React.ReactElement => {
  const CP2MASTER = useSelector((state: RootState) => state.admin_master.cp2);
  const schema = yup.object().shape({
    name: YUP_VALIDATION.required,
    kana: YUP_VALIDATION.furigana,
    tel: YUP_VALIDATION.tel,
    company_user_name: YUP_VALIDATION.required,
    company_user_furigana: YUP_VALIDATION.furigana,
    company_user_section: YUP_VALIDATION.required,
    company_user_post: YUP_VALIDATION.null,
    company_user_email: YUP_VALIDATION.email_required,
    mw_user_id: YUP_VALIDATION.required,
  });
  const methods = useForm<IFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      kana: '',
      tel: '',
      company_user_name: '',
      company_user_furigana: '',
      company_user_section: '',
      company_user_post: '',
      company_user_email: '',
      mw_user_id: '',
    },
  });
  const { handleSubmit } = methods;

  const onSubmit = (data: IFormInputs) => {
    const params: AdminCompanyPostParam = {
      name: data.name,
      kana: data.kana,
      tel: data.tel,
      block_company_id: props.companies,
      company_user_name: data.company_user_name,
      company_user_furigana: data.company_user_furigana,
      company_user_section: data.company_user_section,
      company_user_post: data.company_user_post,
      company_user_email: data.company_user_email,
      mw_user_id: Number(data.mw_user_id),
    };
    props.onSubmit(params);
  };

  return (
    <section className={props.className}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <div className="w-624 m-auto pb-80">
            <FormLayoutFieldset>
              <FormLabel label="企業名" type="small" className="pt-6" />
              <FormContainerTextfield
                name="name"
                height="normal"
                placeholder="例）株式会社マッチデザイン"
              />
            </FormLayoutFieldset>
            <FormLayoutFieldset className="mt-16">
              <FormLabel label="企業名（ふりがな）" type="small" className="pt-6" />
              <FormContainerTextfield
                name="kana"
                height="normal"
                placeholder="例）かぶしきかいしゃまっちでざいん"
              />
            </FormLayoutFieldset>
            <FormLayoutFieldset className="mt-16">
              <FormLabel label="電話番号" type="small" className="pt-6" />
              <FormContainerTextfield name="tel" height="normal" placeholder="例）0312345678" />
            </FormLayoutFieldset>
            <FormLayoutFieldset className="mt-16">
              <FormLabel label="企業DBとの紐付け" type="small" className="pt-6" />
              <div className="flex justify-between min-h-38">
                <CompanyTagList
                  className="w-504"
                  companies={props.companies}
                  setCompanies={props.setCompanies}
                />
                <div className="w-104">
                  <div className="-mt-5 w-full base-button" onClick={props.setModal}>
                    <p className="button size-s theme-tertiary flex items-center">
                      <span className="font-bold label">
                        {props.companies.length === 0 ? '企業検索' : '変更'}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </FormLayoutFieldset>
            <section className="w-308">
              <FormLayoutFieldset className="mt-16">
                <FormLabel label="ご担当者様名" type="small" className="pt-6" />
                <FormContainerTextfield
                  name="company_user_name"
                  height="normal"
                  placeholder="例）町田太郎"
                />
              </FormLayoutFieldset>
              <FormLayoutFieldset className="mt-16">
                <FormLabel label="ご担当者様名（ふりがな）" type="small" className="pt-6" />
                <FormContainerTextfield
                  name="company_user_furigana"
                  height="normal"
                  placeholder="例）まちだたろう"
                />
              </FormLayoutFieldset>
              <FormLayoutFieldset className="mt-16">
                <FormLabel label="ご担当者様部署" type="small" className="pt-6" />
                <FormContainerTextfield
                  name="company_user_section"
                  height="normal"
                  placeholder="例）人事部"
                />
              </FormLayoutFieldset>
              <FormLayoutFieldset className="mt-16">
                <FormLabel label="ご担当者様役職" type="small" className="pt-6" />
                <FormContainerTextfield
                  name="company_user_post"
                  height="normal"
                  placeholder="例）部長"
                />
              </FormLayoutFieldset>
              <FormLayoutFieldset className="mt-16">
                <FormLabel label="メールアドレス" type="small" className="pt-6" />
                <FormContainerTextfield
                  name="company_user_email"
                  type="email"
                  height="normal"
                  placeholder="例）machida.tarou@match-design.com"
                />
              </FormLayoutFieldset>
              <FormLayoutFieldset className="mt-16">
                <FormLabel label="マイナビワークス担当者" type="small" className="pt-6" />
                <FormContainerDropdown
                  name="mw_user_id"
                  selectList={CP2MASTER.cp2_charge_name}
                  placeholder="選択してください"
                />
              </FormLayoutFieldset>
            </section>
            <p className="mt-40 text-center mbx-typography--body_1">
              内容に間違いがないかご確認の上、登録するボタンを押してください。
              <br />
              後担当者様へログイン情報を送信します。
            </p>
            <div className="w-308 mt-24 m-auto">
              <BaseButton theme="primary" size="m" className="w-full">
                登録する
              </BaseButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </section>
  );
};
