import React, { VFC } from 'react';

type TProps = {
  className?: string;
  title: string;
  date?: string;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=92%3A2283
 */
export const PreviewHeader: VFC<TProps> = ({ className, title, date }) => {
  return (
    <section className={className}>
      <div className="w-full h-80">
        <hr className="mbx-keyline-horizontal--bold"></hr>
        <div className="flex justify-between pt-18">
          <p className="text-10_18 font-bold">{title}</p>
          {date ? <p className="text-10_18">{date}現在</p> : null}
        </div>
      </div>
    </section>
  );
};
