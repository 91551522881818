import '@/stylesAdmin/pages/Candidates/Top.scss';

import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import ImgIcon from '@/assets/images/profile/circle-placeholder.svg';
import { BaseButton } from '@/components/common/Button';
import { Filters } from '@/componentsAdmin/Candidates/Filters';
import { TableHeader } from '@/componentsAdmin/Candidates/TableHeader';
import { UserList } from '@/componentsAdmin/Candidates/UserList';
import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { Pager } from '@/componentsDirect/Parts/Navigation/Page/Pager';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { RootState } from '@/reduxAdmin';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import {
  ADMINApi,
  AdminCompany,
  CompanyUser,
  Offer,
  ResponseError,
  Status,
} from '@/utils/api-client';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type TCompany = {
  company: AdminCompany;
  user: CompanyUser;
};

export const CandidatesTop = (): React.ReactElement => {
  // ====================================================================================================
  //
  // CONST
  //
  // ====================================================================================================
  const params: { company_id: string } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.utils.loading);

  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [offers, setOffers] = useState<Offer[]>();
  const [company, setCompany] = useState<TCompany>();
  const [filterValues, setFilterValues] = useState({ matchbox_id: '', status: '', rate: '', job: '', sort: '' });
  const [page, setPage] = useState<number>(0);
  const [pageList, setPageList] = useState<number[]>([]);
  const [total, setTotal] = useState<number>(0);

  // ====================================================================================================
  //
  // METHOD
  //
  // ====================================================================================================
  const getOffer = async () => {
    dispatch(toggleLoading(true));
    try {
      const res = await new ADMINApi().getAdminOffers(
        '10',
        `${offset}`,
        sort.by,
        sort.order,
        filterValues.status ? [Number(filterValues.status)] : undefined,
        filterValues.rate ? Number(filterValues.rate) : undefined,
        filterValues.job ? Number(filterValues.job) : undefined,
        filterValues.matchbox_id,
        filterValues.rate ? (Number(filterValues.rate) as 1 | 2 | 3) : undefined,
        undefined,
        undefined,
        Number(params.company_id)
      );
      setOffers(res.data.offers);
      if (res.data.offers) {
        setOffers(res.data.offers);
      }
      if (res.data.total !== undefined) {
        setTotal(res.data.total);
      }
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const offset = useMemo(() => {
    if (page === 0) return 0;
    return page * 10;
  }, [page]);

  const pageLength = useMemo(() => {
    if (total === 0) return 0;
    return Math.ceil(total / 10);
  }, [total]);

  const sort = useMemo(() => {
    switch (filterValues.sort) {
      case '1':
        return {
          order: 'desc',
          by: 'updated_at',
        };
      case '2':
        return {
          order: 'desc',
          by: 'created_at',
        };
      case '3':
        return {
          order: 'desc',
          by: 'reactions',
        };
      case '4':
        return {
          order: 'asc',
          by: 'reactions',
        };
      case '5':
        return {
          order: 'desc',
          by: 'has_action',
        };
      case '6':
        return {
          order: 'asc',
          by: 'has_action',
        };
    }
    return {
      order: '',
      by: '',
    };
  }, [filterValues.sort]);

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    (async () => {
      dispatch(toggleLoading(true));
      try {
        const company = await new ADMINApi().getAdminCompany(params.company_id);
        setCompany(company.data as TCompany & Status);
        getOffer();
      } catch (error) {
        const e = error as AxiosError<ResponseError>;
        if (e.response) dispatch(notificationError(e.response.data.error_message));
      } finally {
        dispatch(toggleLoading(false));
      }
    })();
  }, []);

  useEffect(() => {
    if (loading) return;
    getOffer();
  }, [filterValues, page]);

  useEffect(() => {
    setPage(0);
  }, [filterValues]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <AdminPage header={<HeaderTitle title="企業情報" />}>
      <section className="mbxd-candidates_top pb-84">
        <BaseButton
          theme="link"
          iconName="Arrow_Left"
          size="s"
          href={`/admin/companies/${params.company_id}`}
          className="mt-16"
        >
          企業詳細へ戻る
        </BaseButton>
        <div className="flex items-center mt-32">
          <div className="w-24 h-24 rounded-full overflow-hidden">
            <img src={company?.company.icon ? company.company.icon : ImgIcon} alt="" />
          </div>
          <h3 className="ml-8 mbx-typography--heading_3">{company?.company.name}のオファー履歴</h3>
        </div>

        <section className="mt-32">
          <Filters setFilterValues={setFilterValues} />
          {total !== 0 && (
            <p className="mt-24 mbx-typography--body_2">
              {page === 0 ? page + 1 : page + '1'}〜{page === pageLength - 1 ? total : page + 1 + '0'}{' '}
              / {total}件
            </p>
          )}
        </section>
        <section className="mt-16">
          <TableHeader />
          {offers?.map((offer: Offer, index: number) => {
            return (
              <div key={index}>
                <UserList offer={offer} />
              </div>
            );
          })}
        </section>
        <div className="mt-12">
          {total !== 0 && (
            <Pager
              page={page}
              pageLength={pageLength}
              setPage={setPage}
              setPageList={setPageList}
              pageList={pageList}
            />
          )}
        </div>
      </section>
    </AdminPage>
  );
};
