import cn from 'classnames';
import React, { ReactNode } from 'react';
import { HashLink } from 'react-router-hash-link';

import { useCompletedAPI } from '@/hooks/useCompletedAPI';

type TProps = {
  href?: string; // url指定がある場合はonClickで毎回同じ処理書くの面倒だからlinkボタンになる機能を持たせる
  bold?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  noneUnderline?: boolean; // underline不要版
};
type TBodyProps = {
  isExternalLink?: boolean;
};

const Body = ({
  children,
  bold,
  isExternalLink = false,
}: Pick<TProps, 'children' | 'bold'> & TBodyProps): React.ReactElement => {
  const className = cn('mbx-button-textLink-body', { 'font-bold': bold });
  const iconName = bold ? 'External_link' : 'External_link-thin';
  return (
    <span className={className}>
      <span>{children}</span>
      {isExternalLink && <i className={`mbx-icon mbx-icon-${iconName} mr-2`}></i>}
    </span>
  );
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=992%3A17933
 *
 * 単純なリンク機能のみでいい場合は href に外部リンクを指定すると aタグで生成され target="_blank" で外部に遷移します。内部リンクの場合は
 * <Link>で生成されます
 *
 * その他の処理が必要な場合は onClick に指定してください。
 *
 * フォントサイズは親要素の指定に依存します
 *
 * 外部リンクアイコンはhrefの値に応じて表示されます。
 */
export const ButtonTextLink = ({
  className,
  onClick,
  disabled,
  children,
  bold,
  href = '',
  noneUnderline = false,
}: TProps): React.ReactElement => {
  const isExternalLink = href?.startsWith('http');
  const { checkCareerHistory } = useCompletedAPI();
  const baseClassName = cn(
    { 'mbx-button-textLink': !noneUnderline },
    { 'mbx-button-textLink-noneUnderline': noneUnderline }
  );

  return (
    <span className={className}>
      <span className={baseClassName}>
        {href && !disabled ? (
          <>
            {isExternalLink ? (
              <a href={href} target="_blank" rel="noreferrer">
                <Body bold={bold} isExternalLink={isExternalLink}>
                  {children}
                </Body>
              </a>
            ) : (
              <HashLink
                to={href}
                scroll={(el) => {
                  /**
                   * issue : https://github.com/prtytokyo/matchbox-2021-web-frontend/issues/1085
                   *
                   * 仕様できるhrefもハードコーディングとだいぶ強引なのでもっといい方法あったら変えて欲しい
                   */
                  if (
                    href === '/mypage/cv/edit#SECTION_CAREERS' ||
                    href === '/mypage/cv/edit#qualification' ||
                    href === '/mypage/cv/edit#skill'
                  ) {
                    (async () => {
                      await checkCareerHistory();
                      el.scrollIntoView({ behavior: 'smooth' });
                    })();
                  } else {
                    el.scrollIntoView({ behavior: 'smooth' });
                  }
                }}
              >
                <Body bold={bold}>{children}</Body>
              </HashLink>
            )}
          </>
        ) : (
          <button disabled={disabled} onClick={onClick}>
            <Body bold={bold} isExternalLink={isExternalLink}>
              {children}
            </Body>
          </button>
        )}
      </span>
    </span>
  );
};
