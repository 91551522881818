import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { FieldArrayWithId } from 'react-hook-form';
import { Container, Draggable, OnDropCallback } from 'react-smooth-dnd';

import { Sorting } from '@/components/common/Form/Unit/Sorting';
import { TFormValues } from '@/components/Resume/Form/SectionEducations';

import { ModalEditableBody } from './Editable';

type TProps = {
  children: ReactNode;
};

type TPropsBody = {
  fields: FieldArrayWithId<TFormValues, 'educations', 'id'>[];
  onDrop: OnDropCallback;
  onClose: React.MouseEventHandler<HTMLDivElement>;
  onDelete: (e_id: string, index: number) => void;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=685%3A35846
 *
 * スマホ時の学歴並び替えモーダル
 */
export const ModalEducation = ({ children }: TProps): React.ReactElement => {
  const el = document.getElementById('educationSortModalPortal') as HTMLElement;
  return ReactDOM.createPortal(children, el);
};

export const ModalEducationBody = ({
  fields,
  onDrop,
  onClose,
  onDelete,
}: TPropsBody): React.ReactElement => {
  return (
    <ModalEditableBody onClose={onClose}>
      <Container
        onDrop={onDrop}
        dragHandleSelector=".dragHandle"
        render={(ref) => {
          return (
            <div ref={ref}>
              {fields.map((item, index) => {
                return (
                  <Draggable key={item.id}>
                    <Sorting handle="dragHandle" onDelete={() => onDelete(item.e_id, index)}>
                      <>
                        {`${item.e_graduate_date_yyyy}年${item.e_graduate_date_mm}月`}
                        {item.e_school_name && (
                          <>
                            <br />
                            {item.e_school_name}
                          </>
                        )}
                      </>
                    </Sorting>
                  </Draggable>
                );
              })}
            </div>
          );
        }}
      />
    </ModalEditableBody>
  );
};
