import '@/styles/common/Direct/InvitationModal.scss';

import cn from 'classnames';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { State } from '@/redux/state';
import { UserInfo } from '@/utils/api-client';

import BaseButton from '../Button/BaseButton';
import Modal from '../Modal';
import WarningList from './WarningList';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type Props = {
  isOpen: boolean;
  isClose: React.Dispatch<React.SetStateAction<boolean>>;
};

// ====================================================================================================
//
// CONST
//
// ====================================================================================================
const warnings = [
  '開示を希望しない企業がある場合は、必ずご自身でブロックする企業の設定を行ってください。ブロック設定ができておらずに発生したトラブルについては、弊社は一切の責任を負いかねます。',
  'オファー機能の登録を行うと、保存された情報は随時企業に公開されます。編集中の情報を企業に公開したくない場合は、一度オファー機能を停止した上で編集を行い、完成後、再度オファー機能に登録してください。',
  'オファー機能の登録を行うと、ブロック設定されない参画企業に対しては、各入力画面の項目のうち、氏名(ふりがな含む)、生年月日、都道府県を除く住所、電話番号、メールアドレス、家族情報、その他各入力画面にて「開示されない」と表示されるものについては、開示されませんが、その他の項目に入力した情報は開示されますので、個人が特定され得る情報の入力を行わないようにしてください。',
  'オファー機能に登録したポートフォリオ内に、著作権、その他第三者の権利の侵害等の問題が発生した場合は、事務局側で強制的に、オファー機能の登録およびポートフォリオの公開を停止する場合があります。あらかじめご了承ください。',
];

function InvitationModal(props: Props): React.ReactElement {
  // ====================================================================================================
  //
  // HOOKS
  //
  // ====================================================================================================
  const user: UserInfo | null = useSelector((state: State) => state.user);
  const mq = useMBXMediaQuery();
  const history = useHistory();
  const { push } = React.useContext(DataLayerContext);

  // ====================================================================================================
  //
  // EVENT
  //
  // ====================================================================================================
  const onClick = () => {
    if (user?.use_direct) return;
    props.isClose(false);
    history.push('/mypage/direct');
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    if (!props.isOpen) return;
    push({
      event: 'pageView',
      actionType: 'page_view',
      actionName: 'modal_open',
      virtualPageName: 'about-matchbox-direct',
    });
  }, [props.isOpen]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <section className="invitation">
      <Modal isOpen={props.isOpen} isClose={() => props.isClose(false)}>
        <section className="w-784 pt-80 pb-80 md:w-full sm:w-full sm:pt-48">
          <h2 className={cn(mq.sm ? 'mbx-typography--heading_3' : 'mbx-typography--heading_2')}>
            オファー機能とは
          </h2>
          <p className="mbx-typography--body_1 mt-24 sm:pl-24 sm:pr-24">
            ポートフォリオや職務経歴書を作成し、「オファー機能」に登録すると、
            <br />
            あなたの作品や経験を見た企業から面接のオファーを受け取ることができます。
            <br />
            オファーを受けたあとのやりとりは、マイナビワークスのキャリアアドバイザーがサポート。
            <br />
            安心して転職活動を進めることができます。
          </p>
          <div className="w-480 mt-32 m-auto sm:w-full">
            <img src="/images/Top/Modal/modal2_1.png" alt="" />
          </div>
          <div
            className="mt-32 py-64 border-t border-b border-blueGray-300 sm:py-32 sm:px-24"
            style={{ backgroundColor: '#e7eff3' }}
          >
            <div className="w-540 m-auto text-left sm:w-full">
              <ul className="text-body_1 font-bold">
                <li className="flex">
                  <p className="flex items-center h-24 mr-16">
                    <span className="inline-block w-6 h-6 bg-red-700 rounded-full"></span>
                  </p>
                  <p>ポートフォリオ、職務経歴書、希望条件を直接企業に見てもらうことができます。</p>
                </li>
                <li className="flex mt-16">
                  <p className="flex items-center h-24 mr-16">
                    <span className="inline-block w-6 h-6 bg-red-700 rounded-full"></span>
                  </p>
                  <p>ポートフォリオがなくても、職務経歴だけで登録可能です。</p>
                </li>
                <li className="flex mt-16">
                  <p className="flex items-center h-24 mr-16">
                    <span className="inline-block w-6 h-6 bg-red-700 rounded-full"></span>
                  </p>
                  <p>
                    企業の目に留まると、面接のオファーが届きます。
                    <br />
                    オファーは、登録したメールアドレスにもお知らせ。
                  </p>
                </li>
                <li className="flex mt-16">
                  <p className="flex items-center h-24 mr-16">
                    <span className="inline-block w-6 h-6 bg-red-700 rounded-full"></span>
                  </p>
                  <p>
                    面接を受けるかどうかはあなた次第。
                    <br />
                    迷ったら、マイナビワークスのキャリアアドバイザーに相談もできます。
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-32 w-624 m-auto text-left sm:w-full sm:mt-48 sm:pl-24 sm:pr-24">
            <WarningList warnings={warnings} />
          </div>
          <BaseButton
            size="m"
            theme="primary"
            className="direct-btn mt-48"
            onClick={onClick}
            disabled={user?.use_direct}
          >
            {user?.use_direct ? '登録済み' : 'オファー機能に登録する'}
          </BaseButton>
        </section>
      </Modal>
    </section>
  );
}

export default InvitationModal;
