/* eslint-disable @typescript-eslint/no-explicit-any */
import 'cropperjs/dist/cropper.css';
import '@/styles/common/Modal/cropper.scss';

import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

import { ImageCropperComponent } from '@/components/common/Image/Cropper';
import Modal from '@/components/common/Modal';

type TPortalProps = {
  children: ReactNode;
};

const CropperModalPortal = ({ children }: TPortalProps): React.ReactElement => {
  return ReactDOM.createPortal(children, document.getElementById('cropperModal') as HTMLElement);
};

type TModalProps = {
  isOpen: boolean;
  width: number;
  height: number;
  maxFileSize?: number;
  src: string | ArrayBuffer | null | undefined; // Cropperに渡せるのはstringだけなんだが呼び出し元でいろんな型で指定されちゃってるのでとりあえずとり得る型を列挙する
  onSave?: (dataUri: string) => void;
  postImage?: (data: any) => void;
  onClose: () => void;
};

/* 画像トリミングモーダル */
export const CropperModal = ({
  isOpen,
  width,
  height,
  src,
  postImage,
  onClose,
  onSave,
}: TModalProps): React.ReactElement => {
  return (
    <CropperModalPortal>
      <Modal
        isOpen={isOpen}
        isClose={onClose}
        className={'modal-contents_add w-784 lg:h-840 mb:h-840'}
      >
        <ImageCropperComponent
          width={width}
          height={height}
          src={src}
          onCancel={onClose}
          postImage={postImage}
          onSave={onSave}
        />
      </Modal>
    </CropperModalPortal>
  );
};
