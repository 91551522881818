import cn from 'classnames';
import React, { useMemo } from 'react';

import ImgIcon from '@/assets/images/profile/circle-placeholder.svg';
import { ReactionsResponseAllOfReactions } from '@/utils/api-client';

type Props = {
  reaction: ReactionsResponseAllOfReactions;
};

export const MessageSent = (props: Props): React.ReactElement => {
  const label = useMemo(() => {
    let background = '';
    let color = '';
    let text = '';
    if (props.reaction.reaction?.status === 2) {
      background = 'bg-attention-300';
      color = 'text-attention-700';
      text = 'マイナビワークスに相談したい';
    }
    if (props.reaction.reaction?.status === 3) {
      background = 'bg-red-300';
      color = 'text-red-700';
      text = '一次面接オファーしたい';
    }
    if (props.reaction.reaction?.status === 8) {
      background = 'bg-blue-300';
      color = 'text-blue-700';
      text = 'カジュアル面談オファーしたい';
    }
    if (props.reaction.reaction?.status === 4) {
      background = 'bg-gray-300';
      color = 'text-gray-600';
      text = '見送りたい';
    }

    return { background, color, text };
  }, [props.reaction]);

  const dateFormat = (date: string) => {
    const result = date.replace(/-/g, '').match(/(\d{4})(\d{2})(\d{2})/);
    if (!result) return;
    return result[1] + '年' + result[2] + '月' + result[3] + '日';
  };

  return (
    <section className="py-16 border-b border-gray-300">
      <div className="flex">
        <div className="w-24 h-24 rounded-full overflow-hidden mkt_mask_items">
          <img src={props.reaction.user?.photo ? props.reaction.user?.photo : ImgIcon} alt="" />
        </div>
        <div className="ml-8 pr-32 w-full">
          <div className="flex">
            <div
              className={cn(
                'flex',
                'justify-center',
                'items-center',
                'w-160',
                'h-18',
                'rounded-full',
                label.background
              )}
            >
              <span className={cn('text-10_18', 'font-bold', label.color)}>{label.text}</span>
            </div>
            <div className="flex items-center h-18">
              <p className="mbx-typography--subhead_2 ml-8 mkt_mask_items">
                {props.reaction.user?.name}
              </p>
              <p className="text-10_18 text-gray-600 ml-8 mkt_mask_items">
                {props.reaction.user?.section}
              </p>
              <p className="text-10_18 text-gray-600 ml-4 mkt_mask_items">
                {props.reaction.user?.post}
              </p>
              <p className="text-10_18 text-gray-600 ml-16">
                {dateFormat(props.reaction.reaction?.created_at as string)}
              </p>
            </div>
          </div>
          <p className="mbx-typography--body_2 mt-4 mkt_mask_items">
            {props.reaction.reaction?.comment}
          </p>
        </div>
      </div>
    </section>
  );
};
