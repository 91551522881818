import React, { useEffect, useState } from 'react';
import { MyNavi } from '@/utils/api-client';

import { V2Transfer } from './V2Transfer';
import { OnboardingPage_1 } from './Page1';
import { OnboardingPage_2 } from './Page2';
import { OnboardingPage_3 } from './Page3';
import { OnboardingPage_4 } from './Page4';
import { OnboardingPage_5 } from './Page5';
import { OnboardingPage_6 } from './Page6';

type Props = {
  setModalIcon: React.Dispatch<React.SetStateAction<boolean>>;
  startPage: number;
  myNavi?: MyNavi;
};

export const Onboarding = (props: Props): React.ReactElement => {
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    document.cookie = 'onboarding=done';
  }, []);

  useEffect(() => {
    setPage(props.startPage);
  }, [props.startPage]);

  useEffect(() => {
    // 最後のページ以外は閉じるボタンを表示させない
    if (page !== 6) return props.setModalIcon(false);
    props.setModalIcon(true);
  }, [page]);

  return (
    <section className="max-w-784 w-784 md:w-screen sm:w-screen sm:h-auto overflow-hidden">
      <>
        {page === 0 && <V2Transfer page={page} setPage={setPage} />}
        {page === 1 && <OnboardingPage_1 page={page} setPage={setPage} myNavi={props.myNavi}/>}
        {page === 2 && <OnboardingPage_2 page={page} setPage={setPage} myNavi={props.myNavi}/>}
        {page === 3 && <OnboardingPage_3 page={page} setPage={setPage} myNavi={props.myNavi}/>}
        {page === 4 && <OnboardingPage_4 page={page} setPage={setPage} myNavi={props.myNavi}/>}
        {page === 5 && <OnboardingPage_5 page={page} setPage={setPage} />}
        {page === 6 && <OnboardingPage_6 page={page} setPage={setPage} />}
      </>
    </section>
  );
};
