import React, { useState, useMemo, useEffect } from 'react';

import { DataLayerPushContainer } from '@/componentsDirect/common/DataLayerPushContainer';
import { GlobalLoading } from '@/componentsDirect/Global/Loading';
import { GlobalNotificationErrorPortal } from '@/componentsDirect/Global/NotificationErrorPortal';
import {
    GlobalNotificationSuccessPortal
} from '@/componentsDirect/Global/NotificationSuccessPortal';
import { DirectFooter } from '@/componentsDirect/Layout/Footer';
import { AdminHeader } from '@/componentsDirect/Layout/Header';
import { Onboarding } from '@/componentsDirect/common/Onboarding/Onboarding';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { useCompany } from '@/hooksDirect/useCompany';
import { useOfferPositions } from '@/hooksDirect/useOfferPositions';
import { DataLayerType } from '@/hooksDirect/common/useDataLayer';
import { RootState } from '@/reduxAdmin';
import { useSelector } from 'react-redux';
import {
  DirectCompanyModel
} from '@/utils/api-client';

type TProps = {
  children?: React.ReactElement;
  header?: React.ReactElement;
  sticky?: React.ReactElement;
  isErrorPage?: boolean;
  disableDataLayerPush?: boolean;
};

/**
 * DirectとAdminでは返ってくるAPIの型構造が違うため分岐処理で対応すると可動性が悪いので切り分けちゃう
 * ここはDirectのディレクトリ切ってそこに格納しDirectPageにリネームしたいが他の人の作業に影響しそう
 * なので一旦このままで進める。
 */
export const AdminPage = ({
  children,
  header,
  sticky,
  isErrorPage,
  disableDataLayerPush,
}: TProps): React.ReactElement => {
  const { companyData, getCompany, patchCompany } = useCompany();
  const { offerPositions, getOfferPositions } = useOfferPositions();

  const isLogin = useSelector((state: RootState) => state.direct_auth.isLogin);
  const userInfo = useSelector((state: RootState) => state.direct_auth.userInfo);

  const [showModal, setShowModal] = useState<boolean>(false);

  const data = useMemo<DataLayerType | undefined>(() => {
    if (!disableDataLayerPush) {
      const data: DataLayerType = {
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'page_view',
      };
      if (isErrorPage) data.errorStatus = 'error';
      return data;
    }
    return undefined;
  }, [disableDataLayerPush]);

  const requiredItems: (keyof DirectCompanyModel)[] = [
    'business_type',
    'prefecture',
    'address',
    'description',
    'message',
  ];

  useEffect(() => {
    if (userInfo?.user_info.user_type !== 'direct_manager') return;
    (async() => {
      if (!companyData && !offerPositions && isLogin) {
        await getCompany();
        await getOfferPositions();
      }

      if (
        isLogin &&
        location.pathname === '/direct/search' &&
        offerPositions &&
        companyData &&
        (offerPositions?.length === 0 || requiredItems.some(item => !companyData[item]))
      ) {
        setShowModal(true);
      }
    })();

  }, [location.pathname, userInfo, companyData, offerPositions]);

  return (
    <DataLayerPushContainer data={data}>
      <div className="min-h-screen relative pb-120">
        <div>
          <AdminHeader />
          {header}
        </div>
        <main>
          <div className={'w-960 m-auto'}>{children}</div>
          {sticky}
        </main>
        <DirectFooter />
      </div>
      <GlobalLoading />
      <GlobalNotificationErrorPortal />
      <GlobalNotificationSuccessPortal />
      <ModalDefault
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        className="w-624 rounded-modalContainer"
        dataLayer={dataLayer}
      >
        <Onboarding
          company={companyData}
          patchCompany={patchCompany}
          requiredItems={requiredItems}
          postion={offerPositions?.length !== 0 ? true : false}
          onClose={() => setShowModal(false)}
        />
      </ModalDefault>
    </DataLayerPushContainer>
  );
};
