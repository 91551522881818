import cn from 'classnames';
import _ from 'lodash';
import React, { ChangeEventHandler, useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { FormCounter } from '@/components/common/Form/Counter';
import { FormFootnote } from '@/components/common/Form/Footnote';
import { setInputClearingInProgress } from '@/redux';
import { delay } from '@/utils/delay';

type TProps = {
  name: string;
  rows?: number;
  id?: string;
  placeholder?: string;
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
  debugError?: boolean;
  maxLength?: number;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: ChangeEventHandler<HTMLTextAreaElement>;
  onFocus?: ChangeEventHandler<HTMLTextAreaElement>;
  noLineBreaks?: boolean; // 改行禁止
  scroll?: boolean; // scroll可能
};
/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=12%3A1522
 *
 * default の値はhook-formのuseFormで指定
 * https://react-hook-form.com/api/useform
 *
 * maxLength : 値があればカウンターが表示
 *
 * validation周りの設定方法についてはもう少しフォームコンポーネント作ってから改めてドキュメント書きます。
 */
export const FormContainerTextArea = ({
  rows = 2,
  id,
  placeholder,
  name,
  className = '',
  readOnly = false,
  disabled = false,
  debugError = false,
  maxLength = 0,
  onChange,
  onBlur: onBlurTextArea,
  onFocus,
  noLineBreaks = false,
  scroll = false,
}: TProps): React.ReactElement => {
  const textareaRef = useRef<HTMLTextAreaElement>();
  const errorRef = useRef(null);
  const dispatch = useDispatch();
  const methods = useFormContext();
  const watchValue = methods.watch(name);
  const defaultClass = `mbx-formContainer mbx-formContainer--textarea ${ scroll ? 'overflow-y-scroll' : 'overflow-y-hidden'}`;
  const error = methods.formState.errors[name];
  const { ref, ...rest } = methods.register(name);

  const rfhOnChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    rest.onChange(e);
  };
  const debouncedChangeHandler = useCallback(_.debounce(rfhOnChange, 100), []);

  const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (noLineBreaks && e.key === 'Enter') {
      e.preventDefault();
      return false;
    }
  };

  /**
   * コピペ対応でフォーカス外れたら改行除外
   */
  const onBlur: React.FocusEventHandler<HTMLTextAreaElement> = (e) => {
    if (noLineBreaks) {
      const newValue = methods.getValues(name).replace(/\n/g, '');
      if (newValue !== methods.getValues(name)) {
        // setValueだとPatch処理が走らないのでtextfieldのclear用に使っているフラグを流用
        dispatch(setInputClearingInProgress(true));

        // clear用のフラグの値が切り替わった後に処理
        delay(100).then(() => {
          methods.setValue(name, newValue);
        });
      }
    }
    rest.onBlur(e);
    if(onBlurTextArea)onBlurTextArea(e);
  };

  useEffect(() => {
    if (!textareaRef.current) return;
    const textarea = textareaRef.current;
    if (textarea.offsetHeight > textarea.scrollHeight) {
      textarea.setAttribute('rows', rows.toString());
    }
    while (textarea.offsetHeight < textarea.scrollHeight) {
      if(scroll) return;
      const currentRows = textarea.getAttribute('rows');
      if (currentRows) textarea.setAttribute('rows', Number(currentRows) + 1 + '');
    }
  }, [watchValue]);

  return (
    <div className={className}>
      {readOnly ? (
        <div className="mbx-formContainer mbx-formContainer--textarea--readonly">{watchValue}</div>
      ) : (
        <div
          className={cn({
            'mbx-formContainer--error': error || debugError,
          })}
        >
          <textarea
            className={`${defaultClass}`}
            id={id}
            {...rest}
            ref={(e: HTMLTextAreaElement) => {
              ref(e);
              textareaRef.current = e; // you can still assign to ref
            }}
            maxLength={maxLength}
            placeholder={placeholder}
            rows={rows}
            disabled={disabled}
            readOnly={readOnly}
            onChange={onChange ? onChange : debouncedChangeHandler}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            onFocus={onFocus}
          />

          <div className="flex flex-row">
            <CSSTransition
              in={error}
              timeout={550}
              nodeRef={errorRef}
              unmountOnExit
              classNames="mbx-anime-error"
            >
              <div ref={errorRef}>
                <FormFootnote className="text-error-700">
                  {error ? error.message : debugError ? debugError : ''}
                </FormFootnote>
              </div>
            </CSSTransition>

            {maxLength > 0 ? (
              <FormCounter
                current={watchValue ? watchValue.length : 0}
                total={maxLength}
                className="flex-shrink ml-auto"
              ></FormCounter>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};
