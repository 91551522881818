import React from 'react';

import {
    FormCombinationsFromToTextfield
} from '@/componentsDirect/Parts/Form/Combinations/FromToTextfield';
import { FormCombinationsFromToDatepicker }from '@/componentsDirect/Parts/Form/Combinations/FromToDatepicker';
import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import {
    FormContainerDropdownMultiple
} from '@/componentsDirect/Parts/Form/Container/DropdownMultiple';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { UserCondition } from '@/componentsDirect/Parts/UserCondition/Forms';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';

/**
 * 検索条件フォーム 基本情報
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65909
 */
export const AdminSearchFormCompanyBasicInfo = (): React.ReactElement => {
  return (
    <UserCondition title="基本情報">
      <>
        <div className="mbx-utils-stack-v--22">
          <div className="flex">
            <FormLayoutFieldset className="ml-24 w-176">
              <FormLabel label="企業 ID" type="small"></FormLabel>
              <FormContainerTextfield
                name="directompany_id"
                placeholder="例）12345678"
              ></FormContainerTextfield>
            </FormLayoutFieldset>

            <FormLayoutFieldset className="ml-24 w-176">
              <FormLabel label="国税庁番号" type="small"></FormLabel>
              <FormContainerTextfield
                name="block_company_id"
                placeholder="例）12345678"
              ></FormContainerTextfield>
            </FormLayoutFieldset>
          </div>

          <div className="flex flex-auto">
            <FormLayoutFieldset className="ml-24 w-1/2">
              <FormLabel label="企業名" type="small"></FormLabel>
              <FormContainerTextfield
                name="name"
                placeholder="例）株式会社マイナビ デザイン"
              ></FormContainerTextfield>
            </FormLayoutFieldset>

            <FormLayoutFieldset className="ml-24 w-1/2">
              <FormLabel label="企業名（ふりがな）" type="small"></FormLabel>
              <FormContainerTextfield
                name="kana"
                placeholder="例）かぶしきがいしゃまいなびでざいん"
              ></FormContainerTextfield>
            </FormLayoutFieldset>
          </div>

          <div className="flex">
            <FormCombinationsFromToDatepicker
              className="pl-24"
              name="registration_date"
              label="アカウント開設日"
              fieldWidthClass="w-126"
              placeholder="yyyy/mm/dd"
              minYesr={2018}
            />

            <FormCombinationsFromToDatepicker
              className="pl-24"
              name="last_login"
              label="最終ログイン日"
              fieldWidthClass="w-126"
              placeholder="yyyy/mm/dd"
              minYesr={2018}
            />
          </div>

          <div className="flex">
            <FormLayoutFieldset className="ml-24 w-176">
              <FormLabel label="都道府県" type="small"></FormLabel>
              <FormContainerDropdown
                name="prefecture"
                placeholder="選択してください"
                selectList={FORM_OPTIONS.prefecture}
              />
            </FormLayoutFieldset>

            <FormLayoutFieldset className="ml-24 flex-grow">
              <FormLabel label="所在地（市区町村）" type="small"></FormLabel>
              <FormContainerTextfield name="address" placeholder="例） 目黒区" />
            </FormLayoutFieldset>
          </div>

          <div className="flex">
            <FormLayoutFieldset className="ml-24 flex-grow">
              <FormLabel label="業種" type="small"></FormLabel>
              <FormContainerDropdownMultiple
                name="business_type"
                placeholder="選択してください（複数選択可能）"
                selectList={FORM_OPTIONS.business_type}
              />
            </FormLayoutFieldset>
          </div>

          <div className="flex">
            <FormLayoutFieldset className="ml-24 flex-grow">
              <FormLabel label="事業内容" type="small"></FormLabel>
              <FormContainerTextfield
                name="description"
                placeholder="例）Webサイト制作　広告制作（スペースで区切って複数入力可能）"
              />
            </FormLayoutFieldset>
          </div>

          <div className="flex">
            <FormLayoutFieldset className="ml-24 w-176">
              <FormLabel label="従業員数" type="small"></FormLabel>
              <FormContainerDropdown
                name="employee_amount"
                placeholder="選択してください"
                selectList={FORM_OPTIONS.employee_amount}
              />
            </FormLayoutFieldset>
            <FormLayoutFieldset className="ml-24 w-176">
              <FormLabel label="電話番号" type="small"></FormLabel>
              <FormContainerTextfield name="tel" placeholder="例） 09012345678" />
            </FormLayoutFieldset>
          </div>
        </div>
      </>
    </UserCondition>
  );
};
