import '@/styles/common/Portfolio/ImagePage/add_link.scss';

import { AxiosError, AxiosResponse } from 'axios';
import cn from 'classnames';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { BaseButton, ButtonIconButton } from '@/components/common/Button/';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { CropperModal } from '@/components/common/Modal/Cropper';
import { useFileLimit } from '@/hooks/useFIleLimit';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { notificationError, toggleLoading } from '@/redux/index';
import { ResponseError } from '@/utils/api-client';
import { Embed, FileResponse, PortfolioApi, Status } from '@/utils/api-client/index';
import { delay } from '@/utils/delay';

import { EmbeddedLink } from '../MainVisual/EmbeddedLink';

type Props = {
  name?: string;
  onPost?(embed: Embed): void;
  onSend: React.MouseEventHandler<HTMLDivElement>;
  onCancel: React.MouseEventHandler<HTMLDivElement>;
  setUpdateType?: React.Dispatch<React.SetStateAction<'file' | 'change' | undefined>>;
};

export function AddLinkRegister(props: Props): React.ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const methods = useFormContext();
  const [buffer, setBuffer] = useState<string | ArrayBuffer | null>(null);
  const [url, setUrl] = useState<string>();
  const [focus, setFocus] = useState<boolean>(false);
  const [embed, setEmbed] = useState<Embed>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const mq = useMBXMediaQuery();
  const { checkFileSize } = useFileLimit();
  const { push } = useContext(DataLayerContext);
  const onSend = () => {
    post();
  };

  const post = async () => {
    dispatch(toggleLoading(true));
    try {
      const res: AxiosResponse<Embed & Status> = await new PortfolioApi().postEmbed({ url });
      const embed: Embed = {
        embed: res.data.embed,
        image: res.data.image,
        caption: res.data.caption,
      };
      if (props.onPost) props.onPost(embed);
      if (props.setUpdateType) props.setUpdateType('change');
      if (props.name) methods.setValue(props.name, embed);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const contentIndex = useMemo<number | undefined>(() => {
    const index = props.name?.split('.')[1];
    return index ? parseInt(index) : undefined;
  }, [props]);

  useEffect(() => {
    if (!methods) return;
    const value = methods.getValues();
    if (contentIndex === undefined) return;
    const content = value.p_contents[contentIndex];
    if (content.embed) {
      setEmbed(content);
    }
  }, [contentIndex]);

  useEffect(() => {
    if (!methods || !methods?.watch) return;
    const subscription = methods.watch((value) => {
      if (contentIndex === undefined) return;
      const content = value.p_contents[contentIndex];
      if (content.embed) {
        setEmbed(content);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods?.watch, contentIndex]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setUrl(e.target.value);
  };

  const onClear: React.MouseEventHandler<HTMLButtonElement> = () => {
    setUrl('');
    setFocus(false);
  };

  const onChangeInput = (e: any) => {
    const imageFile = e.target.files[0];
    if (!checkFileSize(imageFile.size, 5)) return;

    setIsOpen(true);
    const reader = new FileReader();
    reader.onload = () => {
      setBuffer(reader.result);
    };
    // 画像の読み込み
    reader.readAsDataURL(imageFile);
  };

  const postImage = async (data: any) => {
    dispatch(toggleLoading(true));
    try {
      const res: AxiosResponse<FileResponse> = await new PortfolioApi().postFiles(data);
      push({
        event: 'fileUpload',
        actionType: 'file_upload',
        actionName: '画像',
      });

      setIsOpen(false);
      setEmbed({
        ...embed,
        image: {
          f_id: res.data.f_id,
          f_url: res.data.f_url,
          f_thumbnail: res.data.f_thumbnail,
        },
      });
      if (props.name)
        methods.setValue(props.name, {
          ...embed,
          image: {
            f_id: res.data.f_id,
            f_url: res.data.f_url,
            f_thumbnail: res.data.f_thumbnail,
          },
        } as Embed);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const onClick = () => {
    inputRef.current?.click();
  };

  const onRemove = async () => {
    setEmbed(undefined);
    if (props.setUpdateType) props.setUpdateType('change');
    await delay(100);
    if (props.name)
      methods.setValue(props.name, {
        f_thumbnail: '',
        f_url: '',
        f_id: '',
        caption: '',
      });
  };

  return (
    <>
      <input
        className="mbx-add_link_register_input-file"
        type="file"
        ref={inputRef}
        name=""
        id=""
        onChange={onChangeInput}
      />
      {embed ? (
        <EmbeddedLink
          src={embed.embed}
          thumbnail={embed.image?.f_thumbnail}
          onRemove={onRemove}
          onSetThumbnailClick={onClick}
        />
      ) : (
        <div className="flex justify-center items-center bg-white w-full h-full sm:p-24 ">
          <section className="mbx-add_link_register">
            <h1>埋め込みリンクを登録</h1>
            <small>Youtube、Vimeo、SoundCloud、Slideshare、SketchfabのURLに対応しています。</small>
            <div className="lg:space-y-24 md:space-y-24 lg:my-24 md:my-24 sm:mb-12 sm:flex sm:space-x-8">
              <div className={cn('mbx-formContainer--textfield-wrapper', 'sm:flex-1')}>
                <input
                  type={'text'}
                  className={cn(
                    `mbx-formContainer`,
                    `mbx-formContainer--textfield`,
                    'mkt_mask_items',
                    { focus }
                  )}
                  onFocus={() => {
                    setFocus(true);
                  }}
                  onChange={onChange}
                  value={url}
                  placeholder="https://www"
                />
                <div className={'mbx-formContainer--textfield-clear'}>
                  <ButtonIconButton
                    type="delete"
                    iconName="Clear"
                    onClick={onClear}
                    hitArea="mini"
                    focus={focus}
                  />
                </div>
              </div>

              <BaseButton
                onClick={onSend}
                size="m-for-sp"
                className="w-160"
                theme="primary"
                width="large"
              >
                {mq.sm ? '登録' : '登録する'}
              </BaseButton>
            </div>

            <BaseButton theme="link" size="m" onClick={props.onCancel}>
              作品タイプの選択に戻る
            </BaseButton>
          </section>
        </div>
      )}

      <CropperModal
        isOpen={isOpen}
        width={928}
        height={556}
        src={buffer}
        postImage={postImage}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
}
