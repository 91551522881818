import { AxiosError } from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  searchConditionNumberProperties,
  searchConditionPeriodsProperties,
} from '@/hooksAdmin/useDirectSearchConditionValidation';
import { RootState } from '@/reduxAdmin';
import { setSearchCondition } from '@/reduxAdmin/modules/adminDirectSearchCondition';
import { notificationError, notificationSuccess, toggleLoading } from '@/reduxAdmin/modules/utils';
import {
  ADMINApi,
  DirectSearchConditionModel,
  DirectSearchConditionRequest,
  DirectSearchConditionResponse,
  DirectSearchUserRequest,
  DirectSearchUsersResponse,
  Pagination,
  ResponseError,
} from '@/utils/api-client';
import { conversionStringsLikeNumbers, conversionValuesForPeriods } from '@/utils/utils';

// import { testSearchResultData } from '@/debug/adminDirectSearchResult';

type TReturn = {
  searchCondition: DirectSearchConditionModel | null; // フォームの初期値 これと比較して変更があったかどうか判定
  saveSearchCondition: (condition: DirectSearchConditionModel) => void; // 検索条件を一時保存
  fetchSearchUsers: (query: Pagination) => void; // ユーザー検索
  searchUsersResult: DirectSearchUsersResponse | undefined;
  postAdminSearchCondition: (search_condition_name: string) => Promise<number>;
  getSearchCondition: (idPathParam: number) => Promise<void>;
  patchSearchCondition: (
    idPathParam: number,
    request: DirectSearchConditionRequest
  ) => Promise<void>;
  searchConditionResult: DirectSearchConditionResponse | undefined;
  // setTestSearchResultData: () => void;
};

/**
 * 企業向け検索条件の一時保存及び保存された情報の取得
 */
export const useDirectSearchCondition = (): TReturn => {
  const API = new ADMINApi();
  const dispatch = useDispatch();

  const [searchUsersResult, setSearchUsersResult] = useState<DirectSearchUsersResponse>();
  const [searchConditionResult, setSearchConditionResult] =
    useState<DirectSearchConditionResponse>();

  const searchCondition: DirectSearchConditionModel | null = useSelector(
    (state: RootState) => state.admin_direct_search_condition.condition
  );

  /**
   * 検索条件を整形して一時保存
   */
  const saveSearchCondition = (condition: DirectSearchConditionModel) => {
    let convertValue = conversionValuesForPeriods(
      condition as Record<string, unknown>,
      searchConditionPeriodsProperties
    );

    convertValue = conversionStringsLikeNumbers(convertValue, searchConditionNumberProperties);
    dispatch(setSearchCondition(convertValue));
  };

  /**
   * ユーザー検索
   */
  const fetchSearchUsers = (query: Pagination) => {
    const searchQuery: DirectSearchUserRequest = {
      limit: query.limit,
      offset: query.offset,
      sort_by: query.sort_by,
      sort_order: query.sort_order,
      conditions: searchCondition as DirectSearchConditionModel,
    };
    dispatch(toggleLoading(true));
    API.postAdminDirectSearchUsers(searchQuery)
      .then((res) => {
        setSearchUsersResult(res.data);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * ユーザー検索条件の保存
   */
  const postAdminSearchCondition = (search_condition_name: string): Promise<number> => {
    return new Promise<number>((resolve, reject) => {
      const params: DirectSearchConditionRequest = {
        search_condition_name,
        ...searchCondition,
      } as DirectSearchConditionRequest;

      params.search_condition_name = search_condition_name;

      dispatch(toggleLoading(true));
      API.postAdminDirectSearchCondition(params)
        .then((res) => {
          const searchConditionId = res.data.search_condition_id;
          dispatch(notificationSuccess('検索条件を保存しました'));
          if (typeof searchConditionId === 'number') {
            resolve(searchConditionId);
          } else {
            reject(new Error('search_condition_id is undefined or not a number'));
          }
        })
        .catch((error: AxiosError<ResponseError>) => {
          if (error.response) dispatch(notificationError(error.response.data.error_message));
          reject(error);
        })
        .finally(() => {
          dispatch(toggleLoading(false));
        });
    });
  };

  /**
   * 検索条件詳細
   */
  const getSearchCondition = (idPathParam: number) => {
    dispatch(toggleLoading(true));
    return API.getAdminDirectSearchConditionId(idPathParam)
      .then((res) => {
        setSearchConditionResult(res.data);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 検索条件詳細
   */
  const patchSearchCondition = (idPathParam: number, request: DirectSearchConditionRequest) => {
    dispatch(toggleLoading(true));
    return API.patchAdminDirectSearchConditionId(idPathParam, request)
      .then((res) => {
        console.log(res);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  // 暫定的に仮データを設定
  // const setTestSearchResultData = () => {
  //   setSearchUsersResult({
  //     users: testSearchResultData(),
  //     offset: 0,
  //     total: 100,
  //     result: 'success',
  //   });
  // };

  return {
    searchCondition,
    saveSearchCondition,
    fetchSearchUsers,
    searchUsersResult,
    getSearchCondition,
    patchSearchCondition,
    searchConditionResult,
    postAdminSearchCondition,
    // setTestSearchResultData,
  };
};
