import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';

import { toggleLoading } from '@/redux';
import { toggleLoading as toggleLoadingAdmin } from '@/reduxAdmin/modules/utils';

type TReturn = {
  downloadPDF: () => void;
};

/**
 * PDFダウンロード時にクッキーをチェックしてローダーを表示する処理用
 *
 * ダウンロードボタンのonClickにdownloadPDFを設定して使用
 */
export const useDownloadPDF = (): TReturn => {
  const dispatch = useDispatch();
  const downloadPDF = () => {
    dispatch(toggleLoading(true));
    dispatch(toggleLoadingAdmin(true));
    Cookies.set('downloadStarted', '0');
    setTimeout(checkDownloadCookie, 1000);
  };

  const checkDownloadCookie = () => {
    if (Cookies.get('downloadStarted') === '1') {
      Cookies.remove('downloadStarted');
      dispatch(toggleLoading(false));
      dispatch(toggleLoadingAdmin(false));
    } else {
      setTimeout(checkDownloadCookie, 1000);
    }
  };
  return { downloadPDF };
};
