import React from 'react';
import Modal from '@/components/common/Modal';
import { BaseButton, ButtonIconButton } from '@/components/common/Button/';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { PortfolioItem } from '@/utils/api-client';

type Props = {
  isModal: boolean;
  portfolioItems?: PortfolioItem[];
  onModal: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleDragEnd: ({ active, over }: any) => void;
  removeModalOpen: (index: number) => void;
};

export const SpEditModal = (props: Props): React.ReactElement => {
  const touchSensors = useSensors(
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  return (
    <div className="sp-modal">
      <Modal
        isOpen={props.isModal}
        isClose={() => props.onModal(false)}
        className={'sp-edit sp_only'}
      >
        <div className="sp-edit_inner">
          <div className="back">
            <BaseButton
              iconName="Arrow_Left"
              theme={'link'}
              size={'m'}
              onClick={() => props.onModal(false)}
            >
              戻る
            </BaseButton>
          </div>
          <p className="mbx-typography--body_2 text">
            作品をドラッグして並べ替えできます。また[×]ボタンで作品を削除できます。
          </p>
          {props.portfolioItems && (
            <DndContext
              sensors={touchSensors}
              collisionDetection={closestCenter}
              onDragEnd={props.handleDragEnd}
              autoScroll={false}
            >
              <SortableContext
                items={Array.from(props.portfolioItems.values()).map((item) => ({
                  id: `${item.i_id}`,
                }))}
                strategy={rectSortingStrategy}
              >
                <div className="smooth-dnd-container horizontal">
                  {Array.from(props.portfolioItems.values()).map((item, index) => (
                    <SortableSpThumbnail
                      key={`${item.i_id}`}
                      id={`${item.i_id}`}
                      index={index}
                      item={item}
                      removeModalOpen={props.removeModalOpen}
                    />
                  ))}
                </div>
              </SortableContext>
            </DndContext>
          )}
        </div>
      </Modal>
    </div>
  );
};

const SortableSpThumbnail: React.VFC<{
  item: PortfolioItem;
  index: number;
  id: string;
  removeModalOpen(index: number): void;
}> = ({ item, removeModalOpen, index, id }) => {
  const { listeners, transform, transition, setNodeRef } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className="wrapper" style={style} ref={setNodeRef}>
      <div
        className="thumbnail"
        style={{ backgroundImage: `url(${item.i_image.f_thumbnail})` }}
        {...listeners}
      />
      <ButtonIconButton
        type={'delete'}
        iconName={'Circle-large_Clear'}
        className={'delete'}
        onClick={() => removeModalOpen(index)}
      />
    </div>
  );
};
