import React from 'react';

import { NaviInpageItem } from './Item';

type TNaviInpageItemProps = React.ComponentProps<typeof NaviInpageItem>;
type TNaviInpageMenuListProps = {
  menu: Array<TNaviInpageItemProps>;
};

/**
 * Menu List
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=348%3A26923
 */
export const NaviInpageMenuList = ({ menu }: TNaviInpageMenuListProps): React.ReactElement => {
  return (
    <ul className={'mbx-naviInpage-menuList text-left'}>
      {menu.map((item: TNaviInpageItemProps, index: number) => {
        return (
          <NaviInpageItem done={item.done} disabled={item.disabled} href={item.href} key={index}>
            {item.children}
          </NaviInpageItem>
        );
      })}
    </ul>
  );
};
