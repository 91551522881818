import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import * as yup from 'yup';

import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { BaseButton } from '@/componentsAdmin/Parts/Button/';
import {
    FormContainerTextfield, FormLabel, FormLayoutFieldset
} from '@/componentsAdmin/Parts/Form/';
import { HeaderTitle } from '@/componentsAdmin/Parts/Title/HeaderTitle';
import { YUP_VALIDATION } from '@/definitionAdmin/VALIDATION';
import { useUserTag } from '@/hooksAdmin/useUserTag';
import { UserTagPatchParam } from '@/utils/api-client/api';
import { yupResolver } from '@hookform/resolvers/yup';

/**
 * タグ管理 詳細 /admin/user_tags/:id
 * @author s2 masa
 */
export const AdminUserTagsDetail = (): React.ReactElement => {
  // ====================================================================================================
  //
  // Hooks
  //
  // ====================================================================================================
  const params: { id: string } = useParams();
  const { userTagId, getUserTagId, patchUserTagId } = useUserTag();

  // ====================================================================================================
  //
  // RHF
  //
  // ====================================================================================================
  const schema = yup.object().shape({
    name: YUP_VALIDATION.required,
    path: YUP_VALIDATION.path,
  });

  const methods = useForm<UserTagPatchParam>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = methods;

  // ====================================================================================================
  //
  // Events
  //
  // ====================================================================================================
  const onSubmit: SubmitHandler<UserTagPatchParam> = (data) => {
    patchUserTagId(params.id, data);
  };

  // データを取得した後にRHFに値をsetValueする
  const setDefaultValues = (res: UserTagPatchParam) => {
    (Object.keys(res) as (keyof UserTagPatchParam)[]).forEach((v) => {
      if (v === 'active') {
        methods.setValue(v, !!res?.[v]);
      } else {
        methods.setValue(v, res?.[v]);
      }
    });
  };
  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    // 一覧取得
    getUserTagId(params.id);
  }, []);

  useEffect(() => {
    if (userTagId) setDefaultValues(userTagId);
  }, [userTagId]);

  return (
    <AdminPage header={<HeaderTitle title="タグ管理" />}>
      <>
        <div className="h-48 flex items-center mb-24">
          <BaseButton theme="link" iconName="Arrow_Left" size="s" href="/admin/user_tags/">
            一覧へ戻る
          </BaseButton>
        </div>
        <section>
          <FormProvider {...methods}>
            <form className="pb-48 w-624 mx-auto" onSubmit={handleSubmit(onSubmit)}>
              <FormContainerTextfield name="active" className="hidden" />
              <div className="mbx-utils-stack-v--16">
                <FormLayoutFieldset>
                  <FormLabel label="タグ名" type="small" />
                  <FormContainerTextfield
                    name="name"
                    className="pb-6"
                    placeholder={'例）期間限定'}
                  />
                </FormLayoutFieldset>

                <FormLayoutFieldset>
                  <FormLabel label="検索結果URL" type="small" />
                  <FormContainerTextfield
                    name="path"
                    className="pb-6"
                    placeholder={'例）limited'}
                  />
                </FormLayoutFieldset>
              </div>

              <div className="mt-48 space-x-16 flex justify-center">
                <div className="w-146">
                  <BaseButton
                    className="w-full"
                    size="m"
                    theme="secondary"
                    type="button"
                    href="/admin/user_tags/"
                  >
                    キャンセル
                  </BaseButton>
                </div>
                <div className="w-146">
                  <BaseButton
                    className="w-full"
                    size="m"
                    theme="primary"
                    type="submit"
                    disabled={!methods.formState.isValid}
                  >
                    保存する
                  </BaseButton>
                </div>
              </div>
            </form>
          </FormProvider>
        </section>
      </>
    </AdminPage>
  );
};
