import React from 'react';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { DataLayerType } from '@/hooksDirect/common/useDataLayer';
import { useCompanyUsers } from '@/hooksDirect/useCompanyUsers';
import { TFilterValues } from '@/pagesDirect/CompanyUsers/Index';

type TProps = {
  showModal: boolean;
  matchboxId: string;
  setUserDeleteState: React.Dispatch<
    React.SetStateAction<{
      matchbox_id: string;
      showModal: boolean;
    }>
  >;

  filterValues: TFilterValues;
  getCompanyUsers: (request: TFilterValues) => Promise<void>;
};

/**
 * 社内ユーザー削除処理
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A66109
 */
export const DirectUsersDeleteUserComponent = ({
  showModal,
  matchboxId,
  setUserDeleteState,
  filterValues,
  getCompanyUsers,
}: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const { deleteCompanyUserId } = useCompanyUsers();
  const { push } = React.useContext(DataLayerContext);
  const dataLayer: DataLayerType = {
    event: 'pageView',
    actionType: 'page_view',
    actionName: 'modal_open',
    virtualPageName: 'delete-user',
  };

  // ====================================================================================================
  //
  // methods
  //
  // ====================================================================================================
  const onClose: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.preventDefault();
    setUserDeleteState({ matchbox_id: '', showModal: false });
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    (async () => {
      await deleteCompanyUserId(matchboxId);
      await getCompanyUsers({
        user_name: filterValues.user_name,
        mail: filterValues.mail,
        group: filterValues.group,
        user_type: filterValues.user_type,
      });
      setUserDeleteState({ matchbox_id: '', showModal: false });
      push({
        event: 'deleteUser',
        actionType: 'delete_user',
        actionName: 'delete_user',
      });
    })();
  };
  return (
    <ModalDefault
      isOpen={showModal}
      onClose={onClose}
      className="w-624 rounded-modalContainer"
      dataLayer={dataLayer}
    >
      <section className="py-80">
        <h1 className="text-subhead_1 mb-8">このユーザーのアカウントを削除しますか？</h1>
        <form className="px-80" onSubmit={onSubmit}>
          <div className="flex justify-center mt-24">
            <div className="w-146 mr-8">
              <BaseButton size="m" theme="secondary" className="w-full" onClick={onClose}>
                キャンセル
              </BaseButton>
            </div>
            <div className="w-146 ml-8">
              <BaseButton size="m" theme="primary" className="w-full" type="submit">
                削除する
              </BaseButton>
            </div>
          </div>
        </form>
      </section>
    </ModalDefault>
  );
};
