import cn from 'classnames';
import { LocationDescriptor } from 'history';
import React from 'react';
import { Link } from 'react-router-dom';

type TProps = {
  to?: LocationDescriptor<unknown>;
  children: string;
  disabled?: boolean;
  update?: boolean;
  bold?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

export const UserItem = ({
  to,
  children,
  disabled,
  update,
  bold,
  onClick,
}: TProps): React.ReactElement => {
  const props = {
    ...(onClick && { onClick: onClick }),
  };

  return (
    <section className={cn('mbx-user-item', { disabled, bold })}>
      {to ? (
        <Link to={to} {...props}>
          <span className={cn({ update })}>{children}</span>
        </Link>
      ) : (
        <a {...props}>
          <span className={cn({ update })}>{children}</span>
        </a>
      )}
    </section>
  );
};
