import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { Pager } from '@/componentsDirect/Parts/Navigation/Page/Pager';
import { BlockCompaniesPatchParam, BlockCompany } from '@/utils/api-client';

type Props = {
  total: number;
  companies: BlockCompany[];
  blockCompanies: BlockCompany[];
  page: number;
  pageLength: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  onSubmit: (payload: BlockCompaniesPatchParam) => void;
  pageList: number[];
  setPageList: React.Dispatch<React.SetStateAction<number[]>>;
};

type TCheckBox = {
  [key: string]: boolean;
};

export const CompanyCheckForm = (props: Props): React.ReactElement => {
  const [defaultValue, setDefault] = useState<TCheckBox>({ test: false });
  const [checkedBox, setCheckedBox] = useState<string[]>([]);

  useEffect(() => {
    if (!props.companies) return;
    const obj = Object.assign(
      {},
      ...props.companies.map((item) => ({
        [item.id]: false,
      }))
    );
    setDefault(obj);
  }, [props.companies]);

  const methods = useForm<TCheckBox>({
    defaultValues: defaultValue,
  });

  const { handleSubmit, watch } = methods;

  const checked = (id: string) => {
    if (!watch(id)) return false;
    return watch(id);
  };

  const onChange = (id: string, checked: boolean) => {
    const box = checkedBox;
    if (checked) box?.push(id);
    if (!checked) {
      const index = box.indexOf(id);
      box.splice(index, 1);
    }
    setCheckedBox(box);
  };

  const onBlock = async (data: TCheckBox) => {
    const payload: BlockCompaniesPatchParam = {
      ids: Object.keys(data).filter((key) => data[key] === true),
    };

    const defaultBlock = props.blockCompanies.map((b) => {
      return b.id;
    });
    for (let i = 0; i < defaultBlock.length; i++) {
      payload?.ids?.push(defaultBlock[i]);
    }
    props.onSubmit(payload);
  };

  return (
    <section className="text-left">
      {props.total === 0 ? (
        <p className="mbx-typography--body_2 mt-24">0 / 0件</p>
      ) : (
        <p className="mbx-typography--body_2 mt-24">
          {props.page === 0 ? props.page + 1 : props.page + '1'}〜
          {props.page === props.pageLength - 1 ? props.total : props.page + 1 + '0'} / {props.total}
          件
        </p>
      )}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => onBlock(data))}>
          {props.companies &&
            props.companies.map((company, index) => {
              return (
                <div key={index}>
                  <label
                    className="mbx-formCheckbox relative"
                    onChange={() => onChange(company.id.toString(), checked(company.id.toString()))}
                  >
                    <input
                      type="checkbox"
                      checked={checked(company.id.toString())}
                      {...methods.register(company.id.toString())}
                    />
                    <span className="text-body_1 py-12 pl-32">
                      {company.name}
                      <i className="mbx-icon mbx-icon-Check"></i>
                    </span>
                  </label>
                </div>
              );
            })}
          {props.total !== 0 && (
            <Pager pageLength={props.pageLength} page={props.page} setPage={props.setPage} setPageList={props.setPageList} pageList={props.pageList} />
          )}
          <div className="w-308 m-auto mt-28">
            <BaseButton
              theme="primary"
              size="m"
              className="w-full"
              disabled={checkedBox.length === 0}
            >
              チェックした企業を紐付ける
            </BaseButton>
          </div>
        </form>
      </FormProvider>
    </section>
  );
};
