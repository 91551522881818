import '../../styles/common/header.scss';

import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import ImgLogo from '@/assets/images/common/logo.svg';
import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { UserMenu } from '@/components/common/Navigation/User/Menu';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { isUrlString } from '@/utils/conditions';

export type MenuField = {
  title: string;
  target?: '_self' | '_blank';
  url: string;
};

type Props = {
  menu: MenuField[];
  headerMenu: MenuField[];
  userIcon?: string;
  isAuth?: boolean;
  badge: boolean;
  children?: React.ReactElement;
};

function Header(props: Props): JSX.Element {
  const menuRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<HTMLDivElement>(null);
  const spIconRef = useRef<HTMLTableSectionElement>(null);
  const [toggle, setToggle] = useState<boolean>(false);
  const [sp, setSp] = useState<boolean>(false);
  const mq = useMBXMediaQuery();
  const onClick = () => {
    setToggle(!toggle);
  };

  const handleClickEvent = (e: { target: EventTarget | null }) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target as Node) &&
      iconRef.current &&
      !iconRef.current.contains(e.target as Node) &&
      spIconRef.current &&
      !spIconRef.current.contains(e.target as Node)
    ) {
      setToggle(false);
    }
  };

  useEffect(() => {
    setSp(mq.sm);
    document.addEventListener('click', handleClickEvent);
    return () => {
      document.removeEventListener('click', handleClickEvent);
    };
  }, []);

  return (
    <header className="header">
      <div className="header-inner">
        <div className="logo">
          <Link to="/">
            <img src={ImgLogo} alt="" />
          </Link>
        </div>
        <section ref={spIconRef}>
          <ButtonIconButton
            type="gray"
            size="large"
            iconName={toggle ? 'Menu-close' : 'Menu'}
            onClick={onClick}
          />
        </section>
        <nav>
          <ul>
            {props.headerMenu.map((item: MenuField, index: number) => {
              return (
                <li key={index}>
                  {isUrlString(item.url) ? (
                    <a href={item.url} target={item.target}>
                      {item.title}
                    </a>
                  ) : (
                    <Link to={item.url}>{item.title}</Link>
                  )}
                </li>
              );
            })}
            {props.children && <li>{props.children}</li>}
          </ul>
          {props.isAuth && (
            <div className="profile_icon mkt_mask_items" onClick={onClick} ref={iconRef}>
              {props.userIcon ? (
                <img src={props.userIcon} alt="" />
              ) : (
                <img src="/images/components/other_components/Circle-placeholder.png" alt="" />
              )}
              {props.badge ? <div className="red_circle"></div> : null}
            </div>
          )}
        </nav>
      </div>
      <div ref={menuRef}>
        <CSSTransition in={toggle} timeout={550}>
          <UserMenu menu={props.menu} {...{ sp }} open={toggle} setToggle={setToggle} />
        </CSSTransition>
      </div>
    </header>
  );
}

export default Header;
