import '@/styles/common/Portfolio/navigation.scss';

import React from 'react';

import { TabNavigation } from '@/components/common/Navigation/TabNavigation';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';

type Menu = {
  title: string;
  url: string;
};

type Props = {
  menu: Menu[];
  activeIndex: number;
};

function PortfolioNavigation(props: Props): React.ReactElement {
  const mq = useMBXMediaQuery();

  return (
    <TabNavigation
      menu={props.menu}
      activeIndex={props.activeIndex}
      className="portfolio-navigation"
      itemClassName={mq.sm ? '' : 'lg:w-160 md:w-160'}
      align={mq.sm ? 'fit' : 'right'}
    />
  );
}

export default PortfolioNavigation;
