import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { MessageForm } from '@/components/common/Admin/UserDetail/MessageForm';
import { AdminNoteMessage } from '@/componentsAdmin/Company/Notes/Message';
import { useWindowSize } from '@/hooks/common/useWindowSize';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import {
    ADMINApi, AdminNoteModel, AdminNotePatchParam, AdminNotePostParam, ResponseError
} from '@/utils/api-client';

export const CompanyNotes = (): React.ReactElement => {
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const [height, setHeight] = useState<number>(0);
  const params: { company_id: string } = useParams();
  const [notes, setNotes] = useState<AdminNoteModel[]>();
  const [comment, setComment] = useState<string>('');

  useEffect(() => {
    (async () => {
      const note = await new ADMINApi().getAdminNotes(Number(params.company_id));
      setNotes(note.data.notes);
      if (note.data.notes) {
        onIsRead(note.data.notes);
      }
    })();
  }, []);

  useEffect(() => {
    setHeight(windowSize.height - 470);
  }, [windowSize]);

  const getNote = async () => {
    const note = await new ADMINApi().getAdminNotes(Number(params.company_id));
    setNotes(note.data.notes);
  };

  const onChange = (comment: string) => {
    setComment(comment);
  };

  const onSend = async () => {
    const data: AdminNotePostParam = {
      content: comment,
      company_id: Number(params.company_id),
    };
    dispatch(toggleLoading(true));
    try {
      await new ADMINApi().postAdminNote(data);
      await getNote();
      setComment('');
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };
  const onRemove = async (id: number) => {
    dispatch(toggleLoading(true));
    try {
      await new ADMINApi().deleteAdminNote(id);
      await getNote();
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };
  const onIsRead = (notes: AdminNoteModel[]) => {
    const result = notes.filter((n) => !n.is_read);
    const ids = result.map((r) => {
      return r.id;
    });
    ids.forEach(async (id) => {
      const data: AdminNotePatchParam = {
        id: id,
        is_read: true,
      };
      await new ADMINApi().patchAdminNote(data);
    });
  };
  return (
    <section className="w-624 m-auto">
      <section style={{ height }} className="overflow-scroll">
        <ul>
          {notes?.map((note, index) => {
            return (
              <div key={index}>
                <AdminNoteMessage note={note} onRemove={onRemove} />
              </div>
            );
          })}
        </ul>
      </section>
      <MessageForm comment={comment} onSend={onSend} onChange={onChange} />
    </section>
  );
};
