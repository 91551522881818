import '@/styles/common/Navigation/user-item.scss';

import cn from 'classnames';
import { LocationDescriptor } from 'history';
import React from 'react';
import { Link } from 'react-router-dom';

import { isUrlString } from '@/utils/conditions';

type TProps = {
  to?: LocationDescriptor<unknown>;
  children: string;
  disabled?: boolean;
  update?: boolean;
  bold?: boolean;
  target?: '_self' | '_blank';
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

export const UserItem = ({
  to,
  children,
  disabled,
  update,
  bold,
  target,
  onClick,
}: TProps): React.ReactElement => {
  const props = {
    ...(onClick && { onClick: onClick }),
  };

  return (
    <section className={cn('mbx-user-item', { disabled, bold })}>
      {to ? (
        typeof to === 'string' && isUrlString(to) ? (
          <a href={to} target={target}>
            <span className={cn({ update })}>{children}</span>
          </a>
        ) : (
          <Link to={to} {...props}>
            <span className={cn({ update })}>{children}</span>
          </Link>
        )
      ) : (
        <a {...props}>
          <span className={cn({ update })}>{children}</span>
        </a>
      )}
    </section>
  );
};
