import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { NotificationError } from '@/componentsDirect/Parts/Notification/Error';
import { RootState } from '@/reduxAdmin';
import { notificationError } from '@/reduxAdmin/modules/utils';

/**
 * global stateの値検知でエラー表示をする汎用エラー
 *
 * 仕様 :
 * - メッセージが表示されてから4秒後に自動非表示
 */
export const GlobalNotificationErrorPortal = (): React.ReactElement => {
  const isFirstRun = useRef(true);

  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const errorMessage: string | null = useSelector((state: RootState) => state.utils.errorMessage);

  const { push } = useContext(DataLayerContext);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (errorMessage) {
      setShow(true);
      push({
        event: 'errorMessage',
        actionType: 'error_message',
        actionName: errorMessage.replace(/\r?\n/g, ' '),
      });
    }
  }, [errorMessage]);

  /**
   * メッセージが表示されてから4秒後に自動非表示
   */
  const onEnter = () => {
    _.delay(onClick, 4000);
  };

  const onExited = () => {
    dispatch(notificationError(null));
  };

  const onClick = () => {
    setShow(false);
  };

  return (
    <div className="sticky bottom-0 z-300">
      <NotificationError onClick={onClick} show={show} onEnter={onEnter} onExited={onExited}>
        {errorMessage}
      </NotificationError>
    </div>
  );
};
