import { AxiosError, AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  searchConditionNumberProperties,
  searchConditionPeriodsProperties,
} from '@/hooksDirect/useSearchConditionValidation';
import { RootState } from '@/reduxAdmin';
import { setSearchCondition } from '@/reduxAdmin/modules/directSearchCondition';
import {
  forceLoadingOff,
  forceLoadingOn,
  notificationError,
  notificationSuccess,
  toggleLoading,
} from '@/reduxAdmin/modules/utils';
import {
  CommonSCMcExperiencedJobObjectModel,
  DirectApi,
  DirectSearchConditionModel,
  DirectSearchConditionRequest,
  DirectSearchConditionResponse,
  DirectSearchUserRequest,
  DirectSearchUsersResponse,
  Pagination,
  ResponseError,
} from '@/utils/api-client';
import {
  conversionStringsLikeNumbers,
  conversionValuesForPeriods,
  reduceExperiencedJobArray,
} from '@/utils/utils';

// import { testSearchResultData } from '@/debug/directSearchResult';

type TReturn = {
  searchCondition: DirectSearchConditionModel | null; // フォームの初期値 これと比較して変更があったかどうか判定
  saveSearchCondition: (condition: DirectSearchConditionModel) => void; // 検索条件を一時保存
  fetchSearchUsers: (
    query: Pagination
  ) => Promise<AxiosResponse<DirectSearchUsersResponse> | undefined>; // ユーザー検索
  searchUsersResult: DirectSearchUsersResponse | undefined;
  postDirectSearchCondition: (search_condition_name: string) => Promise<void>;
  getSearchCondition: (idPathParam: number, recommended?: boolean) => Promise<void>;
  patchSearchCondition: (
    idPathParam: number,
    request: DirectSearchConditionRequest
  ) => Promise<void>;
  searchConditionResult: DirectSearchConditionResponse | undefined;
  saveTest: (query: DirectSearchConditionRequest) => Promise<void>; // 保存テスト用
  getListTest: () => Promise<void>; // 保存リストテスト用
  listTest: DirectSearchConditionResponse[];
  // setTestSearchResultData: () => void;
};

/**
 * 検索条件の一時保存及び保存された情報の取得
 */
export const useSearchCondition = (): TReturn => {
  const API = new DirectApi();
  const dispatch = useDispatch();

  const [searchUsersResult, setSearchUsersResult] = useState<DirectSearchUsersResponse>();
  const [searchConditionResult, setSearchConditionResult] =
    useState<DirectSearchConditionResponse>();

  const searchCondition = useSelector(
    (state: RootState) => state.direct_search_condition.condition
  );

  /**
   * 検索条件を整形
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formatterSearchConditionData = (condition: any): any => {
    let convertValue = conversionValuesForPeriods(
      condition as Record<string, unknown>,
      searchConditionPeriodsProperties
    );

    convertValue = conversionStringsLikeNumbers(convertValue, searchConditionNumberProperties);
    convertValue.mc_experienced_job = reduceExperiencedJobArray(
      convertValue.mc_experienced_job as Array<CommonSCMcExperiencedJobObjectModel>
    );
    return convertValue;
  };

  /**
   * 検索条件を整形して一時保存
   */
  const saveSearchCondition = (condition: DirectSearchConditionModel) => {
    dispatch(setSearchCondition(formatterSearchConditionData(condition)));
  };

  /**
   * 検索条件を拡張する
   * offer_statusの内容によって企業側の表示結果を拡張する
   * https://github.com/prtytokyo/matchbox-2021-web-frontend/issues/1268
   */
  const expandSearchCondition = (data: DirectSearchConditionModel): DirectSearchConditionModel => {
    const condition = cloneDeep(data);
    const status = condition.offer_status
      ?.filter((s) => s !== null && s !== undefined)
      .map((s) => s.toString());
    if (condition.offer_status && status?.includes('3')) {
      condition.offer_status.push(6, 7);
    }
    if (condition.offer_status && status?.includes('4')) {
      condition.offer_status.push(5);
    }

    return {
      ...condition,
      offer_status: condition.offer_status?.filter((s) => s !== undefined),
    };
  };

  /**
   * ユーザー検索
   */
  const fetchSearchUsers = async (
    query: Pagination
  ): Promise<AxiosResponse<DirectSearchUsersResponse> | undefined> => {
    const searchQuery: DirectSearchUserRequest = {
      limit: query.limit,
      offset: query.offset,
      sort_by: query.sort_by,
      sort_order: query.sort_order,
      conditions: expandSearchCondition(searchCondition as DirectSearchConditionModel),
    };
    // 求職者検索時に他のfetch処理が複数走っていた場合、早く終わったやつのloadingがfalseになるが、これの処理が一番時間かかるのでこれが終わるまでは強制的にローディングを表示するようにする。
    dispatch(forceLoadingOn());
    dispatch(toggleLoading(true));
    try {
      const res: AxiosResponse<DirectSearchUsersResponse> = await API.postDirectSearchUsers(
        searchQuery
      );
      setSearchUsersResult(res.data);
      dispatch(forceLoadingOff());
      dispatch(toggleLoading(false));
      return res;
    } catch (error) {
      dispatch(forceLoadingOff());
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    }
    dispatch(toggleLoading(false));
  };

  /**
   * ユーザー検索条件の保存
   */
  const postDirectSearchCondition = (search_condition_name: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const params: DirectSearchConditionRequest = {
        search_condition_name,
        ...searchCondition,
      } as DirectSearchConditionRequest;

      params.search_condition_name = search_condition_name;

      dispatch(toggleLoading(true));
      API.postDirectSearchCondition(params)
        .then(() => {
          dispatch(notificationSuccess('検索条件を保存しました'));
          resolve();
        })
        .catch((error: AxiosError<ResponseError>) => {
          if (error.response) dispatch(notificationError(error.response.data.error_message));
          reject();
        })
        .finally(() => {
          dispatch(toggleLoading(false));
        });
    });
  };

  /**
   * 検索条件詳細
   *
   * おすすめ条件の場合はパラメーター追加
   */
  const getSearchCondition = (idPathParam: number, recommended = false) => {
    dispatch(toggleLoading(true));
    return API.getDirectSearchConditionId(idPathParam, recommended)
      .then((res) => {
        setSearchConditionResult(res.data);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 検索条件詳細
   */
  const patchSearchCondition = (idPathParam: number, request: DirectSearchConditionRequest) => {
    dispatch(toggleLoading(true));

    return API.patchDirectSearchConditionId(
      idPathParam,
      formatterSearchConditionData(request) as DirectSearchConditionRequest
    )
      .then((res) => {
        console.log(res);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 保存処理テスト用
   */
  const saveTest = (query: DirectSearchConditionRequest) => {
    return API.postDirectSearchCondition(query)
      .then((res) => {
        console.log(res);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      });
  };

  /**
   * 保存一覧テスト用
   */
  const [listTest, setListTest] = useState<DirectSearchConditionResponse[]>([]);
  const getListTest = () => {
    return API.getDirectSearchConditions()
      .then((res) => {
        console.log(res);
        setListTest(res.data.conditions);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      });
  };

  // 暫定的に仮データを設定
  // const setTestSearchResultData = () => {
  //   setSearchUsersResult({
  //     users: testSearchResultData(),
  //     offset: 0,
  //     total: 100,
  //     result: 'success',
  //   });
  // };

  return {
    searchCondition,
    saveSearchCondition,
    fetchSearchUsers,
    searchUsersResult,
    getSearchCondition,
    patchSearchCondition,
    searchConditionResult,
    saveTest,
    getListTest,
    listTest,
    postDirectSearchCondition,
    // setTestSearchResultData,
  };
};
