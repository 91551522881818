import { AxiosError, AxiosResponse } from 'axios';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import {
  FormCheckboxItem,
  FormContainerDropdown,
  FormContainerDropdownMultiple,
  FormContainerTextArea,
  FormLabel,
  FormLayoutFieldset,
} from '@/componentsDirect/Parts/Form/';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { FORM_OPTIONS, TFormOption } from '@/definition/FORM_OPTIONS';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { useCompanyUserGroups } from '@/hooksDirect/useCompanyUserGroups';
import { useCompanyUsers } from '@/hooksDirect/useCompanyUsers';
import { useOfferPositions } from '@/hooksDirect/useOfferPositions';
import { RootState } from '@/reduxAdmin';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import { CandidateData } from '@/types/DataLayerType';
import {
  DirectApi,
  DirectUserResponse,
  OfferPostParam,
  OfferResponse,
  ReactionPostParam,
  ReactionPostParamNotifyTypeEnum,
  ResponseError,
} from '@/utils/api-client';
import { getDirectOptionChildrenFromValue } from '@/utils/optionValueToChildren';
import { noDataText } from '@/utils/string';
import { yupResolver } from '@hookform/resolvers/yup';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type TProps = {
  modalIsOpen: boolean;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  matchboxId: string;
  asyncCallback?: () => Promise<void>;
  additionalData?: CandidateData;
  doneOffer: (offerId: number) => void;
};

type TInput = {
  rate: string;
  reaction_position: string;
  status: string;
  content: string;
  notify_all: boolean;
  notify_member: Array<string>;
  notify_group: Array<string>;
};

/**
 * ピックアップモーダル
 * https://www.figma.com/file/U3CKCcmEc7eIjxkbYoCZx7/UI?type=design&node-id=4694-65510&mode=dev
 */
export const PickUpModal = ({
  modalIsOpen,
  setModalIsOpen,
  matchboxId,
  asyncCallback,
  additionalData,
  doneOffer,
}: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // HOOKS
  //
  // ====================================================================================================
  const { offerPositions, getOfferPositions } = useOfferPositions();
  const { push } = React.useContext(DataLayerContext);
  const { companyUserGroups, getCompanyUserGroups } = useCompanyUserGroups();
  const { companyUsers, getCompanyUsers } = useCompanyUsers();

  // ====================================================================================================
  //
  // REDUX
  //
  // ====================================================================================================
  const dispatch = useDispatch();
  const user: DirectUserResponse | null = useSelector(
    (state: RootState) => state.direct_auth.userInfo
  );

  // ====================================================================================================
  //
  // RHF
  //
  // ====================================================================================================
  const schema = yup.object().shape({
    rate: YUP_VALIDATION.required,
    status: YUP_VALIDATION.required,
  });
  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      rate: '',
      reaction_position: '',
      status: '',
      content: '',
      notify_all: false,
      notify_member: [],
      notify_group: [],
    },
  });

  const { handleSubmit } = methods;

  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [offerPositionsOPTIONS, setOfferPositionsOPTIONS] = useState<TFormOption[]>([]);

  // ====================================================================================================
  //
  // EVENTS
  //
  // ====================================================================================================
  const sendOfferAndReaction = async (data: TInput) => {
    if (!matchboxId) return;
    const postParam: OfferPostParam = {
      matchbox_id: matchboxId,
      rate: Number(data.rate),
      reaction_position: Number(data.reaction_position),
    };
    dispatch(toggleLoading(true));
    try {
      const offerRes: AxiosResponse<OfferResponse> = await new DirectApi().postDirectOffer(
        postParam
      );
      if (!offerRes.data.matchbox_id || !offerRes.data.id) return;
      const reactionPostParam: ReactionPostParam = {
        matchbox_id: offerRes.data.matchbox_id,
        offer_id: offerRes.data.id,
        content: data.content,
        status: Number(data.status),
        notify_type: 'offer' as ReactionPostParamNotifyTypeEnum,
        notify_all: data.notify_all,
        notify_member: data.notify_member,
        notify_group: data.notify_group,
      };
      await new DirectApi().postDirectReaction(reactionPostParam);
      setModalIsOpen(false);
      const status = getDirectOptionChildrenFromValue('reactionOptions', parseInt(data.status));
      const rate = getDirectOptionChildrenFromValue('offer_mw_status', parseInt(data.rate));
      push({
        event: 'candidatePickup',
        actionType: 'candidate_pickup',
        actionName: `${rate}::${status}`,
        candidates: additionalData ? [additionalData] : [],
      });
      doneOffer(offerRes.data.id);
      if (asyncCallback) await asyncCallback();
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    getCompanyUserGroups();
    getCompanyUsers({});
    const bodyEl = document.getElementsByTagName('body');
    if (modalIsOpen) {
      bodyEl[0].classList.add('overflow-hidden');
    } else {
      bodyEl[0].classList.remove('overflow-hidden');
      methods.setValue('rate', '');
      methods.setValue('status', '');
      methods.setValue('content', '');
      methods.setValue('notify_all', false);
      methods.setValue('notify_member', []);
      methods.setValue('notify_group', []);
    }
  }, [modalIsOpen]);

  useEffect(() => {
    // ポジション一覧をOPTIONに変換
    if (offerPositions && offerPositions.length > 0) {
      // activeなポジションのみ
      const activeOfferPositions = offerPositions.filter((position) => position.active);

      const reactionOptions = activeOfferPositions.map((position) => ({
        value: position.id + '',
        children: position.name,
      }));

      setOfferPositionsOPTIONS(reactionOptions);
    }
  }, [offerPositions]);

  useEffect(() => {
    // 一覧取得
    getOfferPositions();
  }, []);

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const userGroupOptions = useMemo(() => {
    if (!companyUserGroups) return [];
    const options = companyUserGroups?.map((v) => {
      return {
        value: v.id.toString(),
        children: v.name,
      };
    });

    return options as TFormOption[];
  }, [companyUserGroups]);

  const userOptions = useMemo(() => {
    if (!companyUsers) return [];
    const options = companyUsers?.map((v) => {
      // 自分と同じユーザーは除外
      if (v.matchbox_id === user?.user_info.matchbox_id) return {};
      return {
        value: v.matchbox_id,
        children: `${noDataText(v.name)} (${v.email})`,
      };
    });

    return options?.filter((v) => v.value) as TFormOption[];
  }, [companyUsers]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <ModalDefault
      isOpen={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      className="mbx-direct-users-detail-modal"
      dataLayer={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'modal_open',
        virtualPageName: 'pickup',
        candidates: additionalData ? [additionalData] : [],
      }}
    >
      <div className="w-624 p-80 text-left">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit((data) => sendOfferAndReaction(data))}>
            <h2 className="text-center text-16_24 font-bold mb-30">求職者をピックアップ</h2>

            <div className="mb-38">
              <h3 className="text-12_16 font-bold mb-8 mbx-status_clients__title">
                マッチ度を設定
                {/* <span className="text-9_16 font-normal">(任意)</span> */}
                <i className="mbx-icon mbx-icon-Info" />
                <div className="mbx-admin_tooltip" style={{ top: 40, left: 0 }}>
                  オファー時、マッチ度は求職者（候補者）に開示されません。
                </div>
              </h3>

              <FormContainerDropdown
                className="w-160"
                name="rate"
                placeholder="マッチ度を選択"
                selectList={FORM_OPTIONS.offer_rate}
              />
            </div>

            <div className="mb-32">
              <h3 className="text-12_16 font-bold mbx-status_clients__title">
                意見を投稿
                {/* <span className="text-9_16 font-normal">(任意)</span> */}
                <i className="mbx-icon mbx-icon-Info" />
                <div className="mbx-admin_tooltip" style={{ top: 40, left: 0 }}>
                  オファー時、あなたの意見の投稿は求職者（候補者）に開示されません。
                </div>
              </h3>

              <p className="my-8 text-11_20">この求職者に対するあなたの意見を投稿しましょう。</p>

              <FormContainerDropdown
                className="w-240 my-8"
                name="status"
                placeholder="希望するアクションを選択"
                selectList={FORM_OPTIONS.reaction}
              />

              <p className="my-8 text-11_20">
                社内メンバーに共有したいことを投稿しましょう。最終的に求職者（候補者）へオファーするかどうか判断材料になります。
              </p>

              <FormContainerTextArea
                placeholder="例）経験豊富な候補者です。オファーし、実際に会って話を聞いたみたいと思いますが、いかがでしょうか？"
                maxLength={300}
                name="content"
                className="my-8"
              />
            </div>

            <div className="mb-32">
              <p className="mbx-typography--caption_1 text-left">
                <span>
                  紹介するポジションを選択してください。オファー時、候補者に開示されます。
                </span>
              </p>
              <FormContainerDropdown
                name="reaction_position"
                className="select mt-8"
                placeholder="ポジションを選択"
                selectList={offerPositionsOPTIONS}
              />
            </div>

            <div className="mb-48">
              <FormCheckboxItem
                label="社内メンバー全員に通知する"
                name="notify_all"
                className="mb-32 w-fit"
                onChange={(e) => {
                  methods.setValue('notify_all', e.target.checked);
                }}
              />
              {!methods.watch('notify_all') && (
                <>
                  <FormLayoutFieldset className="mb-32">
                    <FormLabel label="通知ユーザーを選択" type="small" />
                    <FormContainerDropdownMultiple
                      name="notify_member"
                      placeholder="選択してください（複数選択可）"
                      selectList={userOptions}
                    />
                  </FormLayoutFieldset>
                  <FormLayoutFieldset>
                    <FormLabel label="通知グループを選択" type="small" />
                    <FormContainerDropdownMultiple
                      name="notify_group"
                      placeholder="選択してください（複数選択可）"
                      selectList={userGroupOptions}
                    />
                  </FormLayoutFieldset>
                </>
              )}
            </div>

            <div className="flex justify-between space-x-16">
              <BaseButton
                className="flex-1"
                size="m"
                theme="secondary"
                type="button"
                onClick={() => setModalIsOpen(false)}
              >
                キャンセル
              </BaseButton>
              <BaseButton className="flex-1" size="m" theme="primary" type="submit">
                この内容でピックアップ
              </BaseButton>
            </div>
          </form>
        </FormProvider>
      </div>
    </ModalDefault>
  );
};
