import React from 'react';
import { Link } from 'react-router-dom';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';

type careerInterviewField = {
  id: string;
  num: string;
  job: string;
  name: string;
  text: string;
  img: string;
};

type Props = {
  list: careerInterviewField[];
  title: string;
};

export const InterviewList = (props: Props): React.ReactElement => {
  const mq = useMBXMediaQuery();
  return (
    <section className="interview sm:mb-188">
      <p className="interview-title">{props.title}</p>
      <p className="interview-subtitle">Career Interview</p>
      <div className="interview-list">
        {props.list.map((item, index: number) => {
          return (
            <Link to={'../interview/' + item.id} key={index}>
              <div className="list">
                {!mq.sm ? <img src={item.img} alt="" /> : <img src={item.img} alt="" />}
                <p className="profile">
                  <span className="num">
                    <span>{item.num.toString().length === 1 ? '0' : null}</span>
                    <span>{item.num}.</span>
                  </span>
                  <span className="job">{item.job}</span>
                  <span className="name">{item.name}</span>
                </p>
                <p className="question">{item.text}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </section>
  );
};
