import '@/styles/common/Portfolio/MainVisual/embedded_link.scss';

import React, { useEffect, useMemo } from 'react';
import ReactPlayer from 'react-player';

type Service = 'youtube' | 'vimeo' | 'soundcloud' | 'slideshare' | 'sketchfab' | 'tiktok' | 'tumblr' | 'pinterest' | null;

export function Player({
  width,
  height,
  src,
}: {
  width: number;
  height: number;
  src: string | undefined;
}): React.ReactElement {
  const source: URL | undefined = useMemo(() => {
    return src ? new URL(src) : undefined;
  }, [src]);

  const type: Service = useMemo(() => {
    if (source?.host.includes('youtube.com')) {
      return 'youtube';
    } else if (src?.includes('vimeo.com')) {
      return 'vimeo';
    } else if (src?.includes('w.soundcloud.com')) {
      return 'soundcloud';
    } else if (src?.includes('slideshare.net')) {
      return 'slideshare';
    } else if (src?.includes('sketchfab.com')) {
      return 'sketchfab';
    } else if (src?.includes('www.tiktok.com')) {
      return 'tiktok';
    } else if (src?.includes('tumblr.com')) {
      return "tumblr"
    } else if (src?.includes('pinterest.com')) {
      return "pinterest"
    }
    return null;
  }, [source]);

  useEffect(() => {
    if (type !== 'slideshare') return;
    (async () => {
      // TODO: corsエラー
      // const res = await get(`https://www.slideshare.net/api/oembed/2?url=${props.src}&format=json`)
      // setSlideShareId(res)
    })();
  }, [type]);

  switch (type) {
    case 'slideshare':
      return (
        <div className="mkt_mask_items">
          <iframe
            src={src}
            width={width}
            height={height}
            frameBorder={0}
            marginWidth={0}
            marginHeight={0}
            scrolling="no"
            style={{
              border: '1px solid #CCC',
              borderWidth: '1px',
              marginBottom: '5px',
              maxWidth: '100%',
            }}
            allowFullScreen
          />
        </div>
      );
    case 'sketchfab':
      return (
        <div className="sketchfab-embed-wrapper mkt_mask_items" style={{ width, height }}>
          <iframe width="100%" height="100%" frameBorder={0} allowFullScreen src={src} />
        </div>
      );
    case 'soundcloud':
      return (
        <iframe
          className="mkt_mask_items"
          width="100%"
          height="100%"
          scrolling="no"
          frameBorder={0}
          allow="autoplay"
          src={src}
        />
      );
    case 'tiktok':
      return (
        <div className="mkt_mask_items" style={{ width, height }}>
          <img src="/images/mb-no-image.jpg" alt="" width="100%" height="100%" />
        </div>
      );
    case 'tumblr':
      return (
        <div className="mkt_mask_items" style={{ width, height }}>
          <iframe width="100%" height="100%" frameBorder={0} allowFullScreen src={src} scrolling="no" />
        </div>
      );
    case 'pinterest':
      return (
        <div className="mkt_mask_items" style={{ width, height }}>
          <iframe width="100%" height="100%" frameBorder={0} allowFullScreen src={src} />
        </div>
      );
    default:
      return (
        <div className="mkt_mask_items" style={{ width, height }}>
          <ReactPlayer
            url={src}
            controls={type === 'vimeo'}
            width={`${width}px`}
            height={`${height}px`}
          />
        </div>
      );
  }
}
