import React, { useEffect, useState } from 'react';
import { FieldArrayWithId, FormProvider, useFieldArray, useForm } from 'react-hook-form';

import {
  DirectUsersAddUserGroupComponent,
  DirectUsersDeleteUserGroupComponent,
} from '@/componentsDirect/CompanyUserGroups';
import { AdminPage } from '@/componentsDirect/Layout/Page';
import {
  TableBodyUserGroupList,
  TableContainer,
  TableHeader,
} from '@/componentsDirect/Parts/Table';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { useCompanyUserGroups } from '@/hooksDirect/useCompanyUserGroups';
import { CompanyGroupSummary } from '@/utils/api-client/api';
import { moveArray } from '@/utils/utils';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

const columns = [
  {
    title: 'グループ名',
  },
  {
    title: '',
    className: 'w-104',
  },
];

type TFormValues = {
  listData: Array<CompanyGroupSummary>;
};

/**
 * 社内ユーザーグループ管理 /direct/user_groups
 * https://www.figma.com/file/U3CKCcmEc7eIjxkbYoCZx7/UI?type=design&node-id=11781-162238&mode=design&t=AFDRFHeFDLzsWY90-4
 *
 * 管理者のみ利用可能
 */
export const DirectCompanyUserGroupsIndex = (): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const mq = useMBXMediaQuery();
  const [showFiled] = useState(false);
  const [userDeleteState, setUserDeleteState] = useState({
    id: '',
    showModal: false,
  });
  const { companyUserGroups, getCompanyUserGroups, pathCompanyUserGroups } = useCompanyUserGroups();

  const methods = useForm<TFormValues>();
  const { control } = methods;
  const { fields, move } = useFieldArray({
    control,
    name: 'listData',
  });

  // ====================================================================================================
  //
  // methods
  //
  // ====================================================================================================
  /**
   * 削除処理
   */
  const onDelete = (id: string) => {
    setUserDeleteState({ id, showModal: true });
    return false;
  };

  const onSort = (result: FieldArrayWithId<TFormValues, 'listData', 'id'>[]) => {
    // idのみの配列に変換
    const request = {
      groups: result.map((v) => v.id),
    };
    pathCompanyUserGroups(request);
  };

  /**
   * DnD 並び替え
   */
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  /**
   * DnD 並び替え
   */
  const touchSensors = useSensors(
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  /**
   * DnD 並び替え
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragEnd = ({ active, over }: any) => {
    if (active.id !== over.id) {
      const oldIndex = fields.map((field) => field.id).indexOf(active.id);
      const newIndex = fields.map((field) => field.id).indexOf(over.id);
      move(oldIndex, newIndex);
      onSort(moveArray(fields, oldIndex, newIndex));
    }
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    if (companyUserGroups) methods.setValue('listData', companyUserGroups);
  }, [companyUserGroups]);

  useEffect(() => {
    // 一覧取得
    getCompanyUserGroups();
  }, []);

  return (
    <AdminPage header={<HeaderTitle title="グループ管理" />}>
      <>
        <header className="mt-48 text-center mb-48">
          <h1 className="text-body_1">
            社内ユーザーが所属するグループを作成できます。
            <br />
            求職者をピックアップした時に、グループごとに通知することが可能です。
          </h1>
        </header>
        <DirectUsersDeleteUserGroupComponent
          setUserDeleteState={setUserDeleteState}
          showModal={userDeleteState.showModal}
          getCompanyUserGroups={getCompanyUserGroups}
          id={userDeleteState.id}
          dataLayer={{
            event: 'pageView',
            actionType: 'page_view',
            actionName: 'modal_open',
            virtualPageName: 'delete-user-group',
          }}
        />
        <FormProvider {...methods}>
          <form className="pb-80">
            <TableContainer className="w-full">
              <TableHeader columns={columns}></TableHeader>
              <tbody>
                <DirectUsersAddUserGroupComponent
                  getCompanyUserGroups={getCompanyUserGroups}
                  show={showFiled}
                />

                <DndContext
                  sensors={mq.sm ? touchSensors : sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  autoScroll={false}
                >
                  <SortableContext items={fields} strategy={verticalListSortingStrategy}>
                    {fields.map((data, index) => {
                      return (
                        <TableBodyUserGroupList
                          key={index}
                          data={data}
                          onDelete={onDelete}
                        ></TableBodyUserGroupList>
                      );
                    })}
                  </SortableContext>
                </DndContext>
              </tbody>
            </TableContainer>
          </form>
        </FormProvider>
      </>
    </AdminPage>
  );
};
