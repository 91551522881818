import { useDispatch } from 'react-redux';

import { DYNAMIC_VALIDATION_MESSAGE } from '@/definition/VALIDATION_MESSAGE';
import { notificationError } from '@/redux/index';

type TReturn = {
  checkFileSize: (size: number, maxSize: number) => boolean;
};

/**
 * ファイルサイズをチェックして制限以上なら汎用エラーを表示する
 */
export const useFileLimit = (): TReturn => {
  const dispatch = useDispatch();

  const checkFileSize = (fileSize: number, maxSizeMB: number): boolean => {
    const sizeLimit = 1024 * 1024 * maxSizeMB; // 制限サイズ
    if (fileSize > sizeLimit) {
      dispatch(notificationError(DYNAMIC_VALIDATION_MESSAGE.max_file_size(maxSizeMB)));
      return false;
    } else {
      return true;
    }
  };

  return {
    checkFileSize,
  };
};
