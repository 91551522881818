import React, { useState, useEffect, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { notificationError, toggleLoading } from '@/redux/index';
import cn from 'classnames';
import Modal from '../Modal';
import BlockList from './BlockList';
import BaseButton from '../Button/BaseButton';
import CompanyCheckForm from './CompanyCheckForm';
import PendingSearch from './PendingSearch';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { FormLabel } from '@/components/common/Form/Label';
import { FormContainerTextfield } from '../Form/Container/Textfield';
import { BoxEmpty } from '../OtherComponents/BoxEmpty';
import {
  BlockCompany,
  UserApi,
  BlockCompanyPostParam,
  BlockCompaniesPatchParam,
  ResponseError,
} from '@/utils/api-client';
import '@/styles/common/Direct/BlockModal.scss';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';

type Props = {
  blockCompanies: BlockCompany[];
  isOpen: boolean;
  blockComplete: boolean;
  isClose: React.Dispatch<React.SetStateAction<boolean>>;
  setBlockComplete: React.Dispatch<React.SetStateAction<boolean>>;
  patchCompanies: (payload: BlockCompaniesPatchParam) => Promise<void>;
};

type TInput = {
  name: string;
};

function BlockModal(props: Props): React.ReactElement {
  const [companies, setCompanies] = useState<BlockCompany[]>();
  const [companiesArray, setCompaniesArray] = useState<BlockCompany[][]>();
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageLength, setPageLength] = useState<number>(0);
  const [searchEnd, setSearchEnd] = useState<boolean>(false);
  const [pending, setPending] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { push } = useContext(DataLayerContext);

  const methods = useForm<TInput>({
    defaultValues: {
      name: '',
    },
  });
  const { handleSubmit } = methods;

  const arrayChunk = ([...array], size = 1) => {
    return array.reduce(
      (acc, value, index) => (index % size ? acc : [...acc, array.slice(index, index + size)]),
      []
    );
  };

  useEffect(() => {
    if (!props.isOpen) return;
    push({
      event: 'pageView',
      actionType: 'page_view',
      actionName: 'modal_open',
      virtualPageName: 'search-for-companies-to-block_step_1',
    });
  }, [props.isOpen]);

  useEffect(() => {
    if (!companiesArray) return;
    if (companiesArray.length > 0) {
      push({
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'modal_open',
        virtualPageName: 'search-for-companies-to-block_step_2',
      });
    }
  }, [companiesArray]);

  useEffect(() => {
    if (props.isOpen && props.blockComplete) {
      push({
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'modal_open',
        virtualPageName: 'search-for-companies-to-block_step_3',
      });
    }

    if (!props.isOpen || !props.blockComplete) {
      return () => {
        reset();
      };
    }
  }, [props.isOpen, props.blockComplete]);

  useEffect(() => {
    const arr = Array(total)
      .fill(0)
      .map((v, i) => {
        return i;
      });
    setPageLength(arrayChunk(arr, 10).length);
  }, [total]);

  useEffect(() => {
    if (companiesArray) {
      setCompanies(companiesArray[page]);
    }
  }, [page]);

  const reset = () => {
    setCompanies([]);
    setPage(0);
    setTotal(0);
    setPageLength(0);
    setSearchEnd(false);
    setPending(false);
    methods.setValue('name', '');
  };
  const onSearch = async (name: string) => {
    const param: BlockCompanyPostParam = {
      limit: 9999,
      offset: 0,
      name: name,
    };
    setSearchEnd(false);
    setPending(true);
    dispatch(toggleLoading(true));
    try {
      const res = await new UserApi().postUserBlockCompanies(param);
      const { companies } = Object.assign(res.data);
      const blockIds = props.blockCompanies.map((b) => b.id);
      const companiesArray = arrayChunk(
        companies.filter(function (v: BlockCompany) {
          return !blockIds.includes(v.id);
        }),
        10
      );
      setCompaniesArray(companiesArray);
      setTotal(
        companies.filter(function (v: BlockCompany) {
          return !blockIds.includes(v.id);
        }).length
      );
      setCompanies(companiesArray[0]);
      setPage(0);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      setSearchEnd(true);
      setPending(false);
      dispatch(toggleLoading(false));
    }
  };

  const moreBlock = () => {
    reset();
    props.setBlockComplete(false);
    push({
      event: 'pageView',
      actionType: 'page_view',
      actionName: 'modal_open',
      virtualPageName: 'search-for-companies-to-block_step_1',
    });
  };

  return (
    <Modal isOpen={props.isOpen} isClose={() => props.isClose(false)} className="block-modal">
      <section className="w-624 m-auto sm:w-auto sm:px-24">
        {!props.blockComplete && (
          <>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit((data) => onSearch(data.name))}>
                <div className="flex items-end">
                  <FormLayoutFieldset className="w-528 sm:w-full">
                    <FormLabel label="ブロックする企業名" type="small"></FormLabel>
                    <FormContainerTextfield
                      name="name"
                      className="mkt_mask_items"
                      placeholder="企業名の一部を入力してください"
                    />
                  </FormLayoutFieldset>
                  <div className="w-88">
                    <BaseButton theme="primary" size="m" className="search-btn">
                      検索
                    </BaseButton>
                  </div>
                </div>
              </form>
            </FormProvider>
            {companies && (
              <>
                {pending ? (
                  <PendingSearch />
                ) : (
                  <CompanyCheckForm
                    total={total}
                    companies={companies}
                    blockCompanies={props.blockCompanies}
                    page={page}
                    pageLength={pageLength}
                    searchEnd={searchEnd}
                    setPage={setPage}
                    setBlockComplete={props.setBlockComplete}
                    patchCompanies={props.patchCompanies}
                  />
                )}
              </>
            )}
            {searchEnd && total === 0 && (
              <div className="mt-24">
                <BoxEmpty message="検索結果がありません。" />
              </div>
            )}
          </>
        )}
        {props.blockComplete && (
          <p className="mbx-typography--body_1">以下の企業をブロックしました</p>
        )}
        <div className={cn(props.blockComplete ? 'mt-24 mb-48' : 'mt-48 mb-80')}>
          <BlockList blockCompanies={props.blockCompanies} isDelete={false} />
        </div>
        {props.blockComplete && (
          <>
            <BaseButton theme="primary" size="m" className="btn-308" onClick={moreBlock}>
              続けてブロックする企業を追加する
            </BaseButton>
            <div className="mt-24">
              <BaseButton theme="link" size="m" onClick={() => props.isClose(false)}>
                終了する
              </BaseButton>
            </div>
          </>
        )}
      </section>
    </Modal>
  );
}

export default BlockModal;
