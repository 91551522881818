import '@/styles/common/Admin/FavoriteConditions/list_item.scss';

import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import MatchboxIcon from '@/assets/images/common/icon_matchbox.svg';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ButtonIconButton } from '@/componentsDirect/Parts/Button/IconButton';
import { FormToggleButton } from '@/componentsDirect/Parts/Form/ToggleButton';
import {
  AdminSearchConditionModel,
  AdminSearchConditionResponse,
  DirectSearchConditionResponse,
} from '@/utils/api-client';
import { searchConditionsToText } from '@/utils/search';

type Props = {
  type: 'direct' | 'admin'; // adminの場合はマイナビ向け
  index: number;
  item: AdminSearchConditionResponse | DirectSearchConditionResponse;
  onClickRemoveButton: React.MouseEventHandler<HTMLButtonElement>;
  onClickSearchButton: React.MouseEventHandler<HTMLDivElement>;
  onClickEditButton: React.MouseEventHandler<HTMLDivElement>;
  onChangeDirectMail: () => void;
};

export function ListItem(props: Props): React.ReactElement {
  const methods = useFormContext();
  const [checked, setChecked] = useState<boolean>(false);
  const directMailName = `conditions.${props.index}.direct_mail`;

  useEffect(() => {
    setChecked(methods.getValues(directMailName));
  }, [directMailName]);

  const searchText = useMemo(() => {
    return searchConditionsToText(props.item as AdminSearchConditionModel, props.type);
  }, []);

  const onClickToggleButton = () => {
    const newValue = !checked;
    setChecked(newValue);
    methods.setValue(directMailName, newValue);
    props.onChangeDirectMail();
  };

  return (
    <section className="mbx-list_item">
      <div className="mbx-list_item__header">
        {props.type === 'admin' && (
          <img className="mbx-list_item__icon" src={MatchboxIcon} alt="" />
        )}
        <div className="mbx-list_item__title">{props.item.search_condition_name}</div>
      </div>
      <div className="mbx-list_item__text">{searchText}</div>
      <div className="mbx-list_item__buttons">
        <BaseButton
          size="s"
          theme="secondary"
          className="mbx-list_item__button"
          onClick={props.onClickSearchButton}
        >
          この条件で検索
        </BaseButton>
        <BaseButton
          size="s"
          theme="secondary"
          className="mbx-list_item__button"
          onClick={props.onClickEditButton}
        >
          条件を編集
        </BaseButton>
      </div>
      <FormToggleButton
        className="mbx-list_item__toggle"
        label="メール配信"
        checked={checked}
        onClick={() => onClickToggleButton()}
      />
      <ButtonIconButton
        type="gray"
        iconName="Sortable"
        className="mbx-list_item__drag-handle dragHandle"
      />
      <ButtonIconButton
        type="gray"
        iconName="Clear"
        className="mbx-list_item__remove-button"
        onClick={props.onClickRemoveButton}
      />
    </section>
  );
}
