import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Container, Draggable } from 'react-smooth-dnd';

import { ListItem } from '@/componentsDirect/Conditions/ListItem';
import { AdminPage } from '@/componentsDirect/Layout/Page';
import { TabNavigation } from '@/componentsDirect/Parts/Navigation/TabNavigation';
import { BoxEmpty } from '@/componentsDirect/Parts/OtherComponents/BoxEmpty';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { useDirectSearchConditions } from '@/hooksDirect/useDirectSearchConditions';
import { setSearchCondition } from '@/reduxAdmin/modules/directSearchCondition';

export const DirectConditions = (): React.ReactElement => {
  const dispatch = useDispatch();
  const {
    onDrop,
    onRemove,
    onClickEditButton,
    onClickSearchButton,
    onChangeDirectMail,
    methods,
    fields,
    loaded,
  } = useDirectSearchConditions(false);

  useEffect(() => {
    dispatch(setSearchCondition(null));
  }, []);

  return (
    <AdminPage header={<HeaderTitle title="保存した検索条件" />}>
      <div className="mt-24 mb-80">
        <TabNavigation
          className="mb-48"
          align="fit"
          activeIndex={0}
          menu={[
            { title: 'あなたの検索条件', url: '/direct/conditions' },
            { title: 'MATCHBOXのおすすめ検索条件', url: '/direct/conditions/recommended' },
          ]}
        />
        {loaded && fields.length <= 0 && <BoxEmpty message="検索条件がありません。" />}
        {loaded && fields.length > 0 && (
          <FormProvider {...methods}>
            <Container
              onDrop={onDrop}
              dragHandleSelector=".dragHandle"
              lockAxis="y"
              render={(ref) => {
                return (
                  <div ref={ref} className="">
                    {fields.map((item, index) => (
                      <Draggable key={item.uid} className="">
                        <ListItem
                          direct
                          key={item.uid}
                          index={index}
                          item={item}
                          onClickRemoveButton={() => onRemove(index, item.id)}
                          onClickSearchButton={() => {
                            onClickSearchButton(index, item.id);
                          }}
                          onClickEditButton={() => onClickEditButton(item.id)}
                          onChangeDirectMail={() => onChangeDirectMail(index)}
                        />
                      </Draggable>
                    ))}
                  </div>
                );
              }}
            />
          </FormProvider>
        )}
      </div>
    </AdminPage>
  );
};
