import React from 'react';
import cn from 'classnames'
import '@/styles/common/Form/Unit/sorting.scss';
import { ButtonIconButton } from '@/components/common/Button/IconButton';

type Props = {
  handle: string;
  children?: React.ReactNode;
  className?: string;
  visible?: boolean;
  onVisible?: React.MouseEventHandler<HTMLButtonElement>;
  onDelete?: () => void;
};

export const Sorting:React.VFC<Props> = ({handle, children, className, visible, onVisible, onDelete})=> {
  return (
    <div className="mbx-sorting flex mbx-keyline-horizontal--thin items-center bg-white">
      <div className={cn(
        "text-subhead_1 my-16 ml-16 flex-auto",
        {
          'opacity-20': !visible,
        },
        className,
      )}>
        {children}
      </div>
      <div className="flex">
        <ButtonIconButton
          type="gray"
          iconName="Sortable"
          className={cn(
            {
              'opacity-20': !visible,
            },
            handle,
          )}
        ></ButtonIconButton>
        {onVisible &&
          <ButtonIconButton
            type="gray"
            iconName={visible ? 'Visible' : 'Invisible'}
            onClick={onVisible}
            focus={false}
          ></ButtonIconButton>
        }
        <ButtonIconButton
          type="gray"
          iconName="Clear"
          className={onDelete?"":"opacity-10"}
          onClick={() => {
            if (onDelete) { onDelete() }
          }}
        ></ButtonIconButton>
      </div>
    </div>
  );
}
