import '@/styles/common/Admin/FavoriteConditions/admin_list_item.scss';

import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ButtonIconButton } from '@/componentsDirect/Parts/Button/IconButton';
import { FormCheckboxItem } from '@/componentsDirect/Parts/Form/CheckboxItem';
import { FormToggleButton } from '@/componentsDirect/Parts/Form/ToggleButton';
import {
    AdminDirectSearchConditionResponse, AdminSearchConditionModel, DirectCompanyModel
} from '@/utils/api-client';
import { searchConditionsToText } from '@/utils/search';

type Props = {
  direct?: boolean;
  index: number;
  item: AdminDirectSearchConditionResponse;
  onClickRemoveButton: React.MouseEventHandler<HTMLButtonElement>;
  onClickSearchButton: React.MouseEventHandler<HTMLDivElement>;
  onClickEditButton: React.MouseEventHandler<HTMLDivElement>;
  onChangeDirectMail: () => void;
  onChangeChecked: (checked: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeleteCompanyTag: (company: any) => void;
  onChangeCompanyTag: () => void;
};

export function AdminListItem(props: Props): React.ReactElement {
  const methods = useFormContext();
  const [checked, setChecked] = useState<boolean>(false);
  const directMailName = `conditions.${props.index}.direct_mail`;

  useEffect(() => {
    setChecked(methods.getValues(directMailName));
  }, [directMailName]);

  const searchText = useMemo(() => {
    if (props.direct) {
      const condition = cloneDeep(props.item);
      if (condition?.offer_status?.includes(6 || 7)) {
        const status = condition.offer_status.filter((s) => {
          if (s !== 6 && s !== 7) {
            return true;
          }
        });
        condition.offer_status = status;
      }
      if (condition?.offer_status?.includes(5)) {
        const status = condition.offer_status.filter((s) => {
          if (s !== 5) {
            return true;
          }
        });
        condition.offer_status = status;
      }
      return searchConditionsToText(condition as AdminSearchConditionModel);
    }
    return searchConditionsToText(props.item as AdminSearchConditionModel);
  }, []);

  const onClickToggleButton = () => {
    const newValue = !checked;
    setChecked(newValue);
    methods.setValue(directMailName, newValue);
    props.onChangeDirectMail();
  };

  const onDeleteCompanyTag = (company: DirectCompanyModel) => {
    const item = props.item as AdminDirectSearchConditionResponse;
    const sharedCompanies = item.shared_companies?.filter((item: DirectCompanyModel) => {
      return item.id !== company.id;
    });
    methods.setValue(`conditions.${props.index}.shared_companies`, sharedCompanies);
    props.onDeleteCompanyTag(company);
    props.onChangeCompanyTag();
  };

  return (
    <section className="mbx-admin_list_item">
      <div className="mbx-admin_list_item__content">
        <div className="mbx-admin_list_item__check">
          <FormCheckboxItem
            label=""
            name="check"
            value="value"
            onChange={(e) => {
              props.onChangeChecked(e.target.checked);
            }}
          />
        </div>
        <div className="mbx-admin_list_item__header">
          <div className="mbx-admin_list_item__title">{props.item.search_condition_name}</div>
        </div>
        <div className="mbx-admin_list_item__text">{searchText}</div>
        <div className="mbx-admin_list_item__buttons">
          <BaseButton
            size="s"
            theme="secondary"
            className="mbx-admin_list_item__button"
            onClick={props.onClickSearchButton}
          >
            この条件で検索
          </BaseButton>
          <BaseButton
            size="s"
            theme="secondary"
            className="mbx-admin_list_item__button"
            onClick={props.onClickEditButton}
          >
            条件を編集
          </BaseButton>
        </div>
        <FormToggleButton
          className="mbx-admin_list_item__toggle"
          label="メール配信"
          checked={checked}
          onClick={() => onClickToggleButton()}
        />
        <ButtonIconButton
          type="gray"
          iconName="Sortable"
          className="mbx-admin_list_item__drag-handle dragHandle"
        />
        <ButtonIconButton
          type="gray"
          iconName="Clear"
          className="mbx-admin_list_item__remove-button"
          onClick={props.onClickRemoveButton}
        />
      </div>
      {props.item.shared_companies && props.item.shared_companies.length > 0 && (
        <div className="shared-companies">
          <div className="shared-companies__header">共有済みの企業：</div>
          <div className="shared-companies__content">
            {props.item.shared_companies.map((company: DirectCompanyModel, index: number) => (
              <div key={index} className={'pl-8 m-6 bg-blue-200 rounded-full'}>
                <div className="flex items-center justify-between">
                  <p className="text-11_12">{company.name}</p>
                  <ButtonIconButton
                    type="clear"
                    fontSize="icon_small"
                    iconName="Clear"
                    onClick={(e) => {
                      e.preventDefault();
                      onDeleteCompanyTag(company);
                    }}
                    hitArea="mini"
                    focus={false}
                  ></ButtonIconButton>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  );
}
