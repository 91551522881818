import React from 'react';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { DataLayerType } from '@/hooksDirect/common/useDataLayer';
import { useOfferPositions } from '@/hooksDirect/useOfferPositions';

type TProps = {
  showModal: boolean;
  id: string;
  setPositionDeleteState: React.Dispatch<
    React.SetStateAction<{
      id: string;
      showModal: boolean;
    }>
  >;
  getOfferPositions: () => Promise<void>;
};

/**
 * ポジション削除処理
 */
export const DirectUsersOfferPositionsDeleteComponent = ({
  showModal,
  id,
  setPositionDeleteState,
  getOfferPositions,
}: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const { deleteOfferPositionId } = useOfferPositions();
  const { push } = React.useContext(DataLayerContext);
  const dataLayer: DataLayerType = {
    event: 'pageView',
    actionType: 'page_view',
    actionName: 'modal_open',
    virtualPageName: 'delete-position',
  };

  // ====================================================================================================
  //
  // methods
  //
  // ====================================================================================================
  const onClose: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.preventDefault();
    setPositionDeleteState({ id: '', showModal: false });
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    (async () => {
      await deleteOfferPositionId(id);
      await getOfferPositions();
      setPositionDeleteState({ id: '', showModal: false });
      push({
        event: 'deletePosition',
        actionType: 'delete_position',
        actionName: 'delete_position',
      });
    })();
  };
  return (
    <ModalDefault
      isOpen={showModal}
      onClose={onClose}
      className="w-624 rounded-modalContainer"
      dataLayer={dataLayer}
    >
      <section className="py-80">
        <h1 className="text-subhead_1 mb-8">
          このポジションを削除しますか？
          <br />
          削除すると求職者（候補者）側の画面からも
          <br />
          このポジション内容は削除されます。
        </h1>
        <form className="px-80" onSubmit={onSubmit}>
          <div className="flex justify-center mt-24">
            <div className="w-146 mr-8">
              <BaseButton size="m" theme="secondary" className="w-full" onClick={onClose}>
                キャンセル
              </BaseButton>
            </div>
            <div className="w-146 ml-8">
              <BaseButton size="m" theme="primary" className="w-full" type="submit">
                削除する
              </BaseButton>
            </div>
          </div>
        </form>
      </section>
    </ModalDefault>
  );
};
