import React, { useContext, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import {
    FormContainerTextfieldMultiple
} from '@/componentsDirect/Parts/Form/Container/TextfieldMultiple';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { DataLayerType } from '@/hooksDirect/common/useDataLayer';
import { useCompanyUsers } from '@/hooksDirect/useCompanyUsers';
import { TFilterValues } from '@/pagesDirect/CompanyUsers/Index';

type TProps = {
  getCompanyUsers: (request: TFilterValues) => Promise<void>;
  filterValues: TFilterValues;
};

type TInputs = {
  users: Array<string>;
};

/**
 * 社内ユーザー追加処理
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A66109
 */
export const DirectUsersAddUserComponent = ({
  getCompanyUsers,
  filterValues,
}: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const [showModal, setShowModal] = useState(false);
  const [showSended, setShowSended] = useState(false);
  const { postCompanyUser } = useCompanyUsers();

  const methods = useForm<TInputs>({
    defaultValues: {
      users: [],
    },
  });

  const { handleSubmit } = methods;
  const { push } = useContext(DataLayerContext);
  const dataLayer: DataLayerType = {
    event: 'pageView',
    actionType: 'page_view',
    actionName: 'modal_open',
    virtualPageName: 'add-user_step_1',
  };

  // ====================================================================================================
  //
  // methods
  //
  // ====================================================================================================
  const onClose: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.preventDefault();
    setShowModal(false);
  };

  // 値をクリア
  const clearValue = () => {
    setShowSended(false);
    methods.setValue('users', []);
  };

  const onSubmit: SubmitHandler<TInputs> = (data) => {
    (async () => {
      try {
        await postCompanyUser(data);

        await getCompanyUsers({
          user_name: filterValues.user_name,
          mail: filterValues.mail,
          group: filterValues.group,
          user_type: filterValues.user_type,
        });
        setShowSended(true);
        push({
          event: 'pageView',
          actionType: 'page_view',
          actionName: 'modal_open',
          virtualPageName: 'add-user_step_2',
        });
      } catch (e) {
        //
      }
    })();
    return false;
  };
  return (
    <>
      <BaseButton iconName="Add" size="s" theme="primary" onClick={() => setShowModal(true)}>
        新しいユーザーを追加する
      </BaseButton>
      <ModalDefault
        isOpen={showModal}
        onClose={onClose}
        onExited={clearValue}
        className="w-624 rounded-modalContainer"
        dataLayer={dataLayer}
      >
        <section className="py-80">
          {showSended ? (
            <>
              <h1 className="text-heading_4 font-bold mb-24">招待メールが送信されました</h1>
              <p className="text-subhead_1 mb-8">
                追加したユーザーに、招待メールを確認してアカウント登録を行うよう
                <br />
                お伝えください。
              </p>
            </>
          ) : (
            <>
              <h1 className="text-heading_4 font-bold mb-24">新しいユーザーを追加</h1>
              <p className="text-subhead_1 mb-8">
                追加したいユーザーのメールアドレスを入力してください。
                <br />
                入力したアドレスに招待メールが送信されます。
              </p>
              <p className="text-caption_1 mb-24">※ エンターキーで区切って複数入力可能</p>
              <FormProvider {...methods}>
                <form className="px-80" onSubmit={handleSubmit(onSubmit)}>
                  <FormContainerTextfieldMultiple
                    name="users"
                    placeholder="例）match-tarou@matchbox.com"
                  />
                  <div className="flex justify-center mt-24">
                    <div className="w-146 mr-8">
                      <BaseButton size="m" theme="secondary" className="w-full" onClick={onClose}>
                        キャンセル
                      </BaseButton>
                    </div>
                    <div className="w-146 ml-8">
                      <BaseButton
                        size="m"
                        theme="primary"
                        className="w-full"
                        type="submit"
                        disabled={methods.getValues('users').length === 0}
                      >
                        送信する
                      </BaseButton>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </>
          )}
        </section>
      </ModalDefault>
    </>
  );
};
