export function usePagerIndex() {
  const onPage = (number:number, center:number, limit:number, pageList:number[]):number[] => {
      const box = pageList;
      if (box.indexOf(number) > center) {
        while (box.indexOf(number) !== center) {
          if(box.length - 1 === limit) break;
          box.shift();
        }
      } else {
        while (box.indexOf(number) !== center) {
          box.unshift(box[0] - 1);
        }
      }
      return box
  }

  const onPrev = (number:number, center:number, pageList:number[],limit:number):number[] => {
    const box = pageList;
    if (box.length - 1 !== limit) {
      if (number > center) {
        box.unshift(box[0] - 1);
      }
    } else {
      if (center > box.indexOf(number - 1)) {
        box.unshift(box[0] - 1);
      }
    }
    return box
  }

  const onNext = (number:number, center:number, pageList:number[],limit:number):number[] => {
    const box = pageList;
    if(box.length - 1 !== limit) {
      if (number + 1 > center) {
        box.shift();
      }
    }
    return box
  }

  return {onPage, onPrev, onNext};
}