import React, { useState ,useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Ed13 = (): React.ReactElement => {
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if(isAuth){
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0013");
    if(!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g,"/")} 12:00:00`);
    const now = new Date;
    if(now > deadlineDate)setDeadline(true);
  }, []);

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16">
          <img className={style.img} src="../images/Top/event/event_0013_mv.png" width="978" height="504" alt="" />
        </div>
        <h1 className="mt-56 sm:mt-30 text-29_46 sm:text-18_24 font-bold text-center">1/17（火）20:00 - <br />
          Webディレクターの書類選考の<br className="md:hidden lg:hidden" />最大の武器は<br className="lg:hidden" />ポートフォリオ作成だ！<br />
          採用視点から見るポートフォリオ作成<br className="lg:hidden" />のメリット大公開</h1>
        { deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <section>
          <section>
            <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">以下に該当する方はオススメです。</h2>
            <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
              <ul className="text-16_28 sm:text-14_21">
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>転職活動の準備が進まない。どの形で進めれば良いかわからない。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>Webディレクターのキャリアをまとめることを難しいと感じている。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>転職活動を始めているけど、書類選考が通過しない。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>選考書類をブラッシュアップしたい。客観的なアドバイスが欲しい。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>他の人のキャリアのまとめ方を知りたい。</span>
                </li>
                <li className="flex">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>MATCHBOXの効果的な使い方を知りたい。</span>
                </li>
              </ul>
            </div>
          </section>
          { !deadline && !isLogin &&
            <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
              <div className='flex flex-col items-center justify-center'>
                <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
                <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
              </div>
            </section>
          }
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">ワークショップについて</h2>
          <div className="mb-30">
            <img src="../images/Top/event/event_workshop_image.jpg" width="980" height="374" alt="" />
          </div>
          <p className="text-16_28 sm:text-14_21">
            Webディレクターが転職する際、これまでのキャリアをしっかり採用担当者に伝えるために、ポートフォリオの作成は有効であると言えます。ポートフォリオはデザイナーだけが作成するものと考えられがちですが、そんなことはありません。Webディレクターの能力はマネジメントや問題解決、企画立案・推進、折衝・調整など業務が多岐に渡っています。それらを履歴書・職務経歴書などの資料と並行して可視化することが大切です。また、Webディレクターのポートフォリオ作成は採用企業からの声として、抽象的になりがちなWebディレクターのキャリアが一目で把握しやすいと歓迎の声も多いです。<br />
            <br />
            そこで今回、Webディレクター経験があり、且つ採用担当者でもある株式会社ジュニの峯藤誠さん、合同会社DMM.comの山本純一さんをお迎えして、Webディレクターのポートフォリオ作成について、採用目線からお話をいただきます。制作会社／事業会社の視点、Webディレクターとして／採用視点からなど、全方位からポートフォリオ作成についてお伝えできる貴重な機会となっております。<br />
            作成についてもMATCHBOXを使って、時間をかけずにポイントをおさえる方法もご紹介します。<br />
            オンラインでの開催となりますので、興味のある方はお早めにご応募ください。
          </p>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">講師プロフィール</h2>
          <div className="event-instructor box-line mb-30 sm:p-16">
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0013_prof-01.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                峯藤 誠<span className="inline-block">（MAKOTO MINEFUJI）</span>
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-18 sm:mb-12">
                株式会社ジュニ リードディレクター / マネージャー
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                2008年からWeb・IT業界に携わる。数々の企業に在籍しながらWebサイト制作やシステム開発のディレクションに携わる。2018年から株式会社ジュニにプランニング・ディレクションチームのメンバーとしてジョイン。案件の企画提案、要件定義、画面設計、進行管理、解析などWebディレクション業務全般に渡って一通り経験。 現在は、制作や開発業務のディレクションを行いながらも、メンバーのマネジメントの他に採用活動や教育も担当している。<br />
                ワンストップで幅広い相談に対応できるということを意識してディレクションには取り組んでいる。
              </dd>
            </dl>
          </div>
          <div className="event-instructor box-line sm:p-16">
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0013_prof-02.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                山本 純一<span className="inline-block">（JUNICHI YAMAMOTO）</span>
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-18 sm:mb-12">
                合同会社DMM.com<br />
                EC＆デジタルコンテンツ事業部 WEBサイト運営部 副部長/ディレクター
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                1976年10月生まれ。石川県金沢市在住。外食産業SV、医療機器営業を経て2010年よりDMM.com。Webディレクター職として、スマホアプリ開発/サイト開発・制作ディレクションを中心に、デジタルコンテンツEC構築全般を幅広く担当。DMM英会話、女性向けECサイトの立ち上げなどのPM/ディレクター、その他開発ディレクション/PMなど歴任。合わせてディレクターチームのマネジメント業務を行いつつ、事業間エンゲージメントにも従事。<br />
                現在はWEBサイト運営/運用部門に所属し、業務RPA化などビジネススピード高速化と組織マネジメント中心に取り組む傍ら、地方創生/DX担当臨時職員として地方自治体案件に携わる。Scrum Inc.認定スクラムマスター保有。
              </dd>
            </dl>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap" rowSpan={0}>
                  <span className="sm:block">20：00</span><span className="sm:block">～</span><span className="sm:block">21：30</span>
                </th>
                <td>
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>概要説明</span></li>
                    <li className="flex"><span>・</span><span>講師 峯藤さん、山本さん 自己紹介</span></li>
                    <li className="flex"><span>・</span><span>パネルディスカッション
                      <ul>
                        <li className="flex"><span>-&nbsp;</span><span>ディレクターの選考でポートフォリオを作っている人はどれくらいいる？</span></li>
                        <li className="flex"><span>-&nbsp;</span><span>ディレクターの選考の際はどこをアピールすべき？</span></li>
                        <li className="flex"><span>-&nbsp;</span><span>採用視点で選考の視点、評価ポイント</span></li>
                        <li className="flex"><span>-&nbsp;</span><span>こんなポートフォリオは面接したくなる！</span></li>
                        <li className="flex"><span>-&nbsp;</span><span>必要最低限これは書いてほしい、+α採用側が書いてほしいポイント</span></li>
                      </ul>
                    </span></li>
                    <li className="flex"><span>・</span><span>質疑応答</span></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        { !deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2023/1/17（火）20:00～ <br />
                  [入室開始] 19:45～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">対象者</th>
                <td>転職活動中、もしくは、転職準備中のWebディレクターの方</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">準備物</th>
                <td>
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>ご自身のPC</span></li>
                    <li className="flex"><span>・</span><span>MATCHBOXのアカウントを作成しログイン状態でご参加ください。</span></li>
                    <li className="flex"><span>・</span><span>作成済み、もしくは、作成中の選考書類（履歴書・職務経歴書・ポートフォリオなど）</span></li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.ワークショップ申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink" href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.ワークショップ当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        { !deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">開催について</h3>
          <p className="mt-30 sm:mt-16 text-16_28 sm:text-14_21">
            Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2,000名を超える「0からのWebディレクション講座」を生み出したメンバーでもある一般社団法人ディレクションサポート協会 会長の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。          </p>
        </section>
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div>
    </div>
  );
};
