import { AxiosResponse } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { AdminCompanyResult, AdminCompanySearchForm } from '@/pagesAdmin/';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import {
    ADMINApi, AdminDirectCompanySearchConditionModel, AdminSearchCompaniesRequest,
    AdminSearchCompaniesResponse
} from '@/utils/api-client';

const defaultCondition = {
  keyword: [],
  // directompany_id: [],
  block_company_id: '',
  name: '',
  kana: '',
  last_login: [],
  registration_date: [],
  // prefecture: [],
  address: '',
  business_type: [],
  // employee_amount: [],
  description: [],
  direct_company_user_id: '',
  direct_company_user_email: '',
  direct_company_user_name: '',
  mw_user: '',
};

export const AdminCompany = (): React.ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const [response, setResponse] = useState<AdminSearchCompaniesResponse>();
  const [conditions, setConditions] =
    useState<AdminDirectCompanySearchConditionModel>(defaultCondition);
  const isResult = useMemo(() => {
    const path = location.pathname.split('/');
    return path.reverse()[0] === 'result';
  }, [location]);
  const dispatch = useDispatch();

  const onSearch = async (conditions: AdminDirectCompanySearchConditionModel) => {
    const parameters: AdminSearchCompaniesRequest = {
      conditions,
      limit: 10,
    };
    setConditions(conditions);
    dispatch(toggleLoading(true));
    try {
      const res: AxiosResponse<AdminSearchCompaniesResponse> =
        await new ADMINApi().postAdminDirectCompanies(parameters);
      setResponse(res.data);
    } catch (e) {
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    }
    dispatch(toggleLoading(false));
  };

  const updateSearchResult = async (page: number) => {
    const parameters: AdminSearchCompaniesRequest = {
      conditions,
      limit: 10,
      offset: page * 10,
    };

    try {
      const res: AxiosResponse<AdminSearchCompaniesResponse> =
        await new ADMINApi().postAdminDirectCompanies(parameters);
      setResponse(res.data);
    } catch (e) {
      //
    }
  };

  const onResumeConditions = () => conditions;

  useEffect(() => {
    if (!response) return;
    history.push('/admin/companies/search/result');
  }, [response]);

  return (
    <>
      {isResult ? (
        <AdminCompanyResult
          result={response}
          conditions={conditions}
          updateSearchResult={updateSearchResult}
        />
      ) : (
        <AdminCompanySearchForm
          onSearch={onSearch}
          onResumeConditions={onResumeConditions}
          onResetCondition={() => {
            setConditions(defaultCondition);
          }}
        />
      )}
    </>
  );
};
