import React, { VFC } from 'react';
import cn from 'classnames';
import '@/styles/common/Portfolio/skill_box.scss';

type TProps = {
  skillName: string;
  level: 0 | 1 | 2 | 3 | 4 | 5;
  experience: 1 | 2 | 3 | 4;
};

export const SkillBox: VFC<TProps> = ({ level = 0, skillName, experience }) => {
  const years = ['１年未満', '１年', '２年', '３年以上'];

  return (
    <div className={cn('mbx-skill-box', 'mkt_mask_items')}>
      <h3 className="mbx-typography--subhead_2">{skillName}</h3>
      <div className="flex items-center mt-4">
        <ul className="ul">
          {new Array(5).fill(null).map((item, key) => {
            return (
              <li key={key} className={cn({ active: key < level })}>
                <img src="/images/skill-fire.png" alt="" />
              </li>
            );
          })}
        </ul>
        <p className="text-12_18 ml-8">
          <span>経験</span>
          <span>{years[experience - 1]}</span>
        </p>
      </div>
    </div>
  );
};
