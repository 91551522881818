/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { setPathAfterLogin, auth, notificationError, setPathAfterLogout  } from '@/redux';
import { State } from '@/redux/state';
import axios from 'axios';

type TProps = {
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> | undefined;
  path?: string | undefined;
  exact?: boolean | undefined;
};

export function PrivateRoute({ exact, path, component }: TProps): React.ReactElement {
  const isAuth = useSelector((state: State) => state.isAuth);
  const pathAfterLogout = useSelector((state: State) => state.pathAfterLogout);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    axios.interceptors.response.use(
      (res) => res,
      (e) => {
        const error = e.response.data;
        if (error.error_code === '1010') {
          dispatch(auth(false));
          dispatch(setPathAfterLogout('/login'));
          dispatch(notificationError(error.error_message));
        }
        return Promise.reject(e);
      }
    );
  }, []);

  // 未ログインでパスがloginページ以外の場合は遷移元のパスを保持
  // ログイン成功後に保持した遷移先に移動
  if (isAuth) {
    dispatch(setPathAfterLogin(undefined));
  } else {
    dispatch(setPathAfterLogin(location.pathname));
  }

  return isAuth ? (
    <Route exact={exact} path={path} component={component} />
  ) : (
    <Redirect to={`${pathAfterLogout}${location.search}`} />
  );
}
