import '@/styles/common/Title/header_title.scss';

import React from 'react';

type Props = {
  title: string;
  children?: React.ReactNode;
};

function HeaderTitle(props: Props): React.ReactElement {
  return (
    <section className="header-title">
      <div className="header-title_inner lg:w-960">
        <p className="title">{props.title}</p>
        <div className="children-area">{props.children}</div>
      </div>
    </section>
  );
}

export default HeaderTitle;
