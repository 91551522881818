import cn from 'classnames';
import React, { ReactNode, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import Modal from '@/components/common/Modal';
import MatchImg from '@/images/navi-match.png';

type TProps = {
  className?: string;
  message: React.ReactNode;
  children: React.ReactNode;
};

type TPropsPortal = {
  children: ReactNode;
};

const Portal = ({ children }: TPropsPortal): React.ReactElement => {
  return ReactDOM.createPortal(children, document.getElementById('generalTooltip') as HTMLElement);
};

/**
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=493%3A4422
 *
 * Portfolio PDFダウンロード専用
 * ベースはMatchbouだがリンク指定などが必要なため切り出した。
 *
 */
export const TooltipPdfDownload = ({
  className = '',
  message = '',
  children = '',
}: TProps): React.ReactElement => {
  const [hover, setHover] = useState(false);
  const [touch, setTouch] = useState(false);
  const [leftPosition, setLeftPosition] = useState(0);
  const [topPosition, setTopPosition] = useState(0);
  const divRef = useRef<HTMLDivElement>(null);
  const itemHover = (flag: boolean): void => {
    if (divRef.current) {
      const position = divRef.current.
      getBoundingClientRect();
      setLeftPosition(position.left);
      const scrollTop = document.documentElement.scrollTop;
      if (scrollTop) {
        setTopPosition(scrollTop + 150);
      } else {
        setTopPosition(position.top);
      }
    }

    const winW = window.innerWidth;
    if (winW > 768) {
      setTimeout(() => {
        setHover(flag);
      }, 100);
    }
  };
  const itemTouch = (): void => {
    const winW = window.innerWidth;
    if (winW <= 768) {
      setTouch(true);
    }
  };
  return (
    <>
      <div
        className={className}
        onPointerEnter={() => itemHover(true)}
        onPointerLeave={() => itemHover(false)}
        onClick={itemTouch}
        ref={divRef}
      >
        {children}
      </div>
      <Portal>
        {hover && (
          <div
            className={cn(
              'mbx-tooltip mbx-tooltip--matchbou mbx-tooltip--pc mbx-tooltip--pdf absolute w-424',
              {
                'mbx-tooltip--hover': hover,
              }
            )}
            style={{ left: leftPosition, top: topPosition, transform: 'translate(0, -94px)' }}
            onPointerEnter={() => itemHover(true)}
            onPointerLeave={() => itemHover(false)}
          >
            <i className="mbx-tooltip-icon">
              <img src={MatchImg} />
            </i>
            <p className="mbx-tooltip-message">{message}</p>
          </div>
        )}
      </Portal>
      <Portal>
        <Modal isOpen={touch} className={'mbx-tooltip-modal'} isClose={() => setTouch(false)}>
          <div className={cn('mbx-tooltip mbx-tooltip--matchbou mbx-tooltip--touch')}>
            <i className="mbx-tooltip-icon">
              <img src={MatchImg} alt="" />
            </i>
            <p className="mbx-tooltip-message">{message}</p>
          </div>
        </Modal>
      </Portal>
    </>
  );
};
