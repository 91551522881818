import React, { VFC } from 'react';
import { useFormContext } from 'react-hook-form';

type TProps = {
  label: string;
  name: string;
  value: string | number;
  className?: string;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=1%3A429
 */
export const FormRadioButtonItem: VFC<TProps> = ({ label, name, value, className }) => {
  const methods = useFormContext();
  return (
    <div className={className}>
      <label className="mbx-formRadio relative">
        <input type="radio" value={value} {...methods.register(name)} />
        <span className="text-body_1 py-12 pl-32">{label}</span>
      </label>
    </div>
  );
};
