import React, { useState, useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Ed101 = (): React.ReactElement => {
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if (isAuth) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0101");
    if (!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g, "/")} 12:00:00`);
    const now = new Date;
    if (now > deadlineDate) setDeadline(true);
  }, []);

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16">
          <img className={style.img} src="../images/Top/event/event_0101_mv.jpg" width="978" height="504" alt="" />
        </div>
        <h1 className="mt-56 sm:mt-30 sm:mb-8 text-29_46 sm:text-18_24 font-bold text-center">6/25（火）19:30 -<br />
          <span className="inline-block sm:mb-8 text-21_34 sm:text-15_22">Webデザイナー進化論 season2 <br className="md:hidden lg:hidden" />UI/UXデザイナー徹底解剖編</span><br />
          ユーザビリティテストを経たUI改善！<br />Figmaのリデザインプロセス解説</h1>
        <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
          <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 md:text-center lg:text-center box-line">
            <p className="sm:text-14_21 font-bold">お詫び</p>
            <p className="mt-16 md:mb-10 lg:mb-10 sm:text-14_21">こちらのセミナーは当初6/18実施予定でしたが、<br className="sm:hidden" />講師の体調により6/25に日程を変更させていただきます。<br />
              お申し込みいただいた皆さまには大変申し訳ありません。</p>
          </div>
        </section>
        {deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <section className='mt-80 sm:mt-55'>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>セミナーについて</h2>
          <div className="mt-30 sm:mt-16 mb-30 sm:mb-18 box-line">
            <img src="../images/Top/event/event_0049_image.jpg" width="980" height="374" alt="" />
          </div>
          <p className="text-16_28 sm:text-14_21 mt-30 sm:mt-16 mb-40 sm:mb-18">
            日頃マッチボックスユーザーの皆さま向けにデザイナーのスキルやTipsについてのセミナーを行わせていただいております。<br />
            今回は『クリエイターズ・シナジーカフェ』とマッチボックスのコラボレーション第二弾となっています。今回はUI/UXデザイナー徹底解剖編としまして、具体的にUI/UXの制作過程を5回に渡ってお伝えするセミナーとなります。<br />
            <br />
            今回はvol.4として、vol.3で行ったユーザビリティテスト結果をもとに、Figmaなどでリデザインしたプロセスを丁寧に説明いただくセミナーとなります。ユーザビリティテスト後、UIの改善点を明確にしてデザインにどのように落とし込むかを解説いただきます。インタビュー後、わかったことをアウトプットするプロセスを実際にご覧いただける機会になります。<br />
            講師にはUI / UXデザイナーとして活躍中のタグチマリコさんをお迎えします。UI/UXの制作過程を知りたい、インタビュー後どのようにUIデザインへ反映していくか方法を知りたい方は是非ご参加ください。</p>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/login"><span className='text-14_21'>アカウントお持ちの方</span>ログインしてセミナーに申し込む</Link></p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちでない方は<Link className='textLink font-bold' to="/entry">こちら</Link>でご登録の上、お申込みください</p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>以下に該当する方にオススメです。</h2>
          <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>UIデザインを制作している方や興味のある方</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>デザイナーとしてのスキル・レベルアップをしたい方</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>セミナーを踏まえて転職・書類作成の相談をしたい方</span>
              </li>
            </ul>
          </div>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/login"><span className='text-14_21'>アカウントお持ちの方</span>ログインしてセミナーに申し込む</Link></p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちでない方は<Link className='textLink font-bold' to="/entry">こちら</Link>でご登録の上、お申込みください</p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>講師プロフィール</h2>
          <div className='event-instructor box-line mb-30 sm:p-16'>
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0093_prof.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                タグチ マリコ
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-40 sm:mb-18">
                日本ウェブデザイン株式会社 取締役CXO
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                1994年東京都生まれ。高校在学中にデザイナーとして活動をはじめ、複数の制作会社・開発会社にて、広告・Web・アプリケーション開発のプレイヤーからマネージャー・経営者を経験。<br />
                現在は、UXデザイン・人間中心設計（HCD）を起点としたサービスデザイン、ブランドデザイン、プロトタイピング・調査分析、UI・ビジュアルデザイン・イラスト、企業顧問・人材育成・コミュニティデザインなど、表層デザインから哲学まで、多角的にデザイン行為の在野研究をつづけている。
              </dd>
            </dl>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap">
                  <span className="sm:block">19：30</span><span className="sm:block">～</span><span className="sm:block">20：50</span>
                </th>
                <td>
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>はじめに（自己紹介）</span></li>
                    <li className="flex"><span>・</span><span>前回までのおさらい</span></li>
                    <li className="flex"><span>・</span><span>旧デザインのポイント解説</span></li>
                    <li className="flex"><span>・</span><span>Figmaを用いたリデザインの設計意図・ポイント解説</span></li>
                    <li className="flex"><span>・</span><span>実際の作業の進めかた</span></li>
                    <li className="flex"><span>・</span><span>次回予告</span></li>
                    <li className="flex"><span>・</span><span>質疑応答</span></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </section >
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/login"><span className='text-14_21'>アカウントお持ちの方</span>ログインしてセミナーに申し込む</Link></p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちでない方は<Link className='textLink font-bold' to="/entry">こちら</Link>でご登録の上、お申込みください</p>
            </div>
          </section>
        }
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2024/6/25（火）19:30～<br />
                  [入室開始] 19:20～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.参加申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink"
                      href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.開催当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/login"><span className='text-14_21'>アカウントお持ちの方</span>ログインしてセミナーに申し込む</Link></p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちでない方は<Link className='textLink font-bold' to="/entry">こちら</Link>でご登録の上、お申込みください</p>
            </div>
          </section>
        }
        <section className='mt-80 sm:mt-55'>
          <h3 className='text-18_24 sm:text-14_21 font-bold'>開催について</h3>
          <p className='text-16_28 sm:text-14_21 mt-30 sm:mt-16'>Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2, 000名を超える「0からのWebディレクション講座」を生み出したメンバーでもある一般社団法人ディレクションサポート協会 会長の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。</p>
        </section >
        <section className="mt-80 sm:mt-55 p-40 sm:p-24 bg-gray-50 text-left">
          <h3 className="text-18_24 sm:text-14_21 font-bold">注意点</h3>
          <p className="mt-16 text-16_28 sm:text-14_21">
            ZoomURLはセミナー前日・または当日にメールにてお送りします。<br />
            マッチボックス登録時のアドレス、またはセミナー申込み時に入力したアドレスにURLへ届いていない場合は<a className='textLink' href='https://survey.mynavi.jp/cre/?enq=2va0sJC992s%3d' target={'_blank'} rel="noreferrer">こちら<i className="mbx-icon mbx-icon-External_link"></i></a>からお問い合わせください。
          </p>
        </section>
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div >
    </div >
  );
};
