import cn from 'classnames';
import React, { ReactNode } from 'react';
import { HashLink } from 'react-router-hash-link';

type TProps = {
  href?: string | undefined; // url指定がある場合はonClickで毎回同じ処理書くの面倒だからlinkボタンになる機能を持たせる
  bold?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  noneUnderline?: boolean; // underline不要版
  truncate?: boolean; // 省略表示
};
type TBodyProps = {
  isExternalLink?: boolean;
};

const Body = ({
  children,
  bold,
  isExternalLink = false,
  truncate,
}: Pick<TProps, 'children' | 'bold' | 'truncate'> & TBodyProps): React.ReactElement => {
  const className = cn('mbx-button-textLink-body', { 'font-bold': bold, 'w-full': truncate });
  const iconName = bold ? 'External_link' : 'External_link-thin';
  const truncateClassName = truncate ? 'truncate max-w-full' : '';
  return (
    <span className={className}>
      <span className={truncateClassName}>{children}</span>
      {isExternalLink && <i className={`mbx-icon mbx-icon-${iconName} mr-2`}></i>}
    </span>
  );
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=992%3A17933
 *
 * 単純なリンク機能のみでいい場合は href に外部リンクを指定すると aタグで生成され target="_blank" で外部に遷移します。内部リンクの場合は
 * <Link>で生成されます
 *
 * その他の処理が必要な場合は onClick に指定してください。
 *
 * フォントサイズは親要素の指定に依存します
 *
 * 外部リンクアイコンはhrefの値に応じて表示されます。
 */
export const ButtonTextLink = ({
  className,
  onClick,
  disabled,
  children,
  bold,
  href = '',
  noneUnderline = false,
  truncate = false,
}: TProps): React.ReactElement => {
  const isExternalLink: boolean = href !== undefined ? href?.startsWith('http') : false;

  const baseClassName = cn(
    { 'mbx-button-textLink': !noneUnderline },
    { 'mbx-button-textLink-noneUnderline': noneUnderline }
  );
  return (
    <span className={className}>
      <span className={baseClassName}>
        {href && !disabled ? (
          <>
            {isExternalLink ? (
              <a href={href} target="_blank" rel="noreferrer">
                <Body bold={bold} isExternalLink={isExternalLink} truncate={truncate}>
                  {children}
                </Body>
              </a>
            ) : (
              <HashLink
                to={href}
                scroll={(el) => {
                  setTimeout(() => {
                    el.scrollIntoView({ behavior: 'smooth' });
                  }, 1000);
                }}
              >
                <Body bold={bold} truncate={truncate}>
                  {children}
                </Body>
              </HashLink>
            )}
          </>
        ) : (
          <button disabled={disabled} onClick={onClick}>
            <Body bold={bold} isExternalLink={isExternalLink} truncate={truncate}>
              {children}
            </Body>
          </button>
        )}
      </span>
    </span>
  );
};
