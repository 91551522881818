import '@/styles/common/Admin/SearchResult/save_search_conditions_modal.scss';

import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { BaseButton } from '@/componentsAdmin/Parts/Button';
import { FormContainerDropdownMultiple } from '@/componentsAdmin/Parts/Form';
import { ModalDefault } from '@/componentsAdmin/Parts/Modal/Default';
import { useUserTag } from '@/hooksAdmin/useUserTag';
import { RootState } from '@/reduxAdmin';
import { getQueryParams } from '@/utils/utils';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type Props = {
  isOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
};

type IFormInputs = {
  user_tags: string[];
};

/**
 * 検索結果にタグを付与するモーダル
 *
 * @author s2 masa
 */
export function SaveUserTagsModal({ isOpen, onClose }: Props): React.ReactElement {
  // ====================================================================================================
  //
  // Redux
  //
  // ====================================================================================================
  const savedId = useSelector((state: RootState) => state.admin_direct_search_condition.savedId);
  // ====================================================================================================
  //
  // Hooks
  //
  // ====================================================================================================
  const {
    getUserTags,
    userTagOPTIONS,
    postUserTagToSearchCondition,
    userTagsSearchCondition,
    getUserTagToSearchCondition,
  } = useUserTag();
  const search = useLocation().search;

  // ====================================================================================================
  //
  // Variables
  //
  // ====================================================================================================
  const { search_condition_id } = getQueryParams(search);

  // ====================================================================================================
  //
  // RHF
  //
  // ====================================================================================================
  const methods = useForm<IFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      user_tags: [],
    },
  });

  const { handleSubmit } = methods;

  // ====================================================================================================
  //
  // Events
  //
  // ====================================================================================================
  /**
   * 保存
   */
  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    postUserTagToSearchCondition({
      search_condition_id: search_condition_id
        ? (search_condition_id as string)
        : savedId
        ? savedId.toString()
        : '',
      tags: data.user_tags,
    }).then(() => {
      onClose(false);
    });
  };

  const handleClose: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.preventDefault();
    onClose(false);
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    if (isOpen) {
      // タグ一覧取得
      getUserTags();
      if (search_condition_id || savedId) {
        // 検索条件に紐づいたタグ取得
        const searchConditionId = search_condition_id
          ? (search_condition_id as string)
          : savedId?.toString() || '';
        getUserTagToSearchCondition(searchConditionId);
      }
      methods.setValue('user_tags', []);
    }
  }, [isOpen]);

  useEffect(() => {
    if (userTagsSearchCondition && userTagsSearchCondition.length > 0) {
      // userTagOPTIONSにない値をuserTagsSearchConditionから除外して新しい配列を作る
      const newTags = userTagsSearchCondition.filter((v) =>
        userTagOPTIONS.find((item) => Number(item.value) === Number(v))
      );
      methods.setValue('user_tags', newTags);
    }
  }, [userTagsSearchCondition]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <ModalDefault isOpen={isOpen} onClose={handleClose} className="w-624 rounded-modalContainer">
      <section className="py-80">
        <h1 className="text-heading_4 mb-24 font-bold">タグ管理</h1>
        <div className="px-80 text-left">
          <p className="text-body_1 mb-8">
            この検索条件にヒットするユーザーに対して一括でタグを追加/編集を行います。
          </p>
          <p className="text-caption_1 mb-24">
            ※現在のユーザーに対して付与されます。
            <br />
            今後の新規ユーザーに対しては反映されないため、定期的に保存を行なってください。
          </p>
        </div>
        <FormProvider {...methods}>
          <form className="px-80" onSubmit={handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
              <FormContainerDropdownMultiple
                name="user_tags"
                className="flex-grow text-left"
                placeholder="選択してください（複数選択可）"
                selectList={userTagOPTIONS}
              ></FormContainerDropdownMultiple>
            </FormProvider>
            <div className="flex justify-center mt-24">
              <div className="w-146 mr-8">
                <BaseButton size="m" theme="secondary" className="w-full" onClick={handleClose}>
                  キャンセル
                </BaseButton>
              </div>
              <div className="w-146 ml-8">
                <BaseButton size="m" theme="primary" className="w-full" type="submit">
                  保存する
                </BaseButton>
              </div>
            </div>
          </form>
        </FormProvider>
      </section>
    </ModalDefault>
  );
}
