import { useDispatch, useSelector } from 'react-redux';

import {
    setPortfolioPDFDownloadModal, setPortfolioStatusOfPDF, setPortfolioTicketID
} from '@/redux';
import { State } from '@/redux/state';
import { PortfolioApi } from '@/utils/api-client/';

type TReturn = {
  downloadPDF: () => void;
  requestToGeneratePDF: () => void;
  changeStatus: (status: number) => void;
};

/**
 * 求職者向けポートフォリオPDFダウンロード時の処理
 *
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=10595%3A173736&t=p9teItfor1vPadBJ-4
 *
 * ダウンロードボタンのonClickにdownloadPDFを設定して使用
 *
 * API
 *
 * !see https://matchbox-2021-doc-api-git-develop-prty.vercel.app/#operation/get-portfolio-request-to-generate-pdf
 */
export const useDownloadPDFForPortfolio = (): TReturn => {
  const API = new PortfolioApi();
  const dispatch = useDispatch();
  const ticketId = useSelector((state: State) => state.portfolioTicketID);
  const delay = 5000;

  const downloadByURL = (url: string) => {
    const link = document.createElement('a');
    link.download = 'pf.pdf';
    link.href = url;
    link.click();
  };

  const downloadPDF = () => {
    downloadByURL(`/api/v2/portfolio/download-pdf/${ticketId}`);
    changeStatus(3);
  };

  const changeStatus = (status: number) => {
    dispatch(setPortfolioStatusOfPDF(status));
  };

  const requestToGeneratePDF = () => {
    API.getPortfolioRequestToGeneratePdf()
      .then((res) => {
        if (res.data.result === 'success') {
          dispatch(setPortfolioTicketID(res.data.ticket_id));
          checkDownloadStatus(res.data.ticket_id as number);
        }
      })
      .catch(() => {
        console.log('error');
      });
  };

  const checkDownloadStatus = (id: number) => {
    API.getPortfolioStatusOfPdf(id)
      .then((res) => {
        changeStatus(res.data.status);
        if (res.data.status < 2) {
          setTimeout(() => checkDownloadStatus(id), delay);
        } else {
          dispatch(setPortfolioPDFDownloadModal(true));
        }
      })
      .catch(() => {
        console.log('error');
      });
  };
  return { downloadPDF, requestToGeneratePDF, changeStatus };
};
