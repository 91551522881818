import '@/styles/common/Mypage/textbox.scss';

import React from 'react';

import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { ButtonTextLink } from '@/components/common/Button/TextLink';

type Props = {
  status: string | undefined;
};

export const SectionPortfolio = (props: Props): React.ReactElement => {

  return (
    <section>
      <TitleSectionTitle>
        <div className="flex items-center">
          <span className="mr-4">ポートフォリオの公開設定</span>
        </div>
      </TitleSectionTitle>
      <p className="text-14_21">ポートフォリオWebページへ直接アクセスした際の公開設定について確認できます。</p>
      <p className="text-14_21">
        設定の変更は、<ButtonTextLink href="/mypage/portfolio/setting">こちらから</ButtonTextLink>行うことができます。
      </p>
      <div>
        <div className="flex flex-row pt-24 pb-6 items-center">
            <p className="text-12_16 font-bold">現在の設定</p>
          </div>
        <div className="mkt_mask_items">
          <p className="mbx-textbox-color mbx-textbox">{ props.status }</p>
        </div>
      </div>
    </section>
  );
};
