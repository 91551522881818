import React, { useMemo, useState, useEffect } from 'react';

import Modal from '@/components/common/Modal';
import { FormContainerDropdown } from '@/components/common/Form/Container/Dropdown';
import { FormContainerTextArea } from '@/components/common/Form/Container/TextArea';
import BaseButton from '../Button/BaseButton';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { FormProvider, useForm } from 'react-hook-form';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import cn from 'classnames';

type Props = {
  id: number;
  status: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  statusUpdate: (id: number, status: number, comment: string) => Promise<void>;
  admin?: boolean;
  rate?: 1 | 2 | 3;
};

type TInput = {
  status: string | undefined;
  comment: string;
};

function OfferStatus(props: Props): React.ReactElement {
  const mq = useMBXMediaQuery();

  const [showModal, setShowModal] = useState(false);

  const schema = yup.object().shape({
    status: YUP_VALIDATION.required,
    comment: yup.string().when(['status'], {
      is: (status: string) => status === '7' || status === '11',
      then: YUP_VALIDATION.required,
    }),
  });

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      status: undefined,
      comment: '',
    },
  });

  const { handleSubmit, watch, reset, clearErrors } = methods;

  const rate = useMemo<string>(() => {
    switch (props.rate) {
      case 1:
        return 'マッチ度：低';
      case 2:
        return 'マッチ度：中';
      case 3:
        return 'マッチ度：高';
      default:
        return 'タグなし';
    }
  }, [props.rate]);

  const status = useMemo(() => {
    switch (props.status) {
      case 0:
        return {
          cn: 'bg-white text-gray-400',
          name: <>回答期限終了</>,
        };
      case 1:
        return {
          cn: 'bg-blue-300 text-blue-800',
          name: rate,
        };
      case 3:
        return {
          cn: 'bg-redGray-200 text-redGray-700',
          name: <>一次面接オファー回答待ち</>,
        };
      case 4:
        return {
          cn: 'bg-gray-200 text-gray-400',
          name: <>見送り</>,
        };
      case 5:
        return {
          cn: 'bg-gray-200 text-gray-400',
          name: <>見送り</>,
        };
      case 6:
        return {
          cn: 'bg-red-200 text-red-700',
          name: <>オファーに応諾</>,
        };
      case 7:
        return {
          cn: 'bg-attention-200 text-attention-700',
          name: <>マイナビワークスに相談中</>,
        };
      case 8:
        return {
          cn: 'bg-blueGray-200 text-blueGray-700',
          name: <>カジュアル面談オファー回答待ち</>,
        };
      case 9:
        return {
          cn: 'bg-blue-300 text-blue-800',
          name: <>オファーに応諾</>,
        };
      case 10:
        return {
          cn: 'bg-gray-200 text-gray-400',
          name: <>見送り</>,
        };
      case 11:
        return {
          cn: 'bg-attention-200 text-attention-700',
          name: <>マイナビワークスに相談中</>,
        };
    }
  }, [props.status, props.rate]);

  const reaction = useMemo(() => {
    switch (props.status) {
      case 3:
        return [
          { value: '6', children: 'オファーに応諾する' },
          { value: '7', children: 'マイナビワークスに相談する' },
          { value: '5', children: '見送る' }
        ];
      case 8:
        return [
          { value: '9', children: 'オファーに応諾する' },
          { value: '11', children: 'マイナビワークスに相談する' },
          { value: '10', children: '見送る' }
        ];
      default:
        return [];
    }
  }, [props.status]);

  const selectReaction = useMemo(() => {
    if (watch('status') === '6' || watch('status') === '9') {
      return '応諾';
    } else if (watch('status') === '7' || watch('status') === '11') {
      return '相談中';
    } else if (watch('status') === '5' || watch('status') === '10') {
      return '見送り';
    } else {
      return '';
    }
  }, [watch('status')]);

  useEffect(() => {
    if (watch('status') !== '7' && watch('status') !== '11') clearErrors('comment');
  }, [watch('status')]);

  const onClose = () => {
    setShowModal(false);
    reset();
  };

  const submit = (data: TInput) => {
    if (data.status) props.statusUpdate(props.id, Number(data.status), data.comment);
    onClose();
  }

  return (
    <section>
      {(props.status === 3 || props.status === 8) && !props.admin ? (
        <BaseButton
          theme="primary"
          size="ss"
          className="w-full"
          onClick={() => setShowModal(true)}
        >
          リアクションする
        </BaseButton>
      ) : (
        <div
          className={cn(
            'flex',
            'justify-center',
            'items-center',
            'h-30',
            'text-button_ss',
            'font-bold',
            'sm:h-36',
            status?.cn
          )}
        >
          <span>{status?.name}</span>
        </div>
      )}
      <Modal
        isOpen={showModal}
        isClose={onClose}
        className="modal-contents_add w-624 rounded-modalContainer"
      >
        <section className="p-80 sm:p-30">
          <div className={cn('font-bold', mq.sm ? 'text-18_33' : 'text-21_34')}>
            <p>企業のオファーにリアクションする</p>
          </div>
          <div>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(submit)}>
                <div className="mt-42">
                <p className="mbx-typography--caption_1 text-left">
                  <span>この企業のオファーに対するあなたのリアクションを投稿しましょう。</span>
                </p>
                  <FormContainerDropdown
                    className="select mkt_mask_items w-250 mt-8"
                    name="status"
                    selectList={reaction}
                    placeholder="実行するリアクションを選択"
                  ></FormContainerDropdown>
                </div>
                <p className="mt-8 mbx-typography--caption_1 text-left">
                  {selectReaction === '応諾' &&
                    <span>応諾すると、マイナビワークスがあなたと企業の面談および面接を設定します。<br />追ってマイナビワークスの担当者からご連絡しますのでしばらくお待ちください。</span>
                  }
                  {selectReaction === '相談中' &&
                    <span>このオファーについて、事前に気になることをマイナビワークスに相談できます。<br />内容確認後、追ってマイナビワークスの担当者からご連絡しますのでしばらくお待ちください。</span>
                  }
                  {selectReaction === '見送り' &&
                    <span>この企業からのオファーを断り、面談および面接の機会を見送ることができます。</span>
                  }
                </p>
                <div className="mt-32">
                  <p className="mbx-typography--caption_1 text-left">
                    <span>
                      {selectReaction !== '相談中' &&
                        <span>リアクションについて何か補足があればご記入ください。<br /></span>
                      }
                      なお、ここに入力した内容は企業に開示されず、マイナビワークスのみに通知されます。
                    </span>
                  </p>
                  <FormContainerTextArea
                    className="mkt_mask_items mt-8"
                    name="comment"
                    placeholder={selectReaction === '相談中' ? '例）求人に応募したい／仕事内容について詳しく聞きたい／条件面を追加で確認したい': ''}
                    maxLength={300}
                    rows={mq.sm ? 10 : 3}
                  />
                </div>
                <div className="flex justify-between mt-24">
                  <BaseButton
                    className="w-full mr-8"
                    theme="secondary"
                    size="m"
                    onClick={onClose}
                  >
                    キャンセル
                  </BaseButton>
                  <BaseButton theme="primary" size="m" className="w-full ml-8">
                    この内容で投稿
                  </BaseButton>
                </div>
              </form>
            </FormProvider>
          </div>
        </section>
      </Modal>
    </section>
  );
}

export default OfferStatus;
