import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ButtonIconButton } from '../Button/IconButton';
import { MatchDegree } from '../Navigation/Inpage/Menu/MatchDegree';

type TMatchDegreeProps = React.ComponentProps<typeof MatchDegree>;

type TProps = {
  children: string;
  progress?: TMatchDegreeProps['progress'];
  digit?: number;
  show: boolean;
  onClick?: MouseEventHandler<HTMLSpanElement>;
};

/**
 * PC: https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=278%3A106
 *
 * SP: https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=767%3A12366
 *
 * typeとprogressの使い方は MatchDegree
 *
 */
export const NotificationMatchbou = ({
  progress = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  children = '',
  digit,
  show = false,
  onClick,
}: TProps): React.ReactElement => {
  // !see https://github.com/reactjs/react-transition-group/issues/668
  const nodeRef = useRef(null);

  const [showState, setShow] = useState(show);
  const callBacks = {
    onEnter: () => console.log('show matchbou'),
    onExited: () => console.log('hide matchbou'),
  };

  useEffect(() => {
    setShow(show);
  }, [show]);

  return (
    <CSSTransition
      in={showState}
      timeout={550}
      nodeRef={nodeRef}
      {...callBacks}
      unmountOnExit
      classNames="mbx-anime-notification"
    >
      <div ref={nodeRef} className={'mbx-notification mbx-notification--matchbou'}>
        <div className="mbx-notification-inner">
          <div className={'mbx-notification-progress'}>
            <MatchDegree type="notification" progress={progress} digit={digit} />
          </div>
          <div className={'mbx-notification-message'}>{children}</div>
          <div className={'mbx-notification-icon'}>
            <ButtonIconButton
              type="red"
              iconName="Clear"
              hitArea="mini"
              onClick={onClick}
            ></ButtonIconButton>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
