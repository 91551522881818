import cn from 'classnames';
import React, { ReactElement, useEffect, useMemo, useState, VFC } from 'react';

import { PreviewFooter } from '@/components/common/Preview/Footer';
import { PreviewHeader } from '@/components/common/Preview/Header';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';

type TProps = {
  children?: ReactElement;
  title?: string;
  date?: string;
  page?: number;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=426%3A43023
 */
export const ResumePreview: VFC<TProps> = ({ children, title = '履歴書', date, page }) => {
  const [scale, setScale] = useState<number>(1);
  const [mbxPreviewPageWidth, setMbxPreviewPageWidth] = useState<number>(1);
  const [mbxPreviewContentWidth, setMbxPreviewContentWidth] = useState<number>(1);
  const [aspectRatio, setAspectRatio] = useState<number>(1);
  const mq = useMBXMediaQuery();

  useEffect(() => {
    if (!mq.lg) {
      setScale(mbxPreviewContentWidth / mbxPreviewPageWidth);
    } else {
      setScale(1);
    }
  }, [mq, mbxPreviewContentWidth, mbxPreviewPageWidth]);

  const height = useMemo(() => {
    if (!mq.lg) {
      return mbxPreviewContentWidth * aspectRatio;
    } else {
      return mbxPreviewPageWidth * aspectRatio;
    }
  }, [mq, mbxPreviewContentWidth, mbxPreviewPageWidth, aspectRatio, children]);

  useEffect(() => {
    const mbxPreviewPage = document.getElementsByClassName('mbx-preview-page')[0];
    if (!mbxPreviewPage) {
      return setAspectRatio(1);
    } else {
      return setAspectRatio(
        mbxPreviewPage.getBoundingClientRect().height / mbxPreviewPage.getBoundingClientRect().width
      );
    }
  }, [children]);

  useEffect(() => {
    const mbxPreviewPage = document.getElementsByClassName('mbx-preview-page')[0];
    // 履歴書全体を囲ってる親のdiv
    const mbxPreviewContent = document.getElementsByClassName('mbx-preview')[0];
    setMbxPreviewPageWidth(mbxPreviewPage.getBoundingClientRect().width);
    setMbxPreviewContentWidth(mbxPreviewContent.getBoundingClientRect().width);
    if (!mbxPreviewPage) {
      setAspectRatio(1);
    } else {
      setAspectRatio(
        mbxPreviewPage.getBoundingClientRect().height / mbxPreviewPage.getBoundingClientRect().width
      );
    }

    const resizeObserver = new ResizeObserver(() => {
      if (!mq.lg) {
        setScale(mbxPreviewContentWidth / mbxPreviewPageWidth);
      } else {
        setScale(1);
      }
    });
    resizeObserver.observe(window.document.body);
    return () => {
      resizeObserver.unobserve(window.document.body);
    };
  }, []);

  return (
    <div style={{ height }}>
      <section
        className={cn('mbx-preview-page bg-white mx-auto px-80')}
        style={{ transform: `scale(${scale})` }}
      >
        <PreviewHeader className="flex-shrink-0" title={title} date={date}></PreviewHeader>
        <section>{children}</section>
        <PreviewFooter className="folio mt-auto flex-shrink-0" page={page}></PreviewFooter>
      </section>
    </div>
  );
};
