import React from 'react';

export const Ed11 = (): React.ReactElement => {
  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16">
          <img className={style.img} src="../images/Top/event/event_0011_mv.jpg" width="978" height="504" alt="" />
        </div>
        <h1 className="mt-56 sm:mt-30 text-29_46 sm:text-18_24 font-bold text-center">12/21（水）20:00 - <br />
          ～ Webデザイナー編 ～ <br />ぶち当たったスキルの壁とそれを<br className="md:hidden lg:hidden" />乗り越えて見えてきたキャリア</h1>
        <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
          <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
            <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
            <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">以下に該当する方はオススメです。</h2>
          <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>キャリアアップに悩んでいるWebデザイナー</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>現役Webデザイナーのマインドやスキルについての話を聞いてみたい方</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>自分以外のWebデザイナーのキャリアについて話を聞きたい方</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>話を聞いて悩みをぶつけてみたい方</span>
              </li>
              <li className="flex">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>セミナーを踏まえて転職・書類作成の相談をしたい方</span>
              </li>
            </ul>
          </div>
        </section>
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">イベント内容</h3>
          <div className="mt-30 sm:mt-16 mb-30">
            <img src="../images/Top/event/event_seminar_image.jpg" width="980" height="374" alt="" />
          </div>
          <p className="text-16_28 sm:text-14_21 mt-30">
            デザイナーとしてのキャリアの中で、壁にぶち当たる瞬間はどなたにも1度はあるのではないでしょうか。その先には成長とキャリアの前進があるのだとは思うのですが、その壁の乗り越え方は人それぞれかと思います。私たちもセミナー行っている中で、参加者の皆さまからのお声として「自分以外のデザイナーの話を聞いてみたい！」というご要望をいくつもいくつもいただいておりました。<br />
            そこで今回、現役のWebデザイナーの杉村美智子さんから、デザイナーキャリアの中で訪れた停滞経験談とそこからの脱却ストーリーをお話いただきます。<br />
            現在Webデザイナーの皆さまの「あるある！」「私も同じことで悩んでいる！」という共感必至のセミナーになっています。皆さんの視界が次の日から少しでも晴れればいいなと思い企画いたしました。当日は、質疑応答のお時間も設けますので、セミナーの内容踏まえてご質問なども是非お待ちしております。<br />
            <br />
            興味がある方は是非、お早めにご応募ください。
          </p>
        </section>
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">参加者の声</h3>
          <div className="mt-30 mb-15 sm:mt-16 sm:mb-8 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4">
                <span>・</span><span>自分以外のデザイナーの悩みと、どうやって抜け出したのか実例を聞けてよかった。<br />（28歳 制作会社 Webデザイナー5年目）</span>
              </li>
              <li className="flex mb-4">
                <span>・</span><span>実業務にもつながるWeb・IT業界で働いている人からの話が聞けてとても参考になった。<br />
                  （27歳 広告代理店 Webデザイナー4年目）</span>
              </li>
              <li className="flex mb-4">
                <span>・</span><span>質疑応答で聞きたいことが聞けて、思考がクリアになった。 <br />（30歳 事業会社 UIデザイナー）</span>
              </li>
            </ul>
          </div>
          <p className="text-right text-16_28 sm:text-14_21">※参加者の声の一部を掲載しています</p>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">講師プロフィール</h2>
          <div className="event-instructor box-line sm:p-16">
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0011_prof.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                杉村 美智子（MICHIKO SUGIMURA）
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-40 sm:mb-18">
                株式会社あつまる<br />
                クリエイティブグループ デザインリード
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                グラフィックデザインを学び、看板制作会社、印刷会社を経て、株式会社あつまるへ入社。クライアントのWEBサイトやロゴ、印刷物、自社の広報ツールのデザインを行っている。<br />
                web未経験から、これまで約600サイト以上の制作に携わってきた。<br />
                自社の広報では、「社内報アワード2022」でブロンズ賞を受賞。
              </dd>
            </dl>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap" rowSpan={0}>
                  <span className="sm:block">20：00</span><span className="sm:block">～</span><span className="sm:block">21：20</span>
                </th>
                <td>
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>自己紹介</span></li>
                    <li className="flex"><span>・</span><span>最初の挫折</span></li>
                    <li className="flex"><span>・</span><span>デザインチェックをクリアした時の話</span></li>
                    <li className="flex"><span>・</span><span>グラフィックデザインからWebデザインに転身し、結果に繋げるデザインの壁</span></li>
                    <li className="flex"><span>・</span><span>Web制作の中での学び、気付き</span></li>
                    <li className="flex"><span>・</span><span>デザイナーとしてお客様へ大きく貢献できた話</span></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2022/12/21（水）20:00～<br />
                  [入室開始] 19:45～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">対象者</th>
                <td>今後のキャリア形成について考えているWebデザイナー</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.参加申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink"
                      href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.開催当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">開催について</h3>
          <p className="text-16_28 sm:text-14_21 mt-30 sm:mt-16">
            Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2,000名を超える「0からのWebディレクション講座」を生み出したメンバーでもあるSPEC.代表の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。          </p>
        </section>
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div>
    </div>
  );
};
