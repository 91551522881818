import React, { VFC } from 'react';

type TProps = {
  className?: string;
  children: string;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=92%3A183
 */
export const PreviewResumeTitle: VFC<TProps> = ({ className, children }) => {
  return (
    <div className={className}>
      <hr className="mbx-keyline-horizontal--bold"></hr>
      <p className="mbx-typography--subhead_2 pt-11 pb-12 px-8 w-136">{children}</p>
    </div>
  );
};
