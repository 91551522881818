import React, { VFC } from 'react';

import { ButtonTextLink } from '@/components/common/Button/TextLink';

type TProps = {
  className?: string;
  type: 'career' | 'qualification';
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=331%3A6760
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=331%3A7601
 *
 */
export const CareerQualificationEmpty: VFC<TProps> = ({ className, type = 'career' }) => {
  return (
    <div className={className}>
      <div className="flex items-center justify-center text-body_1 bg-gray-50 h-140 p-16 w-full">
        <div>
          <ButtonTextLink href="/mypage/cv/edit">職務経歴書</ButtonTextLink>
          <span>から{type === 'career' ? '職歴' : '資格・免許'}を追加してください。</span>
        </div>
      </div>
    </div>
  );
};
