import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { useUser } from '@/hooksDirect/useUser';

type TFormProps = {
  current_email: string;
  email: string;
};

/**
 * 個人設定 アカウント メールアドレス変更
 */
export const DirectUserSectionChangeMail = (): React.ReactElement => {
  const {
    companyUser,
    postEmailChange,
    emailChangeResponse,
    postEmailChangeValidateToken,
    getCompanyUser,
  } = useUser();
  const methods = useForm<TFormProps>({
    defaultValues: {
      current_email: companyUser?.email,
    },
  });
  const { handleSubmit } = methods;

  /**
   * tokenがあればtokenチェックしてメルアド変更完了
   * 新しいメルアドを反映するためにユーザー情報を再取得
   */
  useEffect(() => {
    const parameter = window.location.search.substring(1);
    if (!parameter) return;
    (async () => {
      await postEmailChangeValidateToken({
        token: parameter,
      });
      getCompanyUser();
    })();
  }, []);

  useEffect(() => {
    if (companyUser && companyUser.email) methods.setValue('current_email', companyUser.email);
  }, [companyUser]);

  useEffect(() => {
    if (emailChangeResponse && emailChangeResponse.status === 200) {
      methods.setValue('email', '');
    }
  }, [emailChangeResponse]);

  const onSubmit: SubmitHandler<TFormProps> = (data) => {
    (async () => {
      await postEmailChange({
        email: data.email,
      });
    })();
  };

  return (
    <section className="w-308 mx-auto pb-48">
      <header className="text-center mb-22">
        <h1 className="text-heading_4 font-bold mt-48 mb-24">メールアドレスの変更</h1>
        <p className="text-body_1">
          新しいメールアドレスへお送りするリンクを
          <br />
          クリックして、変更を完了してください。
        </p>
      </header>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mbx-utils-stack-v--22">
            <FormLayoutFieldset>
              <FormLabel label="現在のメールアドレス" type="small"></FormLabel>
              <FormContainerTextfield
                name="current_email"
                readOnly
                className="w-full mkt_mask_items"
                height="large"
              />
            </FormLayoutFieldset>
            <FormLayoutFieldset>
              <FormLabel label="新しいメールアドレス" type="small"></FormLabel>
              <FormContainerTextfield
                height="large"
                name="email"
                placeholder="例）suzuki.ichiro@matchbox.com"
                className="w-full mkt_mask_items"
              />
            </FormLayoutFieldset>
          </div>
          <BaseButton size="m" theme="primary" type="submit" className="w-full mt-24">
            メールアドレスを変更する
          </BaseButton>
        </form>
      </FormProvider>
    </section>
  );
};
