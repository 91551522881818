import { createSlice } from '@reduxjs/toolkit';

import { reducers } from './reducer';
import { initialState } from './state';

/**
 * ログイン情報やユーザー情報などを
 */
export const directAuthModule = createSlice({
  name: 'direct_auth',
  initialState,
  reducers,
});

export const { setLogin, setUserInfo, isLoggedIn, isLoggedOut } = directAuthModule.actions;
