import { AxiosError } from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MESSAGE } from '@/definitionDirect/MESSAGE';
import { notificationError, notificationSuccess, toggleLoading } from '@/reduxAdmin/modules/utils';
import {
  DirectApi,
  DirectOfferPositionParam,
  InlineObject17,
  OfferPositionData,
  ResponseError,
} from '@/utils/api-client/api';

type TReturn = {
  offerPositions: Array<OfferPositionData> | undefined;
  offerPositionId: OfferPositionData | undefined;
  getOfferPositions: () => Promise<void>;
  getOfferPositionId: (id: string) => Promise<void>;
  pathOfferPositions: (request: InlineObject17) => Promise<void>;
  patchOfferPositionId: (idPathParam: string, request: DirectOfferPositionParam) => void;
  postOfferPosition: (request: DirectOfferPositionParam) => Promise<void>;
  deleteOfferPositionId: (idPathParam: string) => Promise<void>;
};

/**
 * ポジション関連処理
 */
export const useOfferPositions = (): TReturn => {
  const API = new DirectApi();
  const dispatch = useDispatch();
  const [offerPositions, setOfferPositions] = useState<Array<OfferPositionData>>();
  const [offerPositionId, setOfferPositionId] = useState<OfferPositionData>();

  /**
   * ポジション一覧情報取得
   */
  const getOfferPositions = () => {
    dispatch(toggleLoading(true));
    return API.getDirectOfferPositions()
      .then((res) => {
        setOfferPositions(res.data.positions);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 詳細取得
   */
  const getOfferPositionId = (id: string) => {
    dispatch(toggleLoading(true));
    return API.getDirectOfferPositionId(id)
      .then((res) => {
        setOfferPositionId(res.data.data);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 並び替え
   */
  const pathOfferPositions = (request: InlineObject17) => {
    dispatch(toggleLoading(true));
    return API.patchDirectOfferPositions(request)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.saving_completed_for_position));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 編集
   */
  const patchOfferPositionId = (idPathParam: string, request: DirectOfferPositionParam) => {
    dispatch(toggleLoading(true));
    API.patchDirectOfferPositionId(idPathParam, request)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.saving_completed_for_position));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) {
          dispatch(notificationError(error.response.data.error_message));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 追加
   */
  const postOfferPosition = (request: DirectOfferPositionParam) => {
    dispatch(toggleLoading(true));
    return API.postDirectOfferPosition(request)
      .then(() => {
        setTimeout(() => {
          dispatch(notificationSuccess(MESSAGE.add_position));
        }, 400);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 削除
   */
  const deleteOfferPositionId = (idPathParam: string) => {
    dispatch(toggleLoading(true));
    return API.deleteDirectOfferPositionId(idPathParam)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.deleted_position));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
        dispatch(toggleLoading(false));
      });
  };

  return {
    getOfferPositionId,
    offerPositions,
    getOfferPositions,
    pathOfferPositions,
    patchOfferPositionId,
    offerPositionId,
    postOfferPosition,
    deleteOfferPositionId,
  };
};
