import '@/styles/common/Direct/Step1.scss';
import React from 'react';
import Indicator from '@/components/common/Direct/Indicator';
import BaseButton from '../Button/BaseButton';
import { MyNaviSelectForm } from './MyNaviSelectForm';
import RequestTitle from './RequestTitle';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';

type Props = {
  setStep: React.Dispatch<
    React.SetStateAction<{
      step1: boolean;
      step2: boolean;
      step3: boolean;
    }>
  >;
};

function Step1(props: Props): React.ReactElement {
  const stepUpdate = () => {
    props.setStep({
      step1: false,
      step2: true,
      step3: false,
    });
  };
  return (
    <DataLayerPushContainer
      data={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'page_view',
        virtualPageName: 'step_1'
      }}
    >
    <section className="step1">
      <section className="flex justify-center mt-48">
        <Indicator step={1} />
      </section>
      <RequestTitle title="経験・希望を確認しアップデート">
        <p className="pt-10 pb-34">経験・希望を最新の状態にアップデートし、<br />オファー機能の登録にお進みください。</p>
      </RequestTitle>
      <div className="mb-96 sm:mb-212">
        <MyNaviSelectForm afterSending={stepUpdate} cancelButton={true} offer={true}>
          <div className="m-auto mt-48 text-center">
            <BaseButton theme="primary" size="m" className="send-btn">
              保存して次へ
            </BaseButton>
          </div>
        </MyNaviSelectForm>
      </div>
    </section>
    </DataLayerPushContainer>
  );
}

export default Step1;
