import React from 'react';
import { BlockCompany } from '@/utils/api-client';
import cn from 'classnames';

type Props = {
  blockCompanies: BlockCompany[];
  isDelete: boolean;
  onDelete?: (id: string) => void;
};

function BlockList(props: Props): React.ReactElement {
  const onClick = (id: string) => {
    if (props.onDelete) {
      props.onDelete(id);
    }
  };
  return (
    <section className="p-40 bg-gray-50 border-2 rounded-4 border-gray-600 text-left">
      <div className={cn('overflow-scroll overflow-x-auto', props.isDelete ? 'max-h-300' : 'max-h-212')}>
        <p className="mbx-typography--subhead_1">ブロックしている企業</p>
        <ul className="mbx-typography--body_2">
          {props.blockCompanies?.length === 0 ? (
            <li className="mt-12">ブロックしている企業はありません。</li>
          ) : (
            props.blockCompanies?.map((list, index) => {
              return (
                <li key={index}>
                  {props.isDelete ? (
                    <span className="flex items-center mt-28">
                      <i
                        className="mbx-icon mbx-icon-Circle-large_Clear ml-4 cursor-pointer hover:text-gray-500"
                        onClick={() => onClick(list.id)}
                      ></i>
                      <span className="ml-8 mkt_mask_items">{list.name}</span>
                    </span>
                  ) : (
                    <span className="inline-block mt-12 mkt_mask_items">{list.name}</span>
                  )}
                </li>
              );
            })
          )}
        </ul>
      </div>
    </section>
  );
}

export default BlockList;
