import { useMediaQuery } from 'react-responsive';

type TProps = {
  lg: boolean;
  md: boolean;
  sm: boolean;
  smm: boolean;
  sms: boolean;
};

export const useMBXMediaQuery = (): TProps => {
  const mediaQuery = {
    lg: useMediaQuery({ minWidth: 1024 }),
    md: useMediaQuery({ minWidth: 768, maxWidth: 1023 }),
    sm: useMediaQuery({ maxWidth: 767 }),
    smm: useMediaQuery({ minWidth: 321, maxWidth: 767 }),
    sms: useMediaQuery({ maxWidth: 320 }),
  };
  return mediaQuery;
};
