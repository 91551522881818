import './index.css';
import './assets/fonts/entry.js';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Auth } from '@/components/common/Auth';
import { PrivateRoute } from '@/components/common/Route/PrivateRoute';
import { PublicRoute } from '@/components/common/Route/PublicRoute';
import { RetentionRoute } from '@/components/common/Route/RetentionRoute';
import { IndexAdmin } from '@/indexAdmin';
import { IndexDirect } from '@/indexDirect';
import Login from '@/pages/Account/Login';
import { CareerHistoryEdit, CareerHistoryPreview } from '@/pages/CareerHistory/';
import DirectRequest from '@/pages/Direct/DirectRequest';
import DirectStatus from '@/pages/Direct/DirectStatus';
import { MypageAccount } from '@/pages/Mypage/Account';
import { MypageCondition } from '@/pages/Mypage/Condition';
import { MypageProfile } from '@/pages/Mypage/Profile';
import { MypageSetting } from '@/pages/Mypage/Setting';
import { MypageTop } from '@/pages/Mypage/Top';
import { SelfPortfolio } from '@/pages/Mypage/SelfPortfolio';
import {
  DetailEdit,
  DetailsPreview,
  ProfileEdit,
  ProfilePreview,
  SettingEdit,
  TopEdit,
  TopPreview,
} from '@/pages/Portfolio/';
import { PagesResumeEdit } from '@/pages/Resume/Edit';
import { PagesResumePreview } from '@/pages/Resume/Preview';
import Top from '@/pages/Top';
import { Interview } from './pages/Interview/Interview';
import { Event } from '@/pages/Event/Event';
import { EventDetail } from '@/pages/Event/EventDetail';
import config, { ConfigContext } from '@/utils/config';

import PasswordChange from './pages/Account/PasswordChange';
import Register from './pages/Account/Register';
import Error from './pages/Error';
import Index from './pages/Index';
import Faq from './pages/Other/Faq';
import TermsOfUse from './pages/Other/TermsOfUse';
import store from './redux';
import reportWebVitals from './reportWebVitals';
import { DataLayerProviderContainer } from './components/common/DataLayerProviderContainer';

const Router = BrowserRouter;

// if (process.env.NODE_ENV === 'development') {
//   Router = HashRouter;
// }
console.info('APP VERSION', process.env.REACT_APP_COMMIT_HASH);

const isUser = !/^\/admin\/|^\/direct\//.test(location.pathname);

ReactDOM.render(
  <>
    {/** 管理者用 */}
    <IndexAdmin />
    {/** 企業用 */}
    <IndexDirect />
    {/** 上記のページとはLink介して遷移しないはずなのでUser向けのURIだった場合のみ有効にして表示を切り分ける */}
    {isUser && (
      <ConfigContext.Provider value={config}>
        <Provider store={store}>
          <DataLayerProviderContainer>
            <Auth>
              <Router>
                <Switch>
                  {/** Debug */}
                  <Route exact path="/index" component={Index} />
                  {/** Other */}
                  <Route exact path="/terms_of_use" component={TermsOfUse} />
                  {/* <Route exact path="/faq" component={Faq} /> */}
                  <Route exact path="/interview/:id" component={Interview} />
                  <RetentionRoute exact path="/event/" component={Event} />
                  <RetentionRoute exact path="/event/:id" component={EventDetail} />
                  {/** Auth */}
                  <PublicRoute exact path="/" component={Top} />
                  <PublicRoute exact path="/login" component={Login} />
                  <PublicRoute exact path="/login/reminder" component={PasswordChange} />
                  <PublicRoute exact path="/entry" component={Register} />
                  {/** Mypage */}
                  <PrivateRoute exact path="/mypage" component={MypageTop} />
                  <PrivateRoute exact path="/mypage/direct" component={DirectRequest} />
                  <PrivateRoute exact path="/mypage/direct_status" component={DirectStatus} />
                  <PrivateRoute exact path="/mypage/setting" component={MypageSetting} />
                  <PrivateRoute exact path="/mypage/profile" component={MypageProfile} />
                  <PrivateRoute exact path="/mypage/condition" component={MypageCondition} />
                  <PrivateRoute exact path="/mypage/account" component={MypageAccount} />
                  {/** Resume Preview */}
                  <PrivateRoute exact path="/mypage/resume" component={PagesResumePreview} />
                  {/** Resume Edit */}
                  <PrivateRoute exact path="/mypage/resume/edit" component={PagesResumeEdit} />
                  {/** CareerHistory Preview */}
                  <PrivateRoute exact path="/mypage/cv" component={CareerHistoryPreview} />
                  {/** CareerHistory Edit */}
                  <PrivateRoute exact path="/mypage/cv/edit" component={CareerHistoryEdit} />
                  {/** Portfolio Edit */}
                  <PrivateRoute exact path="/mypage/portfolio/edit" component={TopEdit} />
                  <PrivateRoute
                    exact
                    path="/mypage/portfolio/:user_id/profile/edit"
                    component={ProfileEdit}
                  />
                  <PrivateRoute
                    exact
                    path="/mypage/portfolio/:user_id/:work_id/edit"
                    component={DetailEdit}
                  />
                  <PrivateRoute exact path="/mypage/portfolio/setting" component={SettingEdit} />
                  {/** Portfolio Preview */}
                  <PrivateRoute exact path="/mypage/portfolio/:user_id" component={TopPreview} />
                  <PrivateRoute
                    exact
                    path="/mypage/portfolio/:user_id/profile"
                    component={ProfilePreview}
                  />
                  <PrivateRoute
                    exact
                    path="/mypage/portfolio/:user_id/:work_id"
                    component={DetailsPreview}
                  />
                  {/** Public Portfolio */}
                  <Route
                    exact
                    path="/portfolio/:user_id"
                    component={() => <TopPreview presentation />}
                  />
                  <Route
                    exact
                    path="/portfolio/:user_id/profile"
                    component={() => <ProfilePreview presentation />}
                  />
                  <Route
                    exact
                    path="/portfolio/:user_id/:work_id"
                    component={() => <DetailsPreview presentation />}
                  />
                  {/** self-portfolio */}
                  <PrivateRoute
                    exact
                    path="/mypage/self-portfolio/edit"
                    component={SelfPortfolio}
                  />
                  {/** General */}
                  <Route component={Error} />
                </Switch>
              </Router>
            </Auth>
          </DataLayerProviderContainer>
        </Provider>
      </ConfigContext.Provider>
    )}
  </>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
