import cn from 'classnames';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';
import { RootState } from '@/reduxAdmin';
import { DirectUserResponse, Offer, ReactionsResponseAllOfReactions } from '@/utils/api-client';
import { convertDateFormat, noDataText } from '@/utils/string';

import { DirectOffersModalMessageFromCompanyComponent } from './ModalMessageFromCompany';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type Props = {
  offer: Offer;
};

export const UserList = (props: Props): React.ReactElement => {
  // ====================================================================================================
  //
  // CONST
  //
  // ====================================================================================================
  const history = useHistory();
  const user: DirectUserResponse | null = useSelector(
    (state: RootState) => state.direct_auth.userInfo
  );

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const myself = useMemo(() => {
    if (!props.offer.reactions) return false;
    const reactions = props.offer.reactions as ReactionsResponseAllOfReactions[];
    const result = reactions.some((r) => r.user?.matchbox_id === user?.user_info.matchbox_id);
    return result;
  }, [props.offer, user]);

  const isAction = useMemo(() => {
    return props.offer.status !== 1;
  }, [props.offer.status]);

  const style = useMemo(() => {
    const BLUE = ['bg-blue-100', 'border-gray-200'];
    const GRAY = ['bg-gray-100', 'border-gray-300'];
    const DARKGRAY = ['bg-gray-400', 'border-gray-300'];
    const WHITE = ['bg-white', 'border-gray-300'];
    if (props.offer.user_disabled) return DARKGRAY;
    if (props.offer.mw_status === 3) return GRAY;
    if (!isAction && !myself) return BLUE;
    if (props.offer.status === 0 || props.offer.status === 4 || props.offer.status === 5) {
      return GRAY;
    }
    return WHITE;
  }, [props.offer, myself]);

  const format = useMemo(() => {
    const created_at = convertDateFormat(props.offer.created_at);
    const rate = DIRECT_FORM_OPTIONS.offer_mw_status.find(
      (r) => r.value === props.offer.rate?.toString()
    )?.children;
    const job = FORM_OPTIONS.experienced_job.find(
      (j) => j.value === props.offer.mc_experienced_job?.toString()
    )?.children;
    return { created_at, rate, job };
  }, [props.offer]);

  const status = useMemo(() => {
    if (props.offer.status === 0) return '回答期限終了';
    if (props.offer.status === 1) return 'ピックアップ';
    if (props.offer.status === 2) return 'マイナビワークスに相談中';
    if (props.offer.status === 3 || props.offer.status === 6 || props.offer.status === 7)
      return '一次面接オファー中';
    if (props.offer.status === 8 || props.offer.status === 9 || props.offer.status === 11)
      return 'カジュアル面談オファー中';
    if (props.offer.status === 4 || props.offer.status === 5 || props.offer.status === 10)
      return '見送り';
  }, [props.offer]);

  // ====================================================================================================
  //
  // EVENT
  //
  // ====================================================================================================
  const onClickPage = () => {
    if (props.offer.user_disabled) return;
    history.push({
      pathname: `/direct/users/${props.offer.matchbox_id}`,
      state: { referrer: 'candidates' },
    });
  };

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <section className={cn(style, 'border-b')}>
      <div className="flex items-center pl-16 h-64 text-12_12 relative">
        <div className="flex items-center w-84 whitespace-nowrap">
          {!myself && !isAction && <p className="w-8 h-8 mr-8 bg-red-700 rounded-full"></p>}
          <p>{format.created_at}</p>
        </div>
        <div className={cn('w-62 ml-16', { 'mbx-tooltip--hover': props.offer.user_disabled })}>
          <p
            className={cn({ 'text-blue-700 cursor-pointer': !props.offer.user_disabled })}
            onClick={onClickPage}
          >
            {props.offer.matchbox_id}
          </p>
          <div className="relative flex justify-center">
            <div className="mbx-admin_tooltip" style={{ top: 10 }}>
              現在、この候補者はオファー機能の受信設定を停止しています。
            </div>
          </div>
        </div>
        <div className="ml-16 overflow-hidden w-135">
          <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">{format.job}</p>
        </div>
        <div className="ml-16 w-80">
          <p className="leading-5">{status}</p>
        </div>
        <div className="ml-16 w-60">
          <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">
            {noDataText(props.offer.position?.name)}
          </p>
        </div>
        <div className="ml-16 text-center w-40">
          <p>{format.rate}</p>
        </div>
        <div className="w-60 ml-16 text-center">
          <p>{props.offer.reactions?.length}件</p>
        </div>
        <div className="w-60 text-center ml-34">
          {myself ? <p>済</p> : <p className="text-red-700 font-bold">未投稿</p>}
        </div>
        <div className="w-72 ml-auto text-center">
          {isAction ? <p>済</p> : <p className="text-red-700 font-bold">未アクション</p>}
        </div>
        <div className="w-24 h-24 ml-16">
          {isAction && (
            <DirectOffersModalMessageFromCompanyComponent
              status={props.offer.status}
              comment={props.offer.comment}
              position={props.offer.position}
            ></DirectOffersModalMessageFromCompanyComponent>
          )}
        </div>
        <div
          className={cn('w-66 ml-auto pr-10', { 'mbx-tooltip--hover': props.offer.user_disabled })}
        >
          {props.offer.id && (
            <>
              <BaseButton
                theme="secondary"
                size="ss"
                className="w-full"
                href={
                  !props.offer.user_disabled
                    ? decodeURI(`/direct/candidates/${props.offer.id}`)
                    : ''
                }
                disabled={props.offer.user_disabled ? true : false}
              >
                詳細
              </BaseButton>
              <div className="relative flex justify-center">
                <div className="mbx-admin_tooltip">
                  現在、この候補者はオファー機能の受信設定を停止しています。
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};
