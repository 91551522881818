import React, { useEffect } from 'react';

import { TFormOption } from '@/definition/FORM_OPTIONS';

import { FormDropdownSelectionsItem } from './Item';

type TProps = {
  selectList: Array<TFormOption>;
  className?: string;
  placeholder?: string;
  itemClick?: (value: string) => void;
  value?: string | number;
  disabledPlaceholder?: boolean;
  parentRef: React.RefObject<HTMLDivElement>;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=393%3A40447
 */
export const FormDropdownSelectionsBase = ({
  selectList,
  className = '',
  placeholder = '',
  itemClick,
  value = '',
  disabledPlaceholder,
  parentRef,
}: TProps): React.ReactElement => {
  useEffect(() => {
    if (!parentRef.current) return;
    const mbxDropdownSelections = parentRef.current.children[0];
    if (!mbxDropdownSelections) return;
    // ドロップダウンの高さ
    const dropdownHeight = mbxDropdownSelections.clientHeight;
    // スクロールの長さ
    const scrollHeight = mbxDropdownSelections.scrollHeight;
    // スクロールが発生する場合はスクロールバーを表示する
    if (dropdownHeight < scrollHeight) {
      mbxDropdownSelections.classList.add('mbx-dropdown-scroll');
      const scrollbar = mbxDropdownSelections.lastChild as HTMLElement;
      if (!scrollbar) return;
      const scrollbarHeight = (dropdownHeight / scrollHeight) * dropdownHeight;
      scrollbar.style.height = `${scrollbarHeight}px`;
      // 最下部までスクロールした時のTopの位置
      const scrollPositionBottom =
        scrollHeight - dropdownHeight + (dropdownHeight - scrollbarHeight);
      // スクロールの倍率
      const scrollMagnification = scrollPositionBottom / (scrollHeight - dropdownHeight);

      mbxDropdownSelections.addEventListener('scroll', () => {
        scrollbar.style.transform = `translate(0, ${
          mbxDropdownSelections.scrollTop * scrollMagnification
        }px)`;
      });
    }
  }, [parentRef.current]);

  return (
    <ul className={'mbx-dropdown-selections ' + className}>
      {placeholder && (
        <FormDropdownSelectionsItem
          value={''}
          onClick={itemClick}
          select={value === '' ? true : false}
          disabled={disabledPlaceholder}
        >
          {placeholder}
        </FormDropdownSelectionsItem>
      )}
      {selectList.map((item) => {
        return (
          <FormDropdownSelectionsItem
            value={item.value}
            key={item.value}
            onClick={itemClick}
            select={value == item.value ? true : false}
          >
            {item.children}
          </FormDropdownSelectionsItem>
        );
      })}
      <p className="mbx-dropdown-scroll-bar"></p>
    </ul>
  );
};
