import logger from 'redux-logger';

import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

import { adminAuthModule } from './modules/adminAuth';
import { adminDirectSearchConditionModule } from './modules/adminDirectSearchCondition';
import { adminMasterModule } from './modules/adminMaster';
import { adminSearchConditionModule } from './modules/adminSearchCondition';
import { directAuthModule } from './modules/directAuth';
import { directSearchConditionModule } from './modules/directSearchCondition';
import { portfolioModule } from './modules/portfolio';
import { utilsModule } from './modules/utils';

/**
 * utilsは共有したいのでDirectとAdminのデータは同じreduxで管理
 */
const rootReducer = combineReducers({
  utils: utilsModule.reducer,
  portfolio: portfolioModule.reducer,
  direct_auth: directAuthModule.reducer,
  direct_search_condition: directSearchConditionModule.reducer,
  admin_auth: adminAuthModule.reducer,
  admin_master: adminMasterModule.reducer,
  admin_search_condition: adminSearchConditionModule.reducer,
  admin_direct_search_condition: adminDirectSearchConditionModule.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

let middleware = getDefaultMiddleware();

if (process.env.NODE_ENV === 'development') {
  middleware = middleware.concat(logger);
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
});

export type AppDispatch = typeof store.dispatch;
