import cn from 'classnames';
import React from 'react';

type TProps = {
  className?: string;
  type?: 'postalCode' | 'name';
  children?: string | React.ReactElement;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=92%3A199
 * 郵便番号と名前だけデザインが少し違うので props を受け取り出し分ける
 */
export const PreviewResumeText = ({ className, type, children }: TProps): React.ReactElement => {
  return (
    <div className={className}>
      <hr className="mbx-keyline-horizontal--thin"></hr>
      <p
        className={cn('py-12', 'px-8', 'mkt_mask_items', {
          'mbx-typography--body_2': type !== 'name',
          'text-32_74': type === 'name',
          'pt-36': type === 'postalCode',
        })}
      >
        {children}
      </p>
    </div>
  );
};
