import React, { useMemo } from 'react';
import { getOptionChildrenFromValue } from '@/utils/optionValueToChildren';
import dayjs from 'dayjs';
import {
  PortfolioItem,
  Question,
  PortfolioPagePPageLayoutEnum,
  PortfolioPage,
} from '@/utils/api-client';
import { N2br } from '../../Utils/N2br';

type Props = {
  portfolioItem: PortfolioItem;
};

export const MainQuestions = (props: Props): React.ReactElement => {
  const p_contentsAnswer = (key: string) => {
    if (!props.portfolioItem?.i_pages) return;
    const contents = Array.from(props.portfolioItem?.i_pages.values()).find(
      (p) => p.p_page_layout === 'questions'
    )?.p_contents;
    if (contents) {
      const content = Array.from(contents?.values()).find((c) => (c as Question).qa_id === key);
      if (content) {
        if (!(content as Question).qa_answer.length) return;
        //質問の回答が配列ではない場合
        if (typeof (content as Question).qa_answer === 'string') {
          return (content as Question).qa_answer;
        }
        return (content as Question).qa_answer.filter((a) => {
          a = a || '';
          return a.trim() !== '';
        });
      }
    }
  };

  const p_contentsC_items = useMemo<Question[] | undefined>(() => {
    if (!props.portfolioItem?.i_pages) return;

    const contents: PortfolioPage | undefined = Array.from(props.portfolioItem?.i_pages).find(
      (p) => p.p_page_layout === PortfolioPagePPageLayoutEnum.Questions
    );

    if (contents) {
      const regexp = /^c_/;
      const questions = Array.from(contents?.p_contents as Set<Question>).filter((c) => regexp.test(c.qa_id));
      return questions.sort((a, b) => {
        return (a.qa_id < b.qa_id) ? -1 : 1;
      });
    }
  }, [props.portfolioItem]);

  return (
    <section className="details-left pt-24">
      {props.portfolioItem?.i_description && (
        <div className="list">
          <p className="title mbx-typography--body_1 mt-24">概要</p>
          <p className="mbx-typography--body_1 mkt_mask_items">
            <N2br>{props.portfolioItem?.i_description}</N2br>
          </p>
        </div>
      )}
      {props.portfolioItem?.i_start_date && props.portfolioItem?.i_end_date && (
        <div className="list">
          <p className="title mbx-typography--body_1 mt-24">制作期間</p>
          <p className="mbx-typography--body_1 mkt_mask_items">
            <span>{dayjs(props.portfolioItem?.i_start_date).format('YYYY年MM月')}</span>
            <span>〜</span>
            <span>{dayjs(props.portfolioItem?.i_end_date).format('YYYY年MM月')}</span>
          </p>
        </div>
      )}
      {getOptionChildrenFromValue('production_duration', Number(p_contentsAnswer('b_4'))) && (
        <div className="list">
          <p className="title mbx-typography--body_1 mt-24">制作にかかった期間</p>
          <p className="mbx-typography--body_1 mkt_mask_items">
            {getOptionChildrenFromValue('production_duration', Number(p_contentsAnswer('b_4')))}
          </p>
        </div>
      )}
      {p_contentsAnswer('a_2') && p_contentsAnswer('a_2')?.length !== 0 && (
        <div className="list">
          <p className="title mbx-typography--body_1 mt-24">受賞歴</p>
          <p className="mbx-typography--body_1 mkt_mask_items">
            {p_contentsAnswer('a_2')?.join(' / ')}
          </p>
        </div>
      )}
      {p_contentsC_items?.map((qa: Question, index: number) => {
        return (
          <div className="list" key={index}>
            <p className="title mbx-typography--body_1 mt-24">{qa.qa_question}</p>
            <p className="mbx-typography--body_1 mkt_mask_items">
              <N2br>{qa.qa_answer.join('')}</N2br>
            </p>
          </div>
        );
      })}
    </section>
  );
};
