import '@/styles/common/Preview/preview.scss';

import React, { VFC } from 'react';

import {
    BusinessContent, CareerTitle, CompanyContent, ProjectContent
} from '@/components/CareerHistory';
import { ResumePreview } from '@/components/common/Preview/ResumePreview';
import { PreviewResumeText } from '@/components/common/Preview/ResumeText';
import { PreviewResumeTitle } from '@/components/common/Preview/ResumeTitle';
import { N2br } from '@/components/common/Utils/N2br';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { CareerHistory, Career, QualificationQTypeEnum, Status, UserInfo } from '@/utils/api-client/api';

type TProps = {
  scale?: number;
  height?: number;
  date: string;
  user: UserInfo | null;
  fetchData: (CareerHistory & Status & UserInfo) | undefined;
  type?: 'admin' | 'direct' | 'public';
};

export const PreviewPage: VFC<TProps> = ({ date, user, fetchData, type }) => {
  const period = (start: string): string => {
    const s = start.split('-');
    return `${s[0]}年${s[1]}月`;
  };

  const stars = (level: number | undefined = 0) =>
    Array(5)
      .fill(null)
      .map((val, i) => (i < level ? '★' : '☆'))
      .join('');

  const experience = (val: number | undefined) => {
    if (!val) return '';
    return FORM_OPTIONS.experienced_skill.find((item) => item.value === `${val}`)?.children;
  };

  const careerOrder = (career: Career) => {
    const visibleCareers = fetchData?.careers?.filter((c) => c.c_visible).map((c) => c.c_id);
    if(!visibleCareers) return 0;
    const order = visibleCareers?.indexOf(career.c_id) + 1;
    return order;
  }

  return (
    <div>
      <ResumePreview title="職務経歴書" date={date}>
        <>
          <div className="flex">
            <div className="w-full">
              <div className="flex ">
                <PreviewResumeText className="w-full flex-shrink-0 mkt_mask_items">
                  {fetchData?.job}
                </PreviewResumeText>
              </div>
              <PreviewResumeText className="w-full mkt_mask_items" type="name">
                {user?.name}
              </PreviewResumeText>
            </div>
          </div>
          <div className="flex mt-30">
            <PreviewResumeTitle>職務経歴概要</PreviewResumeTitle>
            <div className="w-full">
              <div className="flex">
                <PreviewResumeText className="w-full mkt_mask_items">
                  <N2br>{fetchData?.description}</N2br>
                </PreviewResumeText>
              </div>
            </div>
          </div>

          <div className="flex mt-30">
            <PreviewResumeTitle>活かせる経験・ スキル・得意分野</PreviewResumeTitle>
            <div className="w-full">
              <div className="flex">
                <PreviewResumeText className="w-full mkt_mask_items">
                  <N2br>{fetchData?.advantage}</N2br>
                </PreviewResumeText>
              </div>
            </div>
          </div>

          <div className="mt-30">
            <PreviewResumeTitle className="w-full mb-18">会社履歴</PreviewResumeTitle>
            <CompanyContent careers={fetchData?.careers} />
          </div>

          <div className="mt-30">
            <PreviewResumeTitle className="w-full mb-18">職務経歴詳細</PreviewResumeTitle>
            {fetchData?.careers?.map((career, index) => {
              if(career.c_visible) {
                return (
                  <div key={index} className="mb-20">
                    <CareerTitle className="mbx-career-title" index={careerOrder(career)}>
                      <>{career.c_company_name}</>
                    </CareerTitle>

                    <BusinessContent career={career} type={type}>
                      <>
                        {career.c_projects?.map((project, index) => (
                          <ProjectContent key={index} project={project} />
                        ))}
                      </>
                    </BusinessContent>
                  </div>
                )
              }
            })}
          </div>

          <div className="flex mt-30">
            <PreviewResumeTitle>資格・免許</PreviewResumeTitle>
            <div className="w-full">
              <div className="flex">
                <PreviewResumeText className="w-full mkt_mask_items">
                  <>
                    {fetchData?.qualifications?.map((q) => {
                      if(q.q_visible) {
                        if (q.q_type === QualificationQTypeEnum.Text) {
                          return (
                            <>
                              {period(q.q_date)} {q.q_title} 取得
                              <br />
                            </>
                          );
                        } else if (parseInt(q.q_score) > 0 || isNaN(Number(q.q_score))) {
                          return (
                            <>
                              {q.q_type === 'toeic' && (
                                <>
                                  {period(q.q_date)} {"TOEIC"} {q.q_score}点 取得
                                  <br />
                                </>
                              )}
                              {q.q_type === 'toefl' && (
                                <>
                                  {period(q.q_date)} {"TOEFL"} {q.q_score}点 取得
                                  <br />
                                </>
                              )}
                            </>
                          )
                        }
                      }
                    })}
                  </>
                </PreviewResumeText>
              </div>
            </div>
          </div>

          <div className="flex mt-30">
            <PreviewResumeTitle>テクニカルスキル</PreviewResumeTitle>
            <div className="w-full">
              <div className="flex">
                <PreviewResumeText className="w-full mkt_mask_items">
                  <>
                    {fetchData?.skills?.map((s) => (
                      <>
                        {s.name}
                        <br />
                        {stars(s.level)}経験 {experience(s.experience)}
                        <br />
                      </>
                    ))}
                  </>
                </PreviewResumeText>
              </div>
            </div>
          </div>

          <div className="flex mt-30">
            <PreviewResumeTitle>自己PR</PreviewResumeTitle>
            <div className="w-full">
              <div className="flex">
                <PreviewResumeText className="w-full mkt_mask_items">
                  <N2br>{fetchData?.pr}</N2br>
                </PreviewResumeText>
              </div>
            </div>
          </div>
        </>
      </ResumePreview>
    </div>
  );
};
