import { DirectSearchConditionModel } from '@/utils/api-client';

export type State = {
  condition: DirectSearchConditionModel | null;
};

export const initialState: State = {
  condition: localStorage.getItem('directCondition')
    ? (JSON.parse(localStorage.getItem('directCondition') as string) as DirectSearchConditionModel)
    : null,
};
