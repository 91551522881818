import { TFormOption } from '@/definition/FORM_OPTIONS';

/**
 * データがない場合は未設定を表示
 */
export const noDataText = (str: string | number | undefined | null): string | number => {
  if (str) {
    return str;
  } else {
    return '未設定';
  }
};

export const noSelectText = (str: string | number | undefined | null): string | number => {
  if (str) {
    return str;
  } else {
    return '未選択';
  }
};

/**
 * YYYY-MM-DD な書式の文字列を分割して戻す
 */
export const dataStringSplitter = (str: string | undefined | null): Array<string> => {
  if (str) {
    return str.split('-');
  } else {
    return ['', '', ''];
  }
};

/**
 * YYYY MM DD な値から現在年齢を返す
 */
export const getAge = (birthYear: number, birthMonth: number, birthDay: number): number => {
  const currentDate = new Date();
  let age = currentDate.getFullYear() - birthYear;
  //誕生日がまだ来ていなければ、1引く
  if (
    currentDate.getMonth() + 1 < birthMonth ||
    (currentDate.getMonth() + 1 == birthMonth && currentDate.getDate() < birthDay)
  ) {
    age--;
  }
  return age;
};

/**
 * valueに該当する表示名を返却
 */
export const getOptionName = (
  value: number | string,
  options: Array<TFormOption>
): string | undefined => {
  return options.find((option) => option.value === value)?.children;
};

/**
 * 「2022-05-20 18:50:04」といった形式の日付文字列を「2022/05/20」 に変換
 */
export const convertDateFormat = (originalTimeString: string | undefined): string => {
  if (!originalTimeString) return '';
  const dateObject = new Date(originalTimeString);

  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1;
  const day = dateObject.getDate();

  const newTimeString = `${year}/${String(month).padStart(2, '0')}/${String(day).padStart(2, '0')}`;

  return newTimeString;
};

/**
 * 改行コードを<br>に変換
 */
export const convertLineBreak = (str?: string): string => {
  if (!str) return '';
  return str.replace(/\r?\n/g, '<br>');
};

/**
 * 文字列中のURLをリンクに変換
 */
export const linkifyUrls = (inputText: string | undefined): string => {
  if (!inputText) return '';
  // RFC3986に基づいたURLを検出する正規表現

  const urlRegex = /((?:https?|ftp):\/\/[^\s/$.?#].[^\s]*)/g;

  // テキスト内のURLを抽出
  const matchedUrls = inputText.match(urlRegex);

  if (!matchedUrls) {
    // URLが見つからない場合は、元のテキストをそのまま返す
    return inputText;
  }

  // テキスト内のURLをリンクに変換
  const linkedText = inputText.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank" class="underline">${url}</a>`;
  });

  return linkedText;
};
