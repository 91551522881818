import React, {useMemo} from 'react';
import { PortfolioPagePPageLayoutEnum } from '@/utils/api-client';
import '@/styles/common/Portfolio/ImagePage/add_button.scss';

type Props = {
  layout?: PortfolioPagePPageLayoutEnum;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};


export function AddButton({layout, onClick}: Props): React.ReactElement {
  const className = useMemo<string>(() => {
    if (layout === PortfolioPagePPageLayoutEnum.StyleV2) return "h-215";
    if (layout === PortfolioPagePPageLayoutEnum.Style4) return "h-215";
    if (layout === PortfolioPagePPageLayoutEnum.Style8) return "h-94";
    return "h-440";
  }, [layout]);

  return (
    <section className={`mbx-pf_add_button ${className}`} style={{fontSize:32}} onClick={onClick}>
      <div className="mbx-icon mbx-icon-Large32_Plus" />
    </section>
  );
}
