import cn from 'classnames';
import React from 'react';

import { ButtonIconButton } from '@/components/common/Button/IconButton';

type TProps = {
  className?: string;
  bodyClassName?: string;
  title?: string;
  invisible?: boolean;
  onVisible?: React.MouseEventHandler<HTMLButtonElement>;
  onRemove?: React.MouseEventHandler<HTMLButtonElement>;
  dragHandle?: string;
  children: React.ReactNode;
  sortable?: boolean;
  nested?: boolean;
  listeners?: any;
  style?: any;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=331%3A6760
 * !see https://matchbox-management.slack.com/archives/G01KQUUNX6W/p1624185233158200?thread_ts=1623715886.143700&cid=G01KQUUNX6W
 *
 * invisibleのスタイルは調整中
 *
 * Uneditable / Invisible を表示する
 *
 * ドラッグエリアが通常のコンポーネントと違うためTitleSectionTitleMediumコンポーネントを利用せずに構築
 *
 * TODO disabledの処理 APIの仕様確定待ち
 */
export const DraggableWrapper = ({
  className,
  bodyClassName,
  invisible = false,
  nested = false,
  sortable = true,
  onVisible,
  onRemove,
  dragHandle,
  children,
  title = '',
  listeners,
  style
}: TProps): React.ReactElement => {
  const titleWrapperClass = cn('flex', {
    'bg-blueGray-200': nested,
    'text-gray-800': nested,
    'text-gray-400': invisible && !nested,
    'bg-gray-400': !invisible && !nested,
    'bg-gray-100': invisible && !nested,
  });
  const bodyWrapperClass = cn(bodyClassName, 'pt-16', 'px-24', 'pb-24', {
    'bg-blue-50': nested,
    'bg-gray-100': !invisible && !nested,
    'bg-gray-50': invisible && !nested,
    'text-gray-400': invisible && !nested,
  });
  return (
    <div className={className} style={style}>
      <div className={titleWrapperClass}>
        <span className={`flex flex-auto mbx-utils-sort-group ${dragHandle}`} {...listeners} data-cypress="draggable-handle">
          <h1 className="mbx-typography--subhead_1 my-14 ml-24 flex-auto">{title}</h1>
          {sortable && (
            <ButtonIconButton
              type="gray"
              iconName="Sortable"
              className={cn({
                'opacity-20': invisible,
              })}
            />
          )}
        </span>
        {onVisible && (
          <ButtonIconButton
            type="gray"
            iconName={invisible ? 'Invisible' : 'Visible'}
            onClick={onVisible}
          ></ButtonIconButton>
        )}
        {onRemove && (
          <ButtonIconButton type="gray" iconName="Clear" onClick={onRemove}></ButtonIconButton>
        )}
      </div>
      <div className={bodyWrapperClass}>{children}</div>
    </div>
  );
};
