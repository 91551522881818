import { createSlice } from '@reduxjs/toolkit';

import { reducers } from './reducer';
import { initialState } from './state';

/**
 * ログイン情報やユーザー情報などを
 */
export const adminAuthModule = createSlice({
  name: 'admin_auth',
  initialState,
  reducers,
});

export const { setLogin, setUserInfo } = adminAuthModule.actions;
