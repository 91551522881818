import React, { useState, useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Blank } from '@/stories/components/Portfolio/ImagePage/ImageGeneral.stories';

export const Ed123 = (): React.ReactElement => {
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if (isAuth) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0123");
    if (!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g, "/")} 12:00:00`);
    const now = new Date;
    if (now > deadlineDate) setDeadline(true);
  }, []);

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16">
          <img className={style.img} src="../images/Top/event/event_0123_mv.jpg" width="978" height="504" alt="" />
        </div>
        <h1 className="mt-56 sm:mt-30 sm:mb-8 text-29_46 sm:text-18_24 font-bold text-center">11/26（火）19:30 -<br />
        MATCHBOXユーザー10万人登録記念！ <br />
        イベント運営スタッフがおすすめする<br className="md:hidden lg:hidden" />生成AIツール<br />
        ＜特典つき！＞</h1>
        {deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <section className="mt-80 sm:mt-55">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">セミナーについて</h2>
          <p className="text-16_28 sm:text-14_21 mt-30 sm:mt-16">
          日頃マッチボックスユーザーの皆さまに向けた無料セミナーを開催するにあたり、生成AIとの実務での向き合い方について知りたいというご要望を多くいただくようになりました。<br />
          今回は、日常的にAIを業務で活用しているマッチボックスの運営スタッフ3人が登壇いたします。<br />
          Webプランナーやディレクターとして、画像・動画・BGM制作、企画・文章作成などの実務視点から、「こういう局面ではこのAIが使える！」「ChatGPTをこんな風に使ってみたら仕事が早くなった」といった事例やノウハウを共有いたします。 AIを積極的に活用したいものの、どのAIをどう使えばよいか迷っている方に最適なセミナーとなる予定です。 質疑応答の時間もございますので、実務でのAI活用についてのお悩みを解決する機会としてぜひご参加ください。<br />
          ※本テキストもAIによる校閲・校正を行っております。
          </p>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/login"><span className='text-14_21'>アカウントお持ちの方</span>ログインしてセミナーに申し込む</Link></p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちでない方は<Link className='textLink font-bold' to="/entry">こちら</Link>でご登録の上、お申込みください</p>
            </div>
          </section>
        }
        <section className="mt-80 sm:mt-55">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">セミナー参加で特典プレゼント</h2>
          <p className="text-16_28 sm:text-14_21 mt-30 sm:mt-16">今回のセミナーは記念開催となっており、セミナー参加特典として、明日から使えるオススメ生成AIツールリストとこれまでマッチボックスで実施してきた多くの反響があった３セミナーの資料をプレゼントいたします。</p>
          <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-12">
                <span style={{'color': '#9E9E9E'}}>◆&nbsp;</span><span>明日から使えるオススメ生成AIツールリスト（PDF）</span>
              </li>
              <li className="flex">
                <span style={{'color': '#9E9E9E'}}>◆&nbsp;</span><span>多くの反響があった３セミナーの資料
                  <ul>
                    <li className="flex mt-8 mb-4"><span>1.&nbsp;</span><span>WebデザイナーのためのUXデザイン講座vol.2<br />
                    ブランディング構築におけるUXデザインを考える際、どのようなステップを踏んで構築されているのか実例を用いて解説。</span></li>
                    <li className="flex mb-4"><span>2.&nbsp;</span><span>UIデザイナーはこう使う！実案件を用いて解説するFigma活用事例<br />
                    WebデザインやUIアプリデザインの作成を行う上でFigmaをどのように使用しているか、実例の紹介。</span></li>
                    <li className="flex"><span>3.&nbsp;</span><span>選考通過につながるポートフォリオ講座 ～最新の転職市場とドキュメント作成術～<br />
                    Web業界の転職市場の動向、また、転職活動における各ドキュメントの作成術を解説。</span></li>
                  </ul>
                </span>
              </li>
            </ul>
          </div>
        </section>
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>以下に該当する方にオススメです。</h2>
          <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>クリエイティブ業務やプランニングといった実務の中で、生成AIを活用してクオリティとスピードをアップしたい </span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>まだ自分が使ったことがない生成について知りたい</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>1年前には考えられなかった方法で制作、日業業務の効率化を図りたい</span>
              </li>
              <li className="flex">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>セミナーを踏まえて転職・書類作成の相談をしたい方</span>
              </li>
            </ul>
          </div>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/login"><span className='text-14_21'>アカウントお持ちの方</span>ログインしてセミナーに申し込む</Link></p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちでない方は<Link className='textLink font-bold' to="/entry">こちら</Link>でご登録の上、お申込みください</p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>講師プロフィール</h2>
          <div className='event-instructor box-line mb-30 sm:p-16'>
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0007_prof.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                助田 正樹（MASAKI SUKEDA）
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-18 sm:mb-12">
              一般社団法人 ディレクションサポート協会 代表理事<br /><a className="textLink" href='https://drsp.cc/' target={'_blank'} rel="noreferrer">https://drsp.cc/<i className="mbx-icon mbx-icon-External_link"></i></a><br />
              株式会社SRI 顧問<br />
              株式会社ShareDan 顧問</dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                桜美林大学経済学部を卒業後、メーカーでシックスシグマというフレームワークのR＆Dセクションに所属。2005年からインターネット系ベンチャー企業中心にWebディレクターとして数社経験。その後フリーランスとしてWebディレクション業務中心に活動。地域コミュニティサイト、採用サイト、モバイル＆デジタルサイネージなど様々なWebサイト、システムの構築・運用から、新規事業プロジェクト企画、立ち上げを経験。2012年6月に人材紹介＆コワーキングスペース事業を立ち上げ、取締役で8年間従事。Webディレクター中心に累計3,000名近くのキャリア相談を行う。日本ディレクション協会創設メンバーの1人。2020年4月より個人事業SPEC.の活動を開始後、2022年10月に一般社団法人ディレクションサポート協会を設立。デジタル系企業数社の顧問を務める。
              </dd>
            </dl>
          </div>
          <div className='event-instructor box-line mb-30 sm:p-16'>
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0123_prof-01.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                伊東 宏之（HIROYUKI ITO）
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-18 sm:mb-12">
                シララ株式会社 代表取締役<br />
                <a className="textLink" href='https://sirara.co.jp/' target={'_blank'} rel="noreferrer">https://sirara.co.jp/<i className="mbx-icon mbx-icon-External_link"></i></a>
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                筑波大学在学中に音楽業界でクリエイターとしてのキャリアをスタートさせ、のちにWebディレクターに転向。事業会社と支援会社の両サイドで経験を積む。2016年シララ株式会社を設立。<br />
                成果を最大化するためのWebディレクションに注力するとともに、内閣府などのテレビCMに楽曲を提供するサウンドプロダクション事業も並走させており、ゆるやかで自由なキャリア形成に挑戦中。
              </dd>
            </dl>
          </div>
          <div className='event-instructor box-line sm:p-16'>
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0123_prof-02.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                本郷 孝太郎（KOTARO HONGO）
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-18 sm:mb-12">
                株式会社マイナビワークス マーケティング統括部
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                大学で経済学を修了後、金融系アセットマネジメント会社に入社、対法人営業、対個人営業、マーケティング業務を経験。<br />
                その後、某人材系企業にマーケターとしてジョイン。入社初年度でアフィリエイト経由でのセッション数約380％増を達成。また、社内エンゲージメントプラットフォーム運営プロジェクトのリーダーを拝名し、プラットフォームの社内定着率を10％未満から94％にまで向上させた。現在は、主に、Web/IT/ゲーム業界を専門とした人材紹介にて、サイト運営、SEO対策など幅広く担当。2023年10月から2024年3月においてorganic経由のsession数、約140％増を達成する。趣味でyoutubeやってます。
              </dd>
            </dl>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap">
                  <span className="sm:block">19：30</span><span className="sm:block">～</span><span className="sm:block">20：50</span>
                </th>
                <td>
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>概要説明、自己紹介</span></li>
                    <li className="flex"><span>・</span><span>カテゴリ別の生成AIの紹介
                      <ul>
                        <li className="flex"><span>-&nbsp;</span><span>企画・テキスト生成</span></li>
                        <li className="flex"><span>-&nbsp;</span><span>画像・動画生成</span></li>
                        <li className="flex"><span>-&nbsp;</span><span>その他（音楽など）</span></li>
                      </ul>
                    </span></li>
                    <li className="flex"><span>・</span><span>質疑応答</span></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/login"><span className='text-14_21'>アカウントお持ちの方</span>ログインしてセミナーに申し込む</Link></p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちでない方は<Link className='textLink font-bold' to="/entry">こちら</Link>でご登録の上、お申込みください</p>
            </div>
          </section>
        }
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2024/11/26（火）19:30～<br />
                  [入室開始] 19:20～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.ワークショップ申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink" href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.ワークショップ当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/login"><span className='text-14_21'>アカウントお持ちの方</span>ログインしてセミナーに申し込む</Link></p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちでない方は<Link className='textLink font-bold' to="/entry">こちら</Link>でご登録の上、お申込みください</p>
            </div>
          </section>
        }
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">開催について</h3>
          <p className="mt-30 sm:mt-16 text-16_28 sm:text-14_21">
            Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2,000名を超える「0からのWebディレクション講座」を生み出したメンバーでもある一般社団法人ディレクションサポート協会 会長の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。
          </p>
        </section>
        <section className="mt-80 sm:mt-55 p-40 sm:p-24 bg-gray-50 text-left">
          <h3 className="text-18_24 sm:text-14_21 font-bold">注意点</h3>
          <p className="mt-16 text-16_28 sm:text-14_21">
            ZoomURLはセミナー前日・または当日にメールにてお送りします。<br />
            マッチボックス登録時のアドレス、またはセミナー申込み時に入力したアドレスにURLへ届いていない場合は<a className='textLink' href='https://survey.mynavi.jp/cre/?enq=2va0sJC992s%3d' target={'_blank'} rel="noreferrer">こちら<i className="mbx-icon mbx-icon-External_link"></i></a>からお問い合わせください。
          </p>
        </section>
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div>
    </div >
  );
};
