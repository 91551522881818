import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

export const LastUpdate = (): React.ReactElement => {
  const { watch, register, setValue } = useFormContext();
  const [latestUpdate, setLatestUpdate] = useState<string>();

  useEffect(() => {
    const subscription = watch((value) => {
      const latest_update = value.latest_update;
      const today = dayjs().format('YYYY-MM-DD');
      setLatestUpdate(latest_update ? latest_update : today);
      if (latest_update !== today) {
        setValue('latest_update', today);
        setLatestUpdate(today);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const year = useMemo(() => {
    return latestUpdate?.split('-')[0];
  }, [latestUpdate]);

  const month = useMemo(() => {
    return latestUpdate?.split('-')[1];
  }, [latestUpdate]);

  const day = useMemo(() => {
    return latestUpdate?.split('-')[2];
  }, [latestUpdate]);

  return (
    <div className="space-y-8 my-22">
      <input type="hidden" {...register('latest_update')} />
      <div className="flex mbx-utils-stack-h--8">
        <div className="w-auto">
          <div className="mbx-formContainer mbx-formContainer--textfield--readonly">{year}</div>
        </div>

        <div>
          <span className="mbx-formUtils-divider">年</span>
        </div>

        <div className="w-auto">
          <div className="mbx-formContainer mbx-formContainer--textfield--readonly">{month}</div>
        </div>

        <div>
          <span className="mbx-formUtils-divider">月</span>
        </div>

        <div className="w-auto">
          <div className="mbx-formContainer mbx-formContainer--textfield--readonly">{day}</div>
        </div>
        <div>
          <span className="mbx-formUtils-divider">日</span>
        </div>

        <div>
          <span className="mbx-formUtils-divider">現在</span>
        </div>
      </div>
    </div>
  );
};
