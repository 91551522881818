import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NotificationSnackBarSaved } from '@/components/common/Notification/SnackBarSaved';
import { setSaved } from '@/redux/index';
import { State } from '@/redux/state';

/**
 * global stateの値検知で表示する保存ボタンを押さないで保存処理が処理される系の通知
 *
 * 仕様 :
 * - 履歴書・職務経歴書・ポートフォリオなど自動で保存が処理されるページに使用
 * - メッセージが表示されてから4秒後に自動非表示
 */
export const GlobalNotificationSavedPortal = (): React.ReactElement => {
  const isFirstRun = useRef(true);
  const dispatch = useDispatch();

  const saved = useSelector((state: State) => state.saved);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    // メッセージが表示されてから4秒後に自動非表示
    if (saved) {
      _.delay(onClick, 4000);
    }
  }, [saved]);

  const onClick = () => {
    dispatch(setSaved(false));
  };
  return (
    <div className="sticky bottom-0 z-300 mx-auto" style={{ maxWidth: '960px' }}>
      <NotificationSnackBarSaved show={saved}></NotificationSnackBarSaved>
    </div>
  );
};
