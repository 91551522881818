import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';

type TInput = {
  matchbox_id: string,
  status: string;
  rate: string;
  job: string;
  sort: string;
};

type Props = {
  setFilterValues: React.Dispatch<
    React.SetStateAction<{
      matchbox_id: string,
      status: string;
      rate: string;
      job: string;
      sort: string;
    }>
  >;
};

const sort = [
  { value: '1', children: '更新日が近い順' },
  { value: '2', children: 'ピックアップ日が近い順' },
  { value: '3', children: 'みんなの意見が多い順' },
  { value: '4', children: 'みんなの意見が少ない順' },
  { value: '5', children: 'アクション 昇順' },
  { value: '6', children: 'アクション 降順' },
];

export const Filters = (props: Props): React.ReactElement => {
  const methods = useForm<TInput>({
    defaultValues: {
      matchbox_id: '',
      status: '',
      rate: '',
      job: '',
      sort: '',
    },
  });
  const { watch } = methods;

  useEffect(() => {
    props.setFilterValues({
      matchbox_id: watch('matchbox_id'),
      status: watch('status'),
      rate: watch('rate'),
      job: watch('job'),
      sort: watch('sort'),
    });
  }, [watch('job'), watch('rate'), watch('sort'), watch('status')]);

  const onBlur = ((e: React.FocusEvent<HTMLInputElement>) => {
    // 削除ボタン判定
    const related = e.relatedTarget as HTMLHtmlElement;
    if(related) {
      const className = related.className;
      if (className.includes('type-delete')) {
        e.target.value = '';
        methods.setValue('matchbox_id', '');
      }
    }
    props.setFilterValues({
      matchbox_id: e.target.value,
      status: watch('status'),
      rate: watch('rate'),
      job: watch('job'),
      sort: watch('sort'),
    });
  });

  return (
    <div className="filters">
      <FormProvider {...methods}>
        <form className="flex items-end justify-between">
          <div className="w-176">
            <FormLayoutFieldset>
              <FormLabel label="MATCHBOX IDで検索" type="small"></FormLabel>
              <FormContainerTextfield
                name="matchbox_id"
                className="mkt_mask_items"
                onBlur={(e) => onBlur(e)}
              />
            </FormLayoutFieldset>
          </div>
          <div className="w-176">
            <FormLayoutFieldset>
              <FormLabel label="絞り込み" type="small"></FormLabel>
              <FormContainerDropdown
                className="select"
                name="job"
                placeholder="職種"
                selectList={FORM_OPTIONS.experienced_job}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
          </div>
          <div className="w-176">
            <FormLayoutFieldset>
              <FormLabel label="絞り込み" type="small"></FormLabel>
              <FormContainerDropdown
                className="select"
                name="status"
                placeholder="ステータス"
                selectList={DIRECT_FORM_OPTIONS.offer_status}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
          </div>
          <div className="w-176">
            <FormLayoutFieldset>
              <FormLabel label="絞り込み" type="small"></FormLabel>
              <FormContainerDropdown
                className="select"
                name="rate"
                placeholder="マッチ度"
                selectList={DIRECT_FORM_OPTIONS.offer_mw_status}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
          </div>
          <div className="w-194">
            <FormLayoutFieldset>
              <FormLabel label="並べ替え" type="small"></FormLabel>
              <FormContainerDropdown
                className="select"
                name="sort"
                placeholder="日付順"
                selectList={sort}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
