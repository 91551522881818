import React from 'react';
import { useHistory } from 'react-router';

import BaseButton from '@/components/common/Button/BaseButton';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
import WarningList from '@/components/common/Direct/WarningList';

import { PageDots } from './PageDots';
import { OnboardingPageWrapper } from './PageWrapper';

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const OnboardingPage_5 = (props: Props): React.ReactElement => {
  const history = useHistory();
  const onClickPage = () => {
    history.push({
      pathname: `/mypage/direct`,
      state: { step: 'step2' },
    });
  };

  const warnings = [
    '開⽰を希望しない企業がある場合は、必ずご⾃⾝でブロックする企業の設定を⾏ってください。ブロック設定ができておらずに発⽣したトラブルについては、弊社は⼀切の責任を負いかねます。',
    'オファー機能の登録を行うと、保存された情報は随時企業に公開されます。編集中の情報を企業に公開したくない場合は、一度オファー機能を停止した上で編集を行い、完成後、再度オファー機能に登録してください。',
    'オファー機能の登録を⾏うと、ブロック設定されない参画企業に対しては、各⼊⼒画⾯の項⽬のうち、⽒名(ふりがな含む)、⽣年⽉⽇、都道府県を除く住所、電話番号、メールアドレス、家族情報、その他各⼊⼒画⾯にて「開⽰されない」と表⽰されるものについては、開⽰されませんが、その他の項⽬に⼊⼒した情報は開⽰されますので、個⼈が特定され得る情報の⼊⼒を⾏わないようにしてください。',
    'オファー機能に登録したポートフォリオ内に、著作権、その他第三者の権利の侵害等の問題が発⽣した場合は、事務局側で強制的に、オファー機能の登録およびポートフォリオの公開を停⽌する場合があります。あらかじめご了承ください。',
  ];

  return (
    <DataLayerPushContainer
      data={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'modal_open',
        virtualPageName: 'on-boarding_step_5',
      }}
    >
      <div>
        <OnboardingPageWrapper
          description={
            <span>
              オファー機能に登録して
              <br />
              企業から⾯接オファーをもらおう。
            </span>
          }
        >
          <p className="mt-14 text-15_32 leading-6">
            ポートフォリオ、職務経歴書、希望条件を
            <br />
            直接企業に⾒てもらうことができます。
            <p className="mt-7 text-12_20">※この項⽬はあとから変更できます。</p>
          </p>
        </OnboardingPageWrapper>
        <div>
          <div className="w-308 m-auto mt-26">
            <BaseButton theme="primary" size="m" className="w-full" onClick={onClickPage}>
              ブロック企業を設定する
            </BaseButton>
            <div className="mt-26">
              <BaseButton theme="link" size="m" onClick={() => props.setPage(6)}>
                あとで設定する
              </BaseButton>
            </div>
          </div>
          <div className="mt-26 m-auto max-w-2xl">
            <WarningList warnings={warnings} className="warning" />
          </div>
          <div className="pb-20 mb-48 sm:mb-72">
            <PageDots page={props.page} setPage={props.setPage} unClick className="mt-48" />
          </div>
        </div>
      </div>
    </DataLayerPushContainer>
  );
};
