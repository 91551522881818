import React, { useEffect, useState } from 'react';
import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';
import { Container, Draggable, OnDropCallback } from 'react-smooth-dnd';

import * as CH from '@/components/CareerHistory/';
import { BaseButton } from '@/components/common/Button/';
import { Sorting } from '@/components/common/Form/Unit/Sorting';
import { ModalEditable, ModalEditableBody } from '@/components/common/Modal/Editable';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { TSkillFormValues } from '@/types/Pages/CareerHistory';
import { CareerHistorySkills } from '@/utils/api-client';
import { moveArray } from '@/utils/utils';

export const Skills: React.VFC<{
  onAppend(): void;
  onRemove(id: number): void;
  onSort(result: FieldArrayWithId<TSkillFormValues, 'skills', 'uid'>[]): void;
}> = ({ onAppend, onRemove: removeSkill, onSort }) => {
  const mq = useMBXMediaQuery();
  const { control, watch } = useFormContext<TSkillFormValues>();
  const { fields, move, remove } = useFieldArray<TSkillFormValues, 'skills', 'uid'>({
    control,
    name: 'skills',
    keyName: 'uid',
  });
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [skills, setSkills] = useState<CareerHistorySkills[]>();

  const onDrop: OnDropCallback = ({ removedIndex, addedIndex }) => {
    if (removedIndex !== null && addedIndex !== null) {
      move(removedIndex, addedIndex);
      onSort(moveArray(fields, removedIndex, addedIndex));
    }
  };

  const onRemove = (index: number, id: number | undefined) => {
    remove(index);
    if (id) {
      removeSkill(id);
    }
  };

  const onHideEducationEditor = () => {
    setShowEditor(false);
  };

  const experience = (id: number | undefined) => {
    if (!id) return '';
    return FORM_OPTIONS.experienced_skill.find((skill) => skill.value === `${id}`)?.children;
  };

  useEffect(() => {
    const subscription = watch((value) => {
      //
      setSkills(value.skills);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <TitleSectionTitle type="large" className="mt-12">
        テクニカルスキル
      </TitleSectionTitle>

      <div className="space-y-8 my-22">
        <Container
          onDrop={onDrop}
          dragHandleSelector=".dragHandle"
          lockAxis="y"
          render={(ref) => {
            return (
              <div ref={ref} className="mb-8 sm:mb-18 mbx-formUnit-education">
                {fields.map((item, index) => (
                  <Draggable key={item.uid} className="mbx-formUnit-education-box">
                    <CH.Skill item={item} index={index} onRemove={() => onRemove(index, item.id)} />
                  </Draggable>
                ))}
              </div>
            );
          }}
        />
        <div className="flex space-x-8">
          <BaseButton iconName="Add" size="s" theme="tertiary" onClick={onAppend}>
            テクニカルスキルを追加
          </BaseButton>
          {mq.sm && (
            <BaseButton
              iconName="Sortable"
              size="s"
              theme="quaternary"
              disabled={fields.length <= 1}
              onClick={() => {
                setShowEditor(true);
              }}
            >
              並べ替え
            </BaseButton>
          )}
        </div>
      </div>

      {showEditor && (
        <ModalEditable>
          <ModalEditableBody onClose={onHideEducationEditor}>
            <Container
              onDrop={onDrop}
              dragHandleSelector=".dragHandle"
              render={(ref) => (
                <div ref={ref}>
                  {skills?.map((skill, index) => (
                    <Draggable key={skill.id}>
                      <Sorting handle="dragHandle" onDelete={() => onRemove(index, skill.id)}>
                        <>
                          {skill.name}
                          <br />
                          経験{experience(skill.experience)} レベル{skill.level}
                        </>
                      </Sorting>
                    </Draggable>
                  ))}
                </div>
              )}
            />
          </ModalEditableBody>
        </ModalEditable>
      )}
    </>
  );
};
