import React from 'react';
import { getOptionChildrenFromValue } from '@/utils/optionValueToChildren';
import { PortfolioItem, Question } from '@/utils/api-client';

type Props = {
  portfolioItem: PortfolioItem;
};

export const SideQuestions = (props: Props): React.ReactElement => {
  const p_contentsAnswer = (questionKey: string):string[]|undefined => {
    if (!props.portfolioItem?.i_pages) return;

    const questions = Array.from(props.portfolioItem?.i_pages.values()).find(
      (p) => p.p_page_layout === 'questions'
    )?.p_contents;
    if (!questions) return;

    const question = Array.from(questions?.values()).find((c) => (c as Question).qa_id === questionKey);
    if (!question) return;
    if (!(question as Question).qa_answer) return;

    if (questionKey=== 'b_5') return (question as Question).qa_answer;

    //質問の回答が配列ではない場合
    if (typeof (question as Question).qa_answer === 'string') {
      return (question as Question).qa_answer;
    }

    return (question as Question).qa_answer.filter((a) => {
      if (a === null) return false;
      return a.trim() !== '';
    });
  };

  return (
    <section className="details-right">
      {p_contentsAnswer('b_v2_1') && p_contentsAnswer('b_v2_1')?.length !== 0 && (
        <div>
          <p className="title mbx-typography--body_2 mt-48 title-first">職種</p>
          <p className="mbx-typography--body_2 mkt_mask_items">
            {p_contentsAnswer('b_v2_1')?.join(' / ')}
          </p>
        </div>
      )}
      {p_contentsAnswer('b_v2_2') && p_contentsAnswer('b_v2_2')?.length !== 0 && (
        <div>
          <p className="title mbx-typography--body_2 mt-24">担当内容</p>
          <p className="mbx-typography--body_2 mkt_mask_items">
            {p_contentsAnswer('b_v2_2')?.join(' / ')}
          </p>
        </div>
      )}
      {p_contentsAnswer('b_v2_8') && p_contentsAnswer('b_v2_8')?.length !== 0 && (
        <div>
          <p className="title mbx-typography--body_2 mt-24">経験</p>
          <p className="mbx-typography--body_2 mkt_mask_items">
            {p_contentsAnswer('b_v2_8')?.join(' / ')}
          </p>
        </div>
      )}
      {p_contentsAnswer('b_v2_7') && p_contentsAnswer('b_v2_7')?.length !== 0 && (
        <div>
          <p className="title mbx-typography--body_2 mt-24">使用ツール・言語</p>
          <p className="mbx-typography--body_2 mkt_mask_items">
            {p_contentsAnswer('b_v2_7')?.join(' / ')}
          </p>
        </div>
      )}
      {p_contentsAnswer('b_5') && p_contentsAnswer('b_5')?.length !== 0 && (
        <div>
          <p className="title mbx-typography--body_2 mt-24">マネジメント人数</p>
          <p className="mbx-typography--body_2 mkt_mask_items">
            {getOptionChildrenFromValue('management_count', Number(p_contentsAnswer('b_5')))}
          </p>
        </div>
      )}
    </section>
  );
};
