import cn from 'classnames';
import React from 'react';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type Props = {
  warnings: string[];
  className?: string;
};

function WarningList(props: Props): React.ReactElement {
  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <section className={cn('p-40', 'bg-gray-50', 'text-left', props.className)}>
      <p className="mbx-typography--subhead_1">注意事項</p>
      <ul className="mt-16 mbx-typography--body_2">
        {props.warnings.map((text, index) => {
          return (
            <li className={cn('flex', index !== 0 && 'mt-8')} key={index}>
              <span>・</span>
              <span>{text}</span>
            </li>
          );
        })}
      </ul>
    </section>
  );
}

export default WarningList;
