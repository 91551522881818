import * as yup from 'yup';

import { YUP_VALIDATION as BASE_VALIDATION } from '@/definition/VALIDATION';
import { VALIDATION_MESSAGE } from '@/definitionAdmin/VALIDATION_MESSAGE';

export const YUP_VALIDATION = {
  ...BASE_VALIDATION,
  path: yup
    .string()
    .required(VALIDATION_MESSAGE.required)
    .matches(/^[a-zA-Z0-9_-]+$/, VALIDATION_MESSAGE.path),
};
