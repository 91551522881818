import cn from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import ImgIcon from '@/assets/images/profile/circle-placeholder.svg';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ButtonTextLink } from '@/componentsDirect/Parts/Button/TextLink';
import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';
import { ADMINApi, AdminUserInfoModel, Offer } from '@/utils/api-client';
import { noDataText } from '@/utils/string';

import { AdminOffersModalMessageFromCompanyComponent } from './ModalMessageFromCompany';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type Props = {
  offer: Offer;
  onUpdate: (id: number, status: number, mwStatus: number) => Promise<void>;
};
type TInput = {
  status: string;
  mw_status: string;
};

export const MatchingList = (props: Props): React.ReactElement => {
  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [user, setUser] = useState<AdminUserInfoModel>();
  const [modal, setModal] = useState<boolean>(false);
  const [updateFlag, setUpdateFlag] = useState<'status' | 'mw_status' | ''>('');

  // ====================================================================================================
  //
  // RHF
  //
  // ====================================================================================================
  const methods = useForm<TInput>({
    mode: 'onChange',
    defaultValues: {
      status: '',
      mw_status: '',
    },
  });
  const { watch } = methods;

  // ====================================================================================================
  //
  // API
  //
  // ====================================================================================================
  const getUser = async () => {
    if (props.offer.matchbox_id) {
      const res = await new ADMINApi().getAdminUserId(props.offer.matchbox_id);
      setUser(res.data.user_info);
    }
  };

  const onUpdate = (status: number, mwStatus: number) => {
    if (props.offer.id) {
      if (updateFlag === 'status') {
        if (status === 4 || status === 5) {
          methods.setValue('mw_status', '3');
          props.onUpdate(props.offer.id, status, 3);
        } else {
          props.onUpdate(props.offer.id, status, mwStatus);
        }
      }
      if (updateFlag === 'mw_status') {
        props.onUpdate(props.offer.id, status, mwStatus);
      }
    }
    if (modal) modalClose();
  };

  // ====================================================================================================
  //
  // EVENT
  //
  // ====================================================================================================
  const modalOpen = (key: 'status' | 'mw_status') => {
    setUpdateFlag(key);
    setModal(true);
  };

  const modalClose = () => {
    setUpdateFlag('');
    if (props.offer.status) {
      methods.setValue('status', props.offer.status?.toString());
    }
    if (props.offer.mw_status) {
      methods.setValue('mw_status', props.offer.mw_status.toString());
    } else {
      methods.setValue('mw_status', '1');
    }
    setModal(false);
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const isStatus = useMemo(() => {
    const status = watch('status');
    const mwStatus = watch('mw_status');
    if (mwStatus === '3') return 'gray';
    if (status === '2' || status === '6' || status === '7') {
      if (mwStatus !== '3') {
        if (mwStatus === '2') return 'blue';
        return 'blueWithRed';
      }
    }
    return 'white';
  }, [watch('status'), watch('mw_status')]);

  const buttonText = useMemo(() => {
    if (watch('mw_status') === '1') return '未完了';
    if (watch('mw_status') === '2') return '保留';
    if (watch('mw_status') === '3') return '完了';
  }, [watch('mw_status')]);

  const background = useMemo(() => {
    if (isStatus === 'gray') return 'bg-gray-100';
    if (isStatus === 'blue' || isStatus === 'blueWithRed') return 'bg-blue-100';
    if (isStatus === 'white') return 'bg-white';
  }, [isStatus]);

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    getUser();
  }, [props.offer.id]);

  useEffect(() => {
    if (props.offer.status === 0) {
      methods.setValue('status', '3');
    } else {
      if (props.offer.status === undefined) return;
      const offerStatus: number = props.offer.status;
      methods.setValue('status', offerStatus.toString());
    }
    if (props.offer.mw_status === undefined) return;
    methods.setValue('mw_status', props.offer.mw_status?.toString());
  }, [props.offer]);

  useEffect(() => {
    if (watch('mw_status') !== '1' && watch('mw_status') !== '2' && watch('mw_status') !== '3') {
      if (!props.offer.id) return;
      methods.setValue('mw_status', '1');
      onUpdate(Number(watch('status')), 1);
    }
  }, [watch('mw_status')]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <section
      className={cn('border-b', 'border-gray-300', background, {
        'font-bold': isStatus === 'blue' || isStatus === 'blueWithRed',
      })}
    >
      <div className={cn('flex', 'items-center', 'pl-16', 'h-64', 'text-12_12', 'relative')}>
        <div className="flex items-center w-84 flex-shrink-0">
          {isStatus === 'blueWithRed' && <p className="w-8 h-8 mr-8 bg-red-700 rounded-full"></p>}
          <p>{dayjs(props.offer.created_at).format('YYYY/MM/DD')}</p>
        </div>
        <div className="w-64 ml-16 truncate flex-shrink-0 mkt_mask_items">
          {user?.matchbox_id}
        </div>
        <div className="ml-16 w-24 h-24 rounded-full overflow-hidden flex-shrink-0 mkt_mask_items">
          <img src={user?.icon ? user.icon : ImgIcon} alt="" />
        </div>
        <div className="w-84 ml-8 flex-shrink-0 mkt_mask_items">
          <ButtonTextLink href={`/admin/users/${user?.matchbox_id}`} truncate={true}>
            {user?.name}
          </ButtonTextLink>
        </div>
        <div className="w-130 ml-8 text-blue-700 truncate flex-shrink-0 mkt_mask_items">
          <ButtonTextLink href={`/admin/companies/${props.offer.senter?.id}`} truncate={true}>
            {props.offer.senter?.name}
          </ButtonTextLink>
        </div>
        <div className="w-140 ml-8 truncate flex-shrink-0 mkt_mask_items">
          {noDataText(props.offer.position?.name)}
        </div>
        <FormProvider {...methods}>
          <form className="flex">
            <div className="w-180 ml-16">
              <FormContainerDropdown
                name="status"
                selectList={DIRECT_FORM_OPTIONS.matchingStatus}
                disabled={watch('mw_status') === '3'}
                onSelected={() => modalOpen('status')}
              />
            </div>
            <div className="flex items-center w-24 ml-16">
              <AdminOffersModalMessageFromCompanyComponent
                offer={props.offer}
              ></AdminOffersModalMessageFromCompanyComponent>
            </div>
            <div className="w-82 ml-16">
              <FormContainerDropdown
                name="mw_status"
                selectList={DIRECT_FORM_OPTIONS.mwStatus}
                onSelected={() => modalOpen('mw_status')}
              />
            </div>
          </form>
        </FormProvider>
      </div>
      <ModalDefault isOpen={modal} onClose={() => modalClose()}>
        <section className="w-624 py-80">
          <p className="mbx-typography--body_1">本当に変更しますか？</p>
          <div className="flex justify-center mt-24">
            <div className="w-146">
              <BaseButton
                theme="secondary"
                size="m"
                className="w-full"
                onClick={() => modalClose()}
              >
                キャンセル
              </BaseButton>
            </div>
            <div className="w-146 ml-16">
              <BaseButton
                theme="primary"
                size="m"
                className="w-full"
                onClick={() => onUpdate(Number(watch('status')), Number(watch('mw_status')))}
              >
                {updateFlag === 'status' && <span>変更する</span>}
                {updateFlag === 'mw_status' && <span>{buttonText}にする</span>}
              </BaseButton>
            </div>
          </div>
        </section>
      </ModalDefault>
    </section>
  );
};
