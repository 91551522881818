import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import metaOriginal from '@/assets/json/meta-config.json';

const metaCommon = {
  title: "MATCHBOX｜採用のプロがつくった、ポートフォリオサービス。",
  description: "必要な情報を入力するだけ。美しく、採用担当者の知りたい情報を押さえたポートフォリオがつくれます。ポートフォリオができたら企業から直接オファーも受け取れます。",
  keywords: "ポートフォリオ,portfolio,履歴書,職務経歴書,MATCHBOX,マッチボックス,マイナビ,マイナビクリエイター,就職,転職",
  siteName: "MATCHBOX｜採用のプロがつくった、ポートフォリオサービス。",
  url: "https://matchbox.work/",
  image: "https://matchbox.work/ogp.jpg",
  type: "website",
}

export const HelmetContainer = ({ notSet, children }: { notSet?: boolean, children: React.ReactNode }): React.ReactElement => {
  const [metaItem, setMetaItem] = useState({
    title: "",
    description: "",
    keywords: "",
    siteName: "",
    url: "",
    image: "",
    type: "",
  });

  useEffect(() => {
    const orgPath = Object.keys(metaOriginal).find((key) => {
      return key === location.pathname;
    }) as keyof typeof metaOriginal | undefined;

    const meta = {
      title: (orgPath) ? metaOriginal[orgPath]['title'] : metaCommon.title,
      description: (orgPath) ? metaOriginal[orgPath]['description'] : metaCommon.description,
      keywords: (orgPath) ? metaOriginal[orgPath]['keywords'] : metaCommon.keywords,
      siteName: (orgPath) ? metaOriginal[orgPath]['og:site_name'] : metaCommon.title,
      url: (orgPath) ? metaOriginal[orgPath]['og:url'] : metaCommon.url,
      image: (orgPath) ? metaOriginal[orgPath]['og:image'] : metaCommon.image,
      type: (orgPath) ? metaOriginal[orgPath]['og:type'] : metaCommon.type,
    }

    setMetaItem(meta);
  }, [location.pathname]);

  return (
    <>
      {!notSet ? (
        <HelmetProvider>
          <Helmet>
            <title>{metaItem.title}</title>
            <meta name="description" content={metaItem.description} />
            <meta name="keywords" content={metaItem.keywords} />
            <meta property="og:title" content={metaItem.title} />
            <meta property="og:site_name" content={metaItem.siteName} />
            <meta property="og:description" content={metaItem.description} />
            <meta property="og:url" content={metaItem.url} />
            <meta property="og:image" content={metaItem.image} />
            <meta property="og:type" content={metaItem.type} />
          </Helmet>
          {children}
        </HelmetProvider>
      ) : (
        <>
          {children}
        </>
      )}
    </>
  );
};
