import React from 'react';

import { AdminPage } from '@/componentsDirect/Layout/Page';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';

export const DirectError = (): React.ReactElement => {
  return (
    <AdminPage isErrorPage>
      <section>
        <h1 className="text-heading_2 font-bold mb-24 mt-104">
          お探しのページが見つかりませんでした
        </h1>
        <p className="text-body_1 mb-40">
          アクセスいただいたページは、削除されたか現在利用できない可能性があります。
          <br />
          お手数ですがトップページから再度お探しください。
        </p>
        <BaseButton size="m" theme="primary" type="button" href="/direct/search">
          トップページへ
        </BaseButton>
      </section>
    </AdminPage>
  );
};
