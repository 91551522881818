import React from 'react';

import { ImageCropperComponent } from '@/componentsDirect/Parts/Image/Cropper';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  width: number;
  height: number;
  src: string; // Data URI
  onSave: (dataUri: string) => void;
};

/**
 * 画像クロップモーダル表示用
 */
export const ModalCropper = ({
  isOpen,
  onClose,
  className,
  width,
  height,
  src,
  onSave,
}: Props): React.ReactElement => {
  return (
    <>
      <ModalDefault isOpen={isOpen} onClose={onClose} className={className}>
        <ImageCropperComponent
          width={width}
          height={height}
          src={src}
          onClose={onClose}
          onSave={onSave}
        />
      </ModalDefault>
    </>
  );
};
