import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { BaseButton } from '@/componentsAdmin/Parts/Button';
import { FormContainerDropdownMultiple } from '@/componentsAdmin/Parts/Form';
import { useUserTag } from '@/hooksAdmin/useUserTag';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type TProps = {
  matchboxId: string;
};

type TSubmit = {
  user_tags: string[];
};

export const AdminUserSectionTags = ({ matchboxId }: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // Hooks
  //
  // ====================================================================================================
  const {
    getUserTags,
    getUserTagsMatchboxId,
    patchUserTagsMatchboxId,
    userTagsMatchboxId,
    userTagOPTIONS,
  } = useUserTag();

  // ====================================================================================================
  //
  // RHF
  //
  // ====================================================================================================
  const methods = useForm();
  const { handleSubmit } = methods;

  // ====================================================================================================
  //
  // Events
  //
  // ====================================================================================================
  const onSubmit: SubmitHandler<TSubmit> = (data) => {
    patchUserTagsMatchboxId(matchboxId, { items: data.user_tags });
  };

  // ====================================================================================================
  //
  // Methods
  //
  // ====================================================================================================
  /**
   * データをRHFに値をsetValueする
   */
  const setDefaultValues = (tags?: string[]) => {
    methods.setValue('user_tags', tags);
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    // タグ一覧取得
    getUserTags();
    // 求職者に紐づいたタグを取得
    getUserTagsMatchboxId(matchboxId);
  }, []);

  useEffect(() => {
    setDefaultValues(userTagsMatchboxId);
  }, [userTagsMatchboxId]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <tr>
      <th className="mbx-admin-form-title">タグ</th>
      <td>
        <FormProvider {...methods}>
          <form id="FORM" className="flex gap-x-16 items-center" onSubmit={handleSubmit(onSubmit)}>
            <FormContainerDropdownMultiple
              name="user_tags"
              className="flex-grow"
              placeholder="選択してください（複数選択可）"
              selectList={userTagOPTIONS}
            ></FormContainerDropdownMultiple>

            <div className="w-224">
              <BaseButton className="w-full" size="40" theme="primary" type="submit">
                変更を保存
              </BaseButton>
            </div>
          </form>
        </FormProvider>
      </td>
    </tr>
  );
};
