import { PayloadAction } from '@reduxjs/toolkit';

import { State } from './state';

export const reducers = {
  setPortfolioStatusOfPDF: (state: State, action: PayloadAction<number | null>): State => {
    return { ...state, portfolioStatusOfPDF: action.payload };
  },
  setPortfolioTicketID: (state: State, action: PayloadAction<number | undefined>): State => {
    return { ...state, portfolioTicketID: action.payload };
  },
  setPortfolioPDFDownloadModal: (state: State, action: PayloadAction<boolean>): State => {
    return { ...state, portfolioPDFDownloadModal: action.payload };
  },
  setPortfolioMatchboxId: (state: State, action: PayloadAction<string>): State => {
    return { ...state, matchboxId: action.payload };
  },
};
