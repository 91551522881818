import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { BaseButton } from '@/componentsAdmin/Parts/Button';
import { FormContainerTextfield } from '@/componentsAdmin/Parts/Form/';
import { YUP_VALIDATION } from '@/definitionAdmin/VALIDATION';
import { useUserTag } from '@/hooksAdmin/useUserTag';
import { UserTagParam } from '@/utils/api-client';
import { yupResolver } from '@hookform/resolvers/yup';

type TProps = {
  getUserTags: () => Promise<void>;
};

/**
 * タグ追加処理
 * @author s2 masa
 */
export const AdminUserTagAddComponent = ({ getUserTags }: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // Hooks
  //
  // ====================================================================================================
  const { postUserTag } = useUserTag();

  // ====================================================================================================
  //
  // RHF
  //
  // ====================================================================================================

  const schema = yup.object().shape({
    name: YUP_VALIDATION.required,
    path: YUP_VALIDATION.path,
  });

  const methods = useForm<UserTagParam>({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      path: '',
    },
  });

  const { handleSubmit } = methods;

  // ====================================================================================================
  //
  // Events
  //
  // ====================================================================================================
  const onSubmit: SubmitHandler<UserTagParam> = (data) => {
    (async () => {
      await postUserTag(data);
      await getUserTags();
      clearValue();
    })();
    return false;
  };

  // ====================================================================================================
  //
  // Methods
  //
  // ====================================================================================================
  // 値をクリア
  const clearValue = () => {
    methods.setValue('name', '');
    methods.setValue('path', '');
  };

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <FormProvider {...methods}>
      <tr className="text-left">
        <td colSpan={2} className="px-16 border-b border-gray-200 ">
          <div className="flex gap-x-16">
            <FormContainerTextfield
              name="name"
              className="py-14 flex-grow"
              placeholder="例）期間限定"
            />
            <FormContainerTextfield name="path" className="py-14 w-2/3" placeholder="例）limited" />
          </div>
        </td>
        <td className="text-right pr-16 border-b border-gray-200 pt-9 align-top">
          <BaseButton
            size="s"
            theme="primary"
            className="w-full"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={!methods.getValues('name') || !methods.getValues('path')}
          >
            保存
          </BaseButton>
        </td>
      </tr>
    </FormProvider>
  );
};
