import React, { ReactElement, VFC } from 'react';

type TProps = {
  className?: string;
  children: Array<ReactElement>;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=326%3A24409
 *
 * フォームパーツを作成する薄い Wrapper Component `FormLayoutFieldset` を利用してレイアウトを整形
 * children は ReactElement を受け取るので中身はフォームに限らず自由にレイアウトできてしまうがStorybookのサンプルを参考に実装することで利用方法を制限する。
 */
export const FormLayoutFieldset: VFC<TProps> = ({ className, children }) => {
  return <fieldset className={className}>{children}</fieldset>;
};
