import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { FormContainerTextArea } from '@/componentsDirect/Parts/Form/Container/TextArea';
import { TFormOption } from '@/definition/FORM_OPTIONS';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { VALIDATION_MESSAGE } from '@/definition/VALIDATION_MESSAGE';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';
import { useOfferPositions } from '@/hooksDirect/useOfferPositions';
import { OfferStatusEnum } from '@/utils/api-client';
import { yupResolver } from '@hookform/resolvers/yup';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type TInput = {
  action: string;
  offer_position: string;
  action_text: string;
};

type TProps = {
  onSend: (value: string, text: string, offer_position: string) => void;
  onClose: React.MouseEventHandler<HTMLDivElement>;
  status?: OfferStatusEnum;
  initPosition?: string;
};

/**
 *
 * みんなの意見を元にアクションするポップアップフォーム
 * @author Kanta Shimizu
 *
 */
export const ActionForm = ({
  onSend,
  onClose,
  initPosition = '',
  status,
}: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const { offerPositions, getOfferPositions } = useOfferPositions();
  const [offerPositionsOPTIONS, setOfferPositionsOPTIONS] = useState<TFormOption[]>([]);

  // ====================================================================================================
  //
  // RHF
  //
  // ====================================================================================================
  const schema = yup.object().shape({
    action: YUP_VALIDATION.required.test(
      'action',
      VALIDATION_MESSAGE.required_new_value,
      (value) => value !== status?.toString()
    ),
    action_text: YUP_VALIDATION.null,
  });
  const methods = useForm<TInput>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      action: '',
      offer_position: initPosition,
      action_text: '',
    },
  });
  const { handleSubmit } = methods;

  // ====================================================================================================
  //
  // EVENT
  //
  // ====================================================================================================
  const submit = (data: TInput) => {
    onSend(data.action, data.action_text, data.offer_position);
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    // ポジション一覧をOPTIONに変換
    if (offerPositions && offerPositions.length > 0) {
      // activeなポジションのみ
      const activeOfferPositions = offerPositions.filter((position) => position.active);

      const reactionOptions = activeOfferPositions.map((position) => ({
        value: position.id + '',
        children: position.name,
      }));

      setOfferPositionsOPTIONS(reactionOptions);
    }
  }, [offerPositions]);

  useEffect(() => {
    // 一覧取得
    getOfferPositions();
  }, []);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)}>
        <div className="w-250 mt-8">
          <FormContainerDropdown
            name="action"
            className="select"
            placeholder="実行するアクションを選択"
            selectList={DIRECT_FORM_OPTIONS.actionOptions}
          />
        </div>

        <div className="mt-32">
          <p className="mbx-typography--caption_1 text-left">
            <span>紹介するポジションを選択してください。オファー時、候補者に開示されます。</span>
          </p>
          <FormContainerDropdown
            name="offer_position"
            className="select mt-8"
            placeholder="ポジションを選択"
            selectList={offerPositionsOPTIONS}
          />
        </div>

        <div className="mt-32">
          <p className="mbx-typography--caption_1 text-left">
            <span>
              マイナビワークスへの要望をご記入ください。なお、ここに入力した内容は、オファー時においても候補者に開示されません。
            </span>
          </p>
          <FormContainerTextArea
            name="action_text"
            maxLength={300}
            rows={2}
            className="mt-8 mkt_mask_items"
          />
        </div>
        <div className="flex justify-between mt-24">
          <div className="w-224">
            <div className="w-full base-button" onClick={onClose}>
              <div className="button size-m theme-secondary flex items-center">
                <span className="font-bold label">キャンセル</span>
              </div>
            </div>
          </div>
          <div className="w-224">
            <BaseButton theme="primary" size="m" className="w-full">
              この内容で投稿
            </BaseButton>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
