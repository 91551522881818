import '@/styles/common/Mypage/assetsbox.scss';

import React, { VFC } from 'react';

import { MypageUploadfileList } from './List';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { ButtonIconButton } from '@/components/common/Button/IconButton';

type TMypageUploadfileListProps = React.ComponentProps<typeof MypageUploadfileList>;

type TProps = {
  className?: string;
  resumeUpload: React.ChangeEventHandler<HTMLInputElement>;
  careerUpload: React.ChangeEventHandler<HTMLInputElement>;
  resume?: TMypageUploadfileListProps | null;
  career?: TMypageUploadfileListProps | null;
};

/**
 *
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=383%3A35945
 *
 */
export const MypageUploadfileBox: VFC<TProps> = ({
  className = '',
  resumeUpload,
  careerUpload,
  resume,
  career,
}) => {
  return (
    <section
      className={'bg-gray-50 lg:mb-80 sm:px-24 py-40 px-40 mx-auto ' + className}
    >
      <div className="flex">
        <h1 className="font-bold text-14_20">書類をアップロード</h1>
        <TooltipMessageMatchbou
          className="-mt-2"
          message="オファー機能ご利用時、より多くのオファーを受け取るために、ご経験やスキルに関する情報に限り、キャリアアドバイザーから企業へ伝える場合があります。なお、ご自身でブロック設定した企業へ開示されることはありません。"
          position={['0', '-164px']}
        >
          <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
        </TooltipMessageMatchbou>
      </div>
      <p className="text-12_20 mt-8">
        MATCHBOX以外で作成した履歴書・職務経歴書があれば、アップロードしてキャリアアドバイザーに見せることができます。
      </p>
      <div className="mt-8">
        <div className="mr-8 w-176 inline-block">
          <label>
            <div className="mr-8 min-w-full base-button">
              <p className="button size-s theme-secondary flex items-center">
                <span className="font-bold label">履歴書をアップロード</span>
              </p>
            </div>
            <input
              type="file"
              onChange={resumeUpload}
              accept=".jpeg,.jpg,.doc,.docx,.xlsx,.xls,.txt,.pdf,.pptx,.ppt"
              className="hidden"
            />
          </label>
        </div>
        <div className="mr-8 w-176 inline-block">
          <label>
            <div className="mr-8 min-w-full base-button">
              <p className="button size-s theme-secondary flex items-center">
                <span className="font-bold label">職務経歴書をアップロード</span>
              </p>
            </div>
            <input
              type="file"
              onChange={careerUpload}
              accept=".jpeg,.jpg,.doc,.docx,.xlsx,.xls,.txt,.pdf,.pptx,.ppt"
              className="hidden"
            />
          </label>
        </div>
        <p className="text-12_20 my-8 inline-block">
          ※ Text、Excel、Word、PowerPoint、PDF、JPG (1MB以内)
        </p>
      </div>
      <div className="mt-8">
        {resume && (
          <MypageUploadfileList
            date={resume?.date}
            name={resume?.name}
            size={resume?.size}
            fileDelete={resume?.fileDelete}
            pdfDl={resume?.pdfDl}
          />
        )}
        {career && (
          <MypageUploadfileList
            date={career?.date}
            name={career?.name}
            size={career?.size}
            fileDelete={career?.fileDelete}
            pdfDl={career?.pdfDl}
          />
        )}
        {(resume || career) && <hr className="mbx-keyline-horizontal--thin"></hr>}
      </div>
    </section>
  );
};
