import cn from 'classnames';
import React, { ReactNode, VFC } from 'react';

type TProps = {
  className?: string;
  value: string;
  children: ReactNode;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=393%3A40447
 */
export const FormDropdownMultipleSectionItem: VFC<TProps> = ({
  className,
  value,
  children,
}) => {
  return (
    <li
      className={cn(className, 'mbx-dropdown-selections-section-item text-14_20 font-bold px-10 py-14')}
      data-value={value}
    >
      <p>{children}</p>
    </li>
  );
};
