import React from 'react';

export const CareerTitle: React.VFC<{
  className?: string;
  children: React.ReactElement;
  index: number;
}> = (props) => {
  return (
    <div className={`flex justify-center items-center h-40 bg-gray-100 ${props.className}`}>
      <div className="flex justify-center items-center h-40 w-40 bg-gray-300 text-body_2 font-bold">
        {props.index}
      </div>
      <div className="flex-1 text-body_2 font-bold px-14 mkt_mask_items">{props.children}</div>
    </div>
  );
};
