import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ButtonTextLink } from '@/componentsDirect/Parts/Button/TextLink';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { useDownloadPDFForPortfolio } from '@/hooksAdmin/useDownloadPDFForPortfolio';
import { RootState } from '@/reduxAdmin';
import { setPortfolioPDFDownloadModal } from '@/reduxAdmin/modules/portfolio';

/**
 * global stateの値検知で表示するPDFダウンロード用のモーダル
 *
 * 仕様 :
 * - PDF生成完了時に全てのページで表示
 *
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=10595%3A174594&t=DYemNnu5kSHUaJmM-4
 */
export const GlobalPDFDownloadModalPortal = (): React.ReactElement => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.portfolio.portfolioPDFDownloadModal);
  const matchboxId = useSelector((state: RootState) => state.portfolio.matchboxId);
  const { downloadPDF, requestToGeneratePDF } = useDownloadPDFForPortfolio();

  const onClose = () => {
    dispatch(setPortfolioPDFDownloadModal(false));
  };

  return (
    <ModalDefault
      isOpen={isOpen}
      onClose={() => onClose()}
      className="w-784 sm:w-screen lg:px-80 md:px-80 rounded-4 sm:rounded-none"
    >
      <div className="lg:p-80 md:p-80 sm:py-48 sm:px-24">
        <h1 className="text-heading_4 sm:text-heading_3 font-body font-bold mb-24">
          ポートフォリオPDFダウンロードの準備が完了しました！
        </h1>
        <div className="sm:flex sm:flex-row">
          <BaseButton
            href=""
            onClick={() => {
              downloadPDF();
              onClose();
            }}
            size="m"
            theme="primary"
            iconName="Done"
            className="mb-24"
          >
            PDFをダウンロード
          </BaseButton>
        </div>

        <p className="mbx-typography--caption_2 font-body mb-36">
          直前の編集内容がPDFに反映されていない場合は
          <br />
          <ButtonTextLink
            onClick={(e) => {
              e.stopPropagation();
              requestToGeneratePDF(matchboxId);
              onClose();
            }}
          >
            こちらをクリック
          </ButtonTextLink>
          して再度ダウンロードの準備を開始してください
        </p>

        <BaseButton
          href=""
          onClick={() => {
            onClose();
          }}
          size="m"
          theme="link"
        >
          閉じる
        </BaseButton>
      </div>
    </ModalDefault>
  );
};
