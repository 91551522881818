import React from 'react';
import { useHistory } from 'react-router';

import { ButtonIconButton } from '@/componentsDirect/Parts/Button/';
import { CompanyGroupSummary } from '@/utils/api-client/api';
import { noDataText } from '@/utils/string';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type TProps = {
  data: CompanyGroupSummary;
  onDelete: (id: string) => boolean;
};

/**
 * ユーザーグループリストデータ
 * TableContainerと共に利用する
 * https://www.figma.com/file/U3CKCcmEc7eIjxkbYoCZx7/UI?type=design&node-id=11781-162244&mode=dev
 */
export const TableBodyUserGroupList = ({ data, onDelete }: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const history = useHistory();
  const { listeners, setNodeRef, transform, transition } = useSortable({ id: data.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  // ====================================================================================================
  //
  // method
  //
  // ====================================================================================================
  const onClick = () => {
    history.push({
      pathname: `/direct/user_groups/${data.id}`,
    });
  };

  // ====================================================================================================
  //
  // jsx
  //
  // ====================================================================================================
  return (
    <tr className="text-left border-b border-gray-200" ref={setNodeRef} style={style}>
      {/* 名前 */}
      <td className="pl-16 ">
        <span className="text-blue-700 cursor-pointer" onClick={onClick}>
          {noDataText(data.name)}
        </span>
      </td>
      {/* 削除機能 */}
      <td className="text-right pr-16  flex">
        <span className="dragHandle" {...listeners} data-cypress="draggable-handle">
          <ButtonIconButton type="gray" iconName="Sortable" />
        </span>
        <ButtonIconButton
          type="gray"
          iconName="Clear"
          onClick={(e) => {
            e.preventDefault();
            if (data.id) onDelete(data.id);
          }}
        />
      </td>
    </tr>
  );
};
