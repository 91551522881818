import { useState } from 'react';
import * as yup from 'yup';

import { YUP_VALIDATION } from '@/definitionDirect/VALIDATION';
import {
    baseValidation, initSearchConditionValuesBase, searchConditionNumberPropertiesBase
} from '@/hooksDirect/useSearchConditionValidation';
import { DirectSearchConditionModel } from '@/utils/api-client';
import {
    conversionStringsLikeNumbers, conversionValuesForPeriods, excludeValuesForSearchCondition,
    objectEquals
} from '@/utils/utils';

type TReturn = {
  initValues: DirectSearchConditionModel; // フォームの初期値 これと比較して変更があったかどうか判定
  isChanged: boolean; // 変更があれば true なければ false
  searchConditionChangeDetection: (changeData: DirectSearchConditionModel) => void; // 変更判定処理
};

/**
 * 検索条件フォーム用の validation 設定
 */
export const validationSchemaForPost = yup.object().shape({
  ...baseValidation,
});

/**
 * 検索条件編集用の validation 設定
 */
export const validationSchemaForEdit = yup.object().shape({
  search_condition_name: YUP_VALIDATION.required,
  ...baseValidation,
});

/**
 * Adminの初期値
 */
export const initSearchConditionValues: DirectSearchConditionModel = {
  ...initSearchConditionValuesBase,
};

/**
 * 数値に変換する必要がある要素
 */
export const searchConditionNumberProperties = [...searchConditionNumberPropertiesBase];

/**
 * 期間を変換する必要がある要素
 */
export const searchConditionPeriodsProperties = [
  'age',
  'offer_update_at',
  'career_history_amount',
  'portfolio_item_count',
];

// booleanに変更

/**
 * 企業向けの検索条件が変更されたかチェック
 */
export const useDirectSearchConditionValidation = (): TReturn => {
  const [isChanged, setIsChanged] = useState(false);
  // 検索条件の初期値 仕様書から構造とってくる
  // https://matchbox-2021-doc-api-git-develop-prty.vercel.app/#operation/post-direct-search_condition
  const initValues: DirectSearchConditionModel = initSearchConditionValues;

  /**
   * データに変更が発生したか検出
   */
  const searchConditionChangeDetection = (changeData: DirectSearchConditionModel) => {
    let convertValue = conversionValuesForPeriods(
      changeData as Record<string, unknown>,
      searchConditionPeriodsProperties
    );

    convertValue = conversionStringsLikeNumbers(convertValue, searchConditionNumberProperties);

    // 編集データの場合に不要なプロパティを除外
    convertValue = excludeValuesForSearchCondition(convertValue);

    setIsChanged(
      !objectEquals(initValues as Record<string, unknown>, convertValue as Record<string, unknown>)
    );
  };

  return { initValues, isChanged, searchConditionChangeDetection };
};
