import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UseFormProps } from 'react-hook-form';

import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { FormContainer, TCallback } from '@/components/common/Form/Container';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { FormRadioButtonItem } from '@/components/common/Form/RadioButtonItem';
import { LayoutColumDivider } from '@/components/common/Layout/ColumDivider';
import { ProfileImageSquare } from '@/components/common/OtherComponents/ProfileImageSquare';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { usePolling } from '@/hooks/usePolling';
import { TSectionProps } from '@/pages/Resume/Edit';
import { toggleLoading, setUploadedImage } from '@/redux/index';
import { FileResponse, ResumeApi, PortfolioApi, ImagePhoto } from '@/utils/api-client';
import { formatDate } from '@/utils/utils';

export const ResumeFormSectionProfile = ({ id, fetchData }: TSectionProps): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch();
  const [param, setParam] = usePolling(ResumeApi, 'patchResume');
  const [iconSrc, setIconSrc] = useState<ImagePhoto|undefined>(undefined);

  useEffect(() => {
    setIconSrc(fetchData?.user?.user_info?.photo);
  }, [fetchData?.user?.user_info?.photo]);

  const birth = fetchData
    ? new Date(
        `${fetchData.user?.user_info?.birth_year}/${fetchData.user?.user_info?.birth_month}/${fetchData.user?.user_info?.birth_day}`
      )
    : new Date();

  const useFormProps: UseFormProps = {
    mode: 'onBlur',
    defaultValues: {
      name: fetchData ? fetchData.user?.user_info?.name : '',
      furigana: fetchData ? fetchData.user?.user_info?.furigana : '',
      gender: fetchData ? fetchData.user?.user_info?.gender?.toString() : '',
      birth: formatDate(birth, 'YYYY年MM月DD日'),
    },
  };

  const onPatch: TCallback = (value, name, type) => {
    // type が undefinedの場合は RFH の setValue を利用した値反映なのでpatchしない
    if (name && type) {
      const data = {
        user: {
          user_info: {
            [name]: value[name],
          },
        },
      };
      setParam({ data });
    }
  };

  const onIconSave = (res: FileResponse) => {
    const data = {
      user: {
        user_info: {
          photo: { f_id: res.f_id },
        },
      },
    };
    setParam({ data });
    setIconSrc(res);
  };

  const onIconClick = async () => {
    if (!iconSrc) return;
    dispatch(toggleLoading(true));
    try {
      await new PortfolioApi().deleteFiles(iconSrc.f_id);
      const data = {
        user: {
          user_info: {
            photo: {},
          },
        },
      };
      setParam({ data });
      setIconSrc(undefined);
      dispatch(setUploadedImage(null));
    } catch (error) {
      console.error(error);
    }
    dispatch(toggleLoading(false));
  }

  return (
    <div id={id}>
      <FormContainer useFormProps={useFormProps} onPatch={onPatch} fetchData={fetchData}>
        <TitleSectionTitle>
          <div className="flex items-center">
            <span className="mr-4">プロフィール</span>
            <TooltipMessageMatchbou
              className=""
              message="オファー機能ご利用時、「氏名」「ふりがな」「生年月日」は企業に開示されません。"
              position={['0', '-94px']}
            >
              <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
            </TooltipMessageMatchbou>
          </div>
        </TitleSectionTitle>
        <LayoutColumDivider
          className={['mbx-utils-stack-v--16 sm:mt-32', 'flex items-center justify-center']}
        >
          <>
            <FormLayoutFieldset>
              <FormLabel label="氏名" type="small">
                <FormCaption></FormCaption>
              </FormLabel>
              <FormContainerTextfield name="name" readOnly={true} className="mkt_mask_items" />
            </FormLayoutFieldset>
            <FormLayoutFieldset>
              <FormLabel label="ふりがな" type="small">
                <FormCaption></FormCaption>
              </FormLabel>
              <FormContainerTextfield name="furigana" readOnly={true} className="mkt_mask_items" />
            </FormLayoutFieldset>
            <FormLayoutFieldset>
              <FormLabel label="性別" type="small"></FormLabel>
              <div className="mbx-utils-stack-h--54 flex mkt_mask_items">
                <FormRadioButtonItem label="男" name="gender" value="2"></FormRadioButtonItem>
                <FormRadioButtonItem label="女" name="gender" value="1"></FormRadioButtonItem>
                <FormRadioButtonItem
                  label="選択しない"
                  name="gender"
                  value="0"
                ></FormRadioButtonItem>
              </div>
            </FormLayoutFieldset>
            <FormLayoutFieldset>
              <FormLabel label="生年月日" type="small">
                <FormCaption></FormCaption>
              </FormLabel>
              <FormContainerTextfield name="birth" readOnly={true} className="mkt_mask_items" />
            </FormLayoutFieldset>
          </>
          <>
            <div className="flex flex-col items-center">
              <h1 className="text-12_16 font-bold py-6 mb-4 text-center">証明写真</h1>
              <ProfileImageSquare src={iconSrc?.f_url} onSave={onIconSave} className="mkt_mask_items w-104" />
              <div className="pt-16 text-center">
                <p className="text-caption_2 text-gray-700">横208px × 縦288px推奨、最大5MB</p>
              </div>
              {iconSrc?.f_url &&
                <div className="pt-16 text-center">
                  <div className="base-button isTextLink" onClick={onIconClick}>
                    <p className="button size-s theme-link flex items-center">
                      <i className="mbx-icon mbx-icon-Trash mr-2"></i>
                      <span className="font-bold label">画像を削除</span>
                    </p>
                  </div>
                </div>
              }
            </div>
          </>
        </LayoutColumDivider>
      </FormContainer>
    </div>
  );
};

const FormCaption = (): React.ReactElement => {
  return (
    <>
      <ButtonTextLink href="/mypage/profile">基本情報</ButtonTextLink>から編集できます
    </>
  );
};
