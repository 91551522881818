import { cloneDeep } from 'lodash';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { AdminCompany } from '@/utils/api-client';

type TInput = {
  tel: string;
};

type Props = {
  company: AdminCompany;
  setModal: React.MouseEventHandler<HTMLDivElement>;
  onSave: (company: AdminCompany) => Promise<void>;
};

export const TelModalForm = (props: Props): React.ReactElement => {
  const methods = useForm<TInput>({
    defaultValues: {
      tel: '',
    },
  });

  useEffect(() => {
    if (props.company.tel) {
      methods.setValue('tel', props.company.tel);
    }
  }, []);

  const onSave = async () => {
    const company = cloneDeep(props.company);
    company.tel = methods.getValues('tel');
    props.onSave(company);
  };

  return (
    <div className="w-624 py-80">
      <p className="mbx-typography--heading_4">電話番号を編集</p>
      <FormProvider {...methods}>
        <form className="w-308 mt-24 m-auto">
          <FormContainerTextfield name="tel" height="large" />
        </form>
      </FormProvider>
      <div className="flex justify-between w-308 m-auto mt-24">
        <div className="w-146">
          <BaseButton theme="secondary" size="m" className="w-full" onClick={props.setModal}>
            キャンセル
          </BaseButton>
        </div>
        <div className="w-146">
          <BaseButton theme="primary" size="m" className="w-full" onClick={onSave}>
            保存する
          </BaseButton>
        </div>
      </div>
    </div>
  );
};
