import { AxiosError } from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { MESSAGE } from '@/definitionDirect/MESSAGE';
import { useUser } from '@/hooksDirect/useUser';
import { notificationError, notificationSuccess, toggleLoading } from '@/reduxAdmin/modules/utils';
import { DirectApi, DirectCompanyModel, ResponseError } from '@/utils/api-client/api';

type TReturn = {
  companyData: DirectCompanyModel | undefined;
  getCompany: () => void;
  patchCompany: (request: DirectCompanyModel) => void;
};

/**
 * 企業情報設定
 */
export const useCompany = (): TReturn => {
  const API = new DirectApi();
  const dispatch = useDispatch();
  const { getCompanyUser } = useUser();

  const [companyData, setCompanyData] = useState<DirectCompanyModel>();

  /**
   * 企業情報取得
   */
  const getCompany = () => {
    API.getDirectCompany()
      .then((res) => {
        setCompanyData(res.data.company);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 企業情報編集
   */
  const patchCompany = (request: DirectCompanyModel) => {
    dispatch(toggleLoading(true));
    API.patchDirectCompany(request)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.saving_completed_for_company));
        getCompany();
        // ヘッダーのロゴに反映するためユーザー情報再取得
        getCompanyUser();
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
        dispatch(toggleLoading(false));
      });
  };

  return { companyData, getCompany, patchCompany };
};
