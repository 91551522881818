import { AxiosError } from 'axios';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { AdminPage } from '@/componentsDirect/Layout/Page';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { isLoggedIn, setLogin } from '@/reduxAdmin/modules/directAuth';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import { DirectApi, ResponseError } from '@/utils/api-client';

type IFormInputs = {
  id: string;
  password: string;
};

export const DirectLogin = (): React.ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { push } = React.useContext(DataLayerContext);

  const methods = useForm<IFormInputs>({
    defaultValues: {
      id: '',
      password: '',
    },
  });

  const onLogin: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    history.push(location.pathname);
    new DirectApi()
      .postDirectLogin(methods.getValues().id, methods.getValues().password)
      .then(() => {
        dispatch(isLoggedIn());
        dispatch(setLogin(true));
        push({
          event: 'loggedIn',
          actionType: 'logged_in',
          actionName: 'logged_in_success',
        });
      })
      .catch((error: AxiosError<ResponseError>) => {
        push({
          event: 'loggedIn',
          actionType: 'logged_in',
          actionName: 'logged_in_failure',
        });
        if (error.response) {
          dispatch(notificationError(error.response.data.error_message));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  return (
    <AdminPage header={<HeaderTitle title="ログイン" />}>
      <FormProvider {...methods}>
        <form>
          <div className="login-form w-308 sm:w-full m-auto mt-48 sm:mt-24 sm:px-24">
            <FormLayoutFieldset>
              <FormLabel label="ログインID (メールアドレス)" type="small" className="pt-6" />
              <FormContainerTextfield name="id" height="large" className="mkt_mask_items" />
            </FormLayoutFieldset>
            <FormLayoutFieldset className="mt-16">
              <FormLabel label="パスワード" type="small" className="pt-6" />
              <FormContainerTextfield
                name="password"
                type="password"
                height="large"
                className="mkt_mask_items"
              />
            </FormLayoutFieldset>
            <BaseButton theme="primary" size="m" className="btn mt-48" onClick={onLogin}>
              ログイン
            </BaseButton>
            <div className="flex flex-col justify-center sm:pb-64">
              <BaseButton theme="link" size="m" href="/direct/login/reminder" className="mt-40">
                パスワードを忘れた方はこちら
              </BaseButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </AdminPage>
  );
};
