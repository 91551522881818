import React, { useState } from 'react';
import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';
import { Container, Draggable, OnDropCallback } from 'react-smooth-dnd';

import * as CH from '@/components/CareerHistory/';
import { BaseButton } from '@/components/common/Button/';
import { Sorting } from '@/components/common/Form/Unit/Sorting';
import { ModalEditable, ModalEditableBody } from '@/components/common/Modal/Editable';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { TQualificationFormValues } from '@/types/Pages/CareerHistory';
import { moveArray } from '@/utils/utils';
import { QualificationQTypeEnum } from '@/utils/api-client';

type TProps = {
  onAppend(): void;
  onSort(result: FieldArrayWithId<TQualificationFormValues, 'qualifications', 'uid'>[]): void;
  patchItem(param: FieldArrayWithId<TQualificationFormValues, "qualifications", "uid">): void;
  deleteItem(qId: string): void;
};

export const Qualifications = ({ onAppend, onSort, patchItem, deleteItem }: TProps): React.ReactElement => {
  const mq = useMBXMediaQuery();
  const { control } = useFormContext<TQualificationFormValues>();
  const [showEditor, setShowEditor] = useState(false);

  const { fields, move, remove, update } = useFieldArray<TQualificationFormValues, 'qualifications', 'uid'>(
    {
      control,
      name: 'qualifications',
    }
  );

  const onDrop: OnDropCallback = ({ removedIndex, addedIndex }) => {
    if (removedIndex !== null && addedIndex !== null) {
      move(removedIndex, addedIndex);
      onSort(moveArray(fields, removedIndex, addedIndex));
    }
  };

  /**
   * 表示・非表示の切り替え
   */
  const toggleVisible = (index: number) => {
    const patchField = { ...fields[index], q_visible: !fields[index].q_visible };
    update(index, patchField);
    patchItem(patchField);
  };

  const onHideEducationEditor = () => {
    setShowEditor(false);
  };

  const dateString = (str: string) => {
    const date = str.split('-');
    return `${date[0]}年${date[1]}月`;
  };

  return (
    <>
      <TitleSectionTitle type="large" className="mt-12">
        資格・免許
      </TitleSectionTitle>

      <div className="space-y-8 my-22">
        <Container
          onDrop={onDrop}
          dragHandleSelector=".dragHandle"
          render={(ref) => {
            return (
              <div ref={ref} className="mb-8 sm:mb-18 mbx-formUnit-education">
                {fields.map((item, index) => (
                  <Draggable key={index} className="mbx-formUnit-education-box">
                    <CH.Qualification
                      index={index}
                      item={item}
                      onVisible={() => {toggleVisible(index);}}
                      onRemove={() => {
                        remove(index);
                        deleteItem(item.q_id);
                      }}
                    />
                  </Draggable>
                ))}
              </div>
            );
          }}
        />
        <div className="flex space-x-8">
          <BaseButton iconName="Add" size="s" theme="tertiary" onClick={onAppend}>
            資格・免許を追加
          </BaseButton>
          {mq.sm && (
            <BaseButton
              iconName="Sortable"
              size="s"
              theme="quaternary"
              disabled={fields.length <= 1}
              onClick={() => {
                setShowEditor(true);
              }}
            >
              並べ替え・表示
            </BaseButton>
          )}
        </div>
      </div>

      {showEditor && (
        <ModalEditable>
          <ModalEditableBody onClose={onHideEducationEditor}>
            <Container
              onDrop={onDrop}
              dragHandleSelector=".dragHandle"
              render={(ref) => {
                return (
                  <div ref={ref}>
                    {fields.map((item, index) => (
                      <Draggable key={item.uid}>
                        <Sorting
                          handle="dragHandle"
                          visible={item.q_visible}
                          onVisible={() => {toggleVisible(index);}}
                          onDelete={item.q_type !== QualificationQTypeEnum.Text ? (
                            undefined
                          ) : (
                            () => {
                              remove(index);
                              deleteItem(item.q_id);
                            }
                          )}
                        >
                          <>
                            {dateString(item.q_date)}
                            <br />
                            {item.q_title}
                          </>
                        </Sorting>
                      </Draggable>
                    ))}
                  </div>
                );
              }}
            />
          </ModalEditableBody>
        </ModalEditable>
      )}
    </>
  );
};
