import { TDictionary } from '@/types/Util';

export const PLACEHOLDER: TDictionary<string> = {
  year: 'YYYY',
  month: 'MM',
  e_school_name: '例）マイナビデザイン専門学校 入学',
  postal_code: '例）1234567',
  address2: '例）東京都町田市中町1-2-3　マッチハイツ101号室',
  address1: '例）とうきょうとまちだしなかまち',
  tel: '例）09012345678',
  email: 'abc@example.com',
  statement:
    '例）Webデザインスキル、UI/UX設計のスキルを活かしながら、よりプロダクトやサービス開発のプロデュース的立場にキャリアアップしたいと決意しています。現在、そのために6ヵ月間の短期カリキュラムではありますが、ビジネススクールに通っており、ビジネス視点でのノウハウを習得中です。',
  hope_job: '例）貴社規定に準じます。',
  interest_things:
    '例）YouTubeの英会話チャンネルを毎日20分は見るようにしており、英語力を日々鍛えています。ビジネスレベルの英会話まではできませんが、メールでのやり取りは可能です。',
  dependent_family: '例）2',
};
