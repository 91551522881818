import React from 'react';

import { useDataLayer } from '@/hooks/common/useDataLayer';
import { DataLayerType } from '@/types/DataLayerType';

export const DataLayerContext = React.createContext<{ push: (param: DataLayerType) => void }>({
  push: () => {
    //
  },
});
export const DataLayerProviderContainer: React.FC = (props) => {
  const { push } = useDataLayer();

  return <DataLayerContext.Provider value={{ push }}>{props.children}</DataLayerContext.Provider>;
};
