import cn from 'classnames';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { iconNameList } from '@/assets/fonts/manifest';
import { ModalCropper } from '@/componentsDirect/Parts/Modal/Cropper';
import { ImageFade } from '@/componentsDirect/Utils/ImageFade';
import { useFileLimit } from '@/hooks/useFIleLimit';

export type TProps = {
  className?: string;
  name: string;
  iconName: typeof iconNameList[number];
  iconSize?: number;
  readOnly?: boolean; // アップ機能なしで表示のみ
  animeClass?: string;
};

/**
 * 企業ロゴおよび企業ユーザーアイコンの表示
 * RFHのform内でで利用する想定の設計
 *
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65938
 */
export const ProfileImageCircle = ({
  className,
  name,
  iconName,
  iconSize = 32,
  readOnly,
  animeClass,
}: TProps): React.ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const methods = useFormContext();
  const register = methods.register(name);
  const { watch } = methods;
  const { checkFileSize } = useFileLimit();

  /**
   * 画像が選択されたらData URIに変換して画像クロップモーダルを表示
   */
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    const imageFile = e.target.files[0];
    if (!checkFileSize(imageFile.size, 5)) return;
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.addEventListener(
      'load',
      function () {
        setImageSrc(reader.result as string);
        setShowModal(true);
      },
      false
    );
  };

  const onClose = (): void => {
    setShowModal(false);
  };

  const onSave = (dataUri: string): void => {
    methods.setValue(name, dataUri);
  };

  /**
   * 同じファイルを再度読み込もうとするとchangeイベントが発火しないので
   * input file の値をクリアする
   *
   */
  const clearFilePath: React.MouseEventHandler<HTMLInputElement> = (event) => {
    const { target } = event;
    if (!(target instanceof HTMLInputElement)) return;
    target.value = '';
  };
  return (
    <div className={cn('relative w-128 h-128 m-auto mbx-utils-hover', className)}>
      <ModalCropper
        isOpen={showModal}
        onClose={onClose}
        width={128}
        height={128}
        src={imageSrc}
        className="rounded-modalContainer w-624"
        onSave={onSave}
      />
      <label htmlFor="IMAGE_UPLOAD_FILE">
        <input
          id="IMAGE_UPLOAD_FILE"
          className="hidden"
          type="file"
          accept="image/*"
          onChange={onChange}
          onClick={clearFilePath}
          disabled={readOnly}
        ></input>
        <input type="hidden" {...register} />
        {!readOnly && (
          <div className="absolute w-40 h-40 text-24_24 flex justify-center items-center rounded-full bg-gray-700 text-white bottom-0 right-0 z-10 cursor-pointer hover_gray-500">
            <i className="mbx-icon mbx-icon-Camera"></i>
          </div>
        )}
        {watch(name) ? (
          <ImageFade
            src={watch(name)}
            animeClass={animeClass}
            className={cn('bg-center bg-cover bg-no-repeat rounded-full w-full h-full', {
              'cursor-pointer': !readOnly,
            })}
          />
        ) : (
          <div
            className={cn(
              `bg-gray-400 text-gray-200 w-full h-full rounded-full flex justify-center items-center`,
              {
                'cursor-pointer': !readOnly,
              }
            )}
            style={{
              fontSize: `${iconSize}px`,
              lineHeight: `${iconSize}px`,
            }}
          >
            <i className={`mbx-icon mbx-icon-${iconName}`}></i>
          </div>
        )}
      </label>
    </div>
  );
};
