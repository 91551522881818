import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';

import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { Graph } from '@/componentsDirect/Candidates/Detail/Graph';
import { MessageSent } from '@/componentsDirect/Candidates/Detail/MessageSent';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { TableContainer, TableHeader } from '@/componentsDirect/Parts/Table';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { BasicInfo } from '@/componentsDirect/Parts/User/Module/BasicInfo';
import { Motivation } from '@/componentsDirect/Parts/User/Module/Motivation';
import { UserStatusLabel } from '@/componentsDirect/Parts/User/Module/Status';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import {
  ADMINApi,
  CareerHistory,
  LastLogin,
  MyNavi,
  Offer,
  OfferPositionData,
  OfferStatusEnum,
  ReactionsResponseAllOfReactions,
  ResponseSuccess,
  UserResumeInfo,
} from '@/utils/api-client';
import {
  convertLineBreak,
  dataStringSplitter,
  getAge,
  linkifyUrls,
  noSelectText,
} from '@/utils/string';

type TUser = {
  career_history: CareerHistory;
  user_info: UserResumeInfo;
  last_login: LastLogin[];
  registration_date: string;
  matchbox_id: string;
  mc: MyNavi;
  offers: Offer[];
};

type TOfferStatus = {
  status: OfferStatusEnum;
  rate: number;
  updatedAt?: string;
  userName?: string;
};

export const CandidatesDetail = (): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const params: { company_id: string; offer_id: string } = useParams();
  const history = useHistory();
  const columns = [
    {
      title: 'ポジション名（職種名）',
      className: 'whitespace-nowrap',
    },
    {
      title: 'ポジション詳細（業務内容詳細）',
    },
  ];

  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [user, setUser] = useState<TUser>();
  const [reactions, setReactions] = useState<ReactionsResponseAllOfReactions[]>();
  const [position, setPosition] = useState<OfferPositionData>();
  const [pickUpPosition, setPickUpPosition] = useState<OfferPositionData>();
  const [status, setStatues] = useState<TOfferStatus>();

  // ====================================================================================================
  //
  // methods
  //
  // ====================================================================================================
  const dateFormat = (date: string) => {
    const year = dataStringSplitter(date)[0];
    const month = dataStringSplitter(date)[1];
    const day = dataStringSplitter(date)[2];
    return year + '年' + month + '月' + day + '日';
  };

  const dateFormatUpdated = (date: string) => {
    const year = dataStringSplitter(date)[0];
    const month = dataStringSplitter(date)[1];
    const dayTime = dataStringSplitter(date)[2].split(' ');
    return year + '年' + month + '月' + dayTime[0] + '日 ' + dayTime[1];
  };

  // ====================================================================================================
  //
  // effect
  //
  // ====================================================================================================
  useEffect(() => {
    (async () => {
      const offers = await new ADMINApi().getAdminOffers(
        '1',
        '0',
        '',
        '',
        [1, 2, 3, 4, 5, 6, 7],
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        params.offer_id
      );
      const offer = offers.data.offers?.length ? offers.data.offers[0] : undefined;
      if (offer?.reactions) {
        setReactions(offer?.reactions.reverse() as ReactionsResponseAllOfReactions[]);
      }
      if (offer?.position) {
        setPosition(offer?.position);
      }
      if (offer?.reaction_position) {
        setPickUpPosition(offer?.reaction_position);
      }
      if (offer?.matchbox_id) {
        getUser(offer.matchbox_id);
      }
      if (offer?.status) {
        setStatues({
          status: offer?.status,
          rate: offer.rate ? offer.rate : 0,
          updatedAt: offer.updated_at,
          userName: offer.user?.name ? offer.user?.name : '',
        });
      }
    })();
  }, []);
  const getUser = async (id: string) => {
    const res = await new ADMINApi().getAdminUserId(id);
    if (res.data) {
      const data = res.data as unknown;
      setUser(data as TUser & ResponseSuccess);
    }
  };

  // ====================================================================================================
  //
  // memo
  //
  // ====================================================================================================
  // 最新のリアクションデータ
  const latestReaction = useMemo(() => {
    if (status?.updatedAt) {
      const date = dateFormatUpdated(status?.updatedAt);
      // 最終更新：萱沼守2023/10/24 11:23
      return `最終更新：${status.userName} ${date}`;
    } else {
      return '';
    }
  }, [status]);

  const basicInfo = useMemo(() => {
    FORM_OPTIONS.prefecture;
    const prefecture = FORM_OPTIONS.prefecture.find(
      (p) => p.value === user?.user_info.prefecture?.toString()
    )?.children;
    const gender = FORM_OPTIONS.gender.find(
      (g) => g.value === user?.user_info.gender?.toString()
    )?.children;
    const age =
      getAge(
        (user?.user_info as UserResumeInfo)?.birth_year,
        (user?.user_info as UserResumeInfo)?.birth_month,
        (user?.user_info as UserResumeInfo)?.birth_day
      ) + '歳';
    const job = FORM_OPTIONS.experienced_job.find(
      (j) => j.value === user?.mc.mc_experienced_job?.toString()
    )?.children;
    const year = FORM_OPTIONS.experienced_year.find(
      (y) => y.value === user?.mc.mc_experienced_year?.toString()
    )?.children;
    const money = FORM_OPTIONS.yearly_income.find(
      (i) => i.value === user?.mc.mc_yearly_income?.toString()
    )?.children;

    return [prefecture, gender, age, job, year, money].filter((v) => {
      return v !== undefined;
    });
  }, [user]);

  const currentInfo = useMemo(() => {
    const situation = FORM_OPTIONS.situation.find(
      (s) => s.value === user?.mc.mc_situation?.toString()
    )?.children;

    const section = user?.career_history.careers?.length
      ? user?.career_history?.careers[0].c_section
      : undefined;
    const post = user?.career_history.careers?.length
      ? user.career_history.careers[0].c_post
      : undefined;
    const employmentStatus = user?.career_history.careers?.length
      ? user?.career_history.careers[0].c_employment_status
      : undefined;
    let type = undefined;
    if (employmentStatus) {
      type = FORM_OPTIONS.employment_contract_type.find(
        (t) => t.value === employmentStatus.toString()
      )?.children;
    }
    return [situation, section, post, type].filter((v) => {
      return v !== undefined;
    });
  }, [user]);

  // ====================================================================================================
  //
  // jsx
  //
  // ====================================================================================================
  return (
    <AdminPage header={<HeaderTitle title="企業情報" updated={latestReaction} />}>
      <section>
        <BaseButton
          theme="link"
          iconName="Arrow_Left"
          size="s"
          href={`/admin/companies/${params.company_id}/candidates/`}
          className="mt-16"
        >
          一覧へ戻る
        </BaseButton>
        <section className="flex items-center mt-28">
          <Motivation
            changeDate={
              user?.mc.mc_preferred_change_date
                ? (user?.mc.mc_preferred_change_date as 1 | 2 | 3 | 4 | 5 | 6)
                : 6
            }
          />
          <BasicInfo className="ml-16" items={basicInfo as string[]} color="blue" size="m" />
          <div className="ml-16">
            {status && <UserStatusLabel status={status?.status} rate={status?.rate} />}
          </div>
        </section>
        <h3 className="mt-12 text-18_24 font-bold">{user?.user_info.name}</h3>
        <BasicInfo className="mt-6" items={currentInfo as string[]} size="s" />
        <p className="mt-18 text-gray-600 text-10_12">
          <span>MB ID：{user?.matchbox_id}</span>
          <span className="ml-16">
            アカウント開設日：{user?.registration_date && dateFormat(user?.registration_date)}
          </span>
          <span className="ml-16">
            最終ログイン日：{user?.last_login[0] && dateFormat(user?.last_login[0].login_date)}
          </span>
        </p>
        <div className="w-224 mt-56">
          <BaseButton
            theme="secondary"
            size="s"
            className="w-full"
            onClick={() =>
              history.push({
                pathname: `/admin/users/${user?.matchbox_id}`,
                state: {
                  referrer: 'candidates',
                  company_id: params.company_id,
                  offer_id: params.offer_id,
                },
              })
            }
          >
            求職者の詳細情報を見る
          </BaseButton>
        </div>
        <div>
          <h4 className="mt-40 mbx-typography--heading_4">この求職者に対するみんなの意見</h4>
          {reactions && <Graph className="mt-24" reactions={reactions} />}
          {reactions?.length !== 0 && (
            <section className="mt-16 pb-84">
              {reactions &&
                reactions.map((reaction, index) => {
                  return (
                    <div key={index}>
                      <MessageSent reaction={reaction} />
                    </div>
                  );
                })}
            </section>
          )}
        </div>

        <div>
          <h4 className="mt-40 mbx-typography--heading_4">
            この求職者をピックアップした時の紐付けポジション
          </h4>
          <section className="mt-16 pb-84">
            <TableContainer className="w-full">
              <TableHeader columns={columns}></TableHeader>
              <tbody>
                <tr className="text-left border-b border-gray-200">
                  {/* ボジション名 */}
                  <td className="pl-16 py-14 font-bold text-subhead_2">
                    {noSelectText(pickUpPosition?.name)}
                  </td>
                  {/* ポジション詳細 */}
                  <td
                    className="pl-16 py-14 text-subhead_2"
                    dangerouslySetInnerHTML={{
                      __html: convertLineBreak(linkifyUrls(pickUpPosition?.body)),
                    }}
                  ></td>
                </tr>
              </tbody>
            </TableContainer>
          </section>
        </div>

        <div>
          <h4 className="mt-40 mbx-typography--heading_4">
            この求職者に直近でアクションした時の紹介ポジション
          </h4>
          <section className="mt-16 pb-84">
            <TableContainer className="w-full">
              <TableHeader columns={columns}></TableHeader>
              <tbody>
                <tr className="text-left border-b border-gray-200">
                  {/* ボジション名 */}
                  <td className="pl-16 py-14 font-bold text-subhead_2">
                    {noSelectText(position?.name)}
                  </td>
                  {/* ポジション詳細 */}
                  <td
                    className="pl-16 py-14 text-subhead_2"
                    dangerouslySetInnerHTML={{
                      __html: convertLineBreak(linkifyUrls(position?.body)),
                    }}
                  ></td>
                </tr>
              </tbody>
            </TableContainer>
          </section>
        </div>
      </section>
    </AdminPage>
  );
};
