import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';

import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { BaseButton } from '@/componentsAdmin/Parts/Button';
import { HeaderTitle } from '@/componentsAdmin/Parts/Title/HeaderTitle';
import {
  AdminSearchFormBasicInfo,
  AdminSearchFormReset,
  AdminSearchFormSectionOffer,
  AdminSearchFormSectionTags,
  AdminSearchFormSectionUpdates,
} from '@/componentsAdmin/Search';
import {
  DirectSearchFormSectionDesired,
  DirectSearchFormSectionEmploymentStatus,
  DirectSearchFormSectionFreeWord,
  DirectSearchFormSectionQualifications,
  DirectSearchFormSectionWorkExperience,
} from '@/componentsDirect/Search';
import { useSearchCondition } from '@/hooksAdmin/useSearchCondition';
import {
  useSearchConditionValidation,
  validationSchemaForPost,
} from '@/hooksAdmin/useSearchConditionValidation';
import { AdminSearchConditionModel } from '@/utils/api-client';
import { yupResolver } from '@hookform/resolvers/yup';

/**
 * Admin 求職者検索 入力フォーム マイナビ向け
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A63469
 */
export const AdminSearchForm = (): React.ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const { searchCondition, saveSearchCondition } = useSearchCondition();
  const { initValues } = useSearchConditionValidation();

  const methods = useForm<AdminSearchConditionModel>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchemaForPost),
    defaultValues: initValues,
  });
  const { handleSubmit, reset, control } = methods;
  const { isValid } = useFormState({ control });

  /**
   * 再編集の際は保持しているデータをフォームに反映
   * ただし mc_experienced_job は useFieldArray で反映するので
   * コンポーネント側で対応
   */
  useEffect(() => {
    if (/reedit$/.test(location.search)) {
      if (searchCondition) {
        setDefaultValues(searchCondition);
      }
    }
  }, []);

  /**
   * 設定した条件をreduxに保持して結果ページに移動
   */
  const onSubmit: SubmitHandler<AdminSearchConditionModel> = (data) => {
    saveSearchCondition(data);
    history.push('/admin/search/result');
  };

  /**
   * データをRHFに値をsetValueする
   */
  const setDefaultValues = (res: AdminSearchConditionModel) => {
    (Object.keys(res) as (keyof AdminSearchConditionModel)[]).forEach((v) => {
      if (res?.[v] !== undefined && res?.[v] !== null) {
        if (v !== 'mc_experienced_job') {
          methods.setValue(v, res?.[v]);
        }
      }
    });
  };

  return (
    <AdminPage
      header={<HeaderTitle title="求職者検索" />}
      sticky={<StickyButton isValid={isValid} />}
    >
      <FormProvider {...methods}>
        <div className="flex justify-end py-25">
          <AdminSearchFormReset reset={reset} />
          <BaseButton size="s" theme="link" className="mr-32" href="/admin/direct/search">
            企業に共有する検索条件を作成する
          </BaseButton>
          <BaseButton size="s" theme="link" href="/admin/conditions">
            保存した条件を見る
          </BaseButton>
        </div>
        <section>
          <form
            id="FORM"
            className="pb-80 border-t border-gray-300"
            onSubmit={handleSubmit(onSubmit)}
          >
            <DirectSearchFormSectionFreeWord />
            <AdminSearchFormSectionOffer />
            <AdminSearchFormBasicInfo />
            <DirectSearchFormSectionEmploymentStatus />
            <AdminSearchFormSectionUpdates />
            <DirectSearchFormSectionWorkExperience />
            <DirectSearchFormSectionQualifications />
            <DirectSearchFormSectionDesired />
            <AdminSearchFormSectionTags />
          </form>
        </section>
      </FormProvider>
    </AdminPage>
  );
};

type TPropsButton = {
  isValid: boolean;
};

const StickyButton = ({ isValid }: TPropsButton): React.ReactElement => {
  return (
    <div className="sticky bottom-10 z-30">
      <BaseButton
        size="m"
        theme="primary"
        type="submit"
        iconName="Search"
        className="absolute right-20 bottom-12"
        disabled={!isValid}
        form="FORM"
      >
        この条件で検索する
      </BaseButton>
    </div>
  );
};
