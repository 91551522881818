import React from 'react';
import BaseButton from '../Button/BaseButton';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';

type Props = {
  heading: string;
  title: string;
  description: string;
  textPosition: 'top' | 'bottom';
  children: React.ReactElement;
};
function TopModalLayout(props: Props): React.ReactElement {
  const mq = useMBXMediaQuery();
  return (
    <div className="contents">
      <p className="heading">
        <span>{props.heading}</span>
      </p>
      <h3 className="title" dangerouslySetInnerHTML={{ __html: props.title }}></h3>
      {props.textPosition === 'top' && (
        <div className="text" dangerouslySetInnerHTML={{ __html: props.description }}></div>
      )}
      {props.children}
      {props.textPosition === 'bottom' && (
        <div className="text" dangerouslySetInnerHTML={{ __html: props.description }}></div>
      )}
      {!mq.sm && (
        <BaseButton theme="primary" size="m" className="modal-btn" href="/entry">
          MATCHBOXをはじめる
        </BaseButton>
      )}
    </div>
  );
}

export default TopModalLayout;
