import React, { useMemo } from 'react';
import CirclePlaceholder from '@/assets/images/profile/circle-placeholder.svg';
import { dataStringSplitter } from '@/utils/string';
import { CompanyUser } from '@/utils/api-client';

type Props = {
  user: CompanyUser;
};

export const BasicInfoLeftUser = (props: Props): React.ReactElement => {
  const accountType = useMemo(() => {
    if (props.user.user_type === 'direct_manager') return '利用責任者';
    if (props.user.user_type === 'direct_user') return '一般利用者';
    if (props.user.user_type === 'direct_hr') return '採用担当者';
  }, [props.user]);

  const dateFormat = (date: string) => {
    const year = dataStringSplitter(date)[0];
    const month = dataStringSplitter(date)[1];
    const day = dataStringSplitter(date)[2];
    return year + '年' + month + '月' + day + '日';
  };

  const details = [props.user.section, props.user.post, accountType];

  return (
    <section className="flex items-center text-left">
      <div className="w-64 h-64 rounded-full overflow-hidden">
        <img src={props.user.photo ? props.user.photo : CirclePlaceholder} alt="" />
      </div>
      <div className="ml-16">
        <h3 className="text-18_24 font-bold">{props.user.name}</h3>
        <p className="mt-6 mbx-typography--caption_1">
          {details
            .filter((v) => v)
            .map((item, index) => {
              return (
                <span key={index}>
                  {index !== 0 && <span className="mx-4">/</span>}
                  {item}
                </span>
              );
            })}
        </p>
        <p className="mt-6 text-10_12 text-gray-600">
          <span>企業担当ID：{props.user.matchbox_id}</span>
          {props.user.registration_date && (
            <span className="ml-16">
              アカウント開設日：{dateFormat(props.user.registration_date)}
            </span>
          )}
          {props.user.last_login && (
            <span className="ml-16">最終ログイン日：{dateFormat(props.user.last_login)}</span>
          )}
        </p>
      </div>
    </section>
  );
};
