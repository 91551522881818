import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { usePolling } from '@/hooks/usePolling';
import { notificationError, setSaved } from '@/redux';
import { State } from '@/redux/state';
import {
    Portfolio, PortfolioApi, PortfolioItem, PortfolioPage, PortfolioPagePPageLayoutEnum,
    ResponseError
} from '@/utils/api-client/index';
import { p_contents, updateQuestion } from '@/utils/updateQuestion';

const defaultPage: PortfolioPage = {
  ...Object.assign({
    p_id: 0,
    p_page_layout: PortfolioPagePPageLayoutEnum.Questions,
  }),
  p_contents,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDetailEdit = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [param, setParam, response, saveFrag, setBeforeUnload] = usePolling(
    PortfolioApi,
    'patchPortfolioEdit',
    undefined,
    1000
  );
  const [pfData, setPFData] = useState<Portfolio>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [userId, setUserId] = useState<string>();
  const user = useSelector((state: State) => state.user);
  const history = useHistory();
  const pfItemId = parseInt(history.location.pathname.split('/')[4]);
  const dispatch = useDispatch();

  useEffect(() => {
    setUserId(user?.user_id);
  }, [user]);

  useEffect(() => {
    if (pfData) return;
    fetchPortfolio();
  }, [user, pfData]);

  const fetchPortfolio = async () => {
    if (!user?.user_id) return;
    const result: AxiosResponse<Portfolio> = await new PortfolioApi().getPortfolioEdit(
      user?.user_id
    );
    setPFData(result.data);
  };

  const patchPortfolio = async (data: Portfolio) => {
    if (!user?.user_id) return;
    new PortfolioApi()
      .patchPortfolioEdit(user?.user_id, data)
      .then((result: AxiosResponse<Portfolio>) => {
        setPFData(result.data);
        dispatch(setSaved(true));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      });
  };

  //pfDataからURLに該当するPortfolioItemを抽出する
  const pfItem = useMemo<PortfolioItem | undefined>(() => {
    if (!pfData) return undefined;
    if (!pfData.items) return undefined;
    return Array.from(pfData.items).find((item: PortfolioItem) => item.i_id === pfItemId);
  }, [pfData]);

  useEffect(() => {
    if (response?.data) setPFData(response.data);
  }, [response]);

  useEffect(() => {
    if (!pfItem) return;
    //i_pagesが0の場合、Questionの初期状態のものを一度patchする
    if (Array.from(pfItem.i_pages).length === 0) {
      if (!pfData) return;
      const items: PortfolioItem[] = Array.from(pfData.items?.values() || []).map(
        (item: PortfolioItem) => {
          if (item.i_id !== pfItemId) return item;
          return {
            ...Object.assign(item),
            i_pages: [defaultPage],
          };
        }
      );

      const data: Portfolio = {
        ...Object.assign(pfData),
        items,
      };

      patchPortfolio(data);
    }
  }, [pfItem]);

  const setQuestionParam = (qId: string, sections: string[], question?: string) => {
    let portfolio: Portfolio | undefined;
    let userId: string | undefined;

    //関数型のsetStateで現在のstateを得る
    const setPFDataPromise = new Promise<void>((resolve) => {
      setPFData((prevPf) => {
        if (prevPf) {
          portfolio = updateQuestion(prevPf, pfItemId, qId, sections, question);
        }
        resolve();
        return prevPf;
      });
    });

    const setUserIdPromise = new Promise<void>((resolve) => {
      setUserId((prevId) => {
        userId = prevId;
        resolve();
        return prevId;
      });
    });

    Promise.all([setPFDataPromise, setUserIdPromise]).then(() => {
      if (userId && portfolio) {
        setParam({
          userId,
          portfolio,
        });
      }
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setItem = (param: any) => {
    let portfolio: Portfolio | undefined;
    //関数型のsetStateで現在のstateを得る
    setPFData((prevPf) => {
      if (prevPf && prevPf.items) {
        portfolio = {
          ...Object.assign(prevPf),
          items: Array.from(prevPf.items).map((i: PortfolioItem) => {
            if (i.i_id === pfItemId) {
              return {
                ...i,
                ...param,
              };
            } else {
              return i;
            }
          }),
        };
      }
      return prevPf;
    });

    if (portfolio) patchPortfolio(portfolio);
  };

  return {
    pfItem,
    pfItemId,
    pfData,
    fetchPortfolio,
    patchPortfolio,
    setParam,
    setQuestionParam,
    setItem,
    setBeforeUnload,
  };
};
