import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import { CompanyModule } from '@/components/common/Admin/SearchResult/CompanyModule';
import { SearchConditions } from '@/components/common/Admin/SearchResult/SearchConditions';
import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { Pager } from '@/componentsDirect/Parts/Navigation/Page/Pager';
import { BoxEmpty } from '@/componentsDirect/Parts/OtherComponents/BoxEmpty';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import {
    AdminDirectCompanySearchConditionModel, AdminSearchCompaniesResponse
} from '@/utils/api-client';

export const AdminCompanyResult = ({
  result,
  updateSearchResult,
  conditions,
}: {
  result: AdminSearchCompaniesResponse | undefined;
  conditions: AdminDirectCompanySearchConditionModel | undefined;
  updateSearchResult: (page: number) => Promise<void>;
}): React.ReactElement => {
  const history = useHistory();
  const [page, setPage] = useState<number>(0);
  const [pageList, setPageList] = useState<number[]>([]);
  const [didResultMount, setDidResultMount] = useState<boolean>(false);

  useEffect(() => {
    if (!result) {
      history.push('/admin/companies/search/');
    }
    setDidResultMount(true);
  }, [result]);

  useEffect(() => {
    if (didResultMount) {
      updateSearchResult(page);
    }
  }, [page]);

  const pageLength: number = useMemo(() => {
    const total = result?.total || 0;
    return total / 10;
  }, [result]);

  return (
    <AdminPage header={<HeaderTitle title="企業検索  結果一覧" />}>
      <div className="mt-48">
        <SearchConditions
          saved={true}
          conditions={conditions || null}
          onClickChange={() => {
            history.push('/admin/companies/search');
          }}
          onClickSave={() => {
            /** */
          }}
        />
        <div className="flex items-center space-x-96 mt-54 mb-22">
          <div className="flex items-center justify-start">
            <section className="text-12_20 mr-40">
              {`${page * 10 + 1} 〜 ${Math.min(page * 10 + 10, result?.total || page * 10 + 10)} / ${
                result?.total
              }件`}
            </section>
            {result?.companies?.length !== 0 && (
              <Pager pageLength={Math.ceil(pageLength)} page={page} setPage={setPage} setPageList={setPageList} pageList={pageList} />
            )}
          </div>
        </div>
        {result?.companies?.length === 0 ? (
          <BoxEmpty message="検索結果がありません。" />
        ) : (
          result?.companies?.map((company, index) => <CompanyModule key={`${company.id}_${index}`} data={company} />)
        )}
        <div className="flex items-center justify-start">
          <section className="text-12_20 mr-40">
            {`${page * 10 + 1} 〜 ${Math.min(page * 10 + 10, result?.total || page * 10 + 10)} / ${
              result?.total
            }件`}
          </section>
          {result?.companies?.length !== 0 && (
            <div className="flex items-center justify-center">
              <Pager pageLength={Math.ceil(pageLength)} page={page} setPage={setPage} setPageList={setPageList} pageList={pageList} />
            </div>
          )}
        </div>
      </div>
    </AdminPage>
  );
};
