import React from 'react';

import { DraggableWrapper } from '@/components/common/DraggableWrapper';

type TProps = {
  index?: number; // 呼び出し元からイテレーターのindexをもらう措定
  body: string; // 職歴のテキスト
  period: string; // 職歴の期間を XXXX年X月〜XXXX年X月 の書式で
  className?: string;
  invisible?: boolean;
  onVisible?: React.MouseEventHandler<HTMLButtonElement>;
  dragHandle?: string;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=331%3A6760
 * !see https://matchbox-management.slack.com/archives/G01KQUUNX6W/p1624185233158200?thread_ts=1623715886.143700&cid=G01KQUUNX6W
 *
 * invisibleのスタイルは調整中
 *
 * Uneditable / Invisible を表示する
 *
 * ドラッグエリアが通常のコンポーネントと違うためTitleSectionTitleMediumコンポーネントを利用せずに構築
 *
 * TODO disabledの処理 APIの仕様確定待ち
 */
export const CareerUneditable = (props: TProps): React.ReactElement => {
  return (
    <DraggableWrapper {...{ ...props, title: `職歴 ${props.index}` }}>
      <>
        <p className="text-subhead_1 mb-10 mkt_mask_items">{props.body}</p>
        <p className="text-body_2 mkt_mask_items">{props.period}</p>
      </>
    </DraggableWrapper>
  );
};
