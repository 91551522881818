import React from 'react';

import { NaviInpageMenu } from '@/components/common/Navigation/Inpage/Menu';
import { Repeat } from '@/types/Util';

type TMenuProps = React.ComponentProps<typeof NaviInpageMenu>;

export const PageMenuContent: React.VFC<{
  progress: Repeat<0 | 1, 4>;
}> = ({ progress }) => {
  // const progressData = usePagePortfolioProgress();
  // if (!progressData) return <></>;

  const progressData = {
    progress,
  };

  const menu = [
    ['職務経歴概要', 'summary'],
    ['資格・免許', 'qualification'],
    ['テクニカルスキル', 'skill'],
    ['自己PR', 'pr'],
  ].map((value, index) => ({
    children: value[0],
    disabled: false,
    done: progressData.progress[index],
    href: value[1],
  }));

  const props: TMenuProps = {
    menu,
    progress: progressData.progress,
    type: 'large',
  };
  return <NaviInpageMenu {...props} />;
};
