import React, { useEffect, useRef, useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { CSSTransition } from 'react-transition-group';

import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormFootnote } from '@/componentsDirect/Parts/Form/Footnote';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { FormLayoutUnitDivider } from '@/componentsDirect/Parts/Form/Layout/UnitDivider';

type TProps = {
  className?: string;
  label: string;
  name: string;
  unit?: string;
  type?: 'text' | 'email' | 'password' | 'submit' | 'url'; // numberはクリアアイコンとnumberの操作UIが衝突するので使用しないようにする
  placeholder?: string;
  placeholder2?: string;
  fieldWidthClass?: string; // tailwindの w-{number} を指定
};

/**
 * 期間や年齢範囲など二つの入力項目から値を設定するファームセット
 *
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=1071%3A54097
 */
export const FormCombinationsFromToTextfield = ({
  className,
  label,
  name,
  unit,
  type = 'text',
  placeholder,
  placeholder2,
  fieldWidthClass,
}: TProps): React.ReactElement => {
  const errorRef = useRef(null);

  const [thisErrors, setThisErrors] = useState<Array<string>>([]);
  const [debugError, setDebugError] = useState(false);

  const methods = useFormContext();
  const register = methods.register(name);
  const { watch, control } = methods;
  const { dirtyFields, errors } = useFormState({
    control,
  });

  /**
   * dirtyFields の値をみてユーザー操作がない場合のみ本来の入力値に変更があれば分割して指定
   */
  useEffect(() => {
    // もとの値が配列のデータでかつfrom to のフォームに対しtえユーザーがない場合は値を設定
    if (Array.isArray(watch(name)) && !dirtyFields[`${name}-from`] && !dirtyFields[`${name}-to`]) {
      methods.setValue(`${name}-from`, watch(name)[0]);
      methods.setValue(`${name}-to`, watch(name)[1]);
    }
  }, [watch(name)]);

  const setOriginalValue = () => {
    if (dirtyFields[`${name}-from`] || dirtyFields[`${name}-to`]) {
      // 両方ともvalidなら設定
      if (errors[`${name}-from`] || errors[`${name}-to`]) return;
      methods.setValue(name, [watch(`${name}-from`), watch(`${name}-to`)], {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  /**
   * from to の値が変更になるたびに本来の入力値を更新
   */
  useEffect(() => {
    setOriginalValue();
  }, [watch(`${name}-from`), watch(`${name}-to`)]);

  /**
   * エラー処理に反応
   */
  useEffect(() => {
    if (errors[`${name}-from`] || errors[`${name}-to`] || errors[name]) {
      let tmpErrors = [];
      if (errors[`${name}-from`]) {
        tmpErrors.push(errors[`${name}-from`].message);
      }
      if (errors[`${name}-to`]) {
        tmpErrors.push(errors[`${name}-to`].message);
      }
      if (errors[`${name}`]) {
        tmpErrors.push(errors[`${name}`].message);
      }
      // 重複を削除
      tmpErrors = tmpErrors.filter(function (x, i, self) {
        return self.indexOf(x) === i;
      });

      if (errors[name]) {
        setDebugError(true);
      }

      // stateに設定
      setThisErrors(tmpErrors);
    } else {
      setDebugError(false);
      setThisErrors([]);
      setOriginalValue();
    }
  }, [errors[`${name}-from`], errors[`${name}-to`], errors[name]]);

  return (
    <FormLayoutFieldset className={className}>
      <FormLabel label={label} type="small" />
      <div className="flex mbx-utils-stack-h--8">
        <FormContainerTextfield
          className={`${fieldWidthClass} mkt_non_mask_items`}
          name={`${name}-from`}
          type={type}
          placeholder={placeholder}
          debugError={debugError}
          disabledError={true}
        />
        <FormLayoutUnitDivider>〜</FormLayoutUnitDivider>
        <FormContainerTextfield
          className={`${fieldWidthClass} mkt_non_mask_items`}
          name={`${name}-to`}
          type={type}
          placeholder={placeholder2 ? placeholder2 : placeholder}
          debugError={debugError}
          disabledError={true}
        />
        {unit && <FormLayoutUnitDivider>{unit}</FormLayoutUnitDivider>}
      </div>
      <input type="hidden" {...register} />
      <CSSTransition
        in={!!thisErrors.length}
        timeout={550}
        nodeRef={errorRef}
        unmountOnExit
        classNames="mbx-anime-error"
      >
        <div className="flex flex-row" ref={errorRef}>
          <FormFootnote className="text-error-700">{thisErrors.join(',')}</FormFootnote>
        </div>
      </CSSTransition>
    </FormLayoutFieldset>
  );
};
