import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';

import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { DirectSearchFormReset } from '@/componentsDirect/Search/Reset';
import { DirectSearchFormBasicInfo } from '@/componentsDirect/Search/SectionBasicInfo';
import { DirectSearchFormSectionDesired } from '@/componentsDirect/Search/SectionDesired';
import {
    DirectSearchFormSectionEmploymentStatus
} from '@/componentsDirect/Search/SectionEmploymentStatus';
import { DirectSearchFormSectionFreeWord } from '@/componentsDirect/Search/SectionFreeWord';
import { DirectSearchFormSectionOffer } from '@/componentsDirect/Search/SectionOffer';
import {
    DirectSearchFormSectionQualifications
} from '@/componentsDirect/Search/SectionQualifications';
import { DirectSearchFormSectionUpdates } from '@/componentsDirect/Search/SectionUpdates';
import {
    DirectSearchFormSectionWorkExperience
} from '@/componentsDirect/Search/SectionWorkExperience';
import { useDirectSearchCondition } from '@/hooksAdmin/useDirectSearchCondition';
import {
    useSearchConditionValidation, validationSchemaForPost
} from '@/hooksDirect/useSearchConditionValidation';
import { DirectSearchConditionModel, DirectSearchConditionResponse } from '@/utils/api-client';
import { yupResolver } from '@hookform/resolvers/yup';

/**
 * Admin Direct 企業向け求職者検索 入力フォーム
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=4694%3A63903
 */
export const AdminDirectSearchForm = (): React.ReactElement => {
  const location = useLocation();
  const history = useHistory();
  const { searchCondition, saveSearchCondition } = useDirectSearchCondition();
  const { initValues, isChanged, searchConditionChangeDetection } = useSearchConditionValidation();

  const methods = useForm<DirectSearchConditionModel>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchemaForPost),
    defaultValues: initValues,
  });
  const { handleSubmit, reset, watch, control } = methods;
  const { isValid } = useFormState({ control });

  /**
   * 再編集の際は保持しているデータをフォームに反映
   * ただし mc_experienced_job は useFieldArray で反映するので
   * コンポーネント側で対応
   */
  useEffect(() => {
    if (/reedit$/.test(location.search)) {
      if (searchCondition) {
        setDefaultValues(searchCondition);
      }
    }
  }, []);

  /**
   * 設定した条件をreduxに保持して結果ページに移動
   */
  const onSubmit: SubmitHandler<DirectSearchConditionModel> = (data) => {
    saveSearchCondition(data);
    history.push('/admin/direct/search/result');
  };

  useEffect(() => {
    const subscription = watch((value) => {
      // データを比較して変更検知
      searchConditionChangeDetection(value);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  /**
   * データをRHFに値をsetValueする
   */
  const setDefaultValues = (res: DirectSearchConditionModel) => {
    (Object.keys(res) as (keyof DirectSearchConditionModel)[]).forEach((v) => {
      if (res?.[v] !== undefined || res?.[v] !== null) {
        if (v !== 'mc_experienced_job') {
          methods.setValue(v, res?.[v]);
        }
      }
    });
    if (watch('offer_status')?.includes(6 || 7)) {
      const status = watch('offer_status')?.filter((s) => {
        if (s !== 6 && s !== 7) {
          return true;
        }
      });
      methods.setValue('offer_status', status);
    }
    if (watch('offer_status')?.includes(5)) {
      const status = watch('offer_status')?.filter((s) => {
        if (s !== 5) {
          return true;
        }
      });
      methods.setValue('offer_status', status);
    }
  };

  return (
    <AdminPage
      header={<HeaderTitle title="求職者検索" subTitle="企業への共有向け" />}
      sticky={<StickyButton isValid={isChanged && isValid} />}
    >
      <FormProvider {...methods}>
        <div className="flex justify-end py-25">
          {isChanged && <DirectSearchFormReset reset={reset} />}
          <BaseButton size="s" theme="link" href="/admin/direct/conditions">
            保存した条件を見る
          </BaseButton>
        </div>
        <section>
          <form
            className="pb-80 border-t border-gray-300"
            onSubmit={handleSubmit(onSubmit)}
            id="FORM"
          >
            <DirectSearchFormSectionFreeWord />
            <DirectSearchFormSectionOffer />
            <DirectSearchFormBasicInfo
              searchConditionResult={searchCondition as DirectSearchConditionResponse}
            />
            <DirectSearchFormSectionEmploymentStatus />
            <DirectSearchFormSectionUpdates />
            <DirectSearchFormSectionWorkExperience />
            <DirectSearchFormSectionQualifications />
            <DirectSearchFormSectionDesired />
          </form>
        </section>
      </FormProvider>
    </AdminPage>
  );
};

type TPropsButton = {
  isValid: boolean;
};

const StickyButton = ({ isValid }: TPropsButton): React.ReactElement => {
  return (
    <div className="sticky bottom-10 z-30">
      <BaseButton
        size="m"
        theme="primary"
        type="submit"
        iconName="Search"
        className="absolute right-20 bottom-12"
        disabled={!isValid}
        form="FORM"
      >
        この条件で検索する
      </BaseButton>
    </div>
  );
};
