import '@/styles/common/Admin/UserDetail/message_form.scss';

import React from 'react';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';

type Props = {
  onSend(): void;
  onChange(comment: string): void;
  comment: string;
};

export function MessageForm(props: Props): React.ReactElement {
  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    props.onChange(e.target.value);
  };

  const onClick = () => {
    if (props.comment.length === 0) return;
    props.onSend();
  };

  return (
    <section className="mbx-message_form space-x-8">
      <div className="mbx-message_form-textarea">
        <textarea
          placeholder="メッセージを入力"
          className="text-12_20 h-48 resize-none mkt_mask_items"
          onChange={onChange}
          value={props.comment}
        />
      </div>
      <div className="mbx-message_form-submit">
        <BaseButton
          theme="primary"
          size="s"
          iconName="PaperPlane"
          onClick={onClick}
          disabled={props.comment.length === 0}
        >
          送信
        </BaseButton>
      </div>
    </section>
  );
}
