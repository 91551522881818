import React, { useState } from 'react';

import CirclePlaceholder from '@/assets/images/profile/circle-placeholder.svg';
import { PositionModalSection } from '@/componentsAdmin/Company/Detail/PositionModal';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { OfferPositionData } from '@/utils/api-client';

type Props = {
  position: OfferPositionData;
};

export const CompanyPositionList = ({ position }: Props): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const [positionModal, setPositionModal] = useState<boolean>(false);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <section className="flex items-center py-20 px-16 border-b border-gray-300">
      <p className="w-200 text-12_18 truncate">{position.name}</p>
      <p className="w-424 ml-16 mr-2 text-12_18 truncate">{position.body}</p>
      <p className="w-36 ml-16 text-12_18">{position.active ? "表示" : "非表示"}</p>
      <div className="w-48 ml-16">
        <BaseButton
          theme="secondary"
          size="ss"
          className="w-full whitespace-nowrap"
          onClick={() => setPositionModal(true)}
        >
          詳細
        </BaseButton>
      </div>
      <ModalDefault
        isOpen={positionModal}
        onClose={() => setPositionModal(false)}
        className="rounded-modalContainer"
      >
        <PositionModalSection position={position} setPositionModal={setPositionModal} />
      </ModalDefault>
    </section>
  );
};
