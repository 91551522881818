import React from 'react';
import '@/styles/common/OtherComponents/box_empty.scss';

type Props = {
  message: string;
};

export function BoxEmpty(props: Props): React.ReactElement {
  return (
    <section className="mbx-box_empty">
      {props.message}
    </section>
  );
}
