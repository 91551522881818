import React, { useMemo } from 'react';
import { ReactionsResponseAllOfReactions } from '@/utils/api-client';
import cn from 'classnames';

type Props = {
  className?: string;
  reactions: ReactionsResponseAllOfReactions[];
};

export const Graph = (props: Props): React.ReactElement => {
  const Ratio = useMemo(() => {
    const total = props.reactions.length;
    const activeNum = props.reactions.filter((r) => {
      return r.reaction?.status === 3;
    }).length;
    const onHoldNum = props.reactions.filter((r) => {
      return r.reaction?.status === 2;
    }).length;
    const activeCasualNum = props.reactions.filter((r) => {
      return r.reaction?.status === 8;
    }).length;
    const inactiveNum = props.reactions.filter((r) => {
      return r.reaction?.status === 4;
    }).length;

    // 割り切れない数のとき右端まで埋まらないから小数第二位まで持つ
    const active = Math.round((activeNum / total) * 10000) / 100;
    const activeCasual = Math.round((activeCasualNum / total) * 10000) / 100;
    const onHold = Math.round((onHoldNum / total) * 10000) / 100;
    const inactive = Math.round((inactiveNum / total) * 10000) / 100;

    return { active, onHold, inactive, activeCasual };
  }, [props.reactions]);

  return (
    <section className={props.className}>
      <div className="flex text-12_16 font-bold">
        <div className="flex items-center">
          <p className="w-16 h-16 bg-red-300"></p>
          <p className="ml-4 text-red-700">一次面接オファーしたい</p>
        </div>
        <div className="flex items-center ml-16">
          <p className="w-16 h-16 bg-blue-300"></p>
          <p className="ml-4 text-blue-700">カジュアル面談オファーしたい</p>
        </div>
        <div className="flex items-center ml-16">
          <p className="w-16 h-16 bg-attention-300"></p>
          <p className="ml-4 text-attention-700">マイナビワークスに相談したい</p>
        </div>
        <div className="flex items-center ml-16">
          <p className="w-16 h-16 bg-gray-300"></p>
          <p className="ml-4 text-gray-600">見送りたい</p>
        </div>
      </div>
      <section>
        {props.reactions.length !== 0 && (
          <div className="flex mt-16 mbx-typography--subhead_1 rounded-4 overflow-hidden">
            <div
              className={cn('flex', 'items-center', 'h-48', 'bg-red-300', {
                'pl-16': Ratio.active,
              })}
              style={{ width: `${Ratio.active}%` }}
            >
              <span className="text-red-700">{Math.round(Ratio.active)}%</span>
            </div>
            <div
              className={cn('flex', 'items-center', 'h-48', 'bg-blue-300', {
                'pl-16': Ratio.activeCasual,
              })}
              style={{ width: `${Ratio.activeCasual}%` }}
            >
              <span className="text-blue-700">{Math.round(Ratio.activeCasual)}%</span>
            </div>
            <div
              className={cn('flex', 'items-center', 'h-48', 'bg-attention-300', {
                'pl-16': Ratio.onHold,
              })}
              style={{ width: `${Ratio.onHold}%` }}
            >
              <span className="text-attention-700">{Math.round(Ratio.onHold)}%</span>
            </div>
            <div
              className={cn('flex', 'items-center', 'h-48', 'bg-gray-300', {
                'pl-16': Ratio.inactive,
              })}
              style={{ width: `${Ratio.inactive}%` }}
            >
              <span className="text-gray-600">{Math.round(Ratio.inactive)}%</span>
            </div>
          </div>
        )}
      </section>
    </section>
  );
};
