import React from 'react';

import { DescriptionWrapper } from '@/componentsAdmin/Company/Detail/DescriptionWrapper';
import { OfferPositionData } from '@/utils/api-client';

type Props = {
  position: OfferPositionData;
  setPositionModal: (value: React.SetStateAction<boolean>) => void;
};

export const PositionModalSection = (props: Props): React.ReactElement => {
  return (
    <section className="w-784 p-80">
      <DescriptionWrapper
        title="ポジション名"
        subTitle="（職種名）"
        titleWidth={170}
        className="border-t border-gray-300 text-left"
      >
        <p className="mbx-typography--body_2 text-left">{props.position.name}</p>
      </DescriptionWrapper>
      <DescriptionWrapper
        title="ポジション詳細"
        subTitle="（業務内容詳細）"
        titleWidth={170}
        className="text-left"
      >
        <p className="mbx-typography--body_2 text-left whitespace-pre-wrap">{props.position.body}</p>
      </DescriptionWrapper>
      <DescriptionWrapper
        title="表示状態"
        titleWidth={170}
        className="text-left"
      >
        <p className="mbx-typography--body_2 text-left">{props.position.active ? "表示" : "非表示"}</p>
      </DescriptionWrapper>
    </section>
  );
};
