import React, { VFC } from 'react';

import ImgLogo from '@/assets/images/common/logo.svg';

type TProps = {
  className?: string;
  page?: number;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=92%3A2265
 */
export const PreviewFooter: VFC<TProps> = ({ page, className }) => {
  return (
    <section className={className}>
      <div className="w-full h-38">
        <div className="flex items-center">
          {page ? <p className="text-10_18">{page}</p> : null}
          <div className="folio-img w-80 ml-auto">
            <img src={ImgLogo} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};
