import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { iconNameList } from '@/assets/fonts/manifest';
import { CropperModal } from '@/components/common/Modal/Cropper';
import { ImageFade } from '@/components/common/Utils/ImageFade';
import { useFileLimit } from '@/hooks/useFIleLimit';
import { IMAGE_SIZE } from '@/definition/IMAGE_SIZE';

export type TProps = {
  className?: string;
  name: string;
  iconName: typeof iconNameList[number];
  readOnly?: boolean; // アップ機能なしで表示のみ
  animeClass?: string;
  src?: string;
};

/**
 * ユーザーアイコンの表示
 * RFHのform内でで利用する想定の設計
 *
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65938
 */
export const ProfileImageCircle = ({
  className,
  name,
  iconName,
  readOnly,
  animeClass,
  src,
}: TProps): React.ReactElement => {
  const { checkFileSize } = useFileLimit();

  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  const methods = useFormContext();
  const register = methods.register(name);
  const { watch } = methods;

  useEffect(() => {
    if (src) methods.setValue(name, src);
  }, []);

  /**
   * 画像が選択されたらData URIに変換して画像クロップモーダルを表示
   */
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    const imageFile = e.target.files[0];

    if (!checkFileSize(imageFile.size, 5)) return;
    const reader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.addEventListener(
      'load',
      function () {
        setImageSrc(reader.result as string);
        setShowModal(true);
      },
      false
    );
  };

  const onClick = () => {
    methods.setValue(name, null);
  }

  const onClose = (): void => {
    setShowModal(false);
  };

  const onSave = (dataUri: string): void => {
    setShowModal(false);
    methods.setValue(name, dataUri);
  };

  /**
   * 同じファイルを再度読み込もうとするとchangeイベントが発火しないので
   * input file の値をクリアする
   *
   */
  const clearFilePath: React.MouseEventHandler<HTMLInputElement> = (event) => {
    const { target } = event;
    if (!(target instanceof HTMLInputElement)) return;
    target.value = '';
  };
  return (
    <>
      <div className={cn('relative w-128 h-128 m-auto mbx-utils-hover sm:w-80 sm:h-80', className)}>
        <CropperModal
          isOpen={showModal}
          onClose={onClose}
          width={IMAGE_SIZE.pf_profile.width}
          height={IMAGE_SIZE.pf_profile.height}
          src={imageSrc}
          onSave={onSave}
        />
        <label htmlFor="IMAGE_UPLOAD_FILE">
          <input
            id="IMAGE_UPLOAD_FILE"
            className="hidden"
            type="file"
            accept=".jpg, .jpeg, .png, .gif"
            onChange={onChange}
            onClick={clearFilePath}
            disabled={readOnly}
          ></input>
          <input type="hidden" {...register} />
          {!readOnly && (
            <div className="sm:-right-8 sm:-bottom-8 absolute w-40 h-40 text-24_24 flex justify-center items-center rounded-full bg-gray-700 text-white bottom-0 right-0 z-10 cursor-pointer hover_gray-500">
              <i className="mbx-icon mbx-icon-Camera"></i>
            </div>
          )}
          {watch(name) ? (
            <ImageFade
              src={watch(name)}
              animeClass={animeClass}
              className={cn('bg-center bg-cover bg-no-repeat rounded-full w-full h-full',
                'mkt_mask_items',
                { 'cursor-pointer': !readOnly }
              )}
            />
          ) : (
            <div
              className={cn(
                `bg-gray-400 text-gray-200 w-full h-full rounded-full flex justify-center items-center text-70_70 sm:text-44_44`,
                {
                  'cursor-pointer': !readOnly,
                }
              )}
            >
              <i className={`mbx-icon mbx-icon-${iconName}`}></i>
            </div>
          )}
        </label>
      </div>
      <div className="pt-16 text-center">
        <p className="text-caption_2 text-gray-700">横232px × 縦232px推奨、最大5MB</p>
      </div>
      {watch(name) &&
        <div className="pt-8 text-center">
          <div className="base-button isTextLink" onClick={onClick}>
            <p className="button size-s theme-link flex items-center">
              <i className="mbx-icon mbx-icon-Trash mr-2"></i>
              <span className="font-bold label">画像を削除</span>
            </p>
          </div>
        </div>
      }
    </>
  );
};
