type TReturn = {
  checkCareerHistory: () => Promise<boolean>;
};

/**
 * 履歴書から職務経歴書にアンカーリンクで遷移させたいのだが画面描画が終わらないと正しい位置に移動しないので
 * 無理やりだがAPI通信が終わったらグローバルな値にフラグを代入、リンク側ではそれを監視して描画完了を判断
 *
 * 監視用のhooks
 *
 * issue : https://github.com/prtytokyo/matchbox-2021-web-frontend/issues/1085
 *
 * だいぶ強引なのでもっといい方法あったら変えて欲しい
 */
export const useCompletedAPI = (): TReturn => {
  const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  /**
   * 求職者のCareerHistoryの取得監視
   *
   */
  const checkCareerHistory = async () => {
    if (window.completedCareerHistory) {
      window.completedCareerHistory = false;
      return true;
    }
    await timeout(100);
    await checkCareerHistory();
    return false;
  };
  return { checkCareerHistory };
};
