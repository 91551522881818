import { AxiosError, AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Draggable, OnDropCallback } from 'react-smooth-dnd';

import { AdminListItem } from '@/components/common/Admin/FavoriteConditions/AdminListItem';
import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { SendSharedCompaniesModal } from '@/componentsDirect/Conditions/SendSharedCompaniesModal';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { BoxEmpty } from '@/componentsDirect/Parts/OtherComponents/BoxEmpty';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { TFormOption } from '@/definition/FORM_OPTIONS';
import { useDirectSearchCondition } from '@/hooksAdmin/useDirectSearchCondition';
import { notificationError, notificationSuccess } from '@/reduxAdmin/modules/utils';
import {
    ADMINApi, AdminDirectSearchConditionResponse, DirectSearchConditionModel, ResponseError
} from '@/utils/api-client';

// import { testConditionsData } from '@/debug/adminDirectConditions';

export type AdminDirectSearchConditionResponseEX = AdminDirectSearchConditionResponse & {
  checked?: boolean;
};

export type TConditionsFormValues = {
  conditions: AdminDirectSearchConditionResponseEX[];
};

export const AdminDirectConditions = (): React.ReactElement => {
  const AdminAPI = new ADMINApi();
  const dispatch = useDispatch();
  const history = useHistory();
  const [conditions, setConditions] = useState<AdminDirectSearchConditionResponseEX[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [enableModalOpen, setEnableModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [checkedConditionTitles, setCheckedConditionTitles] = useState<Array<string>>([]);
  const [searchedCompanies, setSearchedCompanies] = useState<Array<TFormOption>>([]);
  const { saveSearchCondition } = useDirectSearchCondition();

  const methods = useForm<TConditionsFormValues>({
    defaultValues: {
      conditions,
    },
  });

  const { fields, move, remove } = useFieldArray<TConditionsFormValues, 'conditions', 'uid'>({
    control: methods.control,
    name: 'conditions',
    keyName: 'uid',
  });

  const getConditionByIndex = (index: number) => {
    return methods.getValues(`conditions`)[index];
  };

  const updateCheckedConditions = () => {
    const checkedConditions = getCheckedItems();
    const titles = checkedConditions.map((item) => {
      return item.search_condition_name;
    });
    setCheckedConditionTitles(titles);

    if (checkedConditions.length) {
      setEnableModalOpen(true);
    } else {
      setEnableModalOpen(false);
    }
  };

  const fetchSearchConditions = () => {
    AdminAPI.getAdminDirectSearchConditions()
      .then((res: AxiosResponse) => {
        const { conditions } = res.data;
        setConditions(conditions as AdminDirectSearchConditionResponseEX[]);

        // ↓ 一旦テストデータで確認できるように
        // setTestData();

        setLoaded(true);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) {
          dispatch(notificationError(error.response.data.error_message));
        }
      });
  };

  useEffect(() => {
    fetchSearchConditions();
  }, []);

  useEffect(() => {
    methods.setValue('conditions', conditions);
  }, [conditions]);

  const onDrop: OnDropCallback = ({ removedIndex, addedIndex }) => {
    if (removedIndex !== null && addedIndex !== null) {
      move(removedIndex, addedIndex);

      const conditions: AdminDirectSearchConditionResponse[] = methods.getValues(
        `conditions`
      ) as AdminDirectSearchConditionResponse[];
      const ids = conditions.map((item) => {
        return item.id;
      });

      AdminAPI.patchAdminDirectSearchConditions({ ids })
        .then(() => {
          dispatch(notificationSuccess('保存しました。'));
        })
        .catch((error: AxiosError<ResponseError>) => {
          if (error.response) {
            dispatch(notificationError(error.response.data.error_message));
          }
        });
    }
  };

  /**
   * 検索条件削除
   */
  const onRemove = (index: number, id: number) => {
    remove(index);
    updateCheckedConditions();

    AdminAPI.deleteAdminDirectSearchConditionId(id)
      .then(() => {
        dispatch(notificationSuccess('保存しました。'));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) {
          dispatch(notificationError(error.response.data.error_message));
        }
      });
  };

  const onClickEditButton = (id: number) => {
    history.push(`/admin/direct/conditions/${id}`);
  };

  const onClickSearchButton = (index: number, search_condition_id: number) => {
    const condition = cloneDeep(getConditionByIndex(index));
    saveSearchCondition(condition as DirectSearchConditionModel);
    history.push(`/admin/direct/search/result?search_condition_id=${search_condition_id}`);
  };

  const onChangeDirectMail = (index: number) => {
    const condition = getConditionByIndex(index);

    if (condition) {
      AdminAPI.patchAdminDirectMailId(condition.id, {
        direct_mail: condition.direct_mail as boolean,
      })
        .then(() => {
          dispatch(notificationSuccess('保存しました。'));
        })
        .catch((error: AxiosError<ResponseError>) => {
          if (error.response) {
            dispatch(notificationError(error.response.data.error_message));
          } else {
            dispatch(notificationError('保存に失敗しました'));
          }
        });
    }
  };

  const getCheckedItems = () => {
    const conditions = methods.getValues('conditions');
    const checkedConditions = conditions.filter((item) => {
      return item.checked === true;
    });

    return checkedConditions;
  };

  const onChangeChecked = (index: number, value: boolean) => {
    const condition = getConditionByIndex(index);

    if (condition) {
      condition.checked = value;
    }

    const checkedConditions = getCheckedItems();

    if (checkedConditions.length) {
      setEnableModalOpen(true);
    } else {
      setEnableModalOpen(false);
    }
  };

  const onClickModalOpenButton = () => {
    updateCheckedConditions();

    if (enableModalOpen) {
      setModalOpen(true);
    }
  };

  const onClickModalSendButton = (
    selectedCompanies: { value: number | string; children: number | string }[]
  ) => {
    const checkedConditions = getCheckedItems();

    if (checkedConditions.length > 0 && selectedCompanies.length > 0) {
      const search_condition_id = checkedConditions.map((item) => {
        return item.id;
      });
      const company_ids = selectedCompanies.map((item) => {
        return Number(item.value);
      });

      AdminAPI.postAdminSuggest({
        search_condition_id,
        company_ids,
      })
        .then(() => {
          dispatch(notificationSuccess('送信しました。'));
        })
        .catch((error: AxiosError<ResponseError>) => {
          if (error.response) {
            dispatch(notificationError(error.response.data.error_message));
          } else {
            dispatch(notificationError('送信に失敗しました'));
          }
        })
        .finally(() => {
          setModalOpen(false);
          setEnableModalOpen(false);
          fetchSearchConditions();
        });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDeleteCompanyTag = (id: number, company: any) => {
    AdminAPI.deleteAdminSuggestId(id, company.id)
      .then(() => {
        dispatch(notificationSuccess('削除しました。'));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) {
          dispatch(notificationError(error.response.data.error_message));
        } else {
          dispatch(notificationError('削除に失敗しました'));
        }
      });
  };

  const onChangeCompanyTag = () => {
    setConditions([...methods.getValues('conditions')]);
  };

  const onInputModalKeyword = (keyword: string) => {
    searchCompanies(keyword);
  };

  const searchCompanies = (keyword: string) => {
    if (keyword.length <= 0) {
      return;
    }

    AdminAPI.postAdminDirectCompanies({
      limit: 5,
      offset: 0,
      conditions: {
        keyword: [keyword],
      },
    })
      .then((res) => {
        const { companies } = res.data;

        if (companies) {
          const searchedCompanies = companies.map((item) => {
            return {
              value: item.id + '',
              children: item.name,
            };
          });
          setSearchedCompanies(searchedCompanies as Array<TFormOption>);
        }
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) {
          dispatch(notificationError(error.response.data.error_message));
        }
      });
  };

  // テストデータ
  // const setTestData = () => {
  //   const conditions = testConditionsData();
  //   setConditions(conditions);
  // }

  return (
    <AdminPage header={<HeaderTitle title="保存した検索条件" subTitle="企業への共有向け" />}>
      <>
        <div className="mt-48 mb-80">
          <div className="flex items-center justify-between">
            <div className="text-14_27">
              保存した検索条件を企業に送信します。共有したい検索条件にチェックを入れ、
              <br />
              企業に送信ボタンを押してください
            </div>
            <BaseButton
              theme="primary"
              size="m"
              disabled={!enableModalOpen}
              onClick={() => {
                onClickModalOpenButton();
              }}
            >
              チェックした検索条件を企業に送信
            </BaseButton>
          </div>
          <div className="mt-24">
            {loaded && fields.length <= 0 && <BoxEmpty message="検索条件がありません。" />}
            {loaded && fields.length > 0 && (
              <FormProvider {...methods}>
                <Container
                  onDrop={onDrop}
                  dragHandleSelector=".dragHandle"
                  lockAxis="y"
                  render={(ref) => {
                    return (
                      <div ref={ref} className="">
                        {fields.map((item, index) => (
                          <Draggable key={item.uid} className="">
                            <AdminListItem
                              direct
                              key={item.uid}
                              index={index}
                              item={item}
                              onClickRemoveButton={() => onRemove(index, item.id)}
                              onClickSearchButton={() => {
                                onClickSearchButton(index, item.id);
                              }}
                              onClickEditButton={() => onClickEditButton(item.id)}
                              onChangeDirectMail={() => onChangeDirectMail(index)}
                              onChangeChecked={(value) => onChangeChecked(index, value)}
                              onDeleteCompanyTag={(company) => {
                                onDeleteCompanyTag(item.id, company);
                              }}
                              onChangeCompanyTag={onChangeCompanyTag}
                            />
                          </Draggable>
                        ))}
                      </div>
                    );
                  }}
                />
              </FormProvider>
            )}
          </div>
        </div>
        <SendSharedCompaniesModal
          checkedConditionTitles={checkedConditionTitles}
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          onClickSendButton={onClickModalSendButton}
          searchedCompanies={searchedCompanies}
          onInputKeyword={onInputModalKeyword}
        />
      </>
    </AdminPage>
  );
};
