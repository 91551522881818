import React, { useState, useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Blank } from '@/stories/components/Portfolio/ImagePage/ImageGeneral.stories';

export const Ed45 = (): React.ReactElement => {
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if (isAuth) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0045");
    if (!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g, "/")} 12:00:00`);
    const now = new Date;
    if (now > deadlineDate) setDeadline(true);
  }, []);

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16">
          <img className={style.img} src="../images/Top/event/event_0045_mv.png" width="978" height="504" alt="" />
        </div>
        <h1 className="mt-56 sm:mt-30 sm:mb-8 text-29_46 sm:text-18_24 font-bold text-center">6/27（火）20:00 -<br />
          <span className="border-b border-black text-21_34 sm:text-15_22">クリエイターズ・シナジーカフェ×<br className="md:hidden lg:hidden" />マッチボックス コラボセミナー</span><br />
          <span className="inline-block mt-16 sm:mt-10 sm:mb-8 text-21_34 sm:text-15_22">Webデザイナー進化論 season1 <br className="md:hidden lg:hidden" />UIスタート編 vol.6</span><br />
          UI設計を円滑に進めるための<br className="md:hidden lg:hidden" />Figmaテクニック</h1>
        {deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">セミナーについて</h3>
          <div className="mt-30 sm:mt-16 mb-30 sm:mb-18 box-line">
            <img src="../images/Top/event/event_0031_image.png" width="980" height="297" alt="" />
          </div>
          <p className="text-16_28 sm:text-14_21 mt-30 sm:mt-16">
            Webデザイナーの方のスキルアップやUIについての研鑽ニーズにお応えすべく、『クリエターズ・シナジーカフェ』とマッチボックスのコラボセミナーを行っています。今回はvol.6として『UI設計を円滑に進めるためのFigmaテクニック』を開催いたします。<br />
            6/22開催予定の『UIデザインFigma実践（Live）』の後編になっていますので、是非合わせて参加ください！詳細は<a className="textLink" href='0044'>こちら</a>から。<br />
            前編の“Figmaを使ってUIデザインを作る”から、Figmaを実務でどのように作っているか実際に実績をお見せいただきながら解説いただきます。また、コラボセミナーのまとめとして“良いUIデザイナーになるためには？”をキーワードにセッションをしていただきます。
          </p>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>以下に該当する方はオススメです。</h2>
          <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>キャリアアップに悩んでいるWebデザイナー</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>UIデザインに関心がある、知りたいと思っている</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>次のキャリアアクションを決めかねていてWebデザイナーとして何か武器を持ちたい</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>UI構築についての具体的に知りたい方</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>UIデザイナーとして悩みのある方</span>
              </li>
              <li className="flex">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>セミナーを踏まえて転職・書類作成の相談をしたい方</span>
              </li>
            </ul>
          </div>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>講師プロフィール</h2>
          <div className='event-instructor box-line mb-30 sm:p-16'>
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0031_prof.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                タグチ マリコ
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-18 sm:mb-12">
                日本ウェブデザイン株式会社 CXO兼UXデザイナー
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                2001年からアートやプログラミングの虜となり、学生時代からフリーランス活動をスタート。20代から様々な制作会社での勤務や起業を経験し、大手企業から中小企業まで幅広いクライアントと共に、Webサイトやアプリケーション開発に携わる。プレイヤーからマネジメントまで、多岐にわたる領域で実績を積む。
                活動コンセプトは「ユーザ以外も良い体験に」。すべての関係者が幸福度高く、ポテンシャルを発揮できるような構造をクリエイティブすることに専念している。
              </dd>
            </dl>
          </div>
          <div className='event-instructor box-line sm:p-16'>
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0034_prof.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                せきゆおう
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-18 sm:mb-12">
                フリーランス Webクリエイター
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                2003年、短大在学中にプレゼンで使ったAdobeソフトの面白さに魅了され、建築業界からデザイン業界へ進路を変更。在学中に当時はまっていたゲームのギルドサイト制作のため、独学でデザインとコーディングを学ぶ。卒業後はグラフィックデザイナーを経てWebデザイナーに。複数の制作会社で実績を積む。小規模な制作会社での実務が長く、受注から納品まで1人で担当することが多かった。2020年1月に個人事業主となり、同年4月にせきゆおうアカウントを作る。2023年4月現在フォロワー数は約1.9万人。2020年にシナカフェを共同で設立。2022年にはバンタンテックフォードアカデミーでHTML/CSSの講師を半年間勤めた。本職では、Web制作全般に加え、紙媒体のデザインも手掛けている。
              </dd>
            </dl>
          </div>
        </section>
        <section className="mt-80 sm:mt-55">
          <div className='box-line p-30 sm:p-16'>
            <h3 className="text-18_24 sm:text-14_21 font-bold">クリエイターズ・シナジーカフェとは？</h3>
            <div className="md:flex lg:flex items-center">
              <img className="w-1/2 sm:w-full h-full md:mr-16 lg:mr-16" src="../images/Top/event/event_0034_logo-synercafe.png" alt="" />
              <p className="mt-15 sm:mt-8 text-16_28 sm:text-14_21">
                Web業界に携わる人を対象にしているオンラインコミュニティです。コミュニティ参加者限定のウェビナーやイベントが開催されていたり、メンバー同士が互いに意見公開できる場所が提供されていたりします。<br />
                <a className="textLink" href='https://creators-synergy-cafe.com/' target={'_blank'} rel="noreferrer">https://creators-synergy-cafe.com/<i className="mbx-icon mbx-icon-External_link"></i></a>
              </p>
            </div>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap">
                  <span className="sm:block">20：00</span><span className="sm:block">～</span><span className="sm:block">21：20</span>
                </th>
                <td>
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>自己紹介</span></li>
                    <li className="flex"><span>・</span><span>Figmaテクニック例</span></li>
                    <li className="flex"><span>・</span><span>良いUIデザイナーを目指すためには・・・（セッション）</span></li>
                    <li className="flex"><span>・</span><span>質疑応答</span></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2023/6/27（火）20:00～<br />
                  [入室開始] 19:50～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.ワークショップ申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink" href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.ワークショップ当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className='mt-80 sm:mt-55'>
          <h3 className="text-18_24 sm:text-14_21 font-bold">今後のセミナーについて</h3>
          <p className="text-16_28 sm:text-14_21 mt-30 mb-40 sm:mb-18">
            以下のスケジュールで3カ月間開催します。是非ご参加ください！</p>
          <table className="index schedule text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap">
                  日程
                </th>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap">
                  内容
                </th>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap">
                  セミナースピーカー
                </th>
              </tr>
              <tr>
                <th colSpan={3} className="bg-gray-50 font-normal">4月テーマ : UIの全体像</th>
              </tr>
              <tr>
                <td>4/18</td>
                <td>1. UIデザイナーへのロードマップ</td>
                <td>タグチマリコさん</td>
              </tr>
              <tr>
                <td>4/26</td>
                <td>2. 実例から見るWebデザイン設計で意識すべきUIの基本とは？</td>
                <td>タグチマリコさん<br />
                  せきゆおうさん</td>
              </tr>
              <tr>
                <th colSpan={3} className="bg-gray-50 font-normal">5月テーマ : ユーザーと向き合う</th>
              </tr>
              <tr>
                <td>5/18</td>
                <td>3. ユーザーと真に向き合いUI設計するためのキーポイント</td>
                <td>タグチマリコさん</td>
              </tr>
              <tr>
                <td>5/24</td>
                <td>4. ユーザーニーズの探求方法とデザイナーに必要な思考とは</td>
                <td>タグチマリコさん<br />
                  せきゆおうさん</td>
              </tr>
              <tr>
                <th colSpan={3} className="bg-gray-50 font-normal">6月テーマ : UIデザイナーのあるある 総括</th>
              </tr>
              <tr>
                <td>6/22</td>
                <td>5. UIデザインFigma実践（Live）</td>
                <td>タグチマリコさん</td>
              </tr>
              <tr>
                <td>6/27</td>
                <td>6. UI設計を円滑に進めるためのFigmaテクニック</td>
                <td>タグチマリコさん<br />
                  せきゆおうさん</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">開催について</h3>
          <p className="mt-30 sm:mt-16 text-16_28 sm:text-14_21">
            Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2,000名を超える「0からのWebディレクション講座」を生み出したメンバーでもある一般社団法人ディレクションサポート協会 会長の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。
          </p>
        </section>
        <section className="mt-80 sm:mt-55 p-40 sm:p-24 bg-gray-50 text-left">
          <h3 className="text-18_24 sm:text-14_21 font-bold">注意点</h3>
          <p className="mt-16 text-16_28 sm:text-14_21">
            ZoomURLはセミナー前日・または当日にメールにてお送りします。<br />
            マッチボックス登録時のアドレス、またはセミナー申込み時に入力したアドレスにURLへ届いていない場合は<a className='textLink' href='https://survey.mynavi.jp/cre/?enq=2va0sJC992s%3d' target={'_blank'} rel="noreferrer">こちら<i className="mbx-icon mbx-icon-External_link"></i></a>からお問い合わせください。
          </p>
        </section>
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div>
    </div >
  );
};
