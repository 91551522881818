import cn from 'classnames';
import React, { VFC } from 'react';

import { iconNameList } from '@/assets/fonts/manifest';

type TProps = {
  size?: 'default' | 'large';
  iconName: typeof iconNameList[number];
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=1%3A92
 *
 * 全てのアイコンを表示可能な設計になっていますが利用するのは以下のみ
 *
 * `size: default` + `iconName: Circle-large_Clear`
 * `size: large` + `iconName: Circle-large_Arrow-[left|right]`
 *
 * TODO 表示仕様確認中 https://matchbox-management.slack.com/archives/G01KQUUNX6W/p1624262607166100
 */
export const ButtonShadowIconButton: VFC<TProps> = ({
  size = 'default',
  iconName,
  onClick,
  className,
}) => {
  const buttonClassName = cn('mbx-button-iconButton-shadow', {
    'text-32_32': size === 'default',
    'p-8': size === 'default',
    'text-48_48': size === 'large',
    'p-0': size === 'large',
  });
  return (
    <div className={className}>
      <button className={buttonClassName} type="button" onClick={onClick}>
        <i className={`filter drop-shadow-iconButton mbx-icon mbx-icon-${iconName}`}></i>
        <span className="bg"></span>
      </button>
    </div>
  );
};
