type TOption = {
  value: string;
  children: string;
};

const thisYear = new Date().getFullYear();
export const DATE_YEAR: Array<TOption> = [...Array(60)].map((_, index) => {
  const value = (thisYear - index).toString();
  return {
    value,
    children: value,
  };
});

export const DATE_MONTH: Array<TOption> = Array(12).fill(null).map((_, index) => {
  const value = (index+1).toString();
  return {
    value,
    children: value,
  };
});

export const DATE_DAY: Array<TOption> = [...Array(31)].map((_, index) => {
  const value = (1 + index).toString();
  return {
    value,
    children: value,
  };
});
