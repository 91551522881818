import {
    Portfolio, PortfolioItem, PortfolioPage, PortfolioPagePPageLayoutEnum, Question
} from '@/utils/api-client/index';

export const p_contents: Question[] = [
  { qa_id: 'a_2', qa_category: '案件概要', qa_question: '受賞歴', qa_answer: [] },
  { qa_id: 'b_4', qa_category: '関わり方', qa_question: '作業期間', qa_answer: [] },
  { qa_id: 'b_5', qa_category: '関わり方', qa_question: 'マネジメント人数', qa_answer: [] },
  { qa_id: 'b_v2_1', qa_category: '関わり方', qa_question: '職種', qa_answer: [] },
  { qa_id: 'b_v2_2', qa_category: '関わり方', qa_question: '担当内容', qa_answer: [] },
  { qa_id: 'b_v2_8', qa_category: '関わり方', qa_question: '経験', qa_answer: [] },
  { qa_id: 'b_v2_7', qa_category: '関わり方', qa_question: '使用ツール', qa_answer: [] },
  { qa_id: 'c_1', qa_category: 'ポイント', qa_question: '', qa_answer: [] }, //意図・ポイント
  { qa_id: 'c_2', qa_category: 'ポイント', qa_question: '', qa_answer: [] }, //問題と解決策
  { qa_id: 'c_3', qa_category: 'ポイント', qa_question: '', qa_answer: [] }, //結果
];

const getQuestion = (id: string): Question => {
  const q = p_contents.find((contents) => contents.qa_id == id);
  return q || { qa_id: id, qa_category: '', qa_question: '', qa_answer: [] };
};

const genPortfolioPage = (
  page: PortfolioPage,
  qaId: string,
  answer: string[],
  question?: string
): PortfolioPage => {
  const contents = Array.from(page.p_contents || []);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const q = contents.find((c: any) => c.qa_id === qaId);

  if (!q) {
    //該当する質問がない
    const q: Question = getQuestion(qaId);
    q.qa_answer = answer;
    if (question) q.qa_question = question;
    contents.push(q);

    return {
      ...Object.assign(page),
      p_contents: contents,
    };
  } else {
    //qa_idがすでに定義されている場合
    const newContents = contents.map((c) => {
      if ((c as Question).qa_id === qaId) {
        //qa_idが一致するcontentを上書き
        return {
          ...c,
          qa_answer: answer,
          qa_question: question,
        };
      } else {
        return c;
      }
    });

    return {
      ...Object.assign(page),
      p_contents: newContents,
    };
  }
};

const genPortfolioQuestionPages = (
  pages: Set<PortfolioPage>,
  qaId: string,
  answer: string[],
  question?: string
): PortfolioPage[] => {
  //ページが存在しない場合新規で作る
  if (Array.from(pages).length === 0) {
    const q: Question = getQuestion(qaId);
    q.qa_answer = answer;
    if (question) q.qa_question = question;

    const page = {
      ...Object.assign({
        p_id: 0,
        p_page_layout: PortfolioPagePPageLayoutEnum.Questions,
      }),
      p_contents: [q],
    };
    return [page];
  } else {
    //ページが存在する場合上書きする
    return Array.from(pages).map((p: PortfolioPage, index: number) => {
      if (index === 0) {
        //最初のページを更新する
        return genPortfolioPage(p, qaId, answer, question);
      } else {
        return p;
      }
    });
  }
};

const genPortfolioImagePages = (
  pages: Set<PortfolioPage>,
  p_page_layout: PortfolioPagePPageLayoutEnum,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  p_contents: any,
  p_id: number | undefined
): PortfolioPage[] => {
  const questionPage = Array.from(pages).find(
    (p: PortfolioPage) => p.p_page_layout === PortfolioPagePPageLayoutEnum.Questions
  );
  if (questionPage) {
    let imagePage = Array.from(pages).find(
      (p: PortfolioPage) => p.p_page_layout !== PortfolioPagePPageLayoutEnum.Questions
    );
    //画像ページがない場合、新たに作る
    if (!imagePage) {
      imagePage = {
        p_id: p_id || 0, //画像ページがないのにp_idが送られているケースは、新規ページ保存直後。この場合p_idを使うことで新規ページにさらに上書きする
        p_page_layout,
        p_contents,
      } as PortfolioPage;
      //画像ページがある場合、更新する
    } else {
      imagePage = {
        ...Object.assign(imagePage),
        p_page_layout,
        p_contents,
      } as PortfolioPage;
    }
    return [questionPage, imagePage];
  } else {
    return Array.from(pages);
  }
};

const genPortfolioItems = (
  items: Set<PortfolioItem>,
  iId: number,
  type: 'question' | 'image' | 'mockup' | 'embed',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any
): PortfolioItem[] => {
  return Array.from(items).map((i: PortfolioItem) => {
    let i_pages;
    switch (type) {
      case 'question':
        i_pages = genPortfolioQuestionPages(
          i.i_pages,
          options.qaId,
          options.answer,
          options.question
        );
        break;
      case 'image':
        i_pages = genPortfolioImagePages(
          i.i_pages,
          options.p_page_layout,
          options.p_contents,
          options.p_id
        );
        break;
      default:
        i_pages = genPortfolioQuestionPages(
          i.i_pages,
          options.qaId,
          options.answer,
          options.question
        );
        break;
    }

    if (i.i_id !== iId) {
      return i;
    } else {
      return {
        ...Object.assign(i),
        i_pages,
      };
    }
  });
};

export const updateQuestion = (
  portfolio: Portfolio,
  iId: number,
  qaId: string,
  answer: string[],
  question?: string
): Portfolio | undefined => {
  if (!portfolio.items) return;

  return {
    ...Object.assign(portfolio),
    items: genPortfolioItems(portfolio.items, iId, 'question', { qaId, answer, question }),
  };
};

export const updateImages = (
  portfolio: Portfolio,
  iId: number,
  p_page_layout: PortfolioPagePPageLayoutEnum,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  p_contents: any,
  p_id: number | undefined
): Portfolio | undefined => {
  if (!portfolio.items) return;

  return {
    ...Object.assign(portfolio),
    items: genPortfolioItems(portfolio.items, iId, 'image', { p_page_layout, p_contents, p_id }),
  };
};
