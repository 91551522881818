import cn from 'classnames';
import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

type Menu = {
  title: string;
  url: string;
  badge?: boolean;
};

type Props = {
  menu: Menu[];
  align: 'fit' | 'left' | 'right';
  className?: string;
  itemClassName?: string;
};

/**
 * Tab Navigation
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=354%3A933
 *
 * アイテム要素のサイズを指定したいなどの場合は itemClassName でクラス名を指定してそのクラスに対してサイズを設定してください
 *
 * 通常のTabNavigationを拡張してurlのマッチでactiveを切り替えるコンポーネント
 *
 */
export const TabNavigationMatchUrl = ({
  menu,
  align,
  className,
  itemClassName,
}: Props): React.ReactElement => {
  const location = useLocation();
  return (
    <section className={cn('mbx-tab_navigation', className)}>
      <ul className={cn('mbx-tab_navigation__list', `mbx-tab_navigation__list--${align}`)}>
        {menu.map((item: Menu, index: number) => {
          return (
            <li
              className={cn(itemClassName, 'mbx-tab_navigation__item', {
                'is-active': item.url === location.pathname,
              })}
              key={index}
            >
              <Link to={item.url} className="mbx-tab_navigation__link">
                <span className="relative">
                  {item.title}
                  {item.badge && (
                    <span className="absolute top-0 -right-8 rounded-full w-6 h-6 bg-red-600"></span>
                  )}
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    </section>
  );
};
