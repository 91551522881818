import React, { VFC, useEffect } from 'react';

import { FormDropdownMultipleItem } from './Item';
import { FormDropdownMultipleSectionItem } from './SectionItem';
import cn from 'classnames';

type TItemProps = React.ComponentProps<typeof FormDropdownMultipleItem>;

type TProps = {
  selectList: Array<TItemProps>;
  className?: string;
  itemClick?: TItemProps['onClick'];
  value?: Array<string>;
  parentRef: React.RefObject<HTMLDivElement>;
  disabled?: boolean
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=393%3A40447
 */
export const FormDropdownMultipleBase: VFC<TProps> = ({
  selectList,
  className = '',
  itemClick,
  value = [],
  parentRef,
  disabled = false,
}) => {
  useEffect(() => {
    if(!parentRef.current) return;
    const mbxDropdownSelections = parentRef.current.children[0];
    if (!mbxDropdownSelections) return;
    // ドロップダウンの高さ
    const dropdownHeight = mbxDropdownSelections.clientHeight;
    // スクロールの長さ
    const scrollHeight = mbxDropdownSelections.scrollHeight;
    // スクロールが発生する場合はスクロールバーを表示する
    if(dropdownHeight < scrollHeight) {
      mbxDropdownSelections.classList.add('mbx-dropdown-scroll');
      const scrollbar = mbxDropdownSelections.lastChild as HTMLElement;
      if (!scrollbar) return;
      const scrollbarHeight = (dropdownHeight / scrollHeight) * dropdownHeight;
      scrollbar.style.height = `${scrollbarHeight}px`;
      // 最下部までスクロールした時のTopの位置
      const scrollPositionBottom = (scrollHeight - dropdownHeight) + (dropdownHeight - scrollbarHeight);
      // スクロールの倍率
      const scrollMagnification = scrollPositionBottom / (scrollHeight - dropdownHeight);

      mbxDropdownSelections.addEventListener('scroll', () => {
        scrollbar.style.transform = `translate(0, ${mbxDropdownSelections.scrollTop * scrollMagnification}px)`;
      });
    }
  }, [parentRef.current]);

  return (
    <ul className={'mbx-dropdown-selections ' + className}>
      {selectList.map((item) => {
        return (
          <>
            {!item.value.includes('section') ? (
              <FormDropdownMultipleItem
                value={item.value}
                key={item.value}
                onClick={itemClick}
                select={value.includes(item.value)}
                disabled={disabled}
                className={cn({ 'pl-20': selectList.some((item) => item.value.includes('section'))})}
              >
                {item.children}
              </FormDropdownMultipleItem>
            ) : (
              <FormDropdownMultipleSectionItem
                value={item.value}
                key={item.value}
              >
                {item.children}
              </FormDropdownMultipleSectionItem>
            )}
          </>
        );
      })}
      <p className='mbx-dropdown-scroll-bar'></p>
    </ul>
  );
};
