import React from 'react';

import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import {
  FormContainerDropdownMultiple
} from '@/componentsDirect/Parts/Form/Container/DropdownMultiple';
import { FormCombinationsFromToDropdown } from '@/componentsDirect/Parts/Form/Combinations/FromToDropdown';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { UserCondition } from '@/componentsDirect/Parts/UserCondition/Forms';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';

/**
 * 検索条件フォーム 就業状況
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65909
 */
export const DirectSearchFormSectionEmploymentStatus = (): React.ReactElement => {
  return (
    <UserCondition title="就業状況">
      <>
        <div className="flex mb-22">
          <FormLayoutFieldset className="ml-24 w-176">
            <FormLabel label="就業状況" type="small"></FormLabel>
            <FormContainerDropdown
              name="mc_situation"
              placeholder="選択してください"
              selectList={FORM_OPTIONS.situation}
              className="mkt_non_mask_items"
            />
          </FormLayoutFieldset>

          <FormLayoutFieldset className="ml-24 w-1/2">
            <FormLabel label="在籍企業" type="small"></FormLabel>
            <FormContainerTextfield
              name="career_history_current_companies"
              className="w-full mkt_non_mask_items"
              placeholder="例）株式会社マイナビデザイン"
            />
          </FormLayoutFieldset>
        </div>
        <div className="flex flex-auto mb-22">
          <FormLayoutFieldset className="pl-24 w-full">
            <FormLabel label="現在の年収" type="small"></FormLabel>
            <FormContainerDropdownMultiple
              name="mc_yearly_income"
              placeholder="選択してください（複数選択可）"
              selectList={FORM_OPTIONS.yearly_income}
              className="mkt_non_mask_items"
            ></FormContainerDropdownMultiple>
          </FormLayoutFieldset>
        </div>
        <div className="flex flex-auto">
          <FormLayoutFieldset className="pl-24 w-1/2">
            <FormLabel label="現在の在籍部署名 " type="small"></FormLabel>
            <FormContainerTextfield
              name="career_history_current_section"
              className="w-full mkt_non_mask_items"
              placeholder="例）ITメディア事業部"
            />
          </FormLayoutFieldset>

          <FormLayoutFieldset className="pl-24 w-1/2">
            <FormLabel label="現在の役職名" type="small"></FormLabel>
            <FormContainerTextfield
              name="career_history_current_post"
              className="w-full mkt_non_mask_items"
              placeholder="例）デザインマネージャー"
            />
          </FormLayoutFieldset>
        </div>
      </>
    </UserCondition>
  );
};
