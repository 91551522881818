import '@/styles/common/Mypage/textbox.scss';

import React from 'react';

import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { OwnPortfolioResponse } from '@/utils/api-client';

type Props = {
  ownPortfolio: OwnPortfolioResponse | undefined;
};

export const SectionSelfPortfolio = (props: Props): React.ReactElement => {
  return (
    <section className="mt-48">
      <TitleSectionTitle>
        <div className="flex items-center">
          <span className="mr-4">自作のポートフォリオ（URL）の登録</span>
        </div>
      </TitleSectionTitle>
      <p className="text-14_21">
        自作のポートフォリオ（URL）の登録状況を確認できます。
        <br />
        自作のポートフォリオをお持ちの場合は、ぜひ登録し、企業からオファーをもらう可能性を高めましょう。
        <br />
        設定の変更は、
        <ButtonTextLink href="/mypage/self-portfolio/edit">こちらから</ButtonTextLink>
        行うことができます。
      </p>
      <div>
        <div className="flex flex-row pt-24 pb-6 items-center">
          <p className="text-12_16 font-bold">現在の設定</p>
        </div>
        <div className="mkt_mask_items">
          {!props.ownPortfolio?.own_portfolio?.url &&
          !props.ownPortfolio?.own_portfolio?.supplementary_info ? (
            <p className="mbx-textbox-color mbx-textbox">未登録</p>
          ) : (
            <div className="mbx-textbox-color mbx-textbox">
              <div className="flex">
                <p className="w-86 shrink-0 grow-0">URL :</p>
                <p className="flex-1">
                  {props.ownPortfolio?.own_portfolio?.url?.startsWith('http') ? (
                    <ButtonTextLink href={props.ownPortfolio?.own_portfolio?.url}>
                      {props.ownPortfolio?.own_portfolio?.url}
                    </ButtonTextLink>
                  ) : (
                    <>{props.ownPortfolio?.own_portfolio?.url}</>
                  )}
                </p>
              </div>
              <div className="flex">
                <p className="w-86 shrink-0 grow-0">補足情報 :</p>
                <p className="flex-1 whitespace-pre-wrap">
                  {props.ownPortfolio?.own_portfolio?.supplementary_info
                    ? props.ownPortfolio?.own_portfolio?.supplementary_info
                    : '未設定'}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
