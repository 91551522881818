import '@/stylesAdmin/Candidates/Detail/modal_container.scss';

import React from 'react';

import { OfferStatusEnum } from '@/utils/api-client';

import { ActionForm } from './ActionForm';
import { ReactionForm } from './ReactionForm';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type Props = {
  type: 'reaction' | 'action';
  status?: OfferStatusEnum;
  onSend: (value: string, text: string, offer_position?: string) => void;
  onClose: React.MouseEventHandler<HTMLDivElement>;
  initPosition?: string;
};

/**
 *
 * @author Kanta Shimizu
 *
 */
export const ModalContainer = ({
  type,
  onSend,
  onClose,
  initPosition = '',
  status,
}: Props): React.ReactElement => {
  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <section className="mbxd-candidates_modal w-624 p-80">
      <h4 className="mbx-typography--heading_4">
        {type === 'reaction' && 'じぶんの意見を投稿'}
        {type === 'action' && 'みんなの意見を元にアクションする'}
      </h4>
      <p className="mt-42 mbx-typography--caption_1 text-left">
        {type === 'reaction' && (
          <span>
            この求職者に対するあなたの意見を投稿しましょう。この投稿は社内に共有され、その結果を元に管理者が面談のオファー実施を判断します。
          </span>
        )}

        {type === 'action' && <span>オファー時のみ、候補者に通知が届きます。</span>}
      </p>
      {type === 'reaction' && <ReactionForm onSend={onSend} onClose={onClose} />}
      {type === 'action' && (
        <ActionForm onSend={onSend} onClose={onClose} status={status} initPosition={initPosition} />
      )}
    </section>
  );
};
