import React, { useMemo, useState } from 'react';
import Cropper from 'react-cropper';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';

type TProps = {
  src: string; // Data URI
  width: number;
  height: number;
  onClose: () => void;
  onSave: (dataUri: string) => void;
};

export const ImageCropperComponent = ({
  src,
  width,
  height,
  onClose,
  onSave,
}: TProps): React.ReactElement => {
  const mq = useMBXMediaQuery();
  const [cropper, setCropper] = useState<Cropper>();
  const [zoom, setZoom] = useState<number>(0.5);

  const maxHeight = useMemo(() => {
    if (mq.lg) {
      return 584;
    }
    if (mq.md) {
      return 584;
    }
    if (mq.sm) {
      return 279;
    }
  }, [mq]);

  const onChangeSlider: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setZoom(parseFloat(e.target.value));
  };

  const onCancel: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    onClose();
  };

  const onCreateImageData: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    if (!cropper) return;
    cropper.getCroppedCanvas({ fillColor: '#FFFFFF' }).toBlob((blob) => {
      if (!blob) return;
      const fileReader = new FileReader();

      fileReader.onload = function () {
        onSave(this.result as string);
        onClose();
      };

      fileReader.readAsDataURL(blob);
    });
  };

  const align = (direction: 'left' | 'center' | 'right' | 'top' | 'middle' | 'bottom') => {
    if (!cropper) return;
    const cropBoxData = cropper.getCropBoxData();
    const canvasData = cropper.getCanvasData();
    const imageData = cropper.getImageData();
    switch (direction) {
      case 'left':
        cropper.moveTo(cropBoxData.left, canvasData.top);
        break;
      case 'center':
        cropper.moveTo(
          cropBoxData.left + cropBoxData.width / 2 - imageData.width / 2,
          canvasData.top
        );
        break;
      case 'right':
        cropper.moveTo(cropBoxData.left + cropBoxData.width - imageData.width, canvasData.top);
        break;
      case 'top':
        cropper.moveTo(canvasData.left, cropBoxData.top);
        break;
      case 'middle':
        cropper.moveTo(
          canvasData.left,
          cropBoxData.top + cropBoxData.height / 2 - imageData.height / 2
        );
        break;
      case 'bottom':
        cropper.moveTo(canvasData.left, cropBoxData.top + cropBoxData.height - imageData.height);
        break;
    }
  };

  return (
    <div className="mbx-cropper">
      <Cropper
        src={src}
        style={{ height: maxHeight, width: '100%' }}
        guides={true}
        center
        dragMode="move"
        aspectRatio={width / height}
        cropBoxMovable={false}
        cropBoxResizable={false}
        zoomTo={zoom}
        onInitialized={(cropper) => {
          setCropper(cropper);
        }}
      />
      <div className="mbx-cropper_control">
        <div className="mbx-cropper_img-align">
          <button onClick={() => align('left')}>
            <img src="/images/icon-align-left.svg" alt="" />
          </button>
          <button onClick={() => align('center')}>
            <img src="/images/icon-align-center.svg" alt="" />
          </button>
          <button onClick={() => align('right')}>
            <img src="/images/icon-align-right.svg" alt="" />
          </button>
          <button onClick={() => align('top')}>
            <img src="/images/icon-align-top.svg" alt="" />
          </button>
          <button onClick={() => align('middle')}>
            <img src="/images/icon-align-middle.svg" alt="" />
          </button>
          <button onClick={() => align('bottom')}>
            <img src="/images/icon-align-bottom.svg" alt="" />
          </button>
        </div>

        <input
          type="range"
          step="0.0001"
          min={0}
          defaultValue={zoom}
          max={1}
          onChange={onChangeSlider}
        />

        <div className="mbx-cropper_button-wrapper">
          <BaseButton theme="secondary" size="m" width="large" onClick={onCancel}>
            キャンセル
          </BaseButton>

          <BaseButton theme="primary" size="m" width="large" onClick={onCreateImageData}>
            保存する
          </BaseButton>
        </div>
      </div>
    </div>
  );
};
