import { DirectSearchConditionModel } from '@/utils/api-client';
import { PayloadAction } from '@reduxjs/toolkit';

import { State } from './state';

/**
 * 仕様
 * https://matchbox-2021-doc-api-git-develop-prty.vercel.app/#operation/post-direct-search_condition
 */
export const reducers = {
  setSearchCondition: (
    state: State,
    action: PayloadAction<DirectSearchConditionModel | null>
  ): State => {
    localStorage.setItem('directCondition', JSON.stringify(action.payload));
    return { ...state, condition: action.payload };
  },
};
