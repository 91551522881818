import React, { useContext, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/';
import { DataLayerType } from '@/hooksDirect/common/useDataLayer';
import { useCompanyUserGroups } from '@/hooksDirect/useCompanyUserGroups';

type TProps = {
  show: boolean;
  getCompanyUserGroups: () => Promise<void>;
};

type TInputs = {
  name: string;
};

/**
 * 社内ユーザーグループ追加処理
 * https://www.figma.com/file/U3CKCcmEc7eIjxkbYoCZx7/UI?type=design&node-id=11781-162254&mode=dev
 */
export const DirectUsersAddUserGroupComponent = ({
  show,
  getCompanyUserGroups,
}: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const { postCompanyUserGroup } = useCompanyUserGroups();
  const dataLayer: DataLayerType = {
    event: 'pageView',
    actionType: 'page_view',
    actionName: 'filed_open',
    virtualPageName: 'add-user-group_step_1',
  };

  const methods = useForm<TInputs>({
    defaultValues: {
      name: '',
    },
  });

  const { handleSubmit } = methods;
  const { push } = useContext(DataLayerContext);

  // ====================================================================================================
  //
  // methods
  //
  // ====================================================================================================
  const onSubmit: SubmitHandler<TInputs> = (data) => {
    if (!data.name) return;
    (async () => {
      await postCompanyUserGroup(data);
      await getCompanyUserGroups();
      clearValue();
      push({
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'modal_open',
        virtualPageName: 'add-user-group_step_2',
      });
    })();
    return false;
  };

  // 値をクリア
  const clearValue = () => {
    methods.setValue('name', '');
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    if (show) {
      push(dataLayer);
    }
  }, [show]);

  return (
    <FormProvider {...methods}>
      <tr className="text-left">
        <td className="pl-16 border-b border-gray-200">
          <FormContainerTextfield
            name="name"
            className="mr-14 py-14"
            placeholder="追加するグループ名を入力（例 : デザインチーム）"
          />
        </td>
        <td className="text-right pr-16 border-b border-gray-200">
          <BaseButton
            size="s"
            theme="primary"
            className="w-full"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            disabled={!methods.getValues('name')}
          >
            保存
          </BaseButton>
        </td>
      </tr>
    </FormProvider>
  );
};
