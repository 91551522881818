import React from 'react';
import '@/styles/common/Portfolio/image_mobiles_preview.scss';

type Props = {
  images?: string[];
};

export function ImageMobilesPreview(props: Props): React.ReactElement {
  return (
    <>
      <div className="flex justify-center items-center bg-white h-full mkt_mask_items">
        <section className="mbx-image_mobiles_preview">
          <div className="mbx-image_mobiles_preview_inner">
            {props.images?.map((image, index) => (
              <div key={index} className="mbx-image_mobiles_preview_device">
                <img src={image} alt="" />
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
}
