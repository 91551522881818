import { DirectUserResponse } from '@/utils/api-client';

export type State = {
  isLogin?: boolean;
  isLoggedIn: boolean; //ログインアクションを実行したか。ログイン状態を示すのはisLogin
  isLoggedOut: boolean; //ログアウトアクションを実行したか。ログイン状態を示すのはisLogin
  userInfo: DirectUserResponse | null;
};

export const initialState: State = {
  isLoggedIn: false,
  isLoggedOut: false,
  userInfo: null,
};
