import React, { useState, useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Ed30 = (): React.ReactElement => {
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if (isAuth) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0030");
    if (!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g,"/")} 12:00:00`);
    const now = new Date;
    if (now > deadlineDate) setDeadline(true);
  }, []);

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16">
          <img className={style.img} src="../images/Top/event/event_0030_mv.png" width="978" height="504" alt="" />
        </div>
        <h1 className="mt-56 sm:mt-30 text-29_46 sm:text-18_24 font-bold text-center">4/11（火）18:00 -<br />
          MATCHBOXもくもく会<br />履歴書、職務経歴書、ポートフォリオ<br className='lg:hidden' />作りの不安を解消！<br />その場で相談・添削付き</h1>
        { deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">――転職活動のための準備の時間、とれていますか？</h2>
          <p className="text-16_28 sm:text-14_21 mt-30">お忙しい中でキャリアの棚卸しから、履歴書・職務経歴書・ポートフォリオ作成の時間をなかなか確保できないのではないでしょうか。きちんと作成しようと思うと手間がかかるし、そもそも実績をどうまとめればよいのかわからない理由も重なってなかなか進まない、という方も多いのではないでしょうか？<br />
            今回は転職書類作成の目的でオンライン上に集い、もくもくと進めていただく時間とするイベントになります。作成相談や書類添削もその場で承ります。逆に必ず質問がないと参加できないというわけでもありません。<br />
            <br />
            転職準備のためのまとまった時間を是非今回のMATCHBOXもくもく会でお過ごしください。</p>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">MATCHBOXもくもく会 Q&A</h3>
          <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
            <dl className="text-16_28 sm:text-14_21">
              <dt className="flex"><span className='pr-10'>Q.</span><span>質問がないと参加できないの？</span></dt>
              <dd className='flex mb-4'><span className='pr-10'>A.</span><span>作業の時間としてご参加いただいてもOKです！図書館を利用するイメージでご参加ください。</span></dd>
              <dt className="flex"><span className='pr-10'>Q.</span><span>作成相談、添削はできるの？</span></dt>
              <dd className='flex mb-4'><span className='pr-10'>A.</span><span>ご希望いただきましたら、1対1でお話ができるブレイクアウトルームにてご相談を承ります。</span></dd>
              <dt className="flex"><span className='pr-10'>Q.</span><span>誰でも参加できるの？</span></dt>
              <dd className='flex mb-4'><span className='pr-10'>A.</span><span>MATCHBOXのアカウントお持ちいただいていれば、どなたでも申し込み・ご参加いただけます。</span></dd>
              <dt className="flex"><span className='pr-10'>Q.</span><span>カメラ・マイクはオフでいいの？</span></dt>
              <dd className='flex'><span className='pr-10'>A.</span><span>もちろん大丈夫です。できればご質問の際などは、お顔が拝見できるととても嬉しいです。</span></dd>
            </dl>
          </div>
        </section>
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">以下に該当する方はオススメです。</h3>
          <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>履歴書・職務経歴書・ポートフォリオの作成を進めたい方<br />
                  ※MATCHBOXを使用しての作成ではなくても是非ご参加ください。</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>Web/IT/ゲームなどのクリエイティブ業界での転職を希望される方</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>制作中の履歴書・職務経歴書・ポートフォリオをブラッシュアップしたい方</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>まとまった時間でさくっと作成を進めたい方</span>
              </li>
              <li className="flex">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>転職相談をしたい方</span>
              </li>
            </ul>
          </div>
          <p className="text-16_28 sm:text-14_21 mt-30">興味がある方は是非、お早めにご応募ください。</p>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">講師プロフィール</h2>
          <div className='lg:grid lg:grid-cols-3 lg:gap-40'>
            <div className="event-instructor box-line sm:mb-30 md:mb-30 sm:p-16">
              <div className="instructor-photo">
                <img src="../images/Top/event/event_0014_prof-01.jpg" alt="" />
              </div>
              <dl className="instructor-prof sm:mt-16 lg:mt-30">
                <dt className="text-16_28 font-bold mb-8">
                  MATCHBOX担当<br />
                  <span className='instructor-name'>無州 有人</span>
                </dt>
                <dd className="instructor-position text-16_28 sm:text-14_21 mb-30 sm:mb-18">
                  <ul>
                    <li className='inline-block mr-10'>＃書類添削</li>
                    <li className='inline-block mr-10'>＃ポートフォリオ</li>
                    <li className='inline-block mr-10'>＃MATCHBOXの使い方</li>
                    <li className='inline-block mr-10'>＃相談乗ります</li>
                  </ul>
                </dd>
                <dd><span className='p-18 hitokoto text-16_28 sm:text-14_21'>ポートフォリオ作成でお困りの際は、お気軽にお声がけください。</span></dd>
              </dl>
            </div>
            <div className="event-instructor box-line sm:mb-30 md:mb-30 sm:p-16">
              <div className="instructor-photo">
                <img src="../images/Top/event/event_0014_prof-02.jpg" alt="" />
              </div>
              <dl className="instructor-prof sm:mt-16 lg:mt-30">
                <dt className="text-16_28 font-bold mb-8">
                  MATCHBOX担当<br />
                  <span className='instructor-name'>高越 佳奈</span>
                </dt>
                <dd className="instructor-position text-16_28 sm:text-14_21 mb-30 sm:mb-18">
                  <ul>
                    <li className='inline-block mr-10'>＃ポートフォリオ</li>
                    <li className='inline-block mr-10'>＃MATCHBOXの使い方</li>
                    <li className='inline-block mr-10'>＃添削します</li>
                    <li className='inline-block mr-10'>＃未経験</li>
                  </ul>
                </dd>
                <dd><span className='p-18 hitokoto text-16_28 sm:text-14_21'>一緒に転職準備はじめましょう！</span></dd>
              </dl>
            </div>
            <div className="event-instructor box-line sm:p-16">
              <div className="instructor-photo">
                <img src="../images/Top/event/event_0004_prof.jpg" alt="" />
              </div>
              <dl className="instructor-prof sm:mt-16 lg:mt-30">
                <dt className="text-16_28 font-bold mb-8">
                  転職支援担当<br />
                  <span className='instructor-name'>南 文乃</span>
                </dt>
                <dd className="instructor-position text-16_28 sm:text-14_21 mb-30 sm:mb-18 lg:mb-58">
                  <ul>
                    <li className='inline-block mr-10'>＃キャリア・転職支援</li>
                    <li className='inline-block mr-10'>＃転職市場</li>
                  </ul>
                </dd>
                <dd><span className='p-18 hitokoto text-16_28 sm:text-14_21'>お気軽にご参加・お声がけください！</span></dd>
              </dl>
            </div>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap" rowSpan={0}>
                  <span className="sm:block">18：00</span><span className="sm:block">～</span><span className="sm:block">21：00</span>
                </th>
                <td>
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="sm:flex lg:flex sm:flex-col sm:mb-4"><span className='w-90 inline-block'>17：50～</span><span>開場</span></li>
                    <li className="sm:flex lg:flex sm:flex-col sm:mb-4"><span className='w-90 inline-block'>18：00～</span><span className='lg:w-max'>概要のご説明<br />
                      ※オペレーションについては画面にて共有しておきますので、<br className='sm:hidden md:hidden' />遅れて入られる方もご安心ください。</span></li>
                    <li className="sm:flex lg:flex sm:flex-col sm:mb-4"><span className='w-90 inline-block'>18：10～</span><span>もくもく時間   ※入退室自由です</span></li>
                    <li className="sm:flex lg:flex sm:flex-col sm:mb-4"><span className='w-90 inline-block'>21：00</span><span>まとめ、おしらせの時間</span></li>
                    <li className="sm:flex lg:flex sm:flex-col"><span className='w-90 inline-block'>21：30</span><span>完全撤収</span></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="text-16_28 sm:text-14_21 mt-30">※添削等希望される方はあらかじめMATCHBOX上へデータをアップロードしてください。</p>
        </section>
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2023/4/11（火）18:00～<br />
                  [入室開始] 17:50～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">対象者</th>
                <td>転職活動の準備を始めている方</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.参加申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink"
                      href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.開催当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div>
    </div>
  );
};
