import '@/styles/common/Modal/editable.scss';

import React, { VFC, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import BaseButton from '@/components/common/Button/BaseButton';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import cn from 'classnames';

type TProps = {
  children: React.ReactNode;
};

/**
 *
 * スマホ時の並び替え・編集用モーダル
 */
export const ModalEditable: VFC<TProps> = ({ children }) => {
  const el = document.getElementById('generalModalPortal') as HTMLElement;
  return ReactDOM.createPortal(children, el);
};

export const ModalEditableBody: VFC<{
  onClose: React.MouseEventHandler<HTMLDivElement>;
  children: React.ReactElement;
  bgGray?: boolean;
}> = ({
  onClose,
  children,
  bgGray
}): React.ReactElement => {
  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current) {
      disableBodyScroll(ref.current);
    }
    return () => {
      clearAllBodyScrollLocks();
    }
  },[ref])

  return (
    <div className="mbx-modal-editable mbx-page-career-history" ref={ref}>
      <div className={cn("mbx-modal-editable__content", {["bg-gray-100"]:bgGray, ["bg-white"]:!bgGray})}>
        <div className="py-16 pl-12">
          <BaseButton iconName="Arrow_Left" size="40" theme="link" onClick={onClose}>
            戻る
          </BaseButton>
        </div>
        <div className="mbx-keyline-horizontal-b--thin">
          {children}
        </div>
      </div>
    </div>
  );
};
