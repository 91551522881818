import { AxiosError } from 'axios';
import React, { ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { MESSAGE } from '@/definitionDirect/MESSAGE';
import { State } from '@/redux/state';
import { notificationError, notificationSuccess, toggleLoading } from '@/reduxAdmin/modules/utils';
import { DirectApi, RequestPassword, ResponseError } from '@/utils/api-client';

type IFormInputs = {
  password: string;
  passwordAgain: string;
};

type Props = {
  token: string;
};

export const PasswordForm = (props: Props): ReactElement => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loading = useSelector((state: State) => state.loading);
  const { push } = React.useContext(DataLayerContext);
  const methods = useForm<IFormInputs>({
    defaultValues: {
      password: '',
      passwordAgain: '',
    },
  });

  const onPasswordReset = async () => {
    dispatch(toggleLoading(true));
    const data: RequestPassword = {
      token: props.token,
      password: methods.getValues('password'),
      re_password: methods.getValues('passwordAgain'),
    };

    try {
      await new DirectApi().postDirectPasswordResetPassword(data);
      dispatch(notificationSuccess('パスワードの再設定が完了しました'));
      push({
        event: 'pwSetting',
        actionType: 'pw_setting',
        actionName: 'pw_setting',
      });
      history.push('/direct/login');
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  return (
    <section className="password-change text-center">
      <p className="mbx-typography--body_1 mt-48">
        新しいパスワードを入力して、パスワードを再設定してください。
      </p>
      <div className="form w-308 m-auto sm:w-full">
        <FormProvider {...methods}>
          <form>
            <FormLayoutFieldset className={'mt-24'}>
              <FormLabel label="パスワード" type="small"></FormLabel>
              <FormContainerTextfield
                type={'password'}
                name="password"
                placeholder="例）Match.123"
                height="large"
                className="mkt_mask_items"
              />
            </FormLayoutFieldset>
            <p className="mbx-typography--caption_1 mt-8 whitespace-pre text-left">
              {MESSAGE.password_specifications}
            </p>
            <p className="mbx-typography--caption_1 mb-16 ml-12 text-left">
                {MESSAGE.password_specification_supplement}
              </p>
            <FormContainerTextfield
              type={'password'}
              name="passwordAgain"
              placeholder="確認のためもう1度入力してください"
              height="large"
              className="mkt_mask_items"
            />
          </form>
        </FormProvider>
        <BaseButton
          size={'m'}
          theme={'primary'}
          className={'w-full mt-48'}
          onClick={onPasswordReset}
          disabled={loading}
        >
          パスワードを再設定
        </BaseButton>
      </div>
    </section>
  );
};
