// 【type別の並び替えの注意事項】
// ・並び替えはアルファベット順になる(A→Z)
// ・同じtypeを2つのイベントに記載すること(イベント一覧で2列に並べるため)
// ・typeに１つのイベントしかない時（イベント一覧に1列で右側にスペースを設けたい場合）時は「single: true」といれること
// ・過去月のイベントの「type」は自動的に削除するように処理されているため、削除しなくてよい

export const eventList = [
  {
    id: '0123',
    type: '',
    title: [
      'MATCHBOXユーザー10万人登録記念！＜特典つき！＞',
      '<br />',
      'イベント運営スタッフがおすすめする生成AIツール'
    ],
    target: '生成AIに興味のあるWebクリエイターの方',
    img: '../images/Top/event/event_0123_mv.jpg',
    eventDate: '2024.11.26(火) 開催',
    openingDate: '2024-11-26 19:30:00',
    deadlineDate: '2024-11-26 21:00:00',
    description: '日常的にAIを業務で活用しているマッチボックスの運営スタッフ3人が登壇し、Webプランナーやディレクターとして、画像・動画・BGM制作、企画・文章作成などの実務視点から、様々な事例やノウハウを共有いたします。AIを積極的に活用したいものの、どのAIをどう使えばよいか迷っている方に最適なセミナーになります参加特典もありますのでご興味のある方はぜひご応募ください！',
    releaseDate: '2024-11-07 17:00:00',
    performerType: 'PerformingGroup',
    performerName: '一般社団法人 ディレクションサポート協会 代表理事 助田 正樹・シララ株式会社 代表取締役 伊東 宏之・株式会社マイナビワークス マーケティング統括部 本郷 孝太郎',
  },
  {
    id: '0122',
    type: '',
    title: [
      'Webデザイナーとしてのキャリアパスの考え方と整理'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0122_mv.jpg',
    eventDate: '2024.11.19(火) 開催',
    openingDate: '2024-11-19 19:30:00',
    deadlineDate: '2024-11-19 21:00:00',
    description: 'Web業界で活躍するための転職活動対策に直接役に立つセミナーシリーズ「Webデザイナーとしてのキャリアパスの考え方と整理」を11/19（火）に開催します！今回の対象職種はWebデザイナーとし、オンラインでの配信になります。また、参加費は無料です。',
    releaseDate: '2024-10-31 17:00:00',
    performerType: 'Person',
    performerName: '株式会社Buzzreach UI/UX/Webデザイナー 木村 よねこ',
  },
  {
    id: '0121',
    type: '',
    title: [
      '〈作成初心者向け〉ポートフォリオ基礎講座',
      '<br />',
      '～MATCHBOXを使って実績をまとめましょう！～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0121_mv.png',
    eventDate: '2024.11.14(木) 開催',
    openingDate: '2024-11-14 19:00:00',
    deadlineDate: '2024-11-14 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「〈作成初心者向け〉ポートフォリオ基礎講座 ～MATCHBOXを使って実績をまとめましょう！～」を11/14（木）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2024-10-31 17:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0120',
    type: '',
    title: [
      '採用者の心をつかむ',
      '<br />',
      'Webデザイナーのための',
      '<br />',
      '魅力120%のポートフォリオ構築法セミナー'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0120_mv.jpg',
    eventDate: '2024.11.12(火) 開催',
    openingDate: '2024-11-12 19:30:00',
    deadlineDate: '2024-11-12 21:00:00',
    description: '皆さまのポートフォリオ作成への疑問に答えるべく、11/12（火）19:30～、「採用者の心をつかむWebデザイナーのための魅力120％のポートフォリオ構築法セミナー」を開催いたします。どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそうセミナーとなっています。',
    releaseDate: '2024-10-31 17:00:00',
    performerType: 'Person',
    performerName: '株式会社VOWL 代表取締役 Thinka Studio株式会社 代表取締役 林 航平',
  },
  {
    id: '0119',
    type: '',
    title: [
      '〈作成初心者向け〉ポートフォリオ基礎講座',
      '<br />',
      '～MATCHBOXを使って実績をまとめましょう！～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0119_mv.png',
    eventDate: '2024.10.29(火) 開催',
    openingDate: '2024-10-29 19:00:00',
    deadlineDate: '2024-10-29 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「〈作成初心者向け〉ポートフォリオ基礎講座 ～MATCHBOXを使って実績をまとめましょう！～」を10/29（火）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2024-10-08 17:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0118',
    type: '',
    title: [
      '実装者目線と合わせてみていく',
      '<br />',
      'Figmaのデザインデータのポイント'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0118_mv.png',
    eventDate: '2024.10.24(木) 開催',
    openingDate: '2024-10-24 19:30:00',
    deadlineDate: '2024-10-24 20:50:00',
    description: 'Webデザイナー向け『実装者目線と合わせてみていくFigmaのデザインデータのポイント』を開催いたします。今さら聞けない『Figma』の基本的な使い方や実務データを見ながらのポイントを解説します。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2024-10-08 17:00:00',
    performerType: 'Person',
    performerName: 'Webクリエイター 庄司 美雪',
  },
  {
    id: '0117',
    type: '',
    title: [
      '実録！デザイナーがキャリアチェンジしたいときの',
      '<br />',
      'ポートフォリオ作成法'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0117_mv.jpg',
    eventDate: '2024.10.22(火) 開催',
    openingDate: '2024-10-22 19:30:00',
    deadlineDate: '2024-10-22 20:50:00',
    description: '皆さまのポートフォリオ作成への悩みに答えるべく、「実録！デザイナーがキャリアチェンジしたいときのポートフォリオ作成法」10/22（火）19:30～、オンラインで開催します。 ポートフォリオに関するお悩みやご質問を承りますので、是非ご参加ください。',
    releaseDate: '2024-10-08 17:00:00',
    performerType: 'Person',
    performerName: '株式会社カオナビ カスタマーマーケティング部/小中学生向けデザインスクール 講師 池田 萌',
  },
  {
    id: '0116',
    type: '',
    title: [
      '選考通過につながるポートフォリオ講座',
      '<br />',
      '～最新の転職市場とドキュメント作成術～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0116_mv.png',
    eventDate: '2024.10.17(木) 開催',
    openingDate: '2024-10-17 19:30:00',
    deadlineDate: '2024-10-17 21:00:00',
    description: '昨今のWeb業界の転職市場の動向を踏まえ、採用担当者にきちんとキャリアと経験が伝わるポートフォリオを作成するためのポイントをお伝えするセミナーを10/17（木）19:30～にオンライン開催いたします。',
    releaseDate: '2024-10-08 17:00:00',
    performerType: 'Person',
    performerName: '株式会社マイナビワークス マーケティング部 転職セミナー企画担当 南 文乃',
  },
  {
    id: '0115',
    type: '',
    title: [
      '現役デザイナーに聞こう！',
      '<br />',
      'Figma活用なんでもシェアリング会 vol.2'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0115_mv.png',
    eventDate: '2024.9.26(木) 開催',
    openingDate: '2024-09-26 19:30:00',
    deadlineDate: '2024-09-26 21:00:00',
    description: 'Webデザイナー向け 『現役デザイナーに聞こう！Figma活用なんでもシェアリング会 vol.2』を開催いたします。皆さまからいただいたFigma使用に関する悩みについて2名の現役デザイナーに具体的な解決案をお話いただくセミナーとなっております。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2024-09-06 17:00:00',
    performerType: 'PerformingGroup',
    performerName: 'Webデザイナー/エンジニア 庄司 美雪・フリーランス Webクリエイター せきゆおう',
  },
  {
    id: '0114',
    type: '',
    title: [
      '「人となり」を伝える',
      '<br />',
      'ポートフォリオのGoodな記載ポイントとは'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0114_mv.jpg',
    eventDate: '2024.9.24(火) 開催',
    openingDate: '2024-09-24 19:30:00',
    deadlineDate: '2024-09-24 21:00:00',
    description: '皆さまのポートフォリオ作成への悩みに答えるべく、「「人となり」を伝える ポートフォリオのGoodな記載ポイントとは」9/24（火）19:30～、オンラインで開催します。 ポートフォリオに関するお悩みやご質問を承りますので、是非ご参加ください。',
    releaseDate: '2024-09-06 17:00:00',
    performerType: 'Person',
    performerName: 'Studio FireColor 小泉 誠',
  },
  {
    id: '0113',
    type: '',
    title: [
      '自己PRとポートフォリオ作成について'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0113_mv.png',
    eventDate: '2024.9.19(木) 開催',
    openingDate: '2024-09-19 19:30:00',
    deadlineDate: '2024-09-19 21:00:00',
    description: 'Web業界で活躍するための転職活動対策に直接役に立つセミナーシリーズ「自己PRとポートフォリオ作成について」を9/19（木）に開催します！今回の対象職種はWebデザイナーとし、オンラインでの配信になります。是非ご参加ください！',
    releaseDate: '2024-09-06 17:00:00',
    performerType: 'Person',
    performerName: '株式会社Buzzreach UI/UX/Webデザイナー 木村 よねこ',
  },
  {
    id: '0112',
    type: '',
    title: [
      '〈作成初心者向け〉ポートフォリオ基礎講座',
      '<br />',
      '～MATCHBOXを使って実績をまとめましょう！～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0112_mv.png',
    eventDate: '2024.9.10(火) 開催',
    openingDate: '2024-09-10 19:00:00',
    deadlineDate: '2024-09-10 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「〈作成初心者向け〉ポートフォリオ基礎講座 ～MATCHBOXを使って実績をまとめましょう！～」を9/10（火）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2024-09-02 12:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0111',
    type: '',
    title: [
      'Webデザイナーとしてのキャリアパスの考え方と整理'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0111_mv.png',
    eventDate: '2024.8.29(木) 開催',
    openingDate: '2024-08-29 19:30:00',
    deadlineDate: '2024-08-29 21:00:00',
    description: 'Web業界で活躍するための転職活動対策に直接役に立つセミナーシリーズ「Webデザイナーとしてのキャリアパスの考え方と整理」を8/29（木）に開催します！今回の対象職種はWebデザイナーとし、オンラインでの配信になります。また、参加費は無料です。',
    releaseDate: '2024-08-06 12:00:00',
    performerType: 'Person',
    performerName: '株式会社Buzzreach UI/UX/Webデザイナー 木村 よねこ',
  },
  {
    id: '0110',
    type: '',
    title: [
      '現役デザイナーに聞こう！',
      '<br />',
      'Figma活用なんでもシェアリング会 vol.1'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0110_mv.png',
    eventDate: '2024.8.27(火) 開催',
    openingDate: '2024-08-27 19:30:00',
    deadlineDate: '2024-08-27 21:00:00',
    description: 'Webデザイナー向け 『現役デザイナーに聞こう！Figma活用なんでもシェアリング会 vol.1』を開催いたします。皆さまからいただいたFigma使用に関する悩みについて2名の現役デザイナーに具体的な解決案をお話いただくセミナーとなっております。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2024-08-06 12:00:00',
    performerType: 'PerformingGroup',
    performerName: 'Webデザイナー/エンジニア 庄司 美雪・フリーランス Webクリエイター せきゆおう',
  },
  {
    id: '0109',
    type: '',
    title: [
      'Webデザイナー向け',
      '<br />',
      '採用につながる「自分の強み」を伝えるための',
      '<br />',
      'ポートフォリオとは'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0109_mv.png',
    eventDate: '2024.8.26(月) 開催',
    openingDate: '2024-08-26 19:30:00',
    deadlineDate: '2024-08-26 21:00:00',
    description: '皆さまのポートフォリオ作成への疑問に答えるべく、「 Webデザイナー向け 採用につながる「自分の強み」を伝えるためのポートフォリオとは」を8/26（月）19:30～、オンラインで開催します。 どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそうポートフォリオ講座です。',
    releaseDate: '2024-08-06 12:00:00',
    performerType: 'Person',
    performerName: '株式会社StoreHero グロースパートナー フリーランスデザイナー 大石 慎平',
  },
  {
    id: '0108',
    type: '',
    title: [
      '〈作成初心者向け〉ポートフォリオ基礎講座',
      '<br />',
      '～MATCHBOXを使って実績をまとめましょう！～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0108_mv.png',
    eventDate: '2024.8.20(火) 開催',
    openingDate: '2024-08-20 19:00:00',
    deadlineDate: '2024-08-20 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「〈作成初心者向け〉ポートフォリオ基礎講座 ～MATCHBOXを使って実績をまとめましょう！～」を8/20（火）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2024-08-06 17:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0107',
    type: '',
    title: [
      '現役デザイナーが紹介するデザインツール活用講座'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0107_mv.png',
    eventDate: '2024.7.30(火) 開催',
    openingDate: '2024-07-30 19:30:00',
    deadlineDate: '2024-07-30 20:50:00',
    description: 'Webデザイナー向け  『現役デザイナーが紹介するデザインツール活用講座』を開催いたします。注目を集めるFigmaやAdobe XDをはじめ、定番ツールであるIllustratorを利用したデザイン作成例を具体的にお話いただきます。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2024-07-04 12:00:00',
    performerType: 'Person',
    performerName: '株式会社Buzzreach UI/UX/Webデザイナー 木村 よねこ',
  },
  {
    id: '0106',
    type: '',
    title: [
      '採用者の心をつかむ',
      '<br />',
      'Webデザイナーのための',
      '<br />',
      '魅力120%のポートフォリオ構築法セミナー'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0106_mv.png',
    eventDate: '2024.7.24(水) 開催',
    openingDate: '2024-07-24 19:30:00',
    deadlineDate: '2024-07-24 21:00:00',
    description: '皆さまのポートフォリオ作成への疑問に答えるべく、7/24（水）19:30～、「採用者の心をつかむWebデザイナーのための魅力120％のポートフォリオ構築法セミナー」を開催いたします。どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそうセミナーとなっています。',
    releaseDate: '2024-06-28 17:00:00',
    performerType: 'Person',
    performerName: '株式会社VOWL 代表取締役 Thinka Studio株式会社 代表取締役 林 航平',
  },
  {
    id: '0105',
    type: '',
    title: [
      '選考通過につながるポートフォリオ講座',
      '<br />',
      '～最新の転職市場とドキュメント作成術～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0105_mv.png',
    eventDate: '2024.7.18(木) 開催',
    openingDate: '2024-07-18 19:30:00',
    deadlineDate: '2024-07-18 21:00:00',
    description: '昨今のWeb業界の転職市場の動向を踏まえ、採用担当者にきちんとキャリアと経験が伝わるポートフォリオを作成するためのポイントをお伝えするセミナーを7/18（木）19:30～にオンライン開催いたします。',
    releaseDate: '2024-06-28 17:00:00',
    performerType: 'Person',
    performerName: '株式会社マイナビワークス マーケティング部 転職セミナー企画担当 南 文乃',
  },
  {
    id: '0104',
    type: '',
    title: [
      '〈作成初心者向け〉ポートフォリオ基礎講座',
      '<br />',
      '～MATCHBOXを使って実績をまとめましょう！～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0104_mv.png',
    eventDate: '2024.7.11(木) 開催',
    openingDate: '2024-07-11 19:00:00',
    deadlineDate: '2024-07-11 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「〈作成初心者向け〉ポートフォリオ基礎講座 ～MATCHBOXを使って実績をまとめましょう！～」を7/11（木）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2024-06-28 17:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0103',
    type: '',
    title: [
      'Webデザイナー進化論 season2 UI/UXデザイナー徹底解剖編',
      '<br />',
      'ユーザビリティテスト後のUI改善！',
      '<br />',
      '忖度なし実演！デザイナーによるクライアントへの提案プレゼンテーション'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0103_mv.jpg',
    eventDate: '2024.7.9(火) 開催',
    openingDate: '2024-07-09 19:30:00',
    deadlineDate: '2024-07-09 20:50:00',
    description: 'Webデザイナー進化論 season2 UI/UXデザイナー徹底解剖編  『ユーザビリティテスト後のUI改善！忖度なし実演！デザイナーによるクライアントへの提案プレゼンテーション』を7/9（火）19:30～開催いたします。UIリデザインの改善アウトプットを持って、クライアントへの提案の様子を実演いただきます。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2024-06-28 17:00:00',
    performerType: 'Person',
    performerName: '日本ウェブデザイン株式会社 CXO兼UXデザイナー、クリエイターズ・シナジーカフェ共同運営者 タグチ マリコ',
  },
  {
    id: '0101',
    type: '',
    title: [
      'Webデザイナー進化論 season2 UI/UXデザイナー徹底解剖編',
      '<br />',
      'ユーザビリティテストを経たUI改善！',
      '<br />',
      'Figmaのリデザインプロセス解説'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0101_mv.jpg',
    eventDate: '2024.6.25(火) 開催',
    openingDate: '2024-06-25 19:30:00',
    deadlineDate: '2024-06-25 20:50:00',
    description: 'Webデザイナー進化論 season2 UI/UXデザイナー徹底解剖編  『ユーザビリティテストを経たUI改善！Figmaのリデザインプロセス解説』を6/25（火）19:30～開催いたします。ユーザビリティテスト結果をもとに、Figmaなどでリデザインしたプロセスをお話いただきます。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2024-05-30 17:00:00',
    performerType: 'Person',
    performerName: '日本ウェブデザイン株式会社 CXO兼UXデザイナー、クリエイターズ・シナジーカフェ共同運営者 タグチ マリコ',
  },
  {
    id: '0102',
    type: '',
    title: [
      'Webデザイナーのための',
      '<br />',
      '採用につながるポートフォリオ講座',
      '<br />',
      '～「自分のキャリア」を効果的にアピールしよう！～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0102_mv.jpg',
    eventDate: '2024.6.20(木) 開催',
    openingDate: '2024-06-20 19:30:00',
    deadlineDate: '2024-06-20 21:00:00',
    description: '皆さまのポートフォリオ作成への疑問に答えるべく、「 Webデザイナーのための採用につながるポートフォリオ講座 ～「自分のキャリア」を効果的にアピールしよう！～」を6/20（木）19:30～、オンラインで開催します。 どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそうポートフォリオ講座です。',
    releaseDate: '2024-05-30 17:00:00',
    performerType: 'Person',
    performerName: 'Webデザイナー/エンジニア 庄司 美雪',
  },
  {
    id: '0100',
    type: '',
    title: [
      '〈作成初心者向け〉ポートフォリオ基礎講座',
      '<br />',
      '～MATCHBOXを使って実績をまとめましょう！～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0100_mv.png',
    eventDate: '2024.6.11(火) 開催',
    openingDate: '2024-06-11 19:00:00',
    deadlineDate: '2024-06-11 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「〈作成初心者向け〉ポートフォリオ基礎講座 ～MATCHBOXを使って実績をまとめましょう！～」を6/11（火）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2024-05-30 17:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0099',
    type: '',
    title: [
      'Webデザイナー進化論 season2 UI/UXデザイナー徹底解剖編',
      '<br />',
      'リアルなユーザーの反応を肌で感じる',
      '<br />',
      '実録ユーザビリティテスト'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0099_mv.jpg',
    eventDate: '2024.5.28(火) 開催',
    openingDate: '2024-05-28 19:30:00',
    deadlineDate: '2024-05-28 20:50:00',
    description: 'Webデザイナー進化論 season2 UI/UXデザイナー徹底解剖編  『リアルなユーザーの反応を肌で感じる実録ユーザビリティテスト』を5/28（火）19:30～開催いたします。マッチボックスを題材としたユーザビリティテスト実演つきで、その様子を皆さまにご覧いただくセミナーとなります。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2024-04-25 17:00:00',
    performerType: 'Person',
    performerName: '日本ウェブデザイン株式会社 CXO兼UXデザイナー、クリエイターズ・シナジーカフェ共同運営者 タグチ マリコ',
  },
  {
    id: '0098',
    type: '',
    title: [
      'デザイン会社経営者から見る',
      '<br />',
      '「採用したいデザイナーのポートフォリオ」とは？'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0098_mv.jpg',
    eventDate: '2024.5.21(火) 開催',
    openingDate: '2024-05-21 19:30:00',
    deadlineDate: '2024-05-21 21:00:00',
    description: '皆さまのポートフォリオ作成への疑問に答えるべく、「デザイン会社経営者から見る「採用したいデザイナーのポートフォリオ」とは？」を5/21（火）19:30～、オンラインで開催します。 どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそうポートフォリオ講座です。',
    releaseDate: '2024-04-25 17:00:00',
    performerType: 'Person',
    performerName: '株式会社IMAKE 代表取締役 DTP・Webデザイナー 濱野 将',
  },
  {
    id: '0097',
    type: '',
    title: [
      'Webデザイナー進化論 season2 UI/UXデザイナー徹底解剖編',
      '<br />',
      'ユーザー視点で課題を発見！',
      '<br />',
      'UIデザインをみがく評価方法'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0097_mv.jpg',
    eventDate: '2024.5.16(木) 開催',
    openingDate: '2024-05-16 19:30:00',
    deadlineDate: '2024-05-16 20:50:00',
    description: 'Webデザイナー進化論 season2 UI/UXデザイナー徹底解剖編  『ユーザー視点で課題を発見！UIデザインをみがく評価方法』を5/16（木）19:30～開催いたします。ユーザビリティテストをはじめとする、UIデザインの評価手法と具体的な進め方といったUIデザインをみがく評価方法についてお話いただきます。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2024-04-25 17:00:00',
    performerType: 'Person',
    performerName: '日本ウェブデザイン株式会社 CXO兼UXデザイナー、クリエイターズ・シナジーカフェ共同運営者 タグチ マリコ',
  },
  {
    id: '0096',
    type: '',
    title: [
      'はじめて作成する前に知っておきたい',
      '<br />',
      '〈未経験向け〉ポートフォリオ作りの基礎講座',
      '<br />',
      '～実際の作成風景ものぞき見しよう～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0096_mv.png',
    eventDate: '2024.5.14(火) 開催',
    openingDate: '2024-05-14 19:00:00',
    deadlineDate: '2024-05-14 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「はじめて作成する前に知っておきたい　〈未経験向け〉ポートフォリオ作りの基礎講座　～実際の作成風景ものぞき見しよう～」を5/14（火）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2024-04-25 12:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0095',
    type: '',
    title: [
      '選考通過につながるポートフォリオ講座',
      '<br />',
      '～最新の転職市場とドキュメント作成術～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0095_mv.png',
    eventDate: '2024.4.23(火) 開催',
    openingDate: '2024-04-23 19:30:00',
    deadlineDate: '2024-04-23 21:00:00',
    description: '昨今のWeb業界の転職市場の動向を踏まえ、採用担当者にきちんとキャリアと経験が伝わるポートフォリオを作成するためのポイントをお伝えするセミナーを4/23（火）19:30～にオンライン開催いたします。',
    releaseDate: '2024-04-01 12:00:00',
    performerType: 'Person',
    performerName: '株式会社マイナビワークス マーケティング部 転職セミナー企画担当 南 文乃',
  },
  {
    id: '0094',
    type: '',
    title: [
      'Webデザイナー向け',
      '<br />',
      '採用につながる「自分の強み」を伝えるための',
      '<br />',
      'ポートフォリオとは'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0094_mv.png',
    eventDate: '2024.4.18(木) 開催',
    openingDate: '2024-04-18 19:30:00',
    deadlineDate: '2024-04-18 21:00:00',
    description: '皆さまのポートフォリオ作成への疑問に答えるべく、「 Webデザイナー向け 採用につながる「自分の強み」を伝えるためのポートフォリオとは」を4/18（木）19:30～、オンラインで開催します。 どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそうポートフォリオ講座です。',
    releaseDate: '2024-04-01 12:00:00',
    performerType: 'Person',
    performerName: '株式会社StoreHero グロースパートナー フリーランスデザイナー 大石 慎平',
  },
  {
    id: '0093',
    type: '',
    title: [
      'Webデザイナー進化論 season2 UI/UXデザイナー徹底解剖編',
      '<br />',
      'ぜんぶ見せます！',
      '<br />',
      'UIデザイナーのお仕事全体像とスキルセット'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0093_mv.jpg',
    eventDate: '2024.4.16(火) 開催',
    openingDate: '2024-04-16 19:30:00',
    deadlineDate: '2024-04-16 20:50:00',
    description: 'Webデザイナー進化論 season2 UI/UXデザイナー徹底解剖編  『ぜんぶ見せます！UIデザイナーのお仕事全体像とスキルセット』を4/16（火）19:30～開催いたします。デザイナーの役割を5段階に分解して解説いただいたり、ご自身のスキルからどのようにUIデザイナーを目指すかのヒントになるような内容でUIデザイナーの仕事の全体像についてお話いただきます。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2024-04-01 12:00:00',
    performerType: 'Person',
    performerName: '日本ウェブデザイン株式会社 CXO兼UXデザイナー、クリエイターズ・シナジーカフェ共同運営者 タグチ マリコ',
  },
  {
    id: '0092',
    type: '',
    title: [
      'はじめて作成する前に知っておきたい',
      '<br />',
      '〈未経験向け〉ポートフォリオ作りの基礎講座',
      '<br />',
      '～実際の作成風景ものぞき見しよう～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0092_mv.png',
    eventDate: '2024.4.9(火) 開催',
    openingDate: '2024-04-09 19:00:00',
    deadlineDate: '2024-04-09 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「はじめて作成する前に知っておきたい　〈未経験向け〉ポートフォリオ作りの基礎講座　～実際の作成風景ものぞき見しよう～」を4/9（火）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2024-03-21 12:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0091',
    type: '',
    title: [
      '採用者の心をつかむ',
      '<br />',
      'Webデザイナーのための',
      '<br />',
      '魅力120%のポートフォリオ構築法セミナー'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0091_mv.png',
    eventDate: '2024.3.27(水) 開催',
    openingDate: '2024-03-27 20:00:00',
    deadlineDate: '2024-03-27 22:00:00',
    description: '',
    releaseDate: '2024-03-01 17:00:00',
    performerType: 'Person',
    performerName: '株式会社VOWL 代表取締役 Thinka Studio株式会社 代表取締役 林 航平',
  },
  {
    id: '0090',
    type: '',
    title: [
      'Figma × Visual Studio Code',
      '<br />',
      'Webデザイナーのためのコーディング術'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0090_mv.jpg',
    eventDate: '2024.03.21(木) 開催',
    openingDate: '2024-03-21 19:30:00',
    deadlineDate: '2024-03-21 21:00:00',
    description: '',
    releaseDate: '2024-03-01 17:00:00',
    performerType: 'Person',
    performerName: 'Studio FireColor 小泉 誠',
  },
  {
    id: '0089',
    type: '',
    title: [
      '転職の舞台裏',
      '<br />',
      '人事・デザイナー・面接官が贈る幸せな転職成功のカギ'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0089_mv.png',
    eventDate: '2024.3.19(火) 開催',
    openingDate: '2024-03-19 19:30:00',
    deadlineDate: '2024-03-19 21:00:00',
    description: '',
    releaseDate: '2024-03-01 17:00:00',
    performerType: 'Person',
    performerName: '株式会社デパート Corporatedesign div.課長 2級キャリア・コンサルティング技能士 岸本 佳奈',
  },
  {
    id: '0088',
    type: '',
    title: [
      'はじめて作成する前に知っておきたい',
      '<br />',
      '〈未経験向け〉ポートフォリオ作りの基礎講座',
      '<br />',
      '～実際の作成風景ものぞき見しよう～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0088_mv.png',
    eventDate: '2024.3.7(木) 開催',
    openingDate: '2024-03-07 19:00:00',
    deadlineDate: '2024-03-07 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「はじめて作成する前に知っておきたい　〈未経験向け〉ポートフォリオ作りの基礎講座　～実際の作成風景ものぞき見しよう～」を3/7（木）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2024-02-22 12:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0087',
    type: '',
    title: [
      'Webデザイナーのための',
      '<br />',
      '採用につながるポートフォリオ講座',
      '<br />',
      '～「自分のキャリア」を効果的にアピールしよう！～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0087_mv.jpg',
    eventDate: '2024.2.27(火) 開催',
    openingDate: '2024-02-27 19:30:00',
    deadlineDate: '2024-02-27 21:00:00',
    description: '皆さまのポートフォリオ作成への疑問に答えるべく、「 Webデザイナーのための採用につながるポートフォリオ講座 ～「自分のキャリア」を効果的にアピールしよう！～」を2/27（火）19:30～、オンラインで開催します。 どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそうポートフォリオ講座です。',
    releaseDate: '2024-02-01 17:00:00',
    performerType: 'Person',
    performerName: 'Webデザイナー/エンジニア 庄司 美雪',
  },
  {
    id: '0086',
    type: '',
    title: [
      '明日からデザイン実務に活かせるFigmaのイロハ'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0086_mv.jpg',
    eventDate: '2024.2.20(火) 開催',
    openingDate: '2024-02-20 19:30:00',
    deadlineDate: '2024-02-20 21:00:00',
    description: 'Webデザイナー向け  『明日からデザイン実務に活かせるFigmaのイロハ』を開催いたします。Figmaはなぜ選ばれるのかをデザイナー目線からお話いただき、デフォルトの機能やプラグインなども解説いただきます。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2024-02-01 17:00:00',
    performerType: 'Person',
    performerName: 'フリーランス Webクリエイター せきゆおう',
  },
  {
    id: '0085',
    type: '',
    title: [
      '自己PRとポートフォリオ作成について'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0085_mv.jpg',
    eventDate: '2024.2.15(木) 開催',
    openingDate: '2024-02-15 19:30:00',
    deadlineDate: '2024-02-15 21:00:00',
    description: 'Web業界で活躍するための転職活動対策に直接役に立つセミナーシリーズ「自己PRとポートフォリオ作成について」を2/15（木）に開催します！今回の対象職種はWebデザイナーとし、オンラインでの配信になります。是非ご参加ください！',
    releaseDate: '2024-01-25 12:00:00',
    performerType: 'Person',
    performerName: '株式会社Buzzreach UI/UX/Webデザイナー 木村 よねこ',
  },
  {
    id: '0084',
    type: '',
    title: [
      'はじめて作成する前に知っておきたい',
      '<br />',
      '〈未経験向け〉ポートフォリオ作りの基礎講座',
      '<br />',
      '～実際の作成風景ものぞき見しよう～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0084_mv.png',
    eventDate: '2024.2.7(水) 開催',
    openingDate: '2024-02-07 19:00:00',
    deadlineDate: '2024-02-07 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「はじめて作成する前に知っておきたい　〈未経験向け〉ポートフォリオ作りの基礎講座　～実際の作成風景ものぞき見しよう～」を2/7（水）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2024-01-25 12:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0083',
    type: '',
    title: [
      'デザインプロジェクトを円滑に進めるための',
      '<br />',
      'Figmaと外部ツールの活用法'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0083_mv.jpg',
    eventDate: '2024.1.25(木) 開催',
    openingDate: '2024-01-25 19:30:00',
    deadlineDate: '2024-01-25 21:00:00',
    description: 'Webデザイナー向け 『デザインプロジェクトを円滑に進めるためのFigmaと外部ツールの活用法』を開催いたします。FigmaとFigJamの各種プラグイン・ウィジェット・外部ツールを用いてコラボレーションを促進するTIPSを実演いただきます。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2023-12-26 12:00:00',
    performerType: 'Person',
    performerName: '日本ウェブデザイン株式会社 CXO兼UXデザイナー、クリエイターズ・シナジーカフェ共同運営者 タグチ マリコ',
  },
  {
    id: '0082',
    type: '',
    title: [
      'Webデザイナーとしてのキャリアパスの考え方と整理'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0082_mv.jpg',
    eventDate: '2024.1.18(木) 開催',
    openingDate: '2024-01-18 19:30:00',
    deadlineDate: '2024-01-18 21:00:00',
    description: 'Web業界で活躍するための転職活動対策に直接役に立つセミナーシリーズ「Webデザイナーとしてのキャリアパスの考え方と整理」を1/18（木）に開催します！今回の対象職種はWebデザイナーとし、オンラインでの配信になります。また、参加費は無料です。',
    releaseDate: '2023-12-26 12:00:00',
    performerType: 'Person',
    performerName: '株式会社Buzzreach UI/UX/Webデザイナー 木村 よねこ',
  },
  {
    id: '0081',
    type: '',
    title: [
      'Webデザイナーのための',
      '<br />',
      '採用につながるポートフォリオ講座',
      '<br />',
      '～「自分の強み」を伝えるためには～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0081_mv.jpg',
    eventDate: '2024.1.17(水) 開催',
    openingDate: '2024-01-17 19:30:00',
    deadlineDate: '2024-01-17 21:00:00',
    description: '皆さまのポートフォリオ作成への疑問に答えるべく、「Webデザイナーのための採用につながるポートフォリオ講座 ～「自分の強み」を伝えるためには～」を1/17（水）19:30～、オンラインで開催します。 どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそうポートフォリオ講座です。',
    releaseDate: '2023-12-26 12:00:00',
    performerType: 'Person',
    performerName: '株式会社StoreHero グロースパートナー フリーランスデザイナー 大石 慎平',
  },
  {
    id: '0080',
    type: '',
    title: [
      'はじめて作成する前に知っておきたい',
      '<br />',
      '〈未経験向け〉ポートフォリオ作りの基礎講座',
      '<br />',
      '～実際の作成風景ものぞき見しよう～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0080_mv.png',
    eventDate: '2024.1.16(火) 開催',
    openingDate: '2024-01-16 19:00:00',
    deadlineDate: '2024-01-16 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「はじめて作成する前に知っておきたい　〈未経験向け〉ポートフォリオ作りの基礎講座　～実際の作成風景ものぞき見しよう～」を1/16（火）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2023-12-19 12:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0079',
    type: '',
    title: [
      'WebデザイナーのためのUXデザイン講座 vol.4',
      '<br />',
      '～カスタマージャーニーマップ活用編～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0079_mv.png',
    eventDate: '2023.12.20(水) 開催',
    openingDate: '2023-12-20 19:30:00',
    deadlineDate: '2023-12-20 20:50:00',
    description: 'UXデザインに関心のあるWebデザイナー向けに「WebデザイナーのためのUXデザイン講座」を12/20（水）19:30～開催いたします。カスタマージャーニーマップやペルソナからデザインの具現化をしているのかを具体例を交えてお話いただきます。ご興味のある方は是非お早めにご参加ください。',
    releaseDate: '2023-12-01 12:00:00',
    performerType: 'Person',
    performerName: '株式会社TAM 取締役/プロジェクトマネージャー 三内 徹',
  },
  {
    id: '0078',
    type: '',
    title: [
      'WebデザイナーのためのUXデザイン講座 vol.3',
      '<br />',
      'Hello, UX! ～ユーザーと対話するデザイン～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0078_mv.png',
    eventDate: '2023.12.19(火) 開催',
    openingDate: '2023-12-19 19:30:00',
    deadlineDate: '2023-12-19 20:50:00',
    description: 'UXデザインに関心のあるWebデザイナー向けに「WebデザイナーのためのUXデザイン講座」を12/19（火）19:30～開催いたします。ユーザーを意識するデザインとは？やターゲットユーザー理解からどのようにデザインへ具現化しているのか、について実際具体例を交えてお話いただきます。ご興味のある方は是非お早めにご参加ください。',
    releaseDate: '2023-12-01 12:00:00',
    performerType: 'Person',
    performerName: '株式会社カオナビ デザイナー/プログラミングスクール講師 池田 萌',
  },
  {
    id: '0077',
    type: '',
    title: [
      '選考通過につながるポートフォリオ講座',
      '<br />',
      '～最新の転職市場とドキュメント作成術～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0077_mv.png',
    eventDate: '2023.12.14(木) 開催',
    openingDate: '2023-12-14 19:30:00',
    deadlineDate: '2023-12-14 21:00:00',
    description: '昨今のWeb業界の転職市場の動向を踏まえ、採用担当者にきちんとキャリアと経験が伝わるポートフォリオを作成するためのポイントをお伝えするセミナーを12/14（木）19:30～にオンライン開催いたします。',
    releaseDate: '2023-12-01 12:00:00',
    performerType: 'Person',
    performerName: '株式会社マイナビワークス マーケティング部 転職セミナー企画担当 南 文乃',
  },
  {
    id: '0076',
    type: '',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      '自分のキャリアを効果的にアピールするには',
      '<br />',
      '～実例紹介＆質問会～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0076_mv.png',
    eventDate: '2023.12.13(水) 開催',
    openingDate: '2023-12-13 20:00:00',
    deadlineDate: '2023-12-13 22:00:00',
    description: '皆さまのポートフォリオ作成への疑問に答えるべく、「MATCHBOX活用ワークショップ 自分のキャリアを効果的にアピールするには」を12/13（水）20:00～、オンラインで開催します。 どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそう内容のワークショップです。',
    releaseDate: '2023-12-01 12:00:00',
    performerType: 'Person',
    performerName: 'Webデザイナー/エンジニア 庄司 美雪',
  },
  {
    id: '0075',
    type: '',
    title: [
      'はじめて作成する前に知っておきたい',
      '<br />',
      '〈未経験向け〉ポートフォリオ作りの基礎講座',
      '<br />',
      '～実際の作成風景ものぞき見しよう～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0075_mv.png',
    eventDate: '2023.12.7(木) 開催',
    openingDate: '2023-12-07 19:00:00',
    deadlineDate: '2023-12-07 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「はじめて作成する前に知っておきたい　〈未経験向け〉ポートフォリオ作りの基礎講座　～実際の作成風景ものぞき見しよう～」を12/7（木）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2023-11-17 12:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0074',
    type: '',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      '「人となり」を伝える',
      '<br />',
      'ポートフォリオのGoodな記載ポイントとは',
      '<br />',
      '～実例紹介＆質問会～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0074_mv.png',
    eventDate: '2023.11.29(水) 開催',
    openingDate: '2023-11-29 20:00:00',
    deadlineDate: '2023-11-29 22:00:00',
    description: '皆さまのポートフォリオ作成への悩みに答えるべく、「MATCHBOX活用ワークショップ 「人となり」を伝える ポートフォリオのGoodな記載ポイントとは」11/29（水）20:00～、オンラインで開催します。 ポートフォリオに関するお悩みやご質問を承りますので、是非ご参加ください。',
    releaseDate: '2023-11-02 17:00:00',
    performerType: 'Person',
    performerName: 'Studio FireColor 小泉 誠',
  },
  {
    id: '0073',
    type: '',
    title: [
      'WebデザイナーのためのUXデザイン講座 vol.2'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0073_mv.jpg',
    eventDate: '2023.11.28(火) 開催',
    openingDate: '2023-11-28 19:30:00',
    deadlineDate: '2023-11-28 20:50:00',
    description: 'UXデザインに関心のあるWebデザイナー向けに「WebデザイナーのためのUXデザイン講座」を11/28（火）19:30～開催いたします。実際にどんな視点で、どんな手法を用いてデザインへ落とし込んでいるのか、具体例を交えてお話いただきます。ご興味のある方は是非お早めにご参加ください。',
    releaseDate: '2023-11-02 17:00:00',
    performerType: 'Person',
    performerName: 'SIMONE INC. エクスペリエンスデザイナー/UI・UX事業部 部長 中村 直人',
  },
  {
    id: '0072',
    type: '',
    title: [
      'WebデザイナーのためのUXデザイン講座 vol.1'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0072_mv.jpg',
    eventDate: '2023.11.22(水) 開催',
    openingDate: '2023-11-22 19:30:00',
    deadlineDate: '2023-11-22 20:50:00',
    description: 'UXデザインに関心のあるWebデザイナー向けに「WebデザイナーのためのUXデザイン講座」を11/22（水）19:30～開催いたします。実際にどんな視点で、どんな手法を用いてデザインへ落とし込んでいるのか、具体例を交えてお話いただきます。ご興味のある方は是非お早めにご参加ください。',
    releaseDate: '2023-11-02 17:00:00',
    performerType: 'Person',
    performerName: '株式会社IMAKE 代表取締役 DTP・Webデザイナー 濱野 将',
  },
  {
    id: '0071',
    type: '',
    title: [
      '自己PRとポートフォリオ作成について'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0071_mv.png',
    eventDate: '2023.11.15(水) 開催',
    openingDate: '2023-11-15 19:30:00',
    deadlineDate: '2023-11-15 21:00:00',
    description: 'Web業界で活躍するための転職活動対策に直接役に立つセミナーシリーズ「自己PRとポートフォリオ作成について」を11/15（水）に開催します！今回の対象職種はWebデザイナーとし、オンラインでの配信になります。是非ご参加ください！',
    releaseDate: '2023-11-02 17:00:00',
    performerType: 'Person',
    performerName: '株式会社Buzzreach UI/UX/Webデザイナー 木村 よねこ',
  },
  {
    id: '0070',
    type: '',
    title: [
      'はじめて作成する前に知っておきたい',
      '<br />',
      '〈未経験向け〉ポートフォリオ作りの基礎講座',
      '<br />',
      '～実際の作成風景ものぞき見しよう～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0070_mv.png',
    eventDate: '2023.11.8(水) 開催',
    openingDate: '2023-11-08 19:00:00',
    deadlineDate: '2023-11-08 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「はじめて作成する前に知っておきたい　〈未経験向け〉ポートフォリオ作りの基礎講座　～実際の作成風景ものぞき見しよう～」を11/8（水）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2023-10-26 17:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0069',
    type: '',
    title: [
      'Webデザイナー進化論 season2 UIデザイン制作編',
      '<br />',
      'UIデザイナーはこう使う！',
      '<br />',
      '実案件を用いて解説するFigma活用事例'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0069_mv.jpg',
    eventDate: '2023.10.26(木) 開催',
    openingDate: '2023-10-26 19:30:00',
    deadlineDate: '2023-10-26 20:50:00',
    description: 'Webデザイナー進化論 season2 UIデザイン制作編  『UIデザイナーはこう使う！実案件を用いて解説するFigma活用事例』を開催いたします。WebデザインやUIアプリデザインの作成を行う上でFigmaをどのように使用しているか、実案件のご紹介をいただきます。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2023-10-03 17:00:00',
    performerType: 'Person',
    performerName: '日本ウェブデザイン株式会社 CXO兼UXデザイナー、クリエイターズ・シナジーカフェ共同運営者 タグチ マリコ',
  },
  {
    id: '0068',
    type: '',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      '「自分らしさ」を伝えて差別化する',
      '<br />',
      'ポートフォリオ改善の秘訣',
      '<br className="md:hidden lg:hidden" />',
      '～実例紹介＆質問会～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0068_mv.png',
    eventDate: '2023.10.25(水) 開催',
    openingDate: '2023-10-25 20:00:00',
    deadlineDate: '2023-10-25 22:00:00',
    description: '皆さまのポートフォリオ作成への疑問に答えるべく、「MATCHBOX活用ワークショップ 「自分らしさ」を伝えて差別化するポートフォリオ改善の秘訣」を10/25（水）20:00～、オンラインで開催します。 どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそう内容のワークショップです。',
    releaseDate: '2023-10-03 09:00:00',
    performerType: 'Person',
    performerName: '株式会社StoreHero グロースパートナー フリーランスデザイナー 大石 慎平',
  },
  {
    id: '0067',
    type: '',
    title: [
      'Webデザイナー進化論 season2 UIデザイン制作編',
      '<br />',
      '実装者目線と合わせてみていく',
      '<br />',
      'Figmaのデザインデータのポイント'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0067_mv.jpg',
    eventDate: '2023.10.19(木) 開催',
    openingDate: '2023-10-19 19:30:00',
    deadlineDate: '2023-10-19 20:50:00',
    description: 'Webデザイナー進化論 season2 UIデザイン制作編  『実装者目線と合わせてみていくFigmaのデザインデータのポイント』を開催いたします。今さら聞けない『Figma』の基本的な使い方や実務データを見ながらのポイントを解説します。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2023-10-03 17:00:00',
    performerType: 'Person',
    performerName: 'Webデザイナー/エンジニア 庄司 美雪',
  },
  {
    id: '0066',
    type: '',
    title: [
      'Webデザイナーとしてのキャリアパスの考え方と整理'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0066_mv.png',
    eventDate: '2023.10.17(木) 開催',
    openingDate: '2023-10-17 19:30:00',
    deadlineDate: '2023-10-17 21:00:00',
    description: 'Web業界で活躍するための転職活動対策に直接役に立つセミナーシリーズ「Webデザイナーとしてのキャリアパスの考え方と整理」を10/17（木）に開催します！今回の対象職種はWebデザイナーとし、オンラインでの配信になります。また、参加費は無料です。',
    releaseDate: '2023-10-03 09:00:00',
    performerType: 'Person',
    performerName: '株式会社Buzzreach UI/UX/Webデザイナー 木村 よねこ',
  },
  {
    id: '0065',
    type: '',
    title: [
      'はじめて作成する前に知っておきたい',
      '<br />',
      '〈未経験向け〉ポートフォリオ作りの基礎講座',
      '<br />',
      '～実際の作成風景ものぞき見しよう～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0065_mv.png',
    eventDate: '2023.10.12(木) 開催',
    openingDate: '2023-10-12 19:00:00',
    deadlineDate: '2023-10-12 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「はじめて作成する前に知っておきたい　〈未経験向け〉ポートフォリオ作りの基礎講座　～実際の作成風景ものぞき見しよう～」を10/12（木）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2023-09-21 17:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
    },
  {
    id: '0064',
    type: '',
    title: [
      'Webデザイナーのためのスキルアップセミナー vol.6',
      '<br />',
      '〜Webデザイナー向け〜',
      '<br />',
      '数値とロジックによる仮説立案"はじめの一歩"'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0064_mv.jpg',
    eventDate: '2023.9.28(木) 開催',
    openingDate: '2023-09-28 19:30:00',
    deadlineDate: '2023-09-28 20:50:00',
    description: 'Webデザイナーのためのスキルアップセミナー vol.6『〜Webデザイナー向け〜 数値とロジックによる仮説立案"はじめの一歩"』を開催いたします。説得力のあるWebサイトのデザイン案が出来るには、どのようなツールや知識、スキルがあると良いかをお話しいただきます。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2023-09-04 17:00:00',
    performerType: 'Person',
    performerName: 'シララ株式会社 代表取締役 伊東 宏之',
  },
  {
    id: '0063',
    type: '',
    title: [
      'Webデザイナーのためのスキルアップセミナー vol.5',
      '<br />',
      'Webデザイナーとして押さえておくべき',
      '<br />',
      '顧客理解を重視したデザインFixedのプロセスとは'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0063_mv.jpg',
    eventDate: '2023.9.26(火) 開催',
    openingDate: '2023-09-26 19:30:00',
    deadlineDate: '2023-09-26 20:50:00',
    description: 'Webデザイナーのためのスキルアップセミナー vol.5 『Webデザイナーとして押さえておくべき 顧客理解を重視したデザインFixedのプロセスとは』を開催いたします。プロジェクトにおいて顧客とのデザイン面の合意形成を図る上でのデザイナーが行うワークフローや実践例をお伝えいただきます。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2023-09-04 17:00:00',
    performerType: 'Person',
    performerName: '株式会社ベイジ 執行役員／コンサルタント／ディレクター 今西 毅寿',
  },
  {
    id: '0062',
    type: '',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      'デザイナーのためのポートフォリオアドバイス会',
      '<br />',
      '～ブラッシュアップポイント公開します～',
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0062_mv.png',
    eventDate: '2023.9.21(木) 開催',
    openingDate: '2023-09-21 20:00:00',
    deadlineDate: '2023-09-21 22:00:00',
    description: '皆さまのポートフォリオ作成への悩みに答えるべく、「MATCHBOX活用ワークショップ デザイナーのためのポートフォリオアドバイス会 ～ブラッシュアップポイント公開します～」を9/21（木）20:00～、オンラインで開催します。どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそう内容のワークショップです。',
    releaseDate: '2023-09-04 17:00:00',
    performerType: 'Person',
    performerName: '株式会社VOWL 代表取締役 Thinka Studio株式会社 代表取締役 林 航平',
  },
  {
    id: '0061',
    type: '',
    title: [
      '選考通過につながるポートフォリオ講座',
      '<br />',
      '～最新の転職市場とドキュメント作成術～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0061_mv.png',
    eventDate: '2023.9.20(水) 開催',
    openingDate: '2023-09-20 19:30:00',
    deadlineDate: '2023-09-20 21:00:00',
    description: '昨今のWeb業界の転職市場の動向を踏まえ、採用担当者にきちんとキャリアと経験が伝わるポートフォリオを作成するためのポイントをお伝えするセミナーを9/20（水）19:30～にオンライン開催いたします。',
    releaseDate: '2023-09-04 17:00:00',
    performerType: 'Person',
    performerName: '株式会社マイナビワークス マーケティング部 転職セミナー企画担当 南 文乃',
  },
  {
    id: '0060',
    type: '',
    title: [
      'MATCHBOXもくもく会',
      '<br />',
      '履歴書、職務経歴書、ポートフォリオ作りの',
      '<br />',
      '不安を解消！その場で相談・添削付き'
    ],
    target: 'Web/IT/ゲームなどのクリエイティブ業界での転職を希望される方',
    img: '../images/Top/event/event_0060_mv.png',
    eventDate: '2023.9.14(木) 開催',
    openingDate: '2023-09-14 19:00:00',
    deadlineDate: '2023-09-14 21:00:00',
    description: '転職書類作成の目的でオンライン上に集い、もくもくと進めていただく時間とするイベントになります。作成相談や書類添削もその場で承ります。転職準備のためのまとまった時間を是非今回のMATCHBOXもくもく会でお過ごしください。',
    releaseDate: '2023-08-24 17:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0059',
    type: '',
    title: [
      'はじめて作成する前に知っておきたい',
      '<br />',
      '〈未経験向け〉ポートフォリオ作りの基礎講座',
      '<br />',
      '～実際の作成風景ものぞき見しよう～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0059_mv.png',
    eventDate: '2023.9.7(木) 開催',
    openingDate: '2023-09-07 19:00:00',
    deadlineDate: '2023-09-07 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「はじめて作成する前に知っておきたい　〈未経験向け〉ポートフォリオ作りの基礎講座　～実際の作成風景ものぞき見しよう～」を9/7（木）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2023-08-24 17:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0058',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      '「人となり」を伝える',
      '<br />',
      'ポートフォリオのGoodな記載ポイントとは',
      '<br />',
      '～実例紹介＆質問会～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0058_mv.png',
    eventDate: '2023.8.30(水) 開催',
    openingDate: '2023-08-30 20:00:00',
    deadlineDate: '2023-08-30 22:00:00',
    description: '皆さまのポートフォリオ作成への悩みに答えるべく、「MATCHBOX活用ワークショップ 「人となり」を伝える ポートフォリオのGoodな記載ポイントとは」を8/30（水）20:00～、オンラインで開催します。 ポートフォリオに関するお悩みやご質問を承りますので、是非ご参加ください。',
    releaseDate: '2023-08-04 17:00:00',
    performerType: 'Person',
    performerName: 'Studio FireColor 小泉 誠',
  },
  {
    id: '0057',
    title: [
      '自己PRとポートフォリオ作成について'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0057_mv.png',
    eventDate: '2023.8.29(火) 開催',
    openingDate: '2023-08-29 19:30:00',
    deadlineDate: '2023-08-29 21:00:00',
    description: 'Web業界で活躍するための転職活動対策に直接役に立つセミナーシリーズ「自己PRとポートフォリオ作成について」を8/29（火）に開催します！今回の対象職種はWebデザイナーとし、オンラインでの配信になります。是非ご参加ください！',
    releaseDate: '2023-08-04 17:00:00',
    performerType: 'Person',
    performerName: '株式会社Buzzreach UI/UX/Webデザイナー 木村 よねこ',
  },
  {
    id: '0056',
    title: [
      'Webデザイナーのためのスキルアップセミナー vol.4',
      '<br />',
      '～Webデザイナーの未来を切り拓く～',
      '<br />',
      '制作フロー最適化とエンジニアとの円滑な連携'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0056_mv.jpg',
    eventDate: '2023.8.24(木) 開催',
    openingDate: '2023-08-24 19:30:00',
    deadlineDate: '2023-08-24 20:50:00',
    description: 'Webデザイナーのためのスキルアップセミナー vol.4 、『～Webデザイナーの未来を切り拓く～ 制作フロー最適化とエンジニアとの円滑な連携』を開催いたします。デザイナーとエンジニアの連携ポイントについて具体的なツールを交えながら、エンジニアの視点からデザイナーに求めるスキルについてお話しいただきます。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2023-08-04 17:00:00',
    performerType: 'Person',
    performerName: '株式会社ジュニ リードエンジニア 諸橋 大輔',
  },
  {
    id: '0055',
    title: [
      'Webデザイナーのためのスキルアップセミナー vol.3',
      '<br />',
      'デザイナー領域を横断し活躍するための',
      '<br />',
      'プロジェクト全体のプロセス思考'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0055_mv.jpg',
    eventDate: '2023.8.22(火) 開催',
    openingDate: '2023-08-22 19:30:00',
    deadlineDate: '2023-08-22 20:50:00',
    description: 'Webデザイナーのためのスキルアップセミナー vol.3 『デザイナー領域を横断し活躍するためのプロジェクト全体のプロセス思考』を開催いたします。デザイナーとしておさえておくべきディレクションスキルのポイントを、ディレクターの視点お話しいただきます。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2023-08-04 17:00:00',
    performerType: 'Person',
    performerName: '株式会社ENTER THE POCKET Webディレクター兼クリエイター ジェニー',
  },
  {
    id: '0054',
    title: [
      'MATCHBOXもくもく会',
      '<br />',
      '履歴書、職務経歴書、ポートフォリオ作りの',
      '<br />',
      '不安を解消！その場で相談・添削付き'
    ],
    target: 'Web/IT/ゲームなどのクリエイティブ業界での転職を希望される方',
    img: '../images/Top/event/event_0054_mv.png',
    eventDate: '2023.8.10(木) 開催',
    openingDate: '2023-08-10 19:00:00',
    deadlineDate: '2023-08-10 21:00:00',
    description: '転職書類作成の目的でオンライン上に集い、もくもくと進めていただく時間とするイベントになります。作成相談や書類添削もその場で承ります。転職準備のためのまとまった時間を是非今回のMATCHBOXもくもく会でお過ごしください。',
    releaseDate: '2023-07-21 15:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0053',
    title: [
      'はじめて作成する前に知っておきたい',
      '<br />',
      '〈未経験向け〉ポートフォリオ作りの基礎講座',
      '<br />',
      '～実際の作成風景ものぞき見しよう～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0053_mv.png',
    eventDate: '2023.8.3(木) 開催',
    openingDate: '2023-08-03 19:00:00',
    deadlineDate: '2023-08-03 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「はじめて作成する前に知っておきたい　〈未経験向け〉ポートフォリオ作りの基礎講座　～実際の作成風景ものぞき見しよう～」を8/3（木）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2023-07-21 15:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0052',
    title: [
      'Webデザイナーとしてのキャリアパスの考え方と整理'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0052_mv.jpg',
    eventDate: '2023.7.27(木) 開催',
    openingDate: '2023-07-27 19:30:00',
    deadlineDate: '2023-07-27 21:00:00',
    description: 'Web業界で活躍するための転職活動対策に直接役に立つセミナーシリーズ「Webデザイナーとしてのキャリアパスの考え方と整理」を7/27（木）に開催します！今回の対象職種はWebデザイナーとし、オンラインでの配信になります。また、参加費は無料です。',
    releaseDate: '2023-07-07 15:00:00',
    performerType: 'Person',
    performerName: '株式会社Buzzreach UI/UX/Webデザイナー 木村 よねこ',
  },
  {
    id: '0051',
    title: [
      'Webデザイナーのためのスキルアップセミナー vol.2',
      '<br />',
      'WebデザイナーのためのUXスキル入門：',
      '<br />',
      'プロセス理解'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0051_mv.png',
    eventDate: '2023.7.26(水) 開催',
    openingDate: '2023-07-26 19:30:00',
    deadlineDate: '2023-07-26 20:50:00',
    description: 'Webデザイナーのためのスキルアップセミナー vol.2 、UI/UXデザイナー / 情報デザイナーとして活躍中の川崎 沙織氏をお招きし、『WebデザイナーのためのUXスキル入門：プロセス理解』を開催いたします。より納得感のあるものを作るため、ユーザーからの見え方を考えた企画や制作についてUX的視点でどういう観点やプロセスを持てばいいのかについてお話しいただきます。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2023-07-07 15:00:00',
    performerType: 'Person',
    performerName: 'UI/UXデザイナー/情報デザイナー 川﨑 沙織',
  },
  {
    id: '0050',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      '自分のキャリアを効果的にアピールするには',
      '<br />',
      '～実例紹介＆質問会～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0050_mv.jpg',
    eventDate: '2023.7.20(木) 開催',
    openingDate: '2023-07-20 20:00:00',
    deadlineDate: '2023-07-20 22:00:00',
    description: '皆さまのポートフォリオ作成への疑問に答えるべく、「MATCHBOX活用ワークショップ 自分のキャリアを効果的にアピールするには」を7/20（木）20:00～、オンラインで開催します。 どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそう内容のワークショップです。',
    releaseDate: '2023-07-07 15:00:00',
    performerType: 'Person',
    performerName: 'Webデザイナー/エンジニア 庄司 美雪',
  },
  {
    id: '0049',
    title: [
      'Webデザイナーのためのスキルアップセミナー vol.1',
      '<br />',
      'ユーザーの反応を発見するプロトタイピング'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0049_mv.png',
    eventDate: '2023.7.19(水) 開催',
    openingDate: '2023-07-19 19:30:00',
    deadlineDate: '2023-07-19 20:50:00',
    description: 'Webデザイナーのためのスキルアップセミナー vol.1 、株式会社マネーフォワード HOME本部 事業開発部 プロダクトマネージャー 佐藤慎悟氏をお招きし、『ユーザーの反応を発見するプロトタイピング』を開催いたします。ユーザーニーズをどのように落とし込んでより価値を提供するためのデザインを作成するのか、プロトタイピング作成を軸にお話しいただきます。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2023-07-07 15:00:00',
    performerType: 'Person',
    performerName: '株式会社マネーフォワード プロダクトマネージャー 佐藤 慎悟',
  },
  {
    id: '0048',
    title: [
      'MATCHBOXもくもく会',
      '<br />',
      '履歴書、職務経歴書、ポートフォリオ作りの',
      '<br />',
      '不安を解消！その場で相談・添削付き'
    ],
    target: 'Web/IT/ゲームなどのクリエイティブ業界での転職を希望される方',
    img: '../images/Top/event/event_0048_mv.png',
    eventDate: '2023.7.13(木) 開催',
    openingDate: '2023-07-13 19:00:00',
    deadlineDate: '2023-07-13 21:00:00',
    description: '転職書類作成の目的でオンライン上に集い、もくもくと進めていただく時間とするイベントになります。作成相談や書類添削もその場で承ります。転職準備のためのまとまった時間を是非今回のMATCHBOXもくもく会でお過ごしください。',
    releaseDate: '2023-06-26 19:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0047',
    title: [
      'はじめて作成する前に知っておきたい',
      '<br />',
      '〈未経験向け〉ポートフォリオ作りの基礎講座',
      '<br />',
      '～実際の作成風景ものぞき見しよう～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0047_mv.png',
    eventDate: '2023.7.4(火) 開催',
    openingDate: '2023-07-04 19:00:00',
    deadlineDate: '2023-07-04 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「はじめて作成する前に知っておきたい　〈未経験向け〉ポートフォリオ作りの基礎講座　～実際の作成風景ものぞき見しよう～」を7/4（火）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2023-06-26 19:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0046',
    title: [
      '選考通過につながるポートフォリオ講座',
      '<br />',
      '～最新の転職市場とドキュメント作成術～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0046_mv.jpg',
    eventDate: '2023.6.28(水) 開催',
    openingDate: '2023-06-28 19:30:00',
    deadlineDate: '2023-06-28 21:00:00',
    description: '昨今のWeb業界の転職市場の動向を踏まえ、採用担当者にきちんとキャリアと経験が伝わるポートフォリオを作成するためのポイントをお伝えするセミナーを6/28（水）19:30～にオンライン開催いたします。',
    releaseDate: '2023-06-01 13:00:00',
    performerType: 'Person',
    performerName: '株式会社マイナビワークス マーケティング部 転職セミナー企画担当 南 文乃',
  },
  {
    id: '0045',
    title: [
      'Webデザイナー進化論 season1 UIスタート編 vol.6',
      '<br />',
      'UI設計を円滑に進めるための',
      '<br />',
      'Figmaテクニック'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0045_mv.png',
    eventDate: '2023.6.27(火) 開催',
    openingDate: '2023-06-27 20:00:00',
    deadlineDate: '2023-06-27 21:20:00',
    description: 'クリエイターズ・シナジーカフェとマッチボックスのコラボイベント企画vol.6！『UI設計を円滑に進めるためのFigmaテクニック』を開催いたします。Figmaの使い方の具体例をセッション形式でお話いただきます。UIデザイナーに興味がある方は是非ご参加ください！',
    releaseDate: '2023-06-01 13:00:00',
    performerType: 'PerformingGroup',
    performerName: 'クリエイターズ・シナジーカフェ タグチ マリコ・せきゆおう',
  },
  {
    id: '0044',
    title: [
      'Webデザイナー進化論 season1 UIスタート編 vol.5',
      '<br />',
      'UIデザインFigma実践（Live）'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0044_mv.png',
    eventDate: '2023.6.22(木) 開催',
    openingDate: '2023-06-22 20:00:00',
    deadlineDate: '2023-06-22 21:20:00',
    description: 'クリエイターズ・シナジーカフェとマッチボックスのコラボイベント企画vol.5！『UIデザインFigma実践（Live）』を開催いたします。Figmaを使ったUI作成の様子をライブでお届けします。是非ご参加ください！',
    releaseDate: '2023-06-01 13:00:00',
    performerType: 'Person',
    performerName: 'クリエイターズ・シナジーカフェ タグチ マリコ',
  },
  {
    id: '0043',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      '「自分らしさ」を伝えて差別化する',
      '<br />',
      'ポートフォリオ改善の秘訣',
      '<br className="md:hidden lg:hidden" />',
      '～実例紹介＆質問会～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0043_mv.jpg',
    eventDate: '2023.6.21(水) 開催',
    openingDate: '2023-06-21 20:00:00',
    deadlineDate: '2023-06-21 22:00:00',
    description: '皆さまのポートフォリオ作成への疑問に答えるべく、「MATCHBOX活用ワークショップ 「自分らしさ」を伝えて差別化するポートフォリオ改善の秘訣」を6/21（水）20:00～、オンラインで開催します。どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそう内容のワークショップです。',
    releaseDate: '2023-06-01 13:00:00',
    performerType: 'Person',
    performerName: '株式会社StoreHero グロースパートナー フリーランスデザイナー 大石 慎平',
  },
  {
    id: '0042',
    title: [
      'MATCHBOXもくもく会',
      '<br />',
      '履歴書、職務経歴書、ポートフォリオ作りの',
      '<br />',
      '不安を解消！その場で相談・添削付き'
    ],
    target: 'Web/IT/ゲームなどのクリエイティブ業界での転職を希望される方',
    img: '../images/Top/event/event_0042_mv.png',
    eventDate: '2023.6.15(木) 開催',
    openingDate: '2023-06-15 19:00:00',
    deadlineDate: '2023-06-15 21:00:00',
    description: '転職書類作成の目的でオンライン上に集い、もくもくと進めていただく時間とするイベントになります。作成相談や書類添削もその場で承ります。転職準備のためのまとまった時間を是非今回のMATCHBOXもくもく会でお過ごしください。',
    releaseDate: '2023-05-25 19:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0041',
    title: [
      'はじめて作成する前に知っておきたい',
      '<br />',
      '〈未経験向け〉ポートフォリオ作りの基礎講座',
      '<br />',
      '～実際の作成風景ものぞき見しよう～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0041_mv.png',
    eventDate: '2023.6.8(木) 開催',
    openingDate: '2023-06-08 19:00:00',
    deadlineDate: '2023-06-08 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「はじめて作成する前に知っておきたい　〈未経験向け〉ポートフォリオ作りの基礎講座　～実際の作成風景ものぞき見しよう～」を6/8（木）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2023-05-25 19:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0040',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      'デザイナーのためのポートフォリオアドバイス会',
      '<br />',
      '～ブラッシュアップポイント公開します～',
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0040_mv.jpg',
    eventDate: '2023.5.30(火) 開催',
    openingDate: '2023-05-30 20:00:00',
    deadlineDate: '2023-05-30 22:00:00',
    description: '皆さまのポートフォリオ作成への悩みに答えるべく、「MATCHBOX活用ワークショップ デザイナーのためのポートフォリオアドバイス会 ～ブラッシュアップポイント公開します～」を5/30（火）20:00～、オンラインで開催します。どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそう内容のワークショップです。',
    releaseDate: '2023-04-28 11:00:00',
    performerType: 'Person',
    performerName: '株式会社VOWL 代表取締役 Thinka Studio株式会社 代表取締役 林 航平',
  },
  {
    id: '0039',
    title: [
      '自己PRとポートフォリオ作成について'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0039_mv.jpg',
    eventDate: '2023.5.25(木) 開催',
    openingDate: '2023-05-25 19:30:00',
    deadlineDate: '2023-05-25 21:00:00',
    description: 'Web業界で活躍するための転職活動対策に直接役に立つセミナーシリーズ「自己PRとポートフォリオ作成について」を5/25（木）に開催します！今回の対象職種はWebデザイナーとし、オンラインでの配信になります。また、参加費は無料です。',
    releaseDate: '2023-04-28 11:00:00',
    performerType: 'Person',
    performerName: '株式会社Buzzreach UI/UX/Webデザイナー 木村 よねこ',
  },
  {
    id: '0038',
    title: [
      'Webデザイナー進化論 season1 UIスタート編 vol.4',
      '<br />',
      'ユーザーニーズの探求方法と',
      '<br />',
      'デザイナーに必要な思考とは'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0038_mv.png',
    eventDate: '2023.5.24(水) 開催',
    openingDate: '2023-05-24 20:00:00',
    deadlineDate: '2023-05-24 21:20:00',
    description: 'クリエイターズ・シナジーカフェとマッチボックスのコラボイベント企画vol.4！『ユーザーニーズの探求方法とデザイナーに必要な思考とは』を開催いたします。ユーザー視点をUI設計に取り入れる具体例をセッション形式でお話いただきます。UIデザイナーに興味がある方は是非ご参加ください！',
    releaseDate: '2023-04-28 11:00:00',
    performerType: 'PerformingGroup',
    performerName: 'クリエイターズ・シナジーカフェ タグチ マリコ・せきゆおう',
  },
  {
    id: '0037',
    title: [
      'Webデザイナー進化論 season1 UIスタート編 vol.3',
      '<br />',
      'ユーザーと真に向き合い',
      '<br />',
      'UI設計するためのキーポイント'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0037_mv.png',
    eventDate: '2023.5.18(木) 開催',
    openingDate: '2023-05-18 20:00:00',
    deadlineDate: '2023-05-18 21:20:00',
    description: 'クリエイターズ・シナジーカフェとマッチボックスのコラボイベント企画vol.3！『ユーザーと真に向き合いUI設計するためのキーポイント』を開催いたします。ユーザー視点をUI設計に反映するための全体像をお話いただきます。是非ご参加ください！',
    releaseDate: '2023-04-28 11:00:00',
    performerType: 'Person',
    performerName: 'クリエイターズ・シナジーカフェ タグチ マリコ',
  },
  {
    id: '0036',
    title: [
      'MATCHBOXもくもく会',
      '<br />',
      '履歴書、職務経歴書、ポートフォリオ作りの',
      '<br />',
      '不安を解消！その場で相談・添削付き'
    ],
    target: 'Web/IT/ゲームなどのクリエイティブ業界での転職を希望される方',
    img: '../images/Top/event/event_0036_mv.png',
    eventDate: '2023.5.16(火) 開催',
    openingDate: '2023-05-16 18:00:00',
    deadlineDate: '2023-05-16 21:00:00',
    description: '転職書類作成の目的でオンライン上に集い、もくもくと進めていただく時間とするイベントになります。作成相談や書類添削もその場で承ります。転職準備のためのまとまった時間を是非今回のMATCHBOXもくもく会でお過ごしください。',
    releaseDate: '2023-04-24 13:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0035',
    title: [
      'はじめて作成する前に知っておきたい',
      '<br />',
      '〈未経験向け〉ポートフォリオ作りの基礎講座',
      '<br />',
      '～実際の作成風景ものぞき見しよう～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0035_mv.png',
    eventDate: '2023.5.11(木) 開催',
    openingDate: '2023-05-11 19:00:00',
    deadlineDate: '2023-05-11 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「はじめて作成する前に知っておきたい　〈未経験向け〉ポートフォリオ作りの基礎講座　～実際の作成風景ものぞき見しよう～」を5/11（木）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2023-04-24 13:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0034',
    title: [
      'Webデザイナー進化論 season1 UIスタート編 vol.2',
      '<br />',
      '～UIをロジックで紐解く～',
      '<br />',
      '実例から見るWebデザイン設計で',
      '<br />',
      '意識すべきUIの基本とは？'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0034_mv.png',
    eventDate: '2023.4.26(水) 開催',
    openingDate: '2023-04-26 20:00:00',
    deadlineDate: '2023-04-26 21:20:00',
    description: 'クリエイターズ・シナジーカフェとマッチボックスのコラボイベント企画vol.2！『～UIをロジックで紐解く～実例から見るWebデザイン設計で意識すべきUIの基本とは？』を開催いたします。ユーザー意識を起点にデザインをどう掘り下げていくか、セッション形式でお話いただきます。UIデザイナーに興味がある方は是非ご参加ください！',
    releaseDate: '2023-03-31 15:00:00',
    performerType: 'PerformingGroup',
    performerName: 'クリエイターズ・シナジーカフェ タグチ マリコ・せきゆおう',
  },
  {
    id: '0033',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      '自分のキャリアを効果的にアピールするには'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0033_mv.jpg',
    eventDate: '2023.4.25(火) 開催',
    openingDate: '2023-04-25 20:00:00',
    deadlineDate: '2023-04-25 22:00:00',
    description: '皆さまのポートフォリオ作成への疑問に答えるべく、「MATCHBOX活用ワークショップ 自分のキャリアを効果的にアピールするには」を4/25（火）20:00～、オンラインで開催します。どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそう内容のワークショップです。',
    releaseDate: '2023-03-31 15:00:00',
    performerType: 'Person',
    performerName: 'Webデザイナー/エンジニア 庄司 美雪',
  },
  {
    id: '0032',
    title: [
      'Webデザイナーとしてのキャリアパスの考え方と整理'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0032_mv.jpg',
    eventDate: '2023.4.20(木) 開催',
    openingDate: '2023-04-20 19:30:00',
    deadlineDate: '2023-04-20 21:00:00',
    description: 'eb業界で活躍するための転職活動対策に直接役に立つセミナーシリーズ「Webデザイナーとしてのキャリアパスの考え方と整理」を4/20（木）に開催します！今回の対象職種はWebデザイナーとし、オンラインでの配信になります。また、参加費は無料です。',
    releaseDate: '2023-03-31 15:00:00',
    performerType: 'Person',
    performerName: '株式会社Buzzreach UI/UX/Webデザイナー 木村 よねこ',
  },
  {
    id: '0031',
    title: [
      'Webデザイナー進化論 season1 UIスタート編 vol.1',
      '<br />',
      'UIデザイナーへのロードマップ',
      '<br />',
      '～どうしたらUIデザイナーと名乗れるか？～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0031_mv.png',
    eventDate: '2023.4.18(火) 開催',
    openingDate: '2023-04-18 20:00:00',
    deadlineDate: '2023-04-18 21:20:00',
    description: 'この度、様々なクリエイターの交流起点となっているクリエイターズ・シナジーカフェとコラボ！vol.1『UIデザイナーへのロードマップ　～どうしたらUIデザイナーと名乗れるか？～』としてUIの考え方をWebデザイン作成と比較してお伝えいたします。どのような知識・スキルが必要なのかを、UIデザインの基礎とともにお話いただきます。是非ご参加ください！',
    releaseDate: '2023-03-31 15:00:00',
    performerType: 'Person',
    performerName: 'クリエイターズ・シナジーカフェ タグチ マリコ',
  },
  {
    id: '0030',
    title: [
      'MATCHBOXもくもく会',
      '<br />',
      '履歴書、職務経歴書、ポートフォリオ作りの',
      '<br />',
      '不安を解消！その場で相談・添削付き'
    ],
    target: 'Web/IT/ゲームなどのクリエイティブ業界での転職を希望される方',
    img: '../images/Top/event/event_0030_mv.png',
    eventDate: '2023.4.11(火) 開催',
    openingDate: '2023-04-11 18:00:00',
    deadlineDate: '2023-04-11 21:00:00',
    description: '転職書類作成の目的でオンライン上に集い、もくもくと進めていただく時間とするイベントになります。作成相談や書類添削もその場で承ります。転職準備のためのまとまった時間を是非今回のMATCHBOXもくもく会でお過ごしください。',
    releaseDate: '2023-03-27 15:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0029',
    title: [
      'はじめて作成する前に知っておきたい',
      '<br />',
      '〈未経験向け〉ポートフォリオ作りの基礎講座',
      '<br />',
      '～実際の作成風景ものぞき見しよう～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0029_mv.png',
    eventDate: '2023.4.6(木) 開催',
    openingDate: '2023-04-06 19:00:00',
    deadlineDate: '2023-04-06 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「はじめて作成する前に知っておきたい　〈未経験向け〉ポートフォリオ作りの基礎講座　～実際の作成風景ものぞき見しよう～」を4/6（木）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2023-03-27 15:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0028',
    title: [
      '選考通過につながるポートフォリオ講座',
      '<br />',
      '～最新の転職市場とドキュメント作成術～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0028_mv.jpg',
    eventDate: '2023.3.28(火) 開催',
    openingDate: '2023-03-28 19:30:00',
    deadlineDate: '2023-03-28 21:00:00',
    description: '昨今のWeb業界の転職市場の動向を踏まえ、採用担当者にきちんとキャリアと経験が伝わるポートフォリオを作成するためのポイントをお伝えするセミナーを3/28（火）19:30～にオンライン開催いたします。',
    releaseDate: '2023-03-01 15:00:00',
    performerType: 'Person',
    performerName: '株式会社マイナビワークス マーケティング部 転職セミナー企画担当 南 文乃',
  },
  {
    id: '0027',
    title: [
      'ブランディングから始まるUI・UXデザインとは'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0027_mv.jpg',
    eventDate: '2023.3.23(木) 開催',
    openingDate: '2023-03-23 20:00:00',
    deadlineDate: '2023-03-23 21:20:00',
    description: '今後のキャリアについて悩まれているWebデザイナーの皆さま向けに、現在業界で活躍されているデザイナーのキャリア遍歴を知っていただくためのセミナーです。今回は株式会社SIMONE エクスペリエンスデザイナーの中村直人氏をお迎えして「ブランディングから始まるUI・UXデザインとは」を3/23（木）20:00～オンラインにて開催します。ご興味のある方はぜひご参加ください！',
    releaseDate: '2023-03-01 15:00:00',
    performerType: 'Person',
    performerName: 'SIMONE INC. エクスペリエンスデザイナー/UI・UX事業部 部長 中村 直人',
  },
  {
    id: '0026',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      'スキルだけじゃない！？',
      '<br />',
      '評価する側がGoodする記載ポイントとは'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0026_mv.png',
    eventDate: '2023.3.22(水) 開催',
    openingDate: '2023-03-22 20:00:00',
    deadlineDate: '2023-03-22 22:00:00',
    description: '皆さまのポートフォリオ作成への悩みに答えるべく、「MATCHBOX活用ワークショップ スキルだけじゃない！？ 評価する側がGoodする記載ポイントとは」を3/22（水）20:00～、オンラインで開催します。どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそう内容のワークショップです。',
    releaseDate: '2023-03-01 15:00:00',
    performerType: 'Person',
    performerName: 'Studio FireColor 小泉 誠',
  },
  {
    id: '0025',
    title: [
      '現役デザイナーがポイントを解説',
      '<br />',
      '転職のための実例ポートフォリオレビュー会'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0025_mv.jpg',
    eventDate: '2023.3.16(木) 開催',
    openingDate: '2023-03-16 20:00:00',
    deadlineDate: '2023-03-16 21:00:00',
    description: 'この度「- 現役デザイナーがポイントを解説　転職のための実例ポートフォリオレビュー会」を3/16（木）20:00~をオンラインにて開催します。実際のポートフォリオを用いて採用目線からレビューを行います。他の方が作成したポートフォリオを見ることができる機会にもなりますので、是非ご参加ください。',
    releaseDate: '2023-03-01 15:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社ディーゼロ Webデザイナー 新津 圭祐・株式会社LIFULL アートディレクター 田中 忍',
  },
  {
    id: '0024',
    title: [
      'はじめて作成する前に知っておきたい',
      '<br />',
      '〈未経験向け〉ポートフォリオ作りの基礎講座',
      '<br />',
      '～実際の作成風景ものぞき見しよう～'
    ],
    target: 'Webデザイナー , 未経験の方',
    img: '../images/Top/event/event_0024_mv.png',
    eventDate: '2023.3.15(水) 開催',
    openingDate: '2023-03-15 19:00:00',
    deadlineDate: '2023-03-15 20:00:00',
    description: '転職のためのポートフォリオ作成にお悩みのWebデザイナー向けに「はじめて作成する前に知っておきたい　〈未経験向け〉ポートフォリオ作りの基礎講座　～実際の作成風景ものぞき見しよう～」を3/15（水）19:00～、オンラインで開催します。書類通過することにフォーカスした押さえておきたいポイントをお伝えしますのでぜひご参加ください。',
    releaseDate: '2023-03-01 15:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0023',
    title: [
      'MATCHBOXもくもく会',
      '<br />',
      '履歴書、職務経歴書、ポートフォリオ作りの',
      '<br />',
      '不安を解消！その場で相談・添削付き'
    ],
    target: 'Web/IT/ゲームなどのクリエイティブ業界での転職を希望される方',
    img: '../images/Top/event/event_0023_mv.png',
    eventDate: '2023.3.9(木) 開催',
    openingDate: '2023-03-09 18:00:00',
    deadlineDate: '2023-03-09 21:00:00',
    description: '転職書類作成の目的でオンライン上に集い、もくもくと進めていただく時間とするイベントになります。作成相談や書類添削もその場で承ります。転職準備のためのまとまった時間を是非今回のMATCHBOXもくもく会でお過ごしください。',
    releaseDate: '2023-02-20 12:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0022',
    title: [
      '幅広い経験が生んだデザインのアプローチ',
      '<br />',
      '― キャリアの変遷から学んだ課題解決の手法 ―'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0022_mv.jpg',
    eventDate: '2023.2.22(水) 開催',
    openingDate: '2023-02-22 20:00:00',
    deadlineDate: '2023-02-22 21:20:00',
    description: '今後のキャリア形成を考えているWebデザイナーの皆さま向けに、現役のWebデザイナーとして活躍されている株式会社ROUTE06 プロフェッショナルサービス本部の熊野亜由美さん、菅原直也をお迎えして「幅広い経験が生んだデザインのアプローチ - キャリアの変遷から学んだ課題解決の手法 -」を2/22（水）20:00～オンラインにて開催します。ご興味のある方はぜひお早めにご応募ください！',
    releaseDate: '2023-01-27 15:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社ROUTE06 プロフェッショナルサービス本部 熊野 亜由美・菅原 直也',
  },
  {
    id: '0021',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      '自分のキャリアを効果的にアピールするには'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0021_mv.png',
    eventDate: '2023.2.21(火) 開催',
    openingDate: '2023-02-21 20:00:00',
    deadlineDate: '2023-02-21 22:00:00',
    description: '皆さまのポートフォリオ作成への疑問に答えるべく、「MATCHBOX活用ワークショップ 自分のキャリアを効果的にアピールするには」を2/21（火）20:00～、オンラインで開催します。どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそう内容のワークショップです。',
    releaseDate: '2023-01-27 15:00:00',
    performerType: 'Person',
    performerName: 'Webデザイナー/エンジニア 庄司 美雪',
  },
  {
    id: '0020',
    title: [
      'MATCHBOX活用中の採用責任者が初めて明かす',
      '<br />',
      '応募者と企業の出会いがはかどるポートフォリオ術'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0020_mv.jpg',
    eventDate: '2023.2.16(木) 開催',
    openingDate: '2023-02-16 19:30:00',
    deadlineDate: '2023-02-16 21:00:00',
    description: '企業側が書類上から知りたいポイントを端的に言語化し、選考通過率を上げるための効果的なポートフォリオ作成術をお伝えする「MATCHBOX活用中の採用責任者が初めて明かす応募者と企業の出会いがはかどるポートフォリオ術」を2/16（木）19:30～オンラインにて開催します。ぜひお早めにご応募ください！',
    releaseDate: '2023-01-27 15:00:00',
    performerType: 'Person',
    performerName: '株式会社デパート Corporatedesign div.課長 2級キャリア・コンサルティング技能士 岸本 佳奈',
  },
  {
    id: '0019',
    title: [
      '現役デザイナーが斬る！',
      '<br />',
      'ポートフォリオのPRポイント リアル添削ライブ'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0019_mv.png',
    eventDate: '2023.2.15(水) 開催',
    openingDate: '2023-02-15 20:00:00',
    deadlineDate: '2023-02-15 21:20:00',
    description: 'ポートフォリオを作成するときおさえるべきポイントを現役デザイナーが解説する「現役デザイナーが斬る！ポートフォリオのPRポイント リアル添削ライブ」を2/15（水）20:00~をオンラインにて開催します。この機会を活かしてご希望の転職を成功させましょう！ぜひお早めにご応募ください。',
    releaseDate: '2023-01-27 15:00:00',
    performerType: 'Person',
    performerName: '株式会社ジオコード アートディレクター 原 陽奈',
  },
  {
    id: '0018',
    title: [
      'MATCHBOXもくもく会',
      '<br />',
      '履歴書、職務経歴書、ポートフォリオ作りの',
      '<br />',
      '不安を解消！その場で相談・添削付き'
    ],
    target: 'Web/IT/ゲームなどのクリエイティブ業界での転職を希望される方',
    img: '../images/Top/event/event_0018_mv.png',
    eventDate: '2023.2.9(木) 開催',
    openingDate: '2023-02-09 18:00:00',
    deadlineDate: '2023-02-09 21:00:00',
    description: '転職書類作成の目的でオンライン上に集い、もくもくと進めていただく時間とするイベントになります。作成相談や書類添削もその場で承ります。転職準備のためのまとまった時間を是非今回のMATCHBOXもくもく会でお過ごしください。',
    releaseDate: '2023-01-27 15:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0017',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      '採用視点から見る',
      '<br />',
      'Webディレクターの書類としてのポートフォリオ作成'
    ],
    target: 'Webディレクター',
    img: '../images/Top/event/event_0017_mv.png',
    eventDate: '2023.1.26(木) 開催',
    openingDate: '2023-01-26 20:00:00',
    deadlineDate: '2023-01-26 22:00:00',
    description: 'Webディレクターのキャリア、スキル、能力をわかりやすく余すことなくアピールし、時間を掛けずにポートフォリオを作る方法を採用目線からお話いただくワークショップを1/26（木）20:00～開催します。ご自身の悩み払拭につながる可能性があるため、この機会を活かしてご希望の転職を成功させましょう。',
    releaseDate: '2023-01-05 17:00:00',
    performerType: 'Person',
    performerName: '株式会社ジュニ リードディレクター 峯藤 誠',
  },
  {
    id: '0016',
    title: [
      '～悩み多きWebディレクター必見！～',
      '<br />',
      'キャリアの壁を超えるヒント',
      '<br />',
      '― 講義 & トークセッション ―'
    ],
    target: 'Webディレクター',
    img: '../images/Top/event/event_0016_mv.jpg',
    eventDate: '2023.1.24(火) 開催',
    openingDate: '2023-01-24 20:00:00',
    deadlineDate: '2023-01-24 21:20:00',
    description: '今後のキャリア形成を考えているWebディレクターの皆さま向けに、現役のWebディレクターとして活躍されている林純薬工業株式会社 試薬化成品部 デジタルマーケティンググループの田中奈々さん、大西淳一をお迎えして「悩み多きWebディレクター必見！キャリアの壁を超えるヒント - 講義 & トークセッション -」を1/24（火）20:00～オンラインにて開催します。ぜひお早めにご応募ください！',
    releaseDate: '2022-12-26 15:00:00',
    performerType: 'PerformingGroup',
    performerName: '林純薬工業株式会社 試薬化成品部 デジタルマーケティンググループ 田中 奈々・大西 淳一',
  },
  {
    id: '0015',
    title: [
      'Webディレクター向け',
      '<br />',
      'キャリアの棚卸しと転職活動のテクニック大公開'
    ],
    target: 'Webディレクター',
    img: '../images/Top/event/event_0015_mv.jpg',
    eventDate: '2023.1.19(木) 開催',
    openingDate: '2023-01-19 19:30:00',
    deadlineDate: '2023-01-19 20:30:00',
    description: 'Web業界で活躍するための転職活動対策に役に立つ「Webディレクター向けキャリアの棚卸しと転職活動のテクニック大公開」を1/19（木）に開催します！今回の対象職種はWebディレクターとし、オンラインでの配信になります。また、参加費は無料です。興味がある方はお早めにご応募ください。',
    releaseDate: '2022-12-26 15:00:00',
    performerType: 'Person',
    performerName: '一般社団法人 ディレクションサポート協会 会長 助田 正樹',
  },
  {
    id: '0014',
    title: [
      'MATCHBOXもくもく会',
      '<br />',
      '履歴書、職務経歴書、ポートフォリオ作りの',
      '<br />',
      '不安を解消！その場で相談・添削付き'
    ],
    target: 'Web/IT/ゲームなどのクリエイティブ業界での転職を希望される方',
    img: '../images/Top/event/event_0014_mv.png',
    eventDate: '2023.1.18(水) 開催',
    openingDate: '2023-01-18 18:00:00',
    deadlineDate: '2023-01-18 21:00:00',
    description: '転職書類作成の目的でオンライン上に集い、もくもくと進めていただく時間とするイベントになります。作成相談や書類添削もその場で承ります。転職準備のためのまとまった時間を是非今回のMATCHBOXもくもく会でお過ごしください。',
    releaseDate: '2022-12-26 15:00:00',
    performerType: 'PerformingGroup',
    performerName: '株式会社マイナビワークス MATCHBOX・転職支援担当',
  },
  {
    id: '0013',
    title: [
      'Webディレクターの書類選考の',
      '<br />',
      '最大の武器はポートフォリオ作成だ！',
      '<br />',
      '採用視点から見るポートフォリオ作成のメリット大公開'
    ],
    target: 'Webディレクター',
    img: '../images/Top/event/event_0013_mv.png',
    eventDate: '2023.1.17(火) 開催',
    openingDate: '2023-01-17 20:00:00',
    deadlineDate: '2023-01-17 21:30:00',
    description: '今回、Webディレクターのポートフォリオ作成についてお話いただくセミナーを1/17（火）20:00～開催いたします。作成についてもMATCHBOXを使って、時間をかけずにポイントをおさえる方法もご紹介します。多岐に渡るディレクターのキャリアの言語化についてお悩みの方是非ご参加ください。',
    releaseDate: '2022-12-26 15:00:00',
    performerType: 'Person',
    performerName: '株式会社ジュニ リードディレクター 峯藤 誠・合同会社DMM.com ディレクター 山本 純一',
  },
  {
    id: '0012',
    title: [
      '自己PRとポートフォリオ作成について'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0012_mv.jpg',
    eventDate: '2022.12.22(木) 開催',
    openingDate: '2022-12-22 19:30:00',
    deadlineDate: '2022-12-22 21:00:00',
    description: 'Web業界で活躍するための転職活動対策に直接役に立つセミナーシリーズ「自己PRとポートフォリオ作成について」を12/22（木）に開催します！今回の対象職種はWebデザイナーとし、オンラインでの配信になります。また、参加費は無料です。',
    releaseDate: '2022-12-02 18:00:00',
    performerType: 'Person',
    performerName: 'デザイナー兼ディレクター 木村 よねこ',
  },
  {
    id: '0011',
    title: [
      '～ Webデザイナー編 ～',
      '<br />',
      'ぶち当たったスキルの壁とそれを乗り越えて',
      '<br />',
      '見えてきたキャリア'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0011_mv.jpg',
    eventDate: '2022.12.21(水) 開催',
    openingDate: '2022-12-21 20:00:00',
    deadlineDate: '2022-12-21 21:20:00',
    description: '転職をデザイナーの皆さま向けに、現役のWebデザイナーの杉村美智子をお迎えして「ぶち当たったスキルの壁とそれを乗り越えて見えてきたキャリア」を12/21（水）20:00～オンラインにて開催します。',
    releaseDate: '2022-12-02 18:00:00',
    performerType: 'Person',
    performerName: '株式会社あつまる クリエイティブグループ デザインリード 杉村 美智子',
  },
  {
    id: '0010',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      'ポートフォリオ改善 ',
      '<br className="md:hidden lg:hidden" />',
      '～設計とブラッシュアップ方法～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0010_mv.png',
    eventDate: '2022.12.17(土) 開催',
    openingDate: '2022-12-17 13:00:00',
    deadlineDate: '2022-12-17 15:00:00',
    description: '皆さまのポートフォリオ作成への疑問に答えるべく、「MATCHBOX活用ワークショップ ポートフォリオ改善」を12/17（土）13:00 ～、オンラインで開催します。どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそう内容のワークショップです。',
    releaseDate: '2022-12-02 18:00:00',
    performerType: 'Person',
    performerName: 'ディレクター兼デザイナー 大石 慎平',
  },
  {
    id: '0009',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      '～ 転職活動・書類作成の要！プロフィール作成編 ～'
    ],
    target: 'Webディレクター,Webデザイナー',
    img: '../images/Top/event/event_0009_mv.png',
    eventDate: '2022.12.14(水) 開催',
    openingDate: '2022-12-14 20:00:00',
    deadlineDate: '2022-12-14 22:00:00',
    description: 'Webディレクター、Webデザイナー職で転職を検討している方、転職活動の準備段階でご自分の経歴をどうやってまとめたらいいのかよくわからないという方向けにワークショップを行います。プロフィールを作成するためにご自分の経歴を棚卸しする機会となります。',
    releaseDate: '2022-12-02 18:00:00',
    performerType: 'Person',
    performerName: 'SPEC. 代表 一般社団法人 日本ディレクション協会 ファウンダー 助田 正樹',
  },
  {
    id: '0008',
    title: [
      'UIデザイナーが語る',
      '<br />',
      'AdobeXDを活用したプロのコミュニケーション術'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0008_mv.jpg',
    eventDate: '2022.11.29(火) 開催',
    openingDate: '2022-11-29 20:00:00',
    deadlineDate: '2022-11-29 21:20:00',
    description: '転職活動準備の支援のため、セミナーを開催いたします。転職を漠然と考えているデザイナーの皆さま向けに、現役UIデザイナーの林 航平をお迎えして「UIデザイナーが語るAdobeXDを活用したプロのコミュニケーション術」を11/29（火）20:00～オンラインにて転職活動やキャリアについてのお話をお届けします。',
    releaseDate: '2022-11-09 13:00:00',
    performerType: 'Person',
    performerName: '株式会社VOWL 代表取締役 Thinka Studio株式会社 代表取締役 林 航平',
  },
  {
    id: '0007',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      '～ 転職活動・書類作成の要！プロフィール作成編 ～'
    ],
    target: 'Webディレクター,Webデザイナー',
    img: '../images/Top/event/event_0007_mv.png',
    eventDate: '2022.11.24(木) 開催',
    openingDate: '2022-11-24 20:00:00',
    deadlineDate: '2022-11-24 22:00:00',
    description: 'Webディレクター、Webデザイナー職で転職を検討している方、転職活動の準備段階でご自分の経歴をどうやってまとめたらいいのかよくわからないという方向けにワークショップを行います。プロフィールを作成するためにご自分の経歴を棚卸しする機会となります。',
    releaseDate: '2022-11-01 13:00:00',
    performerType: 'Person',
    performerName: 'SPEC. 代表 一般社団法人 日本ディレクション協会 ファウンダー 助田 正樹',
  },
  {
    id: '0006',
    title: [
      'Webデザイナーとしてのキャリアパスの考え方と整理'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0006_mv.jpg',
    eventDate: '2022.11.22(火) 開催',
    openingDate: '2022-11-22 19:30:00',
    deadlineDate: '2022-11-22 21:00:00',
    description: 'Web業界で活躍するための転職活動対策に直接役に立つセミナーシリーズ「Webデザイナーとしてのキャリアパスの考え方と整理」を11/22（火）に開催します！今回の対象職種はWebデザイナーとし、オンラインでの配信になります。また、参加費は無料です。',
    releaseDate: '2022-11-01 13:00:00',
    performerType: 'Person',
    performerName: 'デザイナー兼ディレクター 木村 よねこ',
  },
  {
    id: '0005',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      'スキルだけじゃない！？',
      '<br />',
      '評価する側がGoodする記載ポイントとは'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0005_mv.png',
    eventDate: '2022.11.17(木) 開催',
    openingDate: '2022-11-17 20:00:00',
    deadlineDate: '2022-11-17 22:00:00',
    description: '皆さまのポートフォリオ作成への疑問に答えるべく、「MATCHBOX活用ワークショップ スキルだけじゃない！？ 評価する側がGoodする記載ポイントとは」を11/17（木）20:00～、オンラインで開催します。どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそう内容のワークショップです。',
    releaseDate: '2022-11-01 13:00:00',
    performerType: 'Person',
    performerName: 'Studio FireColor 小泉 誠',
  },
  {
    id: '0004',
    title: [
      '選考通過につながるポートフォリオ講座',
      '<br />',
      '～最新の転職市場とドキュメント作成術～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0004_mv.jpg',
    eventDate: '2022.10.27(木) 開催',
    openingDate: '2022-10-27 19:30:00',
    deadlineDate: '2022-10-27 20:30:00',
    description: '昨今のWeb業界の転職市場の動向を踏まえ、現在どんな経験をしている人が企業から求められているのか、その上で採用担当者にきちんとキャリアと経験が伝わるポートフォリオを作成するためのポイントをお伝えするセミナーを10/27（木）19:30～にオンライン開催いたします。',
    releaseDate: '2022-10-03 17:00:00',
    performerType: 'Person',
    performerName: '株式会社マイナビワークス マーケティング部 転職セミナー企画担当 南 文乃',
  },
  {
    id: '0003',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      '自分のキャリアを効果的にアピールするには'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0003_mv.png',
    eventDate: '2022.10.25(火) 開催',
    openingDate: '2022-10-25 20:00:00',
    deadlineDate: '2022-10-25 22:00:00',
    description: '皆さまのポートフォリオ作成への疑問に答えるべく、「MATCHBOX活用ワークショップ 自分のキャリアを効果的にアピールするには」を10/25（火）20:00～、オンラインで開催します。どう考えて作成にあたればいいか、何を伝えるポートフォリオにすればいいのか、より皆さまの疑問によりそう内容のワークショップです。',
    releaseDate: '2022-10-03 17:00:00',
    performerType: 'Person',
    performerName: 'Webデザイナー/エンジニア 庄司 美雪',
  },
  {
    id: '0002',
    title: ['UIUXデザイナーのキャリアの出発点'],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0002_mv.jpg',
    eventDate: '2022.10.20(木) 開催',
    openingDate: '2022-10-20 20:00:00',
    deadlineDate: '2022-10-20 21:20:00',
    description: '転職を考えているデザイナーの皆さま向けに、現役UI/UXデザイナーの大倉 慶子をお迎えして「UIUXデザイナーのキャリアの出発点」を10/20（木）20:00～オンラインにて転職活動やキャリアについてのお話をお届けします。',
    releaseDate: '2022-10-03 17:00:00',
    performerType: 'Person',
    performerName: 'UI/UXデザイナー 大倉 慶子',
  },
  {
    id: '0001',
    title: [
      'MATCHBOX活用ワークショップ',
      '<br />',
      'ポートフォリオ改善 ',
      '<br className="md:hidden lg:hidden" />',
      '～設計とブラッシュアップ方法～'
    ],
    target: 'Webデザイナー',
    img: '../images/Top/event/event_0001_mv.png',
    eventDate: '2022.10.15(土) 開催',
    openingDate: '2022-10-15 14:00:00',
    deadlineDate: '2022-10-15 16:00:00',
    description: 'WebデザイナーとしてキャリアをスタートさせたM.H.さんは、転職活動中に自己流のポートフォリオでは書類選考が通らない時期があった。そこでキャリアアドバイザーの助言を受け、ポートフォリオの内容を一新すると、書類選考の通過率は格段にアップ。一体ポートフォリオにどんな手を加えたのか。M.H.さんのポートフォリオを劇的に変えた理由に迫った。',
    releaseDate: '2022-10-03 17:00:00',
    performerType: 'Person',
    performerName: 'ディレクター兼デザイナー 大石 慎平',
  },
];
