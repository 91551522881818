import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { State } from '@/redux/state';
import { PortfolioApi, PortfolioProgressResponse, Status, UserInfo } from '@/utils/api-client/api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePagePortfolioProgress = (): (any & Status) | undefined => {
  const user: UserInfo | null = useSelector((state: State) => state.user);
  const history = useHistory();
  const id = history.location.pathname.split('/')[4];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [res, setRes] = useState<PortfolioProgressResponse>();
  useEffect(() => {
    (async () => {
      if (!user?.user_id) return;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: AxiosResponse<any & Status> =
        await new PortfolioApi().getPortfolioProgressDetail(user.user_id, id);
      setRes(response.data);
    })();
  }, [user]);

  return res;
};
