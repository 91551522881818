import React, { MouseEventHandler, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ButtonIconButton } from '@/componentsDirect/Parts/Button/IconButton';

type TProps = {
  show: boolean;
  children: string | null;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  onEnter?: () => void;
  onExited?: () => void;
};

/**
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=5332%3A102628
 */
export const NotificationSnackBar = ({
  children,
  show = false,
  onClick,
  onEnter,
  onExited,
}: TProps): React.ReactElement => {
  // !see https://github.com/reactjs/react-transition-group/issues/668
  const nodeRef = React.useRef(null);

  const [showState, setShow] = useState(show);
  const callBacks = {
    onEnter: onEnter,
    onExited: onExited,
  };

  useEffect(() => {
    setShow(show);
  }, [show]);
  return (
    <CSSTransition
      in={showState}
      timeout={200}
      nodeRef={nodeRef}
      {...callBacks}
      unmountOnExit
      classNames="mbx-anime-notification"
    >
      <div ref={nodeRef} className={'mbxd-notification mbxd-notification--success'}>
        <div className="mbxd-notification-inner">
          <div className={'mbxd-notification-message'}>{children}</div>
          <div className={'mbxd-notification-icon'}>
            <ButtonIconButton
              type="white"
              iconName="Menu-close"
              hitArea="medium"
              onClick={onClick}
            ></ButtonIconButton>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
