import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';

export const optionValueToChildren = (
  value: number,
  options: Array<{ value: string; children: string }>
): string | undefined => {
  const found = options.find((item) => {
    return item.value === value.toString();
  });

  return found?.children;
};

export const getOptionChildrenFromValue = (
  option: keyof typeof FORM_OPTIONS,
  value: number | undefined
): string => {
  if (value === undefined) return '';
  if (value === null) return '';
  const result = optionValueToChildren(value, FORM_OPTIONS[option]);
  return result ? result : '';
};

export const getDirectOptionChildrenFromValue = (
  option: keyof typeof DIRECT_FORM_OPTIONS,
  value: number | undefined
): string => {
  if (value === undefined) return '';
  if (value === null) return '';
  const result = optionValueToChildren(value, DIRECT_FORM_OPTIONS[option]);
  return result ? result : '';
};
