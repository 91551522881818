import React from 'react';

type Props = {
  className?: string;
  title?: string | React.ReactElement;
  text?: string | React.ReactElement;
};

export const ContentsBlank = (props: Props): React.ReactElement => {
  return (
    <section className={props.className}>
      <div className="w-114 m-auto">
        <img src="/images/blank_matchbou.png" alt="" />
      </div>
      <p className="text-20_20 text-center mt-30 font-bold">{props.title}</p>
      <p className="mbx-typography--body_1 text-center mt-28">{props.text}</p>
    </section>
  );
};
