import React from 'react';

type TColumn = {
  title: string;
  className?: string;
};

type TProps = {
  columns: Array<TColumn>;
};

/**
 * 汎用のテーブルヘッダー
 * TableContainerと共に利用する
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=3840%3A60046
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4228%3A61026
 */
export const TableHeader = ({ columns }: TProps): React.ReactElement => {
  return (
    <thead>
      <tr className="text-10_24 text-blueGray-700 bg-gray-200 border-b border-gray-300 text-left">
        {columns.map((column, index) => (
          <th key={index} className={`box-content pl-16 pt-8 pb-9 ${column.className}`}>
            {column.title}
          </th>
        ))}
      </tr>
    </thead>
  );
};
