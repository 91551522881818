import React from 'react';

import { BaseButton } from '@/componentsAdmin/Parts/Button';
import { ModalDefault } from '@/componentsAdmin/Parts/Modal';
import { useUserTag } from '@/hooksAdmin/useUserTag';

type TProps = {
  showModal: boolean;
  id: string;
  setUserDeleteState: React.Dispatch<
    React.SetStateAction<{
      id: string;
      showModal: boolean;
    }>
  >;
  getUserTags: () => Promise<void>;
};

/**
 * タグ削除処理
 * @author s2 masa
 */
export const AdminUserTagDeleteComponent = ({
  showModal,
  id,
  setUserDeleteState,
  getUserTags,
}: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // Hooks
  //
  // ====================================================================================================
  const { deleteUserTagId } = useUserTag();

  // ====================================================================================================
  //
  // Events
  //
  // ====================================================================================================
  const onClose: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.preventDefault();
    setUserDeleteState({ id: '', showModal: false });
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    (async () => {
      await deleteUserTagId(id);
      await getUserTags();
      setUserDeleteState({ id: '', showModal: false });
    })();
  };

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <ModalDefault isOpen={showModal} onClose={onClose} className="w-624 rounded-modalContainer">
      <section className="py-80">
        <h1 className="text-subhead_1 mb-8">このタグを削除しますか？</h1>
        <form className="px-80" onSubmit={onSubmit}>
          <div className="flex justify-center mt-24">
            <div className="w-146 mr-8">
              <BaseButton size="m" theme="secondary" className="w-full" onClick={onClose}>
                キャンセル
              </BaseButton>
            </div>
            <div className="w-146 ml-8">
              <BaseButton size="m" theme="primary" className="w-full" type="submit">
                削除する
              </BaseButton>
            </div>
          </div>
        </form>
      </section>
    </ModalDefault>
  );
};
