import '@/styles/common/component/Top/TopModal.scss';
import React, { useState, useEffect, useRef } from 'react';
// import { items } from '@/utils/top';
import Modal from '../Modal';
import TopModalLayout from './TopModalLayout';
import BaseButton from '../Button/BaseButton';
import cn from 'classnames';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { DataLayerPushContainer } from '../DataLayerPushContainer';

const modalContents = {
  '1-1': {
    heading: 'ポートフォリオって必要？',
    title: 'ポートフォリオを見たい企業100％。<br />でも、持っている人は26.5％。',
    description:
      '実はほぼ全ての企業が、デザイナーを採用するときポートフォリオの<br class="sp-none" />提出を希望しています。<br class="sp-only" />一方で準備できている人は4人にたったの1人だけ。<br />作るだけ、たったそれだけで差も作れます。',
  },
  '1-2': {
    heading: 'なぜMATCHBOXは転職に強い？',
    title: '採用担当者の見たいポイントだけ。<br />だから転職に役立ちます。',
    description:
      'ポートフォリオはコミュニケーションツールです。だから採用担当者の目線で項目を厳選。<br />ガイドに沿って入力するだけで、作品やそのプロセスまで紹介できます。<br />美しいレイアウトにもこだわりました。',
  },
  '1-3': {
    heading: 'MATCHBOXでできることは？',
    title: 'ポートフォリオも、それ以外も。<br />必要な書類がすべて揃います。',
    description:
      '面接に欠かせないのは、ポートフォリオだけではありません。<br class="sp-none" />だからMATCHBOXでは履歴書や職務経歴書も作成できます。<br />あれこれ準備する手間も、情報の抜け漏れもなくなります。',
  },
  '2-1': {
    heading: 'オファー機能とは',
    title: '「オファー機能」で、転職を気軽に始めましょう。',
    description:
      'ポートフォリオや職務経歴書を作成し、「オファー機能」に申し込むと、<br class="sp-none"/>あなたの作品やキャリアを見て気になった企業から面接のオファーを受け取ることができます。<br />さらに、キャリアアドバイザーがサポートしますので、安心して転職を進めることができます。',
  },
  '2-2': {
    heading: '多彩な取引企業',
    title: 'あなたらしい転職を叶える<br />多彩な取引企業',
    description:
      'マイナビクリエイターは幅広い業界・事業規模の企業と取引があります。<br />そのため、クリエイターへのキャリアスタートから、キャリアアップを目的とした<br />転職まで、あなたに合った転職への可能性が広がっています。',
  },
};

function Modal1_1(): React.ReactElement {
  const [leftTimer, setLeftTimer] = useState<number>(0);
  const [rightTimer, setRightTimer] = useState<number>(0);
  const mq = useMBXMediaQuery();
  const delay = (second: number) => {
    return new Promise((resolve) => setTimeout(resolve, second));
  };

  useEffect(() => {
    if (leftTimer >= 100) return;
    (async () => {
      await delay(7);
      setLeftTimer(leftTimer + 1);
    })();
  }, [leftTimer]);

  useEffect(() => {
    (async () => {
      if (rightTimer === 26) {
        await delay(10);
        setRightTimer(rightTimer + 0.5);
      }
    })();
    if (rightTimer >= 26) return;
    (async () => {
      await delay(10);
      setRightTimer(rightTimer + 1);
    })();
  }, [rightTimer]);
  return (
    <section className="modal-1_1">
      <TopModalLayout
        heading={modalContents['1-1'].heading}
        title={modalContents['1-1'].title}
        description={modalContents['1-1'].description}
        textPosition="top"
      >
        <DataLayerPushContainer
          data={{
            event: 'pageView',
            actionType: 'page_view',
            actionName: 'modal_open',
            virtualPageName: 'necessity',
          }}
        >
          <div className="graph-area">
            <div
              className="graph-back"
              style={{ backgroundImage: 'url(images/Top/graf-back.png)' }}
            >
              <div
                className="graph"
                style={{
                  backgroundImage: `radial-gradient(#ffffff 39%, transparent 40%),conic-gradient(#e96645 0% ${leftTimer}%, rgba(0, 0, 0, 0) ${leftTimer}% 100%)`,
                }}
              >
                {leftTimer}%
              </div>
            </div>
            <div
              className="graph-back"
              style={{ backgroundImage: 'url(images/Top/graf-back.png)' }}
            >
              <div
                className="graph"
                style={{
                  backgroundImage: `radial-gradient(#ffffff 39%, transparent 40%),conic-gradient(#e96645 0% ${rightTimer}%, rgba(0, 0, 0, 0) ${rightTimer}% 100%)`,
                }}
              >
                {rightTimer}%
              </div>
            </div>
          </div>
          <div className="comments">
            <p>
              ポートフォリオの{mq.sm && <br />}提出を希望する{mq.sm && <br />}企業
            </p>
            <p>
              ポートフォリオを{mq.sm && <br />}準備できている{mq.sm && <br />}デザイナー
            </p>
          </div>
          <p className="reference">
            参照：マイナビクリエイターによる調査結果{mq.sm && <br />}
            （対象期間：2017/9/1〜2018/2/28）
          </p>
        </DataLayerPushContainer>
      </TopModalLayout>
    </section>
  );
}

function Modal1_2(): React.ReactElement {
  const bnrRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (bnrRef.current) {
      const { clientWidth } = bnrRef.current;
      if (clientWidth > 0) {
        setHeight((clientWidth / 728) * 278);
      }
    }
  }, []);

  return (
    <section className="modal-1_2">
      <TopModalLayout
        heading={modalContents['1-2'].heading}
        title={modalContents['1-2'].title}
        description={modalContents['1-2'].description}
        textPosition="bottom"
      >
        <DataLayerPushContainer
          data={{
            event: 'pageView',
            actionType: 'page_view',
            actionName: 'modal_open',
            virtualPageName: 'why',
          }}
        >
          <div className="main-bnr" ref={bnrRef} style={{ height: `${height}px` }}>
            <img src="images/Top/Modal/modal1_2.png" alt="" />
          </div>
        </DataLayerPushContainer>
      </TopModalLayout>
    </section>
  );
}

function Modal1_3(): React.ReactElement {
  const bnrRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (bnrRef.current) {
      const { clientWidth } = bnrRef.current;
      if (clientWidth > 0) {
        setHeight((clientWidth / 728) * 278);
      }
    }
  }, []);

  return (
    <section className="modal-1_3">
      <TopModalLayout
        heading={modalContents['1-3'].heading}
        title={modalContents['1-3'].title}
        description={modalContents['1-3'].description}
        textPosition="bottom"
      >
        <DataLayerPushContainer
          data={{
            event: 'pageView',
            actionType: 'page_view',
            actionName: 'modal_open',
            virtualPageName: 'potential',
          }}
        >
          <div className="main-bnr" ref={bnrRef} style={{ height: `${height}px` }}>
            <img src="images/Top/Modal/modal1_3.png" alt="" />
          </div>
        </DataLayerPushContainer>
      </TopModalLayout>
    </section>
  );
}

function Modal2_1(): React.ReactElement {
  return (
    <section className="modal-2_1">
      <TopModalLayout
        heading={modalContents['2-1'].heading}
        title={modalContents['2-1'].title}
        description={modalContents['2-1'].description}
        textPosition="top"
      >
        <DataLayerPushContainer
          data={{
            event: 'pageView',
            actionType: 'page_view',
            actionName: 'modal_open',
            virtualPageName: 'offer',
          }}
        >
          <div className="flow">
            <img src="images/Top/Modal/modal2_1.png" alt="" />
          </div>
        </DataLayerPushContainer>
      </TopModalLayout>
    </section>
  );
}

// function Modal2_2(): React.ReactElement {
//   const isSp = window.innerWidth < 768;
// const { push } = useContext(DataLayerContext);

//   return (
//     <section className="modal-2_2">
//       <TopModalLayout
//         heading={modalContents['2-2'].heading}
//         title={modalContents['2-2'].title}
//         description={modalContents['2-2'].description}
//         textPosition="top"
//       >
// <DataLayerPushContainer
//   data={{
//     event: 'pageView',
//     actionType: 'page_view',
//     actionName: 'modal_open',
//     virtualPageName: 'companies',
//   }}
// >
//         <div className="logo-list">
//           {items.companyLogo.slice(0, isSp ? 9 : 18).map((logo: string, index: number) => {
//             return (
//               <div className="logo" key={index}>
//                 <img src={logo} alt="" />
//               </div>
//             );
//           })}
//         </div>
// </DataLayerPushContainer>
//       </TopModalLayout>
//     </section>
//   );
// }

type Props = {
  modal: boolean;
  contentsNumber: 1 | 2;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
};

function TopModal(props: Props): React.ReactElement {
  const firstContents = [<Modal1_1 key={0} />, <Modal1_2 key={1} />, <Modal1_3 key={2} />];
  const secondContents = [<Modal2_1 key={0} />];
  const [counter, setCounter] = useState<number>(0);
  const [contents, setContents] = useState<React.ReactElement[]>([]);
  const isSp = window.innerWidth < 768;

  useEffect(() => {
    if (props.contentsNumber === 1) setContents(firstContents);
    if (props.contentsNumber === 2) setContents(secondContents);
  }, []);

  useEffect(() => {
    setCounter(0);
  }, [props.modal]);
  const countDown = () => {
    if (counter === 0) return;
    setCounter(counter - 1);
  };
  const countUp = () => {
    if (counter === contents.length - 1) return;
    setCounter(counter + 1);
  };

  return (
    <section className="top-page_modal">
      {!isSp ? (
        <Modal isOpen={props.modal} isClose={() => props.setModal(false)} className="top-modal">
          <section className="top-page_modal_inner">
            {contents.length > 1 && (
              <div
                className={cn('control', 'left-control', counter === 0 && 'inactive')}
                onClick={countDown}
              >
                <i className="mbx-icon mbx-icon-Modal_Arrow_Left"></i>
              </div>
            )}
            {contents.length > 1 && (
              <div
                className={cn(
                  'control',
                  'right-control',
                  counter === contents?.length - 1 && 'inactive'
                )}
                onClick={countUp}
              >
                <i className="mbx-icon mbx-icon-Modal_Arrow_Right"></i>
              </div>
            )}
            {contents.map((modal: React.ReactElement, index: number) => {
              return <div key={index}>{index === counter && modal}</div>;
            })}
          </section>
        </Modal>
      ) : (
        <Modal isOpen={props.modal} isClose={() => props.setModal(false)} className="top-modal">
          <section className="top-page_modal_inner">
            {contents.map((modal: React.ReactElement, index: number) => {
              return (
                <div key={index}>
                  <>{modal}</>
                  <BaseButton theme="primary" size="m" className="sp-modal-btn" href="/entry">
                    MATCHBOXをはじめる
                  </BaseButton>
                </div>
              );
            })}
          </section>
        </Modal>
      )}
    </section>
  );
}

export default TopModal;
