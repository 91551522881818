import '@/styles/common/Preview/preview.scss';

import React from 'react';

import { ProfileImageSquare } from '@/components/common/OtherComponents/ProfileImageSquare';
import { PreviewResumeAddress } from '@/components/common/Preview/ResumeAddress';
import { ResumePreview } from '@/components/common/Preview/ResumePreview';
import { PreviewResumeText } from '@/components/common/Preview/ResumeText';
import { PreviewResumeTitle } from '@/components/common/Preview/ResumeTitle';
import { ResumePreviewSectionCareers } from '@/components/Resume/Preview/SectionCareers';
import { ResumePreviewSectionEducations } from '@/components/Resume/Preview/SectionEducations';
import { CareerSummaryModel, EducationData, MBResumeResponse } from '@/utils/api-client/api';
import { getAge } from '@/utils/string';

type TProps = {
  date: string;
  fetchData?: MBResumeResponse;
  educations?: EducationData[];
  careers?: CareerSummaryModel[];
  secondPageCareersItemLength?: number;
};

export const ResumePreviewPage1 = ({
  date,
  fetchData,
  educations = [],
  careers = [],
  secondPageCareersItemLength,
}: TProps): React.ReactElement => {
  if (!fetchData?.user?.user_info) return <></>;
  const userInfo = fetchData?.user?.user_info;
  const genderLabel = userInfo.gender === 0 ? '' : userInfo.gender === 1 ? '女' : '男';
  const firstPageHistoryItemLength = educations.length + careers.length;
  const emptyEducationItemLength =
    careers?.length > 11 ? 14 - careers?.length : 4 - educations?.length;

  const address = userInfo.address2 ? userInfo.address2 : '未記入';
  const addressFurigana = userInfo.address1 ? userInfo.address1 : '未記入';
  return (
    <ResumePreview date={date} page={1}>
      <>
        <div className="flex">
          <div className="w-136 flex-shrink-0">
            <ProfileImageSquare
              src={userInfo.photo?.f_url}
              type="preview"
              className="mkt_mask_items"
            />
          </div>
          <div className="w-full">
            <div className="flex ">
              <PreviewResumeText className="w-272 flex-shrink-0 mkt_mask_items">
                {userInfo.furigana}
              </PreviewResumeText>
              <PreviewResumeText className="w-48 flex-shrink-0 mkt_mask_items">
                {genderLabel}
              </PreviewResumeText>
              <PreviewResumeText className="w-224 flex-shrink-0 mkt_mask_items">
                {`${userInfo.birth_year}年${userInfo.birth_month}月${
                  userInfo.birth_day
                }日（満${getAge(
                  userInfo.birth_year,
                  userInfo.birth_month,
                  userInfo.birth_day
                )}歳）`}
              </PreviewResumeText>
            </div>
            <PreviewResumeText className="w-full mkt_mask_items" type="name">
              {userInfo.name}
            </PreviewResumeText>
          </div>
        </div>
        <div className="flex mt-30">
          <PreviewResumeTitle>現住所</PreviewResumeTitle>
          <div className="w-full">
            <div className="flex">
              <PreviewResumeText type="postalCode" className="w-92 flex-shrink-0 mkt_mask_items">
                {userInfo.postal_code && `〒${userInfo.postal_code}`}
              </PreviewResumeText>
              <PreviewResumeAddress
                addressFurigana={addressFurigana}
                address={address}
                className="w-full mkt_mask_items"
              ></PreviewResumeAddress>
            </div>
            <div className="flex">
              <PreviewResumeText className="w-full mkt_mask_items">
                {userInfo.tel}
              </PreviewResumeText>
              <PreviewResumeText className="w-full mkt_mask_items">
                {userInfo.email}
              </PreviewResumeText>
            </div>
          </div>
        </div>
        <ResumePreviewSectionEducations
          educations={educations}
          careers={careers}
          emptyEducationItemLength={emptyEducationItemLength}
          isFirstPage={true}
        ></ResumePreviewSectionEducations>
        <ResumePreviewSectionCareers
          educations={educations}
          careers={careers}
          firstPageHistoryItemLength={firstPageHistoryItemLength}
          secondPageCareersItemLength={secondPageCareersItemLength}
          emptyEducationItemLength={emptyEducationItemLength}
          isFirstPage={true}
        ></ResumePreviewSectionCareers>
      </>
    </ResumePreview>
  );
};
