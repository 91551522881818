import cn from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/reduxAdmin';
import { AdminMatchboxUserResponse } from '@/utils/api-client';

type Props = {
  user: AdminMatchboxUserResponse | undefined;
};

export const Cp2Status = (props: Props): React.ReactElement => {
  const CP2MASTER = useSelector((state: RootState) => state.admin_master.cp2);
  console.log(CP2MASTER);

  const isMasterValue = (
    key: 'cp2_charge_name' | 'cp2_consultation_status' | 'cp2_registration_status',
    value: string
  ) => {
    return CP2MASTER[key].find((m) => m.value === value)?.children;
  };

  return (
    <section>
      <div className="flex justify-end">
        <div className="text-right text-10_12">
          <p>ピックアップ {props.user?.offers?.filter((offer) => offer.status === 1).length}</p>
          <p>
            一次面接オファー{' '}
            {
              props.user?.offers?.filter(
                (offer) =>
                  offer.status === 3 ||
                  offer.status === 5 ||
                  offer.status === 6 ||
                  offer.status === 7
              ).length
            }
          </p>
          <p>
            カジュアル面談オファー{' '}
            {
              props.user?.offers?.filter(
                (offer) =>
                  offer.status === 8 ||
                  offer.status === 9 ||
                  offer.status === 10 ||
                  offer.status === 11
              ).length
            }
          </p>
        </div>
        <div className="text-gray-700 text-9_10">
          {props.user?.user_info.cp2_consultation_status && (
            <div
              className={cn(
                'flex',
                'items-center',
                'justify-center',
                'w-96',
                'ml-16',
                'border',
                'border-gray-400'
              )}
            >
              <p className="py-3">
                <span>
                  {isMasterValue(
                    'cp2_consultation_status',
                    props.user.user_info.cp2_consultation_status
                  )}
                </span>
              </p>
            </div>
          )}
          {props.user?.user_info.cp2_registration_status && (
            <div
              className={cn(
                'flex',
                'items-center',
                'justify-center',
                'w-96',
                'ml-16',
                'border',
                'border-gray-400',
                'mt-4'
              )}
            >
              <p className="py-3">
                <span>
                  {isMasterValue(
                    'cp2_registration_status',
                    props.user.user_info.cp2_registration_status
                  )}
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
      {props.user?.user_info.cp2_charge_name ? (
        <p className="mt-8 text-10_16 text-right">
          <span>担当者:</span>
          <span>{isMasterValue('cp2_charge_name', props.user.user_info.cp2_charge_name)}</span>
        </p>
      ) : (
        <p className="mt-8 text-10_16 text-right">
          <span>担当者:</span>
          <span>未設定</span>
        </p>
      )}
    </section>
  );
};
