import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { AdminPage } from '@/componentsAdmin/Layout/Page';
import {
    AdminCompanySearchFormReset, AdminSearchFormCompanyBasicInfo,
    AdminSearchFormSectionEmployeeStatus, AdminSearchFormSectionMWStatus
} from '@/componentsAdmin/Search';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { DirectSearchFormSectionFreeWord } from '@/componentsDirect/Search/SectionFreeWord';
import {
    useSearchConditionValidation, validationSchemaForPost
} from '@/hooksAdmin/useSearchConditionValidation';
import { AdminDirectCompanySearchConditionModel } from '@/utils/api-client';
import { yupResolver } from '@hookform/resolvers/yup';

const defaultCondition: AdminDirectCompanySearchConditionModel = {
  keyword: [],
  // directompany_id: [],
  block_company_id: '',
  name: '',
  kana: '',
  last_login: [],
  registration_date: [],
  // prefecture: [],
  address: '',
  business_type: [],
  // employee_amount: [],
  description: [],
  direct_company_user_id: '',
  direct_company_user_email: '',
  direct_company_user_name: '',
  mw_user: '',
};

/**
 * Admin 企業検索 入力フォーム マイナビ向け
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A63469
 */
export const AdminCompanySearchForm = (props: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSearch(param: any): void;
  onResumeConditions(): AdminDirectCompanySearchConditionModel | undefined;
  onResetCondition(): void;
}): React.ReactElement => {
  const { searchConditionChangeDetection } = useSearchConditionValidation();

  const methods = useForm<AdminDirectCompanySearchConditionModel>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchemaForPost),
    defaultValues: defaultCondition,
  });
  const { handleSubmit, reset, watch } = methods;

  //マウント時に親Stateからform valueを復帰
  useEffect(() => {
    const resumeConditions = props.onResumeConditions();
    if (!resumeConditions) return;
    (Object.keys(resumeConditions) as (keyof AdminDirectCompanySearchConditionModel)[]).forEach(
      (key) => {
        const value = resumeConditions?.[key];
        if (value) {
          methods.setValue(key, value);
        }
      }
    );
  }, []);

  const onSubmit = () => {
    props.onSearch(methods.getValues());
  };

  useEffect(() => {
    const subscription = watch((value) => {
      // データを比較して変更検知
      searchConditionChangeDetection(value);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  //親Stateとform valueをreset
  const onResetSearchCondition = () => {
    props.onResetCondition();
    reset();
  };

  return (
    <AdminPage header={<HeaderTitle title="企業検索" />} sticky={<StickyButton isChanged={true} />}>
      <FormProvider {...methods}>
        <div className="flex justify-end py-25">
          {true && <AdminCompanySearchFormReset reset={onResetSearchCondition} />}
          <BaseButton size="s" theme="link" href="/admin/company/new">
            新しい企業を登録する
          </BaseButton>
        </div>
        <section>
          <form
            id="FORM"
            className="pb-80 border-t border-gray-300"
            onSubmit={handleSubmit(onSubmit)}
          >
            <DirectSearchFormSectionFreeWord />
            <AdminSearchFormCompanyBasicInfo />
            <AdminSearchFormSectionEmployeeStatus />
            <AdminSearchFormSectionMWStatus />
          </form>
        </section>
      </FormProvider>
    </AdminPage>
  );
};

type TPropsButton = {
  isChanged: boolean;
};

const StickyButton = ({ isChanged }: TPropsButton): React.ReactElement => {
  return (
    <div className="sticky bottom-10 z-30">
      <BaseButton
        size="m"
        theme="primary"
        type="submit"
        iconName="Search"
        className="absolute right-20 bottom-12"
        disabled={!isChanged}
        form="FORM"
      >
        この条件で検索する
      </BaseButton>
    </div>
  );
};
