import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { AdminPage } from '@/componentsDirect/Layout/Page';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import {
  FormContainerDropdown,
  FormContainerTextArea,
  FormContainerTextfield,
} from '@/componentsDirect/Parts/Form/';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { ProfileImageCircle } from '@/componentsDirect/Parts/OtherComponents/ProfileImageCircle';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { PLACEHOLDER } from '@/definitionDirect/PLACEHOLDER';
import { YUP_VALIDATION } from '@/definitionDirect/VALIDATION';
import { useCompany } from '@/hooksDirect/useCompany';
import { DirectCompanyModel } from '@/utils/api-client';
import { yupResolver } from '@hookform/resolvers/yup';

/**
 * Direct 企業情報設定フォーム
 *
 * 管理者のみ使用可能
 *
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65938
 */
export const DirectCompanyForm = (): React.ReactElement => {
  const { companyData, getCompany, patchCompany } = useCompany();

  const schema = yup.object().shape({
    name: YUP_VALIDATION.required,
    kana: YUP_VALIDATION.required,
    url: YUP_VALIDATION.url,
    message: YUP_VALIDATION.message(1000),
    business_type: YUP_VALIDATION.null,
    prefecture: YUP_VALIDATION.null,
    employee_amount: YUP_VALIDATION.null,
  });

  const methods = useForm<DirectCompanyModel>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<DirectCompanyModel> = (data) => {
    patchCompany(data);
  };

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    if (companyData) setDefaultValues(companyData);
  }, [companyData]);

  // データを取得した後にRHFに値をsetValueする
  const setDefaultValues = (res: DirectCompanyModel) => {
    (Object.keys(res) as (keyof DirectCompanyModel)[]).forEach((v) => {
      if (res?.[v]) {
        methods.setValue(v, res?.[v]);
      }
    });
  };

  return (
    <AdminPage header={<HeaderTitle title="企業情報設定" />}>
      <FormProvider {...methods}>
        <header className="mt-48 text-center mb-40">
          <h1 className="text-body_1">
            オファー時に求職者に伝える情報です。
            <br />
            あなたの会社の魅力が伝わる情報を設定しましょう。
          </h1>
        </header>
        <section>
          <form className="pb-80 w-624 mx-auto" onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-22">
              <h1 className="text-12_16 font-bold text-center mb-8">企業ロゴ</h1>
              <ProfileImageCircle
                name="icon"
                iconName="Large32_Placeholder-image"
                className="mkt_mask_items"
              />
            </div>
            <div className="mbx-utils-stack-v--22">
              <FormLayoutFieldset>
                <FormLabel label="企業ID" type="small" />
                <FormContainerTextfield name="id" readOnly className="mkt_mask_items" />
              </FormLayoutFieldset>

              <FormLayoutFieldset>
                <FormLabel label="企業名" type="small" />
                <FormContainerTextfield name="name" className="mkt_mask_items" />
              </FormLayoutFieldset>

              <FormLayoutFieldset>
                <FormLabel label="企業名（ふりがな）" type="small" />
                <FormContainerTextfield name="kana" className="mkt_mask_items" />
              </FormLayoutFieldset>

              <FormLayoutFieldset className="w-308">
                <FormLabel label="業種" type="small" />
                <FormContainerDropdown
                  name="business_type"
                  placeholder="選択してください"
                  selectList={FORM_OPTIONS.business_type}
                  className="mkt_mask_items"
                />
              </FormLayoutFieldset>

              <FormLayoutFieldset className="w-308">
                <FormLabel label="所在地（都道府県）" type="small" />
                <FormContainerDropdown
                  name="prefecture"
                  placeholder="選択してください"
                  selectList={FORM_OPTIONS.prefecture}
                  className="mkt_mask_items"
                />
              </FormLayoutFieldset>

              <FormLayoutFieldset>
                <FormLabel label="所在地（市区町村以下）" type="small" />
                <FormContainerTextfield name="address" className="mkt_mask_items" />
              </FormLayoutFieldset>

              <FormLayoutFieldset>
                <FormLabel label="事業内容" type="small" />
                <FormContainerTextfield name="description" className="mkt_mask_items" />
              </FormLayoutFieldset>

              <FormLayoutFieldset className="w-308">
                <FormLabel label="社員数" type="small" />
                <FormContainerDropdown
                  name="employee_amount"
                  placeholder="選択してください"
                  selectList={FORM_OPTIONS.employee_amount}
                  className="mkt_mask_items"
                />
              </FormLayoutFieldset>

              <FormLayoutFieldset>
                <FormLabel label="WebサイトURL" type="small" />
                <FormContainerTextfield
                  name="url"
                  placeholder={PLACEHOLDER.company_url}
                  className="mkt_mask_items"
                />
              </FormLayoutFieldset>

              <FormLayoutFieldset>
                <div className="flex mbx-status_clients__title">
                  <FormLabel label="求職者（候補者）へのメッセージ" type="small" />
                  <i className="mbx-icon mbx-icon-Info -ml-16 pb-6" />
                  <div className="mbx-admin_tooltip" style={{ top: 40, left: 0 }}>
                    オファー時、この項目は求職者（候補者）に開示されます。ぜひ求職者（候補者）にとって魅力的なメッセージを記入ください。なお、求職者（候補者）ごとにメッセージを変えることはできません。
                  </div>
                </div>
                <FormContainerTextArea
                  name="message"
                  rows={4}
                  maxLength={1000}
                  placeholder={PLACEHOLDER.company_message}
                  className="mkt_mask_items"
                />
              </FormLayoutFieldset>
            </div>

            <div className="w-308 mx-auto mt-48">
              <BaseButton className="w-full" size="m" theme="primary" type="submit">
                変更を保存
              </BaseButton>
            </div>
          </form>
        </section>
      </FormProvider>
    </AdminPage>
  );
};
