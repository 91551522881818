import '@/styles/common/Mypage/directbox.scss';

import React from 'react';

import BaseButton from '@/components/common/Button/BaseButton';

type TProps = {
  registration: boolean;
  offers: number;
};

/**
 *
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=382%3A40247
 *
 * オファー機能とは は未。
 * スマホ、タブレットのデザインはまだ
 *
 * オファー機能に登録、ログインしていたらregistration: true
 *
 */
export const MypageDirectbox = ({ registration, offers }: TProps): React.ReactElement => {
  return (
    <div className="mbx-directbox">
      <div className="mbx-directbox-wrap">
        {!registration ? (
          <div className="mbx-directbox-contents">
            <h1 className="mbx-directbox-title">
              あなたの経験や希望を、
              <br className="lg:hidden" />
              企業に直接伝えませんか?
            </h1>
            <p className="mbx-directbox-description">
              <span className="font-bold">「オファー機能」</span>に登録すると、
              <br /> あなたの転職活動の可能性が広がります。
              <br />
              意外な企業との出会いがあるかも。
              <br />
              まずは気軽に登録してみましょう。
            </p>
            <BaseButton size="m" theme="primary" href={'/mypage/direct'}>
              オファー機能に登録する
            </BaseButton>
          </div>
        ) : (
          <div className="mbx-directbox-contents">
            {offers !== 0 ? (
              <>
                <h1 className="mbx-directbox-title">
                  新着のオファーが <span className={'text-red-700'}>{offers}</span> 件あります！
                </h1>
                <p className="mbx-directbox-description">
                  あなたの作品や経験を見た企業から、
                  <br />
                  面接のオファーが届いています。
                  <br />
                  さっそくチェックしてみましょう。
                </p>
              </>
            ) : (
              <>
                <h1 className="mbx-directbox-title">オファーを待っています…</h1>
                <p className="mbx-directbox-description">
                  新着のオファーがあれば、
                  <br />
                  メールまたは「オファー履歴」のページで
                  <br />
                  お知らせします。
                </p>
              </>
            )}

            <BaseButton size="m" theme="primary" href="/mypage/direct_status">
              オファー履歴をチェック
            </BaseButton>
          </div>
        )}
      </div>
    </div>
  );
};
