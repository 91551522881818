import React from 'react';

export const Id3 = (): React.ReactElement => {
  const style = {
    img: 'mt-80 md:-mx-16 sm:-mx-16 sm:mt-40',
    text_l: 'mt-160 text-24_24 font-bold sm:mt-80 sm:text-18_24',
    text_m: 'text-18_28 font-bold mt-40 sm:text-15_22 sm:mt-20',
    text_s: 'text-16_28 sm:text-14_21',
  };
  return (
    <section>
      <div className="text-center pt-90 sm:pt-50">
        <p className="inline-block pb-8 text-18_18 font-bold border-b border-black sm:text-14_14">
          <span>03.</span>
          <span className="ml-18 sm:ml-14">編集 M.K.さん</span>
        </p>
        <h2 className="mt-18 text-29_46 font-bold sm:text-21_31">
          一貫したスキルがないと思っていた自分に、
          <br />
          ポートフォリオ作成が「自分の強み」を教えてくれた
        </h2>
      </div>
      <div className={style.img}>
        <img src="../images/Top/interview/interview03_image1.jpg" alt="" />
      </div>
      <div className="mt-40 sm:mt-20">
        <p className={style.text_s}>
          大学卒業後、電化製品のマニュアルなどを制作する会社に入社し、編集者としてのキャリアをスタートしたM.K.さん。マニュアル以外にも、会報誌や社内研修用の教材、販促イベント用のポスターのコピーなど、幅広く「言葉を使ったコンテンツの制作」に携わってきた。
          <br />
          <br />
          そんな中、ライフスタイルの変化をきっかけに転職を決意。しかし、いざ転職となったとき、10年以上勤めた会社で身に付けたスキルはさまざまあったものの、なにが自分の強みとなるのかはっきりせず不安が募った。正解がわからないながらも、手探りで始めた職務経歴書やポートフォリオの作成。これまでの実績を書き出すことで見えてきた自分の強みは、M.K.さんの自信へと繋がっていく。
        </p>
      </div>
      <h3 className={style.text_l}>編集者のポートフォリオとして意識したこと</h3>
      <p className={style.text_m}>―― 初めての転職だったということで、不安はありませんでしたか。</p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          正直、ありました。前職ではさまざまな制作案件を多く経験させてもらえたのはよかったのですが、裏を返せば、ある程度長く働いた割に、「自分はこれをずっとやってきた」という軸となるスキルがはっきりとしていなかったんです。なので、こんな状態で転職しても、クリエイターとしてやっていけるのか、他社でも同じように働けるのかと不安でした。
          <br />
          <br />
          そこで、まずやってみたのが、職務経歴書にこれまでの実績を全部書き込むことです。分量は説得力に繋がると思ったんですよ
          ね。転職に関するノウハウが書かれたさまざまな記事を読み漁り、職務経歴書を書きあげ、ポートフォリオも作成しました。
        </p>
      </div>
      <div className={style.img}>
        <img src="../images/Top/interview/interview03_image2.jpg" alt="" />
      </div>
      <p className={style.text_m}>
        ―― 編集者のポートフォリオを作成するうえで、どんなことを意識されましたか。
      </p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          見栄えのよい実績を集めましたが、ともするとそれはコンテンツの見え方からデザイナーの成果物のようになってしまいます。ですから編集者のポートフォリオとして見せるために、自分はこのコンテンツの企画に対してどんな思いがあり、何を表現したかったのか、デザインについてはどんなディレクションをしたのかなど、自分の考えをしっかり盛り込むようにしました。職務経歴書と同様、書けることは掘り下げて書くようにしましたね。
          <br />
          <br />
          転職ではこれまでの実績を数字で示したほうが、相手にわかりやすく自分のことを伝えられるとは思うのですが、BtoB向けの編集職である自分には、数字で出せる実績がほとんどありませんでした。なので、課題に対してどういう問題意識を持って取り組み、どういう考え方でプランをひねり出し、その結果どうなったのか。考え方の過程や実際にやったことを載せるよう意識しました。
        </p>
      </div>
      <p className={style.text_m}>―― 実際にポートフォリオを作成してみていかがでしたか。</p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          いざ作ってみると、言い方ひとつ、切り取り方ひとつで、相手への伝わり方は違ってくると気づきました。普通のものを少しでもよく見せる、表現する、伝える......これって、クリエイターの仕事の側面だと思うんです。これまでの自分をどう表現してどう伝えるかは、転職しようとする人が一番がんばるべきところであり、クリエイターの腕の見せ所なのかもな、と。
          <br />
          <br />
          自分の経歴を振り返っても一貫したスキルがないような気がして、自信がないまま転職活動を始めました。しかし、できる自分を想像して、職務経歴書やポートフォリオを作ってみると、見え方が変わってきたんです。そして見え方が変わると、考え方も前向きに変わっていくんですね。まさにこれまで気付くことができなかった「自分の強み」を教わったような感覚でした。
        </p>
      </div>
      <h3 className={style.text_l}>経験を活かして、新たな活躍の場へ</h3>
      <p className={style.text_m}>―― 現在はどのような業務に携わっていますか。</p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          学生向けの教材として、eラーニング用のコンテンツ制作全般に携わっています。学習内容をわかりやすく伝えるために、キャラクターを設定したり、セリフを考えたり。コンテンツの企画構成から見せ方まで幅広く担当しています。
          <br />
          <br />
          以前、学生向けオンラインイベントの運営を手伝ったとき、自分が作ったコンテンツに触れる子どもたちと直接話すことができました。BtoBだった前職ではなかなかできなかった体験です。ああ、こんなふうに使ってくれているんだ、自分はこの子どもたちのために仕事をしているんだという実感がわいて、とても感動的だったことを覚えていますね。いい転職ができて、本当によかったです。
        </p>
      </div>
      <div className={style.img}>
        <img src="../images/Top/interview/interview03_image3.jpg" alt="" />
      </div>
      <p className={style.text_m}>
        ―― これまでの経験を活かして、新たなことにも挑戦できているのですね。
      </p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          前職では何か一つのスキルを深く極めたわけではありませんが、その代わり、取材、ライティング、編集、社内向けコンテンツの企画、制作まで実にさまざまな業務を経験することができました。この「さまざまな経験」は、今になってすべて繋がっているように感じます。
        </p>
      </div>
      <p className={style.text_m}>
        ―― 今後は経験を活かしながら、どのように自身の活躍の場を広げていきたいとお考えですか。
      </p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          制作している教材コンテンツの改善プロジェクトにアサインされたので、直近ではそれに取り組む予定です。デザイン性や教材としてのわかりやすさ、子どもたちが興味を持てるようなストーリー設定や世界観などをもっとブラッシュアップして、コンテンツを充実させていきたいですね。
        </p>
      </div>
    </section>
  );
};
