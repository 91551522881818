import { createSlice } from '@reduxjs/toolkit';

import { reducers } from './reducer';
import { initialState } from './state';

/**
 * Directの検索条件
 */
export const directSearchConditionModule = createSlice({
  name: 'direct_search_condition',
  initialState,
  reducers,
});

export const { setSearchCondition } = directSearchConditionModule.actions;
