import React from 'react';

import { FormCombinationsFromToDropdown } from '@/componentsDirect/Parts/Form/Combinations/FromToDropdown';
import {
  FormContainerDropdownMultiple
} from '@/componentsDirect/Parts/Form/Container/DropdownMultiple';
import {
    FormContainerTextfieldMultiple
} from '@/componentsDirect/Parts/Form/Container/TextfieldMultiple';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { UserCondition } from '@/componentsDirect/Parts/UserCondition/Forms';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';

/**
 * 検索条件フォーム 就業状況
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65909
 */
export const DirectSearchFormSectionWorkExperience = (): React.ReactElement => {
  return (
    <UserCondition title="職務経験">
      <div className="mbx-utils-stack-v--22">
        <FormCombinationsFromToDropdown
          name="career_history_amount"
          label="経験社数"
          placeholder="選択してください"
          selectList={FORM_OPTIONS.companies_count}
          className="ml-24"
          fieldWidthClass="w-176"
        />
        <FormLayoutFieldset className="pl-24 w-full">
          <FormLabel label="これまでの在籍企業" type="small"></FormLabel>
          <FormContainerTextfieldMultiple
            name="career_history_companies"
            placeholder="例）株式会社マイナビデザイン（エンターキーで区切って複数入力可能）"
            className="mkt_non_mask_items"
          />
        </FormLayoutFieldset>

        <FormLayoutFieldset className="pl-24 w-full">
          <FormLabel label="これまでの部署名" type="small"></FormLabel>
          <FormContainerTextfieldMultiple
            name="career_history_section"
            placeholder="例）ITメディア事業部　デザイン部（エンターキーで区切って複数入力可能）"
            className="mkt_non_mask_items"
          />
        </FormLayoutFieldset>

        <FormLayoutFieldset className="ml-24 flex-grow">
          <FormLabel label="これまでの役職名" type="small"></FormLabel>
          <FormContainerTextfieldMultiple
            name="career_history_post"
            placeholder="例）デザインマネージャー　プロデューサー（エンターキーで区切って複数入力可能）"
            className="mkt_non_mask_items"
          />
        </FormLayoutFieldset>

        <FormLayoutFieldset className="ml-24">
          <FormLabel label="最大マネジメント人数" type="small"></FormLabel>
          <FormContainerDropdownMultiple
            name="mc_maximum_management_count"
            placeholder="選択してください（複数選択可）"
            selectList={FORM_OPTIONS.maximum_management_count}
            className="mkt_non_mask_items"
          ></FormContainerDropdownMultiple>
        </FormLayoutFieldset>

        <FormLayoutFieldset className="pl-24 w-full">
          <FormLabel label="担当内容" type="small"></FormLabel>
          <FormContainerTextfieldMultiple
            name="qa_answer"
            placeholder="例）Webデザイン　アートディレクション（エンターキーで区切って複数入力可能）"
            className="mkt_non_mask_items"
          />
        </FormLayoutFieldset>

        {/* TODO : 未定義だった中ので確認 */}
        <FormLayoutFieldset className="pl-24 w-full">
          <FormLabel label="経験" type="small"></FormLabel>
          <FormContainerTextfieldMultiple
            name="career_history_experiences"
            placeholder="例）Webデザイン　アートディレクション（エンターキーで区切って複数入力可能）"
            className="mkt_non_mask_items"
          />
        </FormLayoutFieldset>

        <FormLayoutFieldset className="pl-24 w-full">
          <FormLabel label="使用ツール・言語" type="small"></FormLabel>
          <FormContainerTextfieldMultiple
            name="career_history_tools"
            placeholder="例）Photoshop　Illustrator（エンターキーで区切って複数入力可能）"
            className="mkt_non_mask_items"
          />
        </FormLayoutFieldset>
      </div>
    </UserCondition>
  );
};
