import React, { useRef, useEffect, useMemo } from 'react';
import { Image } from '@/utils/api-client/index';
import { useDropzone } from 'react-dropzone';
import { IMAGE_SIZE } from '@/definition/IMAGE_SIZE';

type Props = {
  onChange: (file: File, index: number) => void;
  setSize: React.Dispatch<
    React.SetStateAction<{
      width: number;
      height: number;
    }>
  >;
  image: Image | null;
  editable?: boolean;
  type: 'pc' | 'tablet' | 'sp';
};

export const ImageResponsiveItem = (props: Props): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: [".jpg", ".jpeg", ".png", ".gif"]
  });

  const type = useMemo(() => {
    if (props.type === 'pc') {
      return {
        style: IMAGE_SIZE.responsive_pc,
        index: 2,
      };
    }
    if (props.type === 'tablet') {
      return {
        style: IMAGE_SIZE.responsive_tablet,
        index: 1,
      };
    }
    if (props.type === 'sp') {
      return {
        style: IMAGE_SIZE.responsive_sp,
        index: 0,
      };
    }
  }, [props.type]);

  const onClick = () => {
    if (type?.style) props.setSize(type.style);
    inputRef.current?.click();
  };

  useEffect(() => {
    if (acceptedFiles[0]) {
      if (type?.style) props.setSize(type.style);
      if (type?.index !== undefined) props.onChange(acceptedFiles[0], type.index);
    }
  }, [acceptedFiles[0]]);

  return (
    <div {...getRootProps({ className: `mbx-image_responsive_${props.type}` })}>
      <input type="file" ref={inputRef} name="" id="" {...getInputProps({multiple: false})} />
      <button className="mbx-image_responsive_input" onClick={onClick}>
        {props.image === null ? (
          <>
            {props.editable !== undefined && props.editable && (
              <div className="mbx-icon mbx-icon-Large32_Plus" />
            )}
          </>
        ) : (
          <img src={props.image.f_url} alt="" />
        )}
      </button>
    </div>
  );
};
