import logger from 'redux-logger';

import { configureStore, createSlice, getDefaultMiddleware } from '@reduxjs/toolkit';

import { reducers } from './reducer';
import { initialState } from './state';

const appModule = createSlice({
  name: 'tasks',
  initialState,
  reducers: reducers,
});

const rootReducer = appModule.reducer;

export const {
  toggleImageCropper,
  toggleLoading,
  auth,
  isLoggedIn,
  isLoggedOut,
  userInfo,
  userInfoForceUpdate,
  setUserEmail,
  userResponseAll,
  notificationError,
  notificationSuccess,
  setPortfolioStatusOfPDF,
  setPortfolioTicketID,
  setPortfolioPDFDownloadModal,
  setUploadedImage,
  setSaved,
  setPortfolio,
  setPrepareFrag,
  setPathAfterLogin,
  setPathAfterLogout,
  setInputClearingInProgress,
  setDataLayer,
  setDataLayerChangedBy,
} = appModule.actions;

export type RootState = ReturnType<typeof rootReducer>;

let middleware = getDefaultMiddleware();

if (process.env.NODE_ENV === 'development') {
  middleware = middleware.concat(logger);
}

const store = configureStore({
  reducer: rootReducer,
  middleware: middleware,
});

export default store;
