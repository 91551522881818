import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

type TProps = {
  src: string;
  width?: number;
  height?: number;
  widthUnit?: string;
  heightUnit?: string;
  loadAsBackgroundImage?: boolean; // 背景画像を利用するかどうか
  animeClass?: string;
  className?: string;
};

/**
 * 画像をロードしたらフェードして表示するデザインUIとは別の汎用コンポーネント
 */
export const ImageFade = ({
  src,
  width,
  height,
  widthUnit = 'px',
  heightUnit = 'px',
  loadAsBackgroundImage = false,
  animeClass = 'mbx-anime-img',
  className,
}: TProps): React.ReactElement => {
  const attributes = {
    width: width ? `${width}${widthUnit}` : '',
    height: height ? `${height}${heightUnit}` : '',
  };

  const imgRef = useRef(null);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    if (src) {
      const img = document.createElement('img');
      img.onload = () => {
        setLoaded(true);
      };
      img.src = src;
    }
  }, [src]);

  return (
    <CSSTransition in={loaded} timeout={300} nodeRef={imgRef} classNames={animeClass}>
      {loadAsBackgroundImage ? (
        <div
          className={className + ' opacity-0'}
          ref={imgRef}
          style={{ backgroundImage: `url('${src}')`, ...attributes }}
        ></div>
      ) : (
        <img ref={imgRef} className={className + ' opacity-0'} src={src} {...attributes} />
      )}
    </CSSTransition>
  );
};
