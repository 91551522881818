import React, { useState ,useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Ed17 = (): React.ReactElement => {
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if(isAuth){
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0017");
    if(!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g,"/")} 12:00:00`);
    const now = new Date;
    if(now > deadlineDate)setDeadline(true);
  }, []);

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16">
          <img className={style.img} src="../images/Top/event/event_0017_mv.png" width="978" height="504" alt="" />
        </div>
        <h1 className="mt-56 sm:mt-30 text-29_46 sm:text-18_24 font-bold text-center">
          1/26（木）20:00 -<br />
          MATCHBOX活用ワークショップ<br />
          採用視点から見る<br />
          Webディレクターの書類としての<br className="md:hidden lg:hidden" />
          ポートフォリオ作成
        </h1>
        { deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <section>
          <section className="mt-80 sm:mt-55">
            <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">以下に該当する方はオススメです。</h2>
            <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
              <ul className="text-16_28 sm:text-14_21">
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>転職活動の準備が進まない。何を準備すれば良いかわからない。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>Webディレクター経験者から書類作成について聞きたい。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>Webディレクターのキャリアをまとめることを難しいと感じている。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>転職活動を始めているけど、書類選考が通過しない。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>自分が作成した書類について採用担当者目線からアドバイスが欲しい。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>他の人のキャリアのまとめ方を知りたい。</span>
                </li>
                <li className="flex">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>MATCHBOXの効果的な使い方を知りたい。</span>
                </li>
              </ul>
            </div>
          </section>
          { !deadline && !isLogin &&
            <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
              <div className='flex flex-col items-center justify-center'>
                <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
                <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
              </div>
            </section>
          }
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">ワークショップについて</h2>
          <div className="mb-30">
            <img src="../images/Top/event/event_workshop_image.jpg" width="980" height="374" alt="" />
          </div>
          <p className="text-16_28 sm:text-14_21">
            転職をお考えのWebディレクターがまず悩まれるのは、多岐に渡るご自身のこれまでの業務やスキルをどのように効率的にアピールし、書類通過率を上げるか、ということではないでしょうか。私たちはそのようなお悩みに対し、Webディレクターの方が書類選考を通過させるための最大の武器はポートフォリオである旨をこれまでセミナーやワークショップで伝えておりました。<br />
            <br />
            今回、キャリア、スキル、能力をわかりやすく余すことなくアピールし、時間を掛けずにポートフォリオを作る方法を採用目線からお話いただくワークショップを開催します。<br />
            当日は、100％ポートフォリオとして完成していなくてもよいので一度自分なりにまとめ、その上でご参加ください。<br />
            当日は、講義を聞いていただいた後、ご自身の実績をまとめ、ポートフォリオを形にするためのワークの時間を取ります。その後、講師からその場でフィードバックを受けたい方を募り、細かいご質問やアドバイスをその場で受ける流れとなります。ベースをMATCHBOXで作っていただければ、テンプレートとなっているため時間を掛けずに作成することが可能です。<br />
            採用担当者が行うワークショップに参加することで、ご自身の悩み払拭につながる可能性があるため、この機会を活かしてご希望の転職を成功させましょう。
          </p>
          <section className="mt-80 sm:mt-55 p-40 sm:p-24 bg-gray-50 text-left">
            <h3 className="text-18_24 sm:text-14_21 font-bold">注意点</h3>
            <ul className="text-16_28 sm:text-14_21 mt-16">
              <li className="flex">
                <span>・</span><span>こちらのワークショップは流れとしてMATCHBOXをベースにポートフォリオをまとめていただく流れを取ります。そのため参加の条件として、MATCHBOXのアカウントをご準備ください。</span>
              </li>
              <li className="flex">
                <span>・</span><span>オンライン上ですが、積極的にコミュニケーションを取りたいと思いますので、できればカメラ・マイクオンでご参加ください。</span>
              </li>
              <li className="flex">
                <span>・</span><span>ソロワーク後、講義を踏まえて発表いただきます。ご了承いただいた上でご参加ください。</span>
              </li>
              <li className="flex">
                <span>・</span><span>ワークショップヘの応募、Zoomへの入室時ですが、MATCHBOXログインIDに指定しているメールアドレスをご使用ください。</span>
              </li>
            </ul>
          </section>
        </section>
        { !deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">講師プロフィール</h2>
          <div className="event-instructor box-line sm:p-16">
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0013_prof-01.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                峯藤 誠<span className="inline-block">（MAKOTO MINEFUJI）</span>
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-40 sm:mb-18">
                株式会社ジュニ リードディレクター / マネージャー
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                2008年からWeb・IT業界に携わる。数々の企業に在籍しながらWebサイト制作やシステム開発のディレクションに携わる。2018年から株式会社ジュニにプランニング・ディレクションチームのメンバーとしてジョイン。案件の企画提案、要件定義、画面設計、進行管理、解析などWebディレクション業務全般に渡って一通り経験。 現在は、制作や開発業務のディレクションを行いながらも、メンバーのマネジメントの他に採用活動や教育も担当している。<br />
                ワンストップで幅広い相談に対応できるということを意識してディレクションには取り組んでいる。
              </dd>
            </dl>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap" rowSpan={2}>
                  <span className="sm:block">20：00</span><span className="sm:block">～</span><span className="sm:block">22：00</span>
                </th>
                <td>
                  ＜前半＞
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>ワークショップ概要説明</span></li>
                    <li className="flex"><span>・</span><span>峯藤氏による見本ポートフォリオの紹介とアドバイス</span></li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>＜後半＞
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>参加者ソロワークの時間</span></li>
                    <li className="flex"><span>・</span><span>発表</span></li>
                    <li className="flex"><span>・</span><span>フィードバック</span></li>
                    <li className="flex"><span>・</span><span>ブリーフィング（まとめ）</span></li>
                    <li className="flex"><span>・</span><span>質疑応答</span></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        { !deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2023/1/26（木）20:00～<br /> [入室開始] 19:45～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">定員</th>
                <td>20名</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。
                </td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">対象者</th>
                <td>転職活動中、もしくは、転職準備中のWebディレクターの方</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">準備物</th>
                <td>
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>ご自身のPC</span></li>
                    <li className="flex"><span>・</span><span>MATCHBOXのアカウントを作成しログイン状態でご参加ください。</span></li>
                    <li className="flex"><span>・</span><span>作成済み、もしくは、作成中の選考書類（履歴書・職務経歴書・ポートフォリオなど）</span></li>
                    <li className="flex"><span>・</span><span>ポートフォリオの作成に必要な素材など</span></li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.ワークショップ申し込み</dt>
                    <dd className="mb-38 sm:mb-16">申し込みフォームに必要事項をご入力の上、お申込みください。</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">開催数日前に事務局よりメールにて、当日の詳細についてご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.ワークショップ当日</dt>
                    <dd>Zoomへアクセスしてください。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        { !deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">開催について</h3>
          <p className="mt-30 sm:mt-16 text-16_28 sm:text-14_21">
            Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2,000名を超える「0からのWebディレクション講座」を生み出したメンバーでもある一般社団法人ディレクションサポート協会 会長の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。
          </p>
        </section>
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div>
    </div>
  );
};
