import '@/styles/common/Direct/OfferList.scss';

import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import CompanyPlaceholder from '@/assets/images/common/company_placeholder.svg';
import Status from '@/components/common/Admin/OfferList';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { DirectCompanyModel, Offer } from '@/utils/api-client';
import { convertLineBreak, linkifyUrls } from '@/utils/string';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { ButtonIconButton } from '@/components/common/Button/IconButton';

import { OffersModalMessageFromCompanyComponent } from './ModalMessageFromCompany';
import { AdminOffersModalMessageFromCompanyComponent } from '@/componentsAdmin/Offers/ModalMessageFromCompany';


// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type Props = {
  offer: Offer;
  statusUpdate: (id: number, status: number, comment: string) => Promise<void>;
  hover?: boolean;
  admin?: boolean;
};

/**
 * User / Admin オファー履歴 リスト
 * User / Admin で共有してる
 * # User オファー履歴
 * /mypage/direct_status
 * # Admin オファー・ピックアップ履歴
 * /admin/users/C05853373/offers
 */
function OfferList({ offer, statusUpdate, hover = true, admin }: Props): React.ReactElement {
  // ====================================================================================================
  //
  // CONST
  //
  // ====================================================================================================
  const props = { offer, statusUpdate, hover };

  // ====================================================================================================
  //
  // hooks
  //
  // ====================================================================================================
  const mq = useMBXMediaQuery();
  const [company, setCompany] = useState<DirectCompanyModel>();

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    setCompany(props.offer.senter as DirectCompanyModel);
  }, [props.offer]);

  const getFormOptions = (key: 'business_type' | 'prefecture') => {
    if (company) {
      return company[key]
        ? FORM_OPTIONS[key].find((k) => k.value === company[key]?.toString())?.children
        : '';
    }
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const format = useMemo(() => {
    const created_at = props.offer.created_at?.replace(/-/g, '/');
    const business_type = getFormOptions('business_type');
    const p = getFormOptions('prefecture');
    const address = p ? p + company?.address : '';
    const employee_amount = FORM_OPTIONS.employee_amount.find(
      (n) => n.value === company?.employee_amount?.toString()
    )?.children;

    return { created_at, business_type, address, employee_amount };
  }, [props.offer, company]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <section className="mbx-offer">
      <div className="flex items-center justify-between w-full sm:flex-col">
        <div className="flex items-center w-full sm:flex-col sm:items-start sm:w-auto">
          <div className="flex items-center">
            {!props.offer.is_read && (
              <div>
                <div className="w-8 h-8 bg-red-700 rounded-full mx-8 sm:w-6 sm:h-6 sm:mx-0 sm:mr-4"></div>
              </div>
            )}
            <p
              className={cn(
                'w-85',
                'flex-shrink-0',
                'mr-8',
                'sm:mr-0',
                mq.sm ? 'mbx-typography--subhead_2' : 'text-14_16',
                {
                  ['font-bold']: !props.offer.is_read,
                }
              )}
            >
              {format.created_at}
            </p>
          </div>

          {!mq.sm && (
            <div>
              <div className="flex items-center w-32 h-32 mr-8 sm:mr-4">
                <img
                  src={props.offer.senter?.icon ? props.offer.senter.icon : CompanyPlaceholder}
                  alt=""
                  className="object-contain w-full h-auto rounded-full"
                />
              </div>
            </div>
          )}

          <div className="flex items-center justify-between hover-area w-full sm:justify-start sm:mt-12">
            {mq.sm && (
              <div className="mr-4">
                <div className="flex items-center w-32 h-32">
                  <img
                    src={props.offer.senter?.icon}
                    alt=""
                    className="object-contain w-full h-auto rounded-full"
                  />
                </div>
              </div>
            )}
            <div
              className={cn(
                'name',
                'text-blue-700',
                'my-8',
                'mr-12',
                'sm:my-0',
                mq.sm ? 'mbx-typography--subhead_2' : 'mbx-typography--body_1'
              )}
            >
              {!admin && (
                <div className="flex items-center lg:mt-4 md:mt-6 mb-4">
                  {(props.offer.status === 3 ||
                    props.offer.status === 5 ||
                    props.offer.status === 6 ||
                    props.offer.status === 7) && (
                    <>
                      <p className="px-12 py-2 rounded-full text-caption_2 text-10_16 font-bold bg-red-200 text-red-700">
                        一次面接オファーが届きました
                      </p>
                      <TooltipMessageMatchbou
                        className="ml-4"
                        message="「一次面接オファー」に応諾すると、企業と一次面接を行うことができます。"
                        position={['0', '-94px']}
                      >
                        <ButtonIconButton
                          hitArea="mini"
                          iconName="Info"
                          type="gray"
                          focus={false}
                        />
                      </TooltipMessageMatchbou>
                    </>
                  )}
                  {(props.offer.status === 8 ||
                    props.offer.status === 9 ||
                    props.offer.status === 10 ||
                    props.offer.status === 11) && (
                    <>
                      <p className="px-12 py-2 rounded-full text-caption_2 text-10_16 font-bold bg-blue-200 text-blue-700">
                        カジュアル面談オファーが届きました
                      </p>
                      <TooltipMessageMatchbou
                        className="ml-4"
                        message="「カジュアル面談オファー」に応諾すると、合否を判断されずに、気軽に企業と意見交換をすることができます。"
                        position={['0', '-94px']}
                      >
                        <ButtonIconButton
                          hitArea="mini"
                          iconName="Info"
                          type="gray"
                          focus={false}
                        />
                      </TooltipMessageMatchbou>
                    </>
                  )}
                </div>
              )}
              {admin ? (
                <Link to={`/admin/companies/${props.offer.senter?.id}`}>
                  <span className={cn({ ['font-bold']: !props.offer.is_read })}>
                    {company?.name}
                  </span>
                </Link>
              ) : (
                <a href={company?.url} target="_blank" rel="noreferrer">
                  <span className={cn({ ['font-bold']: !props.offer.is_read })}>
                    {company?.name}
                  </span>
                </a>
              )}
              {props.offer.position?.name && (
                <>
                  <dl className="flex text-caption_2 text-black mt-8">
                    <dt className="flex-shrink-0 w-70">ポジション名</dt>
                    <dd>：{props.offer.position.name}</dd>
                  </dl>
                  <dl className="flex text-caption_2 text-black">
                    <dt className="flex-shrink-0 w-70">ポジション詳細</dt>
                    <dd
                      className="mbx-utils-indent"
                      dangerouslySetInnerHTML={{
                        __html: `：${convertLineBreak(linkifyUrls(props.offer.position.body))}`,
                      }}
                    ></dd>
                  </dl>
                </>
              )}

              {/* オファー時のポジションがなく、リアクション時のポジションがある場合は表示 */}
              {!props.offer.position?.name && props.offer.reaction_position?.name && (
                <>
                  <dl className="flex text-caption_2 text-black mt-8">
                    <dt className="flex-shrink-0 w-70">ポジション名</dt>
                    <dd>：{props.offer.reaction_position.name}</dd>
                  </dl>
                  <dl className="flex text-caption_2 text-black">
                    <dt className="flex-shrink-0 w-70">ポジション詳細</dt>
                    <dd
                      className="mbx-utils-indent"
                      dangerouslySetInnerHTML={{
                        __html: `：${convertLineBreak(
                          linkifyUrls(props.offer.reaction_position.body)
                        )}`,
                      }}
                    ></dd>
                  </dl>
                </>
              )}
            </div>
            <div className="mr-20 sm:mr-0">
              {props.offer.status !== 1 && props.offer.status !== 2 &&
                <>
                  {admin ? (
                    <AdminOffersModalMessageFromCompanyComponent
                      offer={props.offer}
                    ></AdminOffersModalMessageFromCompanyComponent>
                  ) : (
                    <OffersModalMessageFromCompanyComponent
                      company={company}
                      offer={props.offer}
                    ></OffersModalMessageFromCompanyComponent>
                  )}
                </>
              }
            </div>
          </div>
          <div className="flex items-center sm:mt-24 sm:pb-6 sm:w-full">
            <div className="w-200 sm:w-full status">
              {props.offer.id && (
                <Status
                  id={props.offer.id}
                  status={props.offer.status as 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11}
                  rate={props.offer.rate as 1 | 2 | 3}
                  statusUpdate={props.statusUpdate}
                  admin={admin}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OfferList;
