import React from 'react';
import cn from 'classnames';

type Props = {
  title: string;
  border?: boolean;
  id?: string;
  children: React.ReactElement | string | React.ReactElement[];
};

function ItemsWrapper(props: Props): React.ReactElement {
  return (
    <section
      className={cn('py-40', { 'border-b border-gray-300': props.border }, 'sm:pt-24')}
      id={props.id}
    >
      <p className="mbx-typography--subhead_1">{props.title}</p>
      <p className="mt-16">{props.children}</p>
    </section>
  );
}

export default ItemsWrapper;
