import cn from 'classnames';
import React from 'react';

import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';

type TFieldProps = {
  key: string;
  name: string;
  defaultValue: string;
  placeholder: string;
  className?: string;
  onRemove: React.MouseEventHandler<HTMLButtonElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  // item: FieldArrayWithId<TFormValues, 'education', 'id'>;
};

export const QuestionField: React.VFC<TFieldProps> = ({
  onRemove,
  onBlur,
  onFocus,
  name,
  defaultValue,
  placeholder,
  className,
}) => {
  return (
    <FormLayoutFieldset>
      <></>
      <div className="flex">
        <div className="flex flex-auto sm:flex-wrap">
          <FormContainerTextfield
            defaultValue={defaultValue}
            name={name}
            placeholder={placeholder}
            className={cn('lg:mr-8 md:mr-8 w-full', className)}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        </div>
        <div className="sm:hidden">
          <div className="flex">
            <ButtonIconButton type="gray" iconName="Sortable" className="dragHandle" />
            <ButtonIconButton type="gray" iconName="Clear" onClick={onRemove} />
          </div>
        </div>
      </div>
    </FormLayoutFieldset>
  );
};
