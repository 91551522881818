import '@/styles/common/Direct/MyNaviSelectForm.scss';

import React from 'react';
import { OnboardingPageWrapper } from './PageWrapper';
import { PageDots } from './PageDots';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import messageImg from '@/images/direct-message-description.png';

type TStep = {
  step1: boolean,
  step2: boolean,
  step3: boolean,
  step4: boolean,
  step5: boolean,
  step6: boolean,
};

type Props = {
  page: number;
  step: TStep;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalDots: number;
  onNext: (number: number) => void;
  onSkip: (number: number) => void;
  isDone: boolean;
  onClose: () => void;
};

export const OnboardingPage_4 = (props: Props): React.ReactElement => {
  const skipPageCheck = () => {
    const skipPage = Object.keys(props.step).find((stepNum, i) => (props.page + 1) <= i && props.step[stepNum as keyof TStep]);

    if (skipPage === 'step6' && !props.isDone) {
      props.onClose();
    } else {
      props.onSkip(4);
    }
  }

  return (
    <DataLayerPushContainer
      data={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'modal_open',
        virtualPageName: 'on-boarding_step_4'
      }}
    >
    <div>
      <OnboardingPageWrapper
        description={
          <span>
            {props.step.step1 && "最後に、"}求職者へのメッセージ<br />
            を入力しましょう。
          </span>
        }
      >
        <div>
          <p className="mt-24 text-14_20">求職者へのメッセージを入力することで、オファー時に求職者（候補者）に通知され、応諾率を高めることができます。</p>
          <div className="mt-26">
            <img src={messageImg} alt="" />
            <div className="pb-20">
              <PageDots page={props.page} setPage={props.setPage} step={props.step} totalDots={props.totalDots} unClick className="mt-48" />
              <div className="w-240 m-auto mt-48">
                <BaseButton
                  theme="primary"
                  size="m"
                  className="w-full"
                  onClick={() => props.onNext(4)}
                >
                  求職者のメッセージを入力する
                </BaseButton>
              </div>
              <div className="w-240 m-auto mt-24">
                <BaseButton
                  theme="link"
                  size="m"
                  className="w-full"
                  onClick={skipPageCheck}
                >
                  あとで設定する
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </OnboardingPageWrapper>
    </div>
    </DataLayerPushContainer>
  );
};
