import React, { useState, useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Ed28 = (): React.ReactElement => {
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if (isAuth) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0028");
    if (!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g, "/")} 12:00:00`);
    const now = new Date;
    if (now > deadlineDate) setDeadline(true);
  }, []);

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16">
          <img className={style.img} src="../images/Top/event/event_0028_mv.jpg" width="978" height="504" alt="" />
        </div>
        <h1 className="mt-56 sm:mt-30 text-29_46 sm:text-18_24 font-bold text-center">3/28（火）19:30 -<br />
          選考通過につながるポートフォリオ講座<br />～最新の転職市場とドキュメント作成術～</h1>
        {deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>以下に該当する方はオススメです。</h2>
          <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4"><span><i
                className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span> 転職を考えて始めている</span>
              </li>
              <li className="flex mb-4"><span><i
                className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span> 転職活動の準備を始めた</span>
              </li>
              <li className="flex mb-4"><span><i
                className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>
                  ポートフォリオの効果的な作り方を知りたい</span></li>
              <li className="flex mb-4"><span><i
                className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span> 選考通過率を上げたい</span></li>
              <li className="flex mb-4"><span><i
                className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>
                  ポートフォリオ作成のヒントが欲しい</span></li>
              <li className="flex"><span><i
                className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>
                  セミナーを踏まえて転職の相談をしたい</span></li>
            </ul>
          </div>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className='mt-80 sm:mt-55'>
          < h3 className='text-18_24 sm:text-14_21 font-bold'>イベント内容</h3>
          <div className="mt-30 sm:mt-16 mb-30">
            <img src="../images/Top/event/event_seminar_image.jpg" width="980" height="374" alt="" />
          </div>
          <p className="text-16_28 sm:text-14_21 mt-30">
            デザイナーが転職活動を行ううえで必要不可欠なポートフォリオ。しかし、「何から始めたら良いのかわからず、制作がなかなか進まない」「多忙な日々の仕事に追われながら、ポートフォリオを作成するのは大変」など、デザイナーにとってポートフォリオの作成が高いハードルとなっているようです。<br />
            <br />
            そこで、昨今のWeb業界の転職市場の動向を踏まえ、現在どんな経験をしている人が企業から求められているのか、その上で採用担当者にきちんとキャリアと経験が伝わるポートフォリオを作成するためのポイントをお伝えするセミナーを3/28（火）19:30～にオンライン開催いたします。セミナーでは、転職エージェント目線からのWeb・IT業界の採用傾向をお伝えします。それを踏まえて転職準備をMATCHBOXを使って、時間をかけずきちんとポイントをおさえて作成し、企業に伝える方法をお話します。<br />
            <br />
            興味がある方はお早めにご応募ください。
          </p>
        </section>
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">参加者の声</h3>
          <div className='mt-30 mb-15 sm:mt-16 sm:mb-8 p-40 sm:p-24 box-line text-left'>
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4"><span>・</span><span>ポートフォリオのまとめ方、何をまとめればよいか簡潔にわかり、作成を進められそう。<br />
                （28歳 制作会社Webデザイナー 8年目）</span></li>
              <li className="flex mb-4"><span>・</span><span>自己分析や履歴書・職務経歴書のまとめ方についてイメージがついた。<br />
                （25歳 制作会社 グラフィックデザイナー 3年目）</span></li>
              <li className="flex"><span>・</span><span>MATCHBOXの効果的な使い方がよくわかった。<br />
                （26歳 Webデザイナー 4年目）</span></li>
            </ul>
          </div>
          <p className="text-right text-16_28 sm:text-14_21">※参加者の声の一部を掲載しています</p>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>講師プロフィール</h2>
          <div className="event-instructor box-line sm:p-16">
            <div className="instructor-photo"><img src="../images/Top/event/event_0004_prof.jpg" alt="" /></div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">南 文乃<span className="inline-block">（MINAMI AYANO）</span>
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-40 sm:mb-18">株式会社マイナビワークス <br
                className="md:hidden lg:hidden" />マーケティング部<br />
                転職セミナー企画担当</dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                新卒で大阪のデザイン会社に就職。Web・グラフィック制作の営業から進行管理、Webディレクターを経験。社会人向け大学院運営事務局を経てマイナビワークス入社5年目。当初から主にセミナー企画・運営にてWeb・ゲーム・IT業界に置ける転職支援に従事。求職者側のキャリア相談等も並行して担当している。
              </dd>
            </dl>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap" rowSpan={2}><span
                  className="sm:block">19：30</span><span className="sm:block">～</span><span className="sm:block">21：00</span>
                </th>
                <td>＜前半＞ <br className="md:hidden lg:hidden" />現在の転職市場とそこに飛び込み、勝つための考え方<ul
                  className="text-16_28 sm:text-14_21 mt-8">
                  <li className="flex"><span>・</span><span>自己紹介</span></li>
                  <li className="flex"><span>・</span><span>Web業界転職市場のリアル</span></li>
                  <li className="flex"><span>・</span><span>キャリアの振り返り方</span></li>
                  <li className="flex"><span>・</span><span>自己分析と転職への考え方</span></li>
                  <li className="flex"><span>・</span><span>現在・過去・未来をまとめる</span></li>
                </ul>
                </td>
              </tr>
              <tr>
                <td>＜後半＞ <br className="md:hidden lg:hidden" />転職市場でモテるポートフォリオの設計と事例<ul
                  className="text-16_28 sm:text-14_21 mt-8">
                  <li className="flex"><span>・</span><span>ポートフォリオの目的</span></li>
                  <li className="flex"><span>・</span><span>事例紹介</span></li>
                  <li className="flex"><span>・</span><span>質疑応答</span></li>
                </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2023/3/28（火）19:30～<br />
                  [入室開始] 19:20～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />
                  参加者の皆さまには別途URLをお送りします。
                </td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">対象者</th>
                <td>転職を実際に考えている、転職準備中のWebデザイナー</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.参加申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink"
                      href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.開催当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className='mt-80 sm:mt-55'>
          <h3 className='text-18_24 sm:text-14_21 font-bold'>開催について</h3>
          < p className='text-16_28 sm:text-14_21 mt-30 sm:mt-16'>
            Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2, 000名を超える「0からのWebディレクション講座」を生み出したメンバーでもある一般社団法人ディレクションサポート協会 会長の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。          </p >
        </section >
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div>
    </div>
  );
};
