import React, { useState } from 'react';

export const TableHeader = (): React.ReactElement => {
  const [toolTip, setTooltip] = useState({ everyone: false, action: false });

  return (
    <section className="table-header pl-16 text-10_24 text-blue-700 bg-gray-200 border-b border-gray-300">
      <div className="flex items-center h-40">
        <div className="w-84">
          <p>ピックアップ日</p>
        </div>
        <div className="w-74 ml-16">
          <p>MB ID</p>
        </div>
        <div className="w-127 ml-16">
          <p>職種</p>
        </div>
        <div className="w-100 ml-16">
          <p>ポジション</p>
        </div>
        <div className="w-88 ml-33">
          <p>ステータス</p>
        </div>
        <div className="w-40 ml-16 text-center">
          <p>マッチ度</p>
        </div>
        <div
          className="w-60 ml-16 cursor-pointer"
          onMouseOver={() => setTooltip({ everyone: true, action: false })}
          onMouseOut={() => setTooltip({ everyone: false, action: false })}
        >
          <p>みんなの意見</p>
          <i className="mbx-icon mbx-icon-Info"></i>
          {toolTip.everyone && (
            <div className="tool-tip w-195">
              <p className="mbx-typography--caption_2">社内で意見した人数を表示します。</p>
            </div>
          )}
        </div>
        <div
          className="w-50 ml-36 cursor-pointer"
          onMouseOver={() => setTooltip({ everyone: false, action: true })}
          onMouseOut={() => setTooltip({ everyone: false, action: false })}
        >
          <p>アクション</p>
          <i className="mbx-icon mbx-icon-Info"></i>
          {toolTip.action && (
            <div className="tool-tip w-280">
              <p className="mbx-typography--caption_2">
                「みんなの意見」を元に管理者が「オファーする」「マイナビワークスに相談する」「見送る」のいずれかのアクションを起こしたかどうかを表示します。
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
