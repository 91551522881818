import cn from 'classnames';
import React from 'react';

type TNaviInpageItemProps = {
  done: 0 | 1;
  disabled?: boolean;
  href: string;
  children: string;
};

/**
 * item
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=348%3A26697
 *
 * - クリックでhrefに指定したIDの要素までスクロールします
 * - disabledは省略可
 *
 */
export const NaviInpageItem = ({
  done,
  disabled,
  href,
  children,
}: TNaviInpageItemProps): React.ReactElement => {
  const inPageScroll = (e: React.SyntheticEvent<HTMLLIElement>): void => {
    const id = e.currentTarget.dataset.href;
    if (id && document.getElementById(id) !== null) {
      const targetElement = document.getElementById(id);
      if (!targetElement) {
        new Error('ターゲットが存在しません');
        return;
      }
      const rectTop = targetElement.getBoundingClientRect().top;
      const offsetTop = window.pageYOffset;
      const buffer = 0;
      const top = rectTop + offsetTop - buffer;
      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    }
  };
  return (
    <li
      className={cn(
        'mbx-naviInpage-item',
        'text-navText',
        'text-button_small',
        'font-bold',
        'py-16',
        {
          'text-navText-disabled': disabled,
          'hover:text-navText-hover': !disabled,
        }
      )}
      onClick={inPageScroll}
      data-href={href}
    >
      <i
        className={cn('mbx-naviInpage-item-icon', 'mbx-icon', {
          'mbx-icon-Done': done === 1,
          'mbx-icon-Edit': done === 0,
          'text-navText-hover': !disabled,
        })}
      ></i>
      {children}
    </li>
  );
};
