import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { FORM_OPTIONS, TFormOption } from '@/definition/FORM_OPTIONS';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';
import { useOfferPositions } from '@/hooksDirect/useOfferPositions';
import { RootState } from '@/reduxAdmin';
import { DirectUserResponse } from '@/utils/api-client';
import {
  getDirectOptionChildrenFromValue,
  getOptionChildrenFromValue,
} from '@/utils/optionValueToChildren';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type TInput = {
  matchbox_id: string,
  status: string;
  rate: string;
  job: string;
  position: string;
  sort: string;
};

type Props = {
  setFilterValues: React.Dispatch<
    React.SetStateAction<{
      matchbox_id: string,
      status: string;
      rate: string;
      job: string;
      position: string;
      sort: string;
    }>
  >;
};

export const Filters = (props: Props): React.ReactElement => {
  // ====================================================================================================
  //
  // CONST
  //
  // ====================================================================================================
  const { push } = React.useContext(DataLayerContext);
  const user: DirectUserResponse | null = useSelector(
    (state: RootState) => state.direct_auth.userInfo
  );
  const karteEvent = () => {
    const filter: string[] = ['N/A', 'N/A', 'N/A'];
    filter[0] = watch('job')
      ? getOptionChildrenFromValue('experienced_job', parseInt(watch('job')))
      : 'N/A';
    filter[1] = watch('status')
      ? getDirectOptionChildrenFromValue('directSearchStatus', parseInt(watch('status')))
      : 'N/A';
    filter[2] = watch('rate')
      ? getDirectOptionChildrenFromValue('offer_mw_status', parseInt(watch('rate')))
      : 'N/A';
    filter[3] =watch('matchbox_id')
      ? watch('matchbox_id')
      : 'N/A';

    const cSortBy = getDirectOptionChildrenFromValue('sort', parseInt(watch('sort'))) || '日付順';
    push({
      event: 'pageView',
      actionType: 'page_view',
      actionName: 'page_view',
      cNarrowDown: filter.join('::'),
      cSortBy,
    });
  }
  const onBlur = ((e: React.FocusEvent<HTMLInputElement>) => {
    // 削除ボタン判定
    const related = e.relatedTarget as HTMLHtmlElement;
    if(related) {
      const className = related.className;
      if (className.includes('type-delete')) {
        e.target.value = '';
        methods.setValue('matchbox_id', '');
      }
    }
    props.setFilterValues({
      matchbox_id: e.target.value,
      status: watch('status'),
      rate: watch('rate'),
      job: watch('job'),
      sort: watch('sort'),
      position: watch('position'),
    });
    karteEvent();
  });

  // ====================================================================================================
  //
  // custom hooks
  //
  // ====================================================================================================
  const { offerPositions, getOfferPositions } = useOfferPositions();

  // ====================================================================================================
  //
  // RHF
  //
  // ====================================================================================================
  const methods = useForm<TInput>({
    defaultValues: {
      matchbox_id: '',
      status: '',
      rate: '',
      job: '',
      position: '',
      sort: '',
    },
  });
  const { watch } = methods;

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    props.setFilterValues({
      matchbox_id: watch('matchbox_id'),
      status: watch('status'),
      rate: watch('rate'),
      job: watch('job'),
      sort: watch('sort'),
      position: watch('position'),
    });

    karteEvent();
  }, [watch('status'), watch('rate'), watch('job'), watch('sort'), watch('position')]);

  useEffect(() => {
    // 一覧取得
    getOfferPositions();
  }, []);

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const sortItem = useMemo(() => {
    if (user?.user_info.user_type === 'direct_user') {
      return DIRECT_FORM_OPTIONS.sort.filter((s) => {
        return Number(s.value) < 7;
      });
    }
    return DIRECT_FORM_OPTIONS.sort;
  }, [user]);

  const POSITIONS_OPTIONS = useMemo(() => {
    if (!offerPositions || offerPositions?.length === 0) {
      return [
        {
          value: '',
          children: '',
        },
      ];
    } else {
      return offerPositions?.map((position) => {
        if (!position.id) return;
        return {
          value: position.id.toString(),
          children: position.name,
        };
      });
    }
  }, [offerPositions]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <div className="filters">
      <FormProvider {...methods}>
        <form>
          <div className="flex">
            <div className="w-168">
              <FormLayoutFieldset>
                <FormLabel label="MATCHBOX IDで検索" type="small"></FormLabel>
                <FormContainerTextfield
                  name="matchbox_id"
                  className="mkt_mask_items"
                  onBlur={(e) => onBlur(e)}
                />
              </FormLayoutFieldset>
            </div>
            <div className="w-168 ml-16">
              <FormLayoutFieldset>
                <FormLabel label="並べ替え" type="small"></FormLabel>
                <FormContainerDropdown
                  className="select"
                  name="sort"
                  placeholder="日付順"
                  selectList={sortItem}
                ></FormContainerDropdown>
              </FormLayoutFieldset>
            </div>
          </div>
          <div className="flex items-end mt-8">
            <div className="w-168">
              <FormLayoutFieldset>
                <FormLabel label="絞り込み" type="small"></FormLabel>
                <FormContainerDropdown
                  className="select"
                  name="job"
                  placeholder="職種"
                  selectList={FORM_OPTIONS.experienced_job}
                ></FormContainerDropdown>
              </FormLayoutFieldset>
            </div>
            <div className="w-168 ml-16">
              <FormLayoutFieldset>
                <FormLabel label="絞り込み" type="small"></FormLabel>
                  <FormContainerDropdown
                    className="select"
                    name="position"
                    placeholder="ポジション"
                    selectList={POSITIONS_OPTIONS as TFormOption[]}
                  ></FormContainerDropdown>
              </FormLayoutFieldset>
            </div>
            <div className="w-168 ml-16">
              <FormLayoutFieldset>
                <FormLabel label="絞り込み" type="small"></FormLabel>
                <FormContainerDropdown
                  className="select"
                  name="status"
                  placeholder="ステータス"
                  selectList={DIRECT_FORM_OPTIONS.directSearchStatus}
                ></FormContainerDropdown>
              </FormLayoutFieldset>
            </div>
            <div className="w-168 ml-16">
              <FormLayoutFieldset>
                <FormLabel label="絞り込み" type="small"></FormLabel>
                <FormContainerDropdown
                  className="select"
                  name="rate"
                  placeholder="マッチ度"
                  selectList={DIRECT_FORM_OPTIONS.offer_mw_status}
                ></FormContainerDropdown>
              </FormLayoutFieldset>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
