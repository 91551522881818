import '@/styles/common/Admin/SearchResult/search_conditions.scss';

import React, { useMemo, useState } from 'react';

import { BaseButton } from '@/componentsAdmin/Parts/Button';
import { SaveUserTagsModal } from '@/componentsAdmin/Search';
import { DirectSearchConditionModel } from '@/utils/api-client';
import { searchConditionsToText } from '@/utils/search';

type TProps = {
  saved: boolean;
  title?: string;
  conditions: DirectSearchConditionModel | null;
  onClickChange: React.MouseEventHandler<HTMLDivElement>;
  onClickSave: React.MouseEventHandler<HTMLDivElement>;
};

/**
 * 企業向け用の検索条件表示コンポーネント
 * 元々が全部Directのコンポーネント使っていたがらひっぺがした。
 * @author s2 masa
 */
export function SearchConditionsForDirect({
  saved,
  title,
  conditions,
  onClickChange,
  onClickSave,
}: TProps): React.ReactElement {
  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [isOpenTagModal, setIsOpenTagModal] = useState(false);

  // ====================================================================================================
  //
  // Events
  //
  // ====================================================================================================
  const handleOpenUserTagModal = () => {
    setIsOpenTagModal(true);
  };

  const handleCloseUserTagModal = () => {
    setIsOpenTagModal(false);
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const searchConditionsText = useMemo(() => {
    if (conditions) {
      return searchConditionsToText(conditions);
    }

    return '';
  }, [conditions]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <>
      <section className="mbx-search_conditions">
        <div className="mbx-search_conditions__header">現在の検索条件</div>
        <div className="mbx-search_conditions__content">
          {saved && <p className="mbx-search_conditions__title mkt_mask_items">{title}</p>}
          <p className="mbx-search_conditions__text">{searchConditionsText}</p>
        </div>
        <div className="mbx-search_conditions__ui">
          <BaseButton
            size="s"
            theme="primary"
            className="mbx-search_conditions__button"
            onClick={onClickChange}
          >
            条件を変更
          </BaseButton>
          {saved && (
            <BaseButton
              size="s"
              theme="secondary"
              className="mbx-search_conditions__button"
              onClick={handleOpenUserTagModal}
            >
              タグ管理
            </BaseButton>
          )}
          {!saved && (
            <BaseButton
              size="s"
              theme="secondary"
              className="mbx-search_conditions__button"
              onClick={onClickSave}
            >
              この条件を保存
            </BaseButton>
          )}
        </div>
      </section>
      <SaveUserTagsModal isOpen={isOpenTagModal} onClose={handleCloseUserTagModal} />
    </>
  );
}
