import '@/styles/pages/CareerHistory/Edit.scss';

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { FieldValues, useForm, UseFormProps, UseFormReturn, FormProvider } from 'react-hook-form';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import * as CH from '@/components/CareerHistory';
import { ButtonIconButton } from '@/components/common/Button/IconButton';
import {
    FormContainer, FormContainerTextArea, FormContainerTextfield, FormLabel, TCallback
} from '@/components/common/Form/';
import * as Page from '@/components/common/Page';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { FormContainerDropdown } from '@/components/common/Form/Container/Dropdown';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import Modal from '@/components/common/Modal';
import BaseButton from '@/components/common/Button/BaseButton';
import { CAREER_HISTORY_TEMPLATE } from '@/definition/CAREER_HISTORY_TEMPLATE';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { careerHistory, qualification } from '@/hooks/usePageCareerHistory';
import { Repeat } from '@/types/Util';
import { Career, CareerHistory, CareerHistorySkills, Qualification } from '@/utils/api-client';
import { progressFromCareerHistory } from '@/utils/utils';
import { useDispatch } from 'react-redux';
import { setSaved } from '@/redux/index';

export default function Edit(): React.ReactElement {
  const mq = useMBXMediaQuery();
  const dispatch = useDispatch();
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [template, setTemplate] = useState<boolean>(false);
  const {
    data: fetchData,
    user,
    setParam,
    setDefaultValue,
    patch: patchCareerHistoryImmediately,
    patchCareerHistory,
    postSkill,
    deleteSkill,
    postQualification,
    postCareer,
    patchCareer,
    patchCareerImmediately,
    removeCareer,
    setPatchCareerParam,
    deleteQualification,
    patchQualification,
    setBeforeUnload,
  } = careerHistory(true);
  const {
    patch: patchToggleQualification,
  } = qualification();

  const useFormProps: UseFormProps = {
    mode: 'onBlur',
    // FormContainerはfetchDataを監視しているが、fetchDataそのものをsetValueしないので、
    // defaultValuesを更新する必要がある
    defaultValues: setDefaultValue(fetchData),
  };

  const methods = useForm(useFormProps);

  //資格用の更新
  const patchQ = (name: string, qualifications: Qualification[]) => {
    const index = parseInt(name.split('.')[1]);
    patchQualification(qualifications[index]);
  };

  //資格の追加
  const appendQualification = () => {
    postQualification();
  };

  //資格の入れ替え
  const moveQualification = (qualifications: Qualification[]) => {
    patchCareerHistoryImmediately({ qualifications }, false);
  };

  //資格の表示・非表示の切り替え
  const toggleQualification = async (qualification: Qualification) => {
    await patchToggleQualification(qualification, false);
    const val = methods.getValues();
    await patchCareerHistoryImmediately({ latest_update: val.latest_update as string }, true);
    dispatch(setSaved(true));
  };

  //職歴用のパラメータを生成してusePollingにわたす
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setCareerHistoryParam = (val: any) => {
    const param: CareerHistory = {
      advantage: `${val.advantage}`,
      description: `${val.description}`,
      job: val.job || '',
      pr: `${val.pr}`,
      latest_update: val.latest_update,
      skills: val.skills as CareerHistorySkills[],
    };

    setParam({
      userId: user?.user_id,
      param,
    });
  };

  //職歴の追加
  const appendCareer = (career: Career) => {
    postCareer(career);
  };

  //職歴の入れ替え
  const moveCareers = (careers: Career[]) => {
    patchCareerHistoryImmediately({ careers }, false);
  };

  //職歴の更新
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onPatchCareer = (value: any, name: any) => {
    const index = parseInt(name.split('.')[1]);
    if (index === undefined) return;
    const career = value.careers[index];

    patchCareer(setPatchCareerParam(career));
  };

  //スキルの新規追加
  const appendSkill = () => {
    const skill: CareerHistorySkills = {
      name: '',
      experience: 0,
      level: 0,
    };
    postSkill({ skills: [skill] });
  };

  //スキルの削除
  const removeSkill = (id: number) => {
    deleteSkill(id);
  };

  //スキルの入れ替え
  const moveSkills = (skills: CareerHistorySkills[]) => {
    patchCareerHistoryImmediately({ skills }, false);
  };

  //スキルの更新
  const patchSkills = (skills: CareerHistorySkills[]) => {
    patchCareerHistory({ skills });
  };

  //更新日の更新
  const patchLatestUpdate = (latest: string) => {
    patchCareerHistoryImmediately({ latest_update: latest }, false);
  };

  //フォーム更新の監視
  const onPatch: TCallback = (val, name, type) => {
    //typeがundefinedになるが更新したいものは別途定義する

    //テキストエリア編集はここでは更新しない
    if (name === 'job' && val.job !== null) return;
    if (name === 'description' && type === 'change') return;
    if (name === 'advantage' && type === 'change') return;
    if (name === 'pr' && type === 'change') return;

    //ソート時にcareers.(\d).c_projectsの変更が発生するので無視する
    if (name && /careers.(\d).c_projects$/.test(name)) return;

    if (name?.includes('careers.')) {
      //テキスト入力はonBlurで保存する
      if (name?.includes('.c_company_name')) return;
      if (name?.includes('.c_section')) return;
      if (name?.includes('.c_post')) return;
      if (name?.includes('.c_detail')) return;
      if (name?.includes('.c_employee_count')) return;
      if (name?.includes('.c_capital')) return;

      if (name?.includes('c_projects')) {
        if (name?.includes('.name')) return;
        if (name?.includes('.detail')) return;
        if (name?.includes('.structure')) return;
        if (name?.includes('.tools')) return;
        if (name?.includes('_year')) return;
        if (name?.includes('_month')) return;
      }

      //職歴の更新
      onPatchCareer(val, name);
      patchLatestUpdate(val.latest_update as string);
      return;
    }

    if (name?.includes('qualifications.')) {
      //資格の更新
      patchQ(name, val.qualifications as Qualification[]);
      patchLatestUpdate(val.latest_update as string);
      return;
    }

    if (name?.includes('skills.')) {
      patchSkills(val.skills as CareerHistorySkills[]);
      patchLatestUpdate(val.latest_update as string);
      return;
    }

    if (!type) return;
    //その他基本情報の更新
    setCareerHistoryParam(val);
  };

  const onBlur = (
    name: string,
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = methods.getValues();
    if (name === 'job') {
      value.job = (e.target as HTMLInputElement).value;
      setCareerHistoryParam(value);
    }
    if (name === 'description') {
      value.description = (e.target as HTMLInputElement).value;
      setCareerHistoryParam(value);
    }
    if (name === 'advantage') {
      value.advantage = (e.target as HTMLInputElement).value;
      setCareerHistoryParam(value);
    }
    if (name === 'pr') {
      value.pr = (e.target as HTMLInputElement).value;
      setCareerHistoryParam(value);
    }
    if (name.includes('careers.')) {
      onPatchCareer(value, name);
      patchLatestUpdate(value.latest_update as string);
    }
  };

  const progress = useMemo<Repeat<0 | 1, 4>>(
    () => progressFromCareerHistory(fetchData),
    [fetchData]
  );

  const templateMethods = useForm<{template: string}>({
    defaultValues: {
      template: CAREER_HISTORY_TEMPLATE.template_job[0].value,
    }
  });

  useEffect(() => {
    const subscription = templateMethods.watch((value, { name }) => {
      if (!name || !descriptionRef.current) return;
      descriptionRef.current.textContent = CAREER_HISTORY_TEMPLATE.template[value[name]];
    });
    return () => subscription.unsubscribe();
  }, [templateMethods.watch]);

  const placeholder = {
    advantage:`例）
①Webサイト構築およびアプリ開発におけるUI/UX設計（主にFigmaを使用）
②ラフ案およびワイヤーフレームの作成（主にAdobe XD、Photoshop、Illustratorを使用）
③フロントエンド領域でのコーディング（主にHTML、CSS、JavaScript、PHPを使用）`
  }

  return (
    <Page.Wrapper
      header={
        <div className="sticky top-0 z-10">
          <CH.CareerHistoryHeader>
            <CH.PageMenuContent {...{ progress }} />
          </CH.CareerHistoryHeader>
        </div>
      }
    >
      <div className="mbx-page-career-history mb-40 sm:mb-188">
        <Page.Column
          main={
            <>
              <FormContainer
                {...{
                  useFormProps,
                  fetchData,
                  onPatch,
                  watchInitData: false,
                  methods: methods as UseFormReturn<FieldValues, Record<string, unknown>>,
                }}
              >
                <div id="summary" className="pt-56">
                  <TitleSectionTitle type="large" className="mt-12">
                    <div className="flex items-center">
                      <span className="mr-4">職務経歴概要</span>
                      <TooltipMessageMatchbou
                        className=""
                        message="オファー機能ご利用時、「氏名」は企業に開示されません。"
                        position={['0', '-94px']}
                      >
                        <ButtonIconButton
                          hitArea="mini"
                          iconName="Info"
                          type="gray"
                          focus={false}
                        />
                      </TooltipMessageMatchbou>
                    </div>
                  </TitleSectionTitle>

                  <div className="space-y-8 my-22">
                    <div className="flex items-center">
                      <FormLabel label="氏名" type="small" />
                      <small className="text-11_12">
                        <ButtonTextLink href="/mypage/profile">基本情報</ButtonTextLink>から編集できます
                      </small>
                    </div>
                    <p className="text-14_20 mkt_mask_items">{user?.name}</p>
                  </div>

                  <div className="space-y-8 my-22">
                    <FormLabel label="肩書き" type="small" />
                    <FormContainerTextfield
                      className="mkt_mask_items"
                      name="job"
                      placeholder="例）UI/UXデザイナー"
                      onBlur={(e) => onBlur('job', e)}
                      onFocus={() => setBeforeUnload(true)}
                    />
                  </div>

                  <div className="space-y-8 my-22">
                    <FormLabel label="職務経歴概要" type="small">
                      <div className="flex items-center -ml-12">
                        <TooltipMessageMatchbou
                          className=""
                          message="オファー機能ご利用時、「職務経歴概要」は企業に開示されます。そのため自作のポートフォリオサイトなどのURLを入力すると、リンク先のページも企業は閲覧できます。氏名などの個人情報を企業に開示したくない場合は、ご自身で必ずリンク先のページを確認・修正の上、ご入力をお願いします。"
                          position={['0', '-94px']}
                          widthClassName="w-376"
                        >
                          <ButtonIconButton
                            hitArea="mini"
                            iconName="Info"
                            type="gray"
                            focus={false}
                          />
                        </TooltipMessageMatchbou>
                        <ButtonTextLink
                          onClick={(e) => {
                            e.preventDefault();
                            templateMethods.setValue("template", CAREER_HISTORY_TEMPLATE.template_job[0].value);
                            setTemplate(true);
                          }}
                          className="text-11_12 ml-16"
                          bold
                        >
                          テンプレートから⼊⼒する
                        </ButtonTextLink>
                      </div>
                    </FormLabel>
                    <FormContainerTextArea
                      className="mkt_mask_items"
                      rows={6}
                      maxLength={300}
                      name="description"
                      placeholder="例）専門学校を卒業し、株式会社マッチワークスに入社。Webデザイナーとして受託案件のWeb制作に4年間従事。ページデザインやHTML、CSS、JavaScript、PHPでのサイト構築を一通り経験した後、UI/UX領域にスキルの幅を広げるため、株式会社マッチラボに転職。現在は、Webデザイナー経験を活かしながら、アプリ開発のUI/UXデザイナーに従事しています。"
                      onBlur={(e) => onBlur('description', e)}
                      onFocus={() => setBeforeUnload(true)}
                    />
                  </div>

                  <div className="space-y-8 my-22">
                    <FormLabel label="活かせる経験・スキル・得意分野" type="small" />
                    <FormContainerTextArea
                      className="mkt_mask_items"
                      rows={6}
                      maxLength={300}
                      name="advantage"
                      placeholder={placeholder?.advantage}
                      onBlur={(e) => onBlur('advantage', e)}
                      onFocus={() => setBeforeUnload(true)}
                    />
                  </div>
                </div>

                <div id="SECTION_CAREERS">
                  <CH.Careers
                    onAppend={appendCareer}
                    onSort={(result) => moveCareers(result)}
                    patchCareer={patchCareer}
                    patchCareerImmediately={patchCareerImmediately}
                    removeCareer={removeCareer}
                    onBlur={onBlur}
                    onFocus={() => setBeforeUnload(true)}
                  />
                </div>

                <div id="qualification">
                  <CH.Qualifications
                    onAppend={() => appendQualification()}
                    onSort={(result) => moveQualification(result)}
                    patchItem={toggleQualification}
                    deleteItem={deleteQualification}
                  />
                </div>

                <div id="skill">
                  <CH.Skills
                    onAppend={() => appendSkill()}
                    onSort={(result) => moveSkills(result)}
                    onRemove={removeSkill}
                  />
                </div>

                <div id="pr">
                  <TitleSectionTitle type="large" className="mt-12">
                    自己PR
                  </TitleSectionTitle>

                  <div className="space-y-8 my-22">
                    <FormContainerTextArea
                      className="mkt_mask_items"
                      rows={6}
                      maxLength={1000}
                      name="pr"
                      placeholder="例）プロデューサーやディレクターからの指示内容を単に実行するだけでなく、市場や競合の外部環境分析を自ら行い、携わるプロダクトやサービスに、今必要なUI/UXとは何かを提案し実行してきた経験が私の強みと認識しています。ユーザーにとって利便性の高い操作性も当然重要ですが、常に変化するサービスデザイン全般を意識することで、長期的にご利用いただけるサイトやアプリをご提案、構築できると考えています。"
                      onBlur={(e) => onBlur('pr', e)}
                      onFocus={() => setBeforeUnload(true)}
                    />
                  </div>
                </div>

                <div id="media">
                  <TitleSectionTitle type="large" className="mt-12">
                    職務経歴書の更新日
                  </TitleSectionTitle>

                  <CH.LastUpdate />
                </div>
              </FormContainer>
            </>
          }
          menu={
            <div className="mbx-naviInpage flex justify-end lg:py-64">
              {mq.lg && <CH.PageMenuContent {...{ progress }} />}
            </div>
          }
        />
        <Modal
          isOpen={template}
          isClose={() => setTemplate(false)}
          className="rounded-modalContainer"
        >
          <section className="max-w-784 w-784 md:w-screen sm:w-screen sm:h-auto overflow-hidden pt-48 px-24">
            <div className={cn('font-bold mt-20', mq.sm ? 'text-18_33' : 'text-21_34')}>
              <p className="mt-48">
                職務経歴概要テンプレート
              </p>
            </div>
            <div>
              <p className="mt-14 text-15_32">
                使⽤したいテンプレートを選択してください。
                <p className="mt-7 text-12_20">※テンプレートを挿⼊すると、すでに⼊⼒された内容は上書きされます。</p>
              </p>
              <FormProvider {...templateMethods}>
                <form>
                <div className="mt-26 flex justify-center">
                  <FormLayoutFieldset
                    className={cn(mq.sm ? 'w-308' : 'w-530')}
                  >
                    <FormContainerDropdown
                      className={cn('select mkt_mask_items', mq.sm ? 'w-308' : 'w-530')}
                      name="template"
                      selectList={CAREER_HISTORY_TEMPLATE.template_job}
                    ></FormContainerDropdown>
                    <div className="mkt_mask_items mt-26 rounded-4 w-full text-14_21 mbx-formContainer--textarea text-left bg-gray-100" ref={descriptionRef}>{CAREER_HISTORY_TEMPLATE.template.creative}</div>
                  </FormLayoutFieldset>
                </div>
                  <div className="pb-20 mb-48 sm:mb-72">
                    <div className="w-308 m-auto mt-48">
                      <BaseButton
                        theme="primary"
                        size="m"
                        className="w-full"
                        onClick={(e) => {
                          e.preventDefault();
                          methods.setValue("description", descriptionRef.current?.textContent);
                          const value = methods.getValues();
                          value.description = descriptionRef.current?.textContent;
                          setCareerHistoryParam(value);
                          setTemplate(false);
                        }}
                      >
                        このテンプレートを使って⼊⼒する
                      </BaseButton>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </section>
        </Modal>
      </div>
    </Page.Wrapper>
  );
}
