import React, { VFC } from 'react';
import Placeholder from '@/images/Placeholder.jpg';
import '@/styles/common/Mypage/news_module.scss';

type TProps = {
  title: string;
  image?: string;
  href: string;
  blank?: boolean;
};

/**
 *
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=262%3A21
 *
 * imageなしの場合Placeholder画像が表示される
 *
 * リンクのデフォルトはtarget=_blank
 *
 */
export const MypageNewsModule: VFC<TProps> = ({ title, image, href, blank = true }) => {
  return (
    <div className={'mbx-news_module'}>
      <a href={href} target={blank ? '_blank' : ''} rel="noreferrer">
        {image ? <img src={image} alt={title} /> : <img src={Placeholder} alt={title} />}
        {blank && <div className="mbx-icon mbx-icon-External_link"></div>}
        <div className="mbx-news_module-title">
          <p>{title}</p>
        </div>
      </a>
    </div>
  );
};
