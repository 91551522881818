import React, { useEffect, useRef, useState, VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { delay } from 'underscore';

import { ButtonIconButton } from '@/componentsDirect/Parts/Button/IconButton';
import { addClass, removeClass } from '@/utils/dom';

type TProps = {
  name: string; // RFHに登録するname
  placeholder?: string;
  defaultTags?: Array<string>;
  className?: string;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=1082%3A54885
 *
 * default の値はhook-formのuseFormで指定
 * https://react-hook-form.com/api/useform
 *
 * 挙動サンプル
 * https://materializecss.com/chips.html
 *
 * エンターキーで入力値をタグ化
 *
 */
export const FormContainerTextfieldMultiple: VFC<TProps> = ({
  placeholder,
  name,
  className = '',
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const methods = useFormContext();
  const register = methods.register(name);
  const { watch } = methods;
  const [tags, setTags] = useState(
    methods.getValues(name) ? (methods.getValues(name) as Array<string>) : []
  );

  // cssのfocusだとclearボタンをクリックしようとする直前にfocusが外れてボタンがクリックできないので
  // onFocusとonBlurでclassを付けて対応
  const onFocus: React.FocusEventHandler<HTMLInputElement> = () => {
    if (wrapperRef.current)
      addClass(
        wrapperRef.current as HTMLElement,
        'border-border-form-hover shadow-formContainerFocus'
      );
  };
  /**
   * フォーカスアウト時にタグ未生成のテキストがあれば強制タグ化
   */
  const onFocusOut: React.FocusEventHandler<HTMLInputElement> = (e) => {
    const target = e.target as HTMLInputElement;
    createTag(target.value);
    target.value = '';
    delay(() => {
      if (wrapperRef.current)
        removeClass(
          wrapperRef.current as HTMLElement,
          'border-border-form-hover shadow-formContainerFocus'
        );
    }, 100);
  };

  const onKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const target = e.target as HTMLInputElement;
      createTag(target.value);
      target.value = '';
    }
  };

  /**
   * タグを生成
   */
  const createTag = (newTag: string) => {
    // 未入力なら何もしない
    if (!newTag) return;
    if (methods.getValues(name)) {
      methods.setValue(name, [...methods.getValues(name), newTag]);
    } else {
      methods.setValue(name, [newTag]);
    }
  };

  /**
   * タグを削除
   */
  const deleteTag = (index: number) => {
    methods.setValue(
      name,
      (methods.getValues(name) as Array<string>).filter((v, i) => {
        return i !== index;
      })
    );
  };

  useEffect(() => {
    // 内部データとRHFのデータに差があったら合わせる
    if (methods.getValues(name)) {
      if (Array.isArray(methods.getValues(name))) {
        setTags(methods.getValues(name));
      } else {
        setTags(methods.getValues(name).split(','));
      }
    } else {
      setTags([]);
    }
  }, [watch(name)]);

  return (
    <div className={className}>
      <div
        ref={wrapperRef}
        className="w-full text-14_21 border border-border-form rounded-formContainer transition-all min-h-36"
      >
        <input type="hidden" {...register} />
        <ul className="flex items-center flex-wrap">
          {/* タグ化した要素 */}
          {tags &&
            tags.map((tag, index) => (
              <li key={index} className={'pl-8 m-6 bg-blue-200 rounded-full'}>
                <div className="flex items-center justify-between">
                  <p className="text-11_12">{tag}</p>
                  <ButtonIconButton
                    type="clear"
                    fontSize="icon_small"
                    iconName="Clear"
                    onClick={(e) => {
                      e.preventDefault();
                      deleteTag(index);
                    }}
                    hitArea="mini"
                    focus={false}
                  ></ButtonIconButton>
                </div>
              </li>
            ))}
          <li className="flex-grow min-w-120 mx-6 ">
            {/* 入力フォーム */}
            <input
              type="text"
              className="h-36 outline-none placeholder-gray-500 w-full"
              placeholder={placeholder}
              onKeyPress={onKeyPress}
              onBlur={(e) => {
                onFocusOut(e);
              }}
              onFocus={onFocus}
            />
          </li>
        </ul>
      </div>
    </div>
  );
};
