import React from 'react';

import { PreviewResumeHistory } from '@/components/common/Preview/ResumeHistory';
import { PreviewResumeText } from '@/components/common/Preview/ResumeText';
import { PreviewResumeTitle } from '@/components/common/Preview/ResumeTitle';
import { CareerSummaryModel, EducationPatchRequest } from '@/utils/api-client/api';

type TProps = {
  educations?: EducationPatchRequest[];
  careers?: CareerSummaryModel[];
  firstPageHistoryItemLength?: number;
  firstPageEducationsItemLength?: number;
  secondPageCareersItemLength?: number;
  emptyEducationItemLength?: number;
  isFirstPage?: boolean;
};

type TCareersItem = CareerSummaryModel & { isJoin?: boolean };

export const ResumePreviewSectionCareers = ({
  educations = [],
  careers = [],
  firstPageHistoryItemLength = 0,
  firstPageEducationsItemLength = 0,
  secondPageCareersItemLength = 0,
  emptyEducationItemLength = 0,
  isFirstPage = false,
}: TProps): React.ReactElement => {
  if (educations.length >= 16) return <></>;

  // invisibleなやつは除外
  careers = careers.filter((item) => item.c_visible);

  if (
    !isFirstPage &&
    ((firstPageEducationsItemLength < 16 && careers.length === 0) || educations.length >= 9)
  )
    return <></>;
  const historyItemLength = firstPageHistoryItemLength + secondPageCareersItemLength;
  const fillEmptyItemLength =
    emptyEducationItemLength > 0
      ? 16 - firstPageHistoryItemLength - emptyEducationItemLength
      : 16 - firstPageHistoryItemLength;
  return (
    <div className="flex">
      <PreviewResumeTitle>職歴</PreviewResumeTitle>
      <div className="w-full">
        {careers &&
          Array.from(careers.values()).map((item: TCareersItem, index: number) => {
            return item.c_leave_flag && !item.isJoin ? (
              <PreviewResumeHistory
                key={`${index}_leave`}
                date={`${item.c_leave_date?.split('-')[0]}年${item.c_leave_date?.split('-')[1]}月`}
                description={`${item.c_company_name} 退社`}
                className="mkt_mask_items"
              ></PreviewResumeHistory>
            ) : (
              <PreviewResumeHistory
                key={index}
                date={`${item.c_join_date.split('-')[0]}年${item.c_join_date.split('-')[1]}月`}
                description={`${item.c_company_name} 入社`}
                className="mkt_mask_items"
              ></PreviewResumeHistory>
            );
          })}

        {isFirstPage && historyItemLength > 16 ? (
          <PreviewResumeHistory date="" description="" type="empty"></PreviewResumeHistory>
        ) : null}
        {!isFirstPage || historyItemLength <= 16 ? (
          <PreviewResumeText className="w-full text-right">以上</PreviewResumeText>
        ) : null}
        {isFirstPage && fillEmptyItemLength > 0
          ? new Array(fillEmptyItemLength).fill(null).map((item, key) => {
              return (
                <PreviewResumeHistory
                  date=""
                  description=""
                  type="empty"
                  key={key}
                ></PreviewResumeHistory>
              );
            })
          : null}
        {!isFirstPage && careers?.length + educations?.length >= 9 ? (
          <PreviewResumeHistory date="" description="" type="empty"></PreviewResumeHistory>
        ) : null}
      </div>
    </div>
  );
};
