import React from 'react';

type TProps = {
  children: Array<React.ReactNode>;
  className?: string;
};

/**
 * 汎用の薄いテーブルラッパー
 * 今のところ特に何もしないがTableHeaderを包んで使う
 */
export const TableContainer = ({ children, className }: TProps): React.ReactElement => {
  return (
    <table className={className}>
      {children[0]}
      {children[1]}
    </table>
  );
};
