/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { RootState } from '@/reduxAdmin';

export function PublicRoute(props: any): React.ReactElement {
  const isLogin = useSelector((state: RootState) => state.direct_auth.isLogin);
  const pathAfterLogin = useSelector((state: RootState) => state.utils.pathAfterLogin);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const path = useMemo(() => {
    return pathAfterLogin !== undefined
      ? `${pathAfterLogin}${location.search}`
      : `/direct/search${location.search}`;
  }, [pathAfterLogin, location.search]);

  return isLogin ? <Redirect to={path} /> : <Route {...props} />;
}
