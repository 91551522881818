export const pageList = [
  {
    category: 'MATCHBOX Direct管理画面／ログイン',
    title: 'ログイン',
    path: '/direct/login',
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／ログイン',
    title: 'パスワード再設定開始',
    path: '/direct/login/reminder',
    virtualPage: 'step_1',
  },
  {
    category: 'MATCHBOX Direct管理画面／ログイン',
    title: 'パスワード再設定リンク送信完了',
    path: '/direct/login/reminder',
    virtualPage: 'step_2',
  },
  {
    category: 'MATCHBOX Direct管理画面／ログイン',
    title: 'パスワード再設定',
    path: /\/direct\/login\/reminder\?[^/]+$/,

    virtualPage: 'step_3',
  },
  {
    category: 'MATCHBOX Direct管理画面／ログイン',
    title: 'パスワード再設定完了',
    path: /\/direct\/login\/reminder\?[^/]+$/,
    virtualPage: 'step_4',
  },
  {
    category: 'MATCHBOX Direct管理画面／ユーザー管理',
    title: '社内ユーザー管理',
    path: '/direct/users',
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／ユーザー管理',
    title: '新しいユーザーを追加',
    path: '/direct/users',
    virtualPage: 'add-user_step_1',
  },
  {
    category: 'MATCHBOX Direct管理画面／ユーザー管理',
    title: '新しいユーザーを追加完了',
    path: '/direct/users',
    virtualPage: 'add-user_step_2',
  },
  {
    category: 'MATCHBOX Direct管理画面／ユーザー管理',
    title: 'ユーザーの削除',
    path: '/direct/users',
    virtualPage: 'delete-user',
  },
  {
    category: 'MATCHBOX Direct管理画面／ユーザー管理',
    title: 'アカウント登録',
    path: /^\/direct\/entry\?[^/]+$/,
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／ユーザー管理',
    title: 'アカウント登録',
    path: '/direct/entry',
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／求職者検索',
    title: '求職者検索',
    path: '/direct/search',
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／求職者検索',
    title: '保存した条件の編集',
    path: /^\/direct\/conditions\/\d+$/,
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／求職者検索',
    title: '検索条件のリセット',
    path: '/direct/search?reedit',
    virtualPage: 'reset-conditions',
  },
  {
    category: 'MATCHBOX Direct管理画面／求職者検索',
    title: '検索条件のリセット',
    path: '/direct/search',
    virtualPage: 'reset-conditions',
  },
  {
    category: 'MATCHBOX Direct管理画面／求職者検索',
    title: '検索結果',
    path: /^\/direct\/search\/result\?search_condition_id=\d+$/,
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／求職者検索',
    title: '検索結果',
    path: '/direct/search/result',
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／求職者検索',
    title: '検索条件の保存',
    path: '/direct/search/result',
    virtualPage: 'save-conditions',
  },
  {
    category: 'MATCHBOX Direct管理画面／求職者検索',
    title: '保存した検索条件（あなたの検索条件）',
    path: '/direct/conditions',
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／求職者検索',
    title: '保存した検索条件（MATCHBOXおすすめ検索条件）',
    path: '/direct/conditions/recommended',
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／求職者詳細',
    title: '求職者詳細',
    path: /\/direct\/users\/[^/]+$/,
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／求職者詳細',
    title: '求職者をピックアップ',
    path: /\/direct\/users\/[^/]+$/,
    virtualPage: 'pickup',
  },
  {
    category: 'MATCHBOX Direct管理画面／求職者詳細',
    title: 'ピックアップを解除',
    path: /\/direct\/users\/[^/]+$/,
    virtualPage: 'release',
  },
  {
    category: 'MATCHBOX Direct管理画面／求職者詳細',
    title: '社内メモ一覧',
    path: /\/direct\/users\/[^/]+\/notes$/,
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／候補者管理',
    title: '候補者管理',
    path: '',
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／候補者管理',
    title: '候補者一覧',
    path: '/direct/candidates',
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／候補者管理',
    title: '候補者詳細',
    path: /\/direct\/candidates\/[^/]+$/,
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／候補者管理',
    title: '自分の意見を投稿する',
    path: /\/direct\/candidates\/[^/]+$/,
    virtualPage: 'post-opinion',
  },
  {
    category: 'MATCHBOX Direct管理画面／候補者管理',
    title: 'みんなの意見を元にアクションする',
    path: /\/direct\/candidates\/[^/]+$/,
    virtualPage: 'action',
  },
  {
    category: 'MATCHBOX Direct管理画面／候補者詳細',
    title: '候補者詳細（ポートフォリオWeb-作品詳細）',
    path: /\/direct\/portfolio\/[^/]+\/\d+\/$/,
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／候補者詳細',
    title: '候補者詳細（ポートフォリオWeb-作品）',
    path: /\/direct\/portfolio\/[^/]+$/,
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／候補者詳細',
    title: '候補者詳細（ポートフォリオWeb-作品） | パスワード入力',
    path: /\/direct\/portfolio\/[^/]+$/,
    virtualPage: 'input-pw',
  },
  {
    category: 'MATCHBOX Direct管理画面／候補者詳細',
    title: '候補者詳細（ポートフォリオWeb-プロフィール）',
    path: /\/direct\/portfolio\/[^/]+\/profile$/,
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／候補者詳細',
    title: '候補者詳細（ポートフォリオWeb-プロフィール） | パスワード入力',
    path: /\/direct\/portfolio\/[^/]+\/profile$/,
    virtualPage: 'input-pw',
  },
  {
    category: 'MATCHBOX Direct管理画面／候補者詳細',
    title: '候補者詳細（職務経歴書Web）',
    path: /\/direct\/cv\/[^/]+$/,
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／ユーザー管理',
    title: '企業情報設定',
    path: '/direct/company',
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／ユーザー管理',
    title: 'プロフィール設定',
    path: '/direct/user/profile',
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／ユーザー管理',
    title: 'アカウント設定',
    path: '/direct/user/account',
    virtualPage: '',
  },
  {
    category: 'MATCHBOX Direct管理画面／Top',
    title: 'Top',
    path: '/direct/',
    virtualPage: '',
  },
];
