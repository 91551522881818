import { AxiosError } from 'axios';
import React, { useEffect, useState, VFC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { CSSTransition } from 'react-transition-group';

import { ButtonIconButton } from '@/components/common/Button/IconButton';
import BaseButton from '@/components/common/Button/BaseButton';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormContainerTextArea } from '@/components/common/Form/Container/TextArea';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { FormFootnote } from '@/components/common/Form/Footnote';
import Modal from '@/components/common/Modal';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { Wrapper } from '@/components/common/Page';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import WarningList from '@/components/common/Direct/WarningList';

import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { notificationError, toggleLoading, notificationSuccess } from '@/redux/index';
import { State } from '@/redux/state';
import {
  MatchboxUserResponse,
  OwnPortfolioRequest,
  ResponseError,
  UserApi,
  PortfolioApi,
} from '@/utils/api-client/api';

// ====================================================================================================
//
// CONST
//
// ====================================================================================================
const warnings = [
  'MATCHBOXでは企業に個人情報を公開しない仕様となっているため、自作ポートフォリオをご登録の際にも、氏名(フリガナふりがな含む)、生年月日、住所、電話番号、メールアドレス、家族情報などの個人を特定できる情報は含めないでください。',
  '自作のポートフォリオの登録はオファーを受け取るために企業に公開する機能であり一般公開はされません。',
  'オファー登録時にポートフォリオURLと補足情報が企業に公開されます。',
];

/**
 * # マイページ /mypage/self-portfolio/edit
 * UI https://docs.google.com/presentation/d/1RS73OkOLSvuT4Q2NO9NO8EfweGYQerUjQJPJNQ34-ag/edit#slide=id.g2e527bb4675_0_15
 * API
 */
export const SelfPortfolio: VFC = () => {
  // ====================================================================================================
  //
  // REDUX
  //
  // ====================================================================================================
  const user = useSelector((state: State) => state.user);
  const dispatch = useDispatch();

  // ====================================================================================================
  //
  // setState
  //
  // ====================================================================================================
  const [userData, setUser] = useState<MatchboxUserResponse>();
  const [pending, setPending] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [urlError, setUrlError] = useState<boolean>(false);

  // ====================================================================================================
  //
  // METHOD
  //
  // ====================================================================================================
  const methods = useForm<OwnPortfolioRequest>({
    defaultValues: {
      url: '',
      supplementary_info: '',
    },
  });
  const { setValue, getValues, handleSubmit } = methods;

  // ====================================================================================================
  //
  // API
  //
  // ====================================================================================================
  const getUser = () => {
    (async () => {
      const user = await new UserApi().getUser();
      if (user.status === 200) {
        setUser(user.data);
      }
    })();
  };

  const getOwnPortfolio = () => {
    (async () => {
      const data = await new PortfolioApi().getOwnPortfolio();
      if (data.status === 200) {
        if (data.data.own_portfolio?.url) {
          setValue('url', data.data.own_portfolio?.url);
        }
        if (data.data.own_portfolio?.supplementary_info) {
          setValue('supplementary_info', data.data.own_portfolio?.supplementary_info);
        }
        if (data.data.own_portfolio?.last_update_own_portfolio) {
          setIsFirst(false);
        }
      }
    })();
  };

  const send = async () => {
    const params = getValues();
    if (params.url && !params.url.startsWith('http')) {
      setUrlError(true);
      return false;
    }
    if (!params.url && params.supplementary_info) {
      setUrlError(true);
    } else {
      setUrlError(false);
      dispatch(toggleLoading(true));
      try {
        if (params.url == null) params.url = '';
        const data = await new PortfolioApi().patchOwnPortfolio(params);
        if (data.status === 200) {
          if (params.url) {
            setIsOpen(true);
          } else {
            dispatch(notificationSuccess('自作のポートフォリオの保存が完了しました。'));
            setIsFirst(false);
          }
        }
      } catch (error) {
        const e = error as AxiosError<ResponseError>;
        if (e.response) dispatch(notificationError(e.response.data.error_message));
      } finally {
        //
      }
      dispatch(toggleLoading(false));
    }
  };

  const modalClose = () => {
    setIsFirst(false);
    setIsOpen(false);
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    if (!userData?.last_login) return;
    if (pending) return;
    setPending(true);
  }, [userData?.last_login]);

  useEffect(() => {
    try {
      dispatch(toggleLoading(true));
      getUser();
      getOwnPortfolio();
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  }, []);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <Wrapper header={<HeaderTitle title={'自作のポートフォリオの登録'} />}>
      <>
        <p className="mbx-typography--body_1 text-center mt-48 sm:mt-24">
          自作のポートフォリオを企業にアピールし
          <br />
          オファーをもらいましょう。
        </p>

        <section className="condition-selection w-624 m-auto sm:w-full pt-48 pb-100 sm:pt-24 sm:mb-188">
          <TitleSectionTitle>
            <div className="flex items-center">
              <span className="mr-4">自作のポートフォリオの登録</span>
            </div>
          </TitleSectionTitle>
          <p className="text-14_21">
            自分で作成したポートフォリオをMATCHBOXに登録することができます。
            <br />
            また、URLを登録しオファー機能に登録すれば、その自作ポートフォリオも企業に公開され、オファーをもらえる可能性が高まります。PDFなどのファイルの場合は、ファイルサーバー等にアップしたりファイル転送サービスを利用するなどして、URLを登録してください。
          </p>
          <div className="mt-32 w-624 m-auto text-left sm:w-full">
            <WarningList warnings={warnings} />
          </div>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(send)}>
              <div className="mt-32 space-y-24 input-box">
                <FormLayoutFieldset>
                  <FormLabel label="URL" type="small" className="py-4"></FormLabel>
                  <FormContainerTextfield
                    name="url"
                    placeholder="http://www"
                    className="mkt_mask_items"
                    debugError={urlError}
                    disabledError={true}
                  />
                  <CSSTransition
                    in={urlError}
                    timeout={550}
                    unmountOnExit
                    classNames="mbx-anime-error"
                  >
                    <div className="flex flex-row">
                      <FormFootnote className="text-error-700">URLを入力してください</FormFootnote>
                    </div>
                  </CSSTransition>
                </FormLayoutFieldset>
                <FormLayoutFieldset>
                  <FormLabel label="補足情報" type="small">
                    <TooltipMessageMatchbou
                      className="-ml-12"
                      message="閲覧やダウンロードに必要なID・パスワードなどの補足情報があれば、入力してください。"
                      position={['0', '-94px']}
                    >
                      <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
                    </TooltipMessageMatchbou>
                  </FormLabel>
                  <FormContainerTextArea
                    name="supplementary_info"
                    rows={2}
                    maxLength={300}
                    placeholder="例）パスワード:1234test / ダウンロード期限:2024年6月24日 "
                    className="mkt_mask_items"
                  />
                </FormLayoutFieldset>
                <div className="text-center">
                  <BaseButton
                    theme="primary"
                    size={'m-for-sp'}
                    width="large"
                    className="width-lg"
                    type="submit"
                  >
                    <div>{isFirst ? '登録する' : '変更を保存'}</div>
                  </BaseButton>
                </div>
              </div>
            </form>
          </FormProvider>
        </section>

        <Modal
          isOpen={isOpen}
          isClose={modalClose}
          className="rounded-modalContainer"
          contentClass={'content-center'}
        >
          <div
            className="lg:w-624 sm:pb-80 lg:p-80 md:p-80 sm:py-44 sm:px-24 bg-cover bg-no-repeat bg-center"
            style={{ backgroundImage: `url(/images/lively_bg.png)` }}
          >
            <h1 className="mbx-typography--heading_2 mb-40 sm:mb-24">
              {isFirst ? '登録されました！' : '更新されました！'}
            </h1>

            <div className="space-y-24">
              <p className="text-14_21">
                {user?.use_direct ? (
                  <>
                    オファー機能に登録しているため、自作のポートフォリオが企業に公開されています。
                    <br />
                    定期的にオファーが届いているか確認してみましょう。
                  </>
                ) : (
                  <>
                    自作のポートフォリオはまだ企業に公開されていません。
                    <br />
                    オファー機能に登録して企業に自作のポートフォリオを公開してみましょう。
                  </>
                )}
              </p>
              <div className="button-area">
                {user?.use_direct ? (
                  <BaseButton
                    theme="secondary"
                    size={'m-for-sp'}
                    width="large"
                    href={'/mypage/direct_status'}
                  >
                    <div>オファー履歴をチェック</div>
                  </BaseButton>
                ) : (
                  <BaseButton
                    theme="primary"
                    size={'m-for-sp'}
                    width="large"
                    href={'/mypage/direct'}
                  >
                    <div>オファー機能に登録する</div>
                  </BaseButton>
                )}
              </div>

              <BaseButton theme="link" size={'m'} onClick={modalClose}>
                <div className="sm:w-270 md:w-300 lg:w-300">
                  {user?.use_direct ? 'あとで確認する' : 'あとで設定する'}
                </div>
              </BaseButton>
            </div>
          </div>
        </Modal>
      </>
    </Wrapper>
  );
};
