import '@/styles/common/Mypage/assetsbox.scss';

import React, { VFC } from 'react';

import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { OwnPortfolioResponse } from '@/utils/api-client';

type TProps = {
  ownPortfolio: OwnPortfolioResponse | undefined;
};

/**
 *
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=383%3A35945
 *
 */
export const SectionSelfPortfolio: VFC<TProps> = ({ ownPortfolio }) => {
  return (
    <section className={'bg-gray-50 mt-48 sm:px-24 py-40 px-40 mx-auto'}>
      <div className="flex">
        <h1 className="font-bold text-14_20">自作のポートフォリオ（URL）の登録</h1>
      </div>
      <p className="text-12_20 mt-8">
        自作で作成したポートフォリオ（URL）を登録し、オファー機能に登録すれば、あなたの自作のポートフォリオも企業に公開され、オファーをもらえる可能性が高まります。
      </p>
      <div className="mt-8">
        <hr className="mbx-keyline-horizontal--thin"></hr>
        <div className="lg:flex items-center justify-between py-12">
          {!ownPortfolio?.own_portfolio?.url && !ownPortfolio?.own_portfolio?.supplementary_info ? (
            <p className="text-12_20">未登録</p>
          ) : (
            <div className="text-12_20 flex-1">
              <div className="flex">
                <p className="w-86 shrink-0 grow-0">URL :</p>
                <p className="flex-1">
                  {ownPortfolio?.own_portfolio?.url?.startsWith('http') ? (
                    <ButtonTextLink href={ownPortfolio?.own_portfolio?.url}>
                      {ownPortfolio?.own_portfolio?.url}
                    </ButtonTextLink>
                  ) : (
                    <>{ownPortfolio?.own_portfolio?.url}</>
                  )}
                </p>
              </div>
              <div className="flex">
                <p className="w-86 shrink-0 grow-0">補足情報 :</p>
                <p className="flex-1 whitespace-pre-wrap">
                  {ownPortfolio?.own_portfolio?.supplementary_info
                    ? ownPortfolio?.own_portfolio?.supplementary_info
                    : '未設定'}
                </p>
              </div>
            </div>
          )}
          <p className="w-86 shrink-0 text-12_20 sm:mt-12 lg:text-right">
            <ButtonTextLink href="/mypage/self-portfolio/edit">設定の変更</ButtonTextLink>
          </p>
        </div>
        <hr className="mbx-keyline-horizontal--thin"></hr>
      </div>
    </section>
  );
};
