import '@/stylesAdmin/pages/Candidates/Top.scss';

import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContentsBlank } from '@/components/common/ContentsBlank';
import { Filters } from '@/componentsDirect/Candidates/Filters';
import { TableHeader } from '@/componentsDirect/Candidates/TableHeader';
import { UserList } from '@/componentsDirect/Candidates/UserList';
import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { AdminPage } from '@/componentsDirect/Layout/Page';
import { Pager } from '@/componentsDirect/Parts/Navigation/Page/Pager';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { RootState } from '@/reduxAdmin';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import { DirectApi, Offer, ResponseError } from '@/utils/api-client';
import { CandidateDataFromOffer, genCandidateDataFromOffer } from '@/utils/utils';

export const CandidatesTop = (): React.ReactElement => {
  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.utils.loading);
  const [offers, setOffers] = useState<Offer[]>();
  const [filterValues, setFilterValues] = useState({
    matchbox_id: '',
    status: '',
    rate: '',
    job: '',
    position: '',
    sort: '',
  });
  const [page, setPage] = useState<number>(0);
  const [pageList, setPageList] = useState<number[]>([]);
  const [total, setTotal] = useState<number>(0);
  const { push } = React.useContext(DataLayerContext);

  const offset = useMemo(() => {
    if (page === 0) return 0;
    return page * 10;
  }, [page]);

  const pageLength = useMemo(() => {
    if (total === 0) return 0;
    return Math.ceil(total / 10);
  }, [total]);

  const sort = useMemo(() => {
    switch (filterValues.sort) {
      case '1':
        return {
          order: 'desc',
          by: 'updated_at',
        };
      case '2':
        return {
          order: 'desc',
          by: 'created_at',
        };
      case '3':
        return {
          order: 'desc',
          by: 'reactions',
        };
      case '4':
        return {
          order: 'asc',
          by: 'reactions',
        };
      case '5':
        return {
          order: 'desc',
          by: 'my_reaction',
        };
      case '6':
        return {
          order: 'asc',
          by: 'my_reaction',
        };
      case '7':
        return {
          order: 'desc',
          by: 'has_action',
        };
      case '8':
        return {
          order: 'asc',
          by: 'has_action',
        };
    }
    return {
      order: '',
      by: '',
    };
  }, [filterValues.sort]);

  const getOffer = async () => {
    console.log(filterValues.position);
    dispatch(toggleLoading(true));
    try {
      const res = await new DirectApi().getDirectOffers(
        '10',
        `${offset}`,
        sort?.by,
        sort?.order,
        filterValues.status ? Number(filterValues.status) : undefined,
        filterValues.rate ? Number(filterValues.rate) : undefined,
        filterValues.job ? Number(filterValues.job) : undefined,
        filterValues.position ? Number(filterValues.position) : undefined,
        filterValues.matchbox_id,
      );
      setOffers(res.data.offers);
      if (res.data.total !== undefined) {
        setTotal(res.data.total);
      }
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  useEffect(() => {
    if (loading) return;
    getOffer();
  }, [filterValues, page]);

  useEffect(() => {
    setPage(0);
  }, [filterValues]);

  const candidates = useMemo<CandidateDataFromOffer[] | undefined>(() => {
    return offers ? offers.map((offer) => genCandidateDataFromOffer(offer)) : undefined;
  }, [offers]);

  useEffect(() => {
    if (candidates === undefined) return;
    push({
      event: 'pageView',
      actionType: 'page_view',
      actionName: 'page_view',
      pagination: `${page + 1}`,
      cNarrowDown: ['N/A', 'N/A', 'N/A'].join('::'),
      cSortBy: '日付順',
      candidates,
    });
  }, [candidates]);

  return (
    <AdminPage header={<HeaderTitle title="候補者管理" />} disableDataLayerPush>
      <section className="mbxd-candidates_top pb-84">
        <p className="mt-48 mbx-typography--body_1 text-center">
          ピックアップ以降のステータスを管理します。
          <br />
          管理者以外の方は「じぶんの意見」を投稿し、管理者の方はそれをもとに、対外的な「アクション」を行ってください。
        </p>
        <section className="mt-32">
          <Filters setFilterValues={setFilterValues} />
          {total !== 0 && (
            <p className="mt-24 mbx-typography--body_2">
              {page === 0 ? page + 1 : page + '1'}〜{page === pageLength - 1 ? total : page + 1 + '0'}{' '}
              / {total}件
            </p>
          )}
        </section>
        <section className="mt-16">
          <TableHeader />
          {offers?.length === 0 ? (
            <ContentsBlank
              className="mt-50"
              title="候補者はまだいません。"
              text={
                <span>
                  求職者をピックアップすると
                  <br />
                  このページに候補者として表示されます。
                </span>
              }
            />
          ) : (
            offers?.map((offer: Offer, index: number) => {
              return (
                <div key={index}>
                  <UserList offer={offer} />
                </div>
              );
            })
          )}
        </section>
        <div className="mt-12">
          {total !== 0 && (
            <Pager
              page={page}
              pageLength={pageLength}
              setPage={setPage}
              setPageList={setPageList}
              pageList={pageList}
            />
          )}
        </div>
      </section>
    </AdminPage>
  );
};
