import '@/styles/pages/Other/TermsOfUse.scss';
import '@/styles/common/modal.scss';
import cn from 'classnames';

import React, { useEffect } from 'react';
import ItemsWrapper from '@/components/common/List/ItemsWrapper';
import ListItem from '@/components/common/List/ListItem';

type Props = {
  AllSpFlg?: boolean;
};

function TermsText(props: Props): React.ReactElement {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openActive: React.MouseEventHandler<HTMLImageElement> = (e): void => {
    const ele = e.currentTarget;
    ele.nextElementSibling?.classList.add('open', 'modal-overlay');
    const body = document.getElementsByTagName('body');
    body[0].classList.add('overfllow__hidden');
  };

  const closePassive: React.MouseEventHandler<HTMLSpanElement> = (e): void => {
    const ele = e.currentTarget;
    ele.parentNode?.parentElement?.classList.remove('open', 'modal-overlay');
    const body = document.getElementsByTagName('body');
    body[0].classList.remove('overfllow__hidden');
  };

  const actionNone: React.MouseEventHandler<HTMLDivElement> = (e): void => {
    e.stopPropagation();
  };

  return (
    <div className={cn(props.AllSpFlg ? 'sp-display' : 'sm: sp-display')}>
      <div className="border-b-2 border-gray-800 pb-16">
        <h4 className="mt-48 mbx-typography--heading_4 sm:mt-24">利用規約</h4>
      </div>
      <ItemsWrapper title="第1条（総則）" border={true}>
        <span className="mbx-typography--body_1">
          「MATCHBOX利用規約」は、株式会社マイナビワークス（以下「当社」といいます）が運営するポートフォリオサイト「MATCHBOX」（以下「本サイト」といいます）におけるクリエイターの活動支援に関する各種サービス（以下「本サービス」といいます）の利用について定めるものであり、本サービスを利用するすべての方に適用されるものとします。
        </span>
      </ItemsWrapper>
      <ItemsWrapper title="第２条（定義）" border={true}>
        <p className="mbx-typography--body_1">
          本規約において、以下の用語は、次のとおり定義します。
        </p>
        <ListItem type="bracket" number={1} className="mt-16">
          「会員」とは、所定の手続きにより、本サービスを利用するための会員登録を完了した方をいいます。
        </ListItem>
        <ListItem type="bracket" number={2} className="mt-4">
          「専用ページ」とは、本サービスにおける会員専用の管理画面ページをいいます。
        </ListItem>
        <ListItem type="bracket" number={3} className="mt-4">
          「認証情報」とは、専用ページへのログインに必要なID（電子メールアドレス）およびパスワードをいいます。
        </ListItem>
        <ListItem type="bracket" number={4} className="mt-4">
          「投稿データ等」とは、会員が本サイトに投稿した作品およびコメントその他のデータファイル等（会員が本サービスによらず独自に制作し、本サイトに投稿したものを含みます）をいいます。
        </ListItem>
        <ListItem type="bracket" number={5} className="mt-4">
          「スカウトサービス」とは、当社が、当社との間で会員に対する求人申し込みに関する契約を締結した求人者（以下「参画企業」といいます）に対して、会員の登録情報等（登録いただいた情報のうち、「氏名（ふりがな含む）、生年月日、都道府県を除く住所、電話番号、メールアドレス、家族情報、その他各入力画面にて「開示されない」と表示されるもの」を除いたもの）および投稿データ等を提供することにより、会員が当社を通じて参画企業へ求職の申し込みを行うことのできるサービスをいいます。
        </ListItem>
        <ListItem type="bracket" number={6} className="mt-4">
          「オファー機能」とは、会員がスカウトサービスを受けることを希望するために登録する機能のことをいいます。
        </ListItem>
        <ListItem type="bracket" number={7} className="mt-4">
          「ブロック設定」とは、オファー機能を登録した会員が、自己の情報の開示を希望しない企業について、データベースから非開示選択を行うことができる機能をいいます。
        </ListItem>
        <ListItem type="bracket" number={8} className="mt-4">
          「本規約」とは、「MATCHBOX利用規約」および「個人情報の取り扱いについて」をいいます。なお、会員以外の方に本規約が適用される場合、本規約中の「会員」を「利用者」と読み替えるものとします。
        </ListItem>
      </ItemsWrapper>
      <ItemsWrapper title="第３条（会員登録）" border={true}>
        <ListItem type="number" number={1} className="mt-16">
          会員は、会員登録の手続きが行った時点で、本規約の内容をすべて承諾したものとみなされます。不承諾の意思表示は、本サービスへの会員登録を行わないことをもってのみ認められるものとします。
        </ListItem>
        <ListItem type="number" number={2} className="mt-8">
          <>
            会員登録を希望する方が次の各号のいずれかに該当する場合、当社は、会員登録を承諾しないことがあります。また、会員登録の完了後に次の各号のいずれかに該当することが判明した場合、当社は、当該会員に対する本サービスの提供を停止する、または会員登録を抹消する等、当社が適切と判断する措置を講じることができるものとします。
            <ListItem type="bracket" number={1} className="mt-8">
              過去に当社サービス（本サービスに限られません）の規約等に違反したことがある場合。
            </ListItem>
            <ListItem type="bracket" number={2} className="mt-4">
              第三者になりすまし、または不正な目的で会員登録をする場合。
            </ListItem>
            <ListItem type="bracket" number={3} className="mt-4">
              同一人物が複数の会員登録を行った場合。
            </ListItem>
            <ListItem type="bracket" number={4} className="mt-4">
              会員登録を行った内容に虚偽または誤りがある場合。
            </ListItem>
            <ListItem type="bracket" number={5} className="mt-4">
              前各号のほか、当社が会員登録を不適当と判断する場合。
            </ListItem>
          </>
        </ListItem>
      </ItemsWrapper>
      <ItemsWrapper title="第４条（会員の義務）" border={true}>
        <ListItem type="number" number={1}>
          会員は、認証情報を自己の責任において管理するものとし、認証情報を利用して行われたすべての行為は、会員による行為とみなします。
        </ListItem>
        <ListItem type="number" number={2}>
          会員は、認証情報を第三者に譲渡、貸与または質入れ等して、第三者に利用させてはならないものとします。なお、会員が認証情報を第三者に利用させたことにより生じた損害について、当社は、一切の責任を負わないものとします。
        </ListItem>
        <ListItem type="number" number={3}>
          当社は、当社の責に帰すべき事由による場合を除き、認証情報が紛失もしくは流出されたこと、または盗用もしくは不正利用されたことにより生じた損害について、一切の責任を負わないものとします。
        </ListItem>
        <ListItem type="number" number={4}>
          会員は、本サービスに関して登録または提供した情報（以下「登録情報等」といいます）について変更がある場合、遅滞なく当社に対して変更の届出を行うものとします。なお、変更の届出が行われないこと、または変更の届出が遅滞したことにより生じた損害について、当社は、一切の責任を負わないものとします。
        </ListItem>
        <ListItem type="number" number={5}>
          <>
            当社は、会員が次の各号のいずれかに該当する場合、本サービスの提供を適切に行うことができない可能性があることを予め承諾するものとします。
            <ListItem type="bracket" number={1} className="mt-8">
              会員による登録情報等に虚偽、誤りまたは入力漏れ等があった場合。
            </ListItem>
            <ListItem type="bracket" number={2} className="mt-4">
              本サービスを提供するために必要な情報を会員から得られない場合。
            </ListItem>
            <ListItem type="bracket" number={3} className="mt-4">
              メールアドレスについて、フィルタ設定等がされているために、本サービスに関する電子メールを会員が受信できない場合。
            </ListItem>
          </>
        </ListItem>
      </ItemsWrapper>
      <ItemsWrapper title="第５条（本サービスの内容）" border={true}>
        <ListItem type="number" number={1}>
          <>
            <p>本サービスの内容は、次の各号のとおりとします。</p>
            <ListItem type="bracket" number={1} className="">
              本サイトへの作品の投稿、公開および当該作品の管理機能等の提供。
            </ListItem>
            <ListItem type="bracket" number={2} className="mt-4">
              専用ページの提供。
            </ListItem>
            <ListItem type="bracket" number={3} className="mt-4">
              容量拡張、デザインテンプレート等の有料コンテンツの提供。
            </ListItem>
            <ListItem type="bracket" number={4} className="mt-4">
              電子メールによるクリエイターの活動支援に関する有益な情報の提供。
            </ListItem>
            <ListItem type="bracket" number={5} className="mt-4">
              スカウトサービス。
            </ListItem>
            <ListItem type="bracket" number={6} className="mt-4">
              前各号に付帯関連するサービス。
            </ListItem>
          </>
        </ListItem>
        <ListItem type="number" number={2}>
          当社は、本サイトまたは専用ページに掲載することにより、会員に対して事前に通知のうえ、本サービスの内容を変更する場合があります。ただし、緊急やむを得ない場合、または軽微な変更の場合その他正当な事由がある場合には、事後の通知となる可能性があることを予め承諾するものとします。
        </ListItem>
      </ItemsWrapper>
      <ItemsWrapper title="第６条（本サービスの利用）" border={true}>
        <p className="mbx-typography--body_1">
          会員は、次の各号の事項をすべて理解したうえで、本サービスを利用するものとします。
        </p>
        <ListItem type="bracket" number={1} className="mt-8">
          会員は、登録情報等および投稿データ等の内容について一切の責任を負うこと。
        </ListItem>
        <ListItem type="bracket" number={2} className="mt-4">
          会員は、自らの意思によって本サービスを利用し、利用に関して一切の責任を負うこと。
        </ListItem>
        <ListItem type="bracket" number={3} className="mt-4">
          会員は、自己の責任と費用により本サービスを利用するために必要な端末設備、通信機器およびインターネット環境等を用意すること。
        </ListItem>
        <ListItem type="bracket" number={4} className="mt-4">
          会員は、本サービスの利用により第三者に損害を与えた場合、または第三者との間でトラブル・紛争等が生じた場合、自己の責任と費用により解決すること。また、当社は、これらに関して一切の責任を負わないこと。
        </ListItem>
        <ListItem type="bracket" number={5} className="mt-4">
          会員がオファー機能の登録を行うと、ブロック設定されない参画企業に対しては、各入力画面の項目うち氏名（ふりがな含む）、生年月日、都道府県を除く住所、電話番号、メールアドレス、家族情報、その他各入力画面にて「開示されない」と表示されるもの以外は入力した情報が開示されること。
        </ListItem>
        <ListItem type="bracket" number={6} className="mt-4">
          ブロック設定の企業データベースにはすべての企業が網羅されているわけではないこと。また、データベースの企業情報は随時更新されること。
        </ListItem>
        <ListItem type="bracket" number={7} className="mt-4">
          当社は、本サービスの円滑な提供または運営のため、本サービスに関する会員の行為および投稿データ等を閲覧することができること。ただし、当社は、これらを監視する義務を負うものではないこと。
        </ListItem>
      </ItemsWrapper>
      <ItemsWrapper title="第７条（投稿データ等）" border>
        <ListItem type="number" number={1} className="mt-8">
          会員は、専用ページにより、投稿データ等を公開することができるものとします。ただし、投稿データ等の1作品あたりの容量制限、または1会員あたりの公開数の制限等が本サイトにおいて定められている場合、この範囲に限られるものとします。
        </ListItem>
        <ListItem type="number" number={2} className="mt-4">
          投稿データ等の内容およびその公開・非公開の設定等については、すべて会員が責任を負うものとし、当社は、一切の責任を負わないものとします。
        </ListItem>
        <ListItem type="number" number={3} className="mt-4">
          投稿データ等については、公開・非公開の設定を問わず、会員自身が正当な権利を保有し、または正当な権利者から本サイトに公開することへの許諾を得ているものに限られるものとします。
        </ListItem>
        <ListItem type="number" number={4} className="mt-4">
          会員は、次の各号のいずれかに該当するデータ等を本サイトに投稿しないものとします。
        </ListItem>
        <ListItem type="bracket" number={1} className="mt-4">
          虚偽の情報が含まれるもの。
        </ListItem>
        <ListItem type="bracket" number={2} className="mt-4">
          第三者（参画企業および当社の役員および従業員を含みます）を個人として特定するに足りる情報（氏名、電話番号、メールアドレス等の個人情報を含みますが、これらに限られません）が含まれるもの。
        </ListItem>
        <ListItem type="bracket" number={3} className="mt-4">
          本サービスまたは本サイトを正当な理由なく否定または批判する意図または内容が含まれるもの。
        </ListItem>
        <ListItem type="bracket" number={4} className="mt-4">
          本サービスまたは本サイトと関連性が低い内容が含まれるもの。
        </ListItem>
        <ListItem type="bracket" number={5} className="mt-4">
          宗教もしくは宗教的思想の布教、または宣伝（本サービス、本サイトその他当社が提供する商品またはサービスを対象とする場合を含みます）等、本サービスまたは本サイトの利用と異なる意図が含まれるもの。
        </ListItem>
        <ListItem type="bracket" number={6} className="mt-4">
          前各号のほか、当社が形式、意図または内容について不適切と判断するもの。
        </ListItem>
      </ItemsWrapper>
      <ItemsWrapper title="第８条（利用料金）" border>
        <ListItem type="number" number={1} className="mt-8">
          会員は、本サービスのうち有料サービスを利用する場合、当社が別途定める条件に従い、利用料金を支払うものとします。なお、いかなる場合であっても、当社は、会員から支払われた利用料金の返還を行いません。
        </ListItem>
        <ListItem type="number" number={2} className="mt-4">
          当社は、会員の承諾を得ることなく、利用料金の変更を行うことができるものとします。なお、変更がある場合、当社は、会員に対して事前に通知するものとします。
        </ListItem>
      </ItemsWrapper>
      <ItemsWrapper title="第９条（個人情報の取り扱い）" border>
        <p className="mbx-typography--body_1">
          当社は、別途定める「個人情報の取り扱いについて（MATCHBOX）」に従い、会員の個人情報を取り扱うものとします。
        </p>
      </ItemsWrapper>
      <ItemsWrapper title="第１０条（業務の委託等）" border>
        <ListItem type="number" number={1} className="mt-8">
          当社は、本サービスに関する業務の一部または全部を第三者に委託し、または第三者と業務を提携することができるものとします。
        </ListItem>
        <ListItem type="number" number={2} className="mt-4">
          会員は、当社が業務委託先および業務提携先等（以下「業務提携先等」といいます）に対して、個人情報および秘密情報の保護に関する契約を締結したうえ、これらの情報を開示する可能性があることを予め承諾するものとします。
        </ListItem>
      </ItemsWrapper>
      <ItemsWrapper title="第１１条（権利関係）" border>
        <ListItem type="number" number={1} className="mt-8">
          本サービスを構成する著作物（プログラムその他一切の情報を含みます）の著作権その他の知的財産権は、当社または当社にその使用を許諾した第三者に帰属するものとします。ただし、投稿データ等の著作権その他の知的財産権は、当社に移転するものではなく、会員または会員に対して許諾を与えた権利者に権利が留保されるものとします。
        </ListItem>
        <ListItem type="number" number={2} className="mt-4">
          会員は、当社に対して、投稿データ等が第三者の著作権その他の知的財産権、プライバシー権、肖像権その他一切の権利を侵害するものでないこと、第三者を誹謗・中傷する内容が含まれないこと、公序良俗に反する内容が含まれないことを保証するものとします。投稿データ等に関して第三者から権利の主張、異議、苦情、損害賠償請求等を受けた場合、会員は、自己の責任と費用をもって解決するものとし、当社は、一切の責任を負わないものとします。なお、この場合において当社が損害を被ったときには、会員は、当社に対して一切の損害を賠償するものとします。
        </ListItem>
        <ListItem type="number" number={3} className="mt-4">
          会員は、第三者により投稿データ等に対する権利侵害があった場合、当該第三者との間に発生したすべてのトラブル・紛争等を自己の責任と費用をもって解決するものとし、当社は、一切の関与をせず、責任を負わないものとします。
        </ListItem>
        <ListItem type="number" number={4} className="mt-4">
          <>
            <p className="mbx-typography--body_1">
              当社は、投稿データ等について、会員の許諾を得ることなく、必要に応じて無償で次の各号の処理を行うことができるものとします。
            </p>
            <ListItem type="bracket" number={1} className="mt-4">
              内容に関する審査、分析、調査等をすること。
            </ListItem>
            <ListItem type="bracket" number={2} className="mt-4">
              本サービスの利用に適するよう必要な範囲で一部を改変または削除等すること。
            </ListItem>
          </>
        </ListItem>
        <ListItem type="number" number={5} className="mt-4">
          <>
            <p className="mbx-typography--body_1">
              当社は、投稿データ等を会員の許諾を得ることなく、当社の判断に基づき無償で次の各号の方法で利用し、または第三者に利用許諾することができるものとします。ただし、当社は、投稿データ等のうち会員が本サービスによらず独自に制作したものに限り、本項に基づく利用等【①を行わないものとします／②に先立ち、会員の許諾を得るものとします】。
            </p>
            <ListItem type="bracket" number={1} className="mt-4">
              本サービスの広報活動等を目的とする当社または業務提携先等が開設するソーシャル・ネットワーキング・サービスへの掲載。
            </ListItem>
            <ListItem type="bracket" number={2} className="mt-4">
              当社または業務提携先等の運営・発刊するウェブサイト、雑誌媒体その他本サービスに関する各種媒体への掲載。
            </ListItem>
            <ListItem type="bracket" number={3} className="mt-4">
              第三者の運営・発行するウェブサイト、雑誌媒体その他各種媒体で本サービスが紹介される際の掲載。
            </ListItem>
          </>
        </ListItem>
      </ItemsWrapper>
      <ItemsWrapper title="第１２条（統計情報の利用）" border>
        <p className="mbx-typography--body_1">
          当社は、会員個人を特定できないように加工した利用状況や統計データ（以下「統計データ等」といいます）を作成し、統計データ等を何ら制約なく利用することができるものとします。なお、統計データ等に関する著作権は、当社に帰属するものとします。
        </p>
      </ItemsWrapper>
      <ItemsWrapper title="第１３条（禁止事項）" border>
        <ListItem type="number" number={1} className="mt-8">
          <>
            <p className="mbx-typography--body_1">
              会員は、次の各号のいずれかに該当し、または該当するおそれのある行為を行ってはならないものとします。
            </p>
            <ListItem type="bracket" number={1} className="mt-4">
              本規約に違反する行為。
            </ListItem>
            <ListItem type="bracket" number={2} className="mt-4">
              重複、虚偽もしくは自己以外の情報を、登録または提供する行為。
            </ListItem>
            <ListItem type="bracket" number={3} className="mt-4">
              当社または第三者の著作権、知的財産権、プライバシー権、肖像権、名誉その他権利を侵害する行為。
            </ListItem>
            <ListItem type="bracket" number={4} className="mt-4">
              会員としての地位および権利義務を当社の承諾なく譲渡、貸与等する行為。
            </ListItem>
            <ListItem type="bracket" number={5} className="mt-4">
              第三者の個人情報を本人の承諾なく開示または漏洩する行為。
            </ListItem>
            <ListItem type="bracket" number={6} className="mt-4">
              有害なコンピュータプログラム等を投稿もしくは送信し、または第三者が受信可能な状態におく行為。
            </ListItem>
            <ListItem type="bracket" number={7} className="mt-4">
              本サービスの機能利用制限もしくは編集制限を解除し、アクセス制御機能を解除し、またはこれらに関する情報、機器、ソフトウェア等を流通させる行為。
            </ListItem>
            <ListItem type="bracket" number={8} className="mt-4">
              個人や団体を差別、批判、攻撃または誹謗中傷する行為。
            </ListItem>
            <ListItem type="bracket" number={9} className="mt-4">
              第三者または当社に不利益を与える行為。
            </ListItem>
            <ListItem type="bracket" number={10} className="mt-4">
              営業活動または営利を目的として情報を提供する行為。
            </ListItem>
            <ListItem type="bracket" number={11} className="mt-4">
              政治活動、選挙活動、宗教活動またはこれらに類する行為。
            </ListItem>
            <ListItem type="bracket" number={12} className="mt-4">
              本サービスを通じて入手した情報を、複製、販売、出版その他私的利用の範囲を超えて使用する行為。
            </ListItem>
            <ListItem type="bracket" number={13} className="mt-4">
              本サービスの運営を妨げ、または当社の信用を毀損する行為。
            </ListItem>
            <ListItem type="bracket" number={14} className="mt-4">
              暴力的な言動またはこれに類する行為。
            </ListItem>
            <ListItem type="bracket" number={15} className="mt-4">
              法令または公序良俗に反する行為。
            </ListItem>
            <ListItem type="bracket" number={16} className="mt-4">
              本サービスの利用に関係しないと当社が判断する行為。
            </ListItem>
            <ListItem type="bracket" number={17} className="mt-4">
              前各号のほか、当社が不適当と判断する行為。
            </ListItem>
          </>
        </ListItem>
        <ListItem type="number" number={2} className="mt-4">
          当社は、会員の行為が前項各号のいずれかに該当し、または該当するおそれがあると判断する場合、直ちに、会員に対する本サービスの提供を停止する、関連する投稿データ等を削除する、会員登録を抹消する等、当社が適切と判断する措置を講じることができるものとします。
        </ListItem>
        <ListItem type="number" number={3} className="mt-4">
          当社は、前項の権利の行使に代えて、または権利の行使とともに、会員に対して事実確認、および第三者との調整等を要請することができるものとします。
        </ListItem>
      </ItemsWrapper>
      <ItemsWrapper title="第１４条（免責事項）" border>
        <ListItem type="number" number={1} className="mt-8">
          当社は、本サービスの品質および機能に関して、その完全性、正確性および有用性等について、保証しないものとします。
        </ListItem>
        <ListItem type="number" number={2} className="mt-4">
          当社は、本サービスの正常稼働に最善を尽くしますが、一切の障害が生じないことを保証するものではありません。また、当社は、本サービスを利用したこと、または利用できないことにより生じた損害について、当社の責に帰すべき事由による場合を除き、責任を負わないものとします。
        </ListItem>
        <ListItem type="number" number={3} className="mt-4">
          当社は、不可抗力その他当社の責に帰さない事由により、登録情報等または投稿データ等が消失、毀損、漏洩、または本来の目的以外に利用された場合であっても、これにより生じた損害について、一切の責任を負わないものとします。
        </ListItem>
        <ListItem type="number" number={4} className="mt-4">
          当社は、投稿データ等の完全性、正確性、適切性ならびに合法性等、および投稿データ等の形式、意図または内容に関して第三者（参画企業および利用者を含みます。以下本項において同様とします）に発生した損害（リンクされたWebサイトに起因するコンピュータウイルスへの感染等の被害による場合を含みます）について、当社の責に帰すべき事由による場合を除き、一切の責任を負わないものとします。この場合、当該投稿データ等を投稿した会員は、当該投稿データに関して第三者との間に生じたいかなる紛争、損害についても自己の費用および責任において解決するものとし、当社に一切の迷惑をかけないものとします。
        </ListItem>
        <ListItem type="number" number={5} className="mt-4">
          スカウトサービスにおいて、各入力画面の項目のうち氏名（ふりがな含む）、生年月日、都道府県を除く住所、電話番号、メールアドレス、家族情報、その他各入力画面にて「開示されない」と表示されるものについては開示されませんが、その他の項目に入力した情報は開示されます。当該項目に個人が特定され得る情報を入力した結果発生した事案に関しては、当社は一切の責任を負わないものとします。
        </ListItem>
        <ListItem type="number" number={6} className="mt-4">
          スカウトサービスにおいて、会員が開示を希望しない企業については必ず会員の責任でブロック設定を行ってください。また、企業の合併・商号変更等に伴い、企業データベースは随時更新されますので、定期的に設定のご確認をお願いします。これらを怠ったことに基づく会員と参画企業のトラブル等について、当社は一切の責任を負わないものとします。
        </ListItem>
        <ListItem type="number" number={7} className="mt-4">
          スカウトサービスにおいて、当社は会員に対して、参画企業による採用のほか、参画企業との面談が受けられること等、サービスの結果の一切について、その確実性を保証するものではありません。
        </ListItem>
        <ListItem type="number" number={8} className="mt-4">
          当社は、本規約に定める措置により、会員または第三者に損害が発生したとしても、一切の責任を負わないものとします。
        </ListItem>
        <ListItem type="number" number={9} className="mt-4">
          当社は、不可抗力その他当社の責に帰さない事由から生じた損害、予見の有無を問わず特別の事情から生じた損害、ならびに間接的損害および派生的損害について、一切の責任を負わないものとします。
        </ListItem>
      </ItemsWrapper>
      <ItemsWrapper title="第１５条（反社会的勢力の排除）" border>
        <ListItem type="number" number={1} className="mt-8">
          会員は、次の者に該当しないこと、および今後もこれに該当しないことを保証し、会員がこれに該当した場合、または該当していたことが判明した場合には、当社は、別段の催告を要せず、直ちに、本サービスの利用を停止させることができるものとします。暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団、その他これらに準ずる者（以下これらを「反社会的勢力」といいます）であること、または反社会的勢力であったこと。
        </ListItem>
        <ListItem type="number" number={2} className="mt-4">
          <>
            <p className="mbx-typography--body_1">
              会員が次の各号のいずれかに該当した場合、当社は、別段の催告を要せず、直ちに本サービスの利用を停止させることができるものとします。
            </p>
            <ListItem type="bracket" number={1} className="mt-4">
              当社に対して脅迫的な言動をすること、もしくは暴力を用いること、または当社の名誉・信用を毀損する行為を行うこと。
            </ListItem>
            <ListItem type="bracket" number={2} className="mt-4">
              偽計または威力を用いて当社の業務を妨害すること。
            </ListItem>
            <ListItem type="bracket" number={3} className="mt-4">
              当社に対して法的な責任を超えた不当な要求をすること。
            </ListItem>
            <ListItem type="bracket" number={4} className="mt-4">
              反社会的勢力である第三者をして前三号の行為を行わせること。
            </ListItem>
          </>
        </ListItem>
      </ItemsWrapper>
      <ItemsWrapper title="第１６条（退会）" border>
        <ListItem type="number" number={1} className="mt-8">
          会員は、所定の手続きにより、随時退会することができるものとします。退会により、会員は、本サービスの利用に関する一切の権利を失うものとします。
        </ListItem>
        <ListItem type="number" number={2} className="mt-4">
          退会した会員の登録情報等および投稿データ等は、当社が行う退会処理により、所定の期間が経過した後に消去されます。ただし、外部キャッシュサーバに残っているデータおよびソーシャル・ネットワーキング・サービスに共有されたデータ等はこの限りではなく、当社は、これらをすべて消去する義務を負わないものとします。
        </ListItem>
      </ItemsWrapper>
      <ItemsWrapper title="第１７条（本サービスの一時中止）" border>
        <ListItem type="number" number={1} className="mt-8">
          当社は、本サービスの円滑な提供および運営を目的として、本サービスの提供を一時中止することができるものとします。
        </ListItem>
        <ListItem type="number" number={2} className="mt-4">
          <>
            前項のほか、当社は、次の各号のいずれかに該当する場合、本サービスの提供を一時中止することができます。
            <ListItem type="bracket" number={1} className="mt-4">
              本サービスの設備または通信回線等が事故等により停止した場合。
            </ListItem>
            <ListItem type="bracket" number={2} className="mt-4">
              通常のウィルス対策では防止できないウィルスによる被害、火災、停電、天災地変その他の不可抗力により本サービスの提供が困難または不可能となった場合。
            </ListItem>
            <ListItem type="bracket" number={3} className="mt-4">
              本サービスに関するシステム等に突発的な故障が発生した場合。
            </ListItem>
            <ListItem type="bracket" number={4} className="mt-4">
              法令等に基づく措置により本サービスの提供を中止する必要がある場合。
            </ListItem>
            <ListItem type="bracket" number={5} className="mt-4">
              前各号のほか、不測の事態の発生、または運営上もしくは技術上、本サービスの提供が困難もしくは不可能となった場合。
            </ListItem>
          </>
        </ListItem>
      </ItemsWrapper>
      <ItemsWrapper title="第１８条（本サービスの終了）" border>
        <p className="mbx-typography--body_1">
          当社は、次の各号のいずれかに該当する場合、本サービスの一部または全部の提供を終了することができるものとします。
        </p>
        <ListItem type="bracket" number={1} className="mt-24">
          終了日の３０日前までに会員に通知した場合。
        </ListItem>
        <ListItem type="bracket" number={2} className="mt-8">
          前条第2項による一時中止の解消が見込まれない場合。
        </ListItem>
      </ItemsWrapper>
      <ItemsWrapper title="第１９条（損害賠償）" border>
        <p className="mbx-typography--body_1">
          会員が本規約に違反し、または会員の責に帰すべき事由により当社または第三者に損害が発生した場合、会員は、自己の責任と負担により発生した一切の損害を賠償するものとします。
        </p>
      </ItemsWrapper>

      <ItemsWrapper title="第２０条（分離条項）" border>
        <p className="mbx-typography--body_1">
          本規約の一部の効力が、法令や確定判決により無効とされた場合であっても、その他の条項は引き続き効力を有するものとします。
        </p>
      </ItemsWrapper>

      <ItemsWrapper title="第２１条（本規約の変更）" border={true}>
        <>
          <ListItem type="number" number={1}>
            <>
              当社は、次の各号に定める場合、会員の同意を得ることなく、本規約を変更することができるものとします。
              <ListItem type="bracket" number={1}>
                本規約の変更が、会員の一般の利益に適合する場合。
              </ListItem>
              <ListItem type="bracket" number={2}>
                本規約の変更が、本サービスの目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容、その他の変更にかかる事情に照らして合理的なものである場合。
              </ListItem>
            </>
          </ListItem>
          <ListItem type="number" number={2}>
            当社は、前項による本規約の変更にあたり、変更実施日の３０日前までに、本規約を変更する旨および変更後の本規約の内容を本サイトまたは専用ページに掲載することにより、会員に対して通知するものとします。
          </ListItem>
        </>
      </ItemsWrapper>
      <ItemsWrapper title="第２２条（準拠法および管轄）" border={true}>
        <p className="mbx-typography--body_1">
          本サービスおよび本規約は日本法を準拠法とし、これらに関して生じる一切の紛争については、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
        </p>
      </ItemsWrapper>
      <p className="mbx-typography--body_1 pb-16 mt-40">2017年11月1日制定</p>
      <p className="mbx-typography--body_1 pb-16">2022年3月2日改定</p>
      <p className="mbx-typography--body_1 pb-52">2024年10月24日改定</p>
      <div className="border-b-2 border-gray-800 pb-16 pt-52" id="privacy">
        <h4 className="mt-48 mbx-typography--heading_4">
          個人情報の取り扱いについて（MATCHBOX）
        </h4>
      </div>
      <p className="mbx-typography--body_1 mt-40">
        株式会社マイナビワークス（以下「当社」といいます）が提供する、「MATCHBOX」における利用者の活動支援に関する各種サービス（職業紹介サービスを含む、以下「本サービス」といいます）をご利用になる方（以下「利用者」といいます）のプライバシーを尊重し、利用者の個人情報（以下の定義に従います）の管理に細心の注意を払い、これを適正に取り扱います。
      </p>
      <ItemsWrapper title="当社の個人情報の取り扱い" border={true}>
        <p className="mbx-typography--body_1">
          当社における個人情報の取り扱いは「
          <a
            href="https://mynavi-works.jp/privacy/privacy2.html"
            className="text-blue-700 underline"
            target="_blank"
            rel="noreferrer"
          >
            個人情報保護方針
          </a>
          」に基づいて行われます。
        </p>
      </ItemsWrapper>
      <ItemsWrapper title="個人情報" border={true}>
        <p className="mbx-typography--body_1">
          個人情報とは、利用者個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により利用者個人を識別することができるもの、およびその情報のみでは識別できない場合であっても、他の情報と容易に照合することができ、それにより結果的に利用者個人を識別することができるもの、ならびに利用者に関する個人識別符号が含まれるものをいうものとします。
          <br />
          また当社では、Cookie情報、ユーザー識別子、IPアドレス、広告識別子、位置情報、Web閲覧履歴など(以下「インフォマティブデータ」といいます)を取り扱いますが、第三者から提供を受けたインフォマティブデータのうち個人関連情報に該当するものを、当社サービスに登録された個人情報と紐付けて利用する場合は、これらも個人情報として扱うものとします。
        </p>
      </ItemsWrapper>
      <ItemsWrapper title="個人情報の利用目的" border={true}>
        <p className="mbx-typography--body_1 mt-16">
          個人情報の利用目的は次のとおりです。当社は、事前の同意を得ず、利用目的の範囲を超えて個人情報を利用することはありません。個人情報の利用停止については、利用者の申し出に基づき適切に対処します。なお、下記利用目的の範囲において、第三者から提供された個人関連情報（利用環境・属性情報・位置情報・利用履歴情報・端末識別情報（Cookie等）・広告識別子・広告情報・リサーチパネルデータ）を本サービス利用者情報と紐付け、広告配信効果測定および分析に利用する場合があります。
        </p>
        <ListItem type="bracket" number={1} className="mt-16">
          利用者に対する本サービスの提供。
        </ListItem>
        <ListItem type="bracket" number={2} className="mt-4">
          「MyPage」サービスにおける個人認証、その他「MyPage」の運用管理。
        </ListItem>
        <ListItem type="bracket" number={3} className="mt-4">
          スカウトサービスにおける、サービス利用企業（以下「参画企業」といいます）に対するスカウト登録情報（氏名（ふりがな含む）、生年月日、都道府県を除く住所、電話番号、メールアドレス、家族情報、その他各入力画面にて「開示されない」と表示されるものを除く）の提供。
        </ListItem>
        <ListItem type="bracket" number={4} className="mt-4">
          求人求職情報の適切なマッチング。（マッチングに際し、当社が運営する各職業紹介サービスとの連携を含みます。）
        </ListItem>
        <ListItem type="bracket" number={5} className="mt-4">
          利用者の承諾に基づく、参画企業および当社が業務提携を行う職業紹介事業者（以下「業務提携先」といいます）への個人情報の提供。
        </ListItem>
        <ListItem type="bracket" number={6} className="mt-4">
          当社および当社サービスを紹介するための参考資料として、個人を特定できないよう加工した情報の取引先企業への提供。
        </ListItem>
        <ListItem type="bracket" number={7} className="mt-4">
          当社からのお知らせ、ニュース、アンケート（一部広告を含みます）の適正な配信。
        </ListItem>
        <ListItem type="bracket" number={8} className="mt-4">
          モニター等への応募、プレゼント発送、記事作成等における取材対象者の募集。
        </ListItem>
        <ListItem type="bracket" number={9} className="mt-4">
          本サービスに関するお問い合わせへの回答、および緊急時の連絡。
        </ListItem>
        <p className="mbx-typography--body_1 mt-16">
          ※お問い合わせに伴う個人情報の取り扱いについて、こちらをご参照ください。
        </p>
      </ItemsWrapper>
      <ItemsWrapper border={true} title="個人情報を提供することの任意性について">
        <p className="mbx-typography--body_1 mt-16">
          個人情報を提供することは任意です。ただし、それぞれ必要となる項目を入力いただけない場合には、各種サービスを受けられないことがあります。
        </p>
      </ItemsWrapper>
      <ItemsWrapper title="個人情報の第三者への提供" border={true}>
        <p className="mbx-typography--body_1 mt-16">
          当社は、利用者の個人情報について、利用目的に定める場合を除き、原則として第三者に提供することはせず、利用者の同意を得た場合に限ります。ただし、次の場合には、関係法令に反しない範囲で、利用者の同意なく個人情報を提供することがあります。
        </p>
        <ListItem type="bracket" number={1} className="mt-16">
          法令に基づく場合。
        </ListItem>
        <ListItem type="bracket" number={2} className="mt-4">
          人の生命、身体または財産の保護のために必要がある場合であって、利用者の同意を得ることが困難である場合。
        </ListItem>
        <ListItem type="bracket" number={3} className="mt-4">
          公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、利用者の同意を得ることが困難である場合。
        </ListItem>
        <ListItem type="bracket" number={4} className="mt-4">
          国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、利用者の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合。
        </ListItem>
        <ListItem type="bracket" number={5} className="mt-4">
          裁判所、検察庁、警察、弁護士会、消費者センターまたはこれらに準じた権限を有する機関から、個人情報についての提供を求められた場合。
        </ListItem>
        <ListItem type="bracket" number={6} className="mt-4">
          利用者から明示的に第三者への開示または提供を求められた場合。
        </ListItem>
      </ItemsWrapper>
      <ItemsWrapper title="参画企業、業務提携先への個人情報の提供について" border={true}>
        <p className="mbx-typography--body_1 mt-16">
          参画企業および業務提携先への個人情報の提供は、利用者の同意を得たうえで、次のとおり行います。
          <br />
          目的：利用者の就職・転職活動の支援
          <br />
          提供する個人情報の項目：本サービスに登録された項目
          <br />
          提供方法： 暗号化されたデータまたは書面での提供。
        </p>
      </ItemsWrapper>

      <ItemsWrapper title="第三者の範囲" border={true}>
        <p className="mbx-typography--body_1 mt-16">
          次の場合に個人情報の提供を受ける者は、第三者には該当しないものとします。
        </p>
        <ListItem type="bracket" number={1} className="mt-16">
          当社が利用目的の達成に必要な範囲内において、個人情報を取り扱う業務の全部または一部を委託する場合。
        </ListItem>
        <ListItem type="bracket" number={2} className="mt-4">
          当社の合併、その他の事由による事業の承継に伴って個人情報が提供される場合。
        </ListItem>
      </ItemsWrapper>
      <ItemsWrapper title="要配慮個人情報" border={true}>
        <p className="mbx-typography--body_1 mt-16">
          当社は、次に示す要配慮個人情報の取得は原則として行いません。やむを得ない事由により取得を行う場合には、利用者の事前の同意を得るものとします。
        </p>
        <ListItem type="Disc" className="mt-16">
          利用者の人種、信条、社会的身分、病歴、犯罪の経歴、犯罪の被害にあった事実、その他利用者に対する不当な差別、偏見その他不利益が生じないようにその取り扱いに特に配慮を要するものとして政令で定める記述等が含まれる個人情報。
        </ListItem>
      </ItemsWrapper>

      <ItemsWrapper title="免責" border={true}>
        <p className="mbx-typography--body_1 mt-16">
          第三者による個人情報の取得に関して、次の場合、当社は何らの責任を負いません。
        </p>
        <ListItem type="bracket" number={1} className="mt-16">
          利用者が本サービスの機能または別の手段を用いて特定の企業に個人情報を明らかにする場合。
        </ListItem>
        <ListItem type="bracket" number={2} className="mt-4">
          利用者の活動情報、または本サービスに入力した情報により、期せずして本人が特定できてしまった場合。
        </ListItem>
        <ListItem type="bracket" number={3} className="mt-16">
          本サービスサイトからリンクされる外部サービスにおいて、利用者が個人情報を提供し、また、それが利用された場合。
        </ListItem>
        <ListItem type="bracket" number={4} className="mt-4">
          利用者以外の第三者が個人を識別できる情報（ID・パスワード等）を入手した場合。
        </ListItem>
      </ItemsWrapper>

      <ItemsWrapper border={true} title="参画企業、業務提携先における個人情報の管理">
        <p className="mbx-typography--body_1 mt-16">
          利用者の同意に基づいて参画企業および業務提携先に提供された個人情報は、参画企業および業務提携先における個人情報の取り扱いに関する定めに則って管理されます。
        </p>
      </ItemsWrapper>

      <ItemsWrapper border={true} title="外部委託について">
        <p className="mbx-typography--body_1 mt-16">
          当社は、個人情報を取り扱う業務の全部または一部を外部に委託する場合があります。委託を行う場合には、充分な個人情報保護水準を確保していることを条件として委託先を選定し、機密保持に関する契約を結んだうえで行います。また、当該委託先における管理については必要かつ適切な監督を行います。
        </p>
      </ItemsWrapper>

      <ItemsWrapper border={true} title="統計データの利用">
        <p className="mbx-typography--body_1 mt-16">
          当社は、提供された個人情報を抽出・集計して統計データを作成することがあり、当該統計データについて、何ら制約なく利用することができるものとします。なお、この場合の統計データの著作権は当社に帰属するものとします。
        </p>
      </ItemsWrapper>

      <ItemsWrapper border={true} title="「個人情報の取り扱いについて」の変更">
        <p className="mbx-typography--body_1 mt-16">
          当社は、「個人情報の取り扱いについて」を継続的に見直し、その改善に努めます。その際、利用者に予告なく変更することがあります。ただし、利用目的を変更する場合には、利用者の同意を得るものとします。
        </p>
      </ItemsWrapper>
      <ItemsWrapper border={true} title="個人情報の管理について">
        <p className="mbx-typography--body_1 mt-16">
          当社は、個人情報への不当なアクセスまたは個人情報の紛失、破壊、改竄、漏洩等の危険に対して、技術面および組織面において必要な安全対策を継続的に講じるよう努めています。また、当社は個人情報の保護に関する法令、業界規範・慣習、公序良俗を遵守します。
          <br />
          ※当社および参画企業は、利用者または利用者から委託を受けた正当な権利者から、退職、本サービスの登録の取消し、その他の理由により申し出を受けた場合であっても、個人情報の取得として提出された履歴書・職務経歴書等を返却する義務を負いません。
        </p>
      </ItemsWrapper>
      <ItemsWrapper border={true} title="個人情報の変更等">
        <p className="mbx-typography--body_1 mt-16">
          利用者は、本サービスにおいて会員登録した個人情報について、いつでも変更、追加または削除することができます。また、利用者の求めに応じ、保有個人データの利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去および第三者への提供の停止、ならびに第三者提供記録の開示を受けることができます。次のメールアドレス宛にご連絡ください。
          <br />
          E-mail：haken-ki@mynavi.jp
        </p>
      </ItemsWrapper>
      <ItemsWrapper border={true} title="本人確認">
        <p className="mbx-typography--body_1 mt-16">
        当社は、利用者からの個人情報の開示、内容の訂正、追加または削除、利用の停止、消去および第三者への提供の停止、ならびに第三者提供記録の開示の求めに応じる場合には、個人を識別できる情報（氏名、住所、電話番号、メールアドレス、ID・パスワード等）により、利用者本人であることを確認します。なお、利用者以外の第三者が個人を識別できる情報を使用した場合には、当社は責任を負いません。
        </p>
      </ItemsWrapper>
      <ItemsWrapper border={true} title="お問い合わせ">
        <p className="mbx-typography--body_1 mt-16">
          個人情報の取り扱いに関するお問い合わせについては、次のメールアドレス宛にご連絡ください。
          <br />
          E-mail：haken-ki@mynavi.jp
        </p>
      </ItemsWrapper>
      <ItemsWrapper border={true} title="個人情報保護管理者">
        <p className="mbx-typography--body_1 mt-16">
          株式会社マイナビワークス
          <br />
          管理統括本部長
          <br />
          mw-personal_data@mynavi.jp
        </p>
      </ItemsWrapper>

      <ItemsWrapper border={false} title="※プライバシーマークについて">
        <p className="mbx-typography--body_1 mt-16">
          当社は、プライバシーマーク付与事業者です。プライバシーマークとは、個人情報に関するJIS規格JIS
          Q
          15001を満たす人情報保護マネジメントシステムを策定し、運用しているということを一般財団法人日本情報経済社会推進協会（JIPDEC）等の審査機関が認定した場合に使用できるマークです。
        </p>
      </ItemsWrapper>
      <div className="border-b-2 border-gray-800 pb-16 mt-104">
        <h4 className="mt-48 mbx-typography--heading_4">
          インフォマティブデータの取り扱いについて
        </h4>
      </div>
      <p className="mbx-typography--body_1 mt-40">
        本サービスでは、利用者のインフォマティブデータを取得し、広告配信などに利用します。
        <br />
        インフォマティブデータの詳細については、当社の定める「
        <a
          href="https://mynavi-works.jp/privacy/privacy2.html"
          className="text-blue-700 underline"
          target="_blank"
          rel="noreferrer"
        >
          インフォマティブデータの取扱方針
        </a>
        」をご確認ください。
        <br />
        なお、インフォマティブデータを個人関連情報として取り扱い、本サービスに登録された個人情報と紐づけて利用する場合は、個人情報として取り扱います。「個人情報の取り扱いについて」をご参照ください。
      </p>
      <ItemsWrapper border={true} title="インフォマティブデータの外部送信">
        <div className="inner">
          <p className="intro__text text-left mbx-typography--body_1">
            本サービスでは、利用者のウェブ体験の向上や、利用者ごとにパーソナライズしたコンテンツ・広告の表示、アクセス解析による当社ウェブサイトの改善のために、クッキー等の端末識別子（インフォマティブデータ）を利用して、利用者に関する情報（利用者情報）を収集し、外部事業者に送信しています。外部事業者の名称・サービス名、外部事業者に送信される利用者情報の内容、送信される情報の利用目的については、以下より詳細をご確認ください。
          </p>
        </div>
        <div className="table__wrap mt-16">
          <table className={cn('service__table', props.AllSpFlg ? 'hidden' : 'sm:hidden')}>
            <thead>
              <tr>
                <th>分類</th>
                <th>事業者</th>
                <th>サービス名</th>
                <th>
                  送信される利用者情報
                  <br />
                  と利用目的
                </th>
                <th>
                  プライバシー
                  <br />
                  ポリシー
                </th>
                <th>
                  オプトアウト
                  <br />
                  ページ
                </th>
                <th>
                  クッキー
                  <br />
                  ポリシー
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="no__border">
                  <span className="classification performance">アクセス解析</span>
                </td>
                <td rowSpan={2}>Google LLC</td>
                <td className="no__border">
                  <span className="service_names">Googleアナリティクス</span>
                </td>
                <td className="no__border">
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                    alt=""
                    height="30px"
                    width="30px"
                    onClick={openActive}
                    className="icon__button"
                  />
                  <div className="terms-modal">
                    <div className="terms-modal__content" onClick={actionNone}>
                      <span className="terms-modal__close" onClick={closePassive}>
                        ×
                      </span>
                      <h2 className="terms-modal__title border-gray-800">
                        <span className="terms-modal__company__names">Google LLC</span>
                        <span className="terms-modal__service_names">Googleアナリティクス</span>
                      </h2>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            ・クライアントID
                            <br />
                            ・IPアドレス
                            <br />
                            ・閲覧ページURL
                            <br />
                            ・リファラー
                            <br />
                            ・デバイスタイプ
                            <br />
                            ・オペレーティングシステム
                            <br />
                            ・ブラウザタイプ
                            <br />
                            ・利用言語
                            <br />
                            ・画像解像度
                          </p>
                        </div>
                      </div>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">利用目的</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            当社は、ウェブサイトのパフォーマンスを分析し、改善に役立てるため、サイト利用者に関する情報を収集し、Googleへ送信します。Googleでは、送信された情報を分析、統計化し、ウェブサイトのパフォーマンスに関するレポートを当社に提供します。また、Google
                            では、これらの情報をGoogle独自のサービス・プロダクトの改善を目的として利用する場合があります。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text__center no__border">
                  <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center no__border">
                  <a
                    href="https://tools.google.com/dlpage/gaoptout/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center no__border">
                  <a
                    href="https://policies.google.com/technologies/cookies?hl=ja"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="classification targeting">広告・マーケティング</span>
                </td>
                <td>
                  <span className="service_names">コンバージョン測定</span>
                </td>
                <td>
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                    alt=""
                    height="30px"
                    width="30px"
                    onClick={openActive}
                    className="icon__button"
                  />
                  <div className="terms-modal">
                    <div className="terms-modal__content" onClick={actionNone}>
                      <span className="terms-modal__close" onClick={closePassive}>
                        ×
                      </span>
                      <h2 className="terms-modal__title">
                        <span className="terms-modal__company__names">Google LLC</span>
                        <span className="terms-modal__service_names">コンバージョン測定</span>
                      </h2>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            ・クライアントID
                            <br />
                            ・広告のクリック情報
                          </p>
                        </div>
                      </div>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">利用目的</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            Googleにより、サイト利用者が当社の広告をクリックした情報が一意のクライアントIDと共にクッキーに保管されます。当社ウェブサイトでコンバージョンが発生すると、当社ウェブサイトに挿入したコンバージョン
                            トラッキング
                            タグによってクッキー情報が読み取られ、コンバージョン情報とともにGoogleに送信されます。当社では、Googleから提供されるレポートを元に広告の効果測定を行います。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text__center">
                  <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center">
                  <a
                    href="https://tools.google.com/dlpage/gaoptout/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center">
                  <a
                    href="https://policies.google.com/technologies/cookies?hl=ja"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="classification targeting">広告・マーケティング</span>
                </td>
                <td>LINEヤフー株式会社</td>
                <td>
                  <span className="service_names">LINE広告</span>
                </td>
                <td>
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                    alt=""
                    height="30px"
                    width="30px"
                    onClick={openActive}
                    className="icon__button"
                  />
                  <div className="terms-modal">
                    <div className="terms-modal__content" onClick={actionNone}>
                      <span className="terms-modal__close" onClick={closePassive}>
                        ×
                      </span>
                      <h2 className="terms-modal__title">
                        <span className="terms-modal__company__names">LINEヤフー株式会社</span>
                        <span className="terms-modal__service_names">LINE広告</span>
                      </h2>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            ・お客様がお使いの端末情報
                            <br />
                            ・ブラウザを識別する広告識別子
                            <br />
                            ・Cookie IDや端末の種類
                            <br />
                            ・OS
                            <br />
                            ・言語
                            <br />
                            ・タイムゾーン設定
                            <br />
                            ・ブラウザの種類
                            <br />
                            ・アプリバージョン
                            <br />
                            ・ネットワーク情報（通信事業者の名称、IPアドレスなど）
                            <br />
                            ・位置情報
                            <br />
                            ・サービス利用状況（利用日時、滞在時間、閲覧・タップ・クリックしたコンテンツや広告など）
                            <br />
                            ・属性情報、購買履歴、視聴履歴、検索履歴および位置情報を含むお客様に関する行動履歴
                            <br />
                            ・ウェブサイトの訪問履歴（訪問日時、URLなどを含む）
                          </p>
                        </div>
                      </div>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">利用目的</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            当社では、LINEヤフー株式会社のLINE広告サービスを利用し、サイト利用者に関する情報の分析とLINEサービス上における当該サイト利用者に適した広告の配信を行っています。LINEヤフーでは、当該LINE広告のマーケティングサービスの運営・改善のために、サイト利用者に関する情報を利用しています。なお、LINEヤフーでは、収集データ、その他LINEヤフーが保有する各種データとの掛け合わせや組み合わせ・必要な各種データの不可・補完等を行うことができ、例えば、LINEヤフーでは、サイト利用者の訪問履歴と広告識別子や内部識別子などを関連づけ、当社以外から収集した利用者情報と突合・分析し、当社以外の事業者の広告の最適化にも利用する場合があります。また、LINEは、「LINEで送る」や「いいね」などのプラグイン機能が当社ウェブサイトに設置されている場合、ウェブサイトにおけるプラグインからのログイン記録の他、ブラウザ上でLINEにログインした状態で訪問したプラグイン機能が設置されたウェブサイトのURL情報をLINEアカウントに関連づけて取得する場合があります。このように取得したデータは、LINEにおいて、LINEサービスがどのように利用されているかを把握し、その維持・改善、セキュリティ・不正利用防止の目的などに利用されます。なお、LINEからログアウトした状態では、これらの情報は個人を識別しない形でセキュリティ・不正利用防止の目的にのみ利用されます。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text__center">
                  <a href="https://line.me/ja/terms/policy/" target="_blank" rel="noreferrer">
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center"></td>
                <td className="text__center"></td>
              </tr>
              <tr>
                <td>
                  <span className="classification targeting">広告・マーケティング</span>
                </td>
                <td>Meta Platforms, Inc.</td>
                <td>
                  <span className="service_names">Facebook広告</span>
                </td>
                <td>
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                    alt=""
                    height="30px"
                    width="30px"
                    onClick={openActive}
                    className="icon__button"
                  />
                  <div className="terms-modal">
                    <div className="terms-modal__content" onClick={actionNone}>
                      <span className="terms-modal__close" onClick={closePassive}>
                        ×
                      </span>
                      <h2 className="terms-modal__title">
                        <span className="terms-modal__company__names">Meta Platforms, Inc.</span>
                        <span className="terms-modal__service_names">Facebook広告</span>
                      </h2>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            ・ユーザーID
                            <br />
                            ・アクセスしているサイトURL
                            <br />
                            ・アクセスした日付と時刻
                            <br />
                            ・ユーザーが利用しているブラウザーに関連した情報
                            <br />
                            ・利用者がアクションを実行した広告
                            <br />
                            ・利用者が広告に対していつアクションを実行したか
                            <br />
                            ・広告の表示場所(Instagram上、あるいはFacebook上など)
                          </p>
                        </div>
                      </div>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">利用目的</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            サイト利用者が当社ウェブサイトのコンテンツをソーシャルネットワークサービスFacebook上で共有するための機能を提供する目的で利用する他、情報の送信先となるFacebookにおいて、サイト利用者に関する情報を分析し、Facebookサービス上に当該サイト利用者に適した当社の広告を配信するために利用する場合があります。また、Metaではこれらの情報を、Metaの製品の品質を向上させるために使用する他、当社以外から収集した利用者情報と突合・分析し、当社以外の事業者の広告の最適化にも利用する場合があります。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text__center">
                  <a
                    href="https://www.facebook.com/privacy/policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center">
                  <a
                    href="https://www.facebook.com/help/568137493302217"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center">
                  <a
                    href="https://www.facebook.com/privacy/policies/cookies"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="classification targeting">広告・マーケティング</span>
                </td>
                <td>Twitter, Inc.</td>
                <td>
                  <span className="service_names">Twitter</span>
                </td>
                <td>
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                    alt=""
                    height="30px"
                    width="30px"
                    onClick={openActive}
                    className="icon__button"
                  />
                  <div className="terms-modal">
                    <div className="terms-modal__content" onClick={actionNone}>
                      <span className="terms-modal__close" onClick={closePassive}>
                        ×
                      </span>
                      <h2 className="terms-modal__title">
                        <span className="terms-modal__company__names">Twitter, Inc.</span>
                        <span className="terms-modal__service_names">Twitter</span>
                      </h2>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            ・クライアントID
                            <br />
                            ・ウェブサイトへのアクセス状況
                            <br />
                            ・特定のウェブコンテンツやメールコンテンツの利用の有無
                          </p>
                        </div>
                      </div>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">利用目的</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            当社では、当社ウェブサイトでのソーシャルメディア機能の提供およびサイト利用者に適した広告の表示のために利用します。Twitterでは、以下の用途で利用される場合があります。
                            <br />
                            ・Twitterへのログイン状態の維持
                            <br />
                            ・Twitterサービスのさまざまな機能の提供
                            <br />
                            ・個人設定の保存や、個人の嗜好の尊重
                            <br />
                            ・表示するコンテンツのカスタマイズ
                            <br />
                            ・スパムや不正利用の防御
                            <br />
                            ・より関連性の高い広告の表示
                            <br />
                            ・サブスクリプション機能の提供や、特定のコンテンツの配布
                            <br />
                            ・Twitterサービスの利用状況や、改善の余地の把握
                            <br />
                            ・Twitterの広告やマーケティングの効果の測定
                            <br />
                            ・Twitterサービスのパフォーマンスの評価や、バグと品質に関するその他の問題の特定
                            <br />
                            ・オーディエンス規模の把握からTwitterルールの適用まで、Twitterのさまざまな業務の遂行に使用するデータの収集
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text__center">
                  <a href="https://twitter.com/ja/privacy" target="_blank" rel="noreferrer">
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center">
                  <a
                    href="https://help.twitter.com/ja/rules-and-policies/twitter-cookies-japan"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center">
                  <a
                    href="https://help.twitter.com/ja/rules-and-policies/twitter-cookies-japann"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="classification targeting">広告・マーケティング</span>
                </td>
                <td>スマートニュース株式会社</td>
                <td>
                  <span className="service_names">SmartNews Ads</span>
                </td>
                <td>
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                    alt=""
                    height="30px"
                    width="30px"
                    onClick={openActive}
                    className="icon__button"
                  />
                  <div className="terms-modal">
                    <div className="terms-modal__content" onClick={actionNone}>
                      <span className="terms-modal__close" onClick={closePassive}>
                        ×
                      </span>
                      <h2 className="terms-modal__title">
                        <span className="terms-modal__company__names">
                          {' '}
                          スマートニュース株式会社
                        </span>
                        <span className="terms-modal__service_names">SmartNews Ads</span>
                      </h2>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            ・ユーザの端末に関する情報
                            <br />
                            ・端末識別子
                            <br />
                            ・ユーザの位置情報
                            <br />
                            ・ユーザが利用するウェブサイトや広告
                            <br />
                            ・ユーザの閲覧パターン
                            <br />
                            ・ユーザが当社のパートナーのサービスをどのように利用しているかに関する情報を含む、本サービス外におけるユーザの行動や属性、興味関心に関する情報
                          </p>
                        </div>
                      </div>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">利用目的</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            SmartNews
                            Adsにおいて、サイト利用者に関する情報を分析し、インターネット上に当該サイト利用者に適した当社の広告を配信するために利用します。また、SmartNewsではこれらの情報を、当社以外から収集した利用者情報と突合・分析し、当社以外の事業者の広告の最適化にも利用する場合があります。{' '}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text__center">
                  <a
                    href="https://www.smartnews.com/policy/privacy/jp/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center">
                  <a
                    href="https://www.smartnews-ads.com/optout/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center"></td>
              </tr>
              <tr>
                <td>
                  <span className="classification targeting">広告・マーケティング</span>
                </td>
                <td>LINEヤフー株式会社</td>
                <td>
                  <span className="service_names">Yahoo!広告</span>
                </td>
                <td>
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                    alt=""
                    height="30px"
                    width="30px"
                    onClick={openActive}
                    className="icon__button"
                  />
                  <div className="terms-modal">
                    <div className="terms-modal__content" onClick={actionNone}>
                      <span className="terms-modal__close" onClick={closePassive}>
                        ×
                      </span>
                      <h2 className="terms-modal__title">
                        <span className="terms-modal__company__names">LINEヤフー株式会社</span>
                        <span className="terms-modal__service_names">Yahoo!広告</span>
                      </h2>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            ・お客様がお使いの端末情報
                            <br />
                            ・ブラウザを識別する広告識別子
                            <br />
                            ・Cookie IDや端末の種類
                            <br />
                            ・OS
                            <br />
                            ・言語
                            <br />
                            ・タイムゾーン設定
                            <br />
                            ・ブラウザの種類
                            <br />
                            ・アプリバージョン
                            <br />
                            ・ネットワーク情報（通信事業者の名称、IPアドレスなど）
                            <br />
                            ・位置情報
                            <br />
                            ・サービス利用状況（利用日時、滞在時間、閲覧・タップ・クリックしたコンテンツや広告など）
                            <br />
                            ・属性情報、購買履歴、視聴履歴、検索履歴および位置情報を含むお客様に関する行動履歴
                            <br />
                            ・ウェブサイトの訪問履歴（訪問日時、URLなどを含む）
                          </p>
                        </div>
                      </div>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">利用目的</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            当社では、LINEヤフー株式会社のYahoo!広告サービスを利用し、サイト利用者に関する情報の分析と、当社サイト上での利用者に適した広告の配信を行っています。また、当社以外のサイト上に、利用者に適した当社の広告を配信するために利用する場合があります。LINEヤフーでは、当該Yahoo!広告のマーケティングサービスの運営・改善のために、サイト利用者に関する情報を利用しています。なお、LINEヤフーでは、収集データ、その他LINEヤフーが保有する各種データとの掛け合わせや組み合わせ・必要な各種データの不可・補完等を行うことができ、例えば、LINEヤフーでは、サイト利用者の訪問履歴と広告識別子や内部識別子などを関連づけ、当社以外から収集した利用者情報と突合・分析し、当社以外の事業者の広告の最適化にも利用する場合があります。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text__center">
                  <a href="https://www.lycorp.co.jp/ja/company/privacypolicy/" target="_blank" rel="noreferrer">
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center">
                  <a
                    href="https://accounts.yahoo.co.jp/privacy/optout/ads"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center"></td>
              </tr>
              <tr>
                <td>
                  <span className="classification targeting">広告・マーケティング</span>
                </td>
                <td>株式会社インタースペース</td>
                <td>
                  <span className="service_names">Accesstrade</span>
                </td>
                <td>
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                    alt=""
                    height="30px"
                    width="30px"
                    onClick={openActive}
                    className="icon__button"
                  />
                  <div className="terms-modal">
                    <div className="terms-modal__content" onClick={actionNone}>
                      <span className="terms-modal__close" onClick={closePassive}>
                        ×
                      </span>
                      <h2 className="terms-modal__title">
                        <span className="terms-modal__company__names">
                          株式会社インタースペース
                        </span>
                        <span className="terms-modal__service_names">Accesstrade</span>
                      </h2>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            当社が、cookieを通じて取得する情報には、IPアドレス、ブラウザ、オペレーティングシステムとデバイスの特性、言語設定、モバイルデバイスまたは広告ID、参照URL、実行したアクションなど、コンピュータ、デバイス、およびブラウザに関する情報が含まれます。
                          </p>
                        </div>
                      </div>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">利用目的</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            ・本サービスの提供、運営および保守のため
                            <br />
                            ・本人確認、認証サービス、サポートのため
                            <br />
                            ・サイト審査、不正対策のため
                            <br />
                            ・個人関連情報などを利用した成果の計測・確認のため
                            <br />
                            ・広告報酬の請求ならびに支払のため
                            <br />
                            ・本サービスの広告プログラムの案内および広告主や第三者の商品やサービスの紹介、販売勧誘、発送、サービス提供のため
                            <br />
                            ・成果実績等のマーケティングデータの統計や分析のため
                            <br />
                            ・アフターサービス、問い合わせ、苦情対応のため
                            <br />
                            ・日本アフィリエイト・サービス協会への登録情報の届出のため
                            <br />
                            ・広告主、広告代理店および媒体者への提案のため
                            <br />
                            ・不正不当な目的で本サービスを利用した者に対処するため
                            <br />
                            ・その他本サービスにおいて個別に定める目的のため
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text__center">
                  <a
                    href="https://www.accesstrade.ne.jp/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center"></td>
                <td className="text__center">
                  <a href="https://www.accesstrade.ne.jp/cookie" target="_blank" rel="noreferrer">
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="classification targeting">広告・マーケティング</span>
                </td>
                <td>株式会社インティメート・マージャー</td>
                <td>
                  <span className="service_names">IM-DMP</span>
                </td>
                <td>
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                    alt=""
                    height="30px"
                    width="30px"
                    onClick={openActive}
                    className="icon__button"
                  />
                  <div className="terms-modal">
                    <div className="terms-modal__content" onClick={actionNone}>
                      <span className="terms-modal__close" onClick={closePassive}>
                        ×
                      </span>
                      <h2 className="terms-modal__title">
                        <span className="terms-modal__company__names">
                          株式会社インティメート・マージャー
                        </span>
                        <span className="terms-modal__service_names">IM-DMP</span>
                      </h2>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            ・クッキー
                            <br />
                            ・IPアドレス
                            <br />
                            ・ユーザーエージェント
                            <br />
                            ・スマートフォン端末用の広告掲載ID(IDFA、AAID)
                            <br />
                            ・ウェブサイトページのURLおよびリファラ
                            <br />
                            ・ウェブサイトへ訪問した日時
                            <br />
                            ・タイトル・メタキーワード
                            <br />
                            ・行動の履歴に関する情報（配信された広告へのクリックなど）
                          </p>
                        </div>
                      </div>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">利用目的</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            IM-DMPは、株式会社インティメート・マージャーが提供するDMP（データマネジメントプラットフォーム）で、オンラインやオフラインで収集した様々なデータを統合したうえで、データの正規化・分析・抽出を行い、利用企業のマーケティング活動に役立つインサイトを提供します。当社では、IM-DMPにより抽出されたオーディエンスデータ（ユーザープロファイルなど）を利用してセグメント化し、サイト利用者の興味関心に合わせた広告配信や、製品・サービスのご案内のために利用します。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text__center">
                  <a
                    href="https://corp.intimatemerger.com/privacypolicy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center">
                  <a
                    href="https://privacy.intimatemerger.com/choice/ja.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center"></td>
              </tr>
              <tr>
                <td>
                  <span className="classification targeting">広告・マーケティング</span>
                </td>
                <td>株式会社ファンコミュニケーションズ</td>
                <td>
                  <span className="service_names">a8.net</span>
                </td>
                <td>
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                    alt=""
                    height="30px"
                    width="30px"
                    onClick={openActive}
                    className="icon__button"
                  />
                  <div className="terms-modal">
                    <div className="terms-modal__content" onClick={actionNone}>
                      <span className="terms-modal__close" onClick={closePassive}>
                        ×
                      </span>
                      <h2 className="terms-modal__title">
                        <span className="terms-modal__company__names">
                          株式会社ファンコミュニケーションズ
                        </span>
                        <span className="terms-modal__service_names">a8.net</span>
                      </h2>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            ・ご利用者様識別情報（メディアID、パスワード等）
                            <br />
                            ・IPアドレス、OSバージョン情報、端末名
                            <br />
                            ・PUSH配信用ID
                          </p>
                        </div>
                      </div>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">利用目的</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            ・ご利用者様を識別するため
                            <br />
                            ・サービスの提供、統計、マーケティング分析、システムメンテナンス不具合対応を行うため
                            <br />
                            ・プッシュ通知配信をするため
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text__center">
                  <a href="https://www.a8.net/appPrivacy.html" target="_blank" rel="noreferrer">
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center"></td>
                <td className="text__center"></td>
              </tr>
              <tr>
                <td>
                  <span className="classification targeting">広告・マーケティング</span>
                </td>
                <td>株式会社フォーイット</td>
                <td>
                  <span className="service_names">afb</span>
                </td>
                <td>
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                    alt=""
                    height="30px"
                    width="30px"
                    onClick={openActive}
                    className="icon__button"
                  />
                  <div className="terms-modal">
                    <div className="terms-modal__content" onClick={actionNone}>
                      <span className="terms-modal__close" onClick={closePassive}>
                        ×
                      </span>
                      <h2 className="terms-modal__title">
                        <span className="terms-modal__company__names">株式会社フォーイット</span>
                        <span className="terms-modal__service_names">afb</span>
                      </h2>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            履歴情報とは、当社が運営するサイト等をご覧いただきましたお客様の行動履歴や個人属性に関する情報で、個人を特定するような情報は含まれないものをいいます。具体的には、ご利用されているドメイン名や
                            IPアドレスおよびそれに割り当てられた組織名、アクセスされた日時やファイル名、リンク元のページのURL、使用しているブラウザやOSの種類、携帯端末の個体識別情報、広告識別子、cookie等の情報などを指します。
                          </p>
                        </div>
                      </div>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">利用目的</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            当社では、当社のサービスをより便利にご利用いただくために、履歴情報を使用しています。収集したお客様の履歴情報は、ウェブサイトの利用状況に関する統計分析やサービス改善のための参考、システム障害や不正アクセスに対する調査・原因究明、最適なコンテンツ、広告（ターゲティング広告を含みます。）、各種サービスのご案内などをお客様に配信したりするために使用し、合法的かつ業務上正当に必要とされる期間に限り保持するものとします。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text__center">
                  <a
                    href="https://www.afi-b.com/profile/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center"></td>
                <td className="text__center"></td>
              </tr>
              <tr>
                <td>
                  <span className="classification targeting">広告・マーケティング</span>
                </td>
                <td>株式会社プラスアルファ・コンサルティング</td>
                <td>
                  <span className="service_names">Customer Rings</span>
                </td>
                <td>
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                    alt=""
                    height="30px"
                    width="30px"
                    onClick={openActive}
                    className="icon__button"
                  />
                  <div className="terms-modal">
                    <div className="terms-modal__content" onClick={actionNone}>
                      <span className="terms-modal__close" onClick={closePassive}>
                        ×
                      </span>
                      <h2 className="terms-modal__title">
                        <span className="terms-modal__company__names">
                          株式会社プラスアルファ・コンサルティング
                        </span>
                        <span className="terms-modal__service_names">Customer Rings</span>
                      </h2>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            ・IPアドレス
                            <br />
                            ・セッション
                            <br />
                            ・ブラウザタイプ
                          </p>
                        </div>
                      </div>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">利用目的</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            当社は、利用者から取得した情報を元にマーケティング調査・分析に活用をしています。また、株式会社プラスアルファ・コンサルティングでは、これらの情報を用いて利用ユーザーに必要なデータの提供を行っています。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text__center">
                  <a
                    href="https://www.pa-consul.co.jp/corporate/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center"></td>
                <td className="text__center"></td>
              </tr>
              <tr>
                <td>
                  <span className="classification targeting">広告・マーケティング</span>
                </td>
                <td>株式会社プレイド</td>
                <td>
                  <span className="service_names">Karte</span>
                </td>
                <td>
                  <img
                    src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                    alt=""
                    height="30px"
                    width="30px"
                    onClick={openActive}
                    className="icon__button"
                  />
                  <div className="terms-modal">
                    <div className="terms-modal__content" onClick={actionNone}>
                      <span className="terms-modal__close" onClick={closePassive}>
                        ×
                      </span>
                      <h2 className="terms-modal__title">
                        <span className="terms-modal__company__names">株式会社プレイド</span>
                        <span className="terms-modal__service_names">Karte</span>
                      </h2>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            ・クッキー
                            <br />
                            ・IPアドレス情報
                            <br />
                            ・ウェブサイト及びアプリの閲覧履歴
                            <br />
                            ・行動履歴及び購買履歴
                            <br />
                            ・端末 ID
                            <br />
                            ・ユーザーエージェント
                            <br />
                            ・リファラ
                            <br />
                            ・属性等の類推情報等(性別、年齢、閲覧履
                            歴、行動履歴及び購買履歴、モバイル端末向けの広告ID、位置情報等を
                            分析して、ユーザーの属性及び趣向等を類推した情報をいいます。)
                          </p>
                        </div>
                      </div>
                      <div className="terms-modal__user__information">
                        <h3 className="terms-modal__sub__title">利用目的</h3>
                        <div className="terms-modal__in__content">
                          <p>
                            KARTEは株式会社プレイドが提供する顧客体験（CX = Customer
                            Experience）をより良くするための機能がひとつに集約されたサービスで、リアルタイムで更新されるサイトやアプリに訪れるユーザーの行動を、一人ひとりのユーザー行動やセグメントごとに集団の傾向をさまざまな粒度で分析し、自社で保有する社内のあらゆるデータを統合することで、当社のより精緻なマーケティング活動に役立てます。
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text__center">
                  <a href="https://plaid.co.jp/privacy/" target="_blank" rel="noreferrer">
                    <img
                      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                      alt=""
                    />
                  </a>
                </td>
                <td className="text__center"></td>
                <td className="text__center"></td>
              </tr>
            </tbody>
          </table>
          <div className={cn(props.AllSpFlg ? null : 'md:hidden lg:hidden')}>
            <ul className="service__list">
              <li className="list__item">
                <dl className="service__list__table">
                  <dt>
                    <span className="py-6 inline-block">分類</span>
                  </dt>
                  <dd>
                    <span className="classification performance">アクセス解析</span>
                  </dd>
                  <dt>事業者</dt>
                  <dd>Google LLC</dd>
                  <dt>サービス名</dt>
                  <dd>
                    <span className="service_names">Googleアナリティクス</span>
                  </dd>
                  <dt>
                    送信される
                    <br />
                    利用者情報
                  </dt>
                  <dd>
                    <p>
                      ・クライアントID
                      <br />
                      ・IPアドレス
                      <br />
                      ・閲覧ページURL
                      <br />
                      ・リファラー
                      <br />
                      ・デバイスタイプ
                      <br />
                      ・オペレーティングシステム
                      <br />
                      ・ブラウザタイプ
                      <br />
                      ・利用言語
                      <br />
                      ・画像解像度
                    </p>
                  </dd>
                  <dt>利用目的</dt>
                  <dd>
                    <p>
                      当社は、ウェブサイトのパフォーマンスを分析し、改善に役立てるため、サイト利用者に関する情報を収集し、Googleへ送信します。Googleでは、送信された情報を分析、統計化し、ウェブサイトのパフォーマンスに関するレポートを当社に提供します。また、Google
                      では、これらの情報をGoogle独自のサービス・プロダクトの改善を目的として利用する場合があります。
                    </p>
                  </dd>
                </dl>
                <div className="service__link__list">
                  <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
                    プライバシーポリシー
                  </a>
                  <a
                    href="https://tools.google.com/dlpage/gaoptout/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    オプトアウトページ
                  </a>
                  <a
                    href="https://policies.google.com/technologies/cookies?hl=ja"
                    target="_blank"
                    rel="noreferrer"
                  >
                    クッキーポリシー
                  </a>
                </div>
              </li>
              <li className="list__item">
                <dl className="service__list__table">
                  <dt>
                    <span className="py-6 inline-block">分類</span>
                  </dt>
                  <dd>
                    <span className="classification targeting">広告・マーケティング</span>
                  </dd>
                  <dt>事業者</dt>
                  <dd>Google LLC</dd>
                  <dt>サービス名</dt>
                  <dd>
                    <span className="service_names">コンバージョン測定</span>
                  </dd>
                  <dt>
                    送信される
                    <br />
                    利用者情報
                  </dt>
                  <dd>
                    <p>
                      ・クライアントID
                      <br />
                      ・広告のクリック情報
                    </p>
                  </dd>
                  <dt>利用目的</dt>
                  <dd>
                    <p>
                      Googleにより、サイト利用者が当社の広告をクリックした情報が一意のクライアントIDと共にクッキーに保管されます。当社ウェブサイトでコンバージョンが発生すると、当社ウェブサイトに挿入したコンバージョン
                      トラッキング
                      タグによってクッキー情報が読み取られ、コンバージョン情報とともにGoogleに送信されます。当社では、Googleから提供されるレポートを元に広告の効果測定を行います。
                    </p>
                  </dd>
                </dl>
                <div className="service__link__list">
                  <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
                    プライバシーポリシー
                  </a>
                  <a
                    href="https://tools.google.com/dlpage/gaoptout/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    オプトアウトページ
                  </a>
                  <a
                    href="https://policies.google.com/technologies/cookies?hl=ja"
                    target="_blank"
                    rel="noreferrer"
                  >
                    クッキーポリシー
                  </a>
                </div>
              </li>
              <li className="list__item">
                <dl className="service__list__table">
                  <dt>
                    <span className="py-6 inline-block">分類</span>
                  </dt>
                  <dd>
                    <span className="classification targeting">広告・マーケティング</span>
                  </dd>
                  <dt>事業者</dt>
                  <dd>LINEヤフー株式会社</dd>
                  <dt>サービス名</dt>
                  <dd>
                    <span className="service_names">LINE広告</span>
                  </dd>
                  <dt>
                    送信される
                    <br />
                    利用者情報
                  </dt>
                  <dd>
                    <p>
                      ・お客様がお使いの端末情報
                      <br />
                      ・ブラウザを識別する広告識別子
                      <br />
                      ・Cookie IDや端末の種類
                      <br />
                      ・OS
                      <br />
                      ・言語
                      <br />
                      ・タイムゾーン設定
                      <br />
                      ・ブラウザの種類
                      <br />
                      ・アプリバージョン
                      <br />
                      ・ネットワーク情報（通信事業者の名称、IPアドレスなど）
                      <br />
                      ・位置情報
                      <br />
                      ・サービス利用状況（利用日時、滞在時間、閲覧・タップ・クリックしたコンテンツや広告など）
                      <br />
                      ・属性情報、購買履歴、視聴履歴、検索履歴および位置情報を含むお客様に関する行動履歴
                      <br />
                      ・ウェブサイトの訪問履歴（訪問日時、URLなどを含む）
                    </p>
                  </dd>
                  <dt>利用目的</dt>
                  <dd>
                    <p>
                      当社では、LINEヤフー株式会社のLINE広告サービスを利用し、サイト利用者に関する情報の分析とLINEサービス上における当該サイト利用者に適した広告の配信を行っています。LINEヤフーでは、当該LINE広告のマーケティングサービスの運営・改善のために、サイト利用者に関する情報を利用しています。なお、LINEヤフーでは、収集データ、その他LINEヤフーが保有する各種データとの掛け合わせや組み合わせ・必要な各種データの不可・補完等を行うことができ、例えば、LINEヤフーでは、サイト利用者の訪問履歴と広告識別子や内部識別子などを関連づけ、当社以外から収集した利用者情報と突合・分析し、当社以外の事業者の広告の最適化にも利用する場合があります。また、LINEは、「LINEで送る」や「いいね」などのプラグイン機能が当社ウェブサイトに設置されている場合、ウェブサイトにおけるプラグインからのログイン記録の他、ブラウザ上でLINEにログインした状態で訪問したプラグイン機能が設置されたウェブサイトのURL情報をLINEアカウントに関連づけて取得する場合があります。このように取得したデータは、LINEにおいて、LINEサービスがどのように利用されているかを把握し、その維持・改善、セキュリティ・不正利用防止の目的などに利用されます。なお、LINEからログアウトした状態では、これらの情報は個人を識別しない形でセキュリティ・不正利用防止の目的にのみ利用されます。
                    </p>
                  </dd>
                </dl>
                <div className="service__link__list">
                  <a href="https://line.me/ja/terms/policy/" target="_blank" rel="noreferrer">
                    プライバシーポリシー
                  </a>
                </div>
              </li>
              <li className="list__item">
                <dl className="service__list__table">
                  <dt>
                    <span className="py-6 inline-block">分類</span>
                  </dt>
                  <dd>
                    <span className="classification targeting">広告・マーケティング</span>
                  </dd>
                  <dt>事業者</dt>
                  <dd>Meta Platforms, Inc.</dd>
                  <dt>サービス名</dt>
                  <dd>
                    <span className="service_names">Facebook広告</span>
                  </dd>
                  <dt>
                    送信される
                    <br />
                    利用者情報
                  </dt>
                  <dd>
                    <p>
                      ・ユーザーID
                      <br />
                      ・アクセスしているサイトURL
                      <br />
                      ・アクセスした日付と時刻
                      <br />
                      ・ユーザーが利用しているブラウザーに関連した情報
                      <br />
                      ・利用者がアクションを実行した広告
                      <br />
                      ・利用者が広告に対していつアクションを実行したか
                      <br />
                      ・広告の表示場所(Instagram上、あるいはFacebook上など)
                    </p>
                  </dd>
                  <dt>利用目的</dt>
                  <dd>
                    <p>
                      サイト利用者が当社ウェブサイトのコンテンツをソーシャルネットワークサービスFacebook上で共有するための機能を提供する目的で利用する他、情報の送信先となるFacebookにおいて、サイト利用者に関する情報を分析し、Facebookサービス上に当該サイト利用者に適した当社の広告を配信するために利用する場合があります。また、Metaではこれらの情報を、Metaの製品の品質を向上させるために使用する他、当社以外から収集した利用者情報と突合・分析し、当社以外の事業者の広告の最適化にも利用する場合があります。
                    </p>
                  </dd>
                </dl>
                <div className="service__link__list">
                  <a
                    href="https://www.facebook.com/privacy/policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プライバシーポリシー
                  </a>
                  <a
                    href="https://www.facebook.com/help/568137493302217"
                    target="_blank"
                    rel="noreferrer"
                  >
                    オプトアウトページ
                  </a>
                  <a
                    href="https://www.facebook.com/privacy/policies/cookies"
                    target="_blank"
                    rel="noreferrer"
                  >
                    クッキーポリシー
                  </a>
                </div>
              </li>
              <li className="list__item">
                <dl className="service__list__table">
                  <dt>
                    <span className="py-6 inline-block">分類</span>
                  </dt>
                  <dd>
                    <span className="classification targeting">広告・マーケティング</span>
                  </dd>
                  <dt>事業者</dt>
                  <dd>Twitter, Inc.</dd>
                  <dt>サービス名</dt>
                  <dd>
                    <span className="service_names">Twitter</span>
                  </dd>
                  <dt>
                    送信される
                    <br />
                    利用者情報
                  </dt>
                  <dd>
                    <p>
                      ・クライアントID
                      <br />
                      ・ウェブサイトへのアクセス状況
                      <br />
                      ・特定のウェブコンテンツやメールコンテンツの利用の有無
                    </p>
                  </dd>
                  <dt>利用目的</dt>
                  <dd>
                    <p>
                      当社では、当社ウェブサイトでのソーシャルメディア機能の提供およびサイト利用者に適した広告の表示のために利用します。Twitterでは、以下の用途で利用される場合があります。
                      <br />
                      ・Twitterへのログイン状態の維持
                      <br />
                      ・Twitterサービスのさまざまな機能の提供
                      <br />
                      ・個人設定の保存や、個人の嗜好の尊重
                      <br />
                      ・表示するコンテンツのカスタマイズ
                      <br />
                      ・スパムや不正利用の防御
                      <br />
                      ・より関連性の高い広告の表示
                      <br />
                      ・サブスクリプション機能の提供や、特定のコンテンツの配布
                      <br />
                      ・Twitterサービスの利用状況や、改善の余地の把握
                      <br />
                      ・Twitterの広告やマーケティングの効果の測定
                      <br />
                      ・Twitterサービスのパフォーマンスの評価や、バグと品質に関するその他の問題の特定
                      <br />
                      ・オーディエンス規模の把握からTwitterルールの適用まで、Twitterのさまざまな業務の遂行に使用するデータの収集
                    </p>
                  </dd>
                </dl>
                <div className="service__link__list">
                  <a href="https://twitter.com/ja/privacy" target="_blank" rel="noreferrer">
                    プライバシーポリシー
                  </a>
                  <a
                    href="https://help.twitter.com/ja/rules-and-policies/twitter-cookies-japan"
                    target="_blank"
                    rel="noreferrer"
                  >
                    オプトアウトページ
                  </a>
                  <a
                    href="https://help.twitter.com/ja/rules-and-policies/twitter-cookies-japann"
                    target="_blank"
                    rel="noreferrer"
                  >
                    クッキーポリシー
                  </a>
                </div>
              </li>
              <li className="list__item">
                <dl className="service__list__table">
                  <dt>
                    <span className="py-6 inline-block">分類</span>
                  </dt>
                  <dd>
                    <span className="classification targeting">広告・マーケティング</span>
                  </dd>
                  <dt>事業者</dt>
                  <dd>スマートニュース株式会社</dd>
                  <dt>サービス名</dt>
                  <dd>
                    <span className="service_names">SmartNews Ads</span>
                  </dd>
                  <dt>
                    送信される
                    <br />
                    利用者情報
                  </dt>
                  <dd>
                    <p>
                      ・ユーザの端末に関する情報
                      <br />
                      ・端末識別子
                      <br />
                      ・ユーザの位置情報
                      <br />
                      ・ユーザが利用するウェブサイトや広告
                      <br />
                      ・ユーザの閲覧パターン
                      <br />
                      ・ユーザが当社のパートナーのサービスをどのように利用しているかに関する情報を含む、本サービス外におけるユーザの行動や属性、興味関心に関する情報
                    </p>
                  </dd>
                  <dt>利用目的</dt>
                  <dd>
                    <p>
                      SmartNews
                      Adsにおいて、サイト利用者に関する情報を分析し、インターネット上に当該サイト利用者に適した当社の広告を配信するために利用します。また、SmartNewsではこれらの情報を、当社以外から収集した利用者情報と突合・分析し、当社以外の事業者の広告の最適化にも利用する場合があります。{' '}
                    </p>
                  </dd>
                </dl>
                <div className="service__link__list">
                  <a
                    href="https://www.smartnews.com/policy/privacy/jp/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プライバシーポリシー
                  </a>
                  <a
                    href="https://www.smartnews-ads.com/optout/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    オプトアウトページ
                  </a>
                </div>
              </li>
              <li className="list__item">
                <dl className="service__list__table">
                  <dt>
                    <span className="py-6 inline-block">分類</span>
                  </dt>
                  <dd>
                    <span className="classification targeting">広告・マーケティング</span>
                  </dd>
                  <dt>事業者</dt>
                  <dd>LINEヤフー株式会社</dd>
                  <dt>サービス名</dt>
                  <dd>
                    <span className="service_names">Yahoo!広告</span>
                  </dd>
                  <dt>
                    送信される
                    <br />
                    利用者情報
                  </dt>
                  <dd>
                    <p>
                      ・お客様がお使いの端末情報
                      <br />
                      ・ブラウザを識別する広告識別子
                      <br />
                      ・Cookie IDや端末の種類
                      <br />
                      ・OS
                      <br />
                      ・言語
                      <br />
                      ・タイムゾーン設定
                      <br />
                      ・ブラウザの種類
                      <br />
                      ・アプリバージョン
                      <br />
                      ・ネットワーク情報（通信事業者の名称、IPアドレスなど）
                      <br />
                      ・位置情報
                      <br />
                      ・サービス利用状況（利用日時、滞在時間、閲覧・タップ・クリックしたコンテンツや広告など）
                      <br />
                      ・属性情報、購買履歴、視聴履歴、検索履歴および位置情報を含むお客様に関する行動履歴
                      <br />
                      ・ウェブサイトの訪問履歴（訪問日時、URLなどを含む）
                    </p>
                  </dd>
                  <dt>利用目的</dt>
                  <dd>
                    <p>
                      当社では、LINEヤフー株式会社のYahoo!広告サービスを利用し、サイト利用者に関する情報の分析と、当社サイト上での利用者に適した広告の配信を行っています。また、当社以外のサイト上に、利用者に適した当社の広告を配信するために利用する場合があります。LINEヤフーでは、当該Yahoo!広告のマーケティングサービスの運営・改善のために、サイト利用者に関する情報を利用しています。なお、LINEヤフーでは、収集データ、その他LINEヤフーが保有する各種データとの掛け合わせや組み合わせ・必要な各種データの不可・補完等を行うことができ、例えば、LINEヤフーでは、サイト利用者の訪問履歴と広告識別子や内部識別子などを関連づけ、当社以外から収集した利用者情報と突合・分析し、当社以外の事業者の広告の最適化にも利用する場合があります。
                    </p>
                  </dd>
                </dl>
                <div className="service__link__list">
                  <a href="https://www.lycorp.co.jp/ja/company/privacypolicy/" target="_blank" rel="noreferrer">
                    プライバシーポリシー
                  </a>
                  <a
                    href="https://accounts.yahoo.co.jp/privacy/optout/ads"
                    target="_blank"
                    rel="noreferrer"
                  >
                    オプトアウトページ
                  </a>
                </div>
              </li>
              <li className="list__item">
                <dl className="service__list__table">
                  <dt>
                    <span className="py-6 inline-block">分類</span>
                  </dt>
                  <dd>
                    <span className="classification targeting">広告・マーケティング</span>
                  </dd>
                  <dt>事業者</dt>
                  <dd>株式会社インタースペース</dd>
                  <dt>サービス名</dt>
                  <dd>
                    <span className="service_names">Accesstrade</span>
                  </dd>
                  <dt>
                    送信される
                    <br />
                    利用者情報
                  </dt>
                  <dd>
                    <p>
                      当社が、cookieを通じて取得する情報には、IPアドレス、ブラウザ、オペレーティングシステムとデバイスの特性、言語設定、モバイルデバイスまたは広告ID、参照URL、実行したアクションなど、コンピュータ、デバイス、およびブラウザに関する情報が含まれます。
                    </p>
                  </dd>
                  <dt>利用目的</dt>
                  <dd>
                    <p>
                      ・本サービスの提供、運営および保守のため
                      <br />
                      ・本人確認、認証サービス、サポートのため
                      <br />
                      ・サイト審査、不正対策のため
                      <br />
                      ・個人関連情報などを利用した成果の計測・確認のため
                      <br />
                      ・広告報酬の請求ならびに支払のため
                      <br />
                      ・本サービスの広告プログラムの案内および広告主や第三者の商品やサービスの紹介、販売勧誘、発送、サービス提供のため
                      <br />
                      ・成果実績等のマーケティングデータの統計や分析のため
                      <br />
                      ・アフターサービス、問い合わせ、苦情対応のため
                      <br />
                      ・日本アフィリエイト・サービス協会への登録情報の届出のため
                      <br />
                      ・広告主、広告代理店および媒体者への提案のため
                      <br />
                      ・不正不当な目的で本サービスを利用した者に対処するため
                      <br />
                      ・その他本サービスにおいて個別に定める目的のため
                    </p>
                  </dd>
                </dl>
                <div className="service__link__list">
                  <a
                    href="https://www.accesstrade.ne.jp/privacy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プライバシーポリシー
                  </a>
                  <a href="https://www.accesstrade.ne.jp/cookie" target="_blank" rel="noreferrer">
                    クッキーポリシー
                  </a>
                </div>
              </li>
              <li className="list__item">
                <dl className="service__list__table">
                  <dt>
                    <span className="py-6 inline-block">分類</span>
                  </dt>
                  <dd>
                    <span className="classification targeting">広告・マーケティング</span>
                  </dd>
                  <dt>事業者</dt>
                  <dd>株式会社インティメート・マージャー</dd>
                  <dt>サービス名</dt>
                  <dd>
                    <span className="service_names">IM-DMP</span>
                  </dd>
                  <dt>
                    送信される
                    <br />
                    利用者情報
                  </dt>
                  <dd>
                    <p>
                      ・クッキー
                      <br />
                      ・IPアドレス
                      <br />
                      ・ユーザーエージェント
                      <br />
                      ・スマートフォン端末用の広告掲載ID(IDFA、AAID)
                      <br />
                      ・ウェブサイトページのURLおよびリファラ
                      <br />
                      ・ウェブサイトへ訪問した日時
                      <br />
                      ・タイトル・メタキーワード
                      <br />
                      ・行動の履歴に関する情報（配信された広告へのクリックなど）
                    </p>
                  </dd>
                  <dt>利用目的</dt>
                  <dd>
                    <p>
                      IM-DMPは、株式会社インティメート・マージャーが提供するDMP（データマネジメントプラットフォーム）で、オンラインやオフラインで収集した様々なデータを統合したうえで、データの正規化・分析・抽出を行い、利用企業のマーケティング活動に役立つインサイトを提供します。当社では、IM-DMPにより抽出されたオーディエンスデータ（ユーザープロファイルなど）を利用してセグメント化し、サイト利用者の興味関心に合わせた広告配信や、製品・サービスのご案内のために利用します。
                    </p>
                  </dd>
                </dl>
                <div className="service__link__list">
                  <a
                    href="https://corp.intimatemerger.com/privacypolicy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プライバシーポリシー
                  </a>
                  <a
                    href="https://privacy.intimatemerger.com/choice/ja.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    オプトアウトページ
                  </a>
                </div>
              </li>
              <li className="list__item">
                <dl className="service__list__table">
                  <dt>
                    <span className="py-6 inline-block">分類</span>
                  </dt>
                  <dd>
                    <span className="classification targeting">広告・マーケティング</span>
                  </dd>
                  <dt>事業者</dt>
                  <dd>株式会社ファンコミュニケーションズ</dd>
                  <dt>サービス名</dt>
                  <dd>
                    <span className="service_names">a8.net</span>
                  </dd>
                  <dt>
                    送信される
                    <br />
                    利用者情報
                  </dt>
                  <dd>
                    <p>
                      ・ご利用者様識別情報（メディアID、パスワード等）
                      <br />
                      ・IPアドレス、OSバージョン情報、端末名
                      <br />
                      ・PUSH配信用ID
                    </p>
                  </dd>
                  <dt>利用目的</dt>
                  <dd>
                    <p>
                      ・ご利用者様を識別するため
                      <br />
                      ・サービスの提供、統計、マーケティング分析、システムメンテナンス不具合対応を行うため
                      <br />
                      ・プッシュ通知配信をするため
                    </p>
                  </dd>
                </dl>
                <div className="service__link__list">
                  <a href="https://www.a8.net/appPrivacy.html" target="_blank" rel="noreferrer">
                    プライバシーポリシー
                  </a>
                </div>
              </li>
              <li className="list__item">
                <dl className="service__list__table">
                  <dt>
                    <span className="py-6 inline-block">分類</span>
                  </dt>
                  <dd>
                    <span className="classification targeting">広告・マーケティング</span>
                  </dd>
                  <dt>事業者</dt>
                  <dd>株式会社フォーイット</dd>
                  <dt>サービス名</dt>
                  <dd>
                    <span className="service_names">afb</span>
                  </dd>
                  <dt>
                    送信される
                    <br />
                    利用者情報
                  </dt>
                  <dd>
                    <p>
                      履歴情報とは、当社が運営するサイト等をご覧いただきましたお客様の行動履歴や個人属性に関する情報で、個人を特定するような情報は含まれないものをいいます。具体的には、ご利用されているドメイン名や
                      IPアドレスおよびそれに割り当てられた組織名、アクセスされた日時やファイル名、リンク元のページのURL、使用しているブラウザやOSの種類、携帯端末の個体識別情報、広告識別子、cookie等の情報などを指します。{' '}
                    </p>
                  </dd>
                  <dt>利用目的</dt>
                  <dd>
                    <p>
                      当社では、当社のサービスをより便利にご利用いただくために、履歴情報を使用しています。収集したお客様の履歴情報は、ウェブサイトの利用状況に関する統計分析やサービス改善のための参考、システム障害や不正アクセスに対する調査・原因究明、最適なコンテンツ、広告（ターゲティング広告を含みます。）、各種サービスのご案内などをお客様に配信したりするために使用し、合法的かつ業務上正当に必要とされる期間に限り保持するものとします。{' '}
                    </p>
                  </dd>
                </dl>
                <div className="service__link__list">
                  <a
                    href="https://www.afi-b.com/profile/privacy-policy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プライバシーポリシー
                  </a>
                </div>
              </li>
              <li className="list__item">
                <dl className="service__list__table">
                  <dt>
                    <span className="py-6 inline-block">分類</span>
                  </dt>
                  <dd>
                    <span className="classification targeting">広告・マーケティング</span>
                  </dd>
                  <dt>事業者</dt>
                  <dd>株式会社プラスアルファ・コンサルティング</dd>
                  <dt>サービス名</dt>
                  <dd>
                    <span className="service_names">Customer Rings</span>
                  </dd>
                  <dt>
                    送信される
                    <br />
                    利用者情報
                  </dt>
                  <dd>
                    <p>
                      ・IPアドレス
                      <br />
                      ・セッション
                      <br />
                      ・ブラウザタイプ
                    </p>
                  </dd>
                  <dt>利用目的</dt>
                  <dd>
                    <p>
                      当社は、利用者から取得した情報を元にマーケティング調査・分析に活用をしています。また、株式会社プラスアルファ・コンサルティングでは、これらの情報を用いて利用ユーザーに必要なデータの提供を行っています。
                    </p>
                  </dd>
                </dl>
                <div className="service__link__list">
                  <a
                    href="https://www.pa-consul.co.jp/corporate/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    プライバシーポリシー
                  </a>
                </div>
              </li>
              <li className="list__item">
                <dl className="service__list__table">
                  <dt>
                    <span className="py-6 inline-block">分類</span>
                  </dt>
                  <dd>
                    <span className="classification targeting">広告・マーケティング</span>
                  </dd>
                  <dt>事業者</dt>
                  <dd>株式会社プレイド</dd>
                  <dt>サービス名</dt>
                  <dd>
                    <span className="service_names">Karte</span>
                  </dd>
                  <dt>
                    送信される
                    <br />
                    利用者情報
                  </dt>
                  <dd>
                    <p>
                      ・クッキー
                      <br />
                      ・IPアドレス情報
                      <br />
                      ・ウェブサイト及びアプリの閲覧履歴
                      <br />
                      ・行動履歴及び購買履歴
                      <br />
                      ・端末 ID
                      <br />
                      ・ユーザーエージェント
                      <br />
                      ・リファラ
                      <br />
                      ・属性等の類推情報等(性別、年齢、閲覧履
                      歴、行動履歴及び購買履歴、モバイル端末向けの広告ID、位置情報等を
                      分析して、ユーザーの属性及び趣向等を類推した情報をいいます。)
                    </p>
                  </dd>
                  <dt>利用目的</dt>
                  <dd>
                    <p>
                      KARTEは株式会社プレイドが提供する顧客体験（CX = Customer
                      Experience）をより良くするための機能がひとつに集約されたサービスで、リアルタイムで更新されるサイトやアプリに訪れるユーザーの行動を、一人ひとりのユーザー行動やセグメントごとに集団の傾向をさまざまな粒度で分析し、自社で保有する社内のあらゆるデータを統合することで、当社のより精緻なマーケティング活動に役立てます。
                    </p>
                  </dd>
                </dl>
                <div className="service__link__list">
                  <a href="https://plaid.co.jp/privacy/" target="_blank" rel="noreferrer">
                    プライバシーポリシー
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </ItemsWrapper>
      <ItemsWrapper border={false} title="インフォマティブデータのリセット">
        <p className="mbx-typography--body_1 mt-16">
          利用者は、次のいずれかの方法により、当社が取得したインフォマティブデータをリセット（オプトアウト）することができます。
        </p>
        <ListItem type="Disc" className="mt-16" bold>
          webサイトでのオプトアウトについて
        </ListItem>
        <p className="mbx-typography--body_2 mt-4 pl-24">
          外部事業社へのインフォマティブデータの送信についてオプトアウトを希望する場合、各事業者が提供するウェブサイトの指示に従い、オプトアウトを行ってください。外部事業社のオプトアウトページは「インフォマティブデータの外部送信」をご確認ください。
        </p>
        <ListItem type="Disc" className="mt-16" bold>
          Cookieのリセット
        </ListItem>
        <p className="mbx-typography--body_2 mt-4 pl-24">
          ブラウザのCookieを削除または拒否することにより、それまで当社が取得したインフォマティブデータとの関連付けが削除され、インフォマティブデータがリセットされます。
          <br />
          Cookieを削除した後、本サイトを利用する場合、再びインフォマティブデータの取得が開始されることになります。
          <br />
          なお、Cookieを削除または拒否を行った場合、本サイトを利用するにあたり、制約を受ける場合があります。
        </p>
        <ListItem type="Disc" className="mt-16" bold>
          スマートフォン上の広告識別子のリセット
        </ListItem>
        <p className="mbx-typography--body_2 mt-4 pl-24">
          スマートフォン上の広告識別子を、次のOS提供会社のウェブサイトの指示に従いリセットすることにより、それまで端末上で取得したインフォマティブデータとの関連付けが削除され、インフォマティブデータがリセットされます。
          <br />
          なお、広告識別子を削除した後、本サイトを利用する場合、再びインフォマティブデータの取得が開始されることになります。
        </p>
        <p className="mbx-typography--body_2 mt-4 pl-24">
          <span className="font-bold">OS提供会社</span>
          <br />
          <span>Apple Inc.</span>
          <br />
          <span>https://support.apple.com/ja-jp/HT202074</span>
          <br />
          <span>グーグル合同会社</span>
          <br />
          <span>https://support.google.com/googleplay/answer/3405269?hl=ja</span>
        </p>
        <ListItem type="Disc" className="mt-16" bold>
          iOS、iPadOS版アプリのIDFA（iOS、iPadOSの広告識別子）のオプトアウトについて
        </ListItem>
        <p className="mbx-typography--body_2 mt-4 pl-24">
          iOS、iPadOS版アプリでIDFAのトラッキングについて、オプトアウトを希望する場合、以下を確認してウェブサイトの指示に従い、オプトアウトを行ってください。
          <br />
          https://support.apple.com/ja-jp/HT212025
        </p>
      </ItemsWrapper>
    </div>
  );
}

export default TermsText;
