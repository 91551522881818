import React, { useEffect, useState } from 'react';
import { FieldArrayWithId, FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { TableBodyUserTagList, TableContainer, TableHeader } from '@/componentsAdmin/Parts/Table';
import { HeaderTitle } from '@/componentsAdmin/Parts/Title/HeaderTitle';
import { AdminUserTagAddComponent, AdminUserTagDeleteComponent } from '@/componentsAdmin/UserTag';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { useUserTag } from '@/hooksAdmin/useUserTag';
import { UserTag } from '@/utils/api-client/api';
import { moveArray } from '@/utils/utils';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

const columns = [
  {
    title: 'タグ名',
    className: 'w-233',
  },
  {
    title: '検索結果URL',
  },
  {
    title: '',
    className: 'w-104',
  },
];

type TFormValues = {
  listData: Array<UserTag>;
};

/**
 * タグ管理 /admin/user_tags
 * @ui https://www.figma.com/file/U3CKCcmEc7eIjxkbYoCZx7/UI?type=design&node-id=11781-162238&mode=design&t=AFDRFHeFDLzsWY90-4
 * @author s2 masa
 */
export const AdminUserTagsIndex = (): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const mq = useMBXMediaQuery();
  const [userDeleteState, setUserDeleteState] = useState({
    id: '',
    showModal: false,
  });
  const { userTags, getUserTags, patchUserTags } = useUserTag();

  const methods = useForm<TFormValues>();
  const { control } = methods;
  const { fields, move } = useFieldArray({
    control,
    name: 'listData',
  });

  // ====================================================================================================
  //
  // methods
  //
  // ====================================================================================================
  /**
   * 削除処理
   */
  const onDelete = (id: string) => {
    setUserDeleteState({ id, showModal: true });
    return false;
  };

  const onSort = (result: FieldArrayWithId<TFormValues, 'listData', 'id'>[]) => {
    // idのみの配列に変換
    const request = {
      items: result.map((v) => v.id),
    };
    patchUserTags(request);
  };

  /**
   * DnD 並び替え
   */
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  /**
   * DnD 並び替え
   */
  const touchSensors = useSensors(
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  /**
   * DnD 並び替え
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragEnd = ({ active, over }: any) => {
    if (active.id !== over.id) {
      const oldIndex = fields.map((field) => field.id).indexOf(active.id);
      const newIndex = fields.map((field) => field.id).indexOf(over.id);
      move(oldIndex, newIndex);
      onSort(moveArray(fields, oldIndex, newIndex));
    }
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    if (userTags) methods.setValue('listData', userTags);
  }, [userTags]);

  useEffect(() => {
    // 一覧取得
    getUserTags();
  }, []);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <AdminPage header={<HeaderTitle title="タグ管理" />}>
      <>
        <header className="mt-48 text-center mb-48">
          <h1 className="text-body_1">
            タグ名と検索結果時のURLを登録してください。
            <br />
            また、表示トグルで企業側への表示/非表示を選択してください。
          </h1>
        </header>
        <AdminUserTagDeleteComponent
          setUserDeleteState={setUserDeleteState}
          showModal={userDeleteState.showModal}
          getUserTags={getUserTags}
          id={userDeleteState.id}
        />
        <FormProvider {...methods}>
          <form className="pb-80">
            <TableContainer className="w-full">
              <TableHeader columns={columns}></TableHeader>
              <tbody>
                <AdminUserTagAddComponent getUserTags={getUserTags} />

                <DndContext
                  sensors={mq.sm ? touchSensors : sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  autoScroll={false}
                >
                  <SortableContext items={fields} strategy={verticalListSortingStrategy}>
                    {fields.map((data, index) => {
                      return (
                        <TableBodyUserTagList
                          key={index}
                          data={data}
                          onDelete={onDelete}
                        ></TableBodyUserTagList>
                      );
                    })}
                  </SortableContext>
                </DndContext>
              </tbody>
            </TableContainer>
          </form>
        </FormProvider>
      </>
    </AdminPage>
  );
};
