import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { FormContainerTextArea } from '@/componentsDirect/Parts/Form/Container/TextArea';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';
import { yupResolver } from '@hookform/resolvers/yup';

type TInput = {
  reaction: string;
  reaction_text: string;
};

type Props = {
  onSend: (value: string, text: string) => void;
  onClose: React.MouseEventHandler<HTMLDivElement>;
};

export const ReactionForm = (props: Props): React.ReactElement => {
  const schema = yup.object().shape({
    reaction: YUP_VALIDATION.required,
  });
  const methods = useForm<TInput>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      reaction: '',
      reaction_text: '',
    },
  });

  const { handleSubmit } = methods;
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit((data) => props.onSend(data.reaction, data.reaction_text))}>
        <div className="w-200 mt-18">
          <FormContainerDropdown
            name="reaction"
            className="select"
            placeholder="希望するアクションを選択"
            selectList={DIRECT_FORM_OPTIONS.reactionOptions}
          />
        </div>
        <FormContainerTextArea
          name="reaction_text"
          maxLength={300}
          rows={2}
          className="mt-16 mkt_mask_items"
          placeholder="例）経験豊富な候補者です。オファーし、実際に会って話を聞いたみたいと思いますが、いかがでしょうか？"
        />
        <div className="flex justify-between mt-24">
          <div className="w-224">
            <div className="w-full base-button" onClick={props.onClose}>
              <div className="button size-m theme-secondary flex items-center">
                <span className="font-bold label">キャンセル</span>
              </div>
            </div>
          </div>
          <div className="w-224">
            <BaseButton theme="primary" size="m" className="w-full">
              この内容で投稿
            </BaseButton>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
