import React, { useEffect, useMemo, useState, VFC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
    FormContainerDropdown, FormContainerDropdownMultiple, FormContainerTextArea,
    FormContainerTextfield, FormLabel, FormLayoutUnitDivider
} from '@/components/common/Form/';
import { DATE_MONTH, DATE_YEAR } from '@/definition/DATE_OPTIONS';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { PLACEHOLDER } from '@/definition/PLACEHOLDER';
import { State } from '@/redux/state';
import {
    Portfolio, PortfolioItem, PortfolioPage, PortfolioPagePPageLayoutEnum, Question
} from '@/utils/api-client/index';
import { delay } from '@/utils/delay';

export const PortfolioEditSummary: VFC<{
  portfolio?: Portfolio;
  itemId: number;
  setBeforeUnload(flag: boolean): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setItem(param: any): void;
  onUpdateQuestion(qaId: string, answer: string[]): void;
}> = ({ portfolio, itemId, setItem, onUpdateQuestion, setBeforeUnload }) => {
  const methods = useForm();
  const user = useSelector((state: State) => state.user);
  const [init, setInit] = useState<boolean>(true);

  const item = useMemo<PortfolioItem | undefined>(() => {
    if (!portfolio?.items) return undefined;
    return Array.from(portfolio?.items).find((item) => item.i_id === itemId);
  }, [portfolio]);

  //Form to API
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const subscription = methods.watch((value, { name, type, value: v }) => {
      //初期のsetValueが終わってからじゃないとwatchでの更新は受け付けない
      if (init) {
        return;
      }

      switch (name) {
        case 'i_tags':
          setItem({ i_tags: [...value.i_tags, value.i_tags_extra] });
          break;
        case 'i_tags_extra':
          if (value.i_tags_extra === null) {
            setItem({ i_tags: [...value.i_tags, value.i_tags_extra] });
          }
          break;
        case 'i_url':
          if (value.i_url === null) {
            setItem({ i_url: value.i_url });
          }
          break;
        case 'i_title':
          if (value.i_title === null) {
            setItem({ i_title: value.i_title });
          }
          break;
        case 'i_start_year':
          if (!value.i_start_year && !value.i_start_month) {
            setItem({ i_start_date: null });
          } else {
            setItem({ i_start_date: `${value.i_start_year}-${value.i_start_month}-01` });
          }
          return;
        case 'i_start_month':
          if (!value.i_start_year && !value.i_start_month) {
            setItem({ i_start_date: null });
          } else {
            setItem({ i_start_date: `${value.i_start_year}-${value.i_start_month}-01` });
          }
          return;
        case 'i_end_year':
          if (!value.i_end_year && !value.i_end_month) {
            setItem({ i_end_date: null });
          } else {
            setItem({ i_end_date: `${value.i_end_year}-${value.i_end_month}-01` });
          }
          return;
        case 'i_end_month':
          if (!value.i_end_year && !value.i_end_month) {
            setItem({ i_end_date: null });
          } else {
            setItem({ i_end_date: `${value.i_end_year}-${value.i_end_month}-01` });
          }
          return;
        case 'qa_answer':
          onUpdateQuestion('b_4', value.qa_answer);
          break;
        default:
          break;
      }
    });

    return () => subscription.unsubscribe();
  }, [methods.watch, user, item, init]);

  const onblur = (name: string) => {
    const value = methods.getValues();
    switch (name) {
      case 'i_title':
        setItem({ i_title: value.i_title });
        break;
      case 'i_url':
        setItem({ i_url: value.i_url });
        break;
      case 'i_tags_extra':
        setItem({ i_tags: [...value.i_tags, value.i_tags_extra] });
        break;
      case 'i_description':
        setItem({ i_description: value.i_description });
        break;
    }
    setBeforeUnload(false);
  };

  useEffect(() => {
    if (!item) return;
    if (!init) return;
    (async () => {
      methods.setValue('i_title', item?.i_title, { shouldDirty: true });
      methods.setValue('i_url', item?.i_url);
      methods.setValue('i_description', item?.i_description);
      methods.setValue('i_start_year', parseInt(item?.i_start_date?.split('-')[0] || ''));
      methods.setValue('i_start_month', parseInt(item?.i_start_date?.split('-')[1] || ''));
      methods.setValue('i_end_year', parseInt(item?.i_end_date?.split('-')[0] || ''));
      methods.setValue('i_end_month', parseInt(item?.i_end_date?.split('-')[1] || ''));
      const page: PortfolioPage | undefined = Array.from(item?.i_pages).find(
        (page) => page.p_page_layout === PortfolioPagePPageLayoutEnum.Questions
      );
      const content = Array.from(page?.p_contents || []).find(
        (content) => (content as Question).qa_id === 'b_4'
      );
      if (page && content) {
        methods.setValue('qa_answer', (content as Question).qa_answer[0]);
      }

      const tagList = FORM_OPTIONS.product_category.map((category) => category.children);
      const tags: string[] = [];
      let tags_extra = '';
      item?.i_tags?.forEach((tag) => {
        if (tagList.includes(tag)) {
          tags.push(tag);
        } else {
          tags_extra = tag;
        }
      });
      methods.setValue('i_tags', tags);
      methods.setValue('i_tags_extra', tags_extra);
      //初期のsetValueを待ってからsetInitする
      await delay(10);
      setInit(false);
    })();
  }, [item, init]);

  return (
    <FormProvider {...methods}>
      <form onChange={(v) => console.log(v)}>
        <div className="space-y-8 my-22">
          <FormLabel label="カテゴリー" type="small" />

          <FormContainerDropdownMultiple
            className="mkt_mask_items"
            name="i_tags"
            placeholder="作品のカテゴリーを選択してください"
            selectList={FORM_OPTIONS.product_category.map((category) => ({
              value: category.children,
              children: category.children,
            }))}
          />

          <FormContainerTextfield
            className=""
            name="i_tags_extra"
            placeholder="選択肢にない場合はこちらに直接入力してください"
            onBlur={() => onblur('i_tags_extra')}
            onFocus={() => setBeforeUnload(true)}
          />
        </div>
        <div className="space-y-8 my-22">
          <FormLabel label="作品タイトル" type="small" />
          <FormContainerTextfield
            className="mkt_mask_items"
            name="i_title"
            placeholder="例）株式会社毎日製薬コーポレートサイト"
            onBlur={() => onblur('i_title')}
            onFocus={() => setBeforeUnload(true)}
          />
        </div>
        <div className="space-y-8 my-22">
          <FormLabel label="URL" type="small" />
          <FormContainerTextfield
            className="mkt_mask_items"
            name="i_url"
            placeholder="例）https://www.mainichi-seiyaku.jp/"
            onBlur={() => onblur('i_url')}
            onFocus={() => setBeforeUnload(true)}
          />
        </div>
        <div className="space-y-8 my-22">
          <FormLabel label="概要" type="small" />
          <FormContainerTextArea
            className="mkt_mask_items"
            rows={6}
            maxLength={300}
            name="i_description"
            // onChange={(e) => methods.setValue('i_description', e.target.value)}
            placeholder="例）毎日製薬様のコーポレートサイトリニューアル。膨大な情報量をカテゴリーごとに再整理し、いくつかのシンプルなデザインフォーマットに落とし込むことで、目的の情報に到達しやすいサイトにしています。（最大300文字）"
            onBlur={() => onblur('i_description')}
            onFocus={() => setBeforeUnload(true)}
          />
        </div>

        <div className="space-y-8 my-22">
          <FormLabel label="制作時期" type="small" />
          <div className="flex flex-auto sm:flex-wrap">
            <FormContainerDropdown
              defaultValue={``}
              name={`i_start_year`}
              placeholder={PLACEHOLDER.year}
              className="w-100 sm:mb-8 mkt_mask_items"
              selectList={DATE_YEAR}
            />
            <FormLayoutUnitDivider className="mx-8 sm:mb-8">年</FormLayoutUnitDivider>
            <FormContainerDropdown
              defaultValue={``}
              name={`i_start_month`}
              placeholder={PLACEHOLDER.month}
              className="w-80 sm:mb-8 mkt_mask_items"
              selectList={DATE_MONTH}
            />
            <FormLayoutUnitDivider className="mx-8 sm:mb-8">月 〜</FormLayoutUnitDivider>
            <FormContainerDropdown
              defaultValue={``}
              name={`i_end_year`}
              placeholder={PLACEHOLDER.year}
              className="w-100 mkt_mask_items"
              selectList={DATE_YEAR}
            />
            <FormLayoutUnitDivider className="mx-8">年</FormLayoutUnitDivider>
            <FormContainerDropdown
              defaultValue={``}
              name={`i_end_month`}
              placeholder={PLACEHOLDER.month}
              className="w-80 mkt_mask_items"
              selectList={DATE_MONTH}
            />
            <FormLayoutUnitDivider className="ml-8">月</FormLayoutUnitDivider>
          </div>
        </div>
        <div className="space-y-8 my-22">
          <FormLabel label="制作にかかった期間" type="small" />
          <FormContainerDropdown
            className="mkt_mask_items"
            name="qa_answer"
            placeholder="期間を選択"
            selectList={FORM_OPTIONS.production_duration}
          />
        </div>
      </form>
    </FormProvider>
  );
};
