import React, {useMemo} from 'react';
import cn from 'classnames';
import '@/styles/common/Portfolio/ImagePage/button.scss';

type Props = {
  type: "image"|"url"
  children: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>
};

export function Button(props: Props): React.ReactElement {
  const icon = useMemo(() => {
    switch (props.type) {
      case 'image':
        return "Placeholder_image"
      case 'url':
        return "Link"
    }
  },[props.type])

  return (
    <button className={ cn("mbx-imagepage-button", props.type) } onClick={props.onClick}>
      <i className={ `mbx-icon mbx-icon-${icon}`} />
      {props.children}
    </button>
  );
}
