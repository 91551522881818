import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { BasicInfoLeft } from '@/componentsAdmin/Company/Detail/BasicInfoLeft';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { RootState } from '@/reduxAdmin';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import { ADMINApi, AdminCompany, ResponseError } from '@/utils/api-client';

type TInput = {
  mw_user_id: string;
};

type Props = {
  company: AdminCompany;
};

export const BasicInfo = (props: Props): React.ReactElement => {
  const CP2MASTER = useSelector((state: RootState) => state.admin_master.cp2);
  const dispatch = useDispatch();
  const params: { company_id: string } = useParams();
  const methods = useForm<TInput>({
    defaultValues: {
      mw_user_id: '',
    },
  });

  const { watch } = methods;

  useEffect(() => {
    if (props.company.mw_user_id) {
      methods.setValue('mw_user_id', props.company.mw_user_id?.toString());
    }
  }, [props.company.mw_user_id]);

  const onSelected = async () => {
    const company = cloneDeep(props.company);
    company.mw_user_id = Number(watch('mw_user_id'));
    dispatch(toggleLoading(true));
    try {
      await new ADMINApi().patchAdminCompany(params.company_id, company);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  return (
    <section className="flex items-center justify-between">
      <BasicInfoLeft company={props.company} />
      <section>
        <div className="w-240">
          <BaseButton
            theme="primary"
            size="s"
            className="w-full"
            href={`/admin/companies/${props.company.id}/candidates/`}
          >
            オファー履歴
          </BaseButton>
        </div>
        <FormProvider {...methods}>
          <form className="flex items-center mt-8">
            <p className="text-10_16">
              マイナビワークス
              <br />
              担当者：
            </p>
            <div className="w-160 ml-8">
              <FormContainerDropdown
                className="select"
                name="mw_user_id"
                placeholder="選択して下さい"
                selectList={CP2MASTER.cp2_charge_name}
                onSelected={onSelected}
              ></FormContainerDropdown>
            </div>
          </form>
        </FormProvider>
      </section>
    </section>
  );
};
