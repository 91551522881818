import cn from 'classnames';
import React, { useEffect, useRef, useState, VFC } from 'react';

import ImgIcon from '@/assets/images/OtherComponents/Square-thumbnail.svg';
import { useCropper } from '@/hooks/useCropper';
import { FileResponse } from '@/utils/api-client';

type TProps = {
  src: string | undefined;
  className?: string;
  type?: 'preview';
  onSave?: (res: FileResponse) => void;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=275%3A901
 *
 * クロップコンポーネントでクロップした画像を共有のfile APIでアップロード 値の保存は onSave を親から受け取ってその中で処理する
 */
export const ProfileImageSquare: VFC<TProps> = ({ src, className = 'w-104', type, onSave }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [setCropImage, postImage, response] = useCropper();
  const inputRef = useRef<HTMLInputElement>(null);
  const [imgSrc, setImgSrc] = useState(src);
  const onClick = () => {
    inputRef.current?.click();
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.files?.length)
      setCropImage(e.target.files[0], { width: 104 * 2, height: 144 * 2 });
  };

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  useEffect(() => {
    const res = response as FileResponse;
    if (res && res.f_thumbnail) {
      setImgSrc(res.f_thumbnail);
      if (onSave) {
        onSave(res);
      }
    }
  }, [response]);
  return (
    <div className={className}>
      <div
        className={cn('mbx-profileImageSquare', {
          'mbx-profileImageSquare--preview': type === 'preview',
        })}
        onClick={onClick}
      >
        <img src={imgSrc ? imgSrc : ImgIcon} className="w-auto" style={{ height: '144px' }}></img>
        {type !== 'preview' && (
          <>
            <div className="icon">
              <i className="mbx-icon mbx-icon-Camera"></i>
            </div>
            <input className="hidden" type="file" accept=".jpg, .jpeg, .png, .gif" ref={inputRef} onChange={onChange} />
          </>
        )}
      </div>
    </div>
  );
};
