import cn from 'classnames';
import React from 'react';

import { DraggableWrapper } from '@/components/common/DraggableWrapper';

export const DraggableSection: React.VFC<{
  children: React.ReactElement;
  title?: string;
  className: string;
  axis?: 'horizontal' | 'vertical';
  sortable?: boolean;
  nested?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listeners?: any;
  invisible?: boolean;
  onVisible?: React.MouseEventHandler<HTMLButtonElement>;
  onRemove?(): void;
}> = ({
  title,
  children,
  className,
  axis = 'vertical',
  nested = false,
  sortable = true,
  invisible,
  onVisible,
  onRemove,
  listeners,
}) => {
  return (
    <DraggableWrapper
      className={cn('my-8', className, 'flex', 'flex-col', { ['mx-16']: axis === 'horizontal' })}
      bodyClassName="flex flex-1 flex-col"
      dragHandle="dragHandle"
      invisible={invisible}
      onVisible={onVisible}
      onRemove={onRemove}
      title={title}
      sortable={sortable}
      nested={nested}
      listeners={listeners}
    >
      <div className="flex-1">{children}</div>
    </DraggableWrapper>
  );
};
