import '../../styles/common/footer.scss';

import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

type Props = {
  logo: boolean;
};

function Footer(props: Props): ReactElement {
  return (
    <footer className="footer">
      <div className="footer-inner">
        <p className="copyright">Copyright &copy; Mynavi Works Corporation All Rights Reserved.</p>
        {props.logo ? (
          <div className="footer-logo">
            <a href="https://matchbox.work/" target="_blank" rel="noreferrer">
              <img src="/images/footer_logo.png" alt="" />
            </a>
          </div>
        ) : (
          <ul>
            <li>
              <a href="https://mynavi-works.jp/company/detail.html" target="_blank" rel="noreferrer">
                運営会社
              </a>
            </li>
            <li>
              <a
                href="https://survey.mynavi.jp/cre/?enq=jlOIaGU5R3M%3d"
                target="_blank"
                rel="noreferrer"
              >
                お問い合わせ
              </a>
            </li>
            <li>
              <Link to="/terms_of_use">利用規約</Link>
            </li>
            <li>
              <HashLink to="/terms_of_use#privacy">個人情報の取り扱い</HashLink>
            </li>
            <li>
              <a href="/faq">よくあるご質問</a>
            </li>
          </ul>
        )}
      </div>
    </footer>
  );
}

export default Footer;
