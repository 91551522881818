import React from 'react';
import Modal from '@/components/common/Modal';
import { BaseButton } from '@/components/common/Button/';
import { DataLayerPushContainer } from '../../DataLayerPushContainer';

type Props = {
  isModal: boolean;
  onModal: React.Dispatch<React.SetStateAction<boolean>>;
  removePortfolio: () => Promise<void>;
};

export const WorksRemoveModal = (props: Props): React.ReactElement => {
  return (
    <Modal
      isOpen={props.isModal}
      isClose={() => props.onModal(false)}
      className={'modal-contents_delete w-624 h-256 sm:w-288 sm:h-160'}
      iconClass={'sp_none'}
    >
      <DataLayerPushContainer
        data={{
          event: 'pageView',
          actionType: 'page_view',
          actionName: 'modal_open',
          virtualPageName: 'delete-a-work',
        }}
      >
        <p className="mt-80 mbx-typography--body_1 sm:mt-32">作品を削除しますか？</p>
        <div className="btn-area flex justify-center mt-24 sm:mt-24 sm:p-0">
          <BaseButton
            className="ml-8 mr-8"
            theme="secondary"
            size="m"
            onClick={() => props.onModal(false)}
          >
            キャンセル
          </BaseButton>
          <BaseButton
            className="ml-8 mr-8"
            theme="primary"
            size="m"
            onClick={props.removePortfolio}
          >
            削除する
          </BaseButton>
        </div>
      </DataLayerPushContainer>
    </Modal>
  );
};
