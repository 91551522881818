import React from 'react';

type TProps = {
  label: string;
  className?: string;
  checked: boolean;
  onClick: React.MouseEventHandler<HTMLInputElement>;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=1%3A429
 */
export const FormToggleButton = ({
  label,
  checked,
  className,
  onClick,
}: TProps): React.ReactElement => {
  return (
    <div className={className}>
      <label className="mbx-formToggle">
        <span className="text-9_10 py-4 font-bold text-gray-700">{label}</span>
        <input type="checkbox" checked={checked} onClick={onClick} readOnly={true} />
        <div className="mbx-formToggle-body"></div>
      </label>
    </div>
  );
};
