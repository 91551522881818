import React from 'react';

type Props = {
  description: React.ReactElement;
  img?: string;
  children: React.ReactElement;
};

export const OnboardingPageWrapper = (props: Props): React.ReactElement => {

  return (
    <section className="py-48 px-80">
      <div className="font-bold mt-20 text-18_24">
        {props.description}
      </div>
      {props.img && (
        <div className="mt-18 h-280 sm:h-auto 'sm:mt-24'">
          <img src={props.img} alt="" className="h-full object-contain" />
        </div>
      )}
      <div>{props.children}</div>
    </section>
  );
};
