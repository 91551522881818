import React from 'react';
import { AxiosError } from 'axios';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { OnboardingPageWrapper } from './PageWrapper';
import BaseButton from '@/components/common/Button/BaseButton';
import TermsText from '@/components/common/OtherComponents/TermsText';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { notificationError } from '@/redux';
import { UserApi, ResponseError } from '@/utils/api-client';

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const V2Transfer = (props: Props): React.ReactElement => {
  const mq = useMBXMediaQuery();
  const dispatch = useDispatch();
  const onSend = async () => {
    try {
      new UserApi().postUserAgreement();
      props.setPage(1);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      //
    }
  };
  return (
    <div>
      <OnboardingPageWrapper
        description={
          <span className={cn({ 'text-17_32 block -mx-24 px-20': mq.sm })}>
            新しくなったMATCHBOXへようこそ。
            <br />
            旧バージョンからお使いの方は、
            <br />
            新しい利用規約、個人情報の取り扱いについてに
            {!mq.sm && <br />}
            同意の上、ご利用ください。
          </span>
        }
      >
        <section className="px-36 sm:px-0">
          <div className="pr-35  mt-30 bg-gray-100 rounded-modalContainer sm:pr-20">
            <div className="text-left h-300 py-40 pl-40 sm:py-25 sm:pl-20">
              <div
                className="overflow-y-scroll h-full pr-35 sm:pr-16"
                style={{ WebkitOverflowScrolling: 'touch' }}
              >
                <p className="text-14_28">
                  <TermsText AllSpFlg />
                </p>
              </div>
            </div>
          </div>
          <div className="text-right text-14_16 mt-20">
            <BaseButton
              theme="link"
              size="s"
              href="https://survey.mynavi.jp/cre/?enq=L5aHlrtrm58%3d"
            >
              <span className="text-black font-normal underline">退会をご希望の方はこちら</span>
            </BaseButton>
          </div>
        </section>
      </OnboardingPageWrapper>
      <div className="pb-66">
        <div className="w-188 m-auto mt-48 sm:w-full sm:px-24">
          <BaseButton theme="primary" size="m" className="w-full" onClick={onSend}>
            同意して進む
          </BaseButton>
        </div>
      </div>
    </div>
  );
};
