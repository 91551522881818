import { TDictionary } from '@/types/Util';

type TFormOption = {
  value: string;
  children: string;
};

const template: TDictionary<string> = {
  creative:
    '■■を卒業し、株式会社■■に入社。Webデザイナーとして受託案件のWeb制作に▲▲年間従事。ページデザインやHTML、CSS、JavaScript、PHPでのサイト構築を一通り経験した後、UI/UX領域にスキルの幅を広げるため、株式会社■■に転職。現在は、Webデザイナー経験を活かしながら、アプリ開発のUI/UXデザイナーに従事しています。自身で作成したポートフォリオサイトは「 https://********.com 」です。',
  direction:
    '■■を卒業し、株式会社■■に入社。Webディレクターとして受託案件のWeb制作に▲▲年間従事。要件定義、ワイヤーフレームの作成、進行管理などのサイト構築を一通り経験した後、より上流工程にキャリアを広げるために、株式会社■■に転職。現在は、『▲▲』という自社が運営するWebメディアのコンテンツ企画、及び、運用の責任者を担っています。自身で作成したポートフォリオサイトは「 https://********.com 」です。',
  others:
    '■■を卒業し、株式会社■■に入社。広告代理店の営業として主に▲▲業界に特化した広告運用代行に▲▲年間従事。■■年には目標比▲▲%を達成し社長賞を受賞。その後、自社開発のDSPツールの営業部門に異動し、カスタマーサクセスのポジションも兼務しながら、ツール販売だけでなく、集客手法の提案やWeb解析に必要な技術的なサポートなど、包括的にお客様のご支援を行っています。',
};

const template_job: Array<TFormOption> = [
  { value: 'creative', children: 'クリエイター・デザイナー職' },
  { value: 'direction', children: 'プランナー・ディレクター職' },
  { value: 'others', children: '営業職、その他' },
];

export const CAREER_HISTORY_TEMPLATE = {
  template,
  template_job,
};
