import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@/styles/common/Mypage/news.scss';

import React, { VFC } from 'react';
import Slider from 'react-slick';

import { MypageNewsModule } from './NewsModule';

type TMypageNewsModuleProps = React.ComponentProps<typeof MypageNewsModule>;

type TProps = {
  list: Array<TMypageNewsModuleProps>;
};
type TSlickArrowProps = {
  children?: React.ReactNode;
};

/**
 *
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=735%3A6143
 *
 */
export const MypageNews: VFC<TProps> = ({ list }) => {
  // currentSlide, slideCount を使っていないwarningが出るけど、エラー回避のために必要
  const SlickButtonFix: VFC<TSlickArrowProps> = ({ children, ...props }) => (
    <span {...props}>{children}</span>
  );
  const sliderSettings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    prevArrow: (
      <SlickButtonFix>
        <p>
          <i className="mbx-icon mbx-icon-Arrow_Left"></i>
        </p>
      </SlickButtonFix>
    ),
    nextArrow: (
      <SlickButtonFix>
        <p>
          <i className="mbx-icon mbx-icon-Arrow_Right"></i>
        </p>
      </SlickButtonFix>
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '8.5%',
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className={'mbx-news'}>
      <div className="mbx-news-inner">
        <div className="mbx-news-heading">
          <h1 className="mbx-news-heading-title">
            MATCHBOXから
            <br />
            お知らせ
          </h1>
        </div>
        <div className="mbx-news-slider-wrapper">
          <div className="mbx-news-slider">
            <Slider {...sliderSettings}>
              {list.map((item, index) => {
                return (
                  <MypageNewsModule
                    title={item.title}
                    image={item.image}
                    href={item.href}
                    blank={item.blank}
                    key={index}
                  ></MypageNewsModule>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};
