import { MESSAGE as BASE } from '@/definition/MESSAGE';
import { TDictionary } from '@/types/Util';

export const MESSAGE: TDictionary<string> = {
  ...BASE,
  saving_completed_for_company: '企業情報を保存しました。',
  saving_completed_for_position: 'ポジション情報を保存しました。',
  deleted_user: 'アカウントを削除しました',
  add_position: 'ポジションを追加しました',
  deleted_position: 'ポジションを削除しました',
};
