import React from 'react';
import '@/styles/common/Portfolio/image_responsive_preview.scss';
import { Image } from '@/utils/api-client';

type Props = {
  images?: Image[];
};

export function ImageResponsivePreview(props: Props): React.ReactElement {
  return (
    <>
      <div className="overflow-hidden h-full">
        <section className="mbx-image_responsive mkt_mask_items">
          <div className="mbx-image_responsive_inner">
            <div className="mbx-image_responsive_pc">
              {props.images && <img src={props.images[2]?.f_url} alt="" />}
            </div>

            <div className="mbx-image_responsive_tablet">
              {props.images && <img src={props.images[1]?.f_url} alt="" />}
            </div>

            <div className="mbx-image_responsive_sp">
              {props.images && <img src={props.images[0]?.f_url} alt="" />}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
