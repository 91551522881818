import React from 'react';

import * as CH from '@/components/CareerHistory';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type TFieldProps = {
  invisible?: boolean;
  onVisible?: React.MouseEventHandler<HTMLButtonElement>;
  onRemove?(): void;
  children: React.ReactElement;
  id: string;
};

export const DraggableCareer: React.VFC<TFieldProps> = ({invisible, onVisible, onRemove, children, id}) => {
  const { listeners, transform, transition, setNodeRef } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <div style={style} ref={setNodeRef}>
    <CH.DraggableSection className="" title="職歴" invisible={invisible} onVisible={onVisible} onRemove={onRemove} listeners={listeners}>
      {children}
    </CH.DraggableSection>
    </div>
  );
};
