import { AxiosError } from 'axios';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { TelModalForm } from '@/componentsAdmin/Company/Detail/TelModalForm';
import { ButtonTextLink } from '@/componentsDirect/Parts/Button/TextLink';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import { ADMINApi, AdminCompany, ResponseError } from '@/utils/api-client';
import { convertLineBreak } from '@/utils/string';

type Props = {
  company: AdminCompany;
  getCompany: () => Promise<void>;
};

export const CompanyDescription = (props: Props): React.ReactElement => {
  // ====================================================================================================
  //
  // HOOKS
  //
  // ====================================================================================================
  const params: { company_id: string } = useParams();
  // ====================================================================================================
  //
  // Redux
  //
  // ====================================================================================================
  const dispatch = useDispatch();
  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [telModal, setTelModal] = useState<boolean>(false);

  // ====================================================================================================
  //
  // EVENT
  //
  // ====================================================================================================
  const onSave = async (company: AdminCompany) => {
    dispatch(toggleLoading(true));
    try {
      await new ADMINApi().patchAdminCompany('1', company);
      setTelModal(false);
      props.getCompany();
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================

  const companyFormatData = useMemo(() => {
    if (!props.company)
      return {
        prefecture: '',
        employee_amount: '',
      };
    const prefecture = FORM_OPTIONS.prefecture.find(
      (p) => p.value === props.company.prefecture?.toString()
    )?.children;

    const employee_amount = FORM_OPTIONS.employee_amount.find(
      (a) => a.value === props.company.employee_amount?.toString()
    )?.children;
    return { prefecture, employee_amount };
  }, [props.company]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <div className="mbx-typography--body_2">
      <ul>
        <li>
          <span className="inline-block w-120">国税庁法人番号</span>
          <span>：</span>
          <span>
            {props.company.block_company_id?.map((company, index) => {
              return (
                <span key={index}>
                  {index !== 0 && '/'}
                  {company.id}
                </span>
              );
            })}
            <ButtonTextLink
              href={`/admin/companies/${params.company_id}/database`}
              className="ml-4"
            >
              編集
            </ButtonTextLink>
          </span>
        </li>
        <li className="mt-4">
          <span className="inline-block w-120">所在地</span>
          <span>：</span>
          <span>
            {companyFormatData.prefecture}
            {props.company.address}
          </span>
        </li>
        <li className="mt-4">
          <span className="inline-block w-120">電話番号</span>
          <span>：</span>
          <span>{props.company.tel}</span>
          <ButtonTextLink className="ml-4" onClick={() => setTelModal(true)}>
            編集
          </ButtonTextLink>
        </li>
        <li className="mt-4">
          <span className="inline-block w-120">事業内容</span>
          <span>：</span>
          <span>{props.company.description}</span>
        </li>
        <li className="mt-4">
          <span className="inline-block w-120">従業員数</span>
          <span>：</span>
          <span>{companyFormatData.employee_amount}</span>
        </li>
        <li className="mt-4">
          <span className="inline-block w-120">Webサイト</span>
          <span>：</span>
          <ButtonTextLink className="ml-4" href={props.company.url}>
            {props.company.url}
          </ButtonTextLink>
        </li>
        <li className="mt-4">
          <span className="inline-block w-120">求職者へのメッセージ</span>
          <span>：</span>
          <span
            dangerouslySetInnerHTML={{ __html: convertLineBreak(props.company.message) }}
          ></span>
        </li>
      </ul>
      <ModalDefault
        isOpen={telModal}
        onClose={() => setTelModal(false)}
        className="rounded-modalContainer"
      >
        <TelModalForm setModal={() => setTelModal(false)} company={props.company} onSave={onSave} />
      </ModalDefault>
    </div>
  );
};
