import React from 'react';

import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { UserCondition } from '@/componentsDirect/Parts/UserCondition/Forms';

/**
 * 検索条件フォーム 就業状況
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65909
 */
export const AdminSearchFormSectionEmployeeStatus = (): React.ReactElement => {
  return (
    <UserCondition title="社員情報">
      <>
        <div className="flex mb-22">
          <FormLayoutFieldset className="ml-24 w-176">
            <FormLabel label="企業担当者ID" type="small"></FormLabel>
            <FormContainerTextfield
              name="direct_company_user_id"
              className="w-full"
              placeholder="例）12345678"
            />
          </FormLayoutFieldset>

          <FormLayoutFieldset className="ml-24 flex-grow">
            <FormLabel label="ログインID（メールアドレス）" type="small"></FormLabel>
            <FormContainerTextfield
              name="direct_company_user_email"
              className="w-full"
              placeholder="例） match.tarou@matchbox.com"
            ></FormContainerTextfield>
          </FormLayoutFieldset>
          <FormLayoutFieldset className="ml-24 w-174">
            <FormLabel label="氏名" type="small"></FormLabel>
            <FormContainerTextfield
              name="direct_company_user_name"
              className="w-full"
              placeholder="例） 町田太郎"
            />
          </FormLayoutFieldset>
        </div>
      </>
    </UserCondition>
  );
};
