import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import '@/styles/common/Navigation/tab_navigation.scss';

type Menu = {
  title: string;
  url: string;
}

type Props = {
  menu: Menu[];
  activeIndex: number;
  align: 'fit' | 'left' | 'right',
  className?: string,
  itemClassName?: string,
};

/**
 * Tab Navigation
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=354%3A933
 *
 * アイテム要素のサイズを指定したいなどの場合は itemClassName でクラス名を指定してそのクラスに対してサイズを設定してください
 *
 */
export function TabNavigation(props: Props): React.ReactElement {
  return (
    <section className={cn('mbx-tab_navigation', props.className)}>
      <ul className={cn('mbx-tab_navigation__list', `mbx-tab_navigation__list--${props.align}`)}>
        {props.menu.map((item: Menu, index: number) => {
          return (
            <li className={cn(props.itemClassName, 'mbx-tab_navigation__item', {'is-active': index === props.activeIndex})} key={index}>
              <Link to={item.url} className="mbx-tab_navigation__link">
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </section>
  );
}
