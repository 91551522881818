import { AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { auth, toggleLoading, userInfo, userResponseAll } from '@/redux/index';
import { State } from '@/redux/state';
import { MatchboxUserResponse, UserApi, UserIsLogin } from '@/utils/api-client/';

type TProps = {
  children: React.ReactElement;
};

export function Auth({ children }: TProps): React.ReactElement | null {
  const dispatch = useDispatch();
  const isAuth = useSelector((state: State) => state.isAuth);
  useEffect(() => {
    (async () => {
      let userIsLogin: AxiosResponse<UserIsLogin>;
      let userResponse: AxiosResponse<MatchboxUserResponse>;

      if (isAuth === undefined) {
        try {
          dispatch(toggleLoading(true));
          userIsLogin = await new UserApi().getUserIsLogin();
          dispatch(auth(userIsLogin.data.is_login));
          if (userIsLogin.data.is_login) {
            userResponse = await new UserApi().getUser();
            if (userResponse.data) dispatch(userResponseAll(userResponse.data));
            if (userResponse.data.user_info) dispatch(userInfo(userResponse.data.user_info));
          }
          dispatch(toggleLoading(false));
        } catch (e) {
          //
        }
      }
    })();
  }, []);

  if (isAuth === undefined) return null; //TODOローディング画面
  return children;
}
