import '@/styles/common/Admin/SearchResult/search_conditions.scss';

import React, { useMemo } from 'react';

import BaseButton from '@/components/common/Button/BaseButton';
import {
  AdminDirectCompanySearchConditionModel,
  AdminSearchConditionModel,
  DirectSearchConditionModel,
} from '@/utils/api-client';
import { searchConditionsToText, searchConditionsToTextForADCSCM } from '@/utils/search';

type Props = {
  saved: boolean;
  title?: string;
  conditions:
    | DirectSearchConditionModel
    | AdminSearchConditionModel
    | AdminDirectCompanySearchConditionModel
    | null;
  onClickChange: React.MouseEventHandler<HTMLDivElement>;
  onClickSave: React.MouseEventHandler<HTMLDivElement>;
};

export function SearchConditions(props: Props): React.ReactElement {
  // タイプガード
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isADCSCM = (arg: any): arg is AdminDirectCompanySearchConditionModel => {
    return arg !== null && typeof arg === 'object' && typeof arg.block_company_id === 'string';
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const searchConditionsText = useMemo(() => {
    if (props.conditions) {
      return isADCSCM(props.conditions)
        ? searchConditionsToTextForADCSCM(props.conditions)
        : searchConditionsToText(props.conditions, 'admin');
    }

    return '';
  }, [props.conditions]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <section className="mbx-search_conditions">
      <div className="mbx-search_conditions__header">現在の検索条件</div>
      <div className="mbx-search_conditions__content">
        {props.saved && (
          <p className="mbx-search_conditions__title mkt_mask_items">{props.title}</p>
        )}
        <p className="mbx-search_conditions__text">{searchConditionsText}</p>
      </div>
      <div className="mbx-search_conditions__ui">
        <BaseButton
          size="s"
          theme="primary"
          className="mbx-search_conditions__button"
          onClick={props.onClickChange}
        >
          条件を変更
        </BaseButton>
        {!props.saved && (
          <BaseButton
            size="s"
            theme="secondary"
            className="mbx-search_conditions__button"
            onClick={props.onClickSave}
          >
            この条件を保存
          </BaseButton>
        )}
      </div>
    </section>
  );
}
