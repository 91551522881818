import React from 'react';
import cn from 'classnames';

type Props = {
  step: 1 | 2 | 3;
};

function Indicator(props: Props): React.ReactElement {
  return (
    <section className="indicator flex">
      <div className="text-center">
        <i
          className={cn(
            'mbx-icon',
            'text-red-700',
            'text-icon',
            props.step === 1 ? 'mbx-icon-Edit' : 'mbx-icon-Done'
          )}
        ></i>
        <p className="mt-8 text-12_12 font-en text-red-700 font-semibold">STEP1</p>
      </div>
      <div
        className={cn(
          'border-t',
          'border-top',
          'w-48',
          'mt-11',
          'ml-14',
          'mr-14',
          props.step === 1 ? 'border-gray-300' : 'border-red-700'
        )}
      ></div>
      <div className="text-center">
        <i
          className={cn(
            'mbx-icon',
            'text-icon',
            props.step === 1 && 'mbx-icon-Placeholder text-gray-200',
            props.step === 2 && 'mbx-icon-Edit text-red-700',
            props.step === 3 && 'mbx-icon-Done text-red-700'
          )}
        ></i>
        <p
          className={cn(
            'mt-8',
            'text-12_12',
            'font-en',
            'font-semibold',
            props.step === 1 ? 'text-gray-400' : 'text-red-700'
          )}
        >
          STEP2
        </p>
      </div>
      <div
        className={cn(
          'border-t',
          'border-top',
          'w-48',
          'mt-11',
          'ml-14',
          'mr-14',
          props.step === 3 ? 'border-red-700' : 'border-gray-300'
        )}
      ></div>
      <div className="text-center">
        <i
          className={cn(
            'mbx-icon',
            'text-icon',
            props.step === 3 ? 'mbx-icon-Done text-red-700' : 'mbx-icon-Placeholder text-gray-200'
          )}
        ></i>
        <p
          className={cn(
            'mt-8',
            'text-12_12',
            'font-en',
            'font-semibold',
            props.step === 3 ? 'text-red-700' : 'text-gray-400'
          )}
        >
          STEP3
        </p>
      </div>
    </section>
  );
}

export default Indicator;
