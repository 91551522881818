import cn from 'classnames';
import _ from 'lodash';
import React, { MouseEventHandler, useCallback, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { FormFootnote } from '@/components/common/Form/Footnote';
import { setInputClearingInProgress } from '@/redux';
import { delay } from '@/utils/delay';
import { addClass, removeClass } from '@/utils/dom';

type TProps = {
  name: string;
  id?: string;
  type?: 'text' | 'email' | 'password' | 'number' | 'submit' | 'url';
  min?: number; // type numberの際に任意で指定
  max?: number; // type numberの際に任意で指定
  autocapitalize?: 'off' | 'none' | 'on' | 'sentences' | 'words' | 'characters';
  placeholder?: string;
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
  pattern?: string;
  debugError?: boolean;
  defaultValue?: string; // 動的にフィールドを増やす際にはdefaultValueを呼び出し元から渡す
  onClick?: MouseEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  disabledError?: boolean; // コンポーネントないでエラー表示をさせたくない場合に指定
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=325%3A32193
 *
 * default の値はhook-formのuseFormで指定
 * https://react-hook-form.com/api/useform
 *
 * typeは使いそうなものを一旦設定してますが基本textだけの利用になります。
 *
 */
export const FormContainerTextfield = ({
  id,
  autocapitalize = 'off',
  placeholder,
  name,
  type = 'text',
  min,
  max,
  className = '',
  readOnly = false,
  disabled = false,
  pattern,
  debugError = false,
  defaultValue,
  onClick,
  onBlur,
  onFocus:onFocusTextField,
  disabledError = false,
}: TProps): React.ReactElement => {
  const myRef = useRef<HTMLInputElement | null>();
  const errorRef = useRef(null);
  const methods = useFormContext();
  const watchValue = methods.watch(name);
  const defaultClass = `mbx-formContainer mbx-formContainer--textfield`;
  const error = methods.formState.errors[name];
  const register = methods.register(name);
  const dispatch = useDispatch();

  /**
   * 値をクリア
   */
  const onClear: MouseEventHandler<HTMLButtonElement> = () => {
    dispatch(setInputClearingInProgress(true));

    // clear用のフラグの値が切り替わった後に処理
    delay(100).then(() => {
      methods.setValue(register.name, null);
    });
    // methods.setValue(register.name, null);
  };

  /**
   * input用の値が指定されていたらinputに指定
   */
  const numberAttributes = {
    min,
    max,
  };
  // cssのfocusだとclearボタンをクリックしようとする直前にfocusが外れてボタンがクリックできないので
  // onFocusとonBlurでclassを付けて対応
  const onFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    if(onFocusTextField)onFocusTextField(e);
    addClass(e.currentTarget, 'focus');
  };
  const onFocusOut: React.FocusEventHandler<HTMLInputElement> = (e) => {
    const target = e.currentTarget;
    delay(300).then(() => {
      removeClass(target, 'focus');
    });
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    register.onChange(e);
  };

  const debouncedChangeHandler = useCallback(_.debounce(onChange, 100), []);
  return (
    <div className={className}>
      {readOnly ? (
        <div className="mbx-formContainer mbx-formContainer--textfield--readonly">{watchValue}</div>
      ) : (
        <>
          <div
            className={cn('mbx-formContainer--textfield-wrapper', {
              'mbx-formContainer--error': error || debugError,
            })}
          >
            <input
              type={type}
              className={cn(defaultClass)}
              id={id}
              onBlur={(e) => {
                register.onBlur(e);
                onFocusOut(e);
                if (onBlur) {
                  onBlur(e);
                }
              }}
              onFocus={onFocus}
              defaultValue={defaultValue}
              onChange={debouncedChangeHandler}
              ref={(ref) => {
                myRef.current = ref;
                register.ref(ref);
              }}
              // ref={register.ref}
              name={register.name}
              placeholder={placeholder}
              autoCapitalize={autocapitalize}
              disabled={disabled}
              readOnly={readOnly}
              pattern={pattern}
              onClick={onClick}
              {...numberAttributes}
            />
            {watchValue && !disabled ? (
              <div className="mbx-formContainer--textfield-clear">
                <ButtonIconButton
                  type="delete"
                  iconName="Clear"
                  onClick={onClear}
                  hitArea="mini"
                  focus={false}
                ></ButtonIconButton>
              </div>
            ) : null}
          </div>

          {!disabledError && (
            <CSSTransition
              in={error || debugError}
              timeout={550}
              nodeRef={errorRef}
              unmountOnExit
              classNames="mbx-anime-error"
            >
              <div className="flex flex-row" ref={errorRef}>
                <FormFootnote className="text-error-700">
                  {error ? error.message : debugError ? debugError : ''}
                </FormFootnote>
              </div>
            </CSSTransition>
          )}
        </>
      )}
    </div>
  );
};
