import { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { AdminPage } from '@/componentsDirect/Layout/Page';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { MESSAGE } from '@/definitionDirect/MESSAGE';
import { YUP_VALIDATION } from '@/definitionDirect/VALIDATION';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import { DirectApi, RequestTokenCheck, ResponseError, ResponseSuccess } from '@/utils/api-client';
import { yupResolver } from '@hookform/resolvers/yup';

type IFormInputs = {
  token: string;
  password: string;
  re_password: string;
};

/**
 * アカウント登録
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A67018
 */
export const DirectEntry = (): React.ReactElement => {
  const API = new DirectApi();
  const dispatch = useDispatch();
  const history = useHistory();
  const { push } = React.useContext(DataLayerContext);

  const schema = yup.object().shape({
    password: YUP_VALIDATION.password,
    re_password: YUP_VALIDATION.re_password(),
  });

  const methods = useForm<IFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      token: window.location.search.substring(1),
      password: '',
      re_password: '',
    },
  });

  const { handleSubmit } = methods;

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = () => {
    const request: RequestTokenCheck = {
      token: window.location.search.substring(1),
    };
    API.postDirectRegistrationValidateToken(request).catch((error: AxiosError<ResponseError>) => {
      if (error.response) {
        dispatch(notificationError(error.response.data.error_message));
      }
    });
  };

  const onRegistration: SubmitHandler<IFormInputs> = (data) => {
    dispatch(toggleLoading(true));
    new DirectApi()
      .postDirectRegistrationPassword(data)
      .then((res) => {
        const userId = (res as AxiosResponse<ResponseSuccess & { userId: string }>).data.userId;
        push({
          event: 'accountRegistration',
          actionType: 'account_registration',
          actionName: 'account_registration',
          userId,
        });
        history.push('/direct/login');
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) {
          dispatch(notificationError(error.response.data.error_message));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  return (
    <AdminPage header={<HeaderTitle title="アカウント登録" />}>
      <FormProvider {...methods}>
        <div className="text-center">
          <p className="mbx-typography--body_1 mt-48">
            新しいパスワードを入力して、パスワードを再設定してください。
          </p>
          <form className="w-308 m-auto mt-30" onSubmit={handleSubmit(onRegistration)}>
            <FormLayoutFieldset>
              <FormLabel label="パスワード" type="small" className="pt-6" />
              <FormContainerTextfield
                name="password"
                type="password"
                placeholder="例）Match.123"
                height="large"
              />
              <p className="mbx-typography--caption_1 mt-8 whitespace-pre text-left">
                {MESSAGE.password_specifications}
              </p>
              <p className="mbx-typography--caption_1 mb-16 ml-12 text-left">
                {MESSAGE.password_specification_supplement}
              </p>
              <FormContainerTextfield
                type="password"
                name="re_password"
                placeholder="確認のためもう1度入力してください"
                height="large"
              ></FormContainerTextfield>
            </FormLayoutFieldset>
            <BaseButton theme="primary" size="m" className="w-full mt-48">
              会員登録を完了
            </BaseButton>
          </form>
        </div>
      </FormProvider>
    </AdminPage>
  );
};
