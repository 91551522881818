import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUser } from '@/hooksDirect/useUser';
import { RootState } from '@/reduxAdmin/';
import { setLogin } from '@/reduxAdmin/modules/directAuth';
import { notificationError } from '@/reduxAdmin/modules/utils';
import { DirectApi, ResponseError } from '@/utils/api-client/';

type TProps = {
  children: React.ReactElement;
};

export const AuthDirect = ({ children }: TProps): React.ReactElement => {
  const { getCompanyUser } = useUser();
  const API = new DirectApi();
  const dispatch = useDispatch();
  const isLogin = useSelector((state: RootState) => {
    return state.direct_auth.isLogin;
  });
  // ログイン情報チェック
  useEffect(() => {
    (async () => {
      if (isLogin === undefined) {
        try {
          const userIsLogin = await API.getDirectIsLogin();
          dispatch(setLogin(userIsLogin.data.is_login));
        } catch (e) {
          const error = e as AxiosError<ResponseError>;
          if (error.response) dispatch(notificationError(error.response.data.error_message));
        }
      }
    })();
  }, [dispatch]);

  // undefの場合以外のisLoginの値が切り替わるたびに処理する
  useEffect(() => {
    if (isLogin === undefined) return;
    getCompanyUser();
  }, [isLogin]);

  if (isLogin === undefined) return <></>;

  return children;
};
