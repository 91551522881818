import React, { useEffect } from 'react';

import {
  FormContainerDropdownMultiple,
  FormLabel,
  FormLayoutFieldset,
} from '@/componentsAdmin/Parts/Form';
import { UserCondition } from '@/componentsAdmin/Parts/UserCondition/Forms';
import { useUserTag } from '@/hooksAdmin/useUserTag';

/**
 * 検索条件フォーム タグ
 * @author s2 masa
 */
export const AdminSearchFormSectionTags = (): React.ReactElement => {
  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const { getUserTags, userTagOPTIONS } = useUserTag();

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    // タグ一覧取得
    getUserTags();
  }, []);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <UserCondition title="タグ">
      <FormLayoutFieldset className="pl-24">
        <FormLabel label="タグ" type="small"></FormLabel>
        <FormContainerDropdownMultiple
          name="user_tags"
          placeholder="選択してください（複数選択可）"
          selectList={userTagOPTIONS}
        ></FormContainerDropdownMultiple>
      </FormLayoutFieldset>
    </UserCondition>
  );
};
