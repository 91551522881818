import '@/styles/common/Admin/SearchResult/admin_offer_status.scss';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { AdminDirectUserSearchModel, ADMINApi, AdminUserInfoModel } from '@/utils/api-client';
import { RootState } from '@/reduxAdmin';
// import { optionValueToChildren } from '@/utils/optionValueToChildren';

type Props = {
  item: AdminDirectUserSearchModel;
};

export function AdminOfferStatus(props: Props): React.ReactElement {
  const CP2MASTER = useSelector((state: RootState) => state.admin_master.cp2);
  const [userInfo, setUserInfo] = useState<AdminUserInfoModel>();
  const getUser = async () => {
    // cp2_consultation_statusなどの取得に必要
    const res = await new ADMINApi().getAdminUserId(props.item.matchbox_id);
    setUserInfo(res.data.user_info);
  };
  useEffect(() => {
    getUser();
  }, [props.item]);

  const isMasterValue = (
    key: 'cp2_charge_name' | 'cp2_consultation_status' | 'cp2_registration_status',
    value: string
  ) => {
    return CP2MASTER[key].find((m) => m.value === value)?.children;
  };

  return (
    <section className="mbx-admin_offer_status">
      <div className="mbx-admin_offer_status__text">
        一次面接オファー{' '}
        {
          props.item.offers?.filter(
            (offer) =>
              offer.status === 3 || offer.status === 5 || offer.status === 6 || offer.status === 7
          ).length
        }
        <br />
        カジュアル面談オファー{' '}
        {
          props.item.offers?.filter(
            (offer) =>
              offer.status === 8 || offer.status === 9 || offer.status === 10 || offer.status === 11
          ).length
        }
      </div>
      <div className="mbx-admin_offer_status__labels">
        {userInfo?.cp2_consultation_status && (
          <div className="mbx-admin_offer_status__label">
            {isMasterValue('cp2_consultation_status', userInfo.cp2_consultation_status)}
          </div>
        )}
        {userInfo?.cp2_registration_status && (
          <div className="mbx-admin_offer_status__label">
            {isMasterValue('cp2_registration_status', userInfo.cp2_registration_status)}
          </div>
        )}
      </div>
    </section>
  );
}
