import React from 'react';
import BaseButton from '../Button/BaseButton';

function PendingSearch(): React.ReactElement {
  return (
    <section className="text-left">
      <p className="mbx-typography--body_2 mt-24">0〜0 / 0件</p>
      <ul>
        <li className="w-624 h-24 mt-24 bg-gray-50"></li>
        <li className="w-424 h-24 mt-24 bg-gray-50"></li>
        <li className="w-344 h-24 mt-24 bg-gray-50"></li>
        <li className="w-624 h-24 mt-24 bg-gray-50"></li>
        <li className="w-424 h-24 mt-24 bg-gray-50"></li>
      </ul>
      <div className="m-auto mt-32 text-center">
        <BaseButton theme="primary" size="m" className="send-btn" disabled={true}>
          チェックした企業をブロックする
        </BaseButton>
      </div>
    </section>
  );
}

export default PendingSearch;
