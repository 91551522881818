import * as yup from 'yup';

import { YUP_VALIDATION as BASE_VALIDATION } from '@/definition/VALIDATION';
import { VALIDATION_MESSAGE } from '@/definitionDirect/VALIDATION_MESSAGE';
import { checkDate, numberInRange } from '@/utils/utils';

export const YUP_VALIDATION = {
  ...BASE_VALIDATION,
  matchbox_id: yup
    .string()
    .matches(/^C\d{8}$/, VALIDATION_MESSAGE.matchbox_id)
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? undefined : value)),
  search_condition_title: yup
    .string()
    .required(VALIDATION_MESSAGE.required)
    .max(60, `60${VALIDATION_MESSAGE.maxString}`),
  toeic_score: yup
    .string()
    .matches(/^([5-9]|[1-9][0-9]|[1-9][0-8][0-9]|990)$/, VALIDATION_MESSAGE.score) // 最低10点、最高990点満点
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  toefl_score: yup
    .string()
    .matches(/^([0-9]|[1-9][0-9]|[1][0-1][0-9]||120)$/, VALIDATION_MESSAGE.score) // 最低10点、最高990点満点
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  date: yup
    .date()
    .typeError(VALIDATION_MESSAGE.date)
    .nullable()
    .transform(function (value, originalValue) {
      if (checkDate(originalValue) || !originalValue) {
        return String(originalValue).trim() === '' ? null : value;
      } else {
        return new Date('');
      }
    }),
  dateRange: yup
    .array()
    .transform(function (value) {
      // 日程が両方正しい日付が入っていたら期間設定が正しいか判定
      if (value.length === 2 && checkDate(value[0]) && checkDate(value[1])) {
        const from = new Date(value[0]).getTime();
        const to = new Date(value[1]).getTime();
        if (from <= to) {
          return value;
        } else {
          return false;
        }
      } else {
        return value;
      }
    })
    .typeError(VALIDATION_MESSAGE.date_range),
  age: yup
    .number()
    .typeError(VALIDATION_MESSAGE.age)
    .nullable()
    .min(10, VALIDATION_MESSAGE.age)
    .max(80, VALIDATION_MESSAGE.age)
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  ageRange: yup
    .array()
    .transform(function (value) {
      if (
        value.length === 2 &&
        numberInRange(10, 80, Number(value[0])) &&
        numberInRange(10, 80, Number(value[0]))
      ) {
        const from = value[0];
        const to = value[1];
        if (from <= to || !to) {
          return value;
        } else {
          return false;
        }
      } else {
        return value;
      }
    })
    .typeError(VALIDATION_MESSAGE.age_range),
  number: yup
    .number()
    .typeError(VALIDATION_MESSAGE.number)
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  numberRange: (min: number, max: number): yup.AnySchema => {
    return yup
      .array()
      .transform(function (value) {
        if (
          value.length === 2 &&
          value[0] !== '' &&
          value[1] !== '' &&
          value[0] !== null &&
          value[1] !== null &&
          numberInRange(min, max, Number(value[0])) &&
          numberInRange(min, max, Number(value[1]))
        ) {
          const from = Number(value[0]);
          const to = Number(value[1]);
          if (from <= to) {
            return value;
          } else {
            return false;
          }
        } else {
          return value;
        }
      })
      .typeError(VALIDATION_MESSAGE.number_range);
  },
};
