import React, { ReactNode, useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';

import { BaseButton, ButtonIconButton } from '@/components/common/Button';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { PDFDownLoadModal } from '@/components/common/Portfolio/PDFDownLoadModal';
import { TooltipPdfDownload } from '@/components/common/Tooltip/PdfDownload';
import { useDownloadPDFForPortfolio } from '@/hooks/useDownloadPDFForPortfolio';
import { State } from '@/redux/state';

type TProps = {
  children: ReactNode;
};

const ModalPortal = ({ children }: TProps): React.ReactElement => {
  return ReactDOM.createPortal(
    children,
    document.getElementById('generalPDFDownloadModal') as HTMLElement
  );
};

const Message = (): React.ReactElement => {
  const { requestToGeneratePDF } = useDownloadPDFForPortfolio();
  return (
    <>
      PDFがダウンロードできます。直前の編集内容がPDFに反映されていない場合は
      <span
        className="underline cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          requestToGeneratePDF();
        }}
      >
        こちらをクリック
      </span>
      して再度ダウンロードの準備を開始してください
    </>
  );
};

/**
 * ポートフォリオのPDFダウンロードボタン制御用
 */
export const PortfolioPDFDownloadButton = (): React.ReactElement => {
  const { push } = useContext(DataLayerContext);
  const [pdfModal, setPdfModal] = useState(false);
  const statusOfPDF = useSelector((state: State) => state.portfolioStatusOfPDF);
  const { downloadPDF } = useDownloadPDFForPortfolio();

  const checkStatus = () => {
    if (statusOfPDF === 0 || statusOfPDF === 1) {
      return 'creating';
    }

    if (statusOfPDF === null || statusOfPDF === 2) {
      return 'canCreate';
    }

    if (statusOfPDF === 3) {
      return 'created';
    }
  };

  return (
    <>
      {checkStatus() === 'canCreate' && (
        <BaseButton
          theme="link"
          size="40"
          download={`pf-test.pdf`}
          onClick={() => {
            setPdfModal(true);
            push({
              event: 'fileDownload',
              actionType: 'file_download',
              actionName: 'ポートフォリオ',
            });
          }}
          iconName="Download"
        >
          <span>PDF</span>
        </BaseButton>
      )}

      {checkStatus() === 'creating' && (
        <div className="flex items-center">
          <div className="mbx-loader_sm_2 mr-6">
            <div className="mbx-loader_sm_2_core"></div>
          </div>
          <span className="text-blue-700 text-14_16 sm:text-12_14 font-bold">
            <span className="sm:hidden">PDFダウンロード準備中</span>
            <span className="hidden sm:block">ダウンロード</span>
          </span>
        </div>
      )}

      {checkStatus() === 'created' && (
        <div className="flex">
          <BaseButton
            theme="link"
            size="40"
            download={`pf-test.pdf`}
            onClick={() => {
              downloadPDF();
              push({
                event: 'fileDownload',
                actionType: 'file_download',
                actionName: 'ポートフォリオ',
              });
            }}
            iconName="Done"
          >
            <span>PDF</span>
          </BaseButton>

          <TooltipPdfDownload message={<Message />}>
            <ButtonIconButton hitArea="mini" iconName="Info" type="blue" focus={false} />
          </TooltipPdfDownload>
        </div>
      )}
      <ModalPortal>
        <PDFDownLoadModal
          isOpen={pdfModal}
          onClose={() => setPdfModal(false)}
          onClickCancel={() => setPdfModal(false)}
        ></PDFDownLoadModal>
      </ModalPortal>
    </>
  );
};
