import React, { VFC } from 'react';
import cn from 'classnames';
import '@/styles/common/Navigation/user-profile.scss';
import { MotivationGauge } from '@/components/common/Navigation/MotivationGauge';

type TProps = {
  name: string;
  preferredChangeDate: 0 | 1 | 2 | 3 | 4 | 5;
  direct: string;
  icon: string;
  sp?: boolean;
};

export const UserProfile: VFC<TProps> = ({ preferredChangeDate = 0, direct, name, icon, sp }) => {
  return (
    <div className={cn('mbx-user-profile')}>
      <img src={icon} alt="" className="mkt_mask_items" />
      <section>
        <h2 className="mkt_mask_items">{name}</h2>
        <MotivationGauge value={preferredChangeDate} {...{ direct }} {...{ sp }} />
      </section>
    </div>
  );
};
