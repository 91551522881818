import React, { ReactElement } from 'react';
import '@/styles/common/Portfolio/ActionHeader.scss';

type Props = {
  children?: React.ReactNode;
};

function ActionHeader(props: Props): React.ReactElement {
  return (
    <section className="mbx-action-header">
      <div className="mbx-action-header_inner mkt_mask_items">
        {props.children}
      </div>
    </section>
  );
}

export default ActionHeader;
