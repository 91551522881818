import { AxiosError } from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  searchConditionNumberProperties,
  searchConditionPeriodsProperties,
} from '@/hooksAdmin/useSearchConditionValidation';
import { RootState } from '@/reduxAdmin';
import { setSearchCondition } from '@/reduxAdmin/modules/adminSearchCondition';
import {
  forceLoadingOff,
  forceLoadingOn,
  notificationError,
  notificationSuccess,
  toggleLoading,
} from '@/reduxAdmin/modules/utils';
import {
  ADMINApi,
  AdminSearchConditionModel,
  AdminSearchConditionRequest,
  AdminSearchConditionResponse,
  AdminSearchUserRequest,
  AdminSearchUsersResponse,
  Pagination,
  ResponseError,
} from '@/utils/api-client';
import { conversionStringsLikeNumbers, conversionValuesForPeriods } from '@/utils/utils';

// import { testSearchResultData } from '@/debug/adminSearchResult';

type TReturn = {
  searchCondition: AdminSearchConditionModel | null; // フォームの初期値 これと比較して変更があったかどうか判定
  saveSearchCondition: (condition: AdminSearchConditionModel) => void; // 検索条件を一時保存
  fetchSearchUsers: (query: Pagination) => void; // ユーザー検索
  searchUsersResult: AdminSearchUsersResponse | undefined;
  postAdminSearchCondition: (search_condition_name: string) => Promise<void>;
  getSearchCondition: (idPathParam: number) => Promise<void>;
  patchSearchCondition: (
    idPathParam: number,
    request: AdminSearchConditionRequest
  ) => Promise<void>;
  searchConditionResult: AdminSearchConditionResponse | undefined;
  // setTestSearchResultData: () => void;
};

/**
 * マイナビ向け検索条件の一時保存及び保存された情報の取得
 */
export const useSearchCondition = (): TReturn => {
  const API = new ADMINApi();
  const dispatch = useDispatch();

  const [searchUsersResult, setSearchUsersResult] = useState<AdminSearchUsersResponse>();
  const [searchConditionResult, setSearchConditionResult] =
    useState<AdminSearchConditionResponse>();

  const searchCondition = useSelector((state: RootState) => state.admin_search_condition.condition);

  /**
   * 検索条件を整形して一時保存
   */
  const saveSearchCondition = (condition: AdminSearchConditionModel) => {
    let convertValue = conversionValuesForPeriods(
      condition as Record<string, unknown>,
      searchConditionPeriodsProperties
    );

    convertValue = conversionStringsLikeNumbers(convertValue, searchConditionNumberProperties);
    dispatch(setSearchCondition(convertValue));
  };

  /**
   * ユーザー検索
   */
  const fetchSearchUsers = (query: Pagination) => {
    const searchQuery: AdminSearchUserRequest = {
      limit: query.limit,
      offset: query.offset,
      sort_by: query.sort_by,
      sort_order: query.sort_order,
      conditions: searchCondition as AdminSearchConditionModel,
    };
    dispatch(forceLoadingOn());
    dispatch(toggleLoading(true));
    API.postAdminSearchUsers(searchQuery)
      .then((res) => {
        setSearchUsersResult(res.data);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(forceLoadingOff());
        dispatch(toggleLoading(false));
      });
  };

  /**
   * ユーザー検索条件の保存
   */
  const postAdminSearchCondition = (search_condition_name: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const params: AdminSearchConditionRequest = {
        search_condition_name,
        ...searchCondition,
      } as AdminSearchConditionRequest;

      params.search_condition_name = search_condition_name;

      dispatch(toggleLoading(true));
      API.postAdminSearchCondition(params)
        .then(() => {
          dispatch(notificationSuccess('検索条件を保存しました'));
          resolve();
        })
        .catch((error: AxiosError<ResponseError>) => {
          if (error.response) dispatch(notificationError(error.response.data.error_message));
          reject();
        })
        .finally(() => {
          dispatch(toggleLoading(false));
        });
    });
  };

  /**
   * 検索条件詳細
   */
  const getSearchCondition = (idPathParam: number) => {
    dispatch(toggleLoading(true));
    return API.getAdminSearchConditionId(idPathParam)
      .then((res) => {
        setSearchConditionResult(res.data);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 検索条件詳細
   */
  const patchSearchCondition = (idPathParam: number, request: AdminSearchConditionRequest) => {
    dispatch(toggleLoading(true));

    const condition: AdminSearchConditionModel = request;

    let convertValue = conversionValuesForPeriods(
      condition as Record<string, unknown>,
      searchConditionPeriodsProperties
    );
    convertValue = conversionStringsLikeNumbers(convertValue, searchConditionNumberProperties);

    const requestConvertValue = {search_condition_name: request.search_condition_name, ...convertValue} as AdminSearchConditionRequest

    return API.patchAdminSearchConditionId(idPathParam, requestConvertValue)
      .then((res) => {
        console.log(res);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  // 暫定的に仮データを設定
  // const setTestSearchResultData = () => {
  //   setSearchUsersResult({
  //     users: testSearchResultData(),
  //     offset: 0,
  //     total: 100,
  //     result: 'success',
  //   });
  // };

  return {
    searchCondition,
    saveSearchCondition,
    fetchSearchUsers,
    searchUsersResult,
    getSearchCondition,
    patchSearchCondition,
    searchConditionResult,
    postAdminSearchCondition,
    // setTestSearchResultData,
  };
};
