import React, { useMemo } from 'react';
import cn from 'classnames';

type Props = {
  className?: string;
  items: string[];
  color?: 'black' | 'blue';
  size?: 's' | 'm';
};

export const BasicInfo = (props: Props): React.ReactElement => {
  const style = useMemo(() => {
    let color = '';
    if (props.color === 'blue') color = 'text-blue-700';
    let size = '';
    if (props.size === 's') size = 'mbx-typography--caption_1';
    if (props.size === 'm') size = 'text-11_16';

    return { color, size };
  }, [props.color, props.size]);
  return (
    <section className={props.className}>
      <ul className="flex">
        {props.items.map((item, index) => {
          return (
            <li key={index} className={cn(style.size, style.color)}>
              {index !== 0 && <span className="mx-4">/</span>}
              <span>{item}</span>
            </li>
          );
        })}
      </ul>
    </section>
  );
};
