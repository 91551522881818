import React from 'react';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';

import { ButtonIconButton } from '@/components/common/Button/';
import {
    FormContainerDropdown, FormContainerTextfield, FormLayoutFieldset
} from '@/components/common/Form/index';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { TSkillFormValues } from '@/types/Pages/CareerHistory';

type TFieldProps = {
  index: number;
  item: FieldArrayWithId<TSkillFormValues, 'skills', 'uid'>;
  onRemove?(): void;
};

export const Skill: React.VFC<TFieldProps> = (props) => {
  const { register } = useFormContext();
  return (
    <FormLayoutFieldset>
      <input type="hidden" {...register(`skills.${props.index}.id` as const)} />
      <div className="lg:flex md:flex lg:space-x-8 md:space-x-8 sm:space-y-8">
        <div className="flex space-x-8 flex-1">
          <FormContainerTextfield
            defaultValue={''}
            name={`skills.${props.index}.name`}
            placeholder="例）Photoshop"
            className="w-full mkt_mask_items"
          />
        </div>

        <div className="flex space-x-8">
          <FormContainerDropdown
            defaultValue={''}
            name={`skills.${props.index}.level`}
            placeholder="レベル"
            className="sm:flex-1 lg:w-120 md:w-120 mkt_mask_items"
            selectList={FORM_OPTIONS.skill_level}
          />
          <FormContainerDropdown
            defaultValue={''}
            name={`skills.${props.index}.experience`}
            placeholder="経験年数"
            className="sm:flex-1 lg:w-120 md:w-120 mkt_mask_items"
            selectList={FORM_OPTIONS.experienced_skill}
          />
          <div className="flex sm:hidden">
            <ButtonIconButton type="gray" iconName="Sortable" className="dragHandle" />
            {props.onRemove ? (
              <ButtonIconButton type="gray" iconName="Clear" onClick={props.onRemove} />
            ) : (
              <ButtonIconButton type="gray" iconName="Clear" className="opacity-10" />
            )}
          </div>
        </div>
      </div>
    </FormLayoutFieldset>
  );
};
