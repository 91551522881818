import React from 'react';
import Modal from '@/components/common/Modal';
import { DataLayerPushContainer } from '../../DataLayerPushContainer';

type Props = {
  isModal: boolean;
  onModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTypeImage: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  setSelectedTypeContents: React.Dispatch<React.SetStateAction<string[]>>;
  setPortfolioAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  setPortfolioTypeModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const WorkTypeSelectModal = (props: Props): React.ReactElement => {
  const modalContents = [
    {
      type: 'Webページ',
      details: 'サイトや記事などのURL',
      img: 'portfolio-type_02.svg',
    },
    {
      type: '動画',
      details: 'YouTube, VimeoのURL',
      img: 'portfolio-type_03.svg',
    },
    {
      type: '音楽',
      details: 'SoundCloudのURL',
      img: 'portfolio-type_04.svg',
    },
    {
      type: '資料',
      details: 'SlideShareのURL',
      img: 'portfolio-type_05.svg',
    },
    {
      type: '3Dモデル',
      details: 'SketchfabのURL',
      img: 'portfolio-type_06.svg',
    },
  ];

  const selectedPortFolioType = (index: number) => {
    const type = modalContents[index].type;
    const details = modalContents[index].details;
    const content = [type, details];
    props.setSelectedTypeContents(content);
    props.setPortfolioAddModal(true);
    props.setPortfolioTypeModal(false);
  };

  return (
    <Modal
      isOpen={props.isModal}
      isClose={() => props.onModal(false)}
      className="modal-contents_type w-784 h-722"
    >
      <DataLayerPushContainer
        data={{
          event: 'pageView',
          actionType: 'page_view',
          actionName: 'modal_open',
          virtualPageName: 'add-a-work',
        }}
      >
      <p className="mbx-typography--heading_2 mt-80 sm:mt-48">作品タイプを選択</p>
      <p className="mt-24 mbx-typography--body_1 sm:pl-24 sm:pr-24">
        追加する作品の種類を選択してください。
        <br />
        URLで作品を登録すると、設定されている画像やテキストが自動で入力されるので便利です。
      </p>
      <div className="contents-area flex flex-wrap justify-center mt-48 sm:mt-38 sm:pb-80">
        <label htmlFor="file_portfolio">
          <div className="content w-196 m-8 sm:w-155 py-20">
            <input
              id="file_portfolio"
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={props.selectedTypeImage}
            ></input>
            <p className="mbx-typography--subhead_1">画像ファイル</p>
            <div className="w-70 mt-20 m-auto">
              <img src="/images/Onboarding/portfolio-type_01.svg" alt="" />
            </div>
            <p className="text-10_16 mt-20 sm:mt-16">jpg, png, gif</p>
          </div>
        </label>
        {modalContents.map((item, index) => {
          return (
            <div
              className="content w-196 m-8 sm:w-155 py-20"
              key={index}
              onClick={() => selectedPortFolioType(index)}
            >
              <p className="mbx-typography--subhead_1">{item.type}</p>
              <div className="w-70 mt-20 m-auto">
                <img src={'/images/Onboarding/' + item.img} alt="" />
              </div>
              <p className="text-10_16 mt-20 sm:mt-16">{item.details}</p>
            </div>
          );
        })}
      </div>
      </DataLayerPushContainer>
    </Modal>
  );
};
