import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { usePagerIndex } from '@/hooks/common/usePagerIndex';

type Props = {
  pageLength: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

function Pager(props: Props): React.ReactElement {
  const [pageList, setPageList] = useState<number[]>([]);
  const [limit, setLimit] = useState<number>(5);
  const [cener, setCener] = useState<number>(3);
  const mq = useMBXMediaQuery();
  const pagerIndex = usePagerIndex();

  useEffect(() => {
    if (mq.sm) {
      setLimit(4);
      setCener(2);
    }
    onDefaultPageList();
  }, []);

  const onDefaultPageList = () => {
    const pageBox = [];
    for (let i = 0; i < props.pageLength; i++) {
      pageBox.push(i);
    }
    setPageList(pageBox);
  };

  const onPageClick = (number: number) => {
    if (number > cener) {
      setPageList(pagerIndex.onPage(number, cener, limit, pageList));
    } else {
      onDefaultPageList();
    }
    props.setPage(number);
    window.scrollTo(0, 0);
  };

  const onPrevClick = () => {
    if (props.page === 0) return;
    if (pageList.length - 1 !== limit) {
      if (props.page < cener) {
        onDefaultPageList();
        props.setPage(props.page - 1);
        window.scrollTo(0, 0);
        return;
      }
    }
    setPageList(pagerIndex.onPrev(props.page, cener, pageList, limit))
    props.setPage(props.page - 1);
    window.scrollTo(0, 0);
  };

  const onNextClick = () => {
    if (props.page === props.pageLength - 1) return;
    const box = pageList;
    if(box.length - 1 !== limit) {
      if (props.page + 1 > cener) {
        setPageList(pagerIndex.onNext(props.page, cener, pageList, limit));
      } else {
        onDefaultPageList();
      }
    }
    props.setPage(props.page + 1);
    window.scrollTo(0, 0);
  };

  return (
    <section className="pager">
      <ul className="flex items-center text-14_40 text-blue-700">
        <li
          className={cn(
            'flex',
            'items-center',
            'mr-38',
            'cursor-pointer',
            props.page === 0 && 'opacity-20'
          )}
          onClick={onPrevClick}
        >
          <i className="mbx-icon mbx-icon-Arrow_Left"></i>
        </li>
        {pageList.map((p, index) => {
          if (index <= limit) {
            return (
              <li
                key={index}
                className={cn(
                  'mr-32',
                  'underline',
                  'cursor-pointer',
                  props.page === p && 'opacity-20'
                )}
                onClick={() => onPageClick(p)}
              >
                {p + 1}
              </li>
            );
          }
        })}
        <li
          className={cn(
            'flex',
            'items-center',
            'cursor-pointer',
            props.page === props.pageLength - 1 && 'opacity-20'
          )}
          onClick={onNextClick}
        >
          <i className="mbx-icon mbx-icon-Arrow_Right"></i>
        </li>
      </ul>
    </section>
  );
}

export default Pager;
