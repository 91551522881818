import React, {useState, useRef, useEffect} from 'react';
import { CropperModal } from '@/components/common/Modal/Cropper';
import '@/styles/common/Portfolio/header_image.scss';
import { IMAGE_SIZE } from '@/definition/IMAGE_SIZE';
import { useFileLimit } from '@/hooks/useFIleLimit';
import { useWindowSize } from '@/hooks/common/useWindowSize';

type Props = {
  cameraIcon?: boolean;
  imageSrc: string;
  onChange?: (value: string) => void;
  onClick?: () => void;
};

function HeaderImage(props: Props): React.ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [buffer, setBuffer] = useState<string|ArrayBuffer|null>(null)
  const [height, setHeight] = useState<number>(0)
  const { checkFileSize } = useFileLimit();
  const ref = useRef<HTMLDivElement>(null)
  const windowSize = useWindowSize();

  const onChange = (e:any) => {
    const file = e.target.files[0];
    if (!checkFileSize(file.size, 5)) return;
    setIsOpen(true)
    const reader = new FileReader()

    reader.onload = ()=> {
      setBuffer(reader.result)
    }

    // 画像の読み込み
    reader.readAsDataURL(file);
  }

  const onCropped = (file: any) => {
    if(props.onChange)props.onChange(file)
    setIsOpen(false)
  }

  useEffect(() => {
    if (!ref.current) return;
    const width = ref.current.clientWidth;
    setHeight(width / 5);
  },[ref, windowSize])

  return (
    <section className="header-image" ref={ref} style={{height}}>
      <div className="header-image_inner mkt_mask_items" style={{ backgroundImage: `url(${props.imageSrc})` }}>
        {props.cameraIcon ? (
          <>
            <div>
              <div className="header-icons">
                <div className="camera-icon">
                  <label className="flex" htmlFor="file_banner">
                    <input id="file_banner" type="file" accept=".jpg, .jpeg, .png, .gif" onChange={onChange}></input>
                    <i className="mbx-icon mbx-icon-Camera"></i>
                    <p>画像を変更</p>
                  </label>
                </div>
                {props.imageSrc.startsWith('http') &&
                  <div className="close-icon" onClick={() => { if(props.onClick)props.onClick() }}>
                    <i className="mbx-icon mbx-icon-Trash mr-2"></i>
                    <p>画像を削除</p>
                  </div>
                }
              </div>
            </div>
            <div className="lg:pt-20 md:pt-20 sm:pt-10 text-center">
              <p className="text-caption_2 text-gray-700">横2560px × 縦512px推奨、最大5MB</p>
            </div>
          </>
        ) : null}
      </div>
      <CropperModal
        isOpen={isOpen}
        width={IMAGE_SIZE.header_image.width}
        height={IMAGE_SIZE.header_image.height}
        src={buffer}
        postImage={onCropped}
        onClose={() => setIsOpen(false)}
      />
    </section>
  );
}

export default HeaderImage;
