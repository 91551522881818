import { AxiosError } from 'axios';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useInterval from 'use-interval';

import { MESSAGE } from '@/definition/MESSAGE';
import {
    notificationError, setPortfolioStatusOfPDF, setPrepareFrag, setSaved
} from '@/redux/index';
import { State } from '@/redux/state';
import { ResponseError } from '@/utils/api-client/';

const beforeUnloadHandler = (event: any) => {
  event.returnValue = MESSAGE.before_unload;
};

/**
 * @summary paramの変更を監視してAPIリクエストなどを行うhook
 * @param {any} API API種別
 * @param {string} method API method
 * @param {any} map responseを整形する関数
 * @param {number} delay インターバル秒数
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePolling(API: any, method: string, map?: (data: unknown) => void, delay = 5000) {
  console.log(method);
  const dispatch = useDispatch();
  // interval中に処理するかのflag
  const prepareFrag: boolean = useSelector((state: State) => state.prepareFrag);

  // セットされたパラメーターデータ
  const [paramData, __setParamData] = useState<any>();
  // APIからのレスポンス mapがあれば処理して返す
  const [responseData, setResponseData] = useState();
  // APIの処理が終わったかどうか
  const [saveFrag, setSaveFrag] = useState<boolean>(false);

  useEffect(() => {
    if (prepareFrag) {
      window.addEventListener('beforeunload', beforeUnloadHandler);
    } else {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    }
  }, [prepareFrag]);

  const interval = () => {
    (async () => {
      let response;
      try {
        if (prepareFrag && paramData) {
          setSaveFrag(true);
          response = await new API()[method](...Object.values(paramData));
          setResponseData(response);
          setSaveFrag(false);
          dispatch(setPrepareFrag(false));
          dispatch(setSaved(true));

          // ポートフォリオの保存処理されたらPDFのステータスを変更
          // !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=10595%3A174042&t=eBvn3ZeAFMFntJap-4
          if (method === 'patchPortfolioEdit') {
            dispatch(setPortfolioStatusOfPDF(null));
          }
        }
      } catch (e) {
        const error = e as AxiosError<ResponseError>;
        if (error.response) {
          dispatch(notificationError(error.response.data.error_message));
        }

        setSaveFrag(false);
        dispatch(setPrepareFrag(false));
      }
    })();
  };

  const __map = (data: any) => {
    if (!map) return data;
    return map(data);
  };

  const setParam = (data: any) => {
    dispatch(setPrepareFrag(true));
    __setParamData(data);
  };

  const setBeforeUnload = (flag: boolean) => {
    dispatch(setPrepareFrag(flag));
  };

  useInterval(interval, delay);

  return [paramData, setParam, __map(responseData), saveFrag, setBeforeUnload];
}
