import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { DirectCompanyModel } from '@/utils/api-client';

import { OnboardingPage_1 } from './Page1';
import { OnboardingPage_2 } from './Page2';
import { OnboardingPage_3 } from './Page3';
import { OnboardingPage_4 } from './Page4';
import { OnboardingPage_5 } from './Page5';
import { OnboardingPage_6 } from './Page6';

type Props = {
  company: DirectCompanyModel | undefined;
  patchCompany: (request: DirectCompanyModel) => void;
  requiredItems: (keyof DirectCompanyModel)[];
  postion: boolean;
  onClose: () => void;
};

const defaultStep = {
  step1: true,
  step2: true,
  step3: true,
  step4: true,
  step5: true,
  step6: true,
};

type TStep = typeof defaultStep;

export const Onboarding = (props: Props): React.ReactElement => {
  const [page, setPage] = useState<number>(1);
  const [step, setStep] = useState<TStep>(defaultStep);
  const [totalDots, setTotalDots] = useState<number>(1);
  const [isDone, setIsDone] = useState<boolean>(false);

  useEffect(() => {
    if (!props.company) return;

    // Page1の必須項目が全て入力されているか
    const pageOneRequiredItems = props.requiredItems.filter(item => item !== 'message');
    const hasItems = pageOneRequiredItems.every(item => props.company && props.company[item]);
    // ポジションが入力されているか
    const hasPostion = props.postion;
    // メッセージが入力されているか
    const hasMessage = props.company.message ? true : false;

    const visibleStep = cloneDeep(defaultStep);
    if (hasItems) {
      visibleStep.step1 = false;
    }
    if (hasPostion) {
      visibleStep.step2 = false;
      visibleStep.step3 = false;
    }
    if (hasMessage) {
      visibleStep.step4 = false;
      visibleStep.step5 = false;
    }
    setStep(visibleStep);

    const visiblePages = Object.keys(visibleStep).filter((step) => visibleStep[step as keyof TStep]);
    // 最初のPage
    const firstPage = Number(visiblePages[0].split('step')[1]);
    setPage(firstPage);

    // ドットの数
    const pageLength = visiblePages.length;
    setTotalDots(pageLength);
  }, []);

  const stepKey = Object.keys(step);
  // 次のページ
  const onNext = (page: number) => {
    const nextStep = stepKey.find((stepNum, i) => page <= i && step[stepNum as keyof TStep]);
    const nextPage = Number(nextStep?.split('step')[1]);
    setPage(nextPage);
  }

  // 次の次ページ
  const onSkip = (page: number) => {
    const skipStep = stepKey.find((stepNum, i) => (page + 1) <= i && step[stepNum as keyof TStep]);
    const skipPage = Number(skipStep?.split('step')[1]);
    setPage(skipPage);
  }

  // 前のページ
  const onPrev = (page: number) => {
    const copy = cloneDeep(stepKey);
    const prevStep =  copy.reverse().find((stepNum, i) => (copy.length - page) < i && step[stepNum as keyof TStep]);
    const prevPage = Number(prevStep?.split('step')[1]);
    setPage(prevPage);
  }

  return (
    <section className="overflow-hidden">
      {page === 1 &&
        <OnboardingPage_1
          page={page}
          step={step}
          setPage={setPage}
          totalDots={totalDots}
          company={props.company}
          patchCompany={props.patchCompany}
          onNext={onNext}
          setIsDone={setIsDone}
        />
      }
      {page === 2 &&
        <OnboardingPage_2
          page={page}
          step={step}
          setPage={setPage}
          totalDots={totalDots}
          onNext={onNext}
          onSkip={onSkip}
          isDone={isDone}
          onClose={props.onClose}
        />
      }
      {page === 3 &&
        <OnboardingPage_3
          page={page}
          step={step}
          setPage={setPage}
          totalDots={totalDots}
          onNext={onNext}
          onPrev={onPrev}
          setIsDone={setIsDone}
        />
      }
      {page === 4 &&
        <OnboardingPage_4
          page={page}
          step={step}
          setPage={setPage}
          totalDots={totalDots}
          onNext={onNext}
          onSkip={onSkip}
          isDone={isDone}
          onClose={props.onClose}
        />
      }
      {page === 5 &&
        <OnboardingPage_5
          page={page}
          step={step}
          setPage={setPage}
          totalDots={totalDots}
          company={props.company}
          patchCompany={props.patchCompany}
          onNext={onNext}
          onPrev={onPrev}
        />
      }
      {page === 6 &&
        <OnboardingPage_6
          page={page}
          step={step}
          setPage={setPage}
          totalDots={totalDots}
          onClose={props.onClose}
        />
      }
    </section>
  );
};
