import React from 'react';
import { Link } from 'react-router-dom';

import { AdminPage } from '@/componentsDirect/Layout/Page';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';

export function DirectIndex(): React.ReactElement {
  return (
    <AdminPage header={<HeaderTitle title="Direct ページリスト" />}>
      <table className="my-24 index text-11_12">
        <tr>
          <th>ページ</th>
          <th>ステータス</th>
          <th>備考</th>
          <th>更新日</th>
        </tr>
        <tr className="">
          <td>
            <Link to="/direct/login">ログイン</Link>
          </td>
          <td>済</td>
          <td></td>
          <td>10 / 8</td>
        </tr>
        <tr>
          <td>
            <Link to="/direct/login/reminder">パスワード再設定開始</Link>
          </td>
          <td>済</td>
          <td></td>
          <td>10 / 8</td>
        </tr>
        <tr>
          <td>
            <Link to="/direct/login/entry">アカウント登録</Link>
          </td>
          <td>済</td>
          <td>登録時のメール本文はhanai待ちなので来週（10/11）以降</td>
          <td>10 / 8</td>
        </tr>
        <tr className="">
          <td>
            <Link to="/direct/users">社内ユーザー管理</Link>
          </td>
          <td>済</td>
          <td></td>
          <td>10 / 8</td>
        </tr>
        <tr>
          <td>
            <Link to="/direct/conditions">保存したの検索条件／MATCHBOXおすすめ検索条件</Link>
          </td>
          <td></td>
          <td>だいたいできてますが、仕様調整待ちな部分もあるので決まったら諸々反映</td>
          <td>10 / 8</td>
        </tr>
        <tr>
          <td>
            <Link to="/direct/users/C99999999">求職者詳細</Link>
          </td>
          <td>済</td>
          <td>
            ポートフォリオ関連の情報取得部分がTODO。検索結果から遷移ができないので表示するユーザー情報は固定
          </td>
          <td>10 / 8</td>
        </tr>
        <tr>
          <td>
            <Link to="/direct/users/C99999999/notes">求職者詳細（社内メモ）</Link>
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </table>
    </AdminPage>
  );
}
