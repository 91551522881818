import React, { useRef, useEffect, useState, useMemo } from 'react';
import BaseButton from '@/components/common/Button/BaseButton';
import '@/styles/common/Portfolio/ImagePage/image_general.scss';
import { useFormContext } from 'react-hook-form';
import { PortfolioPagePPageLayoutEnum } from '@/utils/api-client';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';

type Props = {
  src?: string;
  name?: string;
  caption?: string;
  switch?: boolean;
  onClick?(): void;
  onBack?: React.MouseEventHandler<HTMLDivElement>;
  onLoadImage?(result: File): void;
  editable: boolean;
  layout?: PortfolioPagePPageLayoutEnum;
};

export function ImageGeneral(props: Props): React.ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);
  const [src, setSrc] = useState<string>();
  const methods = useFormContext();
  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    accept: [".jpg", ".jpeg", ".png", ".gif"]
  });

  const onClick = () => {
    inputRef.current?.click();
  };
  useEffect(() => {
    if (acceptedFiles[0]) {
      props.onLoadImage && props.onLoadImage(acceptedFiles[0]);
    }
  }, [acceptedFiles[0]]);

  const sizeClass = useMemo<string>(() => {
    if (props.layout === PortfolioPagePPageLayoutEnum.StyleV2) return 'sm:h-215 sm:w-full';
    if (props.layout === PortfolioPagePPageLayoutEnum.Style4) return 'sm:h-217 sm:w-155';
    if (props.layout === PortfolioPagePPageLayoutEnum.Style8) return 'sm:h-93 sm:w-155';
    return 'sm:h-465 sm:w-155';
  }, [props.layout]);

  const contentIndex = useMemo<number | undefined>(() => {
    const index = props.name?.split('.')[1];
    return index ? parseInt(index) : undefined;
  }, [props]);

  useEffect(() => {
    const value = methods.getValues();
    if (contentIndex === undefined) return;
    setSrc(value.p_contents[contentIndex]?.f_thumbnail);
  }, [contentIndex]);

  useEffect(() => {
    const subscription = methods.watch((value) => {
      if (contentIndex === undefined) return;
      setSrc(value.p_contents[contentIndex]?.f_url);
    });
    return () => subscription.unsubscribe();
  }, [methods.watch, contentIndex]);

  return (
    <div className="h-full flex flex-col">
      <div
        className={`lg:bg-white md:bg-white lg:w-full md:w-full justify-center items-center lg:flex-1 md:flex-1`}
      >
        <section {...getRootProps({ className: cn('dropzone', 'h-full', 'm-auto', sizeClass) })}>
          <div className="mbx-image_general_inner sm:bg-white">
            {src ? (
              <span
                onClick={() => {
                  if (props.onClick) props.onClick();
                }}
                className="mbx-image_general_image mkt_mask_items"
                style={{ backgroundImage: `url("${src}")` }}
              />
            ) : (
              <button className="mbx-image_general_input" onClick={onClick}>
                <div className="mbx-icon mbx-icon-Large32_Plus" />
                <span className="my-10">
                  ファイルを選択
                  <span className="sm:hidden">
                    または
                    <br />
                    ドラッグ&ドロップ
                  </span>
                </span>
              </button>
            )}
          </div>
          <input
            className="mbx-image_general_input-file"
            type="file"
            ref={inputRef}
            name=""
            id=""
            {...getInputProps({multiple: false})}
          />
        </section>
        {(props.caption || props.switch || src) && props.editable && (
          <div className="flex justify-between mb-22 mt-18">
            <p className="text-caption_2 text-gray-700 lg:flex-1 md:flex-1 sm:w-1/2 sm:mt-34">{props.caption}</p>
            <div className="flex sm:flex-col items-end">
              {props.switch && (
                <BaseButton size="s" theme="link" iconName="Change" onClick={props.onBack}>
                  他の見せ方に変更
                </BaseButton>
              )}
              {src && (
                <BaseButton
                  className="ml-16 sm:ml-0 sm:mt-34"
                  size="s"
                  theme="link"
                  iconName="Placeholder_image"
                  onClick={open}
                >
                  他の画像を設定
                </BaseButton>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
