import cn from 'classnames';
import React from 'react';

import { MatchDegree } from './Menu/MatchDegree';
import { NaviInpageMenuList } from './MenuList';

type TNaviInpageMenuListProps = React.ComponentProps<typeof NaviInpageMenuList>;
type TMatchDegreeProps = React.ComponentProps<typeof MatchDegree>;

type TProps = {
  touchToggle?: boolean;
} & TMatchDegreeProps &
  TNaviInpageMenuListProps;

/**
 * menu
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=348%3A26803
 *
 * 使い方は MatchDegree と MenuList
 *
 * スマホの表示切り替えはtouchToggleを変更
 *
 */
export const NaviInpageMenu = ({
  touchToggle = false,
  type = 'large',
  progress = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  menu = [],
}: TProps): React.ReactElement => {
  return (
    <div
      className={cn('mbx-naviInpage-menu bg-red-100 p-24 text-center w-180 lg:w-240', {
        'is-open': touchToggle,
      })}
    >
      <MatchDegree type={type} progress={progress} />
      <div className={'pt-8'}>
        <NaviInpageMenuList menu={menu} />
      </div>
    </div>
  );
};
