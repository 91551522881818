import React, { VFC } from 'react';
import { useFormContext } from 'react-hook-form';

type TProps = {
  label: string;
  name?: string;
  value?: string | number;
  checked?: boolean;
  className?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=1%3A429
 */
export const FormCheckboxItem: VFC<TProps> = ({ label, name, value, className, onChange, checked }) => {
  const methods = useFormContext();
  const props = name? methods.register(name): {}
  return (
    <div className={className}>
      <label className="mbx-formCheckbox relative">
        <input type="checkbox" {...{...props, checked, value, onChange}}  />
        <span className="text-body_1 py-12 pl-32">
          {label}
          <i className="mbx-icon mbx-icon-Check"></i>
        </span>
      </label>
    </div>
  );
};
