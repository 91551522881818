import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import {
    ADMINApi, AdminCompany, AdminCompanyStatusEnum, ResponseError, Status
} from '@/utils/api-client';

export const CompanyAccount = (): React.ReactElement => {
  const [company, setCompany] = useState<AdminCompany>();
  const params: { company_id: string } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    getCompany();
  }, []);
  const getCompany = async () => {
    type TCompany = {
      company: AdminCompany;
    };
    const res = await new ADMINApi().getAdminCompany(params.company_id);
    const data = res.data as TCompany & Status;
    setCompany(data.company);
  };

  const patchCompany = async (status: AdminCompanyStatusEnum) => {
    const copy = cloneDeep(company);
    if (copy) {
      copy.status = status;
      dispatch(toggleLoading(true));
      try {
        await new ADMINApi().patchAdminCompany(params.company_id, copy);
        await getCompany();
      } catch (error) {
        const e = error as AxiosError<ResponseError>;
        if (e.response) dispatch(notificationError(e.response.data.error_message));
      } finally {
        dispatch(toggleLoading(false));
      }
    }
  };

  return (
    <section className="w-624 m-auto">
      {company?.status !== 'before_delete' ? (
        <div>
          <p className="mbx-typography--subhead_2 mt-48">停止・退会</p>
          <div className="flex justify-between mt-8">
            <div className="w-304">
              {company?.status === 'active' && (
                <BaseButton
                  theme="secondary"
                  size="s"
                  className="w-full"
                  onClick={() => patchCompany('inactive' as AdminCompanyStatusEnum)}
                >
                  このアカウントを停止
                </BaseButton>
              )}
              {company?.status === 'inactive' && (
                <BaseButton
                  theme="secondary"
                  size="s"
                  className="w-full"
                  onClick={() => patchCompany('active' as AdminCompanyStatusEnum)}
                >
                  このアカウントを復活
                </BaseButton>
              )}
            </div>
            <div className="w-304">
              <BaseButton
                theme="secondary"
                size="s"
                className="w-full"
                onClick={() => patchCompany('before_delete' as AdminCompanyStatusEnum)}
              >
                このアカウントを退会
              </BaseButton>
            </div>
          </div>
        </div>
      ) : (
        <p className="text-center mbx-typography--body_1 mt-48">アカウント削除が完了しました。</p>
      )}
    </section>
  );
};
