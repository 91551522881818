import React, { useMemo } from 'react';
import cn from 'classnames';
import { OfferStatusEnum } from '@/utils/api-client';

type Props = {
  status: OfferStatusEnum;
};

export const CandidatesNotification = (props: Props): React.ReactElement => {
  const status = useMemo(() => {
    if (props.status === 2) {
      return {
        background: 'bg-attention-200',
        color: 'text-attention-700',
        text: 'マイナビワークスヘ相談を依頼中です。マイナビワークスからの回答をお待ちください。',
      };
    }
    if (props.status === 3 || props.status === 6 || props.status === 7) {
      return {
        background: 'bg-red-200',
        color: 'text-red-700',
        text: 'この求職者へ一次面接オファー中です。回答をお待ち下さい。',
      };
    }
    if (props.status === 8 || props.status === 9 || props.status === 11) {
      return {
        background: 'bg-blue-200',
        color: 'text-blue-700',
        text: 'この求職者へカジュアル面談オファー中です。回答をお待ちください。',
      };
    }
    if (props.status === 4 || props.status === 5 || props.status === 10) {
      return {
        background: 'bg-gray-200',
        color: 'text-gray-600',
        text: 'オファーを見送りました。',
      };
    }
    return false;
  }, [props.status]);
  return (
    <section>
      {status && (
        <section
          className={cn('flex', 'items-center', 'justify-center', 'h-56', status?.background)}
        >
          <p className={cn('mbx-typography--subhead_2', status?.color)}>{status?.text}</p>
        </section>
      )}
    </section>
  );
};
