import { AdminUserResponse } from '@/utils/api-client';

export type State = {
  isLogin: boolean | undefined;
  userInfo: AdminUserResponse | null;
};

export const initialState: State = {
  isLogin: undefined,
  userInfo: null,
};
