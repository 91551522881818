import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '@/reduxAdmin';
import { setLogin, setUserInfo } from '@/reduxAdmin/modules/adminAuth';
import { getAdminMasterCp2 } from '@/reduxAdmin/modules/adminMaster';
import { notificationError } from '@/reduxAdmin/modules/utils';
import { ADMINApi, ResponseError } from '@/utils/api-client/';

type TProps = {
  children: React.ReactElement;
};

/**
 * 管理者権限のチェック
 */
export const AuthAdmin = ({ children }: TProps): React.ReactElement => {
  const API = new ADMINApi();
  const dispatch = useDispatch();
  const isLogin = useSelector((state: RootState) => state.admin_auth.isLogin);
  // ログイン情報チェック
  useEffect(() => {
    (async () => {
      if (isLogin === undefined) {
        try {
          const userIsLogin = await API.getAdminIsLogin();
          dispatch(setLogin(userIsLogin.data.is_login));
        } catch (e) {
          const error = e as AxiosError<ResponseError>;
          if (error.response) dispatch(notificationError(error.response.data.error_message));
        }
      }
    })();
  }, [dispatch]);

  // undefの場合以外のisLoginの値が切り替わるたびに処理する
  useEffect(() => {
    if (isLogin === undefined || isLogin === false) return;
    dispatch(getAdminMasterCp2());
    getUser();
  }, [isLogin]);

  // User情報取得
  const getUser = async () => {
    API.getAdminUser()
      .then((user) => {
        dispatch(setUserInfo(user.data));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) console.log(error.response.data.error_message);
      });
  };

  if (isLogin === undefined) return <></>;
  return children;
};
