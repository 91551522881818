import { PayloadAction } from '@reduxjs/toolkit';

import { State } from './state';

export const reducers = {
  toggleLoading: (state: State, action: PayloadAction<boolean>): State => {
    // forceがtrueの場合はloadingをfalseにしない
    return { ...state, loading: state.forceLoading ? true : action.payload };
  },
  forceLoadingOn: (state: State): State => {
    return { ...state, forceLoading: true };
  },
  forceLoadingOff: (state: State): State => {
    return { ...state, forceLoading: false };
  },
  notificationError: (
    state: State,
    action: PayloadAction<string | Array<string> | null>
  ): State => {
    if (Array.isArray(action.payload)) {
      return { ...state, errorMessage: action.payload.join('\n') };
    } else {
      return { ...state, errorMessage: action.payload };
    }
  },
  notificationSuccess: (state: State, action: PayloadAction<string | null>): State => {
    return { ...state, successMessage: action.payload };
  },
  setPathAfterLogin: (state: State, action: PayloadAction<string | undefined>): State => {
    return { ...state, pathAfterLogin: action.payload };
  },
};
