import cn from 'classnames';
import React from 'react';

import { Button } from '@/components/common/Portfolio/ImagePage/Button';

export const TypeSelect = ({
  size,
  onClick,
  onClickLink,
  caption,
  className,
}: {
  className?: string;
  onClick(): void;
  size: string | undefined;
  onClickLink(e: React.MouseEvent<HTMLButtonElement>): void;
  caption?: string;
}): React.ReactElement => {
  return (
    <div className={cn('mbx-type_select_inner', className)}>
      <h1>作品タイプの選択</h1>
      <Button type="image" onClick={onClick}>
        画像ファイルを添付
      </Button>
      <small>{size}</small>

      <Button type="url" onClick={onClickLink}>
        リンクを登録
      </Button>
      {caption && <small>{caption}</small>}
    </div>
  );
};
