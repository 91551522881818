import dayjs from 'dayjs';
import React, { useEffect, useMemo } from 'react';
import cn from 'classnames';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import {
  Career,
  Portfolio,
  PortfolioItem,
  Qualification,
  QualificationQTypeEnum,
} from '@/utils/api-client';
import { extractAnswersFromPortfolioQuestions } from '@/utils/extractAnswersFromPortfolioQuestions';
import { getOptionChildrenFromValue } from '@/utils/optionValueToChildren';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';

export const Detail: React.VFC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userInfo: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  careerHistory: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  portfolio: any;
  searchPortfolio: Portfolio | undefined;
  getUser(): void;
}> = ({ userInfo, careerHistory, portfolio, searchPortfolio, getUser }) => {
  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const pfImages = useMemo(() => {
    const items = portfolio?.items;
    if (!items) return new Array(5).fill(null).map(() => '/images/image-placeholder.svg');
    return new Array(Math.max(Array.from(items).length, 5)).fill(null).map((_, index) => {
      const item = Array.from(items)[index] as PortfolioItem;
      if (item?.i_image.f_thumbnail) {
        return item.i_image.f_thumbnail;
      } else {
        return '/images/image-placeholder.svg';
      }
    });
  }, [portfolio]);

  const finalEducation = useMemo<string>(() => {
    if (!careerHistory) return '';
    if (careerHistory.educations.length === 0) return '';
    const educations = [...careerHistory.educations];
    educations.sort((a, b) => {
      if (a.e_graduate_date && b.e_graduate_date) {
        const dateA = new Date(a.e_graduate_date);
        const dateB = new Date(b.e_graduate_date);

        if (dateA.getTime() < dateB.getTime()) {
          return 1;
        }
        if (dateA.getTime() > dateB.getTime()) {
          return -1;
        }

        return 0;
      }

      return 0;
    });
    return educations[0].e_school_name;
  }, [careerHistory]);

  const charge = useMemo(() => {
    return extractAnswersFromPortfolioQuestions(portfolio, 'b_v2_2');
  }, [portfolio]);

  const tools = useMemo(() => {
    return extractAnswersFromPortfolioQuestions(portfolio, 'b_v2_7');
  }, [portfolio]);

  const experience = useMemo(() => {
    return extractAnswersFromPortfolioQuestions(portfolio, 'b_v2_8');
    return ['1'];
  }, [portfolio]);

  const pfLength = useMemo(() => {
    if (!searchPortfolio?.items) {
      return {
        all: 0,
        visible: 0,
      };
    }
    return {
      all: Array.from(searchPortfolio.items).length,
      published: portfolio.items.length,
    };
  }, [searchPortfolio, portfolio]);

  const matchboxId = useMemo(() => location.pathname.split('/')[3], [location]);

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    if (!matchboxId) return;
    (async () => {
      await getUser();
    })();
  }, [matchboxId]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <section className="my-24">
      <table>
        <tbody>
          <tr>
            <th className="mbx-admin-form-title">ポートフォリオ</th>
            <td>
              <div className="flex relative">
                <dl className="">
                  <dt>更新日</dt>
                  <dd>
                    {userInfo?.last_update_portfolio
                      ? dayjs(userInfo?.last_update_portfolio).format('YYYY/M/D')
                      : '未登録'}
                  </dd>
                  <dt>作品数</dt>
                  <dd>{pfLength.published}作品</dd>
                </dl>
                {/* 0作品の場合はリンクを表示しない */}
                {pfLength?.published !== 0 && pfLength.published && (
                  <div className="w-224 absolute right-0">
                    <BaseButton
                      className="w-full"
                      theme="secondary"
                      size="s"
                      onClick={() => {
                        window.open(`/direct/portfolio/${matchboxId}`);
                      }}
                    >
                      詳細を見る
                    </BaseButton>
                  </div>
                )}
              </div>
              {pfLength?.published !== 0 && pfLength.published && (
                <div className="grid grid-cols-5 gap-x-12 gap-y-12 my-12">
                  {pfImages.map((image: string, index: number) => (
                    <div className="w-144 h-81" key={index}>
                      <img src={image} className="h-full object-cover mkt_mask_items" />
                    </div>
                  ))}
                </div>
              )}
            </td>
          </tr>
          <tr>
            <th className="mbx-admin-form-title">
              自作の
              <br />
              ポートフォリオ
            </th>
            <td>
              <div className="relative">
                <dl className={cn('flex', { 'h-48': userInfo?.own_portfolio.url })}>
                  <dt>更新日</dt>
                  <dd>
                    {userInfo?.own_portfolio.updated_at
                      ? dayjs(userInfo?.own_portfolio.updated_at).format('YYYY/M/D')
                      : '未登録'}
                  </dd>
                </dl>
                <dl className="flex">
                  <dt>補足情報</dt>
                  <dd className="whitespace-pre-wrap">
                    {userInfo?.own_portfolio.supplementary_info
                      ? userInfo?.own_portfolio.supplementary_info
                      : ''}
                  </dd>
                </dl>
                {/* 登録されていない場合はリンクを表示しない */}
                {userInfo?.own_portfolio.url && (
                  <div className="w-224 absolute top-0 right-0">
                    <BaseButton
                      className="w-full"
                      theme="secondary"
                      size="s"
                      href={userInfo?.own_portfolio.url}
                    >
                      詳細を見る
                    </BaseButton>
                  </div>
                )}
              </div>
              {userInfo?.own_portfolio.url && (
                <div className="grid grid-cols-5 gap-x-12 gap-y-12 my-12">
                  <div className="w-144 h-81">
                    <img
                      src="/images/original_portfolio.svg"
                      className="h-full object-cover mkt_mask_items"
                      style={{
                        imageRendering: '-webkit-optimize-contrast',
                        backfaceVisibility: 'hidden',
                      }}
                    />
                  </div>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <th className="mbx-admin-form-title">職務経歴</th>
            <td>
              <div
                className={cn('flex relative', { 'h-48': userInfo?.last_update_career_history })}
              >
                <dl>
                  <dt>更新日</dt>
                  <dd>
                    {userInfo?.last_update_career_history
                      ? dayjs(userInfo?.last_update_career_history).format('YYYY/M/D')
                      : '未登録'}
                  </dd>
                </dl>
                {/* 更新日がない場合はリンクを表示しない */}
                {userInfo?.last_update_career_history && (
                  <div className="w-224 absolute right-0">
                    <BaseButton
                      className="w-full"
                      theme="secondary"
                      size="s"
                      onClick={() => {
                        window.open(`/direct/cv/${matchboxId}`);
                      }}
                    >
                      詳細を見る
                    </BaseButton>
                  </div>
                )}
              </div>
              <dl>
                <dt>概要</dt>
                <dd className="mkt_mask_items">
                  {userInfo?.mc?.mc_description && userInfo.mc.mc_description}
                </dd>
                <dt>職歴</dt>
                <dd className="mkt_mask_items">
                  {careerHistory?.careers?.map((c: Career, index: number) => {
                    return (
                      <div key={index}>
                        {c.c_company_name && c.c_visible && (
                          <div>
                            {c.c_company_name && c.c_company_name.length !== 0 && (
                              <span>{c.c_company_name}/</span>
                            )}
                            {c.c_section && c.c_section.length !== 0 && <span>{c.c_section}/</span>}
                            {c.c_post && c.c_post.length !== 0 && <span>{c.c_post}/</span>}
                            {dayjs(c.c_join_date).format('YYYY年M月')}〜
                            {c.c_leave_flag ? (
                              <span>{dayjs(c.c_leave_date).format('YYYY年M月')}</span>
                            ) : (
                              <span>現在</span>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </dd>
                <dt>経験社数</dt>
                <dd className="mkt_mask_items">
                  {getOptionChildrenFromValue('companies_count', userInfo?.mc.mc_companies_count)}
                </dd>
                <dt>マネジメント経験</dt>
                <dd className="mkt_mask_items">
                  {getOptionChildrenFromValue(
                    'maximum_management_count',
                    userInfo?.mc.mc_maximum_management_count
                  )}
                </dd>
                <dt>担当内容</dt>
                <dd className="mkt_mask_items">{charge.join(' / ')}</dd>
                <dt>経験</dt>
                <dd className="mkt_mask_items">{experience.join(' / ')}</dd>
                <dt>使用言語・ツール</dt>
                <dd className="mkt_mask_items">{tools.join(' / ')}</dd>
              </dl>
            </td>
          </tr>
          <tr>
            <th className="mbx-admin-form-title">資格と学歴</th>
            <td>
              <dl>
                <dt>資格・免許</dt>
                <dd className="mkt_mask_items">
                  {careerHistory?.qualifications?.map(
                    (q: Qualification, index: number) =>
                      q.q_visible && (
                        <div key={index}>
                          {q.q_type === QualificationQTypeEnum.Text ? (
                            <>
                              {dayjs(q.q_date).format('YYYY年M月')} {q.q_title}
                            </>
                          ) : (
                            (parseInt(q.q_score) > 0 || isNaN(Number(q.q_score))) && (
                              <>
                                {q.q_type === 'toeic' && (
                                  <>
                                    {dayjs(q.q_date).format('YYYY年M月')} {'TOEIC'} {q.q_score}
                                    点取得
                                  </>
                                )}
                                {q.q_type === 'toefl' && (
                                  <>
                                    {dayjs(q.q_date).format('YYYY年M月')} {'TOEFL'} {q.q_score}
                                    点取得
                                  </>
                                )}
                              </>
                            )
                          )}
                        </div>
                      )
                  )}
                </dd>
                <dt>英語力</dt>
                <dd className="mkt_mask_items">
                  {getOptionChildrenFromValue('english_skills', userInfo?.mc.mc_english_skills)}
                </dd>
                <dt>最終学歴・学校</dt>
                <dd className="mkt_mask_items">
                  <span>
                    {getOptionChildrenFromValue('final_education', userInfo?.mc.mc_final_education)}
                    {finalEducation && `/ ${finalEducation}`}
                  </span>
                </dd>
              </dl>
            </td>
          </tr>
          <tr>
            <th className="mbx-admin-form-title">希望条件</th>
            <td>
              <dl>
                <dt>希望職種</dt>
                <dd>
                  {userInfo?.mc && userInfo?.mc.mc_preferred_job_type
                    ? userInfo?.mc.mc_preferred_job_type
                        .map((jobType: number) =>
                          getOptionChildrenFromValue('preferred_job_type', jobType)
                        )
                        .join(' / ')
                    : []
                        .map((jobType: number) =>
                          getOptionChildrenFromValue('preferred_job_type', jobType)
                        )
                        .join(' / ')}
                </dd>
                <dt>希望業種</dt>
                <dd>
                  {getOptionChildrenFromValue(
                    'business_type',
                    userInfo?.mc.mc_preferred_business_type
                  )}
                </dd>
                <dt>希望勤務地</dt>
                <dd>
                  {userInfo?.mc.mc_preferred_prefecture
                    ? userInfo?.mc.mc_preferred_prefecture
                        .map((prefecture: number) =>
                          getOptionChildrenFromValue('prefecture', prefecture)
                        )
                        .join(' / ')
                    : []
                        .map((prefecture: number) =>
                          getOptionChildrenFromValue('prefecture', prefecture)
                        )
                        .join(' / ')}
                </dd>
                <dt>希望年収</dt>
                <dd>
                  {getOptionChildrenFromValue(
                    'yearly_income',
                    userInfo?.mc.mc_preferred_yearly_income
                  )}
                </dd>
                <dt>希望の雇用契約・働き方</dt>
                <dd className="mkt_mask_items">
                  {userInfo?.mc && userInfo?.mc.mc_preferred_employment_status
                    ? userInfo?.mc.mc_preferred_employment_status
                        .filter((filterEmploymentStatus: number) =>
                          FORM_OPTIONS.direct_employment_status.some(
                            (status) => Number(status.value) === filterEmploymentStatus
                          )
                        )
                        .map((employmentStatus: number) =>
                          getOptionChildrenFromValue('employment_status', employmentStatus)
                        )
                        .join(' / ')
                    : []
                        .filter((filterEmploymentStatus: number) =>
                          FORM_OPTIONS.direct_employment_status.some(
                            (status) => Number(status.value) === filterEmploymentStatus
                          )
                        )
                        .map((employmentStatus: number) =>
                          getOptionChildrenFromValue('employment_status', employmentStatus)
                        )
                        .join(' / ')}
                </dd>
                <dt>こだわりの条件</dt>
                <dd className="mkt_mask_items">
                  {userInfo?.mc && userInfo?.mc.mc_conditions
                    ? userInfo?.mc.mc_conditions
                        .map((conditions: number) =>
                          getOptionChildrenFromValue('conditions', conditions)
                        )
                        .join(' / ')
                    : []
                        .map((conditions: number) =>
                          getOptionChildrenFromValue('conditions', conditions)
                        )
                        .join(' / ')}
                </dd>
                <dt>その他の希望条件</dt>
                <dd className="mkt_mask_items">
                  {userInfo?.mc && userInfo?.mc.mc_preferred_conditions}
                </dd>
              </dl>
            </td>
          </tr>
          <tr>
            <th className="mbx-admin-form-title">補足情報</th>
            <td>
              <dl>
                <dt>
                  マイナビワークス
                  <br />
                  からのコメント
                </dt>
                <dd>{userInfo?.comment}</dd>
              </dl>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};
