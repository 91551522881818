import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { pageList } from '@/definitionDirect/PAGE_LIST';
import { RootState } from '@/reduxAdmin/';
import { CandidateData, CompanyId, DeviceCategory, SearchCondition } from '@/types/DataLayerType';
import {
  DirectApi,
  DirectCompanyResponse,
  DirectSearchConditionModel,
  DirectUserResponse,
  Pagination,
} from '@/utils/api-client/api';
import {
  getDirectOptionChildrenFromValue,
  getOptionChildrenFromValue,
} from '@/utils/optionValueToChildren';
import { CandidateDataFromOffer } from '@/utils/utils';

type EventType =
  | 'pageView'
  | 'loggedIn'
  | 'logout'
  | 'searchResult'
  | 'saveConditions'
  | 'deleteConditions'
  | 'resetConditions'
  | 'candidatePickup'
  | 'postOpinion'
  | 'candidateSelection'
  | 'pwSetting'
  | 'deleteUser'
  | 'deleteUserGroup'
  | 'deletePosition'
  | 'accountRegistration'
  | 'profileUpdate'
  | 'emailSettings'
  | 'errorMessage';

type ActionType =
  | 'page_view'
  | 'logged_in'
  | 'logout'
  | 'search_result'
  | 'save_conditions'
  | 'delete_conditions'
  | 'reset_conditions'
  | 'candidate_pickup'
  | 'post_opinion'
  | 'candidate_selection'
  | 'pw_setting'
  | 'delete_user'
  | 'delete_user-group'
  | 'delete_position'
  | 'account_registration'
  | 'profile_update'
  | 'email_settings'
  | 'error_message';

export type DataLayerType = {
  userType?: 'business';
  url?: string;
  event?: EventType;
  userId?: string;
  virtualPageName?: string;
  pageCategory?: string;
  pageTitle?: string;
  deviceCategory?: DeviceCategory;
  actionType?: ActionType;
  actionName?: string;
  pagination?: string; // '{[MKT_イベント一覧_toC]シート参照}',
  errorStatus?: string;
  cNarrowDown?: string;
  cSortBy?: string;
  matchingDegree?: string;
  candidates?: CandidateData[] | CandidateDataFromOffer[];
} & SearchCondition &
  CompanyId;

export const conditionFromQuery = (
  q: Pagination | undefined,
  conditions: DirectSearchConditionModel
): SearchCondition => {
  const condition: SearchCondition = {};

  condition.scKeyword = conditions?.keyword?.length ? conditions?.keyword?.join(',') : 'N/A';
  condition.scOfferStatus = conditions?.offer_status?.length
    ? conditions?.offer_status
        ?.map((status) => getDirectOptionChildrenFromValue('directSearchStatus', status))
        .join('|')
    : 'N/A';
  condition.scRate = conditions?.rate?.length
    ? conditions?.rate
        ?.map((rate) => getOptionChildrenFromValue('offer_rate', rate))
        .filter((rate) => !!rate)
        .join('|')
    : 'N/A';
  condition.scOfferUpdate = conditions?.offer_update_at?.length
    ? conditions?.offer_update_at?.join('-')
    : 'N/A';
  condition.scMachboxId = conditions?.matchbox_id ? conditions?.matchbox_id : 'N/A';
  condition.scRegistrationDate = conditions?.registration_date
    ? getDirectOptionChildrenFromValue('last_update', conditions?.registration_date)
    : 'N/A';
  condition.scLastLogin = conditions?.last_login
    ? getDirectOptionChildrenFromValue('last_update', conditions?.last_login)
    : 'N/A';
  condition.scPrefecture = conditions?.prefecture
    ? getOptionChildrenFromValue('prefecture', conditions?.prefecture)
    : 'N/A';
  condition.scGender =
    conditions?.gender !== undefined
      ? getOptionChildrenFromValue('gender', conditions?.gender)
      : 'N/A';
  condition.scAge = conditions?.age?.length ? conditions?.age?.join('-') : 'N/A';
  condition.scExperiencedJob = conditions?.mc_experienced_job?.filter(
    (expJob) => expJob.job !== undefined
  ).length
    ? conditions?.mc_experienced_job
        ?.filter((expJob) => expJob.job !== undefined)
        .map(
          (expJob) =>
            `${getOptionChildrenFromValue('preferred_job_type', expJob.job)}::${expJob?.job_year
              ?.map((jobYear) => getOptionChildrenFromValue('experienced_year', jobYear))
              .join('|')}`
        )
        .join(',')
    : 'N/A';
  condition.scPreferredChangeDate = conditions?.mc_preferred_change_date?.length
    ? conditions?.mc_preferred_change_date
        ?.map((changeDate) => getOptionChildrenFromValue('change_date', changeDate))
        .join('|')
    : 'N/A';
  condition.scSituation = conditions?.mc_situation
    ? getOptionChildrenFromValue('situation', conditions?.mc_situation)
    : 'N/A';
  condition.scCareerHistoryCurrentCompanies = conditions?.career_history_current_companies || 'N/A';
  condition.scYearlyIncome = conditions?.mc_yearly_income?.length
    ? conditions?.mc_yearly_income
        ?.map((yearlyIncome) => getOptionChildrenFromValue('yearly_income', yearlyIncome))
        .join('|')
    : 'N/A';
  condition.scCareerHistoryCurrentSection = conditions?.career_history_current_section || 'N/A';
  condition.scCareerHistoryCurrentPost = conditions?.career_history_current_post || 'N/A';
  condition.scPortfolioItemCount =
    conditions?.portfolio_item_count !== undefined ? `${conditions?.portfolio_item_count}` : 'N/A';
  condition.scLastUpdatePortfolio = conditions?.last_update_portfolio
    ? getDirectOptionChildrenFromValue('last_update', conditions?.last_update_portfolio)
    : 'N/A';
  condition.scLastUpdateCareerHistory = conditions?.last_update_career_history
    ? getDirectOptionChildrenFromValue('last_update', conditions?.last_update_career_history)
    : 'N/A';
  condition.scCareerHistoryAmount = conditions?.career_history_amount
    ? `${conditions?.career_history_amount}`
    : 'N/A';
  condition.scCareerHistoryCompanies = conditions?.career_history_companies?.length
    ? conditions.career_history_companies.join(',')
    : 'N/A';
  condition.scCareerHistorySection = conditions?.career_history_section?.length
    ? conditions?.career_history_section?.join(',')
    : 'N/A';
  condition.scCareerHistoryPost = conditions?.career_history_post?.length
    ? conditions?.career_history_post?.join(',')
    : 'N/A';
  condition.scMaximumManagementCount = conditions?.mc_maximum_management_count?.length
    ? conditions?.mc_maximum_management_count
        ?.map((management) => getOptionChildrenFromValue('maximum_management_count', management))
        .join('|')
    : 'N/A';
  condition.scCareerHistoryAssigned = conditions?.qa_answer?.length
    ? conditions?.qa_answer.join(',')
    : 'N/A';
  condition.scCareerHistoryExperiences = conditions?.career_history_experiences?.length
    ? conditions?.career_history_experiences?.join(',')
    : 'N/A';
  condition.scCareerHistoryTools = conditions?.career_history_tools?.length
    ? conditions?.career_history_tools?.join(',')
    : 'N/A';
  condition.scLicentiate = conditions?.q_title?.length ? conditions?.q_title.join(',') : 'N/A';
  condition.scToeicScore = conditions?.q_toeic_score ? `${conditions?.q_toeic_score}` : 'N/A';
  condition.scToeflScore = conditions?.q_toefl_score ? `${conditions?.q_toefl_score}` : 'N/A';
  condition.scEnglishSkills = conditions?.mc_english_skills
    ? getOptionChildrenFromValue('english_skills', conditions?.mc_english_skills)
    : 'N/A';
  condition.scFinalEducation = conditions?.final_education?.length
    ? conditions?.final_education
        ?.map((education) => getOptionChildrenFromValue('final_education', education))
        .join('|')
    : 'N/A';
  condition.scSchoolName = conditions?.e_school_name?.length
    ? conditions?.e_school_name?.join(',')
    : 'N/A';
  condition.scPreferredJobType = conditions?.mc_preferred_job_type?.length
    ? conditions?.mc_preferred_job_type
        ?.map((job) => getOptionChildrenFromValue('preferred_job_type', job))
        .join(',')
    : 'N/A';
  condition.scPreferredBusinessType = conditions?.mc_preferred_business_type?.length
    ? conditions?.mc_preferred_business_type
        ?.map((business) => getOptionChildrenFromValue('business_type', business))
        .join(',')
    : 'N/A';
  condition.scPreferredPrefecture = conditions?.mc_preferred_prefecture?.length
    ? conditions?.mc_preferred_prefecture
        ?.map((preferred_prefecture) =>
          getOptionChildrenFromValue('prefecture', preferred_prefecture)
        )
        .join(',')
    : 'N/A';
  condition.scPreferredYearlyIncome = conditions?.mc_preferred_yearly_income?.length
    ? conditions?.mc_preferred_yearly_income
        ?.map((yearlyIncome) => getOptionChildrenFromValue('yearly_income', yearlyIncome))
        .join('|')
    : 'N/A';
  condition.scPreferredEmploymentStatus = conditions?.mc_preferred_employment_status?.length
    ? conditions?.mc_preferred_employment_status
        ?.map((employment_status) =>
          getOptionChildrenFromValue('employment_status', employment_status)
        )
        .join(',')
    : 'N/A';
  condition.scConditions = conditions?.mc_conditions?.length
    ? conditions?.mc_conditions
        ?.map((conditions) => getOptionChildrenFromValue('conditions', conditions))
        .join(',')
    : 'N/A';
  if (q) {
    condition.srSortBy =
      FORM_OPTIONS.search_users_sort_type.find(
        (type) => type.value === `${q.sort_by}-${q.sort_order}`
      )?.children || 'N/A';
  }
  return condition;
};

const pickUpPage = (virtualPageName: string | undefined) => {
  const candidate = pageList.filter((page) => {
    if (typeof page.path === 'string') {
      return page.path === window.location.pathname + window.location.search;
    } else {
      return (page.path as RegExp).test(window.location.pathname + window.location.search);
    }
  });
  if (candidate.length === 1) {
    return candidate[0];
  } else if (candidate.length > 1) {
    const vPage = virtualPageName || '';
    const filteredCandidate = candidate.filter((page) => page.virtualPage === vPage);
    if (filteredCandidate.length === 1) {
      return filteredCandidate[0];
    } else if (filteredCandidate.length === 0) {
      return candidate[0];
    }
  } else {
    return undefined;
  }
};

const pageTitleFromPathName = (virtualPageName: string | undefined): string => {
  const page = pickUpPage(virtualPageName);
  return page?.title || '';
};

const pageCategoryFromPathName = (virtualPageName: string | undefined): string => {
  const page = pickUpPage(virtualPageName);
  return page?.category || '';
};

let onReady: (result: DataLayerType) => void, onError: any;
let pushIsReady: Promise<DataLayerType>;

const companyToId = (response: DirectCompanyResponse | null): CompanyId => {
  if (!response) {
    return {
      ciId: '',
      ciName: '',
      ciNameKana: '',
      ciRegistrationDate: '',
      ciLastLogin: '',
    };
  }
  return {
    ciId: `${response.company?.id}`,
    ciName: response.company?.name,
    ciNameKana: response.company?.kana,
    ciRegistrationDate: response.company?.registration_date,
    ciLastLogin: response.company?.last_login,
  };
};

const pushDataLayer = (additionalData: DataLayerType) => {
  const fixedData: DataLayerType = {
    userType: 'business',
    url: window.location.href,
    pageTitle: pageTitleFromPathName(additionalData.virtualPageName),
    pageCategory: pageCategoryFromPathName(additionalData.virtualPageName),
    virtualPageName: 'N/A',
    errorStatus: 'N/A',
    pagination: 'N/A',

    //   'event': '{[MKT_イベント一覧_toC]シート参照}',
    //   'actionType': '{[MKT_イベント一覧_toC]シート参照}',
    //   'actionName': '{[MKT_イベント一覧_toC]シート参照}',
  };

  dataLayer.push({ ...fixedData, ...additionalData });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDataLayer() {
  const isAuth: boolean | undefined = useSelector((state: RootState) => state.direct_auth.isLogin);
  const isLoggedIn: boolean = useSelector((state: RootState) => state.direct_auth.isLoggedIn);
  const isLoggedOut: boolean = useSelector((state: RootState) => state.direct_auth.isLoggedOut);
  const user: DirectUserResponse | null = useSelector(
    (state: RootState) => state.direct_auth.userInfo
  );
  const [company, setCompany] = useState<DirectCompanyResponse | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [result, setResult] = useState<DataLayerType>();

  const unResolve = () => {
    pushIsReady = new Promise<DataLayerType>((resolve, reject) => {
      onReady = resolve;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError = reject;
    });
  };

  useEffect(() => {
    // リロードしたとき
    unResolve();
  }, []);

  useEffect(() => {
    if (isLoggedOut) {
      // ログアウト状態に変わったとき
      setCompany(null);
      unResolve();
    }
  }, [isLoggedOut]);

  useEffect(() => {
    if (isLoggedIn) {
      // ログイン状態に変わったとき
      unResolve();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    (async () => {
      if (user && user?.user_info.matchbox_id && isAuth) {
        const res1 = await new DirectApi().getDirectCompany();
        setCompany(res1.data);
      }
    })();
  }, [user, isAuth]);

  const userData = useMemo<DataLayerType>(
    () => ({
      userId: user?.user_info.matchbox_id,
      deviceCategory: 'desktop',
    }),
    [user]
  );

  useEffect(() => {
    let result: DataLayerType = {};
    if (isAuth === undefined) return; //ログイン状態がわからないときはpromiseを解決しない

    if (isAuth) {
      if (!user || !user?.user_info.matchbox_id) return;
      if (user?.user_info.user_type === 'direct_manager' && !company) return;
    }

    if (!isAuth) {
      result = {
        userId: '',
        ciId: '',
      };
    } else {
      result = {
        ...userData,
        ...result,
        ...companyToId(company),
      };
    }

    setResult((prev) => {
      if (
        prev?.userId !== result.userId ||
        prev?.ciId !== result.ciId // 企業id
      ) {
        //結果が違う（更新された）場合のみ解決する
        onReady(result);
      }
      return result;
    });
  }, [user, isAuth, company]);

  const push = (additionalData: DataLayerType) => {
    (async () => {
      const basicInfo = await pushIsReady;
      pushDataLayer({ ...basicInfo, ...additionalData });
    })();
  };

  return { push };
}
