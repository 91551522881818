import '@/styles/common/Direct/MyNaviSelectForm.scss';

import React from 'react';
import { AxiosError } from 'axios';
import { OnboardingPageWrapper } from './PageWrapper';
import { FormProvider, useForm, UseFormProps } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { PageDots } from './PageDots';
import {
    notificationError, toggleLoading
} from '@/redux/index';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormContainerTextArea,
  FormContainerTextfield,
  FormLabel,
  FormLayoutFieldset,
} from '@/componentsDirect/Parts/Form/';
import { ButtonTextLink } from '@/componentsDirect/Parts/Button/TextLink';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
import { useOfferPositions } from '@/hooksDirect/useOfferPositions';
import { PLACEHOLDER } from '@/definitionDirect/PLACEHOLDER';
import { TEMPLATE } from '@/definitionDirect/TEMPLATE';
import {
  DirectCompanyModel,
  ResponseError
} from '@/utils/api-client';

type TStep = {
  step1: boolean,
  step2: boolean,
  step3: boolean,
  step4: boolean,
  step5: boolean,
  step6: boolean,
};

type Props = {
  page: number;
  step: TStep;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalDots: number;
  onNext: (number: number) => void;
  onPrev: (number: number) => void;
  setIsDone: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OnboardingPage_3 = (props: Props): React.ReactElement => {
  const { postOfferPosition } = useOfferPositions();
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    name: YUP_VALIDATION.required,
    body: YUP_VALIDATION.required,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const useFormProps: UseFormProps<any> = {
    mode: 'onBlur',
    resolver: yupResolver(schema),
  };

  const methods = useForm(useFormProps);

  const { handleSubmit, setValue } = methods;

  // 送信処理
  const onSubmit = async (data: DirectCompanyModel) => {
    if (!data) return;

    dispatch(toggleLoading(true));
    try {
      await postOfferPosition(data);
    } catch (e) {
      const error = e as AxiosError<ResponseError>;
      if (error.response) dispatch(notificationError(error.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
      props.setIsDone(true);
      props.onNext(3);
    }
  };

  return (
    <DataLayerPushContainer
      data={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'modal_open',
        virtualPageName: 'on-boarding_step_3'
      }}
    >
    <div>
      <OnboardingPageWrapper
        description={
          <span>ポジション情報</span>
        }
      >
        <div>
          <p className="mt-24 text-14_20 w-624 -ml-80">
            オファー時に通知できるポジション情報を入力しましょう。<br />
            こちらを準備することで求職者（候補者）がオファーに応諾しやすくなります。<br />
            先輩社員のインタビュー記事など、アピール用のWebページURLも入力可能です。<br />
            <p className="mt-8 text-11_16">
              ※求職者（候補者）から貴社へ直接応募できる導線URLを入力することはお控えください。<br />
              ※複数ポジションがある場合は後から追加できます。
            </p>
          </p>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
              <FormContainerTextfield name="active" className="hidden" defaultValue="true" />
              <div className="mt-26 flex justify-center">
                <FormLayoutFieldset className='w-full'>
                  <FormLabel label="ポジション名（職種名）" type="small" />
                  <FormContainerTextfield
                    name="name"
                    className="pb-6"
                    placeholder={PLACEHOLDER.offer_position_name}
                    maxLength={30}
                  />
                </FormLayoutFieldset>
              </div>
              <div className="mt-26 flex justify-center">
                <FormLayoutFieldset className='w-full'>
                  <FormLabel label="ポジション詳細（業務内容詳細）" type="small" >
                    <ButtonTextLink
                      onClick={(e) => {
                        e.preventDefault();
                        setValue("body", TEMPLATE.position_template.default);
                      }}
                      className="text-11_12 ml-8"
                      bold
                    >
                      テンプレートから⼊⼒する
                    </ButtonTextLink>
                  </FormLabel>
                  <FormContainerTextArea
                    name="body"
                    rows={8}
                    maxLength={1000}
                    placeholder={PLACEHOLDER.offer_position_modal_description}
                    className="mkt_mask_items"
                  />
                </FormLayoutFieldset>
              </div>
              <div>
                <PageDots page={props.page} setPage={props.setPage} step={props.step} totalDots={props.totalDots} unClick className="mt-48" />
                <div className="w-224 m-auto mt-48">
                  <BaseButton
                    theme="primary"
                    size="m"
                    className="w-full"
                  >
                    保存して次へ
                  </BaseButton>
                </div>
              </div>
            </form>
          </FormProvider>
          <div className="w-224 m-auto mt-24 mb-20">
            <BaseButton
              theme="secondary"
              size="m"
              className="w-full"
              onClick={() => props.onPrev(3)}
            >
              もどる
            </BaseButton>
          </div>
        </div>
      </OnboardingPageWrapper>
    </div>
    </DataLayerPushContainer>
  );
};
