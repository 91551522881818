import { AxiosError } from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BaseButton from '@/components/common/Button/BaseButton';
import { Wrapper } from '@/components/common/Page';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import { SectionMail } from '@/components/Mypage/Account/SectionMail';
import { SectionPassword } from '@/components/Mypage/Account/SectionPassword';
import {
    auth, notificationError, notificationSuccess, toggleLoading, setUserEmail
} from '@/redux/index';
import { State } from '@/redux/state';
import { AuthApi, RequestTokenCheck, ResponseError, UserApi, UserInfo } from '@/utils/api-client';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';

/**
 * # マイページ アカウント管理 /mypage/account
 * UI PC https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=2249%3A423
 *
 */
export const MypageAccount = (): React.ReactElement => {
  const API = new UserApi();
  const dispatch = useDispatch();
  const user: UserInfo | null = useSelector((state: State) => state.user);
  const { push } = React.useContext(DataLayerContext);

  useEffect(() => {
    const parameter = window.location.search.substring(1);
    if (!parameter) {
      push({
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'page_view',
        virtualPageName: 'mail-pw_step_1',
      });
      return;
    }
    const token: RequestTokenCheck = {
      token: parameter,
    };
    API.postUserEmailChangeValidateToken(token)
      .then(() => {
        API.getUser()
          .then((res) => {
            if (res.data.user_info) dispatch(setUserEmail(res.data.user_info));
            push({
              event: 'pageView',
              actionType: 'page_view',
              actionName: 'page_view',
              virtualPageName: 'mail_step_3',
            });
            dispatch(notificationSuccess('メールアドレスが変更されました。'));
          })
          .catch((error: AxiosError<ResponseError>) => {
            if (error.response) dispatch(notificationError(error.response.data.error_message));
          });
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally();
  }, []);

  const onLogout = async () => {
    dispatch(toggleLoading(true));
    const result = await new AuthApi().getAuthLogout();
    dispatch(toggleLoading(false));
    if (result.status === 200) {
      dispatch(auth(false));
    }
  };

  if (!user) return <></>;
  return (
    <Wrapper
      disableDataLayerPush
      header={
          <HeaderTitle title={'アカウント管理'}>
            <></>
          </HeaderTitle>
      }
    >
      <>
        <div className={'w-308 m-auto mt-48 mb-92 sm:mb-48 sm:w-full'}>
          <SectionMail />
          <SectionPassword />
          <div className="text-center mt-75">
            <BaseButton onClick={onLogout} size="m" theme="link">
              ログアウト
            </BaseButton>
          </div>
          <div className="text-center mt-32 sm:mb-212">
            <BaseButton
              size="m"
              theme="link"
              href="https://survey.mynavi.jp/cre/?enq=L5aHlrtrm58%3d"
            >
              退会をご希望の方はこちら
            </BaseButton>
          </div>
        </div>
      </>
    </Wrapper>
  );
};
