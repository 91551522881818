import React, { useEffect, useMemo, useState, VFC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { FormContainerDropdown, FormLabel } from '@/components/common/Form/';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { State } from '@/redux/state';
import {
    Portfolio, PortfolioItem, PortfolioPage, PortfolioPagePPageLayoutEnum, Question
} from '@/utils/api-client/index';
import { delay } from '@/utils/delay';

export const PortfolioEditManagement: VFC<{
  portfolio?: Portfolio;
  itemId: number;
  onUpdateQuestion(qaId: string, answer: string[]): void;
}> = ({ portfolio, itemId, onUpdateQuestion }) => {
  const methods = useForm();
  const user = useSelector((state: State) => state.user);
  const [init, setInit] = useState<boolean>(true);
  const qaId = 'b_5';

  const item = useMemo<PortfolioItem | undefined>(() => {
    if (!portfolio?.items) return undefined;
    return Array.from(portfolio?.items).find((item) => item.i_id === itemId);
  }, [portfolio]);

  //Form to API
  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      //初期のsetValueが終わってからじゃないとwatchでの更新は受け付けない
      if (init) {
        return;
      }
      if (name === 'qa_answer') {
        onUpdateQuestion(qaId, value.qa_answer);
      }
    });

    return () => subscription.unsubscribe();
  }, [methods.watch, user, item, init]);

  useEffect(() => {
    if (!item) return;
    if (!init) return;
    (async () => {
      const page: PortfolioPage | undefined = Array.from(item?.i_pages).find(
        (page) => page.p_page_layout === PortfolioPagePPageLayoutEnum.Questions
      );
      const content = Array.from(page?.p_contents || []).find(
        (content) => (content as Question).qa_id === qaId
      );
      if (page && content) {
        methods.setValue('qa_answer', (content as Question).qa_answer[0]);
      }

      //初期のsetValueを待ってからsetInitする
      await delay(10);
      setInit(false);
    })();
  }, [item, init]);

  return (
    <FormProvider {...methods}>
      <form onChange={(v) => console.log(v)}>
        <div className="space-y-8 my-22">
          <FormLabel label="マネジメント人数" type="small" />
          <FormContainerDropdown
            name={`qa_answer`}
            placeholder="人数を選択"
            className="w-150 mkt_mask_items"
            selectList={FORM_OPTIONS.management_count}
          />
        </div>
      </form>
    </FormProvider>
  );
};
