import React from 'react';

import { GlobalPDFDownloadModalPortal } from '@/componentsAdmin/Global/PDFDownloadModalPortal';
import {
    GlobalPDFDownloadSnackBarPortal
} from '@/componentsAdmin/Global/PDFDownloadSnackBarPortal';
import { AdminHeader } from '@/componentsAdmin/Layout/Header';
import { GlobalLoading } from '@/componentsDirect/Global/Loading';
import { GlobalNotificationErrorPortal } from '@/componentsDirect/Global/NotificationErrorPortal';
import {
    GlobalNotificationSuccessPortal
} from '@/componentsDirect/Global/NotificationSuccessPortal';

type TProps = {
  children?: React.ReactElement;
  header?: React.ReactElement;
  sticky?: React.ReactElement;
};

export const AdminPage = ({ children, header, sticky }: TProps): React.ReactElement => {
  return (
    <>
      <div className="min-h-screen">
        <div>
          <AdminHeader />
          {header}
        </div>
        <main>
          <div className={'w-960 m-auto'}>{children}</div>
          {sticky}
        </main>
      </div>
      <GlobalLoading />
      <GlobalPDFDownloadModalPortal />
      <GlobalPDFDownloadSnackBarPortal />
      <GlobalNotificationErrorPortal />
      <GlobalNotificationSuccessPortal />
    </>
  );
};
