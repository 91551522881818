import React, { useState, useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Ed31 = (): React.ReactElement => {
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if (isAuth) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0031");
    if (!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g,"/")} 12:00:00`);
    const now = new Date;
    if (now > deadlineDate) setDeadline(true);
  }, []);

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16">
          <img className={style.img} src="../images/Top/event/event_0031_mv.png" width="978" height="504" alt="" />
        </div>
        <h1 className="mt-56 sm:mt-30 sm:mb-8 text-29_46 sm:text-18_24 font-bold text-center">4/18（火）20:00 -<br />
          <span className="border-b border-black text-21_34 sm:text-15_22">クリエイターズ・シナジーカフェ×<br className="md:hidden lg:hidden" />マッチボックス コラボ企画</span><br />
          <span className="inline-block mt-16 sm:mt-10 sm:mb-8 text-21_34 sm:text-15_22">Webデザイナー進化論 season1 <br className="md:hidden lg:hidden" />UIスタート編 vol.1</span><br />
          UIデザイナーへのロードマップ<br />～どうしたらUIデザイナーと<br className="md:hidden lg:hidden" />名乗れるか？～</h1>
        { deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <p className="text-16_28 sm:text-14_21 mt-30 md:text-center lg:text-center">マッチボックスではこの度、様々なクリエイターの交流起点となっている<br className='sm:hidden' />クリエイターズ・シナジーカフェとコラボし、セミナーイベントを開催いたします。</p>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className='mt-80 sm:mt-55'>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>開催への想い</h2>
          <div className="mt-30 sm:mt-16 mb-30 sm:mb-18 box-line">
            <img src="../images/Top/event/event_0031_image.png" width="980" height="297" alt="" />
          </div>
          <p className="text-16_28 sm:text-14_21 mt-30 sm:mt-16 mb-40 sm:mb-18">
            日頃、マッチボックスユーザーの皆さま向けにセミナーや転職支援を起こっている中で、Webデザイナーからのキャリアアップに悩まれているというお声を多くいただいていました。中でもUIデザインに対しての関心が高く、「スキルアップしてUIデザイナーなりたいけどどうすればいいかわからない。」や「現職ではUIのスキルは求められず、経験が積めない。研鑽のための時間も取れない。」など、実際に踏み出せていないという方が多いようでした。<br />
            このようなニーズにお応えすべく、この度Web業界に携わる人を対象にしているオンラインコミュニティの『クリエターズ・シナジーカフェ』とマッチボックスのコラボレーションが実現しました！UIを考える、UIデザイナーになるためのスタート編としてのセミナーを4～6月にシリーズとして開催いたします！<br />
            以下のスケジュール、内容で3カ月展開していきますので是非ご参加ください！</p>
          <table className="index schedule text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap">
                  日程
                </th>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap">
                  内容
                </th>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap">
                  セミナースピーカー
                </th>
              </tr>
              <tr>
                <th colSpan={3} className="bg-gray-50 font-normal">4月テーマ : UIの全体像</th>
              </tr>
              <tr>
                <td>4/18</td>
                <td>1. UIデザイナーへのロードマップ</td>
                <td>タグチマリコさん</td>
              </tr>
              <tr>
                <td>4/26</td>
                <td>2. 実例から見るWebデザイン設計で意識すべきUIの基本とは？</td>
                <td>タグチマリコさん<br />
                  せきゆおうさん</td>
              </tr>
              <tr>
                <th colSpan={3} className="bg-gray-50 font-normal">5月テーマ : ユーザーと向き合う</th>
              </tr>
              <tr>
                <td>5月中旬</td>
                <td>3. UIを考える上でのUXとは</td>
                <td>coming soon</td>
              </tr>
              <tr>
                <td>5月下旬</td>
                <td>4. ユーザーの声を反映する</td>
                <td>coming soon</td>
              </tr>
              <tr>
                <th colSpan={3} className="bg-gray-50 font-normal">6月テーマ : UIデザイナーのあるある 総括</th>
              </tr>
              <tr>
                <td>6月中旬</td>
                <td>5. ツールについて</td>
                <td>coming soon</td>
              </tr>
              <tr>
                <td>6月下旬</td>
                <td>6. UIデザイナーの活躍</td>
                <td>coming soon</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">今回のセミナーについて</h3>
          <p className="text-16_28 sm:text-14_21 mt-30">
            今回はvol.1『UIデザイナーへのロードマップ ～どうしたらUIデザイナーと名乗れるか？～』として、UIの考え方をWebデザイン作成と比較してお伝えいたします。“UIデザイナー“として名乗り、活躍するためにどのような知識・スキルが必要なのかを、UIデザインの基礎とともにお話いただきます。
          </p>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>以下に該当する方はオススメです。</h2>
          <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>キャリアアップに悩んでいるWebデザイナー</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>UIデザインに関心がある、知りたいと思っている</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>次のキャリアアクションを決めかねていてWebデザイナーとして何か武器を持ちたい</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>UI構築についての具体的に知りたい方</span>
              </li>
              <li className="flex">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>セミナーを踏まえて転職・書類作成の相談をしたい方</span>
              </li>
            </ul>
          </div>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>講師プロフィール</h2>
          <div className='event-instructor box-line mb-30 sm:p-16'>
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0031_prof.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                タグチ マリコ
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-40 sm:mb-18">
                日本ウェブデザイン株式会社 CXO兼UXデザイナー
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                2001年からアートやプログラミングの虜となり、学生時代からフリーランス活動をスタート。20代から様々な制作会社での勤務や起業を経験し、大手企業から中小企業まで幅広いクライアントと共に、Webサイトやアプリケーション開発に携わる。プレイヤーからマネジメントまで、多岐にわたる領域で実績を積む。
                活動コンセプトは「ユーザ以外も良い体験に」。すべての関係者が幸福度高く、ポテンシャルを発揮できるような構造をクリエイティブすることに専念している。
              </dd>
            </dl>
          </div>
        </section>
        <section className="mt-80 sm:mt-55">
          <div className='box-line p-30 sm:p-16'>
            <h3 className="text-18_24 sm:text-14_21 font-bold">クリエイターズ・シナジーカフェとは？</h3>
            <div className="md:flex lg:flex items-center">
              <img className="w-1/2 sm:w-full h-full md:mr-16 lg:mr-16" src="../images/Top/event/event_0034_logo-synercafe.png" alt="" />
              <p className="mt-15 sm:mt-8 text-16_28 sm:text-14_21">
                Web業界に携わる人を対象にしているオンラインコミュニティです。コミュニティ参加者限定のウェビナーやイベントが開催されていたり、メンバー同士が互いに意見公開できる場所が提供されていたりします。<br />
                <a className="textLink" href='https://creators-synergy-cafe.com/' target={'_blank'} rel="noreferrer">https://creators-synergy-cafe.com/<i className="mbx-icon mbx-icon-External_link"></i></a>
              </p>
            </div>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap">
                  <span className="sm:block">20：00</span><span className="sm:block">～</span><span className="sm:block">21：20</span>
                </th>
                <td>
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>自己紹介</span></li>
                    <li className="flex"><span>・</span><span>UIデザイナーとは？</span></li>
                    <li className="flex"><span>・</span><span>WebデザインとUIデザインの違い</span></li>
                    <li className="flex"><span>・</span><span>UIデザイナーを名乗るための必須スキル</span></li>
                    <li className="flex"><span>・</span><span>質疑応答</span></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2023/4/18（火）20:00～<br />
                  [入室開始] 19:50～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.参加申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink"
                      href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.開催当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className='mt-80 sm:mt-55'>
          <h3 className='text-18_24 sm:text-14_21 font-bold'>開催について</h3>
          <p className='text-16_28 sm:text-14_21 mt-30 sm:mt-16'>Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2, 000名を超える「0からのWebディレクション講座」を生み出したメンバーでもある一般社団法人ディレクションサポート協会 会長の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。</p>
        </section >
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div>
    </div>
  );
};
