import React from 'react';

import { SkillBox } from '@/components/common/Portfolio/';
import { CareerHistorySkills } from '@/utils/api-client';

export const Skills = ({
  skills,
  experiences,
}: {
  skills: CareerHistorySkills[] | undefined;
  experiences: string[] | undefined;
}): React.ReactElement => {
  return (
    <>
      {skills && skills.length !== 0 && (
        <div className="skills pl-48 mt-48">
          <p className="title mbx-typography--subhead_1">テクニカルスキル</p>
          {skills?.map((item, index) => {
            const level = item.level as 0 | 1 | 2 | 3 | 4 | 5;
            const experience = item.experience as 1 | 2 | 3 | 4;
            return (
              <div className="skill mt-16" key={index}>
                <SkillBox
                  skillName={item.name ? item.name : ''}
                  level={level}
                  experience={experience}
                />
              </div>
            );
          })}
        </div>
      )}
      {experiences && experiences.length !== 0 && (
        <div className="experience pl-48 mt-48">
          <p className="title mbx-typography--subhead_1">経験</p>
          <p className="text mbx-typography--body_2 mt-16 mkt_mask_items">
            {experiences?.join(' / ')}
          </p>
        </div>
      )}
    </>
  );
};
