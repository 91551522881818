import React, { useMemo } from 'react';
import cn from 'classnames';

type Props = {
  number?: number;
  children: React.ReactElement | string;
  type: 'bracket' | 'number' | 'Disc';
  className?: string;
  bold?: boolean;
};

function ListItem(props: Props): React.ReactElement {
  const listType = useMemo(() => {
    if (!props.number || props.type === 'Disc') return '・';
    if (props.type === 'bracket') return `(${props.number})`;
    if (props.type === 'number') return `${props.number}.`;
  }, [props.type, props.number]);
  return (
    <section className={cn('mbx-typography--body_1', 'flex', props.className)}>
      <div className="mr-4">
        <p className="w-auto">{listType}</p>
      </div>
      <p className={cn({ ['font-bold']: props.bold }, '')}>{props.children}</p>
    </section>
  );
}

export default ListItem;
