import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';

type Props = {
  setFilterValue: React.Dispatch<
    React.SetStateAction<{
      company_name: string;
      matchbox_id: string;
      user_name: string;
      job: string;
      status: string;
      mw_status: string;
      sort: string;
    }>
  >;
};

type TInput = {
  company_name: string;
  matchbox_id: string;
  user_name: string;
  job: string;
  status: string;
  mw_status: string;
  sort: string;
};

export const Filters = (props: Props): React.ReactElement => {
  console.log(props);
  const methods = useForm<TInput>({
    defaultValues: {
      company_name: '',
      matchbox_id: '',
      user_name: '',
      job: '',
      status: '',
      mw_status: '',
      sort: '2',
    },
  });
  const { watch } = methods;

  useEffect(() => {
    props.setFilterValue({
      company_name: watch('company_name'),
      matchbox_id: watch('matchbox_id'),
      user_name: watch('user_name'),
      job: watch('job'),
      status: watch('status'),
      mw_status: watch('mw_status'),
      sort: watch('sort'),
    });
  }, [watch('job'), watch('status'), watch('mw_status'), watch('sort')]);

  return (
    <div>
      <FormProvider {...methods}>
        <form>
          <div className="flex">
            <div className="w-168">
              <FormLayoutFieldset>
                <FormLabel label="MATCHBOX IDで検索" type="small"></FormLabel>
                <FormContainerTextfield
                  name="matchbox_id"
                  className="mkt_mask_items"
                  onBlur={(e) => {
                    // 削除ボタン判定
                    const related = e.relatedTarget as HTMLHtmlElement;
                    if (related) {
                      const className = related.className;
                      if (className.includes('type-delete')) {
                        e.target.value = '';
                        methods.setValue('matchbox_id', '');
                      }
                    }
                    props.setFilterValue({
                      company_name: watch('company_name'),
                      matchbox_id: e.target.value,
                      user_name: watch('user_name'),
                      job: watch('job'),
                      status: watch('status'),
                      mw_status: watch('mw_status'),
                      sort: watch('sort'),
                    });
                  }}
                />
              </FormLayoutFieldset>
            </div>
            <div className="w-168 ml-16">
              <FormLayoutFieldset>
                <FormLabel label="求職者名で検索" type="small"></FormLabel>
                <FormContainerTextfield
                  name="user_name"
                  className="mkt_mask_items"
                  onBlur={(e) => {
                    // 削除ボタン判定
                    const related = e.relatedTarget as HTMLHtmlElement;
                    if (related) {
                      const className = related.className;
                      if (className.includes('type-delete')) {
                        e.target.value = '';
                        methods.setValue('user_name', '');
                      }
                    }
                    props.setFilterValue({
                      company_name: watch('company_name'),
                      matchbox_id: watch('matchbox_id'),
                      user_name: e.target.value,
                      job: watch('job'),
                      status: watch('status'),
                      mw_status: watch('mw_status'),
                      sort: watch('sort'),
                    });
                  }}
                />
              </FormLayoutFieldset>
            </div>
            <div className="w-168 ml-16">
              <FormLayoutFieldset>
                <FormLabel label="企業名で検索" type="small"></FormLabel>
                <FormContainerTextfield
                  name="company_name"
                  className="mkt_mask_items"
                  onBlur={(e) => {
                    // 削除ボタン判定
                    const related = e.relatedTarget as HTMLHtmlElement;
                    if (related) {
                      const className = related.className;
                      if (className.includes('type-delete')) {
                        e.target.value = '';
                        methods.setValue('company_name', '');
                      }
                    }
                    props.setFilterValue({
                      company_name: e.target.value,
                      matchbox_id: watch('matchbox_id'),
                      user_name: watch('user_name'),
                      job: watch('job'),
                      status: watch('status'),
                      mw_status: watch('mw_status'),
                      sort: watch('sort'),
                    });
                  }}
                />
              </FormLayoutFieldset>
            </div>
          </div>
          <div className="flex items-end mt-8">
            <div className="w-168">
              <FormLayoutFieldset>
                <FormLabel label="絞り込み" type="small"></FormLabel>
                <FormContainerDropdown
                  name="job"
                  placeholder="職種"
                  selectList={FORM_OPTIONS.experienced_job}
                ></FormContainerDropdown>
              </FormLayoutFieldset>
            </div>
            <div className="w-168 ml-16">
              <FormLayoutFieldset>
                <FormLabel label="絞り込み" type="small"></FormLabel>
                <FormContainerDropdown
                  name="status"
                  placeholder="ステータス"
                  selectList={DIRECT_FORM_OPTIONS.matchingStatus}
                ></FormContainerDropdown>
              </FormLayoutFieldset>
            </div>
            <div className="w-168 ml-16">
              <FormLayoutFieldset>
                <FormLabel label="絞り込み" type="small"></FormLabel>
                <FormContainerDropdown
                  name="mw_status"
                  placeholder="対応状況"
                  selectList={DIRECT_FORM_OPTIONS.mwStatus}
                ></FormContainerDropdown>
              </FormLayoutFieldset>
            </div>
            <div className="w-220 ml-16">
              <FormLayoutFieldset>
                <FormLabel label="並べ替え" type="small"></FormLabel>
                <FormContainerDropdown
                  name="sort"
                  selectList={DIRECT_FORM_OPTIONS.matchingSort}
                ></FormContainerDropdown>
              </FormLayoutFieldset>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
