import { AxiosError } from 'axios';
import React from 'react';
import { useDispatch } from 'react-redux';

import { BaseButton } from '@/components/common/Button/';
import { ModalDefault } from '@/componentsDirect/Parts/Modal';
import { notificationError } from '@/redux/index';
import { ResponseError, UserApi } from '@/utils/api-client';

type TProps = {
  published: number;
  onClose: (agreed: boolean) => void;
};

/**
 * ポートフォリオ : モーダル - ポートフォリオ公開に同意
 * @issue https://github.com/prtytokyo/matchbox-2021-web-frontend/issues/1806
 * @author s2masa
 */
export const PortfolioModalAgreedToPublishPortfolio = ({
  published,
  onClose,
}: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // CONST
  //
  // ====================================================================================================
  const API = new UserApi();

  // ====================================================================================================
  //
  // REDUX
  //
  // ====================================================================================================
  // const stateUserInfo = useSelector((state: State) => state.user);
  const dispatch = useDispatch();

  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [isOpen, setIsOpen] = React.useState<boolean>(true);

  // ====================================================================================================
  //
  // API
  //
  // ====================================================================================================
  const postAgreedToPublishPortfolio = async () => {
    try {
      await API.postAgreedToPublishPortfolio();
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    }
  };

  // ====================================================================================================
  //
  // EVENT
  //
  // ====================================================================================================
  const onCancelClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setIsOpen(false);
    onClose(false);
  };

  const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    (async () => {
      await postAgreedToPublishPortfolio();
      setIsOpen(false);
      onClose(true);
    })();
  };

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <ModalDefault
      isOpen={isOpen}
      onClose={onCancelClick}
      className="w-784 sm:w-screen lg:px-80 md:px-80 rounded-4 sm:rounded-none"
    >
      <div className="lg:p-80 md:p-80 sm:py-48 sm:px-24">
        <h1 className="text-heading_4 sm:text-heading_3 font-body font-bold mb-24">
          仕様変更のお知らせ
        </h1>
        <p className="mbx-typography--body_1 font-body mb-24">
          ポートフォリオの編集・保存内容は、随時企業に公開されます。編集中の情報を企業に公開したくない場合は、一度オファー機能を停止した上で編集を行い、完成後、再度オファー機能に登録してください。
        </p>
        <div className="flex justify-center">
          <BaseButton
            onClick={onClick}
            className="sm:w-full sm:mb-8"
            size="m-for-sp"
            theme="primary"
          >
            同意の上、{published ? '更新する' : 'つくる'}
          </BaseButton>
        </div>
      </div>
    </ModalDefault>
  );
};
