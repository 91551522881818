import '@/styles/common/Mypage/text.scss';

import React, {useContext} from 'react';
import { FormProvider, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import BaseButton from '@/components/common/Button/BaseButton';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { MESSAGE } from '@/definition/MESSAGE';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { useUser } from '@/hooks/useUser';
import { State } from '@/redux/state';
import { PasswordChangeParam } from '@/utils/api-client/api';
import { yupResolver } from '@hookform/resolvers/yup';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
export const SectionPassword = (): React.ReactElement => {
  const { postPasswordChange } = useUser();
  const loading = useSelector((state: State) => state.loading);
  const { push } = useContext(DataLayerContext);
  /**
   * validation 設定
   */
  const schema = yup.object().shape({
    password_current: YUP_VALIDATION.password,
    password_new: YUP_VALIDATION.password,
    password_new_re: YUP_VALIDATION.re_password('password_new'),
  });

  const methods = useForm<PasswordChangeParam>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset, control } = methods;

  const { isValid } = useFormState({
    control,
  });

  const onSubmit: SubmitHandler<PasswordChangeParam> = (data) => {
    (async () => {
      await postPasswordChange(data);
      push({
        event: 'pwSetting',
        actionType: "pw_setting",
        actionName: "pw_setting",
      })
      reset();
    })();
  };

  return (
    <section className="mt-64">
      <p className="mbx-typography--heading_4 sm:mbx-typography--heading_3 text-center">
        パスワードの変更
      </p>
      <p className="mbx-typography--body_1 mbx-mypage--text text-center mt-24 whitespace-pre">
        {MESSAGE.password_specifications}
      </p>
      <p className="text-11_20 mbx-mypage--text text-center">
        {MESSAGE.password_specification_supplement}
      </p>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-16">
            <FormLayoutFieldset className="mbx-utils-stack-v--8">
              <FormContainerTextfield
                name="password_current"
                type="password"
                placeholder="現在のパスワードを入力"
                className="mkt_mask_items"
              />
              <FormContainerTextfield
                name="password_new"
                type="password"
                placeholder="新しいパスワードを入力"
                className="mkt_mask_items"
              />
              <FormContainerTextfield
                name="password_new_re"
                type="password"
                placeholder="確認のため、もう1度入力してください"
                className="mkt_mask_items"
              />
            </FormLayoutFieldset>
          </div>
          <section className="register-btn m-auto mt-24 sm:w-full">
            <BaseButton
              size="m"
              theme="primary"
              className="btn"
              type="submit"
              disabled={loading || !isValid}
            >
              パスワードを変更する
            </BaseButton>
          </section>
        </form>
      </FormProvider>
    </section>
  );
};
