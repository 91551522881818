import React, { ReactElement, VFC } from 'react';

type TProps = {
  className?: [string, string];
  children: [ReactElement, ReactElement];
};

/**
 * コンテンツコラム内を分割するコンポーネント
 *
 * とりあえず2等分割だけ
 *
 * className でそれぞれのカラムにclass指定可能
 */
export const LayoutColumDivider: VFC<TProps> = ({ children, className = ['', ''] }) => {
  return (
    <div className="grid lg:grid-cols-2 smm::grid-cols-1 gap-0">
      <div className={className[0]}>{children[0]}</div>
      <div className={className[1] + ' smm:row-start-1'}>{children[1]}</div>
    </div>
  );
};
