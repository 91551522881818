import React, { useEffect, useContext } from 'react';
import Modal from '@/components/common/Modal';
import { BaseButton } from '@/components/common/Button/';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormProvider, useForm } from 'react-hook-form';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';

type Props = {
  isModal: boolean;
  onModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTypeContents: string[];
  postItemApi: (url: string) => Promise<void>;
  returnModal: () => void;
};
type IFormInputs = {
  url: string;
};

export const WorkAddModal = (props: Props): React.ReactElement => {
  const methods = useForm<IFormInputs>({
    defaultValues: {
      url: '',
    },
  });
  const { handleSubmit } = methods;
  const { push } = useContext(DataLayerContext);

  useEffect(() => {
    if (!props.isModal) methods.setValue('url', '');
  }, [props.isModal]);

  useEffect(() => {
    let virtualPageName = '';
    switch (props.selectedTypeContents[0]) {
      case 'Webページ':
        virtualPageName = 'add-a-work_wp';
        break;
      case '動画':
        virtualPageName = 'add-a-work_video';
        break;
      case '音楽':
        virtualPageName = 'add-a-work_music';
        break;
      case '資料':
        virtualPageName = 'add-a-work_doc';
        break;
      case '3Dモデル':
        virtualPageName = 'add-a-work_3d';
        break;
    }
    if (!virtualPageName) return;
    push({
      event: 'pageView',
      actionType: 'page_view',
      actionName: 'modal_open',
      virtualPageName,
    });
  }, [props.selectedTypeContents]);

  return (
    <Modal
      isOpen={props.isModal}
      isClose={() => props.onModal(false)}
      className={'modal-contents_add w-784 h-722 sm:pb-80'}
    >
      <p className="mbx-typography--heading_2 mt-80 sm:mt-48">
        {props.selectedTypeContents[0]}を追加
      </p>
      <p className="mt-24 mbx-typography--body_1 sm:pl-24 sm:pr-24">
        {props.selectedTypeContents[1]}を入力してください。
      </p>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => props.postItemApi(data.url))}>
          <div className="mt-48 mb-24 pl-80 pr-80 sm:pl-24 sm:pr-24">
            <FormContainerTextfield
              name="url"
              placeholder="http://www"
              className="mkt_mask_items"
            />
          </div>
          <BaseButton theme={'primary'} size={'m'}>
            <div className="w-160">登録する</div>
          </BaseButton>
          <p className="return-text text-12_14 font-bold mt-24" onClick={props.returnModal}>
            作品タイプの選択に戻る
          </p>
        </form>
      </FormProvider>
    </Modal>
  );
};
