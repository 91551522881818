import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import {
  FormContainerDropdown,
  FormContainerTextfield,
  FormLabel,
  FormLayoutFieldset,
} from '@/componentsDirect/Parts/Form/';
import { TFormOption } from '@/definition/FORM_OPTIONS';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';
import { TFilterValues } from '@/pagesDirect/CompanyUsers/Index';
import { CompanyGroupSummary } from '@/utils/api-client/api';

type TProps = {
  setFilterValues: React.Dispatch<React.SetStateAction<TFilterValues>>;
  userGroups?: Array<CompanyGroupSummary>;
};

export const DirectUsersFilters = ({ setFilterValues, userGroups }: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const { push } = React.useContext(DataLayerContext);
  const methods = useForm<TFilterValues>({
    defaultValues: {
      user_name: '',
      mail: '',
      group: '',
      user_type: undefined,
    },
  });
  const { watch } = methods;

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const userGroupOptions = useMemo(() => {
    if (!userGroups) return [];
    const options = userGroups?.map((v) => {
      return {
        value: v.id.toString(),
        children: v.name,
      };
    });

    return options as TFormOption[];
  }, [userGroups]);

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    const subscription = watch((value) => {
      setFilterValues(value);

      const filter: string[] = ['N/A', 'N/A', 'N/A', 'N/A'];
      filter[0] = value.user_name ? value.user_name : 'N/A';
      filter[1] = value.mail ? value.mail : 'N/A';
      filter[2] = value.group ? value.group : 'N/A';
      filter[3] = value.user_type ? value.user_type : 'N/A';

      push({
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'page_view',
        cNarrowDown: filter.join('::'),
      });
    });
    return () => subscription.unsubscribe();
  }, []);

  return (
    <div className="filters">
      <FormProvider {...methods}>
        <form className="flex items-end">
          <div className="w-194 mr-8">
            <FormLayoutFieldset>
              <FormLabel label="ユーザー名で検索" type="small"></FormLabel>
              <FormContainerTextfield
                name="user_name"
                placeholder="ユーザー名を入力"
                iconName="Search"
              ></FormContainerTextfield>
            </FormLayoutFieldset>
          </div>

          <div className="w-194 mr-16">
            <FormLayoutFieldset>
              <FormLabel label="メールアドレスで検索" type="small"></FormLabel>
              <FormContainerTextfield
                name="mail"
                placeholder="メールアドレスを入力"
                iconName="Search"
              ></FormContainerTextfield>
            </FormLayoutFieldset>
          </div>

          <div className="w-155">
            <FormLayoutFieldset>
              <FormLabel label="絞り込み" type="small"></FormLabel>
              <FormContainerDropdown
                className="select"
                name="group"
                placeholder="所属グループ"
                selectList={userGroupOptions}
              ></FormContainerDropdown>
            </FormLayoutFieldset>
          </div>
          <div className="w-155 ml-8">
            <FormContainerDropdown
              className="select"
              name="user_type"
              placeholder="アカウント種別"
              selectList={DIRECT_FORM_OPTIONS.user_type}
            ></FormContainerDropdown>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
