import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { State } from '@/redux/state';
import { ADMINApi, MBResumeResponse, ResumeApi, Status } from '@/utils/api-client/api';

export const usePageResumeFetch = (
  type: 'direct' | 'public' | 'admin' = 'public'
): MBResumeResponse | undefined => {
  const user = useSelector((state: State) => state.user);
  const location = useLocation();
  const [res, setRes] = useState<MBResumeResponse>();
  // response
  useEffect(() => {
    (async () => {
      let response: AxiosResponse<MBResumeResponse>;
      const path = location.pathname.split('/');
      switch (type) {
        case 'admin':
          response = await new ADMINApi().getAdminResume(path[3]);
          break;
        default:
          response = await new ResumeApi().getResume();
          break;
      }
      setRes(response.data);
    })();
  }, [user]);

  return res;
};

// progressの型情報がないようなので自前で設定、openapiの修正が必要かも
// っと思ったら getResumeProgress の返す型が AxiosResponse<object & Status>
// なので修正して再生成しないとダメっぽい
// とりあえずanyを許可して回避
// type TResumeProgress = {
//   progress: Array<0 | 1>;
// };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePageResumeProgress = (): (any & Status) | undefined => {
  const user = useSelector((state: State) => state.user);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [res, setRes] = useState<any & Status>();
  useEffect(() => {
    (async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: AxiosResponse<any & Status> = await new ResumeApi().getResumeProgress();
      setRes(response.data);
    })();
  }, [user]);

  return res;
};
