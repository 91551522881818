import React from 'react';

import { PortfolioItem, PortfolioPage } from './api-client/';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const menu: any[] = [
  {
    title: 'ホーム',
    url: '/mypage',
  },
  {
    title: 'ポートフォリオの編集',
    url: '/mypage/portfolio/edit',
  },
  {
    title: '履歴書の編集',
    url: '/mypage/resume/edit',
  },
  {
    title: '職務経歴書の編集',
    url: '/mypage/cv/edit',
  },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const headerMenu: any[] = [
  {
    title: 'ホーム',
    url: '/mypage',
  },
  {
    title: 'ポートフォリオ',
    url: '/mypage/portfolio/edit',
  },
  {
    title: '履歴書',
    url: '/mypage/resume/edit',
  },
  {
    title: '職務経歴書',
    url: '/mypage/cv/edit',
  },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const notAuthMenu: any[] = [
  {
    title: 'アカウント登録',
    url: '/entry',
  },
  {
    title: 'ログイン',
    url: '/login',
  },
];

//カード移動処理で検証 114行目解除
const testItems: PortfolioItem[] = [
  {
    i_id: 0,
    i_image: {
      f_id: 'RANDOMFILEID',
      f_url: 'URL',
      f_thumbnail: '/images/card_img.png',
    },
    i_embedlink: 'https://www',
    // i_year: 2004,
    i_title: '作品01',
    i_url: 'http://masu2.jp',
    i_description: '斜めに動きます',
    i_pages: new Set<PortfolioPage>(),
    i_visible: true,
    i_tags: [
      'グラフィックデザイナー',
      'アートディレクション',
      'CI・Vi',
      'ブランディング',
      'Webデザイン',
      'Illustrator',
      'アートディレクション',
    ],
  },
  {
    i_id: 0,
    i_image: {
      f_id: 'RANDOMFILEID',
      f_url: 'URL',
      f_thumbnail: '/images/card_img.png',
    },
    i_embedlink: 'https://www',
    // i_year: 2004,
    i_title: '作品02',
    i_url: 'http://masu2.jp',
    i_description: '斜めに動きます',
    i_pages: new Set<PortfolioPage>(),
    i_visible: true,
    i_tags: [
      'グラフィックデザイナー',
      'アートディレクション',
      'CI・Vi',
      'ブランディング',
      'Webデザイン',
      'Illustrator',
      'アートディレクション',
    ],
  },
  {
    i_id: 0,
    i_image: {
      f_id: 'RANDOMFILEID',
      f_url: 'URL',
      f_thumbnail: '/images/card_img.png',
    },
    i_embedlink: 'https://www',
    // i_year: 2004,
    i_title: '作品03',
    i_url: 'http://masu2.jp',
    i_description: '斜めに動きます',
    i_pages: new Set<PortfolioPage>(),
    i_visible: true,
    i_tags: [
      'グラフィックデザイナー',
      'アートディレクション',
      'CI・Vi',
      'ブランディング',
      'Webデザイン',
      'Illustrator',
      'アートディレクション',
    ],
  },
  {
    i_id: 0,
    i_image: {
      f_id: 'RANDOMFILEID',
      f_url: 'URL',
      f_thumbnail: '/images/card_img.png',
    },
    i_embedlink: 'https://www',
    // i_year: 2004,
    i_title: '作品04',
    i_url: 'http://masu2.jp',
    i_description: '斜めに動きます',
    i_pages: new Set<PortfolioPage>(),
    i_visible: true,
    i_tags: [
      'グラフィックデザイナー',
      'アートディレクション',
      'CI・Vi',
      'ブランディング',
      'Webデザイン',
      'Illustrator',
      'アートディレクション',
    ],
  },
];

export default { menu, headerMenu, notAuthMenu, testItems };

export const ConfigContext = React.createContext({ menu, headerMenu, notAuthMenu, testItems });
