import '@/styles/common/Admin/SearchResult/save_search_conditions_modal.scss';

import React, { useEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import { BaseButton } from '@/componentsAdmin/Parts/Button';
import { FormContainerTextfield } from '@/componentsAdmin/Parts/Form';
import { ModalDefault } from '@/componentsAdmin/Parts/Modal/Default';
import { YUP_VALIDATION } from '@/definitionDirect/VALIDATION';
import { DirectSearchConditionModel } from '@/utils/api-client';
import { searchConditionsToText } from '@/utils/search';
import { yupResolver } from '@hookform/resolvers/yup';

type Props = {
  isOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  searchConditions: DirectSearchConditionModel | null;
  onClickSave: () => void;
  onClickCancel: React.MouseEventHandler<HTMLDivElement>;
  setSaveSearchConditionsTitle: React.Dispatch<React.SetStateAction<string>>;
  page?: number;
};

type IFormInputs = {
  title: string;
};

export function SaveSearchDirectConditionsModal(props: Props): React.ReactElement {
  // ====================================================================================================
  //
  // RHF
  //
  // ====================================================================================================
  const schema = yup.object().shape({
    title: YUP_VALIDATION.search_condition_title,
  });

  const methods = useForm<IFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
    },
  });

  const { handleSubmit, watch, control } = methods;
  const { isValid } = useFormState({ control });

  // ====================================================================================================
  //
  // Events
  //
  // ====================================================================================================
  /**
   * 保存
   */
  const onSubmit: SubmitHandler<IFormInputs> = () => {
    props.onClickSave();
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    const subscription = watch((value) => {
      props.setSaveSearchConditionsTitle(value.title);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (props.isOpen) {
      methods.setValue('title', '');
    }
  }, [props.isOpen]);

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const searchConditionsText = useMemo(() => {
    if (props.searchConditions) {
      return searchConditionsToText(props.searchConditions);
    }
    return '';
  }, [props.searchConditions]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <ModalDefault
      isOpen={props.isOpen}
      onClose={() => props.onClose(false)}
      className="mbx-save_search_conditions_modal"
      dataLayer={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'modal_open',
        virtualPageName: 'save-conditions',
        pagination: `${props.page ? props.page : 'N/A'}`,
      }}
    >
      <div className="mbx-save_search_conditions_modal__inner">
        <div className="mbx-save_search_conditions_modal__title">タイトルを入力</div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} id="FORM" className="w-full">
            <FormContainerTextfield
              name="title"
              placeholder={`例）要チェック30代男性Webデザイナー（60文字以内）`}
              className="mbx-save_search_conditions_modal__input mkt_mask_items"
            />
          </form>
        </FormProvider>
        <div className="mbx-save_search_conditions_modal__text">{searchConditionsText}</div>
        <div className="mbx-save_search_conditions_modal__ui">
          <BaseButton
            size="m"
            theme="secondary"
            className="mbx-save_search_conditions_modal__button"
            onClick={props.onClickCancel}
          >
            キャンセル
          </BaseButton>
          <BaseButton
            size="m"
            theme="primary"
            type="submit"
            form="FORM"
            disabled={!isValid}
            className="mbx-save_search_conditions_modal__button"
          >
            保存する
          </BaseButton>
        </div>
      </div>
    </ModalDefault>
  );
}
