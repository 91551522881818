import '@/styles/common/Mypage/textbox.scss';

import React from 'react';
import { BlockCompany } from '@/utils/api-client';
import cn from 'classnames';

import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { ButtonTextLink } from '@/components/common/Button/TextLink';

type Props = {
  directStatus: boolean | undefined;
  blockCompanies: BlockCompany[];
};

export const SectionOffer = (props: Props): React.ReactElement => {
  const status = props.directStatus ? "登録中": "停止中";
  const link = props.directStatus ? "/mypage/direct_status": "/mypage/direct";

  return (
    <section className="mt-48">
      <TitleSectionTitle>
        <div className="flex items-center">
          <span className="mr-4">オファー機能</span>
        </div>
      </TitleSectionTitle>
      <p className="text-14_21">オファー機能へ登録しているか、確認できます。</p>
      <p className="text-14_21">「登録中」の場合、ポートフォリオの公開設定にかかわらず、MATCHBOXスカウトサービス参画企業があなたの経歴やポートフォリオを閲覧できます。</p>
      <p className="text-14_21">
        設定の変更は、<ButtonTextLink href={ link }>こちらから</ButtonTextLink>行うことができます。
      </p>
      <div>
        <div className="flex flex-row pt-24 pb-6 items-center">
            <p className="text-12_16 font-bold">現在の設定</p>
          </div>
        <div className="mkt_mask_items">
          <p className="mbx-textbox-color mbx-textbox">{ status }</p>
        </div>
      </div>
      {props.directStatus &&
        <div>
          <div className="flex flex-row pt-24 pb-6 items-center">
              <p className="text-12_16 font-bold">ブロックしている企業</p>
            </div>
          <div className="mkt_mask_items">
            <div className="p-16 mbx-textbox-color text-left">
              <div className={cn('overflow-scroll', 'max-h-212')}>
                <ul className="mbx-typography--body_2">
                  {props.blockCompanies?.length === 0 ? (
                    <li className="mt-12">ブロックしている企業はありません。</li>
                  ) : (
                    props.blockCompanies?.map((list, index) => {
                      return (
                        <li key={index}>
                          ・<span className="inline-block leading-5 text-14_14 mkt_mask_items">{list.name}</span>
                        </li>
                      );
                    })
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      }
    </section>
  );
};
