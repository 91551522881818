import { TDictionary } from '@/types/Util';

export const VALIDATION_MESSAGE: TDictionary<string> = {
  required: '必須項目です',
  minString: '文字以上で入力してください',
  maxString: '文字以内で入力してください',
  url: '正しいURLを入力してください',
  email: '正しいメールアドレスを入力してください',
  tel: '正しい電場番号を入力してください',
  furigana: '正しいふりがなを入力してください',
  password_format: 'パスワードの形式が正しくありません',
  password_confirmation: 'パスワードが一致しません',
  birth_year: '正しい年月日を入力してください。',
  birth_month: '正しい年月日を入力してください。',
  birth_day: '正しい年月日を入力してください。',
  required_new_value: '項目を更新してください',
};

export const DYNAMIC_VALIDATION_MESSAGE = {
  max_file_size: (fileSize: number): string => {
    return `${fileSize}MB以内のファイルを選択してください。`;
  },
};
