import React from 'react';

import { PreviewResumeHistory } from '@/components/common/Preview/ResumeHistory';
import { PreviewResumeTitle } from '@/components/common/Preview/ResumeTitle';
import { CareerSummaryModel, EducationData } from '@/utils/api-client/api';

type TProps = {
  educations?: EducationData[];
  careers?: CareerSummaryModel[];
  emptyEducationItemLength?: number;
  isFirstPage?: boolean;
};

export const ResumePreviewSectionEducations = ({
  educations = [],
  careers = [],
  emptyEducationItemLength = 0,
  isFirstPage = false,
}: TProps): React.ReactElement => {
  if (
    (isFirstPage && educations?.length === 0 && careers?.length >= 14) ||
    (!isFirstPage && educations?.length === 0)
  )
    return <></>;
  const fillEmptyItemLength = emptyEducationItemLength;
  return (
    <div className="flex">
      <PreviewResumeTitle>学歴</PreviewResumeTitle>
      <div className="w-full">
        {educations &&
          Array.from(educations.values()).map((item: EducationData, index: number) => {
            const splitDate = item.e_graduate_date
              ? item.e_graduate_date.split('-')
              : ['　　 ', '　'];
            const description = item.e_school_name ? item.e_school_name : '未記入';
            return (
              <PreviewResumeHistory
                key={index}
                date={`${splitDate[0]}年${splitDate[1]}月`}
                description={description}
                className="mkt_mask_items"
              ></PreviewResumeHistory>
            );
          })}
        {isFirstPage && fillEmptyItemLength > 0
          ? new Array(fillEmptyItemLength).fill(null).map((item, key) => {
              return (
                <PreviewResumeHistory
                  date=""
                  description=""
                  type="empty"
                  key={key}
                ></PreviewResumeHistory>
              );
            })
          : null}
        {isFirstPage && educations?.length >= 16 ? (
          <PreviewResumeHistory date="" description="" type="empty"></PreviewResumeHistory>
        ) : null}
        {!isFirstPage && educations?.length >= 9 ? (
          <PreviewResumeHistory date="" description="" type="empty"></PreviewResumeHistory>
        ) : null}
      </div>
    </div>
  );
};
