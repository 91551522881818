import '@/styles/common/base_button.scss';

import cn from 'classnames';
import React, { ReactNode, VFC } from 'react';
import { Link } from 'react-router-dom';

import { iconNameList } from '@/assets/fonts/manifest';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';

type Props = {
  size: 'm' | 's' | 'ss' | '40';
  theme: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'link' | 'link_secondary';
  href?: string; // url指定がある場合はonClickで毎回同じ処理書くの面倒だからlinkボタンになる機能を持たせる
  image?: string;
  disabled?: boolean;
  children?: ReactNode;
  className?: string;
  iconName?: typeof iconNameList[number];
  width?: 'normal' | 'large';
  iconLayout?: 'default' | 'right';
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  forceExternal?: boolean;
  download?: string;
  type?: 'submit' | 'reset' | 'button' | undefined;
  form?: string;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=476%3A46508
 *
 * 全てのアイコン及びサイズを表示してますのでデザインに存在しないものもあります。必要なシーンに応じて適切な指定をしてください。
 *
 * 単純なリンク機能のみでいい場合は href に外部リンクを指定すると aタグで生成され target="_blank" で外部に遷移します。内部リンクの場合は
 * <Link>で生成されますがstorybook上でテスト出来ませんのでページ内で挙動確認をしてください。
 *
 * その他の処理が必要な場合は onClick に指定してください。
 */

const BaseButtonWrapper: VFC<Pick<Props, 'className' | 'onClick' | 'children' | 'theme'>> = ({
  className,
  onClick,
  theme,
  children,
}) => {
  const wrapperClassName = cn(className, 'base-button', {
    isTextLink: theme === 'link' || theme === 'link_secondary',
  });
  return (
    <div className={wrapperClassName} onClick={onClick}>
      {children}
    </div>
  );
};

const BaseButtonBody: VFC<Pick<Props, 'image' | 'iconName' | 'iconLayout' | 'children'>> = ({
  image,
  iconName,
  iconLayout = 'default',
  children,
}) => {
  return (
    <>
      {image && <img src={image} alt="" />}
      {iconName && iconLayout === 'default' && (
        <i className={`mbx-icon mbx-icon-${iconName} mr-2`}></i>
      )}
      <span className="font-bold label">{children}</span>
      {iconName && iconLayout === 'right' && (
        <i className={`mbx-icon mbx-icon-${iconName} ml-2`}></i>
      )}
    </>
  );
};

export const BaseButton = ({
  className,
  onClick,
  size,
  theme,
  disabled,
  image,
  iconName,
  iconLayout = 'default',
  children,
  href = '',
  width = 'normal',
  forceExternal,
  download,
  type,
  form,
}: Props): React.ReactElement => {
  const mq = useMBXMediaQuery();

  // SPの場合はサイズが s or ss のいずれかで width も normal のみになるので調整
  if (mq.sm) {
    if (/^(40|m)$/.test(size)) {
      size = 's';
    }
    width = 'normal';
  }
  const buttonClass = cn('button', `size-${size}`, `theme-${theme}`, 'flex items-center', {
    isWidthLarge: width === 'large',
  });
  const isExternalLink = href.startsWith('http');

  const props = {
    ...(download && { download: download }),
  };
  return (
    <BaseButtonWrapper className={className} onClick={onClick} theme={theme}>
      {href ? (
        <>
          {isExternalLink || forceExternal ? (
            <a href={href} target="_blank" className={buttonClass} rel="noreferrer" {...props}>
              <BaseButtonBody iconName={iconName} iconLayout={iconLayout} image={image}>
                {children}
              </BaseButtonBody>
            </a>
          ) : (
            <Link to={href} className={buttonClass}>
              <BaseButtonBody iconName={iconName} iconLayout={iconLayout} image={image}>
                {children}
              </BaseButtonBody>
            </Link>
          )}
        </>
      ) : (
        <button className={buttonClass} disabled={disabled} type={type} form={form}>
          <BaseButtonBody iconName={iconName} iconLayout={iconLayout} image={image}>
            {children}
          </BaseButtonBody>
        </button>
      )}
    </BaseButtonWrapper>
  );
};
