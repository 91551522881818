import '@/styles/common/Admin/UserDetail/message_form_sent.scss';

import cn from 'classnames';
import dayjs from 'dayjs';
import React from 'react';

import { AdminNoteModel, CompanyUser, Note } from '@/utils/api-client';

type Props = {
  onRemove(id: number): void;
  authorId?: string;
  note: Note | AdminNoteModel;
};

export function MessageFormSent(props: Props): React.ReactElement {
  const onRemove = (id: number | undefined) => {
    if (!id) return;
    props.onRemove(id);
  };
  return (
    <section className={cn('mbx-message_form_sent', 'space-x-8', { un_read: !props.note.is_read })}>
      <div className="mbx-message_form_sent-thumb">
        <img src="/images/profile_icon.png" className="mkt_mask_items" alt="" />
      </div>
      <div className="mbx-message_form_sent-body space-y-4">
        <header className="space-x-4">
          <strong className="text-12_18 font-bold mkt_mask_items">{props.note.user?.name}</strong>
          <span className="text-10_16 text-gray-600 font-bold mkt_mask_items">
            {(props.note.user as CompanyUser).section}
          </span>
          <span className="text-10_16 text-gray-600 font-bold mkt_mask_items">
            {(props.note.user as CompanyUser).post}
          </span>
          <small className="text-10_16 text-gray-600">
            {dayjs(props.note.created_at).format('YYYY年MM月DD日 HH:mm')}
          </small>
          {props.authorId === (props.note as Note).user?.matchbox_id && (
            <a
              className="text-10_16 text-blue-700 underline"
              onClick={() => onRemove(props.note.id)}
            >
              削除
            </a>
          )}
        </header>
        <p className="text-12_20 mkt_mask_items">{props.note.content}</p>
      </div>
    </section>
  );
}
