import React, { MouseEventHandler, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ButtonIconButton } from '../Button/IconButton';

type TProps = {
  show: boolean;
  children: string | null;
  onClick?: MouseEventHandler<HTMLSpanElement>;
};

/**
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=5332%3A102628
 */
export const NotificationSnackBar = ({
  children,
  show = false,
  onClick,
}: TProps): React.ReactElement => {
  // !see https://github.com/reactjs/react-transition-group/issues/668
  const nodeRef = React.useRef(null);

  const [showState, setShow] = useState(show);
  const callBacks = {
    onEnter: () => console.log('show success'),
    onExited: () => console.log('hide success'),
  };

  useEffect(() => {
    setShow(show);
  }, [show]);
  return (
    <CSSTransition
      in={showState}
      timeout={550}
      nodeRef={nodeRef}
      {...callBacks}
      unmountOnExit
      classNames="mbx-anime-notification"
    >
      <div ref={nodeRef} className={'mbx-notification mbx-notification--success'}>
        <div className="mbx-notification-inner">
          <div className={'mbx-notification-message'}>{children}</div>
          <div className={'mbx-notification-icon'}>
            <ButtonIconButton
              type="white"
              iconName="Menu-close"
              hitArea="medium"
              onClick={onClick}
            ></ButtonIconButton>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
