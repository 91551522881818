import React, { VFC } from 'react';

type TProps = {
  type: 'small' | 'medium';
  label: string;
  forName?: string;
  className?: string;
  children?: React.ReactNode;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=1%3A92
 */
export const FormLabel: VFC<TProps> = ({ type, label, forName, className, children }) => {
  return type == 'small' ? (
    <div className={className}>
      <div className="flex flex-row py-6 items-center">
        <label className="text-12_16 font-bold" htmlFor={forName}>
          {label}
        </label>
        <p className="text-11_12 ml-16">{children}</p>
      </div>
    </div>
  ) : (
    <div className={className}>
      <label className="text-subhead_1 font-bold mb-8 block" htmlFor={forName}>
        {label}
      </label>
      <p className="text-caption_1">{children}</p>
    </div>
  );
};
