import { DataLayerType } from '@/types/DataLayerType';
import {
    FileResponse, MatchboxUserResponse, Portfolio, Status, UserInfo
} from '@/utils/api-client/';
import { PayloadAction } from '@reduxjs/toolkit';

import { State } from './state';

export const reducers = {
  toggleImageCropper: (
    state: State,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: PayloadAction<{ data: any; width: number; height: number } | null>
  ): State => {
    return { ...state, imageCropper: action.payload };
  },
  toggleLoading: (state: State, action: PayloadAction<boolean>): State => {
    return { ...state, loading: action.payload };
  },
  isLoggedIn: (state: State): State => {
    return { ...state, isLoggedIn: true, isLoggedOut: false };
  },
  isLoggedOut: (state: State): State => {
    return { ...state, isLoggedOut: true, isLoggedIn: false };
  },
  auth: (state: State, action: PayloadAction<boolean>): State => {
    if (action.payload) {
      return { ...state, isAuth: action.payload };
    } else {
      return {
        ...state,
        isAuth: action.payload,
        user: null,
        dataLayer: {},
      };
    }
  },
  userInfo: (state: State, action: PayloadAction<UserInfo>): State => {
    if (state.user) return state;
    return { ...state, user: action.payload };
  },
  // TODO 本来は上記のやつ一つで対応できそうなのでリファクタリングできそうならした方がいい
  // !see https://github.com/prtytokyo/matchbox-2021-web-frontend/issues/1299
  userInfoForceUpdate: (state: State, action: PayloadAction<UserInfo>): State => {
    return { ...state, user: action.payload };
  },
  setUserEmail: (state: State, action: PayloadAction<UserInfo>): State => {
    return { ...state, user: action.payload };
  },
  userResponseAll: (state: State, action: PayloadAction<MatchboxUserResponse>): State => {
    return { ...state, userBasic: action.payload };
  },
  notificationError: (state: State, action: PayloadAction<string | null>): State => {
    return { ...state, errorMessage: action.payload };
  },
  notificationSuccess: (state: State, action: PayloadAction<string | null>): State => {
    return { ...state, successMessage: action.payload };
  },
  setPortfolioStatusOfPDF: (state: State, action: PayloadAction<number | null>): State => {
    localStorage.setItem('portfolioStatusOfPDF', action.payload + '');
    return { ...state, portfolioStatusOfPDF: action.payload };
  },
  setPortfolioTicketID: (state: State, action: PayloadAction<number | undefined>): State => {
    localStorage.setItem('portfolioTicketID', action.payload + '');
    return { ...state, portfolioTicketID: action.payload };
  },
  setPortfolioPDFDownloadModal: (state: State, action: PayloadAction<boolean>): State => {
    return { ...state, portfolioPDFDownloadModal: action.payload };
  },
  setUploadedImage: (state: State, action: PayloadAction<FileResponse | null>): State => {
    return { ...state, uploadedImage: action.payload };
  },
  setSaved: (state: State, action: PayloadAction<boolean>): State => {
    return { ...state, saved: action.payload };
  },
  setPrepareFrag: (state: State, action: PayloadAction<boolean>): State => {
    return { ...state, prepareFrag: action.payload };
  },
  setPortfolio: (state: State, action: PayloadAction<(Portfolio & Status) | undefined>): State => {
    return { ...state, portfolio: action.payload };
  },
  setPathAfterLogin: (state: State, action: PayloadAction<string | undefined>): State => {
    return { ...state, pathAfterLogin: action.payload };
  },
  setPathAfterLogout: (state: State, action: PayloadAction<string>): State => {
    return { ...state, pathAfterLogout: action.payload };
  },
  // インプット要素のクリア処理で自動パッチ処理が動かないのでフラグを指定
  // !see https://github.com/prtytokyo/matchbox-2021-web-frontend/blob/develop/src/components/common/Form/Container/Textfield.tsx#L68
  setInputClearingInProgress: (state: State, action: PayloadAction<boolean>): State => {
    return { ...state, inputClearingInProgress: action.payload };
  },
  setDataLayer: (state: State, action: PayloadAction<DataLayerType>): State => {
    return {
      ...state,
      dataLayer: {
        ...state.dataLayer,
        ...action.payload,
      },
    };
  },
  setDataLayerChangedBy: (state: State, action: PayloadAction<string>): State => {
    return { ...state, dataLayerChangedBy: `${action.payload}_${Math.random()}` };
  },
};
