import React from 'react';

import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';

/**
 * 検索条件編集 タイトル
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65909
 */
export const DirectSearchFormSectionTitle = (): React.ReactElement => {
  return (
    <FormLayoutFieldset className="mb-25 w-480">
      <FormLabel label="タイトル" type="small"></FormLabel>
      <FormContainerTextfield name="search_condition_name" className="w-full mkt_mask_items" />
    </FormLayoutFieldset>
  );
};
