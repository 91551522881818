import cn from 'classnames';
import React, { ReactNode, useState } from 'react';

import BaseButton from '@/components/common/Button/BaseButton';
import HeaderTitle from '@/components/common/Title/HeaderTitle';

type TProps = {
  children?: ReactNode;
};

export const ResumeHeader = ({ children }: TProps): React.ReactElement => {
  const [spNaviFlg, setSpNaviFlg] = useState(false);
  const toggleNavi = () => {
    setSpNaviFlg((prevState) => {
      return !prevState;
    });
  };
  const spNaviClass = cn('hidden sm:block', { 'is-open': spNaviFlg });
  const spNaviBgClass = cn({ 'mbx-naviInpage-wrapper': spNaviFlg });
  return (
    <HeaderTitle title="履歴書の編集">
      <div className="flex justify-end sm:-mr-16">
        <BaseButton theme="secondary" size="40" href="/mypage/resume" width="large">
          プレビュー
        </BaseButton>
        <div className={spNaviClass}>
          <div className="p-4 text-corporate-blue" onClick={toggleNavi}>
            <i className="mbx-icon mbx-icon-Kebab text-40_40"></i>
          </div>
          <div
            className={spNaviBgClass}
            onClick={(e) => {
              if ((e.target as HTMLHtmlElement).className === 'mbx-naviInpage-wrapper') {
                toggleNavi();
              }
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </HeaderTitle>
  );
};
