import React, { useMemo } from 'react';
import Back from '@/components/common/Navigation/Page/Back';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';

import {
  MainVisual,
  PortforioSection,
  PageMenuContent,
  PortfolioEditSummary,
  PortfolioEditManagement,
  PortfolioEditPoints,
  PortfolioEditImages,
} from '@/components/common/Portfolio';

import * as Page from '@/components/common/Page';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { PortfolioItem, UserInfo } from '@/utils/api-client';
import { useDetailEdit } from '@/hooks/Portfolio/useDetailEdit';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';
import { BaseButton } from '@/components/common/Button/';
import HeaderTitle from '@/components/common/Title/HeaderTitle';

function DetailEdit(): React.ReactElement {
  const mq = useMBXMediaQuery();
  const user: UserInfo | null = useSelector((state: State) => state.user);
  const history = useHistory();
  const id = history.location.pathname.split('/')[4];

  const path = useMemo((): string => {
    if (!user) return '/error';
    if (user?.user_id === undefined) return '/error';
    return `/mypage/portfolio/${user?.user_id}/${id}`;
  }, [user]);

  const {
    pfItem,
    pfData,
    pfItemId,
    fetchPortfolio,
    patchPortfolio,
    setQuestionParam,
    setItem,
    setParam,
    setBeforeUnload
  } = useDetailEdit();

  const questionFields = [
    { qa_id: 'b_v2_1', qa_category: '関わり方', qa_question: '職種', qa_answer: [{ answer: '' }] },
    {
      qa_id: 'b_v2_2',
      qa_category: '関わり方',
      qa_question: '担当内容',
      qa_answer: [{ answer: '' }],
    },
    { qa_id: 'b_v2_8', qa_category: '関わり方', qa_question: '経験', qa_answer: [{ answer: '' }] },
    {
      qa_id: 'b_v2_7',
      qa_category: '関わり方',
      qa_question: '使用ツール',
      qa_answer: [{ answer: '' }],
    },
  ];

  const questionPlaceholder = [
    'Webデザイナー',
    'Webデザイン',
    'デザインフォーマットの作成',
    'Photoshop',
  ];

  const linkToTop = () => {
    history.push('/mypage/portfolio/edit');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updatePortfolioItem = async (i_param: any) => {
    if (!pfData) return;

    const items: PortfolioItem[] = Array.from(pfData.items?.values() || []).map((item) => {
      if (item.i_id !== pfItemId) return item;
      return {
        ...item,
        ...i_param,
      };
    });

    const data = {
      ...Object.assign(pfData),
      items,
    };
    await patchPortfolio(data);
  };

  const onUpdateSections = (qId: string, sections: string[]) => {
    setQuestionParam(qId, sections);
  };

  const onUpdateQuestion = (qId: string, sections: string[]) => {
    setQuestionParam(qId, sections);
  };

  const onUpdatePoints = (qaId: string, question: string, answer: string[]) => {
    setQuestionParam(qaId, answer, question);
  };

  const style = pfData?.updated_at ? { justifyContent: 'space-between' } : undefined;

  return (
    <div className="portfolio-detail">
      <Page.Wrapper
        header={
          <div className={cn({"sticky top-0 z-10": !mq.sm})}>
            <HeaderTitle title={'ポートフォリオ'}>
              <div className="portfolio-detail-preview-wrapper" style={style}>
                {pfData?.updated_at && (
                  <p className="text-gray-700 text-10_10">最終更新：{pfData?.updated_at}</p>
                )}
                <div className="ml-16 w-140 sp_none">
                  {user && (
                    <BaseButton
                      theme="secondary"
                      size="40"
                      href={path}
                      className="w-full whitespace-nowrap"
                    >
                      プレビュー
                    </BaseButton>
                  )}
                </div>
              </div>
            </HeaderTitle>
          </div>
        }
        footer={
          <section className="sp-edit_btn sp_only mbx-preview-fix-btn-area sm:mb-144">
            <BaseButton
              theme={'secondary'}
              size={'m-for-sp'}
              href={path}
            >
              プレビューする
            </BaseButton>
          </section>
        }
      >
        <div>
          <Page.Column
            main={
              <>
                <Back onClick={() => linkToTop()}>作品一覧へ</Back>

                <MainVisual
                  item={pfItem}
                  fetchPortfolio={fetchPortfolio}
                  updatePortfolioItem={updatePortfolioItem}
                />

                <div id="summary">
                  <TitleSectionTitle type="large" className="mt-12">
                    作品概要
                  </TitleSectionTitle>
                  <PortfolioEditSummary
                    portfolio={pfData}
                    itemId={pfItemId}
                    setItem={setItem}
                    onUpdateQuestion={onUpdateQuestion}
                    setBeforeUnload={setBeforeUnload}
                  />
                </div>

                <PortforioSection
                  qaId="a_2"
                  portfolio={pfData}
                  itemId={pfItemId}
                  onUpdateSections={(sections) => onUpdateSections('a_2', sections)}
                  title="受賞歴"
                  placeholder="例）カンヌライオンズ2019 モバイル部門 グランプリ"
                  setBeforeUnload={setBeforeUnload}
                />

                <div id="relation">
                  <TitleSectionTitle type="large" className="mt-12">
                    案件への関わり方
                  </TitleSectionTitle>
                  {questionFields.map((value, index) => (
                    <PortforioSection
                      key={index}
                      title={value.qa_question}
                      qaId={value.qa_id}
                      portfolio={pfData}
                      itemId={pfItemId}
                      onUpdateSections={(sections) => onUpdateSections(value.qa_id, sections)}
                      placeholder={questionPlaceholder[index]}
                      setBeforeUnload={setBeforeUnload}
                    />
                  ))}
                </div>

                <PortfolioEditManagement
                  portfolio={pfData}
                  itemId={pfItemId}
                  onUpdateQuestion={onUpdateQuestion}
                />

                <div id="point">
                  <TitleSectionTitle type="large" className="mt-12">
                    制作のポイント
                  </TitleSectionTitle>
                  <PortfolioEditPoints
                    portfolio={pfData}
                    itemId={pfItemId}
                    onUpdatePoints={onUpdatePoints}
                    setBeforeUnload={setBeforeUnload}
                  />
                </div>

                <div id="media">
                  <TitleSectionTitle type="large" className="mt-12">
                    画像・動画など
                  </TitleSectionTitle>
                  <PortfolioEditImages
                    portfolio={pfData}
                    itemId={pfItemId}
                    setParam={setParam}
                    setBeforeUnload={setBeforeUnload}
                  />
                </div>
              </>
            }
            menu={
              <div className="flex items-end flex-col">
                <div className="mbx-naviInpage flex justify-end lg:pt-64">
                  {mq.lg && <PageMenuContent />}
                </div>
                {mq.lg && (
                  <div className="mt-30 w-180 lg:w-240 border-t border-b border-gray-400">
                    <div className="py-30">
                      <a
                        href="/doc/image-size.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="flex items-center justify-center text-12_12 font-bold text-blue-700"
                      >
                        推奨画像サイズ一覧<i className="pl-10 text-24_24 mbx-icon mbx-icon-External_link-thin"></i>
                      </a>
                    </div>
                  </div>
                )}
              </div>
            }
          />
        </div>
      </Page.Wrapper>
    </div>
  );
}

export default DetailEdit;
