import { AxiosError } from 'axios';
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { FormContainerTextfield } from '@/components/common/Form/';
import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ButtonTextLink } from '@/components/common/Button/TextLink';
import Modal from '@/components/common/Modal';
import MatchImg from '@/images/navi-match.png';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import { ResponseError } from '@/utils/api-client';

import { yupResolver } from '@hookform/resolvers/yup';

type TInput = {
  family_name: string;
  first_name: string;
  email: string;
};

export const Application = ({
  modalIsOpen,
  setModalIsOpen,
}: {
  modalIsOpen: boolean;
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
}): React.ReactElement => {
  const [done, setDone] = useState(false);
  const dispatch = useDispatch();
  const { push } = React.useContext(DataLayerContext);

  const schema = yup.object().shape({
    family_name: YUP_VALIDATION.required,
    first_name: YUP_VALIDATION.required,
    email: YUP_VALIDATION.email_required,
  });
  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      family_name: '',
      first_name: '',
      email: '',
    },
  });

  const { handleSubmit, reset } = methods;

  const onSubmit = async (data: TInput) => {
    const postParam = {
      family_name: data.family_name,
      first_name: data.first_name,
      email: data.email,
    };
    dispatch(toggleLoading(true));
    try {
      // KARTEに渡す値は？
      // push({
      //   event: 'candidatePickup',
      //   actionType: 'candidate_pickup',
      //   actionName: `${rate}::${status}`,
      //   candidates: additionalData ? [additionalData] : [],
      // });

      const url = 'https://demo.matchbox.work/api/v2/direct/demo-registration';
      const json = JSON.stringify(postParam);
      const response = await fetch(url, {
        method: "POST",
        body: json,
      });
      if (response.status !== 200) throw new Error();
      setDone(true);
    } catch (error) {
      dispatch(notificationError('フリーメールやキャリアのアドレスはご利用できません'));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  useEffect(() => {
    const bodyEl = document.getElementsByTagName('body');
    if (modalIsOpen) {
      bodyEl[0].classList.add('overflow-hidden');
    } else {
      bodyEl[0].classList.remove('overflow-hidden');
      reset();
    }
  }, [modalIsOpen]);

  return (
    <Modal
      isOpen={modalIsOpen}
      isClose={() => setModalIsOpen(false)}
      className="mbx-direct-users-detail-modal"
    >
      <div className="max-w-784 w-784 md:w-screen sm:w-screen sm:h-auto overflow-hidden p-80 sm:pt-48 sm:pb-120 sm:px-24">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col items-center justify-center">
            <img className="h-56 w-56 filter drop-shadow-matchbouIcon" src={MatchImg} alt="" />
            {done ? (
              <>
                <h2 className="text-21_31 font-bold my-24 ga4_complete_demo">
                  申し込み誠にありがとうございます
                </h2>
                <p className="text-14_23 mb-30">
                  ご入力いただいたメールアドレスにデモ画面のURL をお送りしましたのでご確認ください。
                </p>
                <p className="text-14_23 mb-30">
                  なお、デモ画面は【<span className="font-bold">3日間限定</span>】のWeb ページとなります。あらかじめご了承ください。<br />
                  何卒よろしくお願い申し上げます。
                </p>
              </>
            ) : (
              <>
                <h2 className="text-21_31 font-bold my-24">
                  MATCHBOXデモ画面<br />
                  無料申し込みフォーム
                </h2>
                <p className="text-14_23 mb-30">
                  下記項目を入力の上、送信してください。<br />
                  デモ画面のURLをご入力いただいたメールアドレスにご案内します。
                </p>
                <div className="mb-30">
                  <h3 className="text-12_16 font-bold mb-8 mbx-status_clients__title">氏名<span className="text-9_16 font-medium">（必須）</span></h3>
                  <div className="flex justify-center">
                    <FormContainerTextfield
                      placeholder="姓"
                      name="family_name"
                      className="w-150 mr-8"
                    />
                    <FormContainerTextfield
                      placeholder="名"
                      name="first_name"
                      className="w-150"
                    />
                  </div>
                </div>
                <div className="mb-48">
                  <h3 className="text-12_16 font-bold mb-8 mbx-status_clients__title">会社のメールアドレス<span className="text-9_16 font-medium">（必須）</span></h3>
                  <FormContainerTextfield
                    placeholder="例）sample@matchbox.com"
                    name="email"
                    className="w-308"
                  />
                </div>
                <div className="mb-24 flex">
                  <div className="mr-16 w-40">
                    <a href="https://privacymark.jp/" target="_blank" rel="noreferrer">
                      <img src="/images/p-mark.png" alt="" />
                    </a>
                  </div>
                  <p className="text-12_20">
                    <ButtonTextLink href={window.location.protocol + '/direct/terms_of_use#privacy'}>
                      個人情報の取り扱い
                    </ButtonTextLink>
                    に同意した上で、アカウント登録にお進みください。<br />
                    個人情報の送信はすべてSSL通信で暗号化され、保護されます。
                  </p>
                </div>
                <div className="flex justify-between space-x-16 w-308">
                  <BaseButton className="flex-1" size="m" theme="primary" type="submit">
                    個人情報の取り扱いに同意して送信する
                  </BaseButton>
                </div>
              </>
            )}
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};
