import React, { useState, useEffect, useRef, useMemo} from 'react';
import BaseButton from '@/components/common/Button/BaseButton';
import '@/styles/common/Portfolio/MainVisual/image.scss';
import '@/styles/common/Portfolio/ImagePage/image_general.scss';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery'
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';

type Props = {
  src: string | undefined;
  onClick(): void;
  onRemove: React.MouseEventHandler<HTMLDivElement>;
  onLoadImage(result: File): void;
  onCancel: React.MouseEventHandler<HTMLDivElement>;
};

export function Image(props: Props): React.ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const mq = useMBXMediaQuery();
  const [src, setSrc] = useState<string | undefined>(undefined);
  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    accept: [".jpg", ".jpeg", ".png", ".gif"]
  });

  useEffect(() => {
    if (acceptedFiles[0]) props.onLoadImage(acceptedFiles[0]);
  }, []);

  useEffect(() => {
    if (acceptedFiles[0]) props.onLoadImage(acceptedFiles[0]);
  }, [acceptedFiles[0]]);

  useEffect(() => {
    if (!props.src) return ;
    const img = document.createElement('img');
    img.onload = () => {
      setSrc(props.src);
    };
    img.src = props.src;
  }, [props.src]);

  const height = useMemo(() => {
    if (imgRef.current) {
      const { clientWidth } = imgRef.current;
      if (clientWidth > 0) {
        return (clientWidth / 16) * 9;
      }
    }
    return ((mq.sm ? window.innerWidth : 624) / 16) * 9;
  }, [imgRef.current, src, mq]);

  return (
    <section className="mbx-image">
      <div className="w-full h-full flex flex-col">
        <div
          className={`lg:bg-gray-50 md:bg-gray-50 lg:w-full md:w-full justify-center items-center flex-1`}
        >
          <section {...getRootProps({ className: cn('dropzone', 'w-full', 'm-auto', src ? 'h-full' : 'mbx-image_general_outer') })}>
            <div className={`mbx-image_general_inner sm:bg-gray-50`}>
              {src ? (
                <img
                  src={src}
                  className="mkt_mask_items bg-gray-50 object-cover"
                  alt=""
                  ref={imgRef}
                  style={{ height }}
                />
              ) : (
                <button className="mbx-image_general_input" onClick={props.onClick}>
                  <div className="mbx-icon mbx-icon-Large32_Plus" />
                  <span className="my-10">
                    ファイルを選択
                    <span className="sm:hidden">
                      または
                      <br />
                      ドラッグ&ドロップ
                    </span>
                  </span>
                </button>
              )}
            </div>
            <input
              className="mbx-image_general_input-file"
              type="file"
              ref={inputRef}
              name=""
              id=""
              {...getInputProps({multiple: false})}
            />
          </section>
        </div>
      </div>
      <div className="flex justify-between my-16">
        <div>
          {src &&
            <TooltipMessageMatchbou
              className="flex items-center"
              message="画像が表示されない場合は「他の画像を設定」より新しい画像を設定してください。"
              position={['0', '-64px']}
            >
              <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
              <span className="text-12_16 font-bold">表示されない場合</span>
            </TooltipMessageMatchbou>
          }
        </div>
        {src ? (
          <div className="space-x-16 text-right">
            <BaseButton theme="link" size="s" iconName="Placeholder_image" onClick={open}>
            他の画像を設定
            </BaseButton>

            <BaseButton theme="link" size="s" iconName="Trash" onClick={props.onRemove}>
              画像を削除
            </BaseButton>
          </div>
        ) : (
          <div className="space-x-16 text-right">
            <BaseButton theme="link" size="s" onClick={props.onCancel}>
            作品タイプの選択に戻る
            </BaseButton>
          </div>
        )}
      </div>
    </section>
  );
}
