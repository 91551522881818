import React from 'react';
import { FieldValues, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { ButtonTextLink } from '@/components/common/Button/TextLink';
import { FormContainer, TCallback } from '@/components/common/Form/Container';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { PLACEHOLDER } from '@/definition/PLACEHOLDER';
import { usePolling } from '@/hooks/usePolling';
import { TSectionProps } from '@/pages/Resume/Edit';
import { setInputClearingInProgress } from '@/redux';
import { ResumeApi } from '@/utils/api-client/api';

export const ResumeFormSectionAddress = ({ id, fetchData }: TSectionProps): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [param, setParam, response, saveFlag, setBeforeUnload] = usePolling(
    ResumeApi,
    'patchResume',
    undefined,
    2000
  );
  const dispatch = useDispatch();

  const useFormProps: UseFormProps = {
    mode: 'onBlur',
    defaultValues: {
      tel: fetchData ? fetchData.user?.user_info?.tel : '',
      postal_code: fetchData ? fetchData.user?.user_info?.postal_code : '',
      email: fetchData ? fetchData.user?.user_info?.email : '',
      address1: fetchData ? fetchData.user?.user_info?.address1 : '',
      address2: fetchData ? fetchData.user?.user_info?.address2 : '',
    },
  };
  const methods = useForm(useFormProps);

  const onPatch: TCallback = (value, name, type) => {
    if (name === 'postal_code' && value.postal_code !== null) return;
    if (name === 'address2' && value.postal_code !== null) return;
    if (name === 'address1' && value.postal_code !== null) return;
    if (name === 'tel' && value.postal_code !== null) return;
    if (name === 'email' && value.postal_code !== null) return;
    // type が undefinedの場合は RFH の setValue を利用した値反映なのでpatchしない
    if (name && type) {
      dispatch(setInputClearingInProgress(false));
      const data = {
        user: {
          user_info: {
            [name]: value[name] ? value[name] : null,
          },
        },
      };
      setParam({ data });
    }
  };

  const onBlur = (name: string) => {
    const value = methods.getValues();
    const data = {
      user: {
        user_info: {
          [name]: value[name] ? value[name] : null,
        },
      },
    };
    setParam({ data });
  };

  return (
    <div id={id}>
      <FormContainer
        useFormProps={useFormProps}
        onPatch={onPatch}
        fetchData={fetchData}
        methods={methods as UseFormReturn<FieldValues, Record<string, unknown>>}
      >
        <TitleSectionTitle>
          <div className="flex items-center">
            <span className="mr-4">現住所</span>
            <TooltipMessageMatchbou
              className=""
              message="オファー機能ご利用時、「郵便番号」「住所」「住所ふりがな」「電話番号」「メールアドレス」は企業に開示されません。"
              position={['0', '-94px']}
            >
              <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
            </TooltipMessageMatchbou>
          </div>
        </TitleSectionTitle>
        <div className="mbx-utils-stack-v--16">
          <FormLayoutFieldset>
            <FormLabel label="郵便番号" type="small"></FormLabel>
            <FormContainerTextfield
              name="postal_code"
              className="w-150 mkt_mask_items"
              placeholder={PLACEHOLDER.postal_code}
              type="number"
              onFocus={() => setBeforeUnload(true)}
              onBlur={() => onBlur('postal_code')}
            />
          </FormLayoutFieldset>
          <FormLayoutFieldset>
            <FormLabel label="住所" type="small"></FormLabel>
            <FormContainerTextfield
              name="address2"
              className="mkt_mask_items"
              placeholder={PLACEHOLDER.address2}
              onFocus={() => setBeforeUnload(true)}
              onBlur={() => onBlur('address2')}
            />
          </FormLayoutFieldset>
          <FormLayoutFieldset>
            <FormLabel label="住所ふりがな" type="small"></FormLabel>
            <FormContainerTextfield
              name="address1"
              className="mkt_mask_items"
              placeholder={PLACEHOLDER.address1}
              onFocus={() => setBeforeUnload(true)}
              onBlur={() => onBlur('address1')}
            />
          </FormLayoutFieldset>
          <FormLayoutFieldset>
            <FormLabel label="メールアドレス" type="small"></FormLabel>
            <FormContainerTextfield
              name="email"
              className="mkt_mask_items"
              placeholder={PLACEHOLDER.email}
              onFocus={() => setBeforeUnload(true)}
              onBlur={() => onBlur('email')}
            />
          </FormLayoutFieldset>
          <FormLayoutFieldset>
            <FormLabel label="電話番号" type="small">
              <FormCaption></FormCaption>
            </FormLabel>
            <FormContainerTextfield name="tel" readOnly={true} className="mkt_mask_items" />
          </FormLayoutFieldset>
        </div>
      </FormContainer>
    </div>
  );
};

const FormCaption = (): React.ReactElement => {
  return (
    <>
      <ButtonTextLink href="/mypage/profile">基本情報</ButtonTextLink>から編集できます
    </>
  );
};
