import React from 'react';
import { useDataLayer, DataLayerType } from '@/hooksDirect/common/useDataLayer';

export const DataLayerContext = React.createContext<{ push: (param: DataLayerType) => void }>({
  push: () => {
    //
  },
});

export const DataLayerProviderContainer: React.FC = (props) => {
  const { push } = useDataLayer();

  return (
    <DataLayerContext.Provider value={{push}}>
      {props.children}
    </DataLayerContext.Provider>
  );
};