import '@/styles/common/Direct/MyNaviSelectForm.scss';

import React from 'react';
import { OnboardingPageWrapper } from './PageWrapper';
import { PageDots } from './PageDots';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';

type TStep = {
  step1: boolean,
  step2: boolean,
  step3: boolean,
  step4: boolean,
  step5: boolean,
  step6: boolean,
};

type Props = {
  page: number;
  step: TStep;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalDots: number;
  onClose: () => void;
};

export const OnboardingPage_6 = (props: Props): React.ReactElement => {

  return (
    <DataLayerPushContainer
      data={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'modal_open',
        virtualPageName: 'on-boarding_step_6'
      }}
    >
    <div>
      <OnboardingPageWrapper
        description={
          <span>企業情報設定が完了しました！</span>
        }
      >
        <div>
          <p className="mt-14 text-15_32">さっそく求職者を探してみましょう。</p>
          <div className="mt-26">
            <div className="pb-20">
              <div className="w-240 m-auto mt-48">
                {location.pathname === "/direct/search" ? (
                  <BaseButton
                    theme="primary"
                    size="m"
                    className="w-full"
                    onClick={props.onClose}
                  >
                    求職者を検索する
                  </BaseButton>
                ) : (
                  <BaseButton
                    theme="primary"
                    size="m"
                    className="w-full"
                    href="/direct/search"
                  >
                    求職者を検索する
                  </BaseButton>
                )}
              </div>
              <div className="w-240 m-auto mt-24">
                <BaseButton
                  theme="link"
                  size="m"
                  className="w-full"
                  href="/direct/company"
                >
                  企業情報設定ページへ
                </BaseButton>
              </div>
              <div className="w-240 m-auto mt-24">
                <BaseButton
                  theme="link"
                  size="m"
                  className="w-full"
                  href="/direct/offer_positions"
                >
                  ポジション管理ページへ
                </BaseButton>
              </div>
              <PageDots page={props.page} setPage={props.setPage} step={props.step} totalDots={props.totalDots} unClick className="mt-48" />
            </div>
          </div>
        </div>
      </OnboardingPageWrapper>
    </div>
    </DataLayerPushContainer>
  );
};
