import '@/styles/pages/Other/TermsOfUse.scss';
import '@/styles/common/modal.scss';

import React, { useEffect } from 'react';
import * as Page from '@/components/common/Page';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import TermsText from '@/components/common/OtherComponents/TermsText';

function TermsOfUse(): React.ReactElement {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page.Wrapper header={<HeaderTitle title={'利用規約'} />}>
      <section className="w-624 m-auto mb-120 sm:mb-233 sm:w-full">
        <TermsText />
      </section>
    </Page.Wrapper>
  );
}

export default TermsOfUse;
