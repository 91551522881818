import '@/styles/common/Admin/SearchResult/save_search_conditions_modal.scss';

import React, { useEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';

import { BaseButton } from '@/componentsAdmin/Parts/Button';
import { FormContainerTextfield } from '@/componentsAdmin/Parts/Form';
import { ModalDefault } from '@/componentsAdmin/Parts/Modal/Default';
import { YUP_VALIDATION } from '@/definitionDirect/VALIDATION';
import { useUserTag } from '@/hooksAdmin/useUserTag';
import { AdminSearchConditionModel } from '@/utils/api-client';
import { searchConditionsToText } from '@/utils/search';
import { yupResolver } from '@hookform/resolvers/yup';

type Props = {
  isOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  searchConditions: AdminSearchConditionModel | null;
  onClickSave: () => void;
  onClickCancel: React.MouseEventHandler<HTMLDivElement>;
  setSaveSearchConditionsTitle: React.Dispatch<React.SetStateAction<string>>;
  page?: number;
};

type IFormInputs = {
  title: string;
};

export function SaveSearchConditionsModal(props: Props): React.ReactElement {
  // ====================================================================================================
  //
  // Hooks
  //
  // ====================================================================================================
  const { getUserTags, userTags } = useUserTag();

  // ====================================================================================================
  //
  // RHF
  //
  // ====================================================================================================
  const schema = yup.object().shape({
    title: YUP_VALIDATION.search_condition_title,
  });

  const location = useLocation();

  const methods = useForm<IFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      title: '',
    },
  });

  const { handleSubmit, watch, control } = methods;
  const { isValid } = useFormState({ control });

  // ====================================================================================================
  //
  // Events
  //
  // ====================================================================================================
  /**
   * 保存
   */
  const onSubmit: SubmitHandler<IFormInputs> = () => {
    props.onClickSave();
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    const subscription = watch((value) => {
      props.setSaveSearchConditionsTitle(value.title);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (props.isOpen) {
      methods.setValue('title', '');
    }
  }, [props.isOpen]);

  useEffect(() => {
    getUserTags();
  }, []);

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const searchConditionsText = useMemo(() => {
    if (props.searchConditions) {
      // conditionsにuserTagsが含まれている場合は、検索条件をテキストに変換
      if (props.searchConditions.user_tags && props.searchConditions.user_tags?.length > 0) {
        if (userTags && userTags?.length > 0) {
          const mutationConditions = { ...props.searchConditions };
          mutationConditions.user_tags = props.searchConditions.user_tags.map((v) => {
            const userTag = userTags.find((item) => item.id == v);
            return userTag?.name || '';
          });
          return searchConditionsToText(mutationConditions);
        } else {
          return '';
        }
      } else {
        return searchConditionsToText(
          props.searchConditions,
          location.pathname.indexOf('admin/direct/search') === -1 ? 'admin' : 'direct'
        );
      }
    }

    return '';
  }, [props.searchConditions, userTags]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <ModalDefault
      isOpen={props.isOpen}
      onClose={() => props.onClose(false)}
      className="mbx-save_search_conditions_modal"
      dataLayer={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'modal_open',
        virtualPageName: 'save-conditions',
        pagination: `${props.page ? props.page : 'N/A'}`,
      }}
    >
      <div className="mbx-save_search_conditions_modal__inner">
        <div className="mbx-save_search_conditions_modal__title">タイトルを入力</div>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} id="FORM" className="w-full">
            <FormContainerTextfield
              name="title"
              placeholder={`例）要チェック30代男性Webデザイナー（60文字以内）`}
              className="mbx-save_search_conditions_modal__input mkt_mask_items"
            />
          </form>
        </FormProvider>
        <div className="mbx-save_search_conditions_modal__text">{searchConditionsText}</div>
        <div className="mbx-save_search_conditions_modal__ui">
          <BaseButton
            size="m"
            theme="secondary"
            className="mbx-save_search_conditions_modal__button"
            onClick={props.onClickCancel}
          >
            キャンセル
          </BaseButton>
          <BaseButton
            size="m"
            theme="primary"
            type="submit"
            form="FORM"
            disabled={!isValid}
            className="mbx-save_search_conditions_modal__button"
          >
            保存する
          </BaseButton>
        </div>
      </div>
    </ModalDefault>
  );
}
