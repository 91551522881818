const color = {
  white: '#FFFFFF',
  black: '#000000',
  gray: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },
  blue: {
    50: '#FBFCFD',
    100: '#F4F9FE',
    200: '#DEEFFF',
    300: '#97CBFF',
    400: '#5CADFF',
    500: '#3E9EFF',
    600: '#1E80E3',
    700: '#0A58A8',
    800: '#003B78',
    900: '#002040',
  },
  green: {
    600: '#29C57A',
  },
  blueGray: {
    50: '#F6F8FB',
    100: '#E9F0F6',
    200: '#D9E6F3',
    300: '#C2D4E7',
    400: '#A3BCD6',
    500: '#81A4C7',
    600: '#638EB9',
    700: '#3C658E',
    800: '#334F6B',
  },
  red: {
    50: '#FFF8F6',
    100: '#FFF2EF',
    200: '#FEE5DF',
    300: '#FFD3C8',
    400: '#FFBEAD',
    500: '#FFA48F',
    600: '#FF7C5E',
    700: '#FF5A39',
    800: '#E44120',
    900: '#B61E00',
  },
  redGray: {
    200: '#F6EAE7',
    700: '#D17867',
  },
  attention: {
    200: '#FFFCBB',
    300: '#FFF879',
    500: '#E0DA59',
    700: '#A59F1B',
  },
  success: {
    200: '#B5EDD2',
    300: '#78E3B0',
    500: '#29C57A',
    700: '#208052',
  },
  error: {
    50: '#FFF3F3',
    700: '#E50000',
  },
};

const color_alias = {
  corporate: {
    blue: color.blue[700],
    red: color.red[700],
  },
  text: {
    DEFAULT: color.black,
    disabled: color.gray[400],
    placeholder: color.gray[500],
  },
  navText: {
    DEFAULT: color.black,
    disabled: color.gray[200],
    hover: {
      DEFAULT: color.red[700],
    },
  },
  button: {
    DEFAULT: color.blue[700],
    secondary: color.gray[700],
    delete: color.blueGray[400],
    disabled: {
      bg: color.gray[200],
      text: color.gray[400],
    },
    hover: {
      DEFAULT: color.blue[600],
      secondary: color.gray[800],
    },
    focus: {
      DEFAULT: color.blue[800],
      secondary: color.gray[900],
    },
    attentionBarClose: {
      DEFAULT: color.attention[700],
      hover: color.attention[500],
    },
    iconButton: {
      gray: {
        DEFAULT: color.gray[700],
        hover: color.gray[500],
        disabled: color.gray[700],
      },
      red: {
        DEFAULT: color.red[700],
        hover: color.red[500],
        disabled: color.red[700],
      },
      blue: {
        DEFAULT: color.blue[700],
        hover: color.blue[500],
        disabled: color.blue[700],
      },
      attention: {
        DEFAULT: color.attention[700],
        hover: color.attention[500],
        disabled: color.attention[700],
      },
      white: {
        DEFAULT: color.white,
        hover: color.white,
        disabled: color.white,
      },
      delete: {
        DEFAULT: color.blueGray[400],
        hover: color.blueGray[400],
        disabled: color.blueGray[400],
      },
      clear: {
        DEFAULT: color.blueGray[600],
        hover: color.blueGray[600],
        disabled: color.blueGray[600],
      },
    },
  },
  bar: {
    attention: color.attention[200],
  },
  border: {
    form: {
      DEFAULT: color.gray[400],
      hover: color.blue[700],
      focused: color.blue[700],
      filled: color.blue[700],
      error: color.error[700],
      disabled: color.gray[200],
    },
  },
  bg: {
    form: {
      DEFAULT: color.white,
      error: color.error[50],
      disabled: color.gray[200],
    },
    direct: '#e2eaee',
  },
};

module.exports = {
  color,
  color_alias,
};
