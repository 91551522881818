import { VALIDATION_MESSAGE as BASE_MESSAGE } from '@/definition/VALIDATION_MESSAGE';
import { TDictionary } from '@/types/Util';

export const VALIDATION_MESSAGE: TDictionary<string> = {
  ...BASE_MESSAGE,
  score: '正しい数値で入力してください', // toeic toefl などスコア入力値
  date_range: '正しい日付期間を入力してください',
  date: '正しい日付書式で入力してください',
  age_range: '正しい年齢期間を入力してください',
  age: '正しい年齢で入力してください（仮で10歳から80歳までと制限）',
  matchbox_id: '正しいIDで入力してください',
  number: '正しい数値で入力してください',
  number_range: '正しい数値間隔で入力してください',
};
