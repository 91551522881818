import '@/styles/pages/Other/TermsOfUse.scss';
import '@/styles/common/modal.scss';

import React, { useEffect } from 'react';

import ItemsWrapper from '@/components/common/List/ItemsWrapper';
import ListItem from '@/components/common/List/ListItem';
import { DataLayerPushContainer } from '@/componentsDirect/common/DataLayerPushContainer';
import { DirectFooter } from '@/componentsDirect/Layout/Footer';
import { AdminHeader } from '@/componentsDirect/Layout/Header';
import { DataLayerType } from '@/hooksDirect/common/useDataLayer';

function TermsOfUse(): React.ReactElement {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data: DataLayerType = {
    event: 'pageView',
    actionType: 'page_view',
    actionName: 'page_view',
  };

  const openActive: React.MouseEventHandler<HTMLImageElement> = (e): void => {
    const ele = e.currentTarget;
    ele.nextElementSibling?.classList.add('open', 'modal-overlay');
    const body = document.getElementsByTagName('body');
    body[0].classList.add('overfllow__hidden');
  };

  const closePassive: React.MouseEventHandler<HTMLSpanElement> = (e): void => {
    const ele = e.currentTarget;
    ele.parentNode?.parentElement?.classList.remove('open', 'modal-overlay');
    const body = document.getElementsByTagName('body');
    body[0].classList.remove('overfllow__hidden');
  };

  const actionNone: React.MouseEventHandler<HTMLDivElement> = (e): void => {
    e.stopPropagation();
  };

  return (
    <DataLayerPushContainer data={data}>
      <div className="min-h-screen relative pb-120">
        <div>
          <AdminHeader />
          <section className="bg-gray-200 border-b border-gray-300 shadow-headerTitle px-16">
            <div className="h-56 flex mx-auto lg:w-960 items-center">
              <h1 className="text-text font-bold">利用規約</h1>
            </div>
          </section>
        </div>
        <main>
          <div className={'mb-contents sm:px-24 sms:px-16 md:w-624 lg:w-960 mx-auto'}>
            <section className="w-624 m-auto mb-120 sm:mb-233 sm:w-full sm: sp-display">
              <div className="border-b-2 border-gray-800 pb-16">
                <h4 className="mt-48 mbx-typography--heading_4 sm:mt-24">利用規約</h4>
              </div>
              <ItemsWrapper title="第１条（MATCHBOX　スカウトサービス）" border={true}>
                <span className="mbx-typography--body_1">
                  MATCHBOX
                  スカウトサービス（以下「本サービス」といいます）とは、株式会社マイナビワークス（以下「当社」といいます）が提供する職業紹介サービス（以下「当社諸サービス」といいます）にて人材紹介契約を締結した求人企業（以下「参画企業」といいます）を対象として提供する、利用者（第2条にて定義します）に対するインターネット上における求人情報の提供や勧誘に関するメッセージ送付サービスをいいます。
                </span>
              </ItemsWrapper>
              <ItemsWrapper title="第２条（定義）" border={true}>
                <p className="mbx-typography--body_1">
                  本規約における用語の定義は、次に定めるとおりとします。
                </p>
                <ListItem type="bracket" number={1} className="mt-16">
                  「参画企業」とは、本サービスの利用について、当社が承諾した求人企業のこといいます。なお、参画企業は、当社運営の職業紹介サービスにて「人材紹介契約書」を締結している求人企業に限るものとします。
                </ListItem>
                <ListItem type="bracket" number={2} className="mt-4">
                  「利用者」とは、本サービスの閲覧者、登録者、応募者、質問者等、本サービスを利用する求職者をいいます。
                </ListItem>
                <ListItem type="bracket" number={3} className="mt-4">
                  「応募」とは、利用者が本サービスを経由し、当社諸サービスを介して提供された一切の求人情報を対象として、その一部または全部に応募することをいいます。
                </ListItem>
              </ItemsWrapper>
              <ItemsWrapper title="第３条（申込み）" border={true}>
                <p className="mbx-typography--body_1">
                  当社は、本規約に基づき本サービスを提供するものとし、参画企業は、本規約の内容（本サービスの内容、利用期間、その他必要な条件等）に同意のうえ、当社所定の方法により、本サービスの申込みを行うものとします。
                </p>
              </ItemsWrapper>
              <ItemsWrapper title="第４条（届出事項の変更）" border={true}>
                <p className="mbx-typography--body_1">
                  参画企業は、当社に届け出を行った内容に変更が生じた場合、速やかにその旨を通知するものとします。
                </p>
              </ItemsWrapper>
              <ItemsWrapper title="第５条（ログインID・パスワードの管理）" border={true}>
                <ListItem type="number" number={1}>
                  当社は、参画企業に対して、本サービス上の管理者画面（以下「管理者画面」といいます）のログインID・パスワード（以下「ログインID・パスワード」といいます）を発行します。
                </ListItem>
                <ListItem type="number" number={2}>
                  参画企業は、ログインID・パスワードについて、厳重な管理を行うよう努めるものとし、第三者に対して、貸与、譲渡または開示等できないものとします。
                </ListItem>
                <ListItem type="number" number={3}>
                  当社は、ログインID・パスワードが第三者に漏洩したことにより、参画企業または第三者が被った損害について、一切の責任を負わないものとします。
                </ListItem>
                <ListItem type="number" number={4}>
                  参画企業は、管理者画面（ログインID・パスワードを含む）を業務委託先に使用させる場合、事前に当社に届け出を行うものとし、業務委託先に本規約の内容を遵守させるものとします。
                </ListItem>
                <ListItem type="number" number={5}>
                  当社は、一定期間、参画企業による本サービスの利用がない場合、または本サービスの運営上のやむを得ない理由がある場合、ログインID・パスワードを無効化できるものとします。
                </ListItem>
              </ItemsWrapper>
              <ItemsWrapper title="第６条（利用者情報の取扱い）" border={true}>
                <ListItem type="number" number={1} className="mt-8">
                  参画企業は、本サービスの利用により取得する利用者に関する情報（利用者の個人情報および識別情報を含みます）、その他通信情報のすべて（以下総称して「利用者情報」といいます）を、本サービスの目的のみに利用するものとし、これ以外の目的（営利目的であるか否かを問いません）に利用することはできないものとします。
                </ListItem>
                <ListItem type="number" number={2} className="mt-4">
                  参画企業は、利用者情報を機密として厳重かつ適切に取り扱うものとし、第三者に開示しないものとします。
                </ListItem>
                <ListItem type="number" number={3} className="mt-4">
                  参画企業は、本サービスから出力した利用者情報を、本条第1項の目的が終了した時点で、参画企業の責任において破棄するものとします。
                </ListItem>
                <ListItem type="number" number={4} className="mt-4">
                  当社は、利用者情報の内容、および参画企業が利用者に対して行う連絡の内容に関して、一切の責任を負わないものとします。
                </ListItem>
              </ItemsWrapper>
              <ItemsWrapper title="第７条（当社による情報利用と機密保持義務）" border>
                <ListItem type="number" number={1} className="mt-8">
                  当社は、参画企業が登録した参画企業に関する情報または参画企業による本サービスの利用に関する情報（アクセスログ、操作ログ、参画企業と応募者とのメッセージの送受信記録、本サービスを通じた採用活動の進捗データ等を含みますがこれらに限られません。以下、あわせて「参画企業情報」といいます）を機密として厳重かつ適切に取り扱うものとし、参画企業の同意を得た場合を除き、第三者に開示しないものとします。ただし、当社は、行政・司法機関、その他正当な法令上の権限を有する者から、参画企業情報の開示を要求された場合、開示要求が適法になされたものと当社が判断した場合、参画企業の同意を得ることなく開示することができるものとします。
                </ListItem>
                <ListItem type="number" number={2} className="mt-4">
                  当社は、当社の営業活動に利用するために、参画企業の本サービス利用実績を一部開示することがあり、参画企業は、これを予め承諾するものとします。
                </ListItem>
                <ListItem type="number" number={3} className="mt-4">
                  当社は、本サービスの向上、新規サービスの開発等を目的として、参画企業情報のうち個人を特定できない情報に関して分析等を行うことがあるものし、参画企業は、これを予め承諾するものとします。
                </ListItem>
                <ListItem type="number" number={4} className="mt-4">
                  当社は、個人情報の取扱いに関する管理責任者を選定し、個人情報を取り扱うことが業務遂行上必要な従業者（当社との雇用関係の有無を問わない）に限って、個人情報を取り扱わせるものとします。
                </ListItem>
                <ListItem type="number" number={5} className="mt-4">
                  当社は、業務遂行上必要な範囲内でのみ、個人情報を取り扱う業務の全部または一部を第三者に委託することができるものとします。ただし、この場合、当社は、本条における当社の義務と同等の義務を委託先にも負わせるものとします。
                </ListItem>
                <ListItem type="number" number={6} className="mt-4">
                  当社は、参画企業から個人情報の管理体制についての報告を求められた場合、個人情報の秘匿性を害することがない方法および内容により報告を行うものとします。
                </ListItem>
              </ItemsWrapper>
              <ItemsWrapper title="第８条（利用者対応）" border>
                <ListItem type="number" number={1} className="mt-8">
                  参画企業が利用者とやり取りを行う際は、必ず当社を経由するものとし、スカウト時に参画企業担当者の連絡先等を記入する方法により、当社を介することなく直接利用者対応をしてはならないものとします。
                </ListItem>
                <ListItem type="number" number={2} className="mt-4">
                  参画企業は、本サービスの利用によりスカウトした利用者が必ずしも選考に応じるものではないこと、また当社が利用者に対して選考に応じるよう促すものではないことを了承するものとします。
                </ListItem>
                <ListItem type="number" number={3} className="mt-4">
                  利用者の推薦、選考および入社に際し発生する利用者との具体的なやり取りについては、当社諸サービスの利用に際して締結した人材紹介契約に定めた内容に従うものとします。
                </ListItem>
              </ItemsWrapper>
              <ItemsWrapper title="第９条（利用料金）" border>
                <p className="mbx-typography--body_1">
                  本サービスの利用については、無償とします。なお、当社を介し利用者が参画企業へ入社に至った場合には、当社諸サービスの利用に際して締結した人材紹介契約の規定に従うものとします。
                </p>
              </ItemsWrapper>
              <ItemsWrapper title="第１０条（知的財産権）" border>
                <p className="mbx-typography--body_1">
                  本サービスを通じて提供する一切のコンテンツ、情報等に関する著作権等の知的財産権は、当社に帰属するものとし、当社に無断でコンテンツを利用（転載、複製、公開等を行うこといい、以下同様とします）することはできません。参画企業がコンテンツの利用を希望する場合には、事前に当社の承諾を得なければなりません。
                </p>
              </ItemsWrapper>
              <ItemsWrapper title="第１１条（統計データの利用）" border>
                <p className="mbx-typography--body_1">
                  当社は、参画企業情報および利用者から提供された個人情報ならびに本サービスの利用状況を抽出・集計して統計データを作成することがあり、当該統計データについて、当社は、何ら制約なく利用することができるものとします。また、当社は、参画企業に対して、統計データ作成のための調査を行うことができるものとします。なお、統計データに関する著作権は、当社に帰属するものとします。
                </p>
              </ItemsWrapper>
              <ItemsWrapper title="第１２条（禁止事項）" border>
                <p className="mbx-typography--body_1">
                  参画企業は、本サービスの利用にあたり、次の各号に定める行為またはそれに該当するおそれのある行為を行ってはならないものとします。
                </p>
                <ListItem type="bracket" number={1} className="mt-16">
                  本サービスを利用するうえで知り得た本サービスに関する情報（管理者画面で知り得た情報等）を第三者に提供・漏洩する行為。
                </ListItem>
                <ListItem type="bracket" number={2} className="mt-16">
                  当社または第三者（利用者も含みます。本条において同様とします）の知的所有権、プライバシー、名誉、肖像権等の権利を侵害する行為。
                </ListItem>
                <ListItem type="bracket" number={3} className="mt-16">
                  当社の事前の書面による承諾を得ることなく、利用者と直接連絡を取ること。
                </ListItem>
                <ListItem type="bracket" number={4} className="mt-16">
                  当社または第三者を誹謗中傷する行為。
                </ListItem>
                <ListItem type="bracket" number={5} className="mt-16">
                  当社または第三者に不利益を与える行為。
                </ListItem>
                <ListItem type="bracket" number={6} className="mt-16">
                  重複、虚偽の情報、または他者の情報を告知、提供、登録する行為。
                </ListItem>
                <ListItem type="bracket" number={7} className="mt-16">
                  当社の広告掲載規定、広告掲載ガイドライン、その他の規定に反する内容を告知する行為。
                </ListItem>
                <ListItem type="bracket" number={8} className="mt-16">
                  利用者に対する信用失墜行為。
                </ListItem>
                <ListItem type="bracket" number={9} className="mt-16">
                  ウィルス、ワーム等、コンピューターを汚染・破壊するおそれのある情報、ファイルおよびソフトウェアを掲載または送信する行為。
                </ListItem>
                <ListItem type="bracket" number={10} className="mt-16">
                  <span>
                    ログインID・パスワードの探知、ハッキングその他の手段により、本サービスに関連する
                    <br />
                    コンピュータシステムまたはネットワークへの不法アクセスを試みる行為。
                  </span>
                </ListItem>
                <ListItem type="bracket" number={11} className="mt-16">
                  本サービスの運営を妨げ、または当社の信用を毀損する行為。
                </ListItem>
                <ListItem type="bracket" number={12} className="mt-16">
                  公序良俗または各種法令に違反する行為。
                </ListItem>
                <ListItem type="bracket" number={13} className="mt-16">
                  その他不適切と当社が判断する行為。
                </ListItem>
              </ItemsWrapper>
              <ItemsWrapper title="第１３条（免責）" border>
                <ListItem type="number" number={1} className="mt-8">
                  当社は、参画企業が本サービスを利用（これらに伴う当社または第三者の情報提供行為等を含みます）することにより参画企業に生じたコンピュータシステムの損害、データの損失等を含む、いかなる損害（当社が客観的に合理的と判断される水準の措置を講じていたにもかかわらず、ウイルス・ハッキング等を原因として生じる損害も含みます）について、一切の責任を負わないものとします。
                </ListItem>
                <ListItem type="number" number={2} className="mt-4">
                  当社は、天変地異、その他当社の責めによらない事由による損害が生じた場合、その損害について、一切の責任を負わないものとします。
                </ListItem>
                <ListItem type="number" number={3} className="mt-4">
                  本規約に定める一時的な本サービスの中断により、本サービスへの情報の反映およびエントリー情報の受信に遅れが生じた場合であっても、当社は、一切の責任を負わないものとします。
                </ListItem>
                <ListItem type="number" number={4} className="mt-8">
                  当社は、本サービスにおけるデータが消去・変更されないことを保証しません。必要なデータについては、参画企業が自己の責任において保存するものとします。
                </ListItem>
                <ListItem type="number" number={5} className="mt-8">
                  参画企業は、機能、サービスの向上や安定的なシステム運用等の目的で、本サービスのインターフェイスを参画企業に事前の通知することなく変更することがあること、およびこれにより当該変更後のインターフェイスおよび操作マニュアル内の表示が異なる事態が生じる可能性があることをあらかじめ承諾するものとします。
                </ListItem>
                <ListItem type="number" number={6} className="mt-8">
                  参画企業は、本サービスを利用することにより、利用者または第三者との間に生じた紛争について、自己の費用と責任において解決するものとし、当社は、一切の責任を負わず、介入しないものとします。
                </ListItem>
                <ListItem type="number" number={7} className="mt-8">
                  本サービスにおいて提供される利用者情報については、利用者の責任で提供されるものであり、参画企業は、当社が利用者の資質・能力および求人企業への適合性等、本サービスの効果に関する何らの保証もしないことを予め承諾するものとします。
                </ListItem>
              </ItemsWrapper>
              <ItemsWrapper title="第１４条（反社会的勢力の排除）" border>
                <ListItem type="number" number={1} className="mt-8">
                  <>
                    <p>
                      参画企業は、次の各号のいずれにも該当しないこと、および、今後もこれに該当しないことを保証し、参画企業が次の各号の一に該当した場合、または該当していたことが判明した場合には、当社は、別段の催告を要せず、直ちに本サービスの利用を停止させることができるものとします。
                    </p>
                    <ListItem type="bracket" number={1} className="">
                      暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団、その他これらに準ずる者（以下、あわせて「反社会的勢力」という）であること。
                    </ListItem>
                    <ListItem type="bracket" number={2} className="mt-4">
                      役員または実質的に経営を支配する者が反社会的勢力であること。
                    </ListItem>
                    <ListItem type="bracket" number={3} className="mt-4">
                      親会社、子会社（いずれも会社法の定義による。以下同じ）または本契約の履行のために再委託する第三者が前二号のいずれかに該当すること。
                    </ListItem>
                  </>
                </ListItem>
                <ListItem type="number" number={2} className="mt-8">
                  <>
                    <p>
                      当社は、参画企業が次の各号のいずれかに該当した場合、当社は別段の催告を要せず、直ちに本サービスの利用を停止させることができるものとします。
                    </p>
                    <ListItem type="bracket" number={1} className="">
                      当社に対して脅迫的な言動をすること、もしくは暴力を用いること、または当社の名誉・信用を毀損する行為を行うこと。
                    </ListItem>
                    <ListItem type="bracket" number={2} className="mt-4">
                      偽計または威力を用いて当社の業務を妨害すること。
                    </ListItem>
                    <ListItem type="bracket" number={3} className="mt-4">
                      当社に対して法的な責任を超えた不当な要求をすること。
                    </ListItem>
                    <ListItem type="bracket" number={4} className="mt-4">
                      反社会的勢力である第三者をして前三号の行為を行わせること。
                    </ListItem>
                    <ListItem type="bracket" number={5} className="mt-4">
                      自らまたはその役員もしくは実質的に経営を支配する者が反社会的勢力への資金提供を行う等、その活動を助長する行為を行うこと。
                    </ListItem>
                    <ListItem type="bracket" number={6} className="mt-4">
                      親会社、子会社または本契約の履行のために再委託する第三者が前五号のいずれかに該当する行為を行うこと。
                    </ListItem>
                  </>
                </ListItem>
              </ItemsWrapper>
              <ItemsWrapper title="第１５条（サービス利用の停止・契約の解除）" border>
                <ListItem type="number" number={1} className="mt-4">
                  <>
                    <p className="mbx-typography--body_1">
                      当社は、参画企業が次の各号のいずれかに該当する場合、別段の催告を要せず、直ちに本サービスの利用を停止させ、または本サービスに関する契約を解除することができるものとします。なお、当社諸サービスに関する契約が終了した場合、本サービスの提供も同時に終了するものとします。
                    </p>
                    <ListItem type="bracket" number={1} className="mt-4">
                      告知された情報に悪質な誤り、または重大な変更等があった場合。
                    </ListItem>
                    <ListItem type="bracket" number={2} className="mt-4">
                      本サービスの運営を妨害した場合。
                    </ListItem>
                    <ListItem type="bracket" number={3} className="mt-4">
                      他の参画企業のログインID・パスワードを不正に使用した場合。
                    </ListItem>
                    <ListItem type="bracket" number={4} className="mt-4">
                      支払期日を経過しても、当社諸サービスに関する料金が支払われない場合。
                    </ListItem>
                    <ListItem type="bracket" number={5} className="mt-4">
                      手形または小切手が不渡りとなった場合。
                    </ListItem>
                    <ListItem type="bracket" number={6} className="mt-4">
                      破産手続開始、民事再生手続開始、会社更生手続開始、もしくは特別清算手続開始等の申立てを受け、または自ら申立てを行った場合。
                    </ListItem>
                    <ListItem type="bracket" number={7} className="mt-4">
                      差押え、仮差押え、仮処分、もしくは強制競売の申立て、または公租公課の滞納処分、その他の公権力の処分を受けた場合。
                    </ListItem>
                    <ListItem type="bracket" number={8} className="mt-4">
                      官公署等により業務停止等の処分を受けた場合。
                    </ListItem>
                    <ListItem type="bracket" number={9} className="mt-4">
                      本規約のいずれかに違反した場合。
                    </ListItem>
                    <ListItem type="bracket" number={10} className="mt-4">
                      その他参画企業として不適当であると当社が判断した場合。
                    </ListItem>
                  </>
                </ListItem>
                <ListItem type="number" number={2} className="mt-8">
                  参画企業は、前項の規定により本サービスに関する契約を解除された場合、期限の利益を喪失し、直ちに当社に対する一切の債務を弁済するものとします。
                </ListItem>
                <ListItem type="number" number={3} className="mt-8">
                  参画企業は、第1項の定めにより本サービスの提供を受けられない場合であっても、利用料金全額の支払義務を免れないものとします。
                </ListItem>
              </ItemsWrapper>
              <ItemsWrapper title="第１６条（損害賠償・違約金）" border>
                <ListItem type="number" number={1} className="mt-8">
                  参画企業は、本サービスの利用に際して当社に損害を与えた場合、当社に対して、直接・間接を問わず一切の損害を賠償する義務を負うものとします。
                </ListItem>
                <ListItem type="number" number={2} className="mt-4">
                  参画企業は、第12条第3号に違反して、利用者と直接連絡を取った場合、利用者が参画企業と雇用契約等を締結したか否かに関わらず、当社に対して、違約金として、当社諸サービスの利用に際して締結した人材紹介契約に定める報酬相当額を支払うものとします。なお、利用者が参画企業と雇用契約を締結していた場合は、別途、当社諸サービスの利用に際して締結した人材紹介契約に定める報酬が発生するものします。
                </ListItem>
              </ItemsWrapper>
              <ItemsWrapper title="第１７条（サービスの中断、終了）" border>
                <ListItem type="number" number={1} className="mt-8">
                  本サービスの運用上または技術上等の理由により、本サービスの一時的な中断の必要があると当社が判断した場合、当社は、参画企業に事前に通知することなく、一時的に本サービスの提供を中断することがあります。
                </ListItem>
                <ListItem type="number" number={2} className="mt-8">
                  当社は、特段の事情により、本サービスの提供を中止する必要があると判断した場合、本サービスの提供を長期的に中断または終了することができるものとします。
                </ListItem>
              </ItemsWrapper>
              <ItemsWrapper title="第１８条（分離条項）" border>
                <p className="mbx-typography--body_1">
                  本規約の一部の効力が、法令や確定判決により無効とされた場合であっても、その他の条項は引き続き効力を有するものとします。
                </p>
              </ItemsWrapper>
              <ItemsWrapper title="第１９条（規約の変更）" border>
                <ListItem type="number" number={1} className="mt-4">
                  <>
                    <p className="mbx-typography--body_1">
                      当社は、次の各号に定める場合、参画企業の同意を得ることなく、本規約を変更することができるものとします。
                    </p>
                    <ListItem type="bracket" number={1} className="mt-4">
                      本規約の変更が、参画企業の一般の利益に適合する場合。
                    </ListItem>
                    <ListItem type="bracket" number={2} className="mt-4">
                      本規約の変更が、本サービスの目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容、その他の変更にかかる事情に照らして合理的なものである場合。
                    </ListItem>
                  </>
                </ListItem>
                <ListItem type="number" number={2} className="mt-8">
                  当社は、前項による本規約の変更にあたり、変更実施日の１ヶ月前までに、本規約を変更する旨および変更後の本規約の内容を本サービス上に掲示、または参画企業に電子メールで通知します。
                </ListItem>
              </ItemsWrapper>
              <ItemsWrapper title="第２０条（準拠法および管轄）" border>
                <p className="mbx-typography--body_1">
                  本規約は日本法を準拠法とし、本規約に関わる一切の紛争については、訴額に応じて東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
                </p>
              </ItemsWrapper>
              <ItemsWrapper title="第２１条（協議解決）" border={true}>
                <p className="mbx-typography--body_1">
                  本規約の解釈等に疑義が生じた場合および規定されていない事項については、当社と参画企業は協議のうえ、円満に解決するよう努力するものとします。
                </p>
              </ItemsWrapper>
              <p className="mbx-typography--body_1 pb-52 mt-40">2022年3月2日制定</p>
              <div className="border-b-2 border-gray-800 pb-16 pt-52" id="privacy">
                <h4 className="mt-48 mbx-typography--heading_4">
                  個人情報の取り扱いについて（MATCHBOX）
                </h4>
              </div>
              <p className="mbx-typography--body_1 mt-40">
                株式会社マイナビワークス（以下「当社」といいます）は、当社のサービスについて、ご意見、お問い合わせいただく方（企業等を含み、以下「利用者」といいます）のプライバシーを尊重し、個人情報（以下の定義に従います）の管理に細心の注意を払い、これを適正に取り扱います。
              </p>
              <ItemsWrapper title="当社の個人情報の取り扱い" border={true}>
                <p className="mbx-typography--body_1">
                  当社における個人情報の取り扱いは、「
                  <a
                    href="https://mynavi-works.jp/privacy/privacy2.html"
                    className="text-blue-700 underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    個人情報保護方針
                  </a>
                  」に基づいて行われます。
                </p>
              </ItemsWrapper>
              <ItemsWrapper title="個人情報" border={true}>
                <p className="mbx-typography--body_1">
                  個人情報とは、利用者個人に関する情報であって、当該情報に含まれる氏名、生年月日、その他の記述等により利用者個人を識別することができるもの、およびその情報のみでは識別できない場合であっても、他の情報と容易に照合することができ、それにより結果的に利用者個人を識別することができるもの、ならびに利用者に関する個人識別符号が含まれるものをいうものとします。
                  <br />
                  また当社では、Cookie情報、ユーザー識別子、IPアドレス、広告識別子、位置情報、Web閲覧履歴など(以下「インフォマティブデータ」といいます)を取り扱いますが、第三者から提供を受けたインフォマティブデータのうち個人関連情報に該当するものを、当社サービスに登録された個人情報と紐付けて利用する場合は、これらも個人情報として扱うものとします。
                </p>
              </ItemsWrapper>
              <ItemsWrapper title="個人情報の利用目的" border={true}>
                <p className="mbx-typography--body_1 mt-16">
                  利用者の個人情報は、お問い合わせ等への対応、および当社から利用者へのご連絡（電話、電子メール、郵便等により行います）、広告・コンテンツの配信および提供、マーケティング調査および分析のために利用します。また、担当部署からの対応が必要となる場合には、個人情報を担当部署に転送させていただきます。なお、下記利用目的の範囲において、第三者から提供された個人関連情報（利用環境・属性情報・位置情報・利用履歴情報・端末識別情報（Cookie等）・広告識別子・広告情報・リサーチパネルデータ）を本サービス利用者情報と紐付け、広告配信効果測定および分析に利用する場合があります。
                </p>
              </ItemsWrapper>
              <ItemsWrapper border={true} title="個人情報を提供することの任意性について">
                <p className="mbx-typography--body_1 mt-16">
                  個人情報を提供することは任意です。ただし、お問い合わせ等に必要となる項目を入力いただけない場合には、ご連絡および適切なご回答ができない可能性があります。
                </p>
              </ItemsWrapper>
              <ItemsWrapper title="個人情報の第三者への提供" border={true}>
                <p className="mbx-typography--body_1 mt-16">
                  当社は、法令等に基づく正当な理由がある場合を除き、利用者の同意なく、個人情報の第三者への提供を行いません。
                </p>
              </ItemsWrapper>
              <ItemsWrapper title="外部委託" border={true}>
                <p className="mbx-typography--body_1 mt-16">
                  当社は、個人情報を取り扱う業務の全部または一部を外部に委託する場合があります。委託を行う場合には、充分な個人情報保護水準を確保していることを条件として委託先を選定し、機密保持に関する契約を結んだうえで行います。また、当該委託先における管理については必要かつ適切な監督を行います。
                </p>
              </ItemsWrapper>

              <ItemsWrapper title="統計データの利用" border={true}>
                <p className="mbx-typography--body_1 mt-16">
                  当社は、提供された個人情報を抽出・集計して統計データを作成することがあり、当該統計データについて、何ら制約なく利用することができるものとします。なお、この場合の統計データの著作権は当社に帰属するものとします。
                </p>
              </ItemsWrapper>
              <ItemsWrapper title="「個人情報の取り扱いについて」の変更" border={true}>
                <p className="mbx-typography--body_1 mt-16">
                  当社は、「個人情報の取り扱いについて」を継続的に見直し、その改善に努めます。その際、利用者に予告なく変更することがあります。ただし、利用目的を変更する場合には、利用者の同意を得るものとします。
                </p>
              </ItemsWrapper>
              <ItemsWrapper title="個人情報の管理について" border={true}>
                <p className="mbx-typography--body_1 mt-16">
                  当社は、個人情報への不当なアクセスまたは個人情報の紛失、破壊、改竄、漏洩等の危険に対して、技術面および組織面において必要な安全対策を継続的に講じるよう努めています。また、当社は、個人情報の保護に関する法令、業界規範・慣習、公序良俗を遵守します。
                </p>
              </ItemsWrapper>
              <ItemsWrapper border={true} title="個人情報の変更等">
                <p className="mbx-typography--body_1 mt-16">
                  利用者の求めに応じ、保有個人データの利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去および第三者への提供の停止、ならびに第三者提供記録の開示を受けることができます。次のメールアドレス宛にご連絡ください。
                  <br />
                  E-mail：jinzaihp@mynavi.jp
                </p>
              </ItemsWrapper>
              <ItemsWrapper border={true} title="本人確認">
                <p className="mbx-typography--body_1 mt-16">
                  当社は、利用者からの個人情報の開示、内容の訂正、追加または削除、利用の停止、消去および第三者への提供の停止、ならびに第三者提供記録の開示の求めに応じる場合には、個人を識別できる情報（氏名、住所、電話番号、メールアドレス等）により、利用者本人であることを確認します。なお、利用者以外の第三者が個人を識別できる情報を使用した場合には、当社は責任を負いません。
                </p>
              </ItemsWrapper>
              <ItemsWrapper border={true} title="個人情報保護管理者">
                <p className="mbx-typography--body_1 mt-16">
                  株式会社マイナビワークス
                  <br />
                  管理統括本部長
                  <br />
                  mw-personal_data@mynavi.jp
                </p>
              </ItemsWrapper>
              <ItemsWrapper border={true} title="プライバシーマークについて">
                <p className="mbx-typography--body_1 mt-16">
                  当社は、プライバシーマーク付与事業者です。プライバシーマークとは、個人情報に関するJIS規格JIS
                  Q
                  15001を満たす人情報保護マネジメントシステムを策定し、運用しているということを一般財団法人日本情報経済社会推進協会（JIPDEC）等の審査機関が認定した場合に使用できるマークです。
                </p>
              </ItemsWrapper>
              <div className="border-b-2 border-gray-800 pb-16 mt-104">
                <h4 className="mt-48 mbx-typography--heading_4">
                  インフォマティブデータの取り扱いについて
                </h4>
              </div>
              <p className="mbx-typography--body_1 mt-40">
                本サービスでは、利用者のインフォマティブデータを取得し、広告配信などに利用します。
                <br />
                インフォマティブデータの詳細については、当社の定める「
                <a
                  href="https://mynavi-works.jp/privacy/privacy2.html"
                  className="text-blue-700 underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  インフォマティブデータの取扱方針
                </a>
                」をご確認ください。
                <br />
                なお、インフォマティブデータを個人関連情報として取り扱い、本サービスに登録された個人情報と紐づけて利用する場合は、個人情報として取り扱います。「個人情報の取り扱いについて」をご参照ください。
              </p>
              <ItemsWrapper border={true} title="インフォマティブデータの外部送信">
                <div className="inner">
                  <p className="intro__text text-left mbx-typography--body_1">
                    本サービスでは、利用者のウェブ体験の向上や、利用者ごとにパーソナライズしたコンテンツ・広告の表示、アクセス解析による当社ウェブサイトの改善のために、クッキー等の端末識別子（インフォマティブデータ）を利用して、利用者に関する情報（利用者情報）を収集し、外部事業者に送信しています。外部事業者の名称・サービス名、外部事業者に送信される利用者情報の内容、送信される情報の利用目的については、以下より詳細をご確認ください。
                  </p>
                  <div className="table__wrap mt-16">
                    <table className="service__table sm:hidden">
                      <thead>
                        <tr>
                          <th>分類</th>
                          <th>事業者</th>
                          <th>サービス名</th>
                          <th>
                            送信される利用者情報
                            <br />
                            と利用目的
                          </th>
                          <th>
                            プライバシー
                            <br />
                            ポリシー
                          </th>
                          <th>
                            オプトアウト
                            <br />
                            ページ
                          </th>
                          <th>
                            クッキー
                            <br />
                            ポリシー
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span className="classification performance">アクセス解析</span>
                          </td>
                          <td>Google LLC</td>
                          <td>
                            <span className="service_names"> Googleアナリティクス </span>
                          </td>
                          <td>
                            <img
                              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                              alt=""
                              height="30px"
                              width="30px"
                              onClick={openActive}
                              className="icon__button"
                            />
                            <div className="terms-modal">
                              <div className="terms-modal__content" onClick={actionNone}>
                                <span className="terms-modal__close" onClick={closePassive}>
                                  ×
                                </span>
                                <h2 className="terms-modal__title">
                                  <span className="terms-modal__company__names"> Google LLC </span>
                                  <span className="terms-modal__service_names">Googleアナリティクス</span>
                                </h2>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      ・クライアントID
                                      <br />
                                      ・IPアドレス
                                      <br />
                                      ・閲覧ページURL
                                      <br />
                                      ・リファラー
                                      <br />
                                      ・デバイスタイプ
                                      <br />
                                      ・オペレーティングシステム
                                      <br />
                                      ・ブラウザタイプ
                                      <br />
                                      ・利用言語
                                      <br />
                                      ・画像解像度
                                    </p>
                                  </div>
                                </div>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">利用目的</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      当社は、ウェブサイトのパフォーマンスを分析し、改善に役立てるため、サイト利用者に関する情報を収集し、Googleへ送信します。Googleでは、送信された情報を分析、統計化し、ウェブサイトのパフォーマンスに関するレポートを当社に提供します。また、Google
                                      では、これらの情報をGoogle独自のサービス・プロダクトの改善を目的として利用する場合があります。
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text__center">
                            <a
                              href="https://policies.google.com/privacy"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center">
                            <a
                              href="https://tools.google.com/dlpage/gaoptout/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center">
                            <a
                              href="https://policies.google.com/technologies/cookies?hl=ja"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="classification targeting">広告・マーケティング</span>
                          </td>
                          <td>LINEヤフー株式会社</td>
                          <td>
                            <span className="service_names"> LINE広告 </span>
                          </td>
                          <td>
                            <img
                              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                              alt=""
                              height="30px"
                              width="30px"
                              onClick={openActive}
                              className="icon__button"
                            />
                            <div className="terms-modal">
                              <div className="terms-modal__content" onClick={actionNone}>
                                <span className="terms-modal__close" onClick={closePassive}>
                                  ×
                                </span>
                                <h2 className="terms-modal__title">
                                  <span className="terms-modal__company__names">LINEヤフー株式会社</span>
                                  <span className="terms-modal__service_names"> LINE広告 </span>
                                </h2>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      ・お客様がお使いの端末情報
                                      <br />
                                      ・ブラウザを識別する広告識別子
                                      <br />
                                      ・Cookie IDや端末の種類
                                      <br />
                                      ・OS
                                      <br />
                                      ・言語
                                      <br />
                                      ・タイムゾーン設定
                                      <br />
                                      ・ブラウザの種類
                                      <br />
                                      ・アプリバージョン
                                      <br />
                                      ・ネットワーク情報（通信事業者の名称、IPアドレスなど）
                                      <br />
                                      ・位置情報
                                      <br />
                                      ・サービス利用状況（利用日時、滞在時間、閲覧・タップ・クリックしたコンテンツや広告など）
                                      <br />
                                      ・属性情報、購買履歴、視聴履歴、検索履歴および位置情報を含むお客様に関する行動履歴
                                      <br />
                                      ・ウェブサイトの訪問履歴（訪問日時、URLなどを含む）
                                    </p>
                                  </div>
                                </div>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">利用目的</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      当社では、LINEヤフー株式会社のLINE広告サービスを利用し、サイト利用者に関する情報の分析とLINEサービス上における当該サイト利用者に適した広告の配信を行っています。LINEヤフーでは、当該LINE広告のマーケティングサービスの運営・改善のために、サイト利用者に関する情報を利用しています。なお、LINEヤフーでは、収集データ、その他LINEヤフーが保有する各種データとの掛け合わせや組み合わせ・必要な各種データの不可・補完等を行うことができ、例えば、LINEヤフーでは、サイト利用者の訪問履歴と広告識別子や内部識別子などを関連づけ、当社以外から収集した利用者情報と突合・分析し、当社以外の事業者の広告の最適化にも利用する場合があります。また、LINEは、「LINEで送る」や「いいね」などのプラグイン機能が当社ウェブサイトに設置されている場合、ウェブサイトにおけるプラグインからのログイン記録の他、ブラウザ上でLINEにログインした状態で訪問したプラグイン機能が設置されたウェブサイトのURL情報をLINEアカウントに関連づけて取得する場合があります。このように取得したデータは、LINEにおいて、LINEサービスがどのように利用されているかを把握し、その維持・改善、セキュリティ・不正利用防止の目的などに利用されます。なお、LINEからログアウトした状態では、これらの情報は個人を識別しない形でセキュリティ・不正利用防止の目的にのみ利用されます。
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text__center">
                            <a href="https://line.me/ja/terms/policy/" target="_blank" rel="noreferrer">
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center"></td>
                          <td className="text__center"></td>
                        </tr>
                        <tr>
                          <td>
                            <span className="classification targeting">広告・マーケティング</span>
                          </td>
                          <td>Meta Platforms, Inc.</td>
                          <td>
                            <span className="service_names"> Facebook広告 </span>
                          </td>
                          <td>
                            <img
                              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                              alt=""
                              height="30px"
                              width="30px"
                              onClick={openActive}
                              className="icon__button"
                            />
                            <div className="terms-modal">
                              <div className="terms-modal__content" onClick={actionNone}>
                                <span className="terms-modal__close" onClick={closePassive}>
                                  ×
                                </span>
                                <h2 className="terms-modal__title">
                                  <span className="terms-modal__company__names">
                                    Meta Platforms, Inc.
                                  </span>
                                  <span className="terms-modal__service_names"> Facebook広告 </span>
                                </h2>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      ・ユーザーID
                                      <br />
                                      ・アクセスしているサイトURL
                                      <br />
                                      ・アクセスした日付と時刻
                                      <br />
                                      ・ユーザーが利用しているブラウザーに関連した情報
                                      <br />
                                      ・利用者がアクションを実行した広告
                                      <br />
                                      ・利用者が広告に対していつアクションを実行したか
                                      <br />
                                      ・広告の表示場所(Instagram上、あるいはFacebook上など)
                                    </p>
                                  </div>
                                </div>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">利用目的</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      サイト利用者が当社ウェブサイトのコンテンツをソーシャルネットワークサービスFacebook上で共有するための機能を提供する目的で利用する他、情報の送信先となるFacebookにおいて、サイト利用者に関する情報を分析し、Facebookサービス上に当該サイト利用者に適した当社の広告を配信するために利用する場合があります。また、Metaではこれらの情報を、Metaの製品の品質を向上させるために使用する他、当社以外から収集した利用者情報と突合・分析し、当社以外の事業者の広告の最適化にも利用する場合があります。
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text__center">
                            <a
                              href="https://www.facebook.com/privacy/policy"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center">
                            <a
                              href="https://www.facebook.com/help/568137493302217"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center">
                            <a
                              href="https://www.facebook.com/privacy/policies/cookies"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="classification targeting">広告・マーケティング</span>
                          </td>
                          <td>Twitter, Inc.</td>
                          <td>
                            <span className="service_names"> Twitter </span>
                          </td>
                          <td>
                            <img
                              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                              alt=""
                              height="30px"
                              width="30px"
                              onClick={openActive}
                              className="icon__button"
                            />
                            <div className="terms-modal">
                              <div className="terms-modal__content" onClick={actionNone}>
                                <span className="terms-modal__close" onClick={closePassive}>
                                  ×
                                </span>
                                <h2 className="terms-modal__title">
                                  <span className="terms-modal__company__names">Twitter, Inc.</span>
                                  <span className="terms-modal__service_names"> Twitter </span>
                                </h2>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      ・クライアントID
                                      <br />
                                      ・ウェブサイトへのアクセス状況
                                      <br />
                                      ・特定のウェブコンテンツやメールコンテンツの利用の有無
                                    </p>
                                  </div>
                                </div>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">利用目的</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      &quot;当社では、当社ウェブサイトでのソーシャルメディア機能の提供およびサイト利用者に適した広告の表示のために利用します。Twitterでは、以下の用途で利用される場合があります。
                                      <br />
                                      ・Twitterへのログイン状態の維持
                                      <br />
                                      ・Twitterサービスのさまざまな機能の提供
                                      <br />
                                      ・個人設定の保存や、個人の嗜好の尊重
                                      <br />
                                      ・表示するコンテンツのカスタマイズ
                                      <br />
                                      ・スパムや不正利用の防御
                                      <br />
                                      ・より関連性の高い広告の表示
                                      <br />
                                      ・サブスクリプション機能の提供や、特定のコンテンツの配布
                                      <br />
                                      ・Twitterサービスの利用状況や、改善の余地の把握
                                      <br />
                                      ・Twitterの広告やマーケティングの効果の測定
                                      <br />
                                      ・Twitterサービスのパフォーマンスの評価や、バグと品質に関するその他の問題の特定
                                      <br />
                                      ・オーディエンス規模の把握からTwitterルールの適用まで、Twitterのさまざまな業務の遂行に使用するデータの収集&quot;
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text__center">
                            <a href="https://twitter.com/ja/privacy" target="_blank" rel="noreferrer">
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center">
                            <a
                              href="https://help.twitter.com/ja/rules-and-policies/twitter-cookies-japan"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center">
                            <a
                              href="https://help.twitter.com/ja/rules-and-policies/twitter-cookies-japann"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="classification targeting">広告・マーケティング</span>
                          </td>
                          <td>スマートニュース株式会社</td>
                          <td>
                            <span className="service_names"> SmartNews Ads </span>
                          </td>
                          <td>
                            <img
                              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                              alt=""
                              height="30px"
                              width="30px"
                              onClick={openActive}
                              className="icon__button"
                            />
                            <div className="terms-modal">
                              <div className="terms-modal__content" onClick={actionNone}>
                                <span className="terms-modal__close" onClick={closePassive}>
                                  ×
                                </span>
                                <h2 className="terms-modal__title">
                                  <span className="terms-modal__company__names">
                                    スマートニュース株式会社
                                  </span>
                                  <span className="terms-modal__service_names">SmartNews Ads</span>
                                </h2>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      ・ユーザの端末に関する情報
                                      <br />
                                      ・端末識別子
                                      <br />
                                      ・ユーザの位置情報
                                      <br />
                                      ・ユーザが利用するウェブサイトや広告
                                      <br />
                                      ・ユーザの閲覧パターン
                                      <br />
                                      ・ユーザが当社のパートナーのサービスをどのように利用しているかに関する情報を含む、本サービス外におけるユーザの行動や属性、興味関心に関する情報
                                    </p>
                                  </div>
                                </div>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">利用目的</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      SmartNews
                                      Adsにおいて、サイト利用者に関する情報を分析し、インターネット上に当該サイト利用者に適した当社の広告を配信するために利用します。また、SmartNewsではこれらの情報を、当社以外から収集した利用者情報と突合・分析し、当社以外の事業者の広告の最適化にも利用する場合があります。
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text__center">
                            <a
                              href="https://www.smartnews.com/policy/privacy/jp/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center">
                            <a
                              href="https://www.smartnews-ads.com/optout/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center"></td>
                        </tr>
                        <tr>
                          <td>
                            <span className="classification targeting">広告・マーケティング</span>
                          </td>
                          <td>LINEヤフー株式会社</td>
                          <td>
                            <span className="service_names"> Yahoo!広告 </span>
                          </td>
                          <td>
                            <img
                              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                              alt=""
                              height="30px"
                              width="30px"
                              onClick={openActive}
                              className="icon__button"
                            />
                            <div className="terms-modal">
                              <div className="terms-modal__content" onClick={actionNone}>
                                <span className="terms-modal__close" onClick={closePassive}>
                                  ×
                                </span>
                                <h2 className="terms-modal__title">
                                  <span className="terms-modal__company__names">LINEヤフー株式会社</span>
                                  <span className="terms-modal__service_names"> Yahoo!広告 </span>
                                </h2>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      ・お客様がお使いの端末情報
                                      <br />
                                      ・ブラウザを識別する広告識別子
                                      <br />
                                      ・Cookie IDや端末の種類
                                      <br />
                                      ・OS
                                      <br />
                                      ・言語
                                      <br />
                                      ・タイムゾーン設定
                                      <br />
                                      ・ブラウザの種類
                                      <br />
                                      ・アプリバージョン
                                      <br />
                                      ・ネットワーク情報（通信事業者の名称、IPアドレスなど）
                                      <br />
                                      ・位置情報
                                      <br />
                                      ・サービス利用状況（利用日時、滞在時間、閲覧・タップ・クリックしたコンテンツや広告など）
                                      <br />
                                      ・属性情報、購買履歴、視聴履歴、検索履歴および位置情報を含むお客様に関する行動履歴
                                      <br />
                                      ・ウェブサイトの訪問履歴（訪問日時、URLなどを含む）
                                    </p>
                                  </div>
                                </div>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">利用目的</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      当社では、LINEヤフー株式会社のYahoo!広告サービスを利用し、サイト利用者に関する情報の分析と、当社サイト上での利用者に適した広告の配信を行っています。また、当社以外のサイト上に、利用者に適した当社の広告を配信するために利用する場合があります。LINEヤフーでは、当該Yahoo!広告のマーケティングサービスの運営・改善のために、サイト利用者に関する情報を利用しています。なお、LINEヤフーでは、収集データ、その他LINEヤフーが保有する各種データとの掛け合わせや組み合わせ・必要な各種データの不可・補完等を行うことができ、例えば、LINEヤフーでは、サイト利用者の訪問履歴と広告識別子や内部識別子などを関連づけ、当社以外から収集した利用者情報と突合・分析し、当社以外の事業者の広告の最適化にも利用する場合があります。
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text__center">
                            <a href="https://www.lycorp.co.jp/ja/company/privacypolicy/" target="_blank" rel="noreferrer">
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center">
                            <a
                              href="https://accounts.yahoo.co.jp/privacy/optout/ads"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center"></td>
                        </tr>
                        <tr>
                          <td>
                            <span className="classification targeting">広告・マーケティング</span>
                          </td>
                          <td>株式会社インタースペース</td>
                          <td>
                            <span className="service_names"> Accesstrade </span>
                          </td>
                          <td>
                            <img
                              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                              alt=""
                              height="30px"
                              width="30px"
                              onClick={openActive}
                              className="icon__button"
                            />
                            <div className="terms-modal">
                              <div className="terms-modal__content" onClick={actionNone}>
                                <span className="terms-modal__close" onClick={closePassive}>
                                  ×
                                </span>
                                <h2 className="terms-modal__title">
                                  <span className="terms-modal__company__names">
                                    株式会社インタースペース
                                  </span>
                                  <span className="terms-modal__service_names"> Accesstrade </span>
                                </h2>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      当社が、cookieを通じて取得する情報には、IPアドレス、ブラウザ、オペレーティングシステムとデバイスの特性、言語設定、モバイルデバイスまたは広告ID、参照URL、実行したアクションなど、コンピュータ、デバイス、およびブラウザに関する情報が含まれます。
                                    </p>
                                  </div>
                                </div>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">利用目的</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      ・本サービスの提供、運営および保守のため
                                      <br />
                                      ・本人確認、認証サービス、サポートのため
                                      <br />
                                      ・サイト審査、不正対策のため
                                      <br />
                                      ・個人関連情報などを利用した成果の計測・確認のため
                                      <br />
                                      ・広告報酬の請求ならびに支払のため
                                      <br />
                                      ・本サービスの広告プログラムの案内および広告主や第三者の商品やサービスの紹介、販売勧誘、発送、サービス提供のため
                                      <br />
                                      ・成果実績等のマーケティングデータの統計や分析のため
                                      <br />
                                      ・アフターサービス、問い合わせ、苦情対応のため
                                      <br />
                                      ・日本アフィリエイト・サービス協会への登録情報の届出のため
                                      <br />
                                      ・広告主、広告代理店および媒体者への提案のため
                                      <br />
                                      ・不正不当な目的で本サービスを利用した者に対処するため
                                      <br />
                                      ・その他本サービスにおいて個別に定める目的のため
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text__center">
                            <a
                              href="https://www.accesstrade.ne.jp/privacy"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center"></td>
                          <td className="text__center">
                            <a
                              href="https://www.accesstrade.ne.jp/cookie"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span className="classification targeting">広告・マーケティング</span>
                          </td>
                          <td>株式会社インティメート・マージャー</td>
                          <td>
                            <span className="service_names"> IM-DMP </span>
                          </td>
                          <td>
                            <img
                              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                              alt=""
                              height="30px"
                              width="30px"
                              onClick={openActive}
                              className="icon__button"
                            />
                            <div className="terms-modal">
                              <div className="terms-modal__content" onClick={actionNone}>
                                <span className="terms-modal__close" onClick={closePassive}>
                                  ×
                                </span>
                                <h2 className="terms-modal__title">
                                  <span className="terms-modal__company__names">
                                    株式会社インティメート・マージャー
                                  </span>
                                  <span className="terms-modal__service_names"> IM-DMP </span>
                                </h2>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      ・クッキー
                                      <br />
                                      ・IPアドレス
                                      <br />
                                      ・ユーザーエージェント
                                      <br />
                                      ・スマートフォン端末用の広告掲載ID(IDFA、AAID)
                                      <br />
                                      ・ウェブサイトページのURLおよびリファラ
                                      <br />
                                      ・ウェブサイトへ訪問した日時
                                      <br />
                                      ・タイトル・メタキーワード
                                      <br />
                                      ・行動の履歴に関する情報（配信された広告へのクリックなど）
                                    </p>
                                  </div>
                                </div>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">利用目的</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      IM-DMPは、株式会社インティメート・マージャーが提供するDMP（データマネジメントプラットフォーム）で、オンラインやオフラインで収集した様々なデータを統合したうえで、データの正規化・分析・抽出を行い、利用企業のマーケティング活動に役立つインサイトを提供します。当社では、IM-DMPにより抽出されたオーディエンスデータ（ユーザープロファイルなど）を利用してセグメント化し、サイト利用者の興味関心に合わせた広告配信や、製品・サービスのご案内のために利用します。
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text__center">
                            <a
                              href="https://corp.intimatemerger.com/privacypolicy/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center">
                            <a
                              href="https://privacy.intimatemerger.com/choice/ja.html"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center"></td>
                        </tr>
                        <tr>
                          <td>
                            <span className="classification targeting">広告・マーケティング</span>
                          </td>
                          <td>株式会社ファンコミュニケーションズ</td>
                          <td>
                            <span className="service_names"> a8.net </span>
                          </td>
                          <td>
                            <img
                              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                              alt=""
                              height="30px"
                              width="30px"
                              onClick={openActive}
                              className="icon__button"
                            />
                            <div className="terms-modal">
                              <div className="terms-modal__content" onClick={actionNone}>
                                <span className="terms-modal__close" onClick={closePassive}>
                                  ×
                                </span>
                                <h2 className="terms-modal__title">
                                  <span className="terms-modal__company__names">
                                    株式会社ファンコミュニケーションズ
                                  </span>
                                  <span className="terms-modal__service_names"> a8.net </span>
                                </h2>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      ・ご利用者様識別情報（メディアID、パスワード等）
                                      <br />
                                      ・IPアドレス、OSバージョン情報、端末名
                                      <br />
                                      ・PUSH配信用ID
                                    </p>
                                  </div>
                                </div>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">利用目的</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      ・ご利用者様を識別するため
                                      <br />
                                      ・サービスの提供、統計、マーケティング分析、システムメンテナンス不具合対応を行うため
                                      <br />
                                      ・プッシュ通知配信をするため
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text__center">
                            <a href="https://www.a8.net/appPrivacy.html" target="_blank" rel="noreferrer">
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center"></td>
                          <td className="text__center"></td>
                        </tr>
                        <tr>
                          <td>
                            <span className="classification targeting">広告・マーケティング</span>
                          </td>
                          <td>株式会社フォーイット</td>
                          <td>
                            <span className="service_names"> afb </span>
                          </td>
                          <td>
                            <img
                              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                              alt=""
                              height="30px"
                              width="30px"
                              onClick={openActive}
                              className="icon__button"
                            />
                            <div className="terms-modal">
                              <div className="terms-modal__content" onClick={actionNone}>
                                <span className="terms-modal__close" onClick={closePassive}>
                                  ×
                                </span>
                                <h2 className="terms-modal__title">
                                  <span className="terms-modal__company__names">
                                    株式会社フォーイット
                                  </span>
                                  <span className="terms-modal__service_names"> afb </span>
                                </h2>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      履歴情報とは、当社が運営するサイト等をご覧いただきましたお客様の行動履歴や個人属性に関する情報で、個人を特定するような情報は含まれないものをいいます。具体的には、ご利用されているドメイン名や
                                      IPアドレスおよびそれに割り当てられた組織名、アクセスされた日時やファイル名、リンク元のページのURL、使用しているブラウザやOSの種類、携帯端末の個体識別情報、広告識別子、cookie等の情報などを指します。
                                    </p>
                                  </div>
                                </div>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">利用目的</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      当社では、当社のサービスをより便利にご利用いただくために、履歴情報を使用しています。収集したお客様の履歴情報は、ウェブサイトの利用状況に関する統計分析やサービス改善のための参考、システム障害や不正アクセスに対する調査・原因究明、最適なコンテンツ、広告（ターゲティング広告を含みます。）、各種サービスのご案内などをお客様に配信したりするために使用し、合法的かつ業務上正当に必要とされる期間に限り保持するものとします。
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text__center">
                            <a
                              href="https://www.afi-b.com/profile/privacy-policy/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center"></td>
                          <td className="text__center"></td>
                        </tr>
                        <tr>
                          <td>
                            <span className="classification targeting">広告・マーケティング</span>
                          </td>
                          <td>株式会社プラスアルファ・コンサルティング</td>
                          <td>
                            <span className="service_names"> Customer Rings </span>
                          </td>
                          <td>
                            <img
                              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                              alt=""
                              height="30px"
                              width="30px"
                              onClick={openActive}
                              className="icon__button"
                            />
                            <div className="terms-modal">
                              <div className="terms-modal__content" onClick={actionNone}>
                                <span className="terms-modal__close" onClick={closePassive}>
                                  ×
                                </span>
                                <h2 className="terms-modal__title">
                                  <span className="terms-modal__company__names">
                                    株式会社プラスアルファ・コンサルティング
                                  </span>
                                  <span className="terms-modal__service_names">Customer Rings</span>
                                </h2>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      ・IPアドレス
                                      <br />
                                      ・セッション
                                      <br />
                                      ・ブラウザタイプ
                                    </p>
                                  </div>
                                </div>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">利用目的</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      当社は、利用者から取得した情報を元にマーケティング調査・分析に活用をしています。また、株式会社プラスアルファ・コンサルティングでは、これらの情報を用いて利用ユーザーに必要なデータの提供を行っています。
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text__center">
                            <a
                              href="https://www.pa-consul.co.jp/corporate/privacy/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center"></td>
                          <td className="text__center"></td>
                        </tr>
                        <tr>
                          <td>
                            <span className="classification targeting">広告・マーケティング</span>
                          </td>
                          <td>株式会社プレイド</td>
                          <td>
                            <span className="service_names"> Karte </span>
                          </td>
                          <td>
                            <img
                              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MTIiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+DQogIDxwYXRoIGlkPSJjaXJjbGUtaW5mby1zb2xpZCIgZD0iTTI1Niw1MTJjMTQxLjM4NSwwLDI1Ni0xMTQuNjE1LDI1Ni0yNTZTMzk3LjM4NSwwLDI1NiwwLDAsMTE0LjYxNSwwLDI1NiwxMTQuNjE1LDUxMiwyNTYsNTEyWk0yMTYsMzM2aDI0VjI3MkgyMTZhMjQsMjQsMCwwLDEsMC00OGg0OGEyMy45NDIsMjMuOTQyLDAsMCwxLDI0LDI0djg4aDhhMjQsMjQsMCwwLDEsMCw0OEgyMTZhMjQsMjQsMCwwLDEsMC00OFptNDAtMjA4YTMyLDMyLDAsMSwxLTMyLDMyQTMyLDMyLDAsMCwxLDI1NiwxMjhaIiBmaWxsPSIjNmY2ZjZmIi8+DQo8L3N2Zz4NCg=="
                              alt=""
                              height="30px"
                              width="30px"
                              onClick={openActive}
                              className="icon__button"
                            />
                            <div className="terms-modal">
                              <div className="terms-modal__content" onClick={actionNone}>
                                <span className="terms-modal__close" onClick={closePassive}>
                                  ×
                                </span>
                                <h2 className="terms-modal__title">
                                  <span className="terms-modal__company__names">株式会社プレイド</span>
                                  <span className="terms-modal__service_names"> Karte </span>
                                </h2>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">送信される利用者情報</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      ・クッキー
                                      <br />
                                      ・IPアドレス情報
                                      <br />
                                      ・ウェブサイト及びアプリの閲覧履歴
                                      <br />
                                      ・行動履歴及び購買履歴
                                      <br />
                                      ・端末 ID
                                      <br />
                                      ・ユーザーエージェント
                                      <br />
                                      ・リファラ
                                      <br />
                                      ・属性等の類推情報等(性別、年齢、閲覧履
                                      歴、行動履歴及び購買履歴、モバイル端末向けの広告ID、位置情報等を
                                      分析して、ユーザーの属性及び趣向等を類推した情報をいいます。)
                                    </p>
                                  </div>
                                </div>
                                <div className="terms-modal__user__information">
                                  <h3 className="terms-modal__sub__title">利用目的</h3>
                                  <div className="terms-modal__in__content">
                                    <p>
                                      KARTEは株式会社プレイドが提供する顧客体験（CX = Customer
                                      Experience）をより良くするための機能がひとつに集約されたサービスで、リアルタイムで更新されるサイトやアプリに訪れるユーザーの行動を、一人ひとりのユーザー行動やセグメントごとに集団の傾向をさまざまな粒度で分析し、自社で保有する社内のあらゆるデータを統合することで、当社のより精緻なマーケティング活動に役立てます。
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="text__center">
                            <a href="https://plaid.co.jp/privacy/" target="_blank" rel="noreferrer">
                              <img
                                src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMy4wIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMzUyIDBjLTEyLjkgMC0yNC42IDcuOC0yOS42IDE5LjhzLTIuMiAyNS43IDYuOSAzNC45TDM3MC43IDk2IDIwMS40IDI2NS40Yy0xMi41IDEyLjUtMTIuNSAzMi44IDAgNDUuM3MzMi44IDEyLjUgNDUuMyAwTDQxNiAxNDEuM2w0MS40IDQxLjRjOS4yIDkuMiAyMi45IDExLjkgMzQuOSA2LjlzMTkuOC0xNi42IDE5LjgtMjkuNlYzMmMwLTE3LjctMTQuMy0zMi0zMi0zMkgzNTJ6TTgwIDMyQzM1LjggMzIgMCA2Ny44IDAgMTEyVjQzMmMwIDQ0LjIgMzUuOCA4MCA4MCA4MEg0MDBjNDQuMiAwIDgwLTM1LjggODAtODBWMzIwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjQzMmMwIDguOC03LjIgMTYtMTYgMTZIODBjLTguOCAwLTE2LTcuMi0xNi0xNlYxMTJjMC04LjggNy4yLTE2IDE2LTE2SDE5MmMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkg4MHoiLz48L3N2Zz4="
                                alt=""
                              />
                            </a>
                          </td>
                          <td className="text__center"></td>
                          <td className="text__center"></td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="md:hidden lg:hidden">
                      <ul className="service__list">
                        <li className="list__item">
                          <dl className="service__list__table">
                            <dt>
                              <span className="py-6 inline-block">分類</span>
                            </dt>
                            <dd>
                              <span className="classification performance">アクセス解析</span>
                            </dd>
                            <dt>事業者</dt>
                            <dd>Google LLC</dd>
                            <dt>サービス名</dt>
                            <dd>
                              <span className="service_names"> Googleアナリティクス </span>
                            </dd>
                            <dt>
                              送信される
                              <br />
                              利用者情報
                            </dt>
                            <dd>
                              <p>
                                ・クライアントID
                                <br />
                                ・IPアドレス
                                <br />
                                ・閲覧ページURL
                                <br />
                                ・リファラー
                                <br />
                                ・デバイスタイプ
                                <br />
                                ・オペレーティングシステム
                                <br />
                                ・ブラウザタイプ
                                <br />
                                ・利用言語
                                <br />
                                ・画像解像度
                              </p>
                            </dd>
                            <dt>利用目的</dt>
                            <dd>
                              <p>
                                当社は、ウェブサイトのパフォーマンスを分析し、改善に役立てるため、サイト利用者に関する情報を収集し、Googleへ送信します。Googleでは、送信された情報を分析、統計化し、ウェブサイトのパフォーマンスに関するレポートを当社に提供します。また、Google
                                では、これらの情報をGoogle独自のサービス・プロダクトの改善を目的として利用する場合があります。
                              </p>
                            </dd>
                          </dl>
                          <div className="service__link__list">
                            <a
                              href="https://policies.google.com/privacy"
                              target="_blank"
                              rel="noreferrer"
                            >
                              プライバシーポリシー
                            </a>
                            <a
                              href="https://tools.google.com/dlpage/gaoptout/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              オプトアウトページ
                            </a>
                            <a
                              href="https://policies.google.com/technologies/cookies?hl=ja"
                              target="_blank"
                              rel="noreferrer"
                            >
                              クッキーポリシー
                            </a>
                          </div>
                        </li>
                        <li className="list__item">
                          <dl className="service__list__table">
                            <dt>
                              <span className="py-6 inline-block">分類</span>
                            </dt>
                            <dd>
                              <span className="classification targeting">広告・マーケティング</span>
                            </dd>
                            <dt>事業者</dt>
                            <dd>LINEヤフー株式会社</dd>
                            <dt>サービス名</dt>
                            <dd>
                              <span className="service_names"> LINE広告 </span>
                            </dd>
                            <dt>
                              送信される
                              <br />
                              利用者情報
                            </dt>
                            <dd>
                              <p>
                                ・お客様がお使いの端末情報
                                <br />
                                ・ブラウザを識別する広告識別子
                                <br />
                                ・Cookie IDや端末の種類
                                <br />
                                ・OS
                                <br />
                                ・言語
                                <br />
                                ・タイムゾーン設定
                                <br />
                                ・ブラウザの種類
                                <br />
                                ・アプリバージョン
                                <br />
                                ・ネットワーク情報（通信事業者の名称、IPアドレスなど）
                                <br />
                                ・位置情報
                                <br />
                                ・サービス利用状況（利用日時、滞在時間、閲覧・タップ・クリックしたコンテンツや広告など）
                                <br />
                                ・属性情報、購買履歴、視聴履歴、検索履歴および位置情報を含むお客様に関する行動履歴
                                <br />
                                ・ウェブサイトの訪問履歴（訪問日時、URLなどを含む）
                              </p>
                            </dd>
                            <dt>利用目的</dt>
                            <dd>
                              <p>
                                当社では、LINEヤフー株式会社のLINE広告サービスを利用し、サイト利用者に関する情報の分析とLINEサービス上における当該サイト利用者に適した広告の配信を行っています。LINEヤフーでは、当該LINE広告のマーケティングサービスの運営・改善のために、サイト利用者に関する情報を利用しています。なお、LINEヤフーでは、収集データ、その他LINEヤフーが保有する各種データとの掛け合わせや組み合わせ・必要な各種データの不可・補完等を行うことができ、例えば、LINEヤフーでは、サイト利用者の訪問履歴と広告識別子や内部識別子などを関連づけ、当社以外から収集した利用者情報と突合・分析し、当社以外の事業者の広告の最適化にも利用する場合があります。また、LINEは、「LINEで送る」や「いいね」などのプラグイン機能が当社ウェブサイトに設置されている場合、ウェブサイトにおけるプラグインからのログイン記録の他、ブラウザ上でLINEにログインした状態で訪問したプラグイン機能が設置されたウェブサイトのURL情報をLINEアカウントに関連づけて取得する場合があります。このように取得したデータは、LINEにおいて、LINEサービスがどのように利用されているかを把握し、その維持・改善、セキュリティ・不正利用防止の目的などに利用されます。なお、LINEからログアウトした状態では、これらの情報は個人を識別しない形でセキュリティ・不正利用防止の目的にのみ利用されます。
                              </p>
                            </dd>
                          </dl>
                          <div className="service__link__list">
                            <a href="https://line.me/ja/terms/policy/" target="_blank" rel="noreferrer">
                              プライバシーポリシー
                            </a>
                          </div>
                        </li>
                        <li className="list__item">
                          <dl className="service__list__table">
                            <dt>
                              <span className="py-6 inline-block">分類</span>
                            </dt>
                            <dd>
                              <span className="classification targeting">広告・マーケティング</span>
                            </dd>
                            <dt>事業者</dt>
                            <dd>Meta Platforms, Inc.</dd>
                            <dt>サービス名</dt>
                            <dd>
                              <span className="service_names"> Facebook広告 </span>
                            </dd>
                            <dt>
                              送信される
                              <br />
                              利用者情報
                            </dt>
                            <dd>
                              <p>
                                ・ユーザーID
                                <br />
                                ・アクセスしているサイトURL
                                <br />
                                ・アクセスした日付と時刻
                                <br />
                                ・ユーザーが利用しているブラウザーに関連した情報
                                <br />
                                ・利用者がアクションを実行した広告
                                <br />
                                ・利用者が広告に対していつアクションを実行したか
                                <br />
                                ・広告の表示場所(Instagram上、あるいはFacebook上など)
                              </p>
                            </dd>
                            <dt>利用目的</dt>
                            <dd>
                              <p>
                                サイト利用者が当社ウェブサイトのコンテンツをソーシャルネットワークサービスFacebook上で共有するための機能を提供する目的で利用する他、情報の送信先となるFacebookにおいて、サイト利用者に関する情報を分析し、Facebookサービス上に当該サイト利用者に適した当社の広告を配信するために利用する場合があります。また、Metaではこれらの情報を、Metaの製品の品質を向上させるために使用する他、当社以外から収集した利用者情報と突合・分析し、当社以外の事業者の広告の最適化にも利用する場合があります。
                              </p>
                            </dd>
                          </dl>
                          <div className="service__link__list">
                            <a
                              href="https://www.facebook.com/privacy/policy"
                              target="_blank"
                              rel="noreferrer"
                            >
                              プライバシーポリシー
                            </a>
                            <a
                              href="https://www.facebook.com/help/568137493302217"
                              target="_blank"
                              rel="noreferrer"
                            >
                              オプトアウトページ
                            </a>
                            <a
                              href="https://www.facebook.com/privacy/policies/cookies"
                              target="_blank"
                              rel="noreferrer"
                            >
                              クッキーポリシー
                            </a>
                          </div>
                        </li>
                        <li className="list__item">
                          <dl className="service__list__table">
                            <dt>
                              <span className="py-6 inline-block">分類</span>
                            </dt>
                            <dd>
                              <span className="classification targeting">広告・マーケティング</span>
                            </dd>
                            <dt>事業者</dt>
                            <dd>Twitter, Inc.</dd>
                            <dt>サービス名</dt>
                            <dd>
                              <span className="service_names"> Twitter </span>
                            </dd>
                            <dt>
                              送信される
                              <br />
                              利用者情報
                            </dt>
                            <dd>
                              <p>
                                ・クライアントID
                                <br />
                                ・ウェブサイトへのアクセス状況
                                <br />
                                ・特定のウェブコンテンツやメールコンテンツの利用の有無
                              </p>
                            </dd>
                            <dt>利用目的</dt>
                            <dd>
                              <p>
                                &quot;当社では、当社ウェブサイトでのソーシャルメディア機能の提供およびサイト利用者に適した広告の表示のために利用します。Twitterでは、以下の用途で利用される場合があります。
                                <br />
                                ・Twitterへのログイン状態の維持
                                <br />
                                ・Twitterサービスのさまざまな機能の提供
                                <br />
                                ・個人設定の保存や、個人の嗜好の尊重
                                <br />
                                ・表示するコンテンツのカスタマイズ
                                <br />
                                ・スパムや不正利用の防御
                                <br />
                                ・より関連性の高い広告の表示
                                <br />
                                ・サブスクリプション機能の提供や、特定のコンテンツの配布
                                <br />
                                ・Twitterサービスの利用状況や、改善の余地の把握
                                <br />
                                ・Twitterの広告やマーケティングの効果の測定
                                <br />
                                ・Twitterサービスのパフォーマンスの評価や、バグと品質に関するその他の問題の特定
                                <br />
                                ・オーディエンス規模の把握からTwitterルールの適用まで、Twitterのさまざまな業務の遂行に使用するデータの収集&quot;
                              </p>
                            </dd>
                          </dl>
                          <div className="service__link__list">
                            <a href="https://twitter.com/ja/privacy" target="_blank" rel="noreferrer">
                              プライバシーポリシー
                            </a>
                            <a
                              href="https://help.twitter.com/ja/rules-and-policies/twitter-cookies-japan"
                              target="_blank"
                              rel="noreferrer"
                            >
                              オプトアウトページ
                            </a>
                            <a
                              href="https://help.twitter.com/ja/rules-and-policies/twitter-cookies-japann"
                              target="_blank"
                              rel="noreferrer"
                            >
                              クッキーポリシー
                            </a>
                          </div>
                        </li>
                        <li className="list__item">
                          <dl className="service__list__table">
                            <dt>
                              <span className="py-6 inline-block">分類</span>
                            </dt>
                            <dd>
                              <span className="classification targeting">広告・マーケティング</span>
                            </dd>
                            <dt>事業者</dt>
                            <dd>スマートニュース株式会社</dd>
                            <dt>サービス名</dt>
                            <dd>
                              <span className="service_names"> SmartNews Ads </span>
                            </dd>
                            <dt>
                              送信される
                              <br />
                              利用者情報
                            </dt>
                            <dd>
                              <p>
                                ・ユーザの端末に関する情報
                                <br />
                                ・端末識別子
                                <br />
                                ・ユーザの位置情報
                                <br />
                                ・ユーザが利用するウェブサイトや広告
                                <br />
                                ・ユーザの閲覧パターン
                                <br />
                                ・ユーザが当社のパートナーのサービスをどのように利用しているかに関する情報を含む、本サービス外におけるユーザの行動や属性、興味関心に関する情報
                              </p>
                            </dd>
                            <dt>利用目的</dt>
                            <dd>
                              <p>
                                SmartNews
                                Adsにおいて、サイト利用者に関する情報を分析し、インターネット上に当該サイト利用者に適した当社の広告を配信するために利用します。また、SmartNewsではこれらの情報を、当社以外から収集した利用者情報と突合・分析し、当社以外の事業者の広告の最適化にも利用する場合があります。
                              </p>
                            </dd>
                          </dl>
                          <div className="service__link__list">
                            <a
                              href="https://www.smartnews.com/policy/privacy/jp/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              プライバシーポリシー
                            </a>
                            <a
                              href="https://www.smartnews-ads.com/optout/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              オプトアウトページ
                            </a>
                          </div>
                        </li>
                        <li className="list__item">
                          <dl className="service__list__table">
                            <dt>
                              <span className="py-6 inline-block">分類</span>
                            </dt>
                            <dd>
                              <span className="classification targeting">広告・マーケティング</span>
                            </dd>
                            <dt>事業者</dt>
                            <dd>LINEヤフー株式会社</dd>
                            <dt>サービス名</dt>
                            <dd>
                              <span className="service_names"> Yahoo!広告 </span>
                            </dd>
                            <dt>
                              送信される
                              <br />
                              利用者情報
                            </dt>
                            <dd>
                              <p>
                                ・お客様がお使いの端末情報
                                <br />
                                ・ブラウザを識別する広告識別子
                                <br />
                                ・Cookie IDや端末の種類
                                <br />
                                ・OS
                                <br />
                                ・言語
                                <br />
                                ・タイムゾーン設定
                                <br />
                                ・ブラウザの種類
                                <br />
                                ・アプリバージョン
                                <br />
                                ・ネットワーク情報（通信事業者の名称、IPアドレスなど）
                                <br />
                                ・位置情報
                                <br />
                                ・サービス利用状況（利用日時、滞在時間、閲覧・タップ・クリックしたコンテンツや広告など）
                                <br />
                                ・属性情報、購買履歴、視聴履歴、検索履歴および位置情報を含むお客様に関する行動履歴
                                <br />
                                ・ウェブサイトの訪問履歴（訪問日時、URLなどを含む）
                              </p>
                            </dd>
                            <dt>利用目的</dt>
                            <dd>
                              <p>
                                当社では、LINEヤフー株式会社のYahoo!広告サービスを利用し、サイト利用者に関する情報の分析と、当社サイト上での利用者に適した広告の配信を行っています。また、当社以外のサイト上に、利用者に適した当社の広告を配信するために利用する場合があります。LINEヤフーでは、当該Yahoo!広告のマーケティングサービスの運営・改善のために、サイト利用者に関する情報を利用しています。なお、LINEヤフーでは、収集データ、その他LINEヤフーが保有する各種データとの掛け合わせや組み合わせ・必要な各種データの不可・補完等を行うことができ、例えば、LINEヤフーでは、サイト利用者の訪問履歴と広告識別子や内部識別子などを関連づけ、当社以外から収集した利用者情報と突合・分析し、当社以外の事業者の広告の最適化にも利用する場合があります。
                              </p>
                            </dd>
                          </dl>
                          <div className="service__link__list">
                            <a href="https://www.lycorp.co.jp/ja/company/privacypolicy/" target="_blank" rel="noreferrer">
                              プライバシーポリシー
                            </a>
                            <a
                              href="https://accounts.yahoo.co.jp/privacy/optout/ads"
                              target="_blank"
                              rel="noreferrer"
                            >
                              オプトアウトページ
                            </a>
                          </div>
                        </li>
                        <li className="list__item">
                          <dl className="service__list__table">
                            <dt>
                              <span className="py-6 inline-block">分類</span>
                            </dt>
                            <dd>
                              <span className="classification targeting">広告・マーケティング</span>
                            </dd>
                            <dt>事業者</dt>
                            <dd>株式会社インタースペース</dd>
                            <dt>サービス名</dt>
                            <dd>
                              <span className="service_names"> Accesstrade </span>
                            </dd>
                            <dt>
                              送信される
                              <br />
                              利用者情報
                            </dt>
                            <dd>
                              <p>
                                当社が、cookieを通じて取得する情報には、IPアドレス、ブラウザ、オペレーティングシステムとデバイスの特性、言語設定、モバイルデバイスまたは広告ID、参照URL、実行したアクションなど、コンピュータ、デバイス、およびブラウザに関する情報が含まれます。
                              </p>
                            </dd>
                            <dt>利用目的</dt>
                            <dd>
                              <p>
                                ・本サービスの提供、運営および保守のため
                                <br />
                                ・本人確認、認証サービス、サポートのため
                                <br />
                                ・サイト審査、不正対策のため
                                <br />
                                ・個人関連情報などを利用した成果の計測・確認のため
                                <br />
                                ・広告報酬の請求ならびに支払のため
                                <br />
                                ・本サービスの広告プログラムの案内および広告主や第三者の商品やサービスの紹介、販売勧誘、発送、サービス提供のため
                                <br />
                                ・成果実績等のマーケティングデータの統計や分析のため
                                <br />
                                ・アフターサービス、問い合わせ、苦情対応のため
                                <br />
                                ・日本アフィリエイト・サービス協会への登録情報の届出のため
                                <br />
                                ・広告主、広告代理店および媒体者への提案のため
                                <br />
                                ・不正不当な目的で本サービスを利用した者に対処するため
                                <br />
                                ・その他本サービスにおいて個別に定める目的のため
                              </p>
                            </dd>
                          </dl>
                          <div className="service__link__list">
                            <a
                              href="https://www.accesstrade.ne.jp/privacy"
                              target="_blank"
                              rel="noreferrer"
                            >
                              プライバシーポリシー
                            </a>
                            <a
                              href="https://www.accesstrade.ne.jp/cookie"
                              target="_blank"
                              rel="noreferrer"
                            >
                              クッキーポリシー
                            </a>
                          </div>
                        </li>
                        <li className="list__item">
                          <dl className="service__list__table">
                            <dt>
                              <span className="py-6 inline-block">分類</span>
                            </dt>
                            <dd>
                              <span className="classification targeting">広告・マーケティング</span>
                            </dd>
                            <dt>事業者</dt>
                            <dd>株式会社インティメート・マージャー</dd>
                            <dt>サービス名</dt>
                            <dd>
                              <span className="service_names"> IM-DMP </span>
                            </dd>
                            <dt>
                              送信される
                              <br />
                              利用者情報
                            </dt>
                            <dd>
                              <p>
                                ・クッキー
                                <br />
                                ・IPアドレス
                                <br />
                                ・ユーザーエージェント
                                <br />
                                ・スマートフォン端末用の広告掲載ID(IDFA、AAID)
                                <br />
                                ・ウェブサイトページのURLおよびリファラ
                                <br />
                                ・ウェブサイトへ訪問した日時
                                <br />
                                ・タイトル・メタキーワード
                                <br />
                                ・行動の履歴に関する情報（配信された広告へのクリックなど）
                              </p>
                            </dd>
                            <dt>利用目的</dt>
                            <dd>
                              <p>
                                IM-DMPは、株式会社インティメート・マージャーが提供するDMP（データマネジメントプラットフォーム）で、オンラインやオフラインで収集した様々なデータを統合したうえで、データの正規化・分析・抽出を行い、利用企業のマーケティング活動に役立つインサイトを提供します。当社では、IM-DMPにより抽出されたオーディエンスデータ（ユーザープロファイルなど）を利用してセグメント化し、サイト利用者の興味関心に合わせた広告配信や、製品・サービスのご案内のために利用します。
                              </p>
                            </dd>
                          </dl>
                          <div className="service__link__list">
                            <a
                              href="https://corp.intimatemerger.com/privacypolicy/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              プライバシーポリシー
                            </a>
                            <a
                              href="https://privacy.intimatemerger.com/choice/ja.html"
                              target="_blank"
                              rel="noreferrer"
                            >
                              オプトアウトページ
                            </a>
                          </div>
                        </li>
                        <li className="list__item">
                          <dl className="service__list__table">
                            <dt>
                              <span className="py-6 inline-block">分類</span>
                            </dt>
                            <dd>
                              <span className="classification targeting">広告・マーケティング</span>
                            </dd>
                            <dt>事業者</dt>
                            <dd>株式会社ファンコミュニケーションズ</dd>
                            <dt>サービス名</dt>
                            <dd>
                              <span className="service_names"> a8.net </span>
                            </dd>
                            <dt>
                              送信される
                              <br />
                              利用者情報
                            </dt>
                            <dd>
                              <p>
                                ・ご利用者様識別情報（メディアID、パスワード等）
                                <br />
                                ・IPアドレス、OSバージョン情報、端末名
                                <br />
                                ・PUSH配信用ID
                              </p>
                            </dd>
                            <dt>利用目的</dt>
                            <dd>
                              <p>
                                ・ご利用者様を識別するため
                                <br />
                                ・サービスの提供、統計、マーケティング分析、システムメンテナンス不具合対応を行うため
                                <br />
                                ・プッシュ通知配信をするため
                              </p>
                            </dd>
                          </dl>
                          <div className="service__link__list">
                            <a href="https://www.a8.net/appPrivacy.html" target="_blank" rel="noreferrer">
                              プライバシーポリシー
                            </a>
                          </div>
                        </li>
                        <li className="list__item">
                          <dl className="service__list__table">
                            <dt>
                              <span className="py-6 inline-block">分類</span>
                            </dt>
                            <dd>
                              <span className="classification targeting">広告・マーケティング</span>
                            </dd>
                            <dt>事業者</dt>
                            <dd>株式会社フォーイット</dd>
                            <dt>サービス名</dt>
                            <dd>
                              <span className="service_names"> afb </span>
                            </dd>
                            <dt>
                              送信される
                              <br />
                              利用者情報
                            </dt>
                            <dd>
                              <p>
                                履歴情報とは、当社が運営するサイト等をご覧いただきましたお客様の行動履歴や個人属性に関する情報で、個人を特定するような情報は含まれないものをいいます。具体的には、ご利用されているドメイン名や
                                IPアドレスおよびそれに割り当てられた組織名、アクセスされた日時やファイル名、リンク元のページのURL、使用しているブラウザやOSの種類、携帯端末の個体識別情報、広告識別子、cookie等の情報などを指します。
                              </p>
                            </dd>
                            <dt>利用目的</dt>
                            <dd>
                              <p>
                                当社では、当社のサービスをより便利にご利用いただくために、履歴情報を使用しています。収集したお客様の履歴情報は、ウェブサイトの利用状況に関する統計分析やサービス改善のための参考、システム障害や不正アクセスに対する調査・原因究明、最適なコンテンツ、広告（ターゲティング広告を含みます。）、各種サービスのご案内などをお客様に配信したりするために使用し、合法的かつ業務上正当に必要とされる期間に限り保持するものとします。
                              </p>
                            </dd>
                          </dl>
                          <div className="service__link__list">
                            <a
                              href="https://www.afi-b.com/profile/privacy-policy/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              プライバシーポリシー
                            </a>
                          </div>
                        </li>
                        <li className="list__item">
                          <dl className="service__list__table">
                            <dt>
                              <span className="py-6 inline-block">分類</span>
                            </dt>
                            <dd>
                              <span className="classification targeting">広告・マーケティング</span>
                            </dd>
                            <dt>事業者</dt>
                            <dd>株式会社プラスアルファ・コンサルティング</dd>
                            <dt>サービス名</dt>
                            <dd>
                              <span className="service_names"> Customer Rings </span>
                            </dd>
                            <dt>
                              送信される
                              <br />
                              利用者情報
                            </dt>
                            <dd>
                              <p>
                                ・IPアドレス
                                <br />
                                ・セッション
                                <br />
                                ・ブラウザタイプ
                              </p>
                            </dd>
                            <dt>利用目的</dt>
                            <dd>
                              <p>
                                当社は、利用者から取得した情報を元にマーケティング調査・分析に活用をしています。また、株式会社プラスアルファ・コンサルティングでは、これらの情報を用いて利用ユーザーに必要なデータの提供を行っています。
                              </p>
                            </dd>
                          </dl>
                          <div className="service__link__list">
                            <a
                              href="https://www.pa-consul.co.jp/corporate/privacy/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              プライバシーポリシー
                            </a>
                          </div>
                        </li>
                        <li className="list__item">
                          <dl className="service__list__table">
                            <dt>
                              <span className="py-6 inline-block">分類</span>
                            </dt>
                            <dd>
                              <span className="classification targeting">広告・マーケティング</span>
                            </dd>
                            <dt>事業者</dt>
                            <dd>株式会社プレイド</dd>
                            <dt>サービス名</dt>
                            <dd>
                              <span className="service_names"> Karte </span>
                            </dd>
                            <dt>
                              送信される
                              <br />
                              利用者情報
                            </dt>
                            <dd>
                              <p>
                                ・クッキー
                                <br />
                                ・IPアドレス情報
                                <br />
                                ・ウェブサイト及びアプリの閲覧履歴
                                <br />
                                ・行動履歴及び購買履歴
                                <br />
                                ・端末 ID
                                <br />
                                ・ユーザーエージェント
                                <br />
                                ・リファラ
                                <br />
                                ・属性等の類推情報等(性別、年齢、閲覧履
                                歴、行動履歴及び購買履歴、モバイル端末向けの広告ID、位置情報等を
                                分析して、ユーザーの属性及び趣向等を類推した情報をいいます。)
                              </p>
                            </dd>
                            <dt>利用目的</dt>
                            <dd>
                              <p>
                                KARTEは株式会社プレイドが提供する顧客体験（CX = Customer
                                Experience）をより良くするための機能がひとつに集約されたサービスで、リアルタイムで更新されるサイトやアプリに訪れるユーザーの行動を、一人ひとりのユーザー行動やセグメントごとに集団の傾向をさまざまな粒度で分析し、自社で保有する社内のあらゆるデータを統合することで、当社のより精緻なマーケティング活動に役立てます。
                              </p>
                            </dd>
                          </dl>
                          <div className="service__link__list">
                            <a href="https://plaid.co.jp/privacy/" target="_blank" rel="noreferrer">
                              プライバシーポリシー
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ItemsWrapper>
              <ItemsWrapper border={false} title="インフォマティブデータのリセット">
                <p className="mbx-typography--body_1 mt-16">
                  利用者は、次のいずれかの方法により、当社が取得したインフォマティブデータをリセット（オプトアウト）することができます。
                </p>
                <ListItem type="Disc" className="mt-16" bold>
                  webサイトでのオプトアウトについて
                </ListItem>
                <p className="mbx-typography--body_2 mt-4 pl-24">
                  外部事業社へのインフォマティブデータの送信についてオプトアウトを希望する場合、各事業者が提供するウェブサイトの指示に従い、オプトアウトを行ってください。外部事業社のオプトアウトページは「インフォマティブデータの外部送信」をご確認ください。
                </p>
                <ListItem type="Disc" className="mt-16" bold>
                  Cookieのリセット
                </ListItem>
                <p className="mbx-typography--body_2 mt-4 pl-24">
                  ブラウザのCookieを削除または拒否することにより、それまで当社が取得したインフォマティブデータとの関連付けが削除され、インフォマティブデータがリセットされます。
                  <br />
                  Cookieを削除した後、本サイトを利用する場合、再びインフォマティブデータの取得が開始されることになります。
                  <br />
                  なお、Cookieを削除または拒否を行った場合、本サイトを利用するにあたり、制約を受ける場合があります。
                </p>
                <ListItem type="Disc" className="mt-16" bold>
                  スマートフォン上の広告識別子のリセット
                </ListItem>
                <p className="mbx-typography--body_2 mt-4 pl-24">
                  スマートフォン上の広告識別子を、次のOS提供会社のウェブサイトの指示に従いリセットすることにより、それまで端末上で取得したインフォマティブデータとの関連付けが削除され、インフォマティブデータがリセットされます。
                  <br />
                  なお、広告識別子を削除した後、本サイトを利用する場合、再びインフォマティブデータの取得が開始されることになります。
                </p>
                <p className="mbx-typography--body_2 mt-4 pl-24">
                  <span className="font-bold">OS提供会社</span>
                  <br />
                  <span>Apple Inc.</span>
                  <br />
                  <span>https://support.apple.com/ja-jp/HT202074</span>
                  <br />
                  <span>グーグル合同会社</span>
                  <br />
                  <span>https://support.google.com/googleplay/answer/3405269?hl=ja</span>
                </p>
                <ListItem type="Disc" className="mt-16" bold>
                  iOS、iPadOS版アプリのIDFA（iOS、iPadOSの広告識別子）のオプトアウトについて
                </ListItem>
                <p className="mbx-typography--body_2 mt-4 pl-24">
                  iOS、iPadOS版アプリでIDFAのトラッキングについて、オプトアウトを希望する場合、以下を確認してウェブサイトの指示に従い、オプトアウトを行ってください。
                  <br />
                  https://support.apple.com/ja-jp/HT212025
                </p>
              </ItemsWrapper>
            </section>
          </div>
        </main>
        <DirectFooter />
      </div>
    </DataLayerPushContainer>
  );
}

export default TermsOfUse;
