import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { DataLayerPushContainer } from '@/componentsDirect/common/DataLayerPushContainer';
import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { ProfileImageCircle } from '@/componentsDirect/Parts/OtherComponents/ProfileImageCircle';
import { DirectUserSectionChangeMail } from '@/componentsDirect/User/SectionChangeMail';
import { DirectUserSectionChangePassword } from '@/componentsDirect/User/SectionChangePassword';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';
import { useUser } from '@/hooksDirect/useUser';
import { CompanyUser } from '@/utils/api-client';

/**
 * Direct 個人設定 アカウントページ
 */
export const DirectUserAccount = (): React.ReactElement => {
  const { companyUser } = useUser();
  const methods = useForm<CompanyUser>({
    defaultValues: companyUser,
  });

  useEffect(() => {
    if (companyUser) setDefaultValues(companyUser);
  }, [companyUser]);

  // データを取得した後にRHFに値をsetValueする
  const setDefaultValues = (res: CompanyUser) => {
    (Object.keys(res) as (keyof CompanyUser)[]).forEach((v) => {
      if (res?.[v]) {
        methods.setValue(v, res?.[v]);
      }
    });
  };

  return (
    <DataLayerPushContainer
      data={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'page_view',
      }}
    >
      <section className="w-308 mx-auto pb-40 border-b border-gray-400">
        <FormProvider {...methods}>
          <form>
            <div className="mb-22">
              <ProfileImageCircle
                name="photo"
                iconName="Person"
                iconSize={70}
                readOnly
                animeClass="mbx-anime-img--none"
                className="mkt_mask_items"
              />
            </div>
            <div className="mbx-utils-stack-v--22">
              <FormLayoutFieldset>
                <FormLabel label="社内ユーザーID" type="small"></FormLabel>
                <FormContainerTextfield
                  name="matchbox_id"
                  className="w-full mkt_mask_items"
                  readOnly
                />
              </FormLayoutFieldset>
              <FormLayoutFieldset>
                <FormLabel label="アカウント種別" type="small"></FormLabel>
                <FormContainerDropdown
                  readOnly
                  name="user_type"
                  className="w-full mkt_mask_items"
                  placeholder="選択してください"
                  selectList={DIRECT_FORM_OPTIONS.user_type}
                />
              </FormLayoutFieldset>
            </div>
          </form>
        </FormProvider>
      </section>
      <DirectUserSectionChangeMail />
      <DirectUserSectionChangePassword />
    </DataLayerPushContainer>
  );
};
