import React, { useEffect, useState } from 'react';
import { FieldArrayWithId, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { AdminPage } from '@/componentsDirect/Layout/Page';
import { DirectUsersOfferPositionsDeleteComponent } from '@/componentsDirect/OfferPositions';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import {
  TableBodyOfferPositionList,
  TableContainer,
  TableHeader,
} from '@/componentsDirect/Parts/Table';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { useOfferPositions } from '@/hooksDirect/useOfferPositions';
import { OfferPositionData } from '@/utils/api-client/api';
import { moveArray } from '@/utils/utils';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

const columns = [
  {
    title: 'ポジション名（職種名）',
    className: 'whitespace-nowrap',
  },
  {
    title: 'ポジション詳細（業務内容詳細）',
  },
  {
    title: '',
    className: 'w-250',
  },
];

type TFormValues = {
  listData: Array<OfferPositionData>;
};

/**
 * ポジション管理 /direct/positions
 * https://github.com/prtytokyo/matchbox-2021-web-frontend/issues/1767
 *
 * 管理者のみ利用可能
 */
export const DirectOfferPositionsIndex = (): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const mq = useMBXMediaQuery();
  const history = useHistory();
  const [positionDeleteState, setPositionDeleteState] = useState({
    id: '',
    showModal: false,
  });

  // ====================================================================================================
  //
  // custom hooks
  //
  // ====================================================================================================
  const { offerPositions, getOfferPositions, pathOfferPositions } = useOfferPositions();

  // ====================================================================================================
  //
  // RHF
  //
  // ====================================================================================================
  const methods = useForm<TFormValues>();
  const { control } = methods;
  const { fields, move } = useFieldArray({
    control,
    name: 'listData',
  });

  // ====================================================================================================
  //
  // methods
  //
  // ====================================================================================================
  /**
   * 削除処理
   */
  const onDelete = (id: string) => {
    const idStr = id + '';
    setPositionDeleteState({ id: idStr, showModal: true });
    return false;
  };

  const onSort = (result: FieldArrayWithId<TFormValues, 'listData', 'id'>[]) => {
    // idのみの配列に変換
    const request = {
      positions: result.map((v) => v.id as unknown as number),
    };
    pathOfferPositions(request);
  };

  /**
   * DnD 並び替え
   */
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  /**
   * DnD 並び替え
   */
  const touchSensors = useSensors(
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  /**
   * DnD 並び替え
   */
  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (active && over && active.id !== over.id) {
      const oldIndex = fields.map((field) => field.id).indexOf(active.id as never);
      const newIndex = fields.map((field) => field.id).indexOf(over.id as never);
      move(oldIndex, newIndex);
      onSort(moveArray(fields, oldIndex, newIndex));
    }
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    if (offerPositions) methods.setValue('listData', offerPositions);
  }, [offerPositions]);

  useEffect(() => {
    // 一覧取得
    getOfferPositions();
  }, []);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <AdminPage header={<HeaderTitle title="ポジション管理" />}>
      <>
        <header className="mt-48 text-center">
          <h1 className="text-body_1">
            オファー時に一緒に通知するポジション概要を登録してください。
            <br />
            こちらを準備しておくことで候補者があなたのオファーに応諾しやすくなります。
          </h1>
        </header>
        <DirectUsersOfferPositionsDeleteComponent
          setPositionDeleteState={setPositionDeleteState}
          showModal={positionDeleteState.showModal}
          getOfferPositions={getOfferPositions}
          id={positionDeleteState.id}
        />
        <div className="flex flex-row-reverse py-16">
          <BaseButton
            iconName="Add"
            size="s"
            theme="primary"
            onClick={() => {
              history.push({
                pathname: `/direct/offer_positions_add`,
              });
            }}
          >
            新しいポジションを追加する
          </BaseButton>
        </div>
        {fields.length !== 0 && (
          <FormProvider {...methods}>
            <form className="pb-80">
              <TableContainer className="w-full">
                <TableHeader columns={columns}></TableHeader>
                <tbody>
                  <DndContext
                    sensors={mq.sm ? touchSensors : sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    autoScroll={false}
                  >
                    <SortableContext items={fields} strategy={verticalListSortingStrategy}>
                      {fields.map((data, index) => {
                        return (
                          <TableBodyOfferPositionList
                            key={index}
                            data={data}
                            onDelete={onDelete}
                          ></TableBodyOfferPositionList>
                        );
                      })}
                    </SortableContext>
                  </DndContext>
                </tbody>
              </TableContainer>
            </form>
          </FormProvider>
        )}
      </>
    </AdminPage>
  );
};
