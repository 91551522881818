import '../../styles/common/modal.scss';
import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { ButtonShadowIconButton } from '@/components/common/Button/ShadowIconButton';

type Props = {
  isOpen: boolean;
  isClose: () => void;
  children: React.ReactElement | React.ReactElement[];
  className?: string;
  contentClass?: string;
  iconClass?: string;
};

function Modal(props: Props): React.ReactElement {
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  const [centerMode, setCenterMode] = useState<boolean>(true);

  useEffect(() => {
    if (modalInnerRef.current) {
      if (props.isOpen) {
        document.body.style.overflow = 'hidden';
      }
    }
    if (!props.isOpen) {
      document.body.style.overflow = 'auto';
    }
    changeMode();
  }, [props.isOpen]);

  useEffect(() => {
    changeMode();
  }, [childRef.current?.clientHeight]);

  const changeMode = () => {
    if (childRef.current) {
      const childHeight = childRef.current.clientHeight;
      const windowHeight = window.innerHeight;
      if (childHeight > windowHeight) setCenterMode(false);
      else setCenterMode(true);
    }
  };

  return (
    <div className="modal">
      {props.isOpen ? (
        <div className={cn('modal-overlay', centerMode ? 'modal-overlay_center' : null)}>
          <div className="modal-inner" ref={modalInnerRef}>
            <div className={cn('content', props?.contentClass)}>
              <div className={cn('child', props.className)} ref={childRef}>
                {props.children}
              </div>
              <div className={cn('btn-area', props?.iconClass)}>
                <ButtonShadowIconButton
                  className={cn('close-icon', centerMode ? 'close-icon_center' : null)}
                  iconName={'Circle-large_Clear'}
                  onClick={props.isClose}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Modal;
