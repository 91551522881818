import React from 'react';

import { NaviInpageMenu } from '@/components/common/Navigation/Inpage/Menu';
import { usePagePortfolioProgress } from '@/hooks/usePagePortfolio';

export const PageMenuContent: React.VFC = () => {
  const progressData = usePagePortfolioProgress();
  if (!progressData) return <></>;
  const menu = [
    ['作品概要', 'summary'],
    ['案件への関わり方', 'relation'],
    ['制作のポイント', 'point'],
    ['画像・動画など', 'media'],
  ].map((value, index) => ({
    children: value[0],
    disabled: false,
    done: progressData.progress[index],
    href: value[1],
  }));

  const props: React.ComponentProps<typeof NaviInpageMenu> = {
    menu,
    progress: progressData.progress,
    type: 'large',
  };
  return <NaviInpageMenu {...props} />;
};
