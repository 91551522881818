import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Modal from '@/components/common/Modal';
import { BlockModalContainer } from '@/componentsAdmin/Company/DataBase/ModalContainer';
import { CompanyNewFormSection } from '@/componentsAdmin/Company/New/FormSection';
import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import { ADMINApi, AdminCompanyPostParam, BlockCompany, ResponseError } from '@/utils/api-client';

export const CompanyNew = (): React.ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [modal, setModal] = useState<boolean>(false);
  const [companies, setCompanies] = useState<BlockCompany[]>([]);

  const onSetCompanies = async (payload: BlockCompany[]) => {
    const copy = cloneDeep(companies);
    copy.push(...payload);
    setCompanies(copy);
    setModal(false);
  };

  const onSubmit = async (params: AdminCompanyPostParam) => {
    dispatch(toggleLoading(true));
    try {
      await new ADMINApi().postAdminCompany(params);
      history.push('/admin/companies/search');
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  return (
    <section>
      <AdminPage header={<HeaderTitle title="新しい企業を登録" />}>
        <section>
          <BaseButton
            theme="link"
            iconName="Arrow_Left"
            size="s"
            href="/admin/companies/search"
            className="mt-16"
          >
            企業検索へ戻る
          </BaseButton>
          <CompanyNewFormSection
            className="mt-46"
            setModal={() => setModal(true)}
            companies={companies}
            setCompanies={setCompanies}
            onSubmit={onSubmit}
          />
        </section>
      </AdminPage>
      <Modal isOpen={modal} isClose={() => setModal(false)} className="rounded-modalContainer">
        <BlockModalContainer onSubmit={onSetCompanies} blockCompanies={companies} isBlockList />
      </Modal>
    </section>
  );
};
