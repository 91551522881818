import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import * as yup from 'yup';

import { AdminPage } from '@/componentsDirect/Layout/Page';
import { BaseButton } from '@/componentsDirect/Parts/Button/';
import {
  FormContainerTextArea,
  FormContainerTextfield,
  FormLabel,
  FormLayoutFieldset,
} from '@/componentsDirect/Parts/Form/';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { PLACEHOLDER } from '@/definitionDirect/PLACEHOLDER';
import { YUP_VALIDATION } from '@/definitionDirect/VALIDATION';
import { useOfferPositions } from '@/hooksDirect/useOfferPositions';
import { CompanyGroupParam } from '@/utils/api-client/api';
import { yupResolver } from '@hookform/resolvers/yup';

/**
 * ポジション編集
 * 管理者のみ利用可能
 */
export const DirectOfferPositionsDetail = (): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const params: { id: string } = useParams();
  const { offerPositionId, getOfferPositionId, patchOfferPositionId } = useOfferPositions();

  const schema = yup.object().shape({
    name: YUP_VALIDATION.required,
    body: YUP_VALIDATION.required,
  });

  const methods = useForm<CompanyGroupParam>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = methods;

  // ====================================================================================================
  //
  // methods
  //
  // ====================================================================================================
  const onSubmit: SubmitHandler<CompanyGroupParam> = (data) => {
    patchOfferPositionId(params.id, data);
  };

  // データを取得した後にRHFに値をsetValueする
  const setDefaultValues = (res: CompanyGroupParam) => {
    (Object.keys(res) as (keyof CompanyGroupParam)[]).forEach((v) => {
      if (res?.[v]) {
        methods.setValue(v, res?.[v]);
      }
    });
  };
  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    // 一覧取得
    getOfferPositionId(params.id);
  }, []);

  useEffect(() => {
    if (offerPositionId) setDefaultValues(offerPositionId);
  }, [offerPositionId]);

  return (
    <AdminPage header={<HeaderTitle title="ポジション管理" />}>
      <>
        <div className="h-48 flex items-center mb-24">
          <BaseButton theme="link" iconName="Arrow_Left" size="s" href="/direct/offer_positions/">
            一覧へ戻る
          </BaseButton>
        </div>
        <section>
          <FormProvider {...methods}>
            <form className="pb-48 w-624 mx-auto" onSubmit={handleSubmit(onSubmit)}>
              <FormContainerTextfield name="active" className="hidden" />
              <div className="mbx-utils-stack-v--16">
                <FormLayoutFieldset>
                  <FormLabel label="ポジション名（職種名）" type="small" />
                  <FormContainerTextfield
                    name="name"
                    className="pb-6"
                    placeholder={PLACEHOLDER.offer_position_name}
                    maxLength={30}
                  />
                </FormLayoutFieldset>

                <FormLayoutFieldset>
                  <FormLabel label="ポジション詳細（業務内容詳細）" type="small" />
                  <FormContainerTextArea
                    name="body"
                    rows={8}
                    maxLength={1000}
                    placeholder={PLACEHOLDER.offer_position_description}
                    className="mkt_mask_items"
                  />
                </FormLayoutFieldset>
              </div>

              <div className="mt-48 space-x-16 flex justify-center">
                <div className="w-146">
                  <BaseButton
                    className="w-full"
                    size="m"
                    theme="secondary"
                    type="button"
                    href="/direct/offer_positions/"
                  >
                    キャンセル
                  </BaseButton>
                </div>
                <div className="w-146">
                  <BaseButton
                    className="w-full"
                    size="m"
                    theme="primary"
                    type="submit"
                    disabled={!methods.formState.isValid}
                  >
                    保存する
                  </BaseButton>
                </div>
              </div>
            </form>
          </FormProvider>
        </section>
      </>
    </AdminPage>
  );
};
