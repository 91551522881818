import React from 'react';
import cn from 'classnames';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';

type Props = {
  title: string;
  children: React.ReactElement | string;
};

function RequestTitle(props: Props): React.ReactElement {
  const mq = useMBXMediaQuery();
  return (
    <div className="text-center">
      <h2
        className={cn('mt-24', mq.sm ? 'mbx-typography--heading_3' : 'mbx-typography--heading_2')}
      >
        {props.title}
      </h2>
      <p className="mt-24 mbx-typography--body_1">{props.children}</p>
    </div>
  );
}

export default RequestTitle;
