import React, { ReactNode, VFC } from 'react';

type TProps = {
  className?: string;
  children: ReactNode;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=326%3A24409
 *
 * 日付フォームなど利用する単位文字や区切り文字用の薄いラッパーコンポーネント
 * 詳しい利用方法は storybook の `Form/Layout/BaseForm` を参照
 */
export const FormLayoutUnitDivider: VFC<TProps> = ({ className, children }) => {
  return (
    <div className={className}>
      <span className="text-14_36">{children}</span>
    </div>
  );
};
