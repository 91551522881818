import cn from 'classnames';
import React, { useEffect, useRef, useState, VFC } from 'react';
import { useFormContext } from 'react-hook-form';
import { delay } from 'underscore';

import { ButtonIconButton } from '@/componentsDirect/Parts/Button/IconButton';
import { FormDropdownSelectionsBase } from '@/componentsDirect/Parts/Form/Dropdown/Selections/Base';
import { TFormOption } from '@/definition/FORM_OPTIONS';
import { addClass, removeClass } from '@/utils/dom';

type TProps = {
  name: string; // RFHに登録するname
  placeholder?: string;
  className?: string;
  selectList: Array<TFormOption>;
  onInput: (value: string) => void;
  onChange?: (selectedItems: { value: number | string; children: number | string }[]) => void;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=1082%3A54885
 *
 * default の値はhook-formのuseFormで指定
 * https://react-hook-form.com/api/useform
 *
 * 挙動サンプル
 * https://materializecss.com/chips.html
 *
 * エンターキーで入力値をタグ化
 *
 */
export const FormContainerTextfieldMultipleWithDropDown: VFC<TProps> = ({
  placeholder,
  name,
  className = '',
  selectList,
  onInput,
  onChange,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const divSelectItemBoxRef = React.createRef<HTMLDivElement>(); // 擬似的に作る選択要素のDOM
  const methods = useFormContext();
  const register = methods.register(name);
  const { watch } = methods;
  const [showSelectList, setShow] = useState(false);
  const [selectListMouseOver, setSelectListMouseOver] = useState(false);
  const [tags, setTags] = useState(
    methods.getValues(name) ? (methods.getValues(name) as Array<string>) : []
  );
  const [value, setSelectValue] = useState('');

  // cssのfocusだとclearボタンをクリックしようとする直前にfocusが外れてボタンがクリックできないので
  // onFocusとonBlurでclassを付けて対応
  const onFocus: React.FocusEventHandler<HTMLInputElement> = () => {
    if (wrapperRef.current)
      addClass(
        wrapperRef.current as HTMLElement,
        'border-border-form-hover shadow-formContainerFocus'
      );
  };
  /**
   * フォーカスアウト時にタグ未生成のテキストがあれば強制タグ化
   */
  const onFocusOut: React.FocusEventHandler<HTMLInputElement> = (e) => {
    const target = e.target as HTMLInputElement;
    target.value = '';

    if (!selectListMouseOver) {
      setShow(false);
    }

    delay(() => {
      if (wrapperRef.current)
        removeClass(
          wrapperRef.current as HTMLElement,
          'border-border-form-hover shadow-formContainerFocus'
        );
    }, 100);
  };

  /**
   * タグを生成
   */
  const appendTag = (newTag: string) => {
    // 未入力なら何もしない
    if (!newTag) return;
    if (methods.getValues(name)) {
      methods.setValue(name, [...methods.getValues(name), newTag]);
    } else {
      methods.setValue(name, [newTag]);
    }
  };

  /**
   * タグを削除
   */
  const deleteTag = (index: number) => {
    const values = (methods.getValues(name) as Array<string>).filter((v, i) => {
      return i !== index;
    });

    methods.setValue(name, values);

    if (onChange) {
      onChange(getSelectedList());
    }
  };

  useEffect(() => {
    methods.setValue(name, []);
    setSelectValue('');
    setTags([]);

    setTimeout(() => {
      setShow(false);
    }, 100);
  }, []);

  useEffect(() => {
    if (selectList.length > 0) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [selectList]);

  useEffect(() => {
    // 内部データとRHFのデータに差があったら合わせる
    if (methods.getValues(name)) {
      if (Array.isArray(methods.getValues(name))) {
        setTags(methods.getValues(name));
      } else {
        setTags(methods.getValues(name).split(','));
      }
    } else {
      setTags([]);
    }
  }, [watch(name)]);

  const changeValue = (selectedValue: string | undefined) => {
    setSelectListMouseOver(false);
    const foundItem = selectList.find((item) => {
      return String(item.value) === selectedValue;
    });

    if (foundItem) {
      appendTag(String(foundItem.children));
    }

    if (onChange) {
      onChange(getSelectedList());
    }

    setSelectValue('');
    setShow(false);
  };

  const getSelectedList = () => {
    const selectedList = selectList.filter((item) => {
      const values = methods.getValues(name);
      const found = values.find((value: string | number) => {
        return value === item.children;
      });

      return found ? item : null;
    });

    return selectedList;
  };

  return (
    <div className={className}>
      <div className="relative">
        <div
          ref={wrapperRef}
          className="w-full text-14_21 border border-border-form rounded-formContainer transition-all min-h-36"
        >
          <input type="hidden" {...register} />
          <ul className="flex items-center flex-wrap">
            {/* タグ化した要素 */}
            {tags &&
              tags.length > 0 &&
              tags.map((tag, index) => (
                <li key={index} className={'pl-8 m-6 bg-blue-200 rounded-full'}>
                  <div className="flex items-center justify-between">
                    <p className="text-11_12">{tag}</p>
                    <ButtonIconButton
                      type="clear"
                      fontSize="icon_small"
                      iconName="Clear"
                      onClick={(e) => {
                        e.preventDefault();
                        deleteTag(index);
                      }}
                      hitArea="mini"
                      focus={false}
                    ></ButtonIconButton>
                  </div>
                </li>
              ))}
            <li className="flex-grow min-w-120 mx-6 ">
              {/* 入力フォーム */}
              <input
                type="text"
                className="h-36 outline-none placeholder-gray-500 w-full"
                placeholder={placeholder}
                onInput={(e) => {
                  onInput((e.target as HTMLInputElement).value);
                }}
                onBlur={(e) => {
                  onFocusOut(e);
                }}
                onFocus={onFocus}
              />
            </li>
          </ul>
        </div>
        {showSelectList === true && (
          <div
            id={name}
            className={cn('absolute w-full', { 'is-show': showSelectList })}
            onMouseOver={() => {
              setSelectListMouseOver(true);
            }}
            onMouseOut={() => {
              setSelectListMouseOver(false);
            }}
            ref={divSelectItemBoxRef}
          >
            <FormDropdownSelectionsBase
              className="text-left"
              selectList={selectList}
              itemClick={changeValue}
              value={value}
              parentRef={divSelectItemBoxRef}
            ></FormDropdownSelectionsBase>
          </div>
        )}
      </div>

      <select className="hidden" name={'name'}>
        {selectList.map((item) => {
          return (
            <option className="hidden" value={item.value} key={item.value}>
              {item.children}
            </option>
          );
        })}
      </select>
    </div>
  );
};
