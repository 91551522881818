import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { ButtonIconButton } from '@/componentsAdmin/Parts/Button/';
import { FormToggleButton } from '@/componentsAdmin/Parts/Form';
import { useUserTag } from '@/hooksAdmin/useUserTag';
import { UserTag } from '@/utils/api-client/api';
import { noDataText } from '@/utils/string';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type TProps = {
  data: UserTag;
  onDelete: (id: string) => boolean;
};

/**
 * タグリスト
 * @author s2 masa
 */
export const TableBodyUserTagList = ({ data, onDelete }: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [reactiveData, setReactiveData] = useState(data);

  // ====================================================================================================
  //
  // Hooks
  //
  // ====================================================================================================
  const history = useHistory();
  const { listeners, setNodeRef, transform, transition } = useSortable({ id: data.id });
  const { patchUserTagId } = useUserTag();

  // ====================================================================================================
  //
  // variables
  //
  // ====================================================================================================
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  // ====================================================================================================
  //
  // Events
  //
  // ====================================================================================================
  const onClick = () => {
    history.push({
      pathname: `/admin/user_tags/${data.id}`,
    });
  };

  /**
   * 選択表示の切り替え
   * */
  const onClickToggleButton = () => {
    const patchData = {
      name: reactiveData.name,
      path: reactiveData.path,
      active: !reactiveData.active,
    };
    patchUserTagId(data.id + '', patchData);
    setReactiveData({
      id: reactiveData.id,
      name: reactiveData.name,
      path: reactiveData.path,
      active: !reactiveData.active,
    });
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    setReactiveData(data);
  }, [data]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <tr className="text-left border-b border-gray-200" ref={setNodeRef} style={style}>
      {/* 名前 */}
      <td className="pl-16 ">
        <span className="text-blue-700 cursor-pointer" onClick={onClick}>
          {noDataText(data.name)}
        </span>
      </td>
      {/* 検索結果URL */}
      <td className="pl-16 ">
        <span className="text-blue-700 cursor-pointer" onClick={onClick}>
          {noDataText(data.path)}
        </span>
      </td>
      {/* 削除機能 */}
      <td className="text-right pr-16  flex">
        <FormToggleButton
          className="mbx-list_item__toggle"
          label="表示"
          checked={reactiveData.active}
          onClick={() => onClickToggleButton()}
        />
        <span className="dragHandle" {...listeners} data-cypress="draggable-handle">
          <ButtonIconButton type="gray" iconName="Sortable" />
        </span>
        <ButtonIconButton
          type="gray"
          iconName="Clear"
          onClick={(e) => {
            e.preventDefault();
            if (data.id) onDelete(data.id);
          }}
        />
      </td>
    </tr>
  );
};
