import '@/styles/common/CareerHistory/project.scss';

import React, { useMemo } from 'react';
import { Project } from '@/utils/api-client/api';
import { N2br } from '@/components/common/Utils/N2br';

export const ProjectContent: React.VFC<{
  className?: string;
  project: Project;
}> = ({ className, project }) => {
  const period = useMemo(() => {
    if (!project.start_date && !project.end_date) return null;
    const s = project.start_date ? project.start_date.split('-') : null;
    const start = s ? `${s[0]}年${s[1]}月` : '';
    const e = project.end_date ? project.end_date.split('-') : null;
    const end = e ? `${e[0]}年${e[1]}月` : '';

    // `${s[0]}年${s[1]}月〜${e}`
    return (
      <>
        {start}<br />
        〜<br />
        {end}
      </>
    );
  }, [project]);

  return (
    <table className={`${className} w-full mbx-career-history-project mb-12`}>
      <thead className="bg-gray-100 text-11_12 font-bold text-gray-800">
        <tr>
          <td className="w-80">期間</td>
          <td>業務内容</td>
          <td>役割・チーム体制</td>
          <td>使用言語・ツール</td>
        </tr>
      </thead>
      <tbody className="text-10_16">
        <tr>
          <td className="text-center mkt_mask_items">{period}</td>
          <td className="mkt_mask_items">
            {project.name}
            <br />
            【詳細】
            <br />
            <N2br>{project.detail}</N2br>
          </td>
          <td className="mkt_mask_items">
            <N2br>{project.structure}</N2br>
          </td>
          <td className="mkt_mask_items">
            <N2br>{project.tools}</N2br>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
