import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NotificationSnackBar } from '@/componentsDirect/Parts/Notification/SnackBar';
import { RootState } from '@/reduxAdmin';
import { notificationSuccess } from '@/reduxAdmin/modules/utils';

/**
 * global stateの値検知で表示する汎用通知
 *
 * 仕様 :
 * - メッセージが表示されてから4秒後に自動非表示
 */
export const GlobalNotificationSuccessPortal = (): React.ReactElement => {
  const isFirstRun = useRef(true);

  const [show, setShow] = useState(false);

  const dispatch = useDispatch();

  const successMessage: string | null = useSelector(
    (state: RootState) => state.utils.successMessage
  );

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (successMessage) {
      setShow(true);
    }
  }, [successMessage]);

  /**
   * メッセージが表示されてから4秒後に自動非表示
   */
  const onEnter = () => {
    _.delay(onClick, 4000);
  };

  const onExited = () => {
    dispatch(notificationSuccess(null));
  };

  const onClick = () => {
    setShow(false);
  };
  return (
    <div className="sticky bottom-0 z-300 mx-auto" style={{ maxWidth: '960px' }}>
      <NotificationSnackBar onClick={onClick} show={show} onEnter={onEnter} onExited={onExited}>
        {successMessage}
      </NotificationSnackBar>
    </div>
  );
};
