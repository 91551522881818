import '@/styles/common/Portfolio/ImagePage/image_responsive.scss';

import { AxiosResponse, AxiosError } from 'axios';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import BaseButton from '@/components/common/Button/BaseButton';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { CropperModal } from '@/components/common/Modal/Cropper';
import { ImageResponsiveItem } from '@/components/common/Portfolio/ImagePage/ImageResponsiveItem';
import { IMAGE_SIZE } from '@/definition/IMAGE_SIZE';
import { useFileLimit } from '@/hooks/useFIleLimit';
import { toggleLoading, notificationError } from '@/redux/index';
import { FileResponse, Image, PortfolioApi, ResponseError } from '@/utils/api-client/index';

type Props = {
  onBack: React.MouseEventHandler<HTMLDivElement>;
  name?: string;
  description?: string;
  editable?: boolean;
  onClick?(): void;
  onUploaded(images: (Image | null)[]): void;
};

export function ImageResponsive(props: Props): React.ReactElement {
  const [buffer, setBuffer] = useState<string | ArrayBuffer | null>(null);
  const [images, setImages] = useState<(Image | null)[]>([null, null, null]);
  const [size, setSize] = useState<{ width: number; height: number }>({ width: 0, height: 0 });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const methods = useFormContext();
  const { checkFileSize } = useFileLimit();
  const { push } = useContext(DataLayerContext);
  const onChange = (file: File, index: number) => {
    if (!checkFileSize(file.size, 5)) return;
    setIndex(index);
    setIsOpen(true);
    const reader = new FileReader();

    reader.onload = () => {
      setBuffer(reader.result);
    };

    // 画像の読み込み
    reader.readAsDataURL(file);
  };

  const postImage = async (data: File) => {
    try {
      dispatch(toggleLoading(true));
      const res: AxiosResponse<FileResponse> = await new PortfolioApi().postFiles(data);
      push({
        event: 'fileUpload',
        actionType: 'file_upload',
        actionName: '画像',
      });
      setIsOpen(false);
      const img: (Image | null)[] = [...images];
      img[index] = {
        f_id: res.data.f_id,
        f_url: res.data.f_url,
        f_thumbnail: res.data.f_thumbnail,
      };
      setImages(img);
      props.onUploaded(img);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const contentIndex = useMemo(() => {
    const index = props.name?.split('.')[1];
    return index ? parseInt(index) : undefined;
  }, [props]);

  useEffect(() => {
    const value = methods.getValues();
    if (contentIndex === undefined) return;
    const content = value.p_contents[contentIndex];

    if (content.m_type === 'all') {
      setImages(content.m_contents);
    }
  }, []);

  useEffect(() => {
    const subscription = methods.watch((value) => {
      if (contentIndex === undefined) return;
      const content = value.p_contents[contentIndex];
      if (content.m_type === 'all') {
        setImages(content.m_contents);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch, contentIndex]);

  useEffect(() => {
    if (!ref.current) return;
    setHeight(ref.current.clientWidth * (384 / 576));
  }, [ref]);

  return (
    <>
      <div className="bg-white mb-18 overflow-hidden" style={{ height }} ref={ref}>
        <section className="mbx-image_responsive">
          <div className="mbx-image_responsive_inner">
            <ImageResponsiveItem
              onChange={onChange}
              setSize={setSize}
              image={images[2]}
              editable={props.editable}
              type="pc"
            />
            <ImageResponsiveItem
              onChange={onChange}
              setSize={setSize}
              image={images[1]}
              editable={props.editable}
              type="tablet"
            />
            <ImageResponsiveItem
              onChange={onChange}
              setSize={setSize}
              image={images[0]}
              editable={props.editable}
              type="sp"
            />
          </div>
          {props.onClick && <div className="mbx-image_responsive_cover" onClick={props.onClick} />}
        </section>
      </div>
      {props.editable !== undefined && props.editable && (
        <div className="flex justify-between mb-22">
          <p className="text-caption_2 text-gray-700 lg:flex-1 md:flex-1 sm:w-1/2">
            モバイル：横{IMAGE_SIZE.responsive_sp.width}px × 縦{IMAGE_SIZE.responsive_sp.height}px推奨、最大5MB<br />
            タブレット：横{IMAGE_SIZE.responsive_tablet.width}px × 縦{IMAGE_SIZE.responsive_tablet.height}px推奨、最大5MB<br />
            PC：縦{IMAGE_SIZE.responsive_pc.width}px × 横{IMAGE_SIZE.responsive_pc.height}px推奨、最大5MB<br />
          </p>
          <BaseButton size="s" theme="link" iconName="Change" onClick={props.onBack}>
            他の見せ方に変更
          </BaseButton>
        </div>
      )}

      <CropperModal
        isOpen={isOpen}
        width={size.width}
        height={size.height}
        src={buffer}
        postImage={postImage}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
}
