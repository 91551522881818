import React, { VFC } from 'react';
import { PreviewResumeText } from '@/components/common/Preview/ResumeText';

type TProps = {
  className?: string;
  type?: 'empty';
  date: string;
  description: string;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=92%3A688  
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=167%3A11046  
 *
 * HistoryEmpty の場合は type に 'empty' を渡す
 */
export const PreviewResumeHistory: VFC<TProps> = ({ className, type, date, description }) => {
  return (
    <div className={className}>
      {type === 'empty' ? (
        <div className="h-45">
          <hr className="mbx-keyline-horizontal--thin"></hr>
        </div>
      ) : (
        <div className="flex">
          <PreviewResumeText className="w-92 flex-shrink-0">{date}</PreviewResumeText>
          <PreviewResumeText className="w-full">{description}</PreviewResumeText>
        </div>
      )}
    </div>
  );
};
