import React, { useMemo, useState } from 'react';

import { ModalDefault } from '@/components/common/Modal/Default';
import { ModalTabNavigation } from '@/components/common/Navigation/ModalTabNavigation';
import { Offer } from '@/utils/api-client';
import { convertLineBreak, linkifyUrls } from '@/utils/string';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type TProps = {
  offer?: Offer;
};

/**
 * 企業からのメッセージをポップアップで表示
 */
export const AdminOffersModalMessageFromCompanyComponent = ({
  offer,
}: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [showModal, setShowModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);

  // ====================================================================================================
  //
  // EVENT
  //
  // ====================================================================================================
  const onOpen: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.preventDefault();
    setActiveIndex(1);
    setShowModal(true);
  };

  const onClose: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.preventDefault();
    setShowModal(false);
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const statusText = useMemo(() => {
    switch (offer?.status) {
      case 0:
        return {
          user: '回答期限終了',
          direct: '回答期限終了',
        };
      case 2:
        return {
          user: '',
          direct: 'マイナビワークスに相談中',
        };
      case 3:
        return {
          user: '',
          direct: '一次面接オファー中',
        };
      case 4:
        return {
          user: '',
          direct: '見送り',
        };
      case 5:
        return {
          user: '見送り',
          direct: '一次面接オファー見送り',
        };
      case 6:
        return {
          user: 'オファー応諾',
          direct: '一次面接オファー中',
        };
      case 7:
        return {
          user: 'マイナビワークスに相談中',
          direct: '一次面接オファー中',
        };
      case 8:
        return {
          user: '',
          direct: 'カジュアル面談オファー中',
        };
      case 9:
        return {
          user: 'オファー応諾',
          direct: 'カジュアル面談オファー中',
        };
      case 10:
        return {
          user: '見送り',
          direct: 'カジュアル面談オファー見送り',
        };
      case 11:
        return {
          user: 'マイナビワークスに相談中',
          direct: 'カジュアル面談オファー中',
        };
    }
  }, [offer?.status]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <>
      <i
        className="mbx-icon mbx-icon-Message text-icon text-blue-700 cursor-pointer"
        onClick={onOpen}
      ></i>
      <ModalDefault
        isOpen={showModal}
        onClose={onClose}
        className="w-2/3vw rounded-modalContainer"
      >
        <section className="p-80">
          <ModalTabNavigation
            menu={[
              { title: '企業のアクション履歴', index: 1 },
              { title: '候補者のリアクション履歴', index: 2 },
            ]}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            align='fit'
            className='mb-48'
          />
          {activeIndex === 1 && (
            <table className="w-full">
              <tbody className="text-left border-b border-gray-300">
                <tr className="border-t border-gray-300">
                  <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100 w-170">
                    アクション
                  </th>
                  <td className="text-body_2 p-24 font-normal">{statusText?.direct}</td>
                </tr>
                <tr className="border-t border-gray-300">
                  <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100">
                    ポジション名
                  </th>
                  <td className="text-body_2 p-24 font-normal">{offer?.position?.name}</td>
                </tr>
                <tr className="border-t border-gray-300">
                  <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100">
                    ポジション詳細
                  </th>
                  <td
                    className="text-body_2 p-24 font-normal"
                    dangerouslySetInnerHTML={{
                      __html: convertLineBreak(linkifyUrls(offer?.position?.body)),
                    }}
                  ></td>
                </tr>
                <tr className="border-t border-gray-300">
                  <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100">
                    マイナビワークスへのメッセージ
                  </th>
                  <td
                    className="text-body_2 p-24 font-normal"
                    dangerouslySetInnerHTML={{ __html: convertLineBreak(linkifyUrls(offer?.comment)) }}
                  ></td>
                </tr>
              </tbody>
            </table>
          )}
          {activeIndex === 2 && (
            <>
              {(offer?.status === 2 || offer?.status === 3 || offer?.status === 4 || offer?.status === 8) ? (
                <div className="text-center	">
                  <div className="w-148 sm:w-114 m-auto">
                    <img src="/images/blank_matchbou.png" alt="" />
                  </div>
                  <p className="mbx-typography--heading_4 mt-24">まだ候補者がリアクションしていません</p>
                  <p className="text-body_1 mt-24">この企業のオファーに対して候補者がリアクションするとその内容が表示されます。</p>
                </div>
              ) : (
                <table className="w-full">
                  <tbody className="text-left border-b border-gray-300">
                    <tr className="border-t border-gray-300">
                      <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100 w-170 sm:w-114">
                        リアクション
                      </th>
                      <td className="text-body_2 p-24 font-normal">
                        {statusText?.user}
                      </td>
                    </tr>
                    <tr className="border-t border-gray-300">
                      <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100 w-170 sm:w-114">
                        マイナビワークスへのメッセージ
                      </th>
                      <td
                        className="text-body_2 p-24 font-normal"
                        dangerouslySetInnerHTML={{ __html: convertLineBreak(linkifyUrls(offer?.user_comment)) }}
                      ></td>
                    </tr>
                  </tbody>
                </table>
              )}
            </>
          )}
        </section>
      </ModalDefault>
    </>
  );
};
