export type State = {
  portfolioStatusOfPDF: number | null; // PDFダウンロードのSnackBarの表示制御
  portfolioTicketID: number | undefined;
  portfolioPDFDownloadModal: boolean; // PDFダウンロードのSnackBarの表示制御
  matchboxId: string;
};

export const initialState: State = {
  portfolioStatusOfPDF: null,
  portfolioTicketID: undefined,
  portfolioPDFDownloadModal: false,
  matchboxId: '',
};
