import { createSlice } from '@reduxjs/toolkit';

import { reducers } from './reducer';
import { initialState } from './state';

/**
 * ポートフォリオ関連
 */
export const portfolioModule = createSlice({
  name: 'portfolio',
  initialState,
  reducers,
});

export const {
  setPortfolioStatusOfPDF,
  setPortfolioTicketID,
  setPortfolioPDFDownloadModal,
  setPortfolioMatchboxId,
} = portfolioModule.actions;
