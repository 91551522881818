import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import cn from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { ButtonShadowIconButton } from '@/componentsDirect/Parts/Button/ShadowIconButton';
import { DataLayerType } from '@/hooksDirect/common/useDataLayer';

import type { BodyScrollOptions } from 'body-scroll-lock';
type Props = {
  isOpen: boolean;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactElement | React.ReactElement[];
  className?: string;
  onEnter?: () => void;
  onExited?: () => void;
  dataLayer?: DataLayerType;
};

export const ModalDefault = ({
  isOpen,
  onClose,
  children,
  className,
  onEnter,
  onExited,
  dataLayer,
}: Props): React.ReactElement => {
  // !see https://github.com/reactjs/react-transition-group/issues/668
  const modalRef = useRef(null);
  const overlayRef = useRef(null);
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);

  const [isCenter, setIsCenter] = useState<boolean>(true);

  const { push } = useContext(DataLayerContext);

  useEffect(() => {
    if (!modalInnerRef.current) return;
    if (isOpen) {
      const options: BodyScrollOptions = {
        reserveScrollBarGap: true,
      };
      disableBodyScroll(modalInnerRef.current, options);
      if (dataLayer) push(dataLayer);
    } else {
      clearAllBodyScrollLocks();
    }
    changeMode();
  }, [isOpen]);

  useEffect(() => {
    changeMode();
  }, [modalContentRef.current?.clientHeight]);

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  /**
   * モーダルの高さよりブラウザの高さの方が低い場合はレイアウトを調整
   */
  const changeMode = () => {
    const resizeObserver = new ResizeObserver(() => {
      if (modalContentRef.current) {
        const childHeight = modalContentRef.current.clientHeight;
        const windowHeight = window.innerHeight;
        if (childHeight > windowHeight) setIsCenter(false);
        else setIsCenter(true);
      }
    });
    if (modalContentRef.current) {
      resizeObserver.observe(modalContentRef.current);
    }
  };

  return (
    <>
      <CSSTransition
        in={isOpen}
        timeout={550}
        nodeRef={modalRef}
        unmountOnExit
        classNames="mbx-anime-modal"
      >
        <div
          ref={modalRef}
          className={cn('mbx-modal-default--wrapper', {
            'mbx-modal-default--center': isCenter,
          })}
        >
          <div className="mbx-modal-default--inner" ref={modalInnerRef}>
            <div className={cn('mbx-modal-default--content', className)} ref={modalContentRef}>
              {children}
            </div>
            <div className="mbx-modal-default--close">
              <ButtonShadowIconButton
                className="mbx-modal-default--closeIcon"
                iconName={'Circle-large_Clear'}
                onClick={onClose}
              />
            </div>
          </div>
        </div>
      </CSSTransition>
      <CSSTransition
        in={isOpen}
        timeout={550}
        nodeRef={overlayRef}
        onEnter={onEnter}
        onExited={onExited}
        unmountOnExit
        classNames="mbx-anime-modal--overlay"
      >
        <div
          className="flex items-center justify-center z-30 w-full h-full bg-black bg-opacity-75 fixed left-0 top-0"
          ref={overlayRef}
        ></div>
      </CSSTransition>
    </>
  );
};
