import React, { useEffect, useMemo, useState } from 'react';

import FireActiveIcon from '@/assets/images/common/fire_active.png';
import FireInactiveIcon from '@/assets/images/common/fire_inactive.png';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { optionValueToChildren } from '@/utils/optionValueToChildren';

type Props = {
  changeDate: number; // API生成側がユニオンで描き出せないでのnumber型に内容的には 1 | 2 | 3 | 4 | 5 | 6
};

export const Motivation = (props: Props): React.ReactElement => {
  type MotivationIcon = {
    isActive: boolean;
  };
  const [motivationIcons, setMotivationIcons] = useState<Array<MotivationIcon>>();

  useEffect(() => {
    const activeNum = 6 - score;
    const arr = [];

    for (let i = 0; i < 5; i++) {
      arr.push({
        isActive: i + 1 < activeNum + 1,
      });
    }
    setMotivationIcons(arr);
  }, [props.changeDate]);

  const score = useMemo(() => {
    return props.changeDate;
  }, [props.changeDate]);

  const motivation = (value: number) => {
    const children = optionValueToChildren(value, FORM_OPTIONS.change_date);
    const after = '転職希望';

    if (value === 1) {
      return `${children}${after}`;
    } else if (value >= 2 && value <= 5) {
      return `${children}に${after}`;
    }

    return children;
  };

  return (
    <section className="inline-block p-4 bg-red-100">
      <div className="flex items-center">
        <ul className="flex">
          {motivationIcons &&
            motivationIcons.map((item: MotivationIcon, index: number) => {
              return (
                <li key={index} className="w-11">
                  <img src={item.isActive ? FireActiveIcon : FireInactiveIcon} alt="" />
                </li>
              );
            })}
        </ul>
        <p className="ml-3 text-10_16 font-bold">
          {motivation(Number(props.changeDate))}
        </p>
      </div>
    </section>
  );
};
