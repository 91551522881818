import cn from 'classnames';
import React from 'react';

import { iconNameList } from '@/assets/fonts/manifest';

type TProps = {
  fontSize?: string;
  iconName: typeof iconNameList[number];
};

/**
 * アイコンベース
 */
export const IconBase = ({ fontSize, iconName }: TProps): React.ReactElement => {
  const IconProps = {
    className: cn({ [`text-${fontSize}`]: fontSize }, `mbx-icon mbx-icon-${iconName}`),
  };
  return <i {...IconProps}></i>;
};
