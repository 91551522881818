import '@/styles/common/Admin/FavoriteConditions/list_item.scss';

import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import MatchboxIcon from '@/assets/images/common/icon_matchbox.svg';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ButtonIconButton } from '@/componentsDirect/Parts/Button/IconButton';
import { FormToggleButton } from '@/componentsDirect/Parts/Form/ToggleButton';
import {
    AdminSearchConditionModel, AdminSearchConditionResponse, DirectSearchConditionResponse
} from '@/utils/api-client';
import { searchConditionsToText } from '@/utils/search';

type TProps = {
  direct?: boolean;
  recommended?: boolean; // adminの場合はおすすめ向け
  index: number;
  item: AdminSearchConditionResponse | DirectSearchConditionResponse;
  onClickRemoveButton: React.MouseEventHandler<HTMLButtonElement>;
  onClickSearchButton: React.MouseEventHandler<HTMLDivElement>;
  onClickEditButton: React.MouseEventHandler<HTMLDivElement>;
  onChangeDirectMail: () => void;
};

export function ListItem({
  direct,
  recommended = false,
  index,
  item,
  onClickRemoveButton,
  onClickSearchButton,
  onClickEditButton,
  onChangeDirectMail,
}: TProps): React.ReactElement {
  const methods = useFormContext();
  const [checked, setChecked] = useState(false);
  const directMailName = `conditions.${index}.direct_mail`;

  useEffect(() => {
    setChecked(methods.getValues(directMailName));
  }, [directMailName]);

  const searchText = useMemo(() => {
    if (direct) {
      const condition = cloneDeep(item);
      if (condition?.offer_status?.includes(6 || 7)) {
        const status = condition.offer_status.filter((s) => {
          if (s !== 6 && s !== 7) {
            return true;
          }
        });
        condition.offer_status = status;
      }
      if (condition?.offer_status?.includes(5)) {
        const status = condition.offer_status.filter((s) => {
          if (s !== 5) {
            return true;
          }
        });
        condition.offer_status = status;
      }
      return searchConditionsToText(condition as AdminSearchConditionModel);
    }
    return searchConditionsToText(item as AdminSearchConditionModel);
  }, []);

  const onClickToggleButton = () => {
    const newValue = !checked;
    setChecked(newValue);
    methods.setValue(directMailName, newValue);
    onChangeDirectMail();
  };

  return (
    <section className="mbx-list_item">
      <div className="mbx-list_item__header">
        {recommended && <img className="mbx-list_item__icon" src={MatchboxIcon} alt="" />}
        <div className="mbx-list_item__title mkt_mask_items">{item.search_condition_name}</div>
      </div>
      <div className="mbx-list_item__text">{searchText}</div>
      <div className="mbx-list_item__buttons">
        <BaseButton
          size="s"
          theme="secondary"
          className="mbx-list_item__button"
          onClick={onClickSearchButton}
        >
          この条件で検索
        </BaseButton>
        {!recommended && (
          <BaseButton
            size="s"
            theme="secondary"
            className="mbx-list_item__button"
            onClick={onClickEditButton}
          >
            条件を編集
          </BaseButton>
        )}
      </div>
      <FormToggleButton
        className="mbx-list_item__toggle"
        label="メール配信"
        checked={checked}
        onClick={() => onClickToggleButton()}
      />
      <ButtonIconButton
        type="gray"
        iconName="Sortable"
        className="mbx-list_item__drag-handle dragHandle"
      />
      {!recommended ? (
        <ButtonIconButton
          type="gray"
          iconName="Clear"
          className="mbx-list_item__remove-button"
          onClick={onClickRemoveButton}
        />
      ) : (
        <ButtonIconButton
          type="gray"
          iconName="Clear"
          hidden={true}
          className="mbx-list_item__remove-button"
        />
      )}
    </section>
  );
}
