import React, { VFC } from 'react';

type TProps = {
  en: string;
  children: string;
};

/**
 * 
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=383%3A35945
 *
 */
export const MypageAssetsTitle: VFC<TProps> = ({ children, en }) => {
  return (
    <div className={"text-center"}>
      <h1 className="mbx-typography--heading_4">{children}</h1>
      <p className="text-red-700 font-number text-12_20 font-bold">{en}</p>
    </div>
  );
};
