import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import * as yup from 'yup';

import ImgIcon from '@/assets/images/profile/circle-placeholder.svg';
import { AdminPage } from '@/componentsDirect/Layout/Page';
import { BaseButton, ButtonTextLink } from '@/componentsDirect/Parts/Button/';
import {
  FormContainerTextfield,
  FormLabel,
  FormLayoutFieldset,
} from '@/componentsDirect/Parts/Form/';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { YUP_VALIDATION } from '@/definitionDirect/VALIDATION';
import { useCompanyUserGroups } from '@/hooksDirect/useCompanyUserGroups';
import { CompanyGroupParam } from '@/utils/api-client/api';
import { noDataText } from '@/utils/string';
import { yupResolver } from '@hookform/resolvers/yup';

/**
 * 社内ユーザーグループ管理 詳細 /direct/user_groups/:id
 * 管理者のみ利用可能
 */
export const DirectCompanyUserGroupsDetail = (): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const params: { id: string } = useParams();
  const { companyUserGroupId, getCompanyUserGroupId, patchCompanyUserGroupId } =
    useCompanyUserGroups();

  const schema = yup.object().shape({
    name: YUP_VALIDATION.required,
  });

  const methods = useForm<CompanyGroupParam>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { handleSubmit } = methods;

  // ====================================================================================================
  //
  // methods
  //
  // ====================================================================================================
  const onSubmit: SubmitHandler<CompanyGroupParam> = (data) => {
    patchCompanyUserGroupId(params.id, data);
  };

  // データを取得した後にRHFに値をsetValueする
  const setDefaultValues = (res: CompanyGroupParam) => {
    (Object.keys(res) as (keyof CompanyGroupParam)[]).forEach((v) => {
      if (res?.[v]) {
        methods.setValue(v, res?.[v]);
      }
    });
  };
  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    // 一覧取得
    getCompanyUserGroupId(params.id);
  }, []);

  useEffect(() => {
    if (companyUserGroupId) setDefaultValues(companyUserGroupId);
  }, [companyUserGroupId]);

  return (
    <AdminPage header={<HeaderTitle title="グループ管理" />}>
      <>
        <div className="h-48 flex items-center mb-24">
          <BaseButton theme="link" iconName="Arrow_Left" size="s" href="/direct/user_groups/">
            戻る
          </BaseButton>
        </div>
        <section>
          <FormProvider {...methods}>
            <form className="pb-48 w-624 mx-auto" onSubmit={handleSubmit(onSubmit)}>
              <div className="flex items-start">
                <FormLayoutFieldset className="flex-grow mr-16">
                  <FormLabel label="グループ名 " type="small" />
                  <FormContainerTextfield name="name" className="pb-6" />
                </FormLayoutFieldset>

                <div className="w-104 pt-16">
                  <BaseButton className="w-full" size="s" theme="primary" type="submit">
                    保存
                  </BaseButton>
                </div>
              </div>
            </form>
          </FormProvider>
        </section>
        <section className="w-624 mx-auto border-2 border-gray-600 rounded-4 bg-gray-50 p-40">
          <h2 className="text-subhead_1 border-b-2 border-gray-800 pb-16 font-bold">
            所属しているユーザー
          </h2>
          <p className="text-caption_1 py-24">
            <ButtonTextLink href="/direct/users">社内ユーザー管理</ButtonTextLink>から編集できます。
          </p>
          <div className="grid gap-y-8">
            {companyUserGroupId?.users?.map((user, index) => (
              <div key={index} className="flex items-center gap-x-8 grid-cols-1">
                <div className="w-24 h-24">
                  <img
                    src={user.photo ? user.photo : ImgIcon}
                    alt={user.name}
                    className="rounded-full block"
                  />
                </div>
                <div>
                  <p className="text-subhead_2">{noDataText(user.name)}</p>
                  <p className="text-caption_2 text-gray-600">{user.email}</p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </>
    </AdminPage>
  );
};
