import dayjs from 'dayjs';
import React, { useState } from 'react';
import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';

import * as CH from '@/components/CareerHistory/';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { TCareerFormValues } from '@/types/Pages/CareerHistory';
import { Career } from '@/utils/api-client';
import { moveArray } from '@/utils/utils';
import {
    closestCenter, DndContext, KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors
} from '@dnd-kit/core';
import {
    SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy
} from '@dnd-kit/sortable';

export const Careers: React.VFC<{
  onAppend(career: Career): void;
  onSort(result: FieldArrayWithId<TCareerFormValues, 'careers', 'uid'>[]): void;
  patchCareer(param: Career): Promise<Career | undefined>;
  patchCareerImmediately(param: Career): Promise<Career | undefined>;
  removeCareer(id: string): void;
  onBlur?(
    name: string,
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
  ): void;
  onFocus?(e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>): void;
}> = ({
  onAppend: postCareer,
  onSort,
  patchCareer,
  patchCareerImmediately,
  removeCareer,
  onBlur,
  onFocus,
}) => {
  const mq = useMBXMediaQuery();
  const [opens, setOpens] = useState<boolean[]>([]);
  const { control } = useFormContext<TCareerFormValues>();

  const { fields, move, update } = useFieldArray<TCareerFormValues, 'careers'>({
    control,
    name: 'careers',
  });

  /**
   * 表示・非表示の切り替え
   */
  const toggleVisible = (index: number) => {
    const patchField = { ...fields[index], c_visible: !fields[index].c_visible };
    update(index, patchField);
    patchCareer(patchField);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const touchSensors = useSensors(
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragEnd = ({ active, over }: any) => {
    const opens: boolean[] = fields.map(() => false);
    setOpens(opens);

    if (active.id !== over.id) {
      const oldIndex = fields.map((field) => field.id).indexOf(active.id);
      const newIndex = fields.map((field) => field.id).indexOf(over.id);
      move(oldIndex, newIndex);
      onSort(moveArray(fields, oldIndex, newIndex));
    }
  };

  const onAppend = () => {
    const career: Career = {
      c_id: '',
      c_company_name: '',
      c_join_date: dayjs().format('YYYY-M'),
      c_detail: '',
      c_section: '',
      c_employment_status: 0,
      c_leave_reason: '',
      c_post: '',
      c_employee_count: 0,
      c_capital: 0,
      c_leave_flag: false,
      c_visible: true,
      c_projects: [
        {
          tools: '',
          structure: '',
          start_date: null,
          end_date: null,
          detail: '',
          name: '',
        },
      ],
    };
    postCareer(career);

    const opens: boolean[] = fields.map(() => false);
    opens.push(true);
    setOpens(opens);
  };

  const onRemove = (index: number, id: string) => {
    removeCareer(id);
  };
  return (
    <>
      <TitleSectionTitle type="large" className="mt-12">
        職歴
      </TitleSectionTitle>

      <div className="space-y-8 my-22">
        <DndContext
          sensors={mq.sm ? touchSensors : sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          autoScroll={false}
        >
          <SortableContext items={fields} strategy={verticalListSortingStrategy}>
            <div className="mb-8 mbx-utils-stack-v--16">
              {fields.map((item, index) => (
                <div key={item.id} className="mbx-formUnit-education-box">
                  {mq.sm ? (
                    <CH.Editable
                      index={index}
                      item={item}
                      patchCareer={patchCareer}
                      patchCareerImmediately={patchCareerImmediately}
                      invisible={!item.c_visible}
                      onVisible={() => {toggleVisible(index);}}
                      onRemove={() => onRemove(index, item.c_id)}
                      onBlur={onBlur}
                      onFocus={onFocus}
                      open={opens[index]}
                    />
                  ) : (
                    <CH.DraggableCareer
                      invisible={!item.c_visible}
                      onVisible={() => {toggleVisible(index);}}
                      onRemove={() => onRemove(index, item.c_id)} id={item.id}
                    >
                      <CH.Career
                        index={index}
                        item={item}
                        patchCareer={patchCareer}
                        patchCareerImmediately={patchCareerImmediately}
                        foldable
                        onBlur={onBlur}
                        onFocus={onFocus}
                      />
                    </CH.DraggableCareer>
                  )}
                </div>
              ))}
            </div>
          </SortableContext>
        </DndContext>

        <CH.DraggableSection className="" title="職歴" sortable={false}>
          <CH.AddButton onClick={onAppend} />
        </CH.DraggableSection>
      </div>
    </>
  );
};
