import React, { VFC } from 'react';

type TProps = {
  current: number;
  total: number;
  className?: string;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=1%3A92
 */
export const FormCounter: VFC<TProps> = ({ current = 0, total, className }) => {
  return (
    <div className={className}>
      <p className="text-10_16 ml-24">
        {current}/{total}
      </p>
    </div>
  );
};
