import '@/stylesAdmin/Conditions/send_shared_companies_modal.scss';

import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import {
    FormContainerTextfieldMultipleWithDropDown
} from '@/componentsDirect/Parts/Form/Container/TextfieldMultipleWithDropDown';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { TFormOption } from '@/definition/FORM_OPTIONS';
import { DirectCompanyModel } from '@/utils/api-client';

type Props = {
  checkedConditionTitles: string[];
  searchedCompanies: Array<TFormOption>;
  isOpen: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  onClickSendButton: (
    selectedCompanies: { value: number | string; children: number | string }[]
  ) => void;
  onInputKeyword: (value: string) => void;
};

export function SendSharedCompaniesModal(props: Props): React.ReactElement {
  const [sendEnabled, setSendEnabled] = useState<boolean>(false);
  const [selectedCompanies, setSelectedCompanies] = useState<
    { value: number | string; children: number | string }[]
  >([]);

  type IFormInputs = {
    companies: DirectCompanyModel[];
  };

  const methods = useForm<IFormInputs>({
    defaultValues: {
      companies: [],
    },
  });

  useEffect(() => {
    if (props.isOpen) {
      setSelectedCompanies([]);
      setSendEnabled(false);
    }
  }, [props.isOpen]);

  const onClickSendButton = () => {
    props.onClickSendButton(selectedCompanies);
  };

  const onChangeSelectedCompanies = (
    selectedItems: { value: number | string; children: number | string }[]
  ) => {
    setSelectedCompanies(selectedItems);
    setSendEnabled(selectedItems.length > 0);
  };

  return (
    <ModalDefault
      isOpen={props.isOpen}
      onClose={() => props.onClose(false)}
      className="mbx-send_shared_companies_modal"
    >
      <div className="mbx-send_shared_companies_modal__inner">
        <section className="conditions">
          <div className="conditions__inner">
            <p className="mbx-typography--subhead_1">送信する検索条件</p>
            <ul className="conditions__list">
              {props.checkedConditionTitles.map((title: string, index) => (
                <li className="conditions__list-item" key={index}>
                  {title}
                </li>
              ))}
            </ul>
          </div>
        </section>

        <div className="mbx-send_shared_companies_modal__title">送信する企業名</div>
        <FormProvider {...methods}>
          <FormContainerTextfieldMultipleWithDropDown
            className="mbx-send_shared_companies_modal__input"
            name="companies"
            placeholder="送信したい会社名の一部を入力してください"
            selectList={props.searchedCompanies}
            onInput={props.onInputKeyword}
            onChange={onChangeSelectedCompanies}
          />
        </FormProvider>
        <div className="mbx-send_shared_companies_modal__ui">
          <BaseButton
            size="m"
            theme="primary"
            disabled={!sendEnabled}
            className="mbx-send_shared_companies_modal__button"
            onClick={() => onClickSendButton()}
          >
            この内容で送信
          </BaseButton>
        </div>
      </div>
    </ModalDefault>
  );
}
