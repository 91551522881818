type careerInterviewField = {
  id: string;
  num: string;
  job: string;
  name: string;
  text: string;
  img: string;
};

type recruitVoiceField = {
  img: string;
  name: string;
  text: string;
  src: string;
};

type everyonePortfolioField = {
  img: string;
  title: string;
  userName: string;
  userIcon: string;
  job: string;
};

type questions = {
  question: string;
  answer: string;
};

type TItems = {
  mainBnr: { img: string; text: string; backgroundColor: string; spImage: string }[];
  careerInterview: careerInterviewField[];
  companyLogo: string[];
  recruitVoice: recruitVoiceField[];
  everyonePortfolio?: everyonePortfolioField[];
  questions: questions[];
};

export const items: TItems = {
  mainBnr: [
    {
      img: 'images/Top/KV/main-slider01.jpg',
      text: '採用のプロがつくった、<br />ポートフォリオサービス。',
      backgroundColor: '#d6e3ea',
      spImage: 'images/Top/KV/main-slider01_SP.jpg',
    },
    {
      img: 'images/Top/KV/main-slider02.jpg',
      text: 'PDFも同時にできあがるので、<br />印刷も安心です。',
      backgroundColor: '',
      spImage: 'images/Top/KV/main-slider02_SP.jpg',
    },
    {
      img: 'images/Top/KV/main-slider03.jpg',
      text: '企業からの<br />面接オファーが届くことも。',
      backgroundColor: '#e2eaee',
      spImage: 'images/Top/KV/main-slider03_SP.jpg',
    },
    {
      img: 'images/Top/KV/main-slider04.jpg',
      text: '履歴書や職務経歴書も、<br />かんたんに作成できます。',
      backgroundColor: '#dae1e4',
      spImage: 'images/Top/KV/main-slider04_SP.jpg',
    },
    {
      img: 'images/Top/KV/main-slider05.jpg',
      text: '一度の入力で、<br />多彩なデバイスにすぐ対応。',
      backgroundColor: '',
      spImage: 'images/Top/KV/main-slider05_SP.jpg',
    },
  ],
  careerInterview: [
    {
      id: '0001',
      num: '01',
      job: 'Webデザイナー',
      name: 'M.H.さん',
      text: 'ポートフォリオで語る、自分の役割と仕事のプロセス',
      img: '../images/Top/interview01.png',
    },
    {
      id: '0002',
      num: '02',
      job: '3DCGデザイナー',
      name: 'L.H.さん',
      text: 'ポートフォリオづくりで大切にした「自分らしさ」は、見る人にも必ず届く',
      img: '../images/Top/interview02.png',
    },
    {
      id: '0003',
      num: '03',
      job: '編集',
      name: 'M.K.さん',
      text: '一貫したスキルがないと思っていた自分に、ポートフォリオ作成が「自分の強み」を教えてくれた',
      img: '../images/Top/interview03.png',
    },
  ],
  companyLogo: [
    'images/Top/companyLogo/01.png',
    'images/Top/companyLogo/02.png',
    'images/Top/companyLogo/03.png',
    'images/Top/companyLogo/04.png',
    'images/Top/companyLogo/05.png',
  ],
  recruitVoice: [
    {
      img: 'images/Top/Recruit/recruit-01.png',
      name: '株式会社コンセント 様',
      text: 'スキルや技術だけでなく「考えるクリエイター」としてのあなた自身を表現できるポートフォリオを',
      src: 'https://mynavi-creator.jp/blog/article/matchbox-review-0007',
    },
    {
      img: 'images/Top/Recruit/recruit-02.png',
      name: 'Sansan株式会社 様',
      text: '名刺管理からはじまる出会いをイノベーション。深く、そして幅広く自社サービスと向き合うクリエイターの働き方',
      src: 'https://mynavi-creator.jp/blog/article/matchbox-review-0008',
    },
    {
      img: 'images/Top/Recruit/recruit-03.png',
      name: '株式会社ライデン 様',
      text: '会社は素材が集まる鍋。ハーモニーを完成させるにはその人がわかるポートフォリオが必要',
      src: 'https://mynavi-creator.jp/blog/article/matchbox-review-0009',
    },
    {
      img: 'images/Top/Recruit/recruit-04.png',
      name: '株式会社セガゲームス 様',
      text: '仕事の実績を示すだけでなく自主制作で自分の主張を。ありきたりから脱出するポートフォリオを作る',
      src: 'https://mynavi-creator.jp/blog/article/matchbox-review-0011',
    },
    {
      img: 'images/Top/Recruit/recruit-05.png',
      name: 'ディライトワークス株式会社 様',
      text: '未完成の企業にこそある可能性に心が躍る。渾身のポートフォリオでデザイナーとしての道を切り..',
      src: 'https://mynavi-creator.jp/blog/article/matchbox-review-0012',
    },
    {
      img: 'images/Top/Recruit/recruit-06.png',
      name: 'PARTY 中村洋基氏',
      text: 'やはりポートフォリオは重要。ポートフォリオ作成サービスMATCHBOXで、採用側と転職側を行ったり来たりして感じたこと..',
      src: 'https://mynavi-creator.jp/blog/article/matchbox-review-0002',
    },
  ],
  everyonePortfolio: [
    {
      img: 'images/Top/portfolio01.png',
      title: '',
      userName: '谷小夏',
      userIcon: 'images/Top/userIcon01.png',
      job: 'ディレクション',
    },
    {
      img: 'images/Top/portfolio01.png',
      title: '',
      userName: '谷小夏',
      userIcon: 'images/Top/userIcon01.png',
      job: 'ディレクション',
    },
    {
      img: 'images/Top/portfolio01.png',
      title: '',
      userName: '谷小夏',
      userIcon: 'images/Top/userIcon01.png',
      job: 'ディレクション',
    },
    {
      img: 'images/Top/portfolio01.png',
      title: '',
      userName: '谷小夏',
      userIcon: 'images/Top/userIcon01.png',
      job: 'ディレクション',
    },
    {
      img: 'images/Top/portfolio01.png',
      title: '',
      userName: '谷小夏',
      userIcon: 'images/Top/userIcon01.png',
      job: 'ディレクション',
    },
    {
      img: 'images/Top/portfolio01.png',
      title: '',
      userName: '谷小夏',
      userIcon: 'images/Top/userIcon01.png',
      job: 'ディレクション',
    },
    {
      img: 'images/Top/portfolio01.png',
      title: '',
      userName: '谷小夏',
      userIcon: 'images/Top/userIcon01.png',
      job: 'ディレクション',
    },
  ],
  questions: [
    {
      question: '今すぐ転職するつもりはないのですが、MATCHBOXのサービスを利用できますか？',
      answer:
        'はい、MATCHBOXは転職を考えていなくても、利用できます。<br />転職を検討しはじめた際には、まずは「オファー機能」に登録してみましょう。あなたにぴったりのお仕事とのマッチングをお手伝いさせていただきます。',
    },
    {
      question: '作成したポートフォリオに、パスワードをかけることは可能でしょうか？',
      answer:
        '可能です。<br />・公開（パスワードなし）<br />・限定公開（パスワードつき）<br />・非公開（本人のみ閲覧可能）<br />よりお選びください。',
    },
    {
      question: 'ポートフォリオをつくらないと、「オファー機能」は使えませんか？',
      answer:
        'いいえ、オファー機能は、職務経歴書のみでも使えます。<br />プロデューサーやディレクター、プランナー、営業など、ビジュアルのアウトプットがない職種の方も、ぜひご利用ください。',
    },
  ],
};
