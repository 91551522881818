import cn from 'classnames';
import React, { VFC } from 'react';

import { iconNameList } from '@/assets/fonts/manifest';

type TProps = {
  size?: 'default' | 'large';
  type: 'gray' | 'red' | 'blue' | 'attention' | 'delete' | 'white';
  iconName: typeof iconNameList[number];
  hitArea?: 'default' | 'mini' | 'medium';
  disabled?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  focus?: boolean; // タブでフォーカスするかどうか false でフォーカスさせない
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=1%3A92
 *
 *  - hitArea : クリッカブルエリアが size default時はそれぞれ48px x 48px , 24px x 24px、largeの際は40px x 40px 固定となります
 *
 * 全てのアイコンを表示可能な設計になっていますがデザイン上は全てのアイコンをボタンでは利用しないため表示がおかしいものもあります。 `Figma` を参考にシーンに応じて設定してください。
 */
export const ButtonIconButton: VFC<TProps> = ({
  size = 'default',
  type,
  iconName,
  onClick,
  hitArea = 'default',
  disabled = false,
  className,
  focus = true,
}) => {
  const ButtonProps = {
    className: cn(
      { isDisabled: disabled },
      `hitArea_${hitArea}`,
      `size_${size}`,
      `mbx-button-iconButton`,
      `color-${type}`,
      `hover:text-button-iconButton-${type}-hover`
    ),
    ...(!focus && { tabIndex: -1 }),
  };
  return (
    <div className={className}>
      <button type="button" onClick={onClick} {...ButtonProps}>
        <i className={`mbx-icon mbx-icon-${iconName}`}></i>
      </button>
    </div>
  );
};
