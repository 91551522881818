import '@/styles/common/Preview/preview.scss';

import React, { useEffect, useMemo, useState, VFC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PreviewPage } from '@/components/CareerHistory/';
import BaseButton from '@/components/common/Button/BaseButton';
import { Wrapper } from '@/components/common/Page';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import { useDownloadPDF } from '@/hooks/useDownloadPDF';
import { careerHistory } from '@/hooks/usePageCareerHistory';
import { State } from '@/redux/state';
import { CareerHistory, Status, UserInfo } from '@/utils/api-client/api';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
export type TFetchDataProps = {
  fetchData: (CareerHistory & Status & UserInfo) | undefined;
};

const PageMainContent: VFC<{ type: 'admin' | 'direct' | 'public' }> = ({ type }) => {
  const { data: fetchData, user } = careerHistory(true, type);
  const [scale, setScale] = useState<number>(1);
  const [height, setHeight] = useState<number>(1188);

  useEffect(() => {
    const mbxPreviewPage = document.getElementsByClassName('mbx-preview-page')[0];
    if (!mbxPreviewPage) return;
    const mbxPreviewPageWidth = mbxPreviewPage.getBoundingClientRect().width;
    const mbxPreviewContent = document.getElementsByClassName('mbx-preview')[0];
    const aspectRatio = mbxPreviewPage.getBoundingClientRect().height / mbxPreviewPageWidth;
    const resizeObserver = new ResizeObserver(() => {
      const mbxPreviewContentWidth = mbxPreviewContent.getBoundingClientRect().width;
      if (window.innerWidth < 1024) {
        setScale(mbxPreviewContentWidth / mbxPreviewPageWidth);
        setHeight(mbxPreviewContentWidth * aspectRatio);
      } else {
        setScale(1);
        setHeight(mbxPreviewPageWidth * aspectRatio);
      }
    });
    resizeObserver.observe(window.document.body);
  }, []);

  const date = useMemo<string>(() => {
    if (!fetchData?.latest_update) return '';
    const dateAry = fetchData?.latest_update.split('-');
    return `${dateAry[0]}年${dateAry[1]}月${dateAry[2]}日`;
  }, [fetchData]);

  return (
    <div className="mbx-preview mbx-utils-stack-v--preview">
      <PreviewPage
        scale={scale}
        height={height}
        date={date}
        fetchData={fetchData}
        user={user}
        type={type}
      />
    </div>
  );
};

export const CareerHistoryPreview: VFC<{
  direct?: boolean;
  admin?: boolean;
  presentation?: boolean;
}> = ({ direct, admin, presentation = false }) => {
  const user = useSelector((state: State) => state.user);
  const location = useLocation();

  const { downloadPDF } = useDownloadPDF();
  const { push } = useContext(DataLayerContext);
  const userId = useMemo(() => {
    if (direct) {
      return undefined;
    }
    if (admin) {
      return location.pathname.split('/')[3];
    }
    return user?.user_id;
  }, [user]);

  const matchboxId = useMemo(() => {
    if (direct) {
      return location.pathname.split('/')[3];
    }
    return undefined;
  }, []);

  const downloadEndpoint = useMemo(() => {
    if (direct) {
      return `/api/v2/direct/career_history/${matchboxId}/download`;
    }
    if (admin) {
      return `/api/v2/admin/career_history/${userId}/download`;
    }
    return `/api/v2/career_history/${userId}/download`;
  }, [userId, matchboxId]);

  const type = useMemo<'admin' | 'direct' | 'public'>(() => {
    if (direct) {
      return 'direct';
    }
    if (admin) {
      return 'admin';
    }
    return 'public';
  }, [direct, admin]);

  return (
    <Wrapper
      type="preview"
      presentation={presentation}
      header={
        <div className="sticky top-0 z-10">
          <HeaderTitle title="職務経歴書">
            <div className="flex justify-end space-x-24">
              {!direct && (
                <BaseButton
                  theme="link"
                  size="s"
                  download="cv.pdf"
                  forceExternal={true}
                  onClick={() => {
                    push({
                      event: 'fileDownload',
                      actionType: 'file_download',
                      actionName: '職務経歴書',
                    });
                    downloadPDF();
                  }}
                  href={downloadEndpoint}
                >
                  <i className="mbx-icon mbx-icon-Download" />
                  PDF
                </BaseButton>
              )}
              {!presentation && (
                <BaseButton
                  className="mbx-top-preview-edit-button"
                  href="/mypage/cv/edit"
                  theme="secondary"
                  size="s"
                >
                  編集する
                </BaseButton>
              )}
            </div>
          </HeaderTitle>
        </div>
      }
    >
      <>
        <PageMainContent {...{ type }} />
        <div className="sm:mb-170"></div>
      </>
    </Wrapper>
  );
};
