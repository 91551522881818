import dayjs from 'dayjs';
import React, { useMemo } from 'react';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { AdminMatchboxUserResponse, Portfolio, PortfolioItem, Status } from '@/utils/api-client';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type TProps = {
  userInfo?: AdminMatchboxUserResponse;
  portfolio?: Portfolio & Status;
};

/**
 * 管理者 : ユーザー詳細ページ - 企業向けポートフォリオ
 * https://matchbox-aws.s2factory.co.jp/admin/users/C18790999
 */
export const AdminUserDetailPublicPortfolio = ({
  userInfo,
  portfolio,
}: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const pfImages = useMemo(() => {
    const items = portfolio?.direct_items as PortfolioItem[] | undefined;
    if (items) {
      return new Array(Math.max(items.length, 5)).fill(null).map((v, index) => {
        const item = items[index];
        if (item) {
          return item.i_image.f_thumbnail;
        } else {
          return '/images/image-placeholder.svg';
        }
      });
    } else {
      return new Array(5).fill(null).map(() => '/images/image-placeholder.svg');
    }
  }, [portfolio]);

  // ====================================================================================================
  //
  // CONST
  //
  // ====================================================================================================
  const useDirect = (value: boolean) => {
    return value ? '有' : '無';
  };
  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <tr>
      <th className="mbx-admin-form-title">
        企業向け
        <br />
        ポートフォリオ
      </th>
      <td>
        <div className="flex relative">
          <dl className="">
            <dt>更新日</dt>
            <dd>
              {userInfo?.last_update_public_portfolio
                ? dayjs(userInfo?.last_update_public_portfolio).format('YYYY/M/D')
                : '未登録'}
            </dd>
            <dt>作品数</dt>
            <dd>
              {userInfo?.direct_portfolio_item_count
                ? `${userInfo?.direct_portfolio_item_count}作品`
                : '0作品'}
            </dd>
          </dl>
          {/* 0作品の場合はリンクを表示しない */}
          {userInfo?.direct_portfolio_item_count !== 0 && userInfo?.direct_portfolio_item_count && (
            <div className="w-224 absolute right-0">
              <BaseButton
                className="w-full"
                theme="secondary"
                size="s"
                onClick={() => {
                  window.open(`/admin/direct/portfolio/${userInfo?.user_info.matchbox_id}`);
                }}
              >
                詳細を見る
              </BaseButton>
            </div>
          )}
        </div>
        <dl className="">
          <dt>オファー機能登録</dt>
          <dd>
            {useDirect(Boolean(userInfo?.user_info?.use_direct))}
          </dd>
        </dl>
        {userInfo?.direct_portfolio_item_count !== 0 && userInfo?.direct_portfolio_item_count && (
          <div className="grid grid-cols-5 gap-x-12 gap-y-12 my-12">
            {pfImages.map((image: string, index: number) => (
              <div className="w-144 h-81" key={index}>
                <img src={image} alt="" className="h-full object-cover mkt_mask_items" />
              </div>
            ))}
          </div>
        )}
      </td>
    </tr>
  );
};
