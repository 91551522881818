import React from 'react';

import {
    FormContainerTextfieldMultiple
} from '@/componentsDirect/Parts/Form/Container/TextfieldMultiple';
import { UserCondition } from '@/componentsDirect/Parts/UserCondition/Forms';

/**
 * 検索条件フォーム フリーワード
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65909
 */
export const DirectSearchFormSectionFreeWord = (): React.ReactElement => {
  return (
    <UserCondition title="フリーワード">
      <FormContainerTextfieldMultiple
        className="pt-2 ml-24 mkt_non_mask_items"
        name="keyword"
        placeholder="例）UIデザイナー　UXデザイナー（エンターキーで区切って複数入力可能）"
      />
    </UserCondition>
  );
};
