import React, { useMemo } from 'react';
import CompanyPlaceholder from '@/assets/images/common/company_placeholder.svg';
import { dataStringSplitter } from '@/utils/string';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { AdminCompany } from '@/utils/api-client';

type Props = {
  company: AdminCompany;
};

export const BasicInfoLeft = (props: Props): React.ReactElement => {
  const format = useMemo(() => {
    FORM_OPTIONS.prefecture;
    const prefecture = FORM_OPTIONS.prefecture.find(
      (p) => p.value === props.company.prefecture?.toString()
    )?.children;
    const business_type = FORM_OPTIONS.business_type.find(
      (p) => p.value === props.company.business_type?.toString()
    )?.children;

    return {
      prefecture,
      business_type,
    };
  }, [props.company]);

  const dateFormat = (date: string) => {
    const year = dataStringSplitter(date)[0];
    const month = dataStringSplitter(date)[1];
    const day = dataStringSplitter(date)[2];
    return year + '年' + month + '月' + day + '日';
  };

  return (
    <section className="flex items-center">
      <div className="w-64 h-64 rounded-full overflow-hidden">
        <img src={props.company.icon ? props.company.icon : CompanyPlaceholder} alt="" />
      </div>
      <div className="ml-16">
        <h3 className="text-18_24 font-bold">{props.company.name}</h3>
        <p className="mt-6 mbx-typography--caption_1">
          <span>{format.prefecture}</span>
          <span className="mx-4">/</span>
          <span>{format.business_type}</span>
        </p>
        <p className="mt-6 text-10_12 text-gray-600">
          <span>企業ID：{props.company.id}</span>
          {props.company.registration_date && (
            <span className="ml-16">
              アカウント開設日：{dateFormat(props.company.registration_date)}
            </span>
          )}
          {props.company.last_login && (
            <span className="ml-16">最終ログイン日：{dateFormat(props.company.last_login)}</span>
          )}
        </p>
      </div>
    </section>
  );
};
