import React, { useState, useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Ed49 = (): React.ReactElement => {
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if (isAuth) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0049");
    if (!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g, "/")} 12:00:00`);
    const now = new Date;
    if (now > deadlineDate) setDeadline(true);
  }, []);

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16">
          <img className={style.img} src="../images/Top/event/event_0049_mv.png" width="978" height="504" alt="" />
        </div>
        <h1 className="mt-56 sm:mt-30 sm:mb-8 text-29_46 sm:text-18_24 font-bold text-center">7/19（水）19:30 -<br />
          <span className="inline-block sm:mb-8 text-21_34 sm:text-15_22">Webデザイナーのための<br className="md:hidden lg:hidden" />スキルアップセミナー vol.1</span><br />
          ユーザーの反応を発見するプロトタイピング</h1>
        {deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <section className='mt-80 sm:mt-55'>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>セミナーについて</h2>
          <div className="mt-30 sm:mt-16 mb-30 sm:mb-18 box-line">
            <img src="../images/Top/event/event_0049_image.jpg" width="980" height="374" alt="" />
          </div>
          <p className="text-16_28 sm:text-14_21 mt-30 sm:mt-16 mb-40 sm:mb-18">
            日頃、マッチボックスユーザーの皆さま向けにセミナーや転職支援を行っている中で、Webデザイナーから、「周りに聞けない、相談できない」、「教育体制が無く、キャリアアップできているのかわからない」、「スキルアップしたいが、何を学べばよいかわからない」など成長を個人に委ねられていることに対する不安の声を多くいただいていました。<br />
            <br />
            今回、Webデザイナーの方のスキルアップや自己研鑽のニーズにお応えすべく、『スキルアップセミナー』を3カ月に渡り、計6回行います。<br />
            スキルアップの項目については、セミナーアンケートからご要望の多かった、『プロダクトマネジメント』・『UX』・『プログラミング（フロントエンド）』・『ディレクション』・『デジタルマーケティング』・『デザイン領域の合意形成』についてWebデザイナーと協働するポジションの方を講師に招きます。
            何故そのスキルを習得するべきなのか、使用ツールの具体事例、講師目線からWebデザイナーに期待すること、スキルアップのためのおすすめの書籍など、次の日から始められるTipsをお伝えいたします。<br />
            <br />
            今回はvol.1『ユーザーの反応を発見するプロトタイピング』として、ユーザーニーズをどのように落とし込んでより価値を提供するためのデザインを作成するのか、プロトタイピング作成を軸にお話しいただきます。講師は株式会社マネーフォワード HOME本部 事業開発部 プロダクトマネージャー 佐藤慎悟氏です。是非ご参加ください。</p>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>以下に該当する方はオススメです。</h2>
          <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>キャリアアップに悩んでいるWebデザイナー</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>スキルアップして、今の現状を自分の力で打破したい人</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>Web制作の現場において、自分の上げたデザインのこれじゃない感やリテイクに悩んでいる</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>次のキャリアアクションを決めかねていてWebデザイナーとして何か武器を持ちたい</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>プロダクトマネージャーとの協働の機会がある方</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>ツールについて知りたい方</span>
              </li>
              <li className="flex">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>セミナーを踏まえて転職・書類作成の相談をしたい方</span>
              </li>
            </ul>
          </div>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>講師プロフィール</h2>
          <div className='event-instructor box-line mb-30 sm:p-16'>
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0049_prof.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                佐藤 慎悟（SHINGO SATO）
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-40 sm:mb-18">
                株式会社マネーフォワード<br />
                HOME本部 事業開発部 プロダクトマネージャー
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                大学卒業後、Web制作会社にてWebデザイン、ディレクションを担当。その後、2011年に株式会社サイバーエージェントに転職。主にはメディア事業でディレクターやプロデューサーを担当しながらスクラム開発やチームビルディングなどを経験。新規サービスの立ち上げにも携わる。2017年に株式会社リクルートに移り、クリエイティブチームのマネジメントを経て、サービス改善のプランニングや横断組織でのディレクションを担当。2020年に株式会社マネーフォワードに入社し、個人事業主向けサービスのプロダクトマネージャー、戦略部部長を兼任。戦略立案、チームビルディング、ピープルマネジメントを中心に行う。現在は個人向けサービスを中心とした事業開発のチームのマネジメントと新規サービスの立ち上げを担当。
              </dd>
            </dl>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap">
                  <span className="sm:block">19：30</span><span className="sm:block">～</span><span className="sm:block">20：50</span>
                </th>
                <td>
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>本講座の目的</span></li>
                    <li className="flex"><span>・</span><span>PdMの立場から見たデザイナーの重要性</span></li>
                    <li className="flex"><span>・</span><span>プロトタイピングの考え方</span></li>
                    <li className="flex"><span>・</span><span>ケース別プロトタイプの紹介</span></li>
                    <li className="flex"><span>・</span><span>まとめ</span></li>
                    <li className="flex"><span>・</span><span>Q&A</span></li>
                    <li className="flex"><span>・</span><span>質疑応答</span></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2023/7/19（水）19:30～<br />
                  [入室開始] 19:20～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.参加申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink"
                      href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.開催当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className='mt-80 sm:mt-55'>
          <h3 className='text-18_24 sm:text-14_21 font-bold'>開催について</h3>
          <p className='text-16_28 sm:text-14_21 mt-30 sm:mt-16'>Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2, 000名を超える「0からのWebディレクション講座」を生み出したメンバーでもある一般社団法人ディレクションサポート協会 会長の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。</p>
        </section >
        <section className="mt-80 sm:mt-55 p-40 sm:p-24 bg-gray-50 text-left">
          <h3 className="text-18_24 sm:text-14_21 font-bold">注意点</h3>
          <p className="mt-16 text-16_28 sm:text-14_21">
            ZoomURLはセミナー前日・または当日にメールにてお送りします。<br />
            マッチボックス登録時のアドレス、またはセミナー申込み時に入力したアドレスにURLへ届いていない場合は<a className='textLink' href='https://survey.mynavi.jp/cre/?enq=2va0sJC992s%3d' target={'_blank'} rel="noreferrer">こちら<i className="mbx-icon mbx-icon-External_link"></i></a>からお問い合わせください。
          </p>
        </section>
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div>
    </div>
  );
};
