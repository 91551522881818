import '../../styles/common/footer.scss';

import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export const DirectFooter = (): ReactElement => {
  return (
    <footer className="footer">
      <div className="footer-inner">
        <p className="copyright">Copyright &copy; Mynavi Works Corporation All Rights Reserved.</p>

        <ul>
          <li>
            <a href="https://mynavi-works.jp/" target="_blank" rel="noreferrer">
              運営会社
            </a>
          </li>
          <li>
            <a
              href="https://survey.mynavi.jp/cre/?enq=fJlHCQO1mWY%3d"
              target="_blank"
              rel="noreferrer"
            >
              お問い合わせ
            </a>
          </li>
          <li>
            <Link to="/direct/terms_of_use">利用規約</Link>
          </li>
          <li>
            <HashLink to="/direct/terms_of_use#privacy">個人情報の取り扱い</HashLink>
          </li>
        </ul>
      </div>
    </footer>
  );
};
