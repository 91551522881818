import '@/styles/common/Mypage/assetsbox.scss';

import React, { useContext, useState } from 'react';

import BaseButton from '@/components/common/Button/BaseButton';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { MypageAssetsTitle } from '@/components/common/Mypage/Assets/Title';
import { PDFDownLoadModal } from '@/components/common/Portfolio/PDFDownLoadModal';
import { useDownloadPDF } from '@/hooks/useDownloadPDF';
import CareerImg from '@/images/Career.png';
import PortfolioImg from '@/images/Portfolio.png';
import ResumeImg from '@/images/Resume.png';

type TProps = {
  type: 'resume' | 'career' | 'portfolio';
  className?: string;
  editHref: string;
  downloadFileName?: string;
  pdfDl?: string; // API endpoint https://matchbox-2021-doc-api-git-develop-prty.vercel.app/#operation/get-user_id-download
};

/**
 * PDFのダウンロードボタンはa要素のdownload属性を利用する
 *
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=383%3A35945
 *
 */
export const MypageAssetsBox = ({
  className,
  type,
  editHref,
  downloadFileName = 'downloadFile.pdf',
  pdfDl,
}: TProps): React.ReactElement => {
  const { downloadPDF } = useDownloadPDF();
  const { push } = useContext(DataLayerContext);
  const [pdfModal, setPdfModal] = useState(false);

  let title = '';
  let en = '';
  let img;
  switch (type) {
    case 'resume':
      title = '履歴書';
      en = 'Resume';
      img = ResumeImg;
      break;
    case 'career':
      title = '職務経歴書';
      en = 'Work History';
      img = CareerImg;
      break;
    case 'portfolio':
      title = 'ポートフォリオ';
      en = 'Portfolio';
      img = PortfolioImg;
      break;
  }
  return (
    <>
      <div className={'mbx-assetsbox w-288 text-center ' + className}>
        <MypageAssetsTitle en={en}>{title}</MypageAssetsTitle>
        <div className="w-80 mx-auto mt-8">
          <img src={img} alt="" />
        </div>
        <BaseButton size="m" theme="primary" href={editHref} className="mt-24">
          編集ページへ
        </BaseButton>
        <BaseButton
          size="m"
          theme="secondary"
          iconName="Download"
          className="md:mt-8 lg:mt-8"
          download={downloadFileName}
          forceExternal={true}
          href={pdfDl}
          onClick={() => {
            push({
              event: 'fileDownload',
              actionType: 'file_download',
              actionName: title,
            });
            if (type === 'portfolio') {
              setPdfModal(true);
            } else {
              downloadPDF();
            }
          }}
        >
          PDF
        </BaseButton>
      </div>
      {type === 'portfolio' && (
        <PDFDownLoadModal
          isOpen={pdfModal}
          onClose={() => setPdfModal(false)}
          onClickCancel={() => setPdfModal(false)}
        ></PDFDownLoadModal>
      )}
    </>
  );
};
