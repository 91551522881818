import { createSlice } from '@reduxjs/toolkit';

import { reducers } from './reducer';
import { initialState } from './state';

/**
 * ローディングやエラーなど汎用情報
 */
export const utilsModule = createSlice({
  name: 'utils',
  initialState,
  reducers,
});

export const {
  toggleLoading,
  forceLoadingOn,
  forceLoadingOff,
  notificationError,
  notificationSuccess,
  setPathAfterLogin,
} = utilsModule.actions;
