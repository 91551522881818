import React, { ReactNode, VFC } from 'react';

type TProps = {
  type?: 'large' | 'text-large';
  className?: string;
  children: ReactNode;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=326%3A23639
 */
export const TitleSectionTitle: VFC<TProps> = ({ type = 'large', children, className }) => {
  return type == 'large' ? (
    <div className={className}>
      <h1 className="mbx-typography--heading_4 mb-16">{children}</h1>
      <hr className="mb-24 mbx-keyline-horizontal--bold"></hr>
    </div>
  ) : (
    <div className={className}>
      <h1 className="mbx-typography--heading_4 mb-24">{children}</h1>
    </div>
  );
};
