import '@/styles/pages/Direct/Users/Detail/index.scss';

import { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import BackButton from '@/components/common/Button/BackButton';
import OfferList from '@/components/common/Direct/OfferList';
import { TabNavigation } from '@/components/common/Navigation/TabNavigation';
import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { Pager } from '@/componentsDirect/Parts/Navigation/Page/Pager';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { notificationError } from '@/reduxAdmin/modules/utils';
import { ADMINApi, Offer, OfferStatusEnum, ResponseError } from '@/utils/api-client';

export const AdminUserOffers = (): React.ReactElement => {
  // ====================================================================================================
  //
  // hooks
  //
  // ====================================================================================================
  const dispatch = useDispatch();
  const location = useLocation();
  const params: { matchbox_id: string } = useParams();

  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [pageList, setPageList] = useState<number[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [userInfo, setUserInfo] = useState<any>();
  const [activeIndex, setActiveIndex] = useState<number>(0);

  // ====================================================================================================
  //
  // RHF
  //
  // ====================================================================================================
  const methods = useForm();

  // ====================================================================================================
  //
  // API
  //
  // ====================================================================================================
  const getUser = async () => {
    try {
      const userRes: AxiosResponse = await new ADMINApi().getAdminUserId(matchboxId);
      setUserInfo(userRes.data);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    }
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const matchboxId = useMemo(() => location.pathname.split('/')[3], [location]);

  const offers = useMemo<Offer[]>(() => {
    if (!userInfo?.offers) return [];
    if (activeIndex === 0) {
      return userInfo?.offers.filter(
        (offer: Offer) =>
          offer.status !== OfferStatusEnum.NUMBER_1 && offer.status !== OfferStatusEnum.NUMBER_2
      );
    } else {
      return userInfo?.offers.filter(
        (offer: Offer) =>
          offer.status === OfferStatusEnum.NUMBER_1 || offer.status === OfferStatusEnum.NUMBER_2
      );
    }
  }, [userInfo, activeIndex]);

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    if (!params.matchbox_id) return;
    (async () => {
      await getUser();
    })();
  }, [matchboxId]);

  useEffect(() => {
    if (!userInfo) return;
    methods.setValue('user_admin_tags', userInfo?.user_admin_tags);
  }, [userInfo]);

  useEffect(() => {
    setPageIndex(0);
    const lastPath = location.pathname.split('/').reverse()[0];
    switch (lastPath) {
      case 'pickups':
        setActiveIndex(1);
        break;
      default:
        setActiveIndex(0);
        break;
    }
  }, [location]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <AdminPage header={<HeaderTitle title="オファー・ピックアップ履歴" />}>
      <>
        <div className="mbx-direct-users-detail">
          <section className="h-48 flex items-center">
            <BackButton href={`/admin/users/${matchboxId}`}>求職者詳細へ戻る</BackButton>
          </section>

          <section className="mt-12">
            <TabNavigation
              align="fit"
              menu={[
                { title: 'オファー履歴', url: `/admin/users/${matchboxId}/offers` },
                { title: 'ピックアップ履歴', url: `/admin/users/${matchboxId}/pickups` },
              ]}
              activeIndex={activeIndex}
            />
          </section>

          <div className="flex my-22 space-x-8">
            <img
              src={
                userInfo?.user_info.photo.f_thumbnail
                  ? userInfo?.user_info.photo?.f_thumbnail
                  : '/images/profile_icon.png'
              }
              className="w-24 h-24 block"
              alt=""
            />
            <h3 className="text-18_24 font-bold">
              {userInfo?.user_info.name} の{activeIndex === 0 ? 'オファー' : 'ピックアップ'}履歴
            </h3>
          </div>

          <p className="text-12_20">
            {offers.length === 0 ? (
              0
            ) : (
              <span>
                {pageIndex * 10 + 1}〜{Math.min(pageIndex * 10 + 10, offers.length)}
              </span>
            )}
            / {offers.length}件
          </p>
          <section className="mt-12 mb-24 mbx-direct-users-detail-offers-wrapper">
            {offers
              .slice(pageIndex * 10, pageIndex * 10 + 10)
              .map((offer: Offer, index: number) => (
                <div key={index} className="mbx-direct-users-detail-offers">
                  <OfferList
                    offer={{ ...offer, is_read: true }}
                    hover={false}
                    statusUpdate={async () => {
                      /** */
                    }}
                    admin
                  />
                </div>
              ))}
          </section>
          <Pager
            page={pageIndex}
            pageLength={Math.ceil(offers.length / 10)}
            setPage={(page: number) => {
              setPageIndex(page);
            }}
            setPageList={setPageList}
            pageList={pageList}
          />
        </div>
      </>
    </AdminPage>
  );
};
