import React, { useEffect, useRef } from 'react';

import MatchImg from '@/images/navi-match.png';
import { color, color_alias } from '@/tailwind/colors.js';
import { Repeat } from '@/types/Util';

type TProps = {
  type?: 'large' | 'medium' | 'small' | 'notification';
  progress?: Repeat<0|1, 4|10>;
  digit?: number;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=348%3A26660
 *
 * progress : ポートフォリオ入力度の配列を受け取る想定
 * https://matchbox-2021-doc-api-git-develop-prty.vercel.app/#operation/get-portfolio_progress
 *
 * digit : 完成度の数値。 digit があればこちらが優先される。
 *
 */
export const MatchDegree = ({
  type = 'large',
  progress = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  digit,
}: TProps): React.ReactElement => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const percentage = useRef(0);
  percentage.current = digit ?? Math.floor(progress.filter((x) => x === 1).length * 100/progress.length);

  const createProgressGraph = (): void => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const canvasWidth = type === 'small' || type === 'notification' ? 32 : 64;
    canvas.width = canvasWidth;
    canvas.height = canvasWidth;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    ctx.beginPath();
    const angleA = 360 * (percentage.current / 100);
    const angleB = 360 * ((100 - percentage.current) / 100);
    const circle = ctx.canvas.width / 2;
    ctx.beginPath();
    ctx.arc(
      circle,
      circle,
      circle,
      ((0 - 90) * Math.PI) / 180,
      ((angleA - 90) * Math.PI) / 180,
      false
    );
    ctx.lineTo(circle, circle);
    ctx.fillStyle = color_alias.corporate.red;
    ctx.fill();
    ctx.beginPath();
    ctx.arc(
      circle,
      circle,
      circle,
      ((angleA - 90) * Math.PI) / 180,
      ((angleA + angleB - 90) * Math.PI) / 180,
      false
    );
    ctx.lineTo(circle, circle);
    ctx.fillStyle = color.red[300];
    ctx.fill();
  };

  useEffect(() => {
    createProgressGraph();
  }, [progress]);

  if (type == 'small') {
    return (
      <div className="flex items-center">
        <div className="mbx-matchdegree--image">
          <canvas ref={canvasRef} />
          <img src={MatchImg} alt="" className="mbx-matchdegree--image-s" />
        </div>
        <p className="mbx-matchdegree--text-s text-corporate-red text-center">
          完成度
          <span className="mbx-matchdegree--progress-s">{percentage.current}</span>%
        </p>
      </div>
    );
  } else if (type == 'medium') {
    return (
      <div>
        <div className="mbx-matchdegree--image mx-auto">
          <canvas ref={canvasRef} className="mx-auto"></canvas>
          <img src={MatchImg} alt="" className="mbx-matchdegree--image-l" />
        </div>
        <p className="mbx-matchdegree--text-m text-corporate-red text-center">
          完成度<span className="mbx-matchdegree--progress-m">{percentage.current}</span>%
        </p>
      </div>
    );
  } else if (type == 'notification') {
    return (
      <div className="mbx-matchdegree--small md:flex lg:flex items-center">
        <div className="mbx-matchdegree--image">
          <canvas ref={canvasRef} className="mx-auto" />
          <img src={MatchImg} alt="" className="mbx-matchdegree--image-s" />
        </div>
        <p className="mbx-matchdegree--text-s text-corporate-red text-center">
          <span className="sm:hidden">完成度</span>
          <span className="mbx-matchdegree--progress-s">{percentage.current}</span>%
        </p>
      </div>
    );
  } else {
    return (
      <div>
        <div className="mbx-matchdegree--image">
          <canvas ref={canvasRef} className="mx-auto"></canvas>
          <img src={MatchImg} alt="" className="mbx-matchdegree--image-l" />
        </div>
        <p className="mbx-matchdegree--text-l text-corporate-red text-center">
          完成度
          <span className="mbx-matchdegree--progress-l">{percentage.current}</span>%
        </p>
      </div>
    );
  }
};
