import React from 'react';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { DataLayerType } from '@/hooksDirect/common/useDataLayer';
import { useCompanyUserGroups } from '@/hooksDirect/useCompanyUserGroups';

type TProps = {
  showModal: boolean;
  id: string;
  setUserDeleteState: React.Dispatch<
    React.SetStateAction<{
      id: string;
      showModal: boolean;
    }>
  >;
  getCompanyUserGroups: () => Promise<void>;
  dataLayer?: DataLayerType;
};

/**
 * 社内ユーザーグループ削除処理
 * https://www.figma.com/file/U3CKCcmEc7eIjxkbYoCZx7/UI?type=design&node-id=11781-162287&mode=dev
 */
export const DirectUsersDeleteUserGroupComponent = ({
  showModal,
  id,
  setUserDeleteState,
  getCompanyUserGroups,
  dataLayer,
}: TProps): React.ReactElement => {
  const { deleteCompanyUserGroupId } = useCompanyUserGroups();
  const { push } = React.useContext(DataLayerContext);

  const onClose: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.preventDefault();
    setUserDeleteState({ id: '', showModal: false });
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    (async () => {
      await deleteCompanyUserGroupId(id);
      await getCompanyUserGroups();
      setUserDeleteState({ id: '', showModal: false });
      push({
        event: 'deleteUserGroup',
        actionType: 'delete_user-group',
        actionName: 'delete_user-group',
      });
    })();
  };
  return (
    <ModalDefault
      isOpen={showModal}
      onClose={onClose}
      className="w-624 rounded-modalContainer"
      dataLayer={dataLayer}
    >
      <section className="py-80">
        <h1 className="text-subhead_1 mb-8">このグループを削除しますか？</h1>
        <form className="px-80" onSubmit={onSubmit}>
          <div className="flex justify-center mt-24">
            <div className="w-146 mr-8">
              <BaseButton size="m" theme="secondary" className="w-full" onClick={onClose}>
                キャンセル
              </BaseButton>
            </div>
            <div className="w-146 ml-8">
              <BaseButton size="m" theme="primary" className="w-full" type="submit">
                削除する
              </BaseButton>
            </div>
          </div>
        </form>
      </section>
    </ModalDefault>
  );
};
