import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NotificationError } from '@/components/common/Notification/Error';
import { notificationError } from '@/redux/index';
import { State } from '@/redux/state';

/**
 * global stateの値検知でエラー表示をする汎用エラー
 *
 * 仕様 :
 * - メッセージが表示されてから4秒後に自動非表示
 */
export const GlobalNotificationErrorPortal = (): React.ReactElement => {
  const isFirstRun = useRef(true);

  const dispatch = useDispatch();

  const errorMessage: string | null = useSelector((state: State) => state.errorMessage);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (errorMessage) {
      _.delay(onClick, 4000);
    }
  }, [errorMessage]);

  const onClick = () => {
    dispatch(notificationError(null));
  };

  return (
    <div className="sticky bottom-0 z-300">
      <NotificationError onClick={onClick} show={!!errorMessage}>
        {errorMessage}
      </NotificationError>
    </div>
  );
};
