import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { MESSAGE } from '@/definition/MESSAGE';

type TProps = {
  show: boolean;
};

/**
 * 自動保存がされた時に表示されるコンパクトなスナックバー
 *
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=5332%3A102628
 */
export const NotificationSnackBarSaved = ({ show = false }: TProps): React.ReactElement => {
  // !see https://github.com/reactjs/react-transition-group/issues/668
  const nodeRef = React.useRef(null);

  const [showState, setShow] = useState(show);

  useEffect(() => {
    setShow(show);
  }, [show]);
  return (
    <CSSTransition
      in={showState}
      timeout={550}
      nodeRef={nodeRef}
      unmountOnExit
      classNames="mbx-anime-notification"
    >
      <div ref={nodeRef} className={'mbx-notification--saved'}>
        <div className="mbx-notification--saved-inner">
          <i className="mbx-icon mbx-icon-Check text-gray-500 text-24_24 m-8"></i>
          <div className={'mbx-notification--saved-message'}>{MESSAGE.saving_completed}</div>
        </div>
      </div>
    </CSSTransition>
  );
};
