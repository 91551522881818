import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MESSAGE } from '@/definition/MESSAGE';
import {
    notificationError, notificationSuccess, toggleLoading, userInfo, userResponseAll
} from '@/redux/index';
import { State } from '@/redux/state';
import {
    PasswordChangeParam, ResponseError, UserApi, UserEmailChange, UserEmailChangeToken, UserInfo,
    UserPatchRequest
} from '@/utils/api-client';

type TReturn = {
  matchboxUser: UserInfo | null;
  getUser: () => void;
  patchUser: (request: UserPatchRequest) => void;
  postPasswordChange: (request: PasswordChangeParam) => Promise<void>;
  postEmailChange: (request: UserEmailChange) => Promise<void>;
  emailChangeResponse: AxiosResponse | undefined;
  postEmailChangeValidateToken: (request: UserEmailChangeToken) => Promise<void>;
};

/**
 * ユーザー情報設定関連
 */
export const useUser = (): TReturn => {
  const API = new UserApi();
  const dispatch = useDispatch();

  const [emailChangeResponse, setemailChangeResponse] = useState<AxiosResponse>();

  const matchboxUser = useSelector((state: State) => state.user);

  /**
   * ユーザー情報取得
   * ログアウト時などにエラーになってしまうのでエラー通知はせずに成功時のみ処理
   */
  const getUser = () => {
    API.getUser().then((res) => {
      if (res.data) dispatch(userResponseAll(res.data));
      if (res.data.user_info) dispatch(userInfo(res.data.user_info));
    });
  };

  /**
   * ユーザー情報編集
   */
  const patchUser = (request: UserPatchRequest) => {
    dispatch(toggleLoading(true));
    API.patchUser(request)
      .then((res) => {
        if (res.data.user_info) dispatch(userInfo(res.data.user_info));
        dispatch(notificationSuccess(MESSAGE.saving_completed_for_user_profile));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * パスワード変更
   */
  const postPasswordChange = (request: PasswordChangeParam) => {
    dispatch(toggleLoading(true));
    return API.postUserPasswordChange(request)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.saving_completed_for_password_change));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * メールアドレス変更
   */
  const postEmailChange = (request: UserEmailChange) => {
    dispatch(toggleLoading(true));
    return API.postUserEmailChange(request)
      .then((res) => {
        setemailChangeResponse(res);
        dispatch(notificationSuccess(MESSAGE.saving_completed_for_email_change));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) {
          setemailChangeResponse(error.response);
          dispatch(notificationError(error.response.data.error_message));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * メールアドレス変更 トークンチェック
   */
  const postEmailChangeValidateToken = (request: UserEmailChangeToken) => {
    return API.postUserEmailChangeValidateToken(request)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.saving_completed_for_email_change_validate_token));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      });
  };

  return {
    matchboxUser,
    getUser,
    patchUser,
    postPasswordChange,
    postEmailChange,
    emailChangeResponse,
    postEmailChangeValidateToken,
  };
};
