import cn from 'classnames';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import { CSSTransition } from 'react-transition-group';

import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { FormFootnote } from '@/componentsDirect/Parts/Form/Footnote';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { FormLayoutUnitDivider } from '@/componentsDirect/Parts/Form/Layout/UnitDivider';
import { TFormOption } from '@/definition/FORM_OPTIONS';

type TProps = {
  className?: string;
  label: string;
  name: string;
  unit?: string;
  placeholder?: string;
  fieldWidthClass?: string; // tailwindの w-{number} を指定
  selectList: Array<TFormOption>;
  type?: 'default' | 'double-l' | 'double-r';
};

/**
 * 期間や年齢範囲など二つの入力項目から値を設定するファームセット
 *
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=1071%3A54097
 */
export const FormCombinationsFromToDropdown = ({
  className,
  label,
  name,
  unit,
  placeholder,
  fieldWidthClass,
  selectList,
  type
}: TProps): React.ReactElement => {
  const errorRef = useRef(null);

  const [thisErrors, setThisErrors] = useState<Array<string>>([]);
  const [debugError, setDebugError] = useState(false);
  const reName = useMemo(() => {
    if(name.includes('.')) return name.split('.')[0];
    return name;
  }, [name]);

  const methods = useFormContext();
  const register = methods.register(name);
  const { watch, control, trigger } = methods;
  const { dirtyFields, errors } = useFormState({
    control,
  });

  /**
   * dirtyFields の値をみてユーザー操作がない場合のみ本来の入力値に変更があれば分割して指定
   */
  useEffect(() => {
    // もとの値が配列のデータでかつfrom to のフォームに対してユーザーがない場合は値を設定
    if (Array.isArray(watch(name)) && !dirtyFields[`${name}-from`] && !dirtyFields[`${name}-to`]) {
      methods.setValue(`${name}-from`, watch(name)[0]);
      methods.setValue(`${name}-to`, watch(name)[1]);
    }
  }, [watch(name)]);

  const setOriginalValue = () => {
    if (watch(`${name}-from`) !== undefined || watch(`${name}-to`) !== undefined) {
      // 両方ともvalidなら設定
      if (errors[`${name}-from`] || errors[`${name}-to`]) return;
      methods.setValue(name, [watch(`${name}-from`), watch(`${name}-to`)], {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  /**
   * from to の値が変更になるたびに本来の入力値を更新
   */
  useEffect(() => {
    setOriginalValue();
    trigger();
  }, [watch(`${name}-from`), watch(`${name}-to`)]);

  /**
   * エラー処理に反応
   */
  useEffect(() => {
    if (errors[`${name}-from`] || errors[`${name}-to`] || errors[reName]) {
      let tmpErrors = [];
      if (errors[`${name}-from`]) {
        tmpErrors.push(errors[`${name}-from`].message);
      }
      if (errors[`${name}-to`]) {
        tmpErrors.push(errors[`${name}-to`].message);
      }
      if (errors[`${reName}`]) {
        if (name.includes('.')) {
          const array = name.split('.');
          const error = errors[array[0]][array[1]];
          if (error) {
            tmpErrors.push(errors[array[0]][array[1]][array[2]].message);
            setDebugError(true);
          } else {
            setDebugError(false);
          }
        } else {
          tmpErrors.push(errors[`${name}`].message);
        }
      }
      // 重複を削除
      tmpErrors = tmpErrors.filter(function (x, i, self) {
        return self.indexOf(x) === i;
      });

      if (errors[reName] && !name.includes('.')) {
        setDebugError(true);
      }

      // stateに設定
      setThisErrors(tmpErrors);
    } else {
      setDebugError(false);
      setThisErrors([]);
      setOriginalValue();
    }
  }, [errors[`${name}-from`], errors[`${name}-to`], errors[reName]]);

  return (
    <FormLayoutFieldset className={className}>
      <div className={cn({ "-mb-6": label === '' })}>
        <FormLabel label={label} type='small' />
      </div>
      <div className="flex mbx-utils-stack-h--8">
        <FormContainerDropdown
          name={`${name}-from`}
          placeholder={placeholder}
          selectList={selectList}
          className={`${fieldWidthClass} mkt_non_mask_items`}
          debugError={debugError}
          type={type === 'double-r' ? type : 'default'}
        />
        <FormLayoutUnitDivider>〜</FormLayoutUnitDivider>
        <FormContainerDropdown
          name={`${name}-to`}
          placeholder={placeholder}
          selectList={selectList}
          className={`${fieldWidthClass} mkt_non_mask_items`}
          debugError={debugError}
          type={type === 'double-l' ? type : 'default'}
        />
        {unit && <FormLayoutUnitDivider>{unit}</FormLayoutUnitDivider>}
      </div>
      <input type="hidden" {...register} />
      <CSSTransition
        in={!!thisErrors.length}
        timeout={550}
        nodeRef={errorRef}
        unmountOnExit
        classNames="mbx-anime-error"
      >
        <div className="flex flex-row" ref={errorRef}>
          <FormFootnote className="text-error-700">{thisErrors.join(',')}</FormFootnote>
        </div>
      </CSSTransition>
    </FormLayoutFieldset>
  );
};
