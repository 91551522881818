import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import {
    NotificationPDFDownloadSnackBar
} from '@/componentsDirect/Parts/Notification/PDFDownloadSnackBar';
import { RootState } from '@/reduxAdmin';

/**
 * global stateの値検知で表示する汎用通知
 *
 * 仕様 :
 * - PDF生成中に全てのページで表示
 *
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=10595%3A174607&t=DYemNnu5kSHUaJmM-4
 */
export const GlobalPDFDownloadSnackBarPortal = (): React.ReactElement => {
  const isFirstRun = useRef(true);
  const statusOfPDF = useSelector((state: RootState) => state.portfolio.portfolioStatusOfPDF);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
  }, []);

  return (
    <div className="sticky bottom-0 z-15">
      <NotificationPDFDownloadSnackBar status={statusOfPDF}></NotificationPDFDownloadSnackBar>
    </div>
  );
};
