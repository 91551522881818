import React, { ComponentProps } from 'react';

import { NaviInpageMenu } from '@/components/common/Navigation/Inpage/Menu';
import { Column, Wrapper } from '@/components/common/Page';
import { ResumeFormSectionAddress } from '@/components/Resume/Form/SectionAddress';
import { ResumeFormSectionCareer } from '@/components/Resume/Form/SectionCareer';
import { ResumeFormSectionEducations } from '@/components/Resume/Form/SectionEducations';
import { ResumeFormSectionFamily } from '@/components/Resume/Form/SectionFamily';
import { ResumeFormSectionHopeJob } from '@/components/Resume/Form/SectionHopeJob';
import { ResumeFormSectionInterestThings } from '@/components/Resume/Form/SectionInterestThings';
import { ResumeFormSectionLastUpdate } from '@/components/Resume/Form/SectionLastUpdate';
import { ResumeFormSectionProfile } from '@/components/Resume/Form/SectionProfile';
import { ResumeFormSectionQualification } from '@/components/Resume/Form/SectionQualification';
import { ResumeFormSectionStatement } from '@/components/Resume/Form/SectionStatement';
import { ResumeHeader } from '@/components/Resume/Header';
import { usePageResumeFetch, usePageResumeProgress } from '@/hooks/usePageResume';
import { MBResumeResponse } from '@/utils/api-client/api';

// Section用の型指定 共有なので読み込み側で指定してimportさせてる
export type TSectionProps = {
  id?: string;
  fetchData: MBResumeResponse | undefined;
};

/**
 * # 7-1-1 履歴書 編集 /mypage/resume/edit
 * UI PC https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=426%3A42863
 * UI SP https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=685%3A35345
 * API https://matchbox-2021-doc-api-git-develop-prty.vercel.app/#operation/get-resume
 */
export const PagesResumeEdit = (): React.ReactElement => {
  return (
    <Wrapper
      header={
        <div className="sticky top-0 z-10">
          <ResumeHeader>
            <PageMenuContent></PageMenuContent>
          </ResumeHeader>
        </div>
      }
    >
      <Column
        main={<PageMainContent></PageMainContent>}
        menu={
          <div className="mbx-naviInpage flex justify-end py-48 sm:hidden">
            <PageMenuContent></PageMenuContent>
          </div>
        }
      />
    </Wrapper>
  );
};

const PageMainContent = (): React.ReactElement => {
  const resumeData = usePageResumeFetch();
  return (
    <div className="pt-48 pb-100 mbx-utils-stack-v--48 sm:pt-24 sm:mb-144">
      <ResumeFormSectionProfile id="Profile" fetchData={resumeData}></ResumeFormSectionProfile>
      <ResumeFormSectionAddress id="Address" fetchData={resumeData}></ResumeFormSectionAddress>
      <ResumeFormSectionEducations
        id="Education"
        fetchData={resumeData}
      ></ResumeFormSectionEducations>
      <ResumeFormSectionCareer id="Career" fetchData={resumeData}></ResumeFormSectionCareer>
      <ResumeFormSectionQualification
        id="Qualification"
        fetchData={resumeData}
      ></ResumeFormSectionQualification>
      <ResumeFormSectionStatement
        id="Statement"
        fetchData={resumeData}
      ></ResumeFormSectionStatement>
      <ResumeFormSectionInterestThings
        id="InterestThings"
        fetchData={resumeData}
      ></ResumeFormSectionInterestThings>
      <ResumeFormSectionHopeJob id="HopeJob" fetchData={resumeData}></ResumeFormSectionHopeJob>
      <ResumeFormSectionFamily id="Family" fetchData={resumeData}></ResumeFormSectionFamily>
      <ResumeFormSectionLastUpdate fetchData={resumeData}></ResumeFormSectionLastUpdate>
    </div>
  );
};

type TMenuProps = ComponentProps<typeof NaviInpageMenu>;

const PageMenuContent = (): React.ReactElement => {
  const progressData = usePageResumeProgress();
  if (!progressData) return <></>;
  const menu = [
    ['プロフィール', 'Profile'],
    ['現住所', 'Address'],
    ['学歴', 'Education'],
    ['職歴', 'Career'],
    ['資格・免許', 'Qualification'],
    ['志望動機', 'Statement'],
    ['趣味・特技', 'InterestThings'],
    ['本人希望', 'HopeJob'],
    ['家族について', 'Family'],
  ].map((value, index) => {
    return {
      children: value[0],
      disabled: false,
      done: progressData.progress[index],
      href: value[1],
    };
  });

  const props: TMenuProps = {
    menu,
    progress: progressData.progress,
    type: 'large',
  };
  return <NaviInpageMenu {...props} />;
};
