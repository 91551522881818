import '@/styles/common/Admin/SearchResult/company_module.scss';

import cn from 'classnames';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { ButtonIconButton } from '@/componentsDirect/Parts/Button/IconButton';
import { AdminCompany } from '@/utils/api-client';
import { getOptionChildrenFromValue } from '@/utils/optionValueToChildren';

export function CompanyModule({ data }: { data: AdminCompany }): React.ReactElement {
  const info1 = useMemo(() => {
    return `${getOptionChildrenFromValue(
      'prefecture',
      data.prefecture
    )} / ${getOptionChildrenFromValue(
      'business_type',
      data.business_type
    )} / マイナビワークス担当者：${data.mw_user_id}`;
  }, [data]);

  const info2 = useMemo(() => {
    let content = `企業ID：${data.id} アカウント開設日：${dayjs(data.registration_date).format(
      'YYYY年MM月DD日'
    )}`;
    if (data.last_login)
      content += ` 最終ログイン日：${dayjs(data.last_login).format('YYYY年MM月DD日')}`;
    return content;
  }, [data]);

  return (
    <section
      className={cn('mbx-company_module', 'h-112', 'my-12', {
        'bg-gray-300 border border-gray-500': data.status !== 'active',
      })}
    >
      <Link to={`/admin/companies/${data.id}`} className="p-24 block" target="_blank">
        <div className="flex space-x-16">
          <img src={data.icon} alt="" className="w-64 h-64 block" />
          <div className="flex flex-col justify-between flex-1">
            <h2 className="text-18_24 font-bold">{data.name}</h2>
            <span className="text-10_16">{info1}</span>
            <span className="text-10_16 text-gray-600">{info2}</span>
          </div>
          <div className="flex items-center justify-center">
            <ButtonIconButton type="blue" iconName="Large32_Arrow-right" />
          </div>
        </div>
      </Link>
    </section>
  );
}
