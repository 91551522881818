import React from 'react';

import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { Career } from '@/utils/api-client/api';

export const BusinessContent: React.VFC<{
  className?: string;
  children?: React.ReactElement;
  career: Career;
  type?: 'admin' | 'direct' | 'public';
}> = (props) => {
  const employment_contract = (val: number | undefined) => {
    if (!val) return '';
    return FORM_OPTIONS.employment_contract_type.find((item) => item.value === `${val}`)?.children;
  };

  return (
    <div className={`${props.className}`}>
      <div className={`p-12`}>
        <div className={`flex items-top pb-8`}>
          <div className="w-64 text-body_2 font-bold">事業内容</div>
          <div className="text-body_2 px-4">：</div>
          <div className="flex-1 text-body_2 mkt_mask_items">{props.career.c_detail}</div>
        </div>
        <div className={`flex items-top pb-8`}>
          <div className="w-64 text-body_2 font-bold">従業員数</div>
          <div className="text-body_2 px-4">：</div>
          <div className="flex-1 text-body_2 mkt_mask_items">{props.career.c_employee_count}名</div>
        </div>
        <div className={`flex items-top pb-8`}>
          <div className="w-64 text-body_2 font-bold">資本金</div>
          <div className="text-body_2 px-4">：</div>
          <div className="flex-1 text-body_2 mkt_mask_items">{props.career.c_capital}万円</div>
        </div>
        <div className={`flex items-top pb-8`}>
          <div className="w-64 text-body_2 font-bold">雇用形態</div>
          <div className="text-body_2 px-4">：</div>
          <div className="flex-1 text-body_2 mkt_mask_items">
            {employment_contract(props.career.c_employment_status)}
          </div>
        </div>
        <div className={`flex items-top pb-8`}>
          <div className="w-64 text-body_2 font-bold">部署</div>
          <div className="text-body_2 px-4">：</div>
          <div className="flex-1 text-body_2 mkt_mask_items">{props.career.c_section}</div>
        </div>
        <div className={`flex items-top pb-8`}>
          <div className="w-64 text-body_2 font-bold">役職</div>
          <div className="text-body_2 px-4">：</div>
          <div className="flex-1 text-body_2 mkt_mask_items">{props.career.c_post}</div>
        </div>
      </div>

      {props.children}
      {props.type !== 'direct' && (
        <div className={`p-12`}>
          <div className="text-body_2 font-bold">転職・退職理由</div>
          <div className="text-body_2 mkt_mask_items">{props.career.c_leave_reason}</div>
        </div>
      )}
    </div>
  );
};
