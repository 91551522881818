import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Route } from 'react-router-dom';

import { BasicInfo } from '@/componentsAdmin/Company/Detail/BasicInfo';
import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { TabNavigationMatchUrl } from '@/componentsDirect/Parts/Navigation/TabNavigationMatchUrl';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { CompanyAccount } from '@/pagesAdmin/Companies/Account';
import { CompanyDetail } from '@/pagesAdmin/Companies/Detail';
import { CompanyNotes } from '@/pagesAdmin/Companies/Notes';
import {
    ADMINApi, AdminCompany, AdminNotesResponse, CompanyUser, Status
} from '@/utils/api-client';

type TCompany = {
  company: AdminCompany;
  users: CompanyUser;
};

export const CompanyMain = (): React.ReactElement => {
  const params: { company_id: string } = useParams();
  const [company, setCompany] = useState<TCompany>();
  const [note, setNote] = useState<AdminNotesResponse>();

  useEffect(() => {
    (async () => {
      const res = await new ADMINApi().getAdminCompany(params.company_id);
      setCompany(res.data as TCompany & Status);
      const note = await new ADMINApi().getAdminNotes(Number(params.company_id));
      setNote(note.data);
    })();
  }, []);

  const badge = useMemo(() => {
    if (note?.notes) {
      return note?.notes.some((n) => !n.is_read);
    }
    if (note?.notes) {
      if (note?.notes.length === 0) return false;
    }
    return false;
  }, [note]);

  return (
    <AdminPage header={<HeaderTitle title="企業情報" />}>
      <section>
        <div className="mt-32">{company?.company && <BasicInfo company={company?.company} />}</div>
        <TabNavigationMatchUrl
          className="mt-40"
          align="fit"
          menu={[
            { title: '企業詳細', url: `/admin/companies/${params.company_id}` },
            {
              title: '社内メモ',
              url: `/admin/companies/${params.company_id}/notes`,
              badge: badge,
            },
            { title: '企業アカウント操作', url: `/admin/companies/${params.company_id}/account` },
          ]}
        />
        <Route path="/admin/companies/:company_id" exact component={CompanyDetail}></Route>
        <Route path="/admin/companies/:company_id/notes" exact component={CompanyNotes}></Route>
        <Route path="/admin/companies/:company_id/account" exact component={CompanyAccount}></Route>
      </section>
    </AdminPage>
  );
};
