import { AxiosError } from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { TFormOption } from '@/definition/FORM_OPTIONS';
import { MESSAGE } from '@/definitionAdmin/MESSAGE';
import { notificationError, notificationSuccess, toggleLoading } from '@/reduxAdmin/modules/utils';
import {
  ADMINApi,
  ResponseError,
  UserTag,
  UserTagParam,
  UserTagPatchParam,
  UserTagsPatchParam,
  UserTagToSearchResultBatchSaveParam,
} from '@/utils/api-client/api';

type TReturn = {
  userTags: Array<UserTag> | undefined;
  userTagId: UserTag | undefined;
  userTagOPTIONS: TFormOption[] & {
    active: boolean;
  }[];
  userTagsMatchboxId: string[] | undefined;
  userTagsSearchCondition: string[] | undefined;
  getUserTags: () => Promise<void>;
  getUserTagId: (id: string) => Promise<void>;
  getUserTagsMatchboxId: (id: string) => Promise<void>;
  patchUserTagsMatchboxId: (matchboxId: string, request: UserTagsPatchParam) => Promise<void>;
  patchUserTagId: (idPathParam: string, request: UserTagPatchParam) => void;
  patchUserTags: (request: UserTagsPatchParam) => Promise<void>;
  postUserTag: (request: UserTagParam) => Promise<void>;
  deleteUserTagId: (idPathParam: string) => Promise<void>;
  postUserTagToSearchCondition: (request: UserTagToSearchResultBatchSaveParam) => Promise<void>;
  getUserTagToSearchCondition: (matchboxId: string) => Promise<void>;
};

/**
 * タグ管理関連処理
 */
export const useUserTag = (): TReturn => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const API = new ADMINApi();
  const dispatch = useDispatch();
  const [userTags, setUserTags] = useState<Array<UserTag>>();
  const [userTagId, setUserTagId] = useState<UserTag>();
  const [userTagsMatchboxId, setUserTagsMatchboxId] = useState<string[]>();
  const [userTagsSearchCondition, setUserTagsSearchCondition] = useState<string[]>();
  const [userTagOPTIONS, setUserTagOPTIONS] = useState<TFormOption[] & {
    active: boolean;
  }[]>([
    { value: '', children: '', active: true },
  ]);

  // ====================================================================================================
  //
  // Mutation
  //
  // ====================================================================================================
  // ポジションデータをOPTIONに変換
  const mutationOptionData = (data: Array<UserTag>) => {
    if (data.length > 0) {
      const reactionOptions = data.map((item) => ({
        value: item.id + '',
        children: item.name,
        active: item.active,
      }));

      setUserTagOPTIONS(reactionOptions);
    }
  };

  // ====================================================================================================
  //
  // methods
  //
  // ====================================================================================================
  /**
   * 一覧情報取得
   */
  const getUserTags = () => {
    dispatch(toggleLoading(true));
    return API.getAdminUserTags()
      .then((res) => {
        setUserTags(res.data.items);
        if (res.data.items) mutationOptionData(res.data.items);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 詳細情報取得
   */
  const getUserTagId = (id: string) => {
    dispatch(toggleLoading(true));
    return API.getAdminUserTagId(id)
      .then((res) => {
        setUserTagId(res.data.data);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 詳細情報編集
   */
  const patchUserTagId = (idPathParam: string, request: UserTagPatchParam) => {
    dispatch(toggleLoading(true));
    API.patchAdminUserTagId(idPathParam, request)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.patch_tag));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 求職者タグ情報取得
   */
  const getUserTagsMatchboxId = (matchboxId: string) => {
    return API.getAdminUserTagsMbid(matchboxId)
      .then((res) => {
        if (res.data.items) setUserTagsMatchboxId(res.data.items.map((item) => item.id));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      });
  };

  /**
   * 求職者タグ情報編集
   */
  const patchUserTagsMatchboxId = (matchboxId: string, request: UserTagsPatchParam) => {
    dispatch(toggleLoading(true));
    return API.patchAdminUserTagsMbid(matchboxId, request)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.patch_tag));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
        dispatch(toggleLoading(false));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 並び替え
   */
  const patchUserTags = (request: UserTagsPatchParam) => {
    dispatch(toggleLoading(true));
    return API.patchAdminUserTags(request)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.patch_tags));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 追加
   */
  const postUserTag = (request: UserTagParam) => {
    dispatch(toggleLoading(true));
    return API.postAdminUserTag(request)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.add_tag));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
        dispatch(toggleLoading(false));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 削除
   */
  const deleteUserTagId = (idPathParam: string) => {
    dispatch(toggleLoading(true));
    return API.deleteAdminUserTagId(idPathParam)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.deleted_tag));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
        dispatch(toggleLoading(false));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 検索条件に紐づいたタグを取得
   */
  const getUserTagToSearchCondition = (searchConditionId: string) => {
    return API.getAdminUserTagToSearchResult(searchConditionId)
      .then((res) => {
        if (res.data.items) setUserTagsSearchCondition(res.data.items.map((item) => item.id));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      });
  };

  /**
   * 検索条件にタグを追加
   */
  const postUserTagToSearchCondition = (request: UserTagToSearchResultBatchSaveParam) => {
    dispatch(toggleLoading(true));
    return API.postAdminUserTagToSearchResult(request)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.add_tag));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
        dispatch(toggleLoading(false));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  return {
    userTags,
    userTagId,
    userTagsMatchboxId,
    userTagsSearchCondition,
    userTagOPTIONS,
    getUserTags,
    getUserTagId,
    patchUserTagsMatchboxId,
    getUserTagsMatchboxId,
    patchUserTagId,
    patchUserTags,
    postUserTag,
    deleteUserTagId,
    postUserTagToSearchCondition,
    getUserTagToSearchCondition,
  };
};
