import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    NotificationPDFDownloadSnackBar
} from '@/components/common/Notification/PDFDownloadSnackBar';
import { State } from '@/redux/state';

/**
 * global stateの値検知で表示する汎用通知
 *
 * 仕様 :
 * - PDF生成中に全てのページで表示
 *
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=10595%3A174607&t=DYemNnu5kSHUaJmM-4
 */
export const GlobalPDFDownloadSnackBarPortal = (): React.ReactElement => {
  const isFirstRun = useRef(true);
  const statusOfPDF = useSelector((state: State) => state.portfolioStatusOfPDF);
  const [display, setDisplay] = useState<'full' | 'mini'>('full');

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
  }, []);

  const changeDisplay = (type: 'full' | 'mini') => {
    setDisplay(type);
  };

  return (
    <div
      className={cn('sticky bottom-0 z-20', {
        'sm:z-15': display === 'full',
        'sm:z-20': display === 'mini',
      })}
    >
      <NotificationPDFDownloadSnackBar
        status={statusOfPDF}
        changeDisplay={changeDisplay}
      ></NotificationPDFDownloadSnackBar>
    </div>
  );
};
