import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { PasswordForm } from '@/componentsDirect/Account/PasswordForm';
import { DataLayerPushContainer } from '@/componentsDirect/common/DataLayerPushContainer';
import { AdminPage } from '@/componentsDirect/Layout/Page';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { State } from '@/redux/state';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import {
    DirectApi, PasswordResetParam, RequestTokenCheck, ResponseError
} from '@/utils/api-client';

type IFormInputs = {
  email: string;
};
type Flow = {
  step1: boolean;
  step2: boolean;
  step3: boolean;
};

export const DirectReminder = (): React.ReactElement => {
  const methods = useForm<IFormInputs>({
    defaultValues: {
      email: '',
    },
  });
  const defaultFlow = {
    step1: false,
    step2: false,
    step3: false,
  };
  const { watch } = methods;
  const [pending, setPending] = useState<boolean>(false);
  const [flow, setFlow] = useState<Flow>(defaultFlow);
  const [parameter, setParameter] = useState<string>('');
  const dispatch = useDispatch();
  const loading = useSelector((state: State) => state.loading);

  useEffect(() => {
    (async () => {
      const parameter = window.location.search.substring(1);
      if (!parameter) {
        setFlow({
          step1: true,
          step2: false,
          step3: false,
        });
        return;
      }
      setPending(true);
      setParameter(parameter);
      const token: RequestTokenCheck = {
        token: parameter,
      };
      try {
        await new DirectApi().postDirectPasswordResetValidateToken(token);
        setFlow({
          step1: false,
          step2: false,
          step3: true,
        });
        setPending(false);
      } catch (error) {
        const e = error as AxiosError<ResponseError>;
        if (e.response) dispatch(notificationError(e.response.data.error_message));
      } finally {
        //
      }
    })();
  }, []);

  const reSetting = async () => {
    if (loading) return;
    dispatch(toggleLoading(true));
    const param: PasswordResetParam = {
      email: watch('email'),
    };
    try {
      await new DirectApi().postDirectPasswordReset(param);
      setFlow({
        step1: false,
        step2: true,
        step3: false,
      });
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };
  return (
    <section className="mbxd-reminder">
      <AdminPage header={<HeaderTitle title={'パスワード再設定'} />} disableDataLayerPush>
        <>
          {!pending && (
            <section className="sm:px-24">
              {flow.step1 && (
                <DataLayerPushContainer
                  data={{
                    event: 'pageView',
                    actionType: 'page_view',
                    actionName: 'page_view',
                    virtualPageName: 'step_1',
                  }}
                >
                  <section className="w-308 m-auto mt-48 sm:w-full">
                    <p className="mbx-typography--body_1 text-center">
                      登録したメールアドレスをご入力ください。
                      <br />
                      パスワード再設定用のリンクをお送りします。
                    </p>
                    <FormProvider {...methods}>
                      <form>
                        <FormLayoutFieldset className="mt-24">
                          <FormLabel
                            label="メールアドレス"
                            type="small"
                            className="pt-6"
                          ></FormLabel>
                          <FormContainerTextfield
                            height="large"
                            type="email"
                            name="email"
                            placeholder="例）example@matchbox.com"
                            className="mkt_mask_items"
                          ></FormContainerTextfield>
                        </FormLayoutFieldset>
                      </form>
                    </FormProvider>
                    <BaseButton
                      theme="primary"
                      size="m"
                      className="w-full mt-40 sm:mt-48"
                      disabled={loading}
                      onClick={reSetting}
                    >
                      パスワード再設定のリンクを送信する
                    </BaseButton>
                    <BaseButton
                      theme="link"
                      size="m"
                      href="/direct/login"
                      className="w-full mt-24 sm:mb-64"
                    >
                      ログイン画面へ戻る
                    </BaseButton>
                  </section>
                </DataLayerPushContainer>
              )}
              {flow.step2 && (
                <DataLayerPushContainer
                  data={{
                    event: 'pageView',
                    actionType: 'page_view',
                    actionName: 'page_view',
                    virtualPageName: 'step_2',
                  }}
                >
                  <section className="w-308 m-auto text-center">
                    <p className="mbx-typography--body_1 mt-48">
                      パスワード再設定用のリンクを送信しました。 メールをご確認ください。
                      なお、リンクの有効期限は発行後、24時間です。
                    </p>
                    <BaseButton
                      size={'m'}
                      theme={'link'}
                      href={'/direct/login'}
                      className="mt-64 sm:mb-64"
                    >
                      ログイン画面へ
                    </BaseButton>
                  </section>
                </DataLayerPushContainer>
              )}
              {flow.step3 && (
                <DataLayerPushContainer
                  data={{
                    event: 'pageView',
                    actionType: 'page_view',
                    actionName: 'page_view',
                    virtualPageName: 'step_3',
                  }}
                >
                  <div className="sm:pb-64">
                    <PasswordForm token={parameter} />
                  </div>
                </DataLayerPushContainer>
              )}
            </section>
          )}
        </>
      </AdminPage>
    </section>
  );
};
