import React from 'react';

export const Id1 = (): React.ReactElement => {
  const style = {
    img: 'mt-80 md:-mx-16 sm:-mx-16 sm:mt-40',
    text_l: 'mt-160 text-24_24 font-bold sm:mt-80 sm:text-18_24',
    text_m: 'text-18_28 font-bold mt-40 sm:text-15_22 sm:mt-20',
    text_s: 'text-16_28 sm:text-14_21',
  };
  return (
    <section>
      <div className="text-center pt-90 sm:pt-50">
        <p className="inline-block pb-8 text-18_18 font-bold border-b border-black sm:text-14_14">
          <span>01.</span>
          <span className="ml-18 sm:ml-14">Webデザイナー M.H.さん</span>
        </p>
        <h2 className="mt-18 text-29_46 font-bold sm:text-21_31">
          ポートフォリオで語る、自分の役割と仕事のプロセス
        </h2>
      </div>
      <div className={style.img}>
        <img src="../images/Top/interview/interview01_image1.jpg" alt="" />
      </div>
      <div className="mt-40 sm:mt-20">
        <p className={style.text_s}>
          デザイン系の専門学校を卒業後、WebデザイナーとしてキャリアをスタートさせたM.H.さん。幾度かの転職を経て、Web以外にも販促ツールや広告など、多岐にわたりデザイナーとしての経験値を積み上げてきた。
          <br />
          <br />
          そんな中、さらなる活躍の場を求め転職活動を始めたが、自己流のポートフォリオでは書類選考が通らず、悔しい思いをした時期があったという。そこでキャリアアドバイザーの助言を受け、ポートフォリオの内容を一新すると、書類選考の通過率は格段に上がっていった。一体ポートフォリオにどんな手を加えたのか。M.H.さんのポートフォリオを劇的に変えた理由に迫った。
        </p>
      </div>
      <h3 className={style.text_l}>書類選考の通過率を上げるポートフォリオの作り方</h3>
      <p className={style.text_m}>―― 今回、転職をしようと思ったきっかけを教えてください。</p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          これまでは、Webサイトのデザインをはじめ、広告、什器、企業ブランディングなど、さまざまなデザインに関わってきました。その中でもWebデザインが持つ、より理論的な考え方が自分に合っていると思い、面白みを感じていたんです。それで次はUI・UXなど、作ったサービスをさらに育てていける環境でWebデザイナーとして挑戦したいと思って転職を決めました。
        </p>
      </div>
      <div className={style.img}>
        <img src="../images/Top/interview/interview01_image2.jpg" alt="" />
      </div>
      <p className={style.text_m}>
        ―― ポートフォリオはどのような点を意識して作成したのでしょうか。
      </p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          最初は、成果物を見せることが大切だと思っていたので、成果物をベースに制作者のクレジットを載せる構成で作成していました。ただ、書類選考がほとんど通らなくて......。そこで客観的なアドバイスがほしいと思い転職エージェントに相談したところ、「今のままだと、この案件に関わったという事実しか伝わらない」という指摘を受け、ハッとしたんです。案件の中で自分がデザイナーとして具体的になにをしたのかが抜けていたんですね。それで、どうしたらこれまでの自分の仕事に対するスタンスが伝えられるか考え、ポートフォリオを修正しました。そうしたら、書類の通過率がどんどん上がっていったんです。
        </p>
      </div>
      <p className={style.text_m}>―― 具体的にはどのような修正をしたのでしょうか。</p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          まず、案件に対して、自分がどういう立ち回りをしていたのか、しっかり書き込むことを意識しました。クライアント企業にはどういう課題があって、それに対して自分はデザイナーの立場からどんな工夫をし、解決策を提案したのか。思考のフローをしっかりとポートフォリオに盛り込んだのです。
          <br />
          <br />
          また、20代後半という自分の年齢を考えると、デザイナーとしての役割以外にも求められることが多くなると思いました。なので、Webディレクターを兼務していたり、ADとしてビジュアルのクオリティチェックに入った案件など、デザイン以外にもプロジェクトに対して包括的に関わった案件は、なるべく多めに選ぶようにしましたね。
        </p>
      </div>
      <p className={style.text_m}>
        ―― 改めて、ポートフォリオを作るうえで大切なことはどのようなことだと感じましたか。
      </p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          実は見た目よりも、自分がなにをしたのか解説を書くことが大事なんだと実感しました。交通広告などは派手だし、一見すごいって思いがちなんですが、内容的に、自分がその案件に対してどう関わったのかが書いていなければ意味がないのかなと思います。
        </p>
      </div>
      <div className={style.img}>
        <img src="../images/Top/interview/interview01_image3.jpg" alt="" />
        <p className="md:px-16 sm:px-16">
          <span className="text-14_14 text-gray-500">
            実際にM.H.さんが制作したポートフォリオ。案件ごとに自身の関わり方が細かく記載されている。
          </span>
        </p>
      </div>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          面接でお会いした方たちの中には、「ポートフォリオの内容が詳しく書かれていたので、どんな人なのかぜひお会いしたい」「これだけていねいに説明できる人となら一緒に働いてみたい」とポートフォリオの内容に触れてくれる方もいました。ポートフォリオを通じて私のことを見てくれていたんだ、そこまで言ってくれるんだと、本当にうれしかったです。
        </p>
      </div>
      <h3 className={style.text_l}>
        ポートフォリオ作成にも取り入れたい、「わからない人」に伝えようとする意識
      </h3>
      <p className={style.text_m}>
        ――
        M.H.さんのポートフォリオは内容が詳しく言語化されているのが印象的でした。「言葉で伝える」ということに対して日常的に意識していることはあるのでしょうか。
      </p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          なにもわからない人に向けて話すにはどうしたらいいかというのを常に考えながらコミュニケーションしています。お互いに共有認識があるからこそ通じるコミュニケーションってあると思うのですが、そもそもなにも知らない人に説明するためには、まずはその共通認識から説明しなくてはいけません。たとえばWebの話がわからない人にUIやUXの話をしても、うまく通じ合えないと思うんです。でもクライアントの中には、そういう人が一定数存在するので、その人に伝えるためにはどうしたらいいか、どんな言葉を使って例えればいいかとかは考えちゃいますね。もしかしたら、その意識が言語化のトレーニングになっていて、ポートフォリオにも反映されていたのかもしれません。
        </p>
      </div>
      <div className={style.img}>
        <img src="../images/Top/interview/interview01_image4.jpg" alt="" />
      </div>
      <p className={style.text_m}>―― 現在はどのような業務をされていますか？</p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          自社サービスから受託開発まで幅広く携われる会社で、現在はWebチームに所属し、親会社のサービスサイトをよりよくしていくための開発に取り組んでいます。数値をもとに改善点を洗い出し、どちらがいいかABテストをするなど、日々検証を繰り返していますね。まさしくこういった理論的に考える仕事がしたかったのでやりがいがあります。よりユーザーに届きやすいものとはなんなのか、具体的な数値を見ながら考えるのは楽しいです。
        </p>
      </div>
      <p className={style.text_m}>―― 最後に、今後の目標について教えてください。</p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          今後は、UIデザインをマスターしたいです。Webサービスは作ったら終わりではなく育てていくものだと思っているので、ユーザーがより使いやすいと感じられるWebサイトやサービス作りにWebデザイナーとして関わっていきたいと思います。また、今回の転職では、ポートフォリオを通して自分を高く評価していただき、チームリーダーとしての責任が求められるポジションでの採用になりました。この機会を大事に、今後は個人の目標を達成するだけではなく、チームとしての評価も得られるよう、がんばっていきたいですね。
        </p>
      </div>
    </section>
  );
};
