import '../styles/App.scss';

import React, { useEffect } from 'react';

import { BaseButton } from '@/components/common/Button/';

import * as Page from '../components/common/Page';

function App({ presentation }: { presentation?: boolean }): React.ReactElement {
  useEffect(() => {
    //
  }, []);

  return (
    <Page.Wrapper presentation={presentation} header={<></>} footer={<></>} isErrorPage>
      <Page.Column
        main={
          <section>
            <h1 className="text-heading_2 font-bold mb-24 mt-104">
              お探しのページが見つかりませんでした
            </h1>
            <p className="text-body_1 mb-40">
              アクセスいただいたページは、削除されたか現在利用できない可能性があります。
              <br />
              お手数ですがトップページから再度お探しください。
            </p>
            <BaseButton className="sm:mb-212" size="m" theme="primary" type="button" href="/">
              トップページへ
            </BaseButton>
          </section>
        }
        menu={<></>}
      />
    </Page.Wrapper>
  );
}

export default App;
