import '@/styles/common/Admin/UserDetail/basic_info.scss';

import cn from 'classnames';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';

import { BasicInfo } from '@/componentsDirect/Parts/User/Module/BasicInfo';
import { Motivation } from '@/componentsDirect/Parts/User/Module/Motivation';
import { AdminUserInfoModel, Career, LastLogin, MyNavi } from '@/utils/api-client';
import { getOptionChildrenFromValue, optionValueToChildren } from '@/utils/optionValueToChildren';
import { getAge } from '@/utils/string';

type Props = {
  matchboxId: string | undefined;
  lastLogin: LastLogin;
  registrationDate: string;
  lastUpdateUseDirect?: string;
  mcInfo: MyNavi | undefined;
  userInfo: AdminUserInfoModel;
  userEntryId?: string;
  career?: Career;
  isDirect?: boolean;
};

export function BasicInfoComponent({
  matchboxId,
  lastLogin,
  registrationDate,
  lastUpdateUseDirect,
  mcInfo,
  userInfo,
  userEntryId,
  career,
  isDirect,
}: Props): React.ReactElement {
  const [toolTip, setTooltip] = useState({ users: false, portfolio: false, cv: false });
  const [toolTipText, setTooltipText] = useState('URLをコピー');

  const prefecture = getOptionChildrenFromValue('prefecture', userInfo.prefecture);
  const gender = userInfo.gender
    ? getOptionChildrenFromValue('gender', userInfo.gender)
    : getOptionChildrenFromValue('gender', 0);
  const situation =
    mcInfo && mcInfo.mc_situation
      ? optionValueToChildren(Number(mcInfo.mc_situation), [
          {
            value: '2',
            children: '就業中',
          },
          {
            value: '1',
            children: '離職中',
          },
        ])
      : undefined;
  // オプショナル扱いだけど本来は生年月日は必須項目でいいはず？
  const age = getAge(
    userInfo.birth_year ? userInfo.birth_year : 0,
    userInfo.birth_month ? userInfo.birth_month : 0,
    userInfo.birth_day ? userInfo.birth_day : 0
  );

  const useDirect = (value: boolean) => {
    return value ? '有' : '無';
  };

  const profileItems = useMemo<string[]>(() => {
    const items: string[] = [];
    if (prefecture) items.push(`${prefecture}在住`);
    if (gender) items.push(gender);
    if (age) items.push(`${age}歳`);
    if (mcInfo && mcInfo.mc_yearly_income)
      items.push(`現年収${getOptionChildrenFromValue('yearly_income', mcInfo.mc_yearly_income)}`);
    if (situation) items.push(situation);
    return items;
  }, [prefecture, gender, age, situation]);

  const jobInfoItems = useMemo<string[]>(() => {
    const items: string[] = [];
    if (career?.c_company_name) items.push(`直近の職歴：${career?.c_company_name}`);
    if (career?.c_employment_status)
      items.push(
        getOptionChildrenFromValue('employment_contract_type', career?.c_employment_status)
      );
    return items;
  }, []);

  const onClick = (url: string) => {
    navigator.clipboard.writeText(url);
    setTooltipText('URLをコピーしました');
  };

  const onMouseOver = (tip: 'users' | 'portfolio' | 'cv') => {
    const toolTips = { users: false, portfolio: false, cv: false };
    toolTips[tip] = true;
    setTooltip(toolTips);
  };

  const onMouseOut = () => {
    setTooltip({ users: false, portfolio: false, cv: false });
    setTooltipText('URLをコピー');
  };

  return (
    <section className="mbx-basic_info">
      <section className="flex items-center whitespace-nowrap">
        {/* 必ず数値がくる想定の実装になってるけどそうなのか？？ */}
        {mcInfo?.mc_preferred_change_date !== 0 && mcInfo?.mc_preferred_change_date && (
          <Motivation changeDate={mcInfo ? mcInfo.mc_preferred_change_date : 1} />
        )}
        <BasicInfo
          className={cn({ ['ml-12']: mcInfo?.mc_preferred_change_date })}
          items={profileItems}
          color="blue"
          size="m"
        />
      </section>

      <div className="mt-12 flex space-x-8 items-center">
        {userInfo.name ? (
          <>
            <img
              src={userInfo.icon && userInfo.icon ? userInfo.icon : '/images/profile_icon.png'}
              className="w-24 h-24 block mkt_mask_items"
              alt=""
            />
            <h3 className="text-18_24 font-bold mkt_mask_items">
              {userInfo.name}
              {mcInfo?.mc_experienced_job &&
                mcInfo?.mc_experienced_year &&
                `：${getOptionChildrenFromValue(
                  'preferred_job_type',
                  mcInfo.mc_experienced_job
                )} 経験${getOptionChildrenFromValue(
                  'experienced_year',
                  mcInfo.mc_experienced_year
                )}`}
            </h3>
          </>
        ) : (
          <h3 className="text-18_24 font-bold mkt_mask_items">
            {matchboxId}
            {mcInfo?.mc_experienced_job &&
              mcInfo?.mc_experienced_year &&
              `：${getOptionChildrenFromValue(
                'preferred_job_type',
                mcInfo.mc_experienced_job
              )} 経験${getOptionChildrenFromValue('experienced_year', mcInfo.mc_experienced_year)}`}
          </h3>
        )}
      </div>

      <BasicInfo className="mt-6 mkt_mask_items" items={jobInfoItems} size="s" />
      {!isDirect && (
        <p className="mt-6 text-gray-600 text-10_12 space-x-16">
          <span>MB ID：{matchboxId}</span>
          <span>連携 ID：{userEntryId}</span>
          {userInfo.furigana && <span>氏名ふりがな：{userInfo.furigana}</span>}
        </p>
      )}
      <p className={cn('text-gray-600 text-10_12 space-x-16', !isDirect ? 'mt-4' : 'mt-6')}>
        <span className="">アカウント開設日：{dayjs(registrationDate).format('YYYY/M/D')}</span>
        {lastLogin && (
          <span className="">
            最終ログイン日：{dayjs(lastLogin?.login_date).format('YYYY/M/D')}
          </span>
        )}
      </p>
      {!isDirect && (
        <>
          <p className="mt-4 text-gray-600 text-10_12 space-x-16">
            <span>オファー機能登録：{useDirect(Boolean(userInfo.use_direct))}</span>
            {lastUpdateUseDirect &&
              <span>最終オファー登録日：{dayjs(lastUpdateUseDirect).format('YYYY/M/D')}</span>
            }
          </p>
          <p className="mt-4 text-gray-600 text-10_12 space-x-16">
            <span>
              企業向け求職者情報URL：{location.origin}/direct/users/{matchboxId}
              <span
                className="cursor-pointer direct-url ml-4"
                onClick={() => onClick(`${location.origin}/direct/users/${matchboxId}`)}
                onMouseOver={() => onMouseOver('users')}
                onMouseOut={() => onMouseOut()}
              >
                <i className="mbx-icon mbx-icon-Copy text-14_21"></i>
                {toolTip.users && (
                  <div className="tool-tip">
                    <p className="mbx-typography--caption_2 text-center">{toolTipText}</p>
                  </div>
                )}
              </span>
            </span>
          </p>
          <p className="mt-4 text-gray-600 text-10_12 space-x-16">
            <span>
              企業向けポートフォリオURL：{location.origin}/direct/portfolio/{matchboxId}
              <span
                className="cursor-pointer direct-url ml-4"
                onClick={() => onClick(`${location.origin}/direct/portfolio/${matchboxId}`)}
                onMouseOver={() => onMouseOver('portfolio')}
                onMouseOut={() => onMouseOut()}
              >
                <i className="mbx-icon mbx-icon-Copy text-14_21"></i>
                {toolTip.portfolio && (
                  <div className="tool-tip">
                    <p className="mbx-typography--caption_2 text-center">{toolTipText}</p>
                  </div>
                )}
              </span>
            </span>
          </p>
          <p className="mt-4 text-gray-600 text-10_12 space-x-16">
            <span>
              企業向け職務経歴URL：{location.origin}/direct/cv/{matchboxId}
              <span
                className="cursor-pointer direct-url ml-4"
                onClick={() => onClick(`${location.origin}/direct/cv/${matchboxId}`)}
                onMouseOver={() => onMouseOver('cv')}
                onMouseOut={() => onMouseOut()}
              >
                <i className="mbx-icon mbx-icon-Copy text-14_21"></i>
                {toolTip.cv && (
                  <div className="tool-tip">
                    <p className="mbx-typography--caption_2 text-center">{toolTipText}</p>
                  </div>
                )}
              </span>
            </span>
          </p>
        </>
      )}
    </section>
  );
}
