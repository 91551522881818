import React, { ReactNode, useEffect, useRef, useState, VFC } from 'react';
import {
  EventType,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormProps,
  UseFormReturn,
  FieldValues,
} from 'react-hook-form';
import { useSelector } from 'react-redux';

import { State } from '@/redux/state';
import { TPagesResumeFormValue } from '@/types/Pages/Resume';

export interface TCallback {
  (
    value: {
      [x: string]: unknown;
    },
    name?: string,
    type?: EventType
  ): void;
}

type TProps = {
  useFormProps: UseFormProps;
  onSubmit?: SubmitHandler<TPagesResumeFormValue>;
  onPatch?: TCallback;
  fetchData?: unknown;
  children: ReactNode;
  watchInitData?: boolean;
  methods?: UseFormReturn<FieldValues, Record<string, unknown>>;
};
/**
 * React-Hook-Formでフォーム作成する際のお決まりのセットをまとめたもの
 */
export const FormContainer: VFC<TProps> = ({
  useFormProps,
  children,
  onSubmit = (data) => {
    console.log(data);
  },
  onPatch,
  fetchData,
  watchInitData = true,
  methods: propsMethods,
}) => {
  const user = useSelector((state: State) => state.user);
  const inputClearingInProgress = useSelector((state: State) => state.inputClearingInProgress);

  // refの定義
  const refInputClearingInProgress = useRef(inputClearingInProgress);
  // redux stateが更新されたらrefも更新するようにする
  useEffect(() => {
    refInputClearingInProgress.current = inputClearingInProgress;
  }, [inputClearingInProgress]);

  const methods = propsMethods ? propsMethods : useForm(useFormProps);
  const { handleSubmit, watch } = methods;

  // fetchDataの値設定時にはonPatchを走らせないためのフラグ
  const [fetchDataLoading, setFetchDataLoading] = useState(true);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // fetchDataLoadingのuseStateの値は切り替わらないので useEffect の監視対象にしている
      if (!watchInitData && fetchDataLoading) {
        return;
      }
      if (onPatch) {
        onPatch(value, name, refInputClearingInProgress.current ? 'change' : type);
      }
    });
    return () => subscription.unsubscribe();
  }, [user, fetchDataLoading]);

  useEffect(() => {
    if (!fetchData && !watchInitData) return;

    // fetchDataの更新ではPatch処理を動かしたくないので処理中はフラグを立てる
    setFetchDataLoading(true);

    if (useFormProps.defaultValues) {
      Object.keys(useFormProps.defaultValues).forEach((v) => {
        if (useFormProps.defaultValues?.[v]) {
          methods.setValue(v, useFormProps.defaultValues?.[v]);
        }
      });
    }

    // fetchData更新終わり
    setFetchDataLoading(false);
  }, [fetchData]);

  return (
    <FormProvider {...(methods as UseFormReturn<FieldValues, Record<string, unknown>>)}>
      <form onSubmit={handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};
