import React, { useEffect } from 'react';

import BaseButton from '../Button/BaseButton';
import Indicator from './Indicator';
import RequestTitle from './RequestTitle';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
import { useSelector, useDispatch } from 'react-redux';
import { setDataLayer, setDataLayerChangedBy } from '@/redux/index';
import { State } from '@/redux/state';
import { DataLayerType } from '@/types/DataLayerType';

function Step3(): React.ReactElement {
  const dispatch = useDispatch();
  const dataLayer:DataLayerType = useSelector((state: State) => state.dataLayer);

  useEffect(() => {
    if (dataLayer.mbOfferStatus === undefined || dataLayer.mbOfferStatus === 'off') {
      dispatch(setDataLayer({ mbOfferStatus: 'on' }));
      dispatch(setDataLayerChangedBy('matchbox_offer_stop'));
    }
  }, []);
  return (
    <DataLayerPushContainer
      data={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'page_view',
        virtualPageName: 'step_3',
        mbOfferStatus: 'on',
      }}
    >
      <section className="mb-64">
        <section className="flex justify-center mt-48">
          <Indicator step={3} />
        </section>
        <RequestTitle title="登録が完了しました">
          <>
            オファー機能への登録が完了しました。
            <br />
            企業からのオファーをお待ちください。
          </>
        </RequestTitle>
        <div className="flex justify-center mt-64 sm:mb-212">
          <BaseButton theme="link" size="m" href="/mypage">
            ホームへ戻る
          </BaseButton>
        </div>
      </section>
    </DataLayerPushContainer>
  );
}

export default Step3;
