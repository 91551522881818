import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import ImgIcon from '@/assets/images/profile/circle-placeholder.svg';
import { ButtonIconButton } from '@/componentsDirect/Parts/Button/';
import {
  FormContainerDropdown,
  FormContainerDropdownMultiple,
} from '@/componentsDirect/Parts/Form/';
import { TFormOption } from '@/definition/FORM_OPTIONS';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';
import { RootState } from '@/reduxAdmin';
import { CompanyGroupSummary, CompanyUser, DirectUserResponse } from '@/utils/api-client';
import { noDataText } from '@/utils/string';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type TProps = {
  index: number;
  user: CompanyUser;
  hasSingleManagerUserType: boolean;
  isSingleUser: boolean;
  onDelete: (matchbox_id: string) => boolean;
  userGroups?: Array<CompanyGroupSummary>;
};

/**
 * ユーザーリストデータ
 * TableContainerと共に利用する
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4228%3A61118
 */
export const TableBodyUserList = ({
  user,
  index,
  hasSingleManagerUserType,
  isSingleUser,
  onDelete,
  userGroups,
}: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const isSingleManagerUserType = useMemo(() => {
    if (hasSingleManagerUserType && user.user_type === 'direct_manager') {
      return true;
    } else {
      return false;
    }
  }, [hasSingleManagerUserType]);
  const { listeners, setNodeRef, transform, transition, attributes } = useSortable({
    id: user.matchbox_id ? user.matchbox_id : index.toString(),
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const userInfo: DirectUserResponse | null = useSelector(
    (state: RootState) => state.direct_auth.userInfo
  );
  const isManager = /^direct_manager$/.test(userInfo?.user_info.user_type as string);

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const userGroupOptions = useMemo(() => {
    if (!userGroups) return [];
    const options = userGroups?.map((v) => {
      return {
        value: v.id.toString(),
        children: v.name,
      };
    });

    return options as TFormOption[];
  }, [userGroups]);

  return (
    <tr className="text-left " ref={setNodeRef} style={style}>
      {/* ユーザー名 */}
      <td className="pl-16 border-b border-gray-200">
        <div className="flex items-center">
          <div className="w-24 h-24 mr-8">
            <img
              src={user.photo ? user.photo : ImgIcon}
              alt={user.name}
              className="rounded-full block mkt_mask_items"
            />
          </div>
          <span className="text-subhead_2 mkt_mask_items">{noDataText(user.name)}</span>
        </div>
      </td>
      {/* メールアドレス */}
      <td className="pl-16 text-subhead_2 border-b border-gray-200">
        <span className="mkt_mask_items line-clamp-2">{noDataText(user.email)}</span>
      </td>
      {/* 所属グループ */}
      <td className="pl-16 text-subhead_2 border-b border-gray-200">
        <FormContainerDropdownMultiple
          name={`users.${index}.groups`}
          placeholder="選択してください（複数選択可）"
          selectList={userGroupOptions}
          className="mkt_mask_items"
          lineClamp={1}
        />
      </td>
      {/* アカウント種別 */}
      <td className="pl-16 border-b border-gray-200 py-20">
        <FormContainerDropdown
          defaultValue={user.user_type}
          name={`users.${index}.user_type`}
          selectList={DIRECT_FORM_OPTIONS.user_type}
          disabled={isSingleUser || isSingleManagerUserType}
          className="mkt_mask_items"
          readOnly={!isManager}
        />
      </td>
      {/* 削除機能 */}
      {isManager && (
        <td className="text-right pl-24 pr-16 border-b border-gray-200">
          <span className="flex items-center">
            <span
              className="dragHandle"
              {...listeners}
              {...attributes}
              data-cypress="draggable-handle"
            >
              <ButtonIconButton type="gray" iconName="Sortable" />
            </span>
            <ButtonIconButton
              type="gray"
              iconName="Clear"
              onClick={(e) => {
                if (isSingleUser || isSingleManagerUserType) return;
                e.preventDefault();
                if (user.matchbox_id) onDelete(user.matchbox_id);
              }}
              disabled={isSingleUser || isSingleManagerUserType}
            />
          </span>
        </td>
      )}
    </tr>
  );
};
