import React from 'react';
import { OnboardingPageWrapper } from './PageWrapper';
import { PageDots } from './PageDots';
import BaseButton from '@/components/common/Button/BaseButton';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const OnboardingPage_6 = (props: Props): React.ReactElement => {

  return (
    <DataLayerPushContainer
      data={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'modal_open',
        virtualPageName: 'on-boarding_step_6'
      }}
    >
      <div className="flex justify-center items-center sm:block">
        <OnboardingPageWrapper
          description={
            <span>
              さあ、さっそくポートフォリオを
              <br />
              作ってみましょう。
            </span>
          }
        >
          <div>
            <div className="w-225 mt-48 m-auto sm:w-full">
              <BaseButton
                theme="primary"
                size="m"
                className="w-full"
                href="/mypage/portfolio/edit"
              >
                ポートフォリオを作る
              </BaseButton>
            </div>
            <div className="mt-48">
              <p className="text-16_16 font-bold">作品がない方はこちらから</p>
            </div>
            <div className="flex mt-24">
              <div className="w-200 sm:w-full">
                <BaseButton
                  theme="secondary"
                  size="m"
                  className="w-full"
                  href="/mypage/resume/edit"
                >
                  履歴書を作る
                </BaseButton>
              </div>
              <div className="w-200 ml-16 sm:ml-8 sm:w-full">
                <BaseButton theme="secondary" size="m" className="w-full" href="/mypage/cv/edit">
                  職務経歴書を作る
                </BaseButton>
              </div>
            </div>
          </div>
        </OnboardingPageWrapper>
      </div>
      <div className="pb-20 mb-48 sm:mb-72">
        <PageDots page={props.page} setPage={props.setPage} unClick className="mt-48" />
      </div>
    </DataLayerPushContainer>
  );
};
