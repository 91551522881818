import React from 'react';
import '@/styles/common/Admin/UserDetail/message_form_sent.scss';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { RootState } from '@/reduxAdmin';
import cn from 'classnames';
import { AdminUserResponse, AdminNoteModel } from '@/utils/api-client';

type Props = {
  onRemove(id: number): void;
  note: AdminNoteModel;
};

export const AdminNoteMessage = (props: Props): React.ReactElement => {
  const user: AdminUserResponse | null = useSelector(
    (state: RootState) => state.admin_auth.userInfo
  );
  const onRemove = (id: number | undefined) => {
    if (!id) return;
    props.onRemove(id);
  };
  return (
    <section
      className={cn('mbx-message_form_sent', 'space-x-8', { 'bg-gray-50': props.note.is_read })}
    >
      <div className="mbx-message_form_sent-body space-y-4">
        <header className="space-x-4">
          <strong className="text-12_18 font-bold">{props.note.user?.name}</strong>
          <span className="text-10_16 text-gray-600 font-bold">{props.note.user?.section}</span>
          <span className="text-10_16 text-gray-600 font-bold">{props.note.user?.post}</span>
          <small className="text-10_16 text-gray-600">
            {dayjs(props.note.created_at).format('YYYY年MM月DD日')}
          </small>
          {user?.user_info?.admin_id === props.note.user?.admin_id && (
            <a
              className="text-10_16 text-blue-700 underline"
              onClick={() => onRemove(props.note.id)}
            >
              削除
            </a>
          )}
        </header>
        <p className="text-12_20">{props.note.content}</p>
      </div>
    </section>
  );
};
