import React, { useEffect, useMemo, useState } from 'react';
import { FieldArrayWithId, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import {
  DirectUsersAddUserComponent,
  DirectUsersDeleteUserComponent,
  DirectUsersFilters,
} from '@/componentsDirect/CompanyUsers';
import { AdminPage } from '@/componentsDirect/Layout/Page';
import { TableBodyUserList, TableContainer, TableHeader } from '@/componentsDirect/Parts/Table';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { useCompanyUserGroups } from '@/hooksDirect/useCompanyUserGroups';
import { useCompanyUsers } from '@/hooksDirect/useCompanyUsers';
import { RootState } from '@/reduxAdmin';
import { CompanyUser, DirectUserResponse, InlineObject9 } from '@/utils/api-client';
import { moveArray } from '@/utils/utils';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

type TFormValues = {
  users: Array<CompanyUser>;
};

export type TFilterValues = {
  user_name?: string;
  mail?: string;
  group?: string;
  user_type?: 'direct_manager' | 'direct_user' | 'direct_hr';
};

/**
 * 社内ユーザー管理 /direct/users
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A66093
 *
 * 利用責任者は1人以上必要
 */
export const DirectCompanyUsersIndex = (): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const mq = useMBXMediaQuery();
  const userInfo: DirectUserResponse | null = useSelector(
    (state: RootState) => state.direct_auth.userInfo
  );

  const [filterValues, setFilterValues] = useState<TFilterValues>({
    user_name: '',
    mail: '',
    group: '',
    user_type: undefined,
  });
  const [userDeleteState, setUserDeleteState] = useState({
    matchbox_id: '',
    showModal: false,
  });
  const { companyUserGroups, getCompanyUserGroups } = useCompanyUserGroups();
  const { companyUsers, getCompanyUsers, patchCompanyUserId, patchCompanyUsers } =
    useCompanyUsers();

  const methods = useForm<TFormValues>();
  const { control, watch } = methods;
  const { fields, move } = useFieldArray({
    control,
    name: 'users',
  });

  const loading = useSelector((state: RootState) => state.utils.loading);

  const isManager = /^direct_manager$/.test(userInfo?.user_info.user_type as string);

  const [singleManagerUserType, setSingleManagerUserType] = useState(false);

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const tableConfig = useMemo(() => {
    if (!userInfo?.user_info.user_type) return [];
    if (isManager) {
      return [
        {
          title: 'ユーザー名',
          className: 'w-160',
        },
        {
          title: 'メールアドレス',
        },
        {
          title: '所属グループ',
          className: 'w-270',
        },
        {
          title: 'アカウント種別',
          className: 'w-140',
        },
        {
          title: '',
        },
      ];
    } else {
      return [
        {
          title: 'ユーザー名',
          className: 'w-160',
        },
        {
          title: 'メールアドレス',
          className: 'w-220',
        },
        {
          title: '所属グループ',
        },
        {
          title: 'アカウント種別',
          className: 'w-100',
        },
      ];
    }
  }, [userInfo]);

  // ====================================================================================================
  //
  // methods
  //
  // ====================================================================================================
  /**
   * direct_managerが一名の場合は該当ユーザーのuser_typeを変更できないようにするためのチェック処理
   */
  const hasSingleManagerUserType = () => {
    if (!methods.getValues().users) return false;
    const managerUserTypes = methods
      .getValues()
      .users.map((user) => {
        return user.user_type;
      })
      .filter((userType) => userType === 'direct_manager');
    return managerUserTypes.length === 1;
  };

  /**
   * ユーザーが1人かどうか、1人の時は削除不可に
   */
  const isSingleUser = () => {
    if (!methods.getValues().users) return false;
    return methods.getValues().users.length === 1;
  };

  /**
   * 削除処理
   */
  const onDelete = (matchbox_id: string) => {
    setUserDeleteState({ matchbox_id, showModal: true });
    return false;
  };

  const onSort = (result: FieldArrayWithId<TFormValues, 'users', 'id'>[]) => {
    // idのみの配列に変換
    const request = {
      users: result.map((v) => v.matchbox_id),
    };
    patchCompanyUsers(request as InlineObject9);
  };

  /**
   * DnD 並び替え
   */
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  /**
   * DnD 並び替え
   */
  const touchSensors = useSensors(
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  /**
   * DnD 並び替え
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragEnd = ({ active, over }: any) => {
    if (active.id !== over.id) {
      const oldIndex = fields.map((field) => field.matchbox_id).indexOf(active.id);
      const newIndex = fields.map((field) => field.matchbox_id).indexOf(over.id);
      move(oldIndex, newIndex);
      onSort(moveArray(fields, oldIndex, newIndex));
    }
  };

  const fetchGetCompanyUsers = () => {
    getCompanyUsers({
      user_name: filterValues.user_name,
      mail: filterValues.mail,
      group: filterValues.group,
      user_type: filterValues.user_type,
    });
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    if (companyUsers) {
      methods.setValue('users', companyUsers);
    }
    setSingleManagerUserType(hasSingleManagerUserType());
  }, [companyUsers]);

  useEffect(() => {
    // グループ一覧
    getCompanyUserGroups();
    // 一覧取得
    fetchGetCompanyUsers();

    // 変更検知で編集処理
    const subscription = watch((value, { name }) => {
      // データがない場合は処理しない
      if (!name || !value.users || name === 'users') return;
      const names = name.split('.'); // e.g : users.0.user_type
      const userData = value.users[Number(names[1])];
      const data = {
        groups: userData.groups,
        user_type: userData.user_type,
      };
      if (userData.matchbox_id) {
        patchCompanyUserId(userData.matchbox_id, data);
        setSingleManagerUserType(hasSingleManagerUserType());
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (loading) return;
    fetchGetCompanyUsers();
  }, [filterValues]);

  return (
    <AdminPage header={<HeaderTitle title="社内ユーザー管理" />}>
      <>
        {isManager && (
          <div className="flex justify-end py-16">
            <DirectUsersAddUserComponent
              getCompanyUsers={getCompanyUsers}
              filterValues={filterValues}
            />
          </div>
        )}
        <DirectUsersDeleteUserComponent
          setUserDeleteState={setUserDeleteState}
          showModal={userDeleteState.showModal}
          getCompanyUsers={getCompanyUsers}
          filterValues={filterValues}
          matchboxId={userDeleteState.matchbox_id}
        />

        <section className="mt-45 mb-16 flex items-center justify-between">
          <p className="mbx-typography--body_2 line-clamp-1">{fields.length}件</p>
          <DirectUsersFilters setFilterValues={setFilterValues} userGroups={companyUserGroups} />
        </section>
        <FormProvider {...methods}>
          <form className="pb-80">
            <TableContainer className="w-full">
              <TableHeader columns={tableConfig}></TableHeader>
              <tbody>
                <DndContext
                  sensors={mq.sm ? touchSensors : sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  autoScroll={false}
                >
                  <SortableContext
                    items={fields.map((item) => item.matchbox_id as string)}
                    strategy={verticalListSortingStrategy}
                  >
                    {fields.map((user, index) => {
                      return (
                        <TableBodyUserList
                          key={user.matchbox_id}
                          index={index}
                          user={user}
                          hasSingleManagerUserType={singleManagerUserType}
                          isSingleUser={isSingleUser()}
                          onDelete={onDelete}
                          userGroups={companyUserGroups}
                        ></TableBodyUserList>
                      );
                    })}
                  </SortableContext>
                </DndContext>
              </tbody>
            </TableContainer>
          </form>
        </FormProvider>
      </>
    </AdminPage>
  );
};
