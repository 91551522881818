import React, { useState, useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Ed22 = (): React.ReactElement => {
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if (isAuth) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0022");
    if (!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g, "/")} 12:00:00`);
    const now = new Date;
    if (now > deadlineDate) setDeadline(true);
  }, []);

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        < div className='md:-mx-16 sm:-mx-16'>
          < img className={style.img} src="../images/Top/event/event_0022_mv.jpg" width="978" height="504" alt="" />
        </div >
        <h1 className='mt-56 sm:mt-30 text-29_46 sm:text-18_24 font-bold text-center'>2/22（水）20:00 - <br />
          幅広い経験が生んだ<br className="md:hidden lg:hidden" />デザインのアプローチ<br />― キャリアの変遷から学んだ<br className="md:hidden lg:hidden" />課題解決の手法 ―</h1>
        {deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>以下に該当する方はオススメです。</h2>
          <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>キャリアアップに悩んでいるWebデザイナー</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>次のキャリアアクションを決めかねているので他の人のキャリア遍歴を知りたい方</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>プロダクト制作の現場の業務具体を知りたい方</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>プロダクト制作におけるプロセスや課題解決のためのアプローチについて聞いてみたい方</span>
              </li>
              <li className="flex">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>セミナーを踏まえて転職・書類作成の相談をしたい方</span>
              </li>
            </ul>
          </div>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className='mt-80 sm:mt-55'>
          < h3 className='text-18_24 sm:text-14_21 font-bold'>イベント内容</h3>
          <div className="mt-30 sm:mt-16 mb-30">
            <img src="../images/Top/event/event_seminar_image.jpg" width="980" height="374" alt="" />
          </div>
          <p className="text-16_28 sm:text-14_21 mt-30">
            デザイナーのキャリアについて、数多い道筋があり転職したいという意思があっても、次に何をしよう？何がしたい？と悩んでいる方が多いのではないでしょうか？<br />
            今回のセミナーはそのような方々向けに、現在業界で活躍されているデザイナーのキャリア遍歴を知っていただく機会となります。<br />
            <br />
            ご登壇は株式会社ROUTE06 プロフェッショナルサービス本部の熊野亜由美氏、菅原直也氏です。お二人はデザイナー以外にもフロントエンドやディレクターのご経験があり、その経験が現在どのようにデザインやアイデアとして生かされて課題解決を実践しているのか、などプロダクト制作現場でのデザイナーの業務具体を実際のプロジェクトを例にお話しいただきます。<br />
            当日は、質疑応答のお時間も設けますので、セミナーの内容踏まえてご質問なども是非お待ちしております。
            興味がある方は是非、お早めにご応募ください。
          </p>
        </section>
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">参加者の声</h3>
          <div className='mt-30 mb-15 sm:mt-16 sm:mb-8 p-40 sm:p-24 box-line text-left'>
            <ul className='text-16_28 sm:text-14_21'>
              <li className="flex mb-4">
                <span>・</span><span>他社のプロジェクトの実例はなかなか聞けない機会なので、参考になった<br />
                  （28歳 制作会社Webデザイナー 5年目）</span>
              </li>
              <li className="flex mb-4">
                <span>・</span><span>次のキャリアで悩んでいたが、現役デザイナーのキャリア遍歴が聞けて次の目指す先を見つけることができた<br />
                  （27歳 Webデザイナー 4年目）</span>
              </li>
              <li className="flex mb-4">
                <span>・</span><span>質疑応答で聞きたいことが聞けて、思考がクリアになった<br />
                  （30歳 事業会社Webデザイナー）</span>
              </li>
            </ul>
          </div>
          <p className="text-right text-16_28 sm:text-14_21">※参加者の声の一部を掲載しています</p>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section>
          <h2 className='text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6'>講師プロフィール</h2>
          <div className='event-instructor box-line mb-30 sm:p-16'>
            <div className='instructor-photo'>
              < img src="../images/Top/event/event_0022_prof-01.jpg" alt="" />
            </div >
            <dl className='instructor-prof sm:mt-16'>
              <dt className='instructor-name text-16_28 font-bold'>
                熊野 亜由美（AYUMI KUMANO）
              </dt >
              <dd className='instructor-position text-16_28 sm:text-14_21 mb-18 sm:mb-12'>
                株式会社ROUTE06<br />
                プロフェッショナルサービス本部 デザインマネージャー
              </dd>
              <dd className='instructor-career text-16_28 sm:text-14_21'>
                八戸工業高等専門学校専攻科 物質工学専攻を卒業後、旭化成せんい株式会社 商品科学研究所にてスポーツ・インナーアパレル向けの商品開発・特許業務など担当。<br />
                ウェブ制作会社でデザイナーに転向後、フリーランス、株式会社スマービー、株式会社ストライプデパートメントでデザイナーを経験。2020年7月、株式会社ROUTE06に入社。
              </dd >
            </dl >
          </div >
          <div className='event-instructor box-line sm:p-16'>
            <div className='instructor-photo'>
              <img src="../images/Top/event/event_0022_prof-02.jpg" alt="" />
            </div>
            <dl className='instructor-prof sm:mt-16'>
              <dt className='instructor-name text-16_28 font-bold'>
                菅原 直也（NAOYA SUGAWARA）
              </dt >
              <dd className='instructor-position text-16_28 sm:text-14_21 mb-18 sm:mb-12'>
                株式会社ROUTE06<br />
                プロフェッショナルサービス本部 デザイナー
              </dd>
              <dd className='instructor-career text-16_28 sm:text-14_21'>
                桑沢デザイン研究所でプロダクトデザインを専攻。<br />
                株式会社ロフトワーク、株式会社グッドパッチ、株式会社パーティでデザイナー・Webディレクションを経験。 2021年1月、株式会社ROUTE06に入社。
              </dd >
            </dl >
          </div >
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap">
                  <span className="sm:block">20：00</span><span className="sm:block">～</span><span className="sm:block">21：20</span>
                </th>
                <td>
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex mb-4"><span>・</span><span>自己紹介：ご経歴、現在の職務</span></li>
                    <li className="flex mb-4"><span>・</span><span>キャリアの変遷：<br />
                      前職、これまでの経験が現在のデザインに与える影響<br />
                      課題解決のためのアプローチ：経験を活かしたアイデア、手法</span></li>
                    <li className="flex mb-4"><span>・</span><span>ケーススタディ：実際に取り組んだ課題とその解決策</span></li>
                    <li className="flex mb-4"><span>・</span><span>結論：今後の課題、将来の方向性</span></li>
                    <li className="flex"><span>・</span><span>質疑応答</span></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td> 2023/2/22（水）20:00～<br />
                  [入室開始] 19:50～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">対象者</th>
                <td>今後のキャリア形成について考えているWebデザイナー</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.参加申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink"
                      href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.開催当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className='mt-80 sm:mt-55'>
          <h3 className='text-18_24 sm:text-14_21 font-bold'>開催について</h3>
          < p className='text-16_28 sm:text-14_21 mt-30 sm:mt-16'>
            Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2, 000名を超える「0からのWebディレクション講座」を生み出したメンバーでもある一般社団法人ディレクションサポート協会 会長の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。          </p >
        </section >
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div>
    </div >
  );
};
