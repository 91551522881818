import { AdminUserResponse } from '@/utils/api-client/';
import { PayloadAction } from '@reduxjs/toolkit';

import { State } from './state';

export const reducers = {
  setLogin: (state: State, action: PayloadAction<boolean>): State => {
    // Adminログイン情報 設定
    return { ...state, isLogin: action.payload };
  },
  setUserInfo: (state: State, action: PayloadAction<AdminUserResponse>): State => {
    // Adminユーザー情報 設定
    // https://matchbox-2021-doc-api-git-develop-prty.vercel.app/#operation/get-direct-user
    return { ...state, userInfo: action.payload };
  },
};
