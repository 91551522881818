import React, { useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { notificationError, toggleLoading } from '@/redux/index';
import BlockList from './BlockList';
import BaseButton from '../Button/BaseButton';
import BlockModal from './BlockModal';
import WarningList from './WarningList';
import { UserApi, BlockCompany, BlockCompaniesPatchParam, ResponseError } from '@/utils/api-client';
import '@/styles/common/Direct/BlockSection.scss';

const warnings = [
  '企業情報は随時更新しておりますが、全ての企業が網羅されているわけではありません。あらかじめご了承下さい。',
  '合併・分社化などに伴って企業名が変更される場合も想定されます。データベースは随時変更されますので、ご自身で定期的に設定をご確認いただくようお願いいたします。',
];

function BlockSection(): React.ReactElement {
  const [blockList, setBlockList] = useState<BlockCompany[]>();
  const [blockModal, setBlockModal] = useState<boolean>(false);
  const [blockComplete, setBlockComplete] = useState<boolean>(false);
  const dispatch = useDispatch();
  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    refresh();
  }, [blockComplete]);

  useEffect(() => {
    if (!blockModal) setBlockComplete(false);
  }, [blockModal]);

  const refresh = async () => {
    const b = await new UserApi().getUserBlockCompanies();
    setBlockList(b.data.companies);
  };

  const patchCompanies = async (payload: BlockCompaniesPatchParam) => {
    toggleLoading(true);
    try {
      await new UserApi().patchUserBlockCompanies(payload);
      setBlockComplete(true);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      await refresh();
      toggleLoading(false);
    }
  };
  const onDelete = (id: string) => {
    const list = blockList?.map((c) => {
      return c.id;
    });
    const res = list?.filter((i) => {
      return i !== id;
    });
    const payload = {
      ids: res,
    };
    patchCompanies(payload);
  };
  return (
    <section className="block-section">
      {blockList && (
        <BlockModal
          isOpen={blockModal}
          isClose={setBlockModal}
          blockCompanies={blockList}
          blockComplete={blockComplete}
          setBlockComplete={setBlockComplete}
          patchCompanies={patchCompanies}
        />
      )}
      <div className="w-624 m-auto pb-96 sm:w-auto sm:pb-64">
        <div className="mt-48">
          <WarningList warnings={warnings} className="warning" />
        </div>
        <div className="mt-48">
          {blockList && (
            <BlockList blockCompanies={blockList} isDelete={true} onDelete={onDelete} />
          )}
        </div>
      </div>
      <div className="flex justify-center mt-24">
        <BaseButton
          theme="secondary"
          size="m"
          className="modal-btn"
          onClick={() => setBlockModal(true)}
        >
          ブロックする企業を追加する
        </BaseButton>
      </div>
    </section>
  );
}

export default BlockSection;
