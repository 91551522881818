import '@/stylesAdmin/App.scss';

import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { DataLayerProviderContainer } from '@/componentsDirect/common/DataLayerProviderContainer';
import { AuthDirect } from '@/componentsDirect/Global/Auth';
import { DirectRoute } from '@/componentsDirect/Route/DirectRoute';
import { PublicRoute } from '@/componentsDirect/Route/PublicRoute';
import {
  CandidatesDetail,
  CandidatesTop,
  CareerHistoryPreview,
  DirectCompanyUserGroupsDetail,
  DirectCompanyUserGroupsIndex,
  DirectCompanyUsersIndex,
  DirectConditions,
  DirectConditionsRecommended,
  DirectEntry,
  DirectIndex,
  DirectLogin,
  DirectOfferPositionsAdd,
  DirectOfferPositionsDetail,
  DirectOfferPositionsIndex,
  DirectReminder,
  DirectSearchForm,
  DirectSearchResult,
  DirectTagResult,
  DirectUser,
  PortfolioPreview,
  PortfolioPreviewDetail,
  PortfolioPreviewProfile,
  Top,
} from '@/pagesDirect/';
import { DirectCompanyForm } from '@/pagesDirect/Company/Form';
import { DirectConditionsEdit } from '@/pagesDirect/Conditions/Edit';
import { DirectError } from '@/pagesDirect/Error';
import TermsOfUse from '@/pagesDirect/Other/TermsOfUse';
// import { DirectSearchResultSample } from '@/pagesDirect/Search/ResultSample';
import { DirectUserMain } from '@/pagesDirect/User/Main';
import { store } from '@/reduxAdmin';

/**
 * 企業用ページ ルート設定
 * publicなやつは後ろに記述する
 */
export const IndexDirect = (): React.ReactElement => {
  // 指定パス以外のアクセスなら何も表示しないでアーリーリターン
  if (!/^\/direct\//.test(location.pathname)) return <></>;

  return (
    <Provider store={store}>
      <DataLayerProviderContainer>
        <AuthDirect>
          <BrowserRouter>
            {/** direct以下の場合に表示 */}
            <Route path="/direct">
              <Switch>
                {/** Index */}
                <Route exact path="/direct/index" component={DirectIndex} />

                {/** Top */}
                <Route exact path="/direct/" component={Top} />

                {/** UserGroups */}
                <DirectRoute
                  exact
                  path="/direct/user_groups"
                  component={DirectCompanyUserGroupsIndex}
                />
                <DirectRoute
                  exact
                  path="/direct/user_groups/:id"
                  component={DirectCompanyUserGroupsDetail}
                />

                {/** Users */}
                <DirectRoute exact path="/direct/users" component={DirectCompanyUsersIndex} />
                <DirectRoute exact path="/direct/users/:matchbox_id" component={DirectUser} />
                <DirectRoute exact path="/direct/users/:matchbox_id/notes" component={DirectUser} />

                {/** Search */}
                <DirectRoute exact path="/direct/search" component={DirectSearchForm} />
                <DirectRoute exact path="/direct/search/result" component={DirectSearchResult} />
                {/* <DirectRoute
                  exact
                  path="/direct/search/resultSample"
                  component={DirectSearchResultSample}
                /> */}

                {/** Tag Search */}
                <DirectRoute exact path="/direct/tag/:userTagPath" component={DirectTagResult} />

                {/** Conditions */}
                <DirectRoute exact path="/direct/conditions" component={DirectConditions} />
                <DirectRoute
                  exact
                  path="/direct/conditions/recommended"
                  component={DirectConditionsRecommended}
                />
                <DirectRoute
                  exact
                  path="/direct/conditions/:id(\d+)"
                  component={DirectConditionsEdit}
                />

                {/** Company */}
                <DirectRoute exact path="/direct/company" component={DirectCompanyForm} />

                {/** User プロフィール / アカウントは DirectUserMain 側でルーティング */}
                <DirectRoute path="/direct/user" component={DirectUserMain} />

                {/* Candidates */}
                <DirectRoute exact path="/direct/candidates" component={CandidatesTop} />
                <DirectRoute exact path="/direct/candidates/:id" component={CandidatesDetail} />

                {/** Auth */}
                <PublicRoute exact path="/direct/login" component={DirectLogin} />
                <PublicRoute exact path="/direct/login/reminder" component={DirectReminder} />
                <PublicRoute exact path="/direct/entry" component={DirectEntry} />

                {/** Portfolio Preview */}
                <DirectRoute
                  exact
                  path="/direct/portfolio/:matchbox_id"
                  component={PortfolioPreview}
                />
                <DirectRoute
                  exact
                  path="/direct/portfolio/:matchbox_id/profile"
                  component={PortfolioPreviewProfile}
                />
                <DirectRoute
                  exact
                  path="/direct/portfolio/:matchbox_id/:work_id"
                  component={PortfolioPreviewDetail}
                />

                {/** Career History Preview */}
                <DirectRoute
                  exact
                  path="/direct/cv/:matchbox_id"
                  component={CareerHistoryPreview}
                />

                {/** Other */}
                <Route exact path="/direct/terms_of_use" component={TermsOfUse} />

                {/** Positions */}
                <DirectRoute
                  exact
                  path="/direct/offer_positions"
                  component={DirectOfferPositionsIndex}
                />
                <DirectRoute
                  exact
                  path="/direct/offer_positions/:id"
                  component={DirectOfferPositionsDetail}
                />
                <DirectRoute
                  exact
                  path="/direct/offer_positions_add"
                  component={DirectOfferPositionsAdd}
                />

                {/** General */}
                <Route component={DirectError} />
              </Switch>
            </Route>
          </BrowserRouter>
        </AuthDirect>
      </DataLayerProviderContainer>
    </Provider>
  );
};
