import React from 'react';
import BaseButton from '@/components/common/Button/BaseButton';
import { HashLink } from 'react-router-hash-link';
import ImgLogo from '@/assets/images/common/logo.svg';

export const Footer = (): React.ReactElement => {
  return (
    <section className="top-footer">
      <span className="text">採用のプロがつくった、ポートフォリオサービス。</span>
      <img src={ImgLogo} alt="" />
      <img src="../images/Top/mynaviworks-logo.svg" alt="" />
      <div className="contact">
        <BaseButton
          href="https://survey.mynavi.jp/cre/?enq=jlOIaGU5R3M%3d"
          className="link"
          theme="link"
          size="ss"
        >
          お問い合わせ
        </BaseButton>
        <BaseButton href="/faq" className="link" theme="link" size="ss" forceExternal={true}>
          よくあるご質問
        </BaseButton>
      </div>
      <div className="top-footer_bottom">
        <div className="bottom-inner">
          <p className="copyright">Copyright © Mynavi Works Corporation All Rights Reserved.</p>
          <div className="links">
            <BaseButton
              href="https://mynavi-works.jp/company/detail.html"
              className="link"
              theme="link"
              size="ss"
            >
              運営会社
            </BaseButton>
            <BaseButton href="/terms_of_use" className="link" theme="link" size="ss">
              利用規約
            </BaseButton>
            <div className="link base-button isTextLink">
              <HashLink
                to="/terms_of_use#privacy"
                className="button size-ss theme-link flex items-center"
              >
                <span className="font-bold label">個人情報の取り扱い</span>
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
