import React, { useState, useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Ed104 = (): React.ReactElement => {
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if (isAuth) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0104");
    if (!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g, "/")} 12:00:00`);
    const now = new Date;
    if (now > deadlineDate) setDeadline(true);
  }, []);

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16">
          <img className={style.img} src="../images/Top/event/event_0104_mv.png" width="978" height="504" alt="" />
        </div>
        <h1 className="mt-56 sm:mt-30 text-29_46 sm:text-18_24 font-bold text-center">7/11（木）19:00 -<br />
          〈作成初心者向け〉<br className="md:hidden lg:hidden" />ポートフォリオ基礎講座<br />～MATCHBOXを使って実績をまとめましょう！～</h1>
        <p className="mt-20 sm:mt-10 text-20_36 sm:text-14_21 font-bold text-center">※参加者全員にポートフォリオの作成に役立つ<br />“棚卸しシート”を参加者特典としてお渡しします！</p>
        {deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <section>
          <section className="mt-80 sm:mt-55">
            <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">以下のような方にはおすすめです。</h2>
            <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
              <ul className="text-16_28 sm:text-14_21">
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>ポートフォリオを作るのが初めてで、何から手を付けたらいいのか分からない</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>ポートフォリオのそもそもの作り方を知りたい</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>転職におけるポートフォリオの重要性を知りたい</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>ポートフォリオへ掲載する内容ってどんなものか知りたい</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>デザイン以外に応募者の何を見たいと思っているか知りたい</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>MATCHBOXをもっと効果的に使いたい</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>実際にMATCHBOXを活用している様子を見てみたい</span>
                </li>
                <li className="flex">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>セミナーを踏まえて転職の相談をしたい</span>
                </li>
              </ul>
            </div>
          </section>
          {!deadline && !isLogin &&
            <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
              <div className='flex flex-col items-center justify-center'>
                <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/login"><span className='text-14_21'>アカウントお持ちの方</span>ログインしてセミナーに申し込む</Link></p>
                <p className='text-16_28 sm:text-14_21'>アカウントをお持ちでない方は<Link className='textLink font-bold' to="/entry">こちら</Link>でご登録の上、お申込みください</p>
              </div>
            </section>
          }
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">イベント内容</h2>
          <div className="mb-30">
            <img src="../images/Top/event/event_0041_image.jpg" width="980" height="374" alt="" />
          </div>
          <p className="text-16_28 sm:text-14_21">
            デザイナーが転職活動を行ううえで必要不可欠なポートフォリオ。しかし、「何から始めたら良いのかわからず、制作がなかなか進まない」「そもそも何から手を付けたらよいのかわからない」など、ポートフォリオ作成が高いハードルとなっているようです。<br />
            <br />
            転職のためのポートフォリオ作成にお悩みのデザイナーの皆さま向けに、ポートフォリオの作成基礎をお話する“ポートフォリオ講座”を開催することとなりました。
            ポートフォリオは、作成したデザインを掲載するだけではなく、他に押さえなくてはならないポイントが様々にあります。今まで培ってきたあなたの価値を正しく企業に伝えられるよう、より書類通過することにフォーカスしたポートフォリオ作成のテクニックをお伝えします。<br />
            特に実務未経験の方やデザイナー経験が浅い方には「まず押さえておきたい内容」が詰まっておりますのでぜひご参加ください。<br />
            <br />
            オンラインでの開催となりますので、興味がある方はお早めにご応募ください。
          </p>
          <section className="mt-80 sm:mt-55">
            <h3 className="mb-20 sm:mb-10 text-18_24 sm:text-14_21 font-bold">参加者の声</h3>
            <p className="text-16_28 sm:text-14_21">これまでのセミナーの参加者からは、以下のようなご好評をいただいております。</p>
            <div className='mt-30 mb-15 sm:mt-16 sm:mb-8 p-40 sm:p-24 box-line text-left'>
              <ul className="text-16_28 sm:text-14_21">
                <li className="flex mb-4"><span>・</span><span>ポートフォリオを作るうえで押さえたいポイントを理由と共に具体的に教えて頂き、大変参考になりました。</span></li>
                <li className="flex mb-4"><span>・</span><span>どのようなポートフォリオを作成したらいいのかわからなかったので勉強になりました。</span></li>
                <li className="flex"><span>・</span><span>自分の作ったポートフォリオの問題点を見つけることができました。</span></li>
              </ul>
            </div>
            <p className="text-right text-16_28 sm:text-14_21">※参加者の声の一部を掲載しています</p>
          </section>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/login"><span className='text-14_21'>アカウントお持ちの方</span>ログインしてセミナーに申し込む</Link></p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちでない方は<Link className='textLink font-bold' to="/entry">こちら</Link>でご登録の上、お申込みください</p>
            </div>
          </section>
        }
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">講師プロフィール</h2>
          <div className="event-instructor box-line mb-30 sm:p-16">
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0070_prof.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                鈴木 岬成（KOSEI SUZUKI）
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-40 sm:mb-18">
                株式会社マイナビワークス<br />
                マーケティング部
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                学生時代にイラストレーターになるため自身のポートフォリオを作成し、大学卒業後はマイナビワークスに入社。企業への営業活動を経て、現在では社内のWebディレクションなどを行っている。Web・デザイン・作曲・3DCGなどクリエイティブに関して幅広く興味をもっており、副業でイラストレーターとしても活動中。
              </dd>
            </dl>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap" rowSpan={3}>
                  <span className="sm:block">19：00</span><span className="sm:block">～</span><span className="sm:block">20：00</span>
                </th>
                <td>
                  ＜前半＞
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>自己紹介</span></li>
                    <li className="flex"><span>・</span><span>そもそもポートフォリオとは？</span></li>
                    <li className="flex"><span>・</span><span>転職におけるポートフォリオづくりで押さえたいポイント</span></li>
                    <li className="flex"><span>・</span><span>MATCHBOXとは？</span></li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  ＜後半＞
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>一緒にポートフォリオを作ってみよう。</span></li>
                    <li className="flex"><span>・</span><span>制作するうえで気をつけたいこと</span></li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  ＜質疑応答＞
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/login"><span className='text-14_21'>アカウントお持ちの方</span>ログインしてセミナーに申し込む</Link></p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちでない方は<Link className='textLink font-bold' to="/entry">こちら</Link>でご登録の上、お申込みください</p>
            </div>
          </section>
        }
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2024/7/11（木）19:00～<br />
                  [入室開始] 18:50～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">対象者</th>
                <td>転職準備を進めており、ポートフォリオ作りの基礎が知りたいデザイナーや実務未経験の方</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.ワークショップ申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink" href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.ワークショップ当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/login"><span className='text-14_21'>アカウントお持ちの方</span>ログインしてセミナーに申し込む</Link></p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちでない方は<Link className='textLink font-bold' to="/entry">こちら</Link>でご登録の上、お申込みください</p>
            </div>
          </section>
        }
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">開催について</h3>
          <p className="mt-30 sm:mt-16 text-16_28 sm:text-14_21">
            Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2,000名を超える「0からのWebディレクション講座」を生み出したメンバーでもある一般社団法人ディレクションサポート協会 会長の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。
          </p>
        </section>
        <section className="mt-80 sm:mt-55 p-40 sm:p-24 bg-gray-50 text-left">
          <h3 className="text-18_24 sm:text-14_21 font-bold">注意点</h3>
          <p className="mt-16 text-16_28 sm:text-14_21">
            ZoomURLはセミナー前日・または当日にメールにてお送りします。<br />
            マッチボックス登録時のアドレス、またはセミナー申込み時に入力したアドレスにURLへ届いていない場合は<a className='textLink' href='https://survey.mynavi.jp/cre/?enq=2va0sJC992s%3d' target={'_blank'} rel="noreferrer">こちら<i className="mbx-icon mbx-icon-External_link"></i></a>からお問い合わせください。
          </p>
        </section>
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div>
    </div>
  );
};
