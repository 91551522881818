import React, { useState, useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Ed89 = (): React.ReactElement => {
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if (isAuth) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0089");
    if (!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g, "/")} 12:00:00`);
    const now = new Date;
    if (now > deadlineDate) setDeadline(true);
  }, []);

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16">
          <img className={style.img} src="../images/Top/event/event_0089_mv.png" width="978" height="504" alt="" />
        </div>
        <h1 className="mt-56 sm:mt-30 text-29_46 sm:text-18_24 font-bold text-center">3/19（火）19:30 -<br />
          転職の舞台裏<br />人事・デザイナー・面接官が贈る<br className="md:hidden lg:hidden" />幸せな転職成功のカギ</h1>
        {deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">以下に該当する方にオススメです。</h2>
          <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>転職活動を始めたので、選考通過率をあげたい</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>企業担当者が書類に対してどこをみているか知りたい</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>履歴書・職務経歴書作成のヒントが欲しい</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>転職したデザイナーの話を聞いてみたい</span>
              </li>
              <li className="flex mb-4">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>MATCHBOXをもっと効果的に使いたい</span>
              </li>
              <li className="flex">
                <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>セミナーを踏まえて転職の相談をしたい</span>
              </li>
            </ul>
          </div>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">イベント内容</h3>
          <div className="mt-30 sm:mt-16 mb-30">
            <img src="../images/Top/event/event_seminar_image.jpg" width="980" height="374" alt="" />
          </div>
          <p className="text-16_28 sm:text-14_21 mt-30">
            デザイナーが転職活動を行ううえで、「何から始めたら良いのかわからず、書類作成がなかなか進まない」「多忙な日々の仕事に追われながら、準備するのは大変」、また、活動が始まってもどのように心づもりをして臨めばいいかわからない・・・など転職活動には飛び込む前後でも躊躇してしまう出来事に直面することが多々あります。<br />
            <br />
            今回は転職活動のことについて、プロに聞いてみよう！ということで、Webデザイナーが転職する際に押さえておくべきポイントを、Web制作会社の採用担当者から直接聞くことができるセミナーになっています。<br />
            <br />
            転職に成功したデザイナーの体験記や、採用担当者はどんな目線で候補者選びをしているのかをお話いただく予定となっております。<br />
            オンラインでの開催となりますので、興味がある方はお早めにご応募ください。
          </p>
        </section>
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">参加者の声</h3>
          <div className="mt-30 mb-15 sm:mt-16 sm:mb-8 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4">
                <span>・</span><span>採用担当者が各書類に何が書いてあるか重視しているかよくわかった。<br />
                  （28歳 制作会社Webデザイナー 6年目）</span>
              </li>
              <li className="flex mb-4">
                <span>・</span><span>ポートフォリオや履歴書・職務経歴書のまとめ方についてイメージがついた。<br />
                  （グラフィックデザイナー 3年目）</span>
              </li>
              <li className="flex mb-4">
                <span>・</span><span>MATCHBOXの効果的な使い方がよくわかった。<br />
                  （26歳 Webデザイナー 4年）</span>
              </li>
            </ul>
          </div>
          <p className="text-right text-16_28 sm:text-14_21">※参加者の声の一部を掲載しています</p>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">講師プロフィール</h2>
          <div className='event-instructor box-line mb-30 sm:p-16'>
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0020_prof.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                岸本 佳奈
                <span className='inline-block'>（KANA KISHIMOTO）</span>
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-40 sm:mb-18">
                株式会社デパート Corporatedesign div.課長<br />
                2級キャリア・コンサルティング技能士<br />
                国家資格キャリアコンサルタント<br />
                JCDA認定CDA<br />
                NLPマスタープラクティショナー<br />
                ※NLP：神経言語プログラミング</dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                新卒で某証券会社の新規開拓営業として若手トップに。2年後、若手採用.育成部隊の立上げメンバーとして人事部へ異動。採用（新卒・中途）・教育・制度・評価等、人事業務に幅広く従事。30歳を機にWeb業界へ転身。Web制作会社CEO秘書兼PR、事業会社 人事広報を経て、2018年にデパートのCorporatedesign div.の立上げにJoin。人事としてのキャリアは15年、年間1500名以上のクリエーターと出会い、年間1万人以上の履歴書・ポートフォリオを目にするクリエーター特化の人事。
              </dd>
            </dl>
          </div>
          <div className='event-instructor box-line mb-30 sm:p-16'>
            <dl className="sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                末廣 和弘<span className='inline-block'>（KAZUHIRO SUEHIRO）</span>
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-18 sm:mb-12">
                株式会社デパート Creative div. designer sec.課長<br />
                アートディレクターチーフデザイナー</dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                大阪府出身。制作会社（デザイン／ディレクション）〜ECサイト運営〜出版会社（グラフィックデザイン）〜フリーランス（Webデザイン）など、ふらふらしながらその後は、都内セレクトショップのWeb事業部のディレクター／マネージメントへと。もともとコツコツと手を動かすのが好きで、また制作現場での仕事がしたく、デパートにJoin。
              </dd>
            </dl>
          </div>
          <div className='event-instructor box-line sm:p-16'>
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0089_prof.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                内村 理沙<span className='inline-block'>（RISA UCHIMURA）</span>
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-18 sm:mb-12">
                株式会社デパート Creative div. designer sec.<br />
                デザイナー</dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                高知県出身。美大でUIデザインやグラフィックデザインを学び、新卒でWeb制作会社に入社。デザイナーとしてコーポレートサイトや採用サイト、キャンペーンサイトやロゴ等、色々なジャンルのデザインを制作。4年半勤務した後、スキルアップを目指し転職活動を経て今年1月からデパートに入社。
              </dd>
            </dl>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap" rowSpan={2}>
                  <span className="sm:block">19：30</span><span className="sm:block">～</span><span className="sm:block">21：00</span>
                </th>
                <td>
                  ＜前半＞ <br />
                  MATCHBOX活用中の人事責任者が明かす転職術
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>知っ得！採用担当の裏っ側の動き教えます</span></li>
                    <li className="flex"><span>・</span><span>採用担当はこんな風に候補者を探しています</span></li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  ＜後半＞
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>対談１ - 株式会社デパートで活躍中のデザイナーが明かす転職秘話</span></li>
                    <li className="flex"><span>・</span><span>対談２ - 株式会社デパート デザイナー面接官が明かす選考基準</span></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2024/3/19（火）19:30～<br />
                  [入室開始] 19:20～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">対象者</th>
                <td>転職を実際に考えている、もしくは、今後のキャリア形成について考えている事業会社／制作会社のWebデザイナー</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.参加申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink"
                      href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.開催当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">開催について</h3>
          <p className="mt-30 sm:mt-16 text-16_28 sm:text-14_21">
            Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2,000名を超える「0からのWebディレクション講座」を生み出したメンバーでもある一般社団法人ディレクションサポート協会 会長の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。
          </p>
        </section>
        <section className="mt-80 sm:mt-55 p-40 sm:p-24 bg-gray-50 text-left">
          <h3 className="text-18_24 sm:text-14_21 font-bold">注意点</h3>
          <p className="mt-16 text-16_28 sm:text-14_21">
            ZoomURLはセミナー前日・または当日にメールにてお送りします。<br />
            マッチボックス登録時のアドレス、またはセミナー申込み時に入力したアドレスにURLへ届いていない場合は<a className='textLink' href='https://survey.mynavi.jp/cre/?enq=2va0sJC992s%3d' target={'_blank'} rel="noreferrer">こちら<i className="mbx-icon mbx-icon-External_link"></i></a>からお問い合わせください。
          </p>
        </section>
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div>
    </div>
  );
};
