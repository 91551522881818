import React, { useMemo, useState } from 'react';

import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { OfferPositionData } from '@/utils/api-client';
import { convertLineBreak, linkifyUrls } from '@/utils/string';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type TProps = {
  status?: number
  comment?: string;
  position?: OfferPositionData;
};

/**
 * 企業からのメッセージをポップアップで表示
 */
export const DirectOffersModalMessageFromCompanyComponent = ({
  status,
  comment,
  position,
}: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [showModal, setShowModal] = useState(false);

  // ====================================================================================================
  //
  // EVENT
  //
  // ====================================================================================================
  const onOpen: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const onClose: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.preventDefault();
    setShowModal(false);
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const isShow = useMemo(() => {
    return comment || position;
  }, [comment, position]);

  const statusText = useMemo(() => {
    switch (status) {
      case 0:
        return '回答期限終了';
      case 2:
        return 'マイナビワークスに相談中';
      case 3:
        return '一次面接オファー中';
      case 4:
        return '見送り';
      case 5:
        return '一次面接オファー見送り';
      case 6:
        return '一次面接オファー中';
      case 7:
        return '一次面接オファー中';
      case 8:
        return 'カジュアル面談オファー中';
      case 9:
        return 'カジュアル面談オファー中';
      case 10:
        return 'カジュアル面談オファー見送り';
      case 11:
        return 'カジュアル面談オファー中';
    }
  }, [status]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <>
      {isShow && (
        <>
          <i
            className="mbx-icon mbx-icon-Message text-icon text-blue-700 cursor-pointer"
            onClick={onOpen}
          ></i>
          <ModalDefault
            isOpen={showModal}
            onClose={onClose}
            className="w-2/3vw rounded-modalContainer"
          >
            <section className="p-80">
              <table className="w-full">
                <tbody className="text-left border-b border-gray-300">
                  <tr className="border-t border-gray-300">
                    <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100 w-170">
                      アクション
                    </th>
                    <td className="text-body_2 p-24 font-normal">{statusText}</td>
                  </tr>
                  <tr className="border-t border-gray-300">
                    <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100">
                      ポジション名
                    </th>
                    <td className="text-body_2 p-24 font-normal">{position?.name}</td>
                  </tr>
                  <tr className="border-t border-gray-300">
                    <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100">
                      ポジション詳細
                    </th>
                    <td
                      className="text-body_2 p-24 font-normal"
                      dangerouslySetInnerHTML={{
                        __html: convertLineBreak(linkifyUrls(position?.body)),
                      }}
                    ></td>
                  </tr>
                  <tr className="border-t border-gray-300">
                    <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100">
                      マイナビワークスへのメッセージ
                    </th>
                    <td
                      className="text-body_2 p-24 font-normal"
                      dangerouslySetInnerHTML={{ __html: convertLineBreak(linkifyUrls(comment)) }}
                    ></td>
                  </tr>
                </tbody>
              </table>
            </section>
          </ModalDefault>
        </>
      )}
    </>
  );
};
