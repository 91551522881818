import { AdminSearchConditionModel } from '@/utils/api-client';

export type State = {
  condition: AdminSearchConditionModel | null;
};

export const initialState: State = {
  condition: localStorage.getItem('adminCondition')
    ? (JSON.parse(localStorage.getItem('adminCondition') as string) as AdminSearchConditionModel)
    : null,
};
