import React, { MouseEventHandler, ReactNode, VFC } from 'react';
import cn from 'classnames';

type TProps = {
  value: string;
  children: ReactNode;
  onClickClose?: MouseEventHandler<HTMLDivElement>;
  isActive?: boolean;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=393%3A40447
 */
export const FormDropdownMultipleSelectItem: VFC<TProps> = ({ value, children, onClickClose, isActive = true }) => {
  return (
    <li
      className={cn(isActive ? "bg-blue-200" : "bg-gray-200", "mbx-dropdown-multiple-select-item pl-8 m-6 rounded-full")}
      data-value={value}
    >
      <div className="flex items-center">
        <p className="text-11_12">{children}</p>
        <div
          className="mbx-dropdown-multiple-select-item-icon"
          onClick={onClickClose}
          data-value={value}
        >
          <i className={cn({ "mbx-icon-inactive": !isActive }, "mbx-icon mbx-icon-Clear")}></i>
        </div>
      </div>
    </li>
  );
};
