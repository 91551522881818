import { useEffect, useState } from 'react';

type TReturn = {
  width: number;
  height: number;
};

export function useWindowSize(): TReturn {
  const getSize = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [windowSize, setWindowSize] = useState<{ width: number; height: number }>(getSize());

  useEffect(() => {
    const handleResizeDebounced = () => {
      setWindowSize(getSize());
    };

    window.addEventListener('resize', handleResizeDebounced);
    return () => window.removeEventListener('resize', handleResizeDebounced);
  }, []);

  return windowSize;
}
