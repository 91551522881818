import React from 'react';

import { PreviewResumeHistory } from '@/components/common/Preview/ResumeHistory';
import { PreviewResumeTitle } from '@/components/common/Preview/ResumeTitle';
import {
    CareerSummaryModel, EducationData, Qualification, QualificationQTypeEnum
} from '@/utils/api-client/api';

type TProps = {
  educations?: EducationData[];
  careers?: CareerSummaryModel[];
  qualifications?: Qualification[];
};

export const ResumePreviewSectionQualifications = ({
  educations = [],
  careers = [],
  qualifications = [],
}: TProps): React.ReactElement => {
  // 学歴と職歴の合計が9以上だったら表示エリアがないので非表示
  if (educations?.length + careers?.length >= 9) return <></>;

  // invisibleなやつは除外
  qualifications = qualifications.filter((item) => {
    if(item.q_visible){
      if(item.q_type === QualificationQTypeEnum.Text){
        return item;
      }else if(parseInt(item.q_score) > 0 || isNaN(Number(item.q_score))){
        // 0点は除外
        return item;
      }
    }
  });

  const fillEmptyItemLength =
    educations?.length + careers?.length <= 6
      ? 2 - qualifications?.length
      : 9 - qualifications?.length - educations?.length - careers?.length;
  return (
    <div className="flex">
      <PreviewResumeTitle>資格・免許</PreviewResumeTitle>
      <div className="w-full">
        {qualifications &&
          Array.from(qualifications.values()).map((item: Qualification, index: number) => {
            let description;
            switch (item.q_type) {
              case QualificationQTypeEnum.Text:
                description = `${item.q_title} 取得`;
                break;
              case QualificationQTypeEnum.Toeic:
                description = `TOEIC ${item.q_score}点取得`;
                break;
              case QualificationQTypeEnum.Toefl:
                description = `TOEFL ${item.q_score}点取得`;
                break;
              default:
                return '';
            }
            return (
              <PreviewResumeHistory
                key={index}
                date={`${item.q_date?.split('-')[0]}年${item.q_date?.split('-')[1]}月`}
                description={description}
              ></PreviewResumeHistory>
            );
          })}
        {fillEmptyItemLength > 0
          ? new Array(fillEmptyItemLength).fill(null).map((item, key) => {
              return (
                <PreviewResumeHistory
                  date=""
                  description=""
                  type="empty"
                  key={key}
                ></PreviewResumeHistory>
              );
            })
          : null}
        <PreviewResumeHistory date="" description="" type="empty"></PreviewResumeHistory>
      </div>
    </div>
  );
};
