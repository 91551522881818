import React from 'react';
import { FieldValues, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';

import { ButtonIconButton } from '@/components/common/Button/IconButton';
import { FormContainer, TCallback } from '@/components/common/Form/Container';
import { FormContainerTextArea } from '@/components/common/Form/Container/TextArea';
import { TitleSectionTitle } from '@/components/common/Title/SectionTitle';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { PLACEHOLDER } from '@/definition/PLACEHOLDER';
import { usePolling } from '@/hooks/usePolling';
import { TSectionProps } from '@/pages/Resume/Edit';
import { ResumeApi } from '@/utils/api-client';

export const ResumeFormSectionInterestThings = ({
  id,
  fetchData,
}: TSectionProps): React.ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [param, setParam, response, saveFlag, setBeforeUnload] = usePolling(
    ResumeApi,
    'patchResume',
    undefined,
    2000
  );

  const useFormProps: UseFormProps = {
    mode: 'onBlur',
    defaultValues: {
      interest_things: fetchData ? fetchData.resume?.interest_things : '',
    },
  };
  const methods = useForm(useFormProps);

  const onPatch: TCallback = () => {
    //
  };

  const onBlur = (name: string) => {
    const value = methods.getValues();
    const data = {
      resume: {
        [name]: value[name],
      },
    };
    setParam({ data });
  };

  return (
    <div id={id}>
      <FormContainer
        useFormProps={useFormProps}
        onPatch={onPatch}
        fetchData={fetchData}
        methods={methods as UseFormReturn<FieldValues, Record<string, unknown>>}
      >
        <TitleSectionTitle>
          <div className="flex items-center">
            <span className="mr-4">趣味・特技</span>
            <TooltipMessageMatchbou
              className=""
              message="オファー機能ご利用時、「趣味・特技」は企業に開示されません。"
              position={['0', '-94px']}
            >
              <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
            </TooltipMessageMatchbou>
          </div>
        </TitleSectionTitle>
        <div>
          <FormContainerTextArea
            name="interest_things"
            rows={5}
            maxLength={150}
            className="mkt_mask_items"
            placeholder={PLACEHOLDER.interest_things}
            noLineBreaks={true}
            onFocus={() => setBeforeUnload(true)}
            onBlur={() => onBlur('interest_things')}
          />
        </div>
      </FormContainer>
    </div>
  );
};
