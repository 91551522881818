import '@/styles/common/Direct/MyNaviSelectForm.scss';

import React from 'react';
import { AxiosError } from 'axios';
import { OnboardingPageWrapper } from './PageWrapper';
import { FormProvider, useForm, UseFormProps } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { PageDots } from './PageDots';
import {
    notificationError, toggleLoading
} from '@/redux/index';
import {
  FormContainerDropdown,
  FormContainerTextfield,
} from '@/componentsDirect/Parts/Form/';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import {
  DirectCompanyModel,
  ResponseError
} from '@/utils/api-client';

type TStep = {
  step1: boolean,
  step2: boolean,
  step3: boolean,
  step4: boolean,
  step5: boolean,
  step6: boolean,
};

type Props = {
  page: number;
  step: TStep;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalDots: number;
  company: DirectCompanyModel | undefined;
  patchCompany: (request: DirectCompanyModel) => void;
  onNext: (number: number) => void;
  setIsDone: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OnboardingPage_1 = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const useFormProps: UseFormProps<any> = {
    mode: 'onBlur',
    defaultValues: props.company,
  };

  const methods = useForm(useFormProps);

  const { handleSubmit } = methods;

  // 送信処理
  const onSubmit = async (data: DirectCompanyModel) => {
    if (!data) return;

    dispatch(toggleLoading(true));
    try {
      await props.patchCompany(data);
    } catch (e) {
      const error = e as AxiosError<ResponseError>;
      if (error.response) dispatch(notificationError(error.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
      props.setIsDone(true);
      props.onNext(1);
    }
  };

  return (
    <DataLayerPushContainer
      data={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'modal_open',
        virtualPageName: 'on-boarding_step_1'
      }}
    >
    <div>
      <OnboardingPageWrapper
        description={
          <span>
            スカウトサービス
            <br />
            MATCHBOXへようこそ
          </span>
        }
      >
        <div>
          <p className="mt-24 text-14_20">
            はじめに、企業情報を入力しましょう。
            <br />
            入力した情報はオファーした求職者に開示され<br />
            {props.company?.name}様の魅力がより伝わりやすくなります。
            <p className="mt-8 text-11_16">※この項⽬はあとから変更できます。</p>
          </p>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
              <div className="mt-26 flex justify-center">
                <FormLayoutFieldset className='w-full'>
                  <FormLabel label="業種" type="small">
                  </FormLabel>
                  <FormContainerDropdown
                    className="select mkt_mask_items"
                    name="business_type"
                    placeholder="選択してください"
                    selectList={FORM_OPTIONS.business_type}
                  ></FormContainerDropdown>
                </FormLayoutFieldset>
              </div>
              <div className="mt-26 flex justify-center">
                <FormLayoutFieldset className='w-full'>
                  <FormLabel label="所在地（都道府県）" type="small"></FormLabel>
                  <FormContainerDropdown
                    className="select mkt_mask_items"
                    name="prefecture"
                    placeholder="選択してください"
                    selectList={FORM_OPTIONS.prefecture}
                  ></FormContainerDropdown>
                </FormLayoutFieldset>
              </div>
              <div className="mt-26 flex justify-center">
                <FormLayoutFieldset className='w-full'>
                  <FormLabel label="所在地（市区町村以下）" type="small"></FormLabel>
                  <FormContainerTextfield name="address" className="mkt_mask_items" />
                </FormLayoutFieldset>
              </div>
              <div className="mt-26 flex justify-center">
                <FormLayoutFieldset className='w-full'>
                  <FormLabel label="事業内容" type="small"></FormLabel>
                  <FormContainerTextfield name="description" className="mkt_mask_items" />
                </FormLayoutFieldset>
              </div>
              <div className="pb-20">
                <PageDots page={props.page} setPage={props.setPage} step={props.step} totalDots={props.totalDots} unClick className="mt-48" />
                <div className="w-224 m-auto mt-48">
                  <BaseButton
                    theme="primary"
                    size="m"
                    className="w-full"
                  >
                    次へ
                  </BaseButton>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </OnboardingPageWrapper>
    </div>
    </DataLayerPushContainer>
  );
};
