import React, { ReactElement, useMemo } from 'react';

import CirclePlaceholder from '@/components/common/OtherComponents/CirclePlaceholder';
import { Profile } from '@/utils/api-client';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type Props = {
  profile: Profile | undefined;
  direct?: boolean;
};

export function UserThumbNameJob({ profile, direct }: Props): ReactElement {
  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const iconPath = useMemo(() => {
    if (profile?.icon && !direct) {
      if (location.pathname.includes(`/admin/direct/portfolio/`)) {
        // 管理者 企業向けポートフォリオの場合はブランクアイコンを表示
        return '/images/components/other_components/Circle-placeholder.png';
      } else {
        return profile?.icon;
      }
    } else {
      return '/images/components/other_components/Circle-placeholder.png';
    }
  }, [profile, direct]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <section className="profile-preview-profile">
      <div className="profile-preview-profile__thumb mkt_mask_items">
        <CirclePlaceholder src={iconPath} />
      </div>
      <div className="mbx-top-preview-profile__inner">
        <span className="mbx-typography--body_1 mkt_mask_items">{profile?.job_title}</span>
        <p className="mbx-typography--heading_2 mkt_mask_items">{profile?.name}</p>
      </div>
    </section>
  );
}
