import React from 'react';

import { ButtonIconButton } from '@/componentsDirect/Parts/Button/IconButton';
import { BlockCompany } from '@/utils/api-client';

type TProps = {
  companies: BlockCompany[];
  className?: string;
  setCompanies: (value: React.SetStateAction<BlockCompany[]>) => void;
};

export const CompanyTagList = (props: TProps): React.ReactElement => {
  const deleteTag = (id: string) => {
    props.setCompanies(
      props.companies.filter((t) => {
        return t.id !== id;
      })
    );
  };

  return (
    <div className={props.className}>
      <div className="w-full text-14_21 border border-border-form rounded-formContainer transition-all min-h-36">
        <input type="hidden" />
        <ul className="flex items-center flex-wrap">
          {/* タグ化した要素 */}
          {props.companies.map((tag, index) => (
            <li key={index} className={'pl-8 m-6 bg-blue-200 rounded-full'}>
              <div className="flex items-center justify-between">
                <p className="text-11_12">{tag.name}</p>
                <ButtonIconButton
                  type="clear"
                  fontSize="icon_small"
                  iconName="Clear"
                  onClick={(e) => {
                    e.preventDefault();
                    deleteTag(tag.id);
                  }}
                  hitArea="mini"
                  focus={false}
                ></ButtonIconButton>
              </div>
            </li>
          ))}
          <li className="flex-grow min-w-120 mx-6 ">
            <input type="text" className="outline-none w-full" readOnly={true} />
          </li>
        </ul>
      </div>
    </div>
  );
};
