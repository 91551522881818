import '@/styles/common/Portfolio/MainVisual/embedded_link.scss';

import React, { useEffect, useRef, useState } from 'react';

import BaseButton from '@/components/common/Button/BaseButton';

import { Player } from './Player';

type Props = {
  src: string | undefined;
  thumbnail: string | undefined;
  onClick?(): void;
  onRemove?(): void;
  onSetThumbnailClick?(): void;
  editable?: boolean;
};

export function EmbeddedLink(props: Props): React.ReactElement {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const ratio = 351 / 624;

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    const el = ref.current;
    setWidth(el.clientWidth);
    setHeight(el.clientWidth * ratio);
  }, [ref]);

  return (
    <section className="mbx-embedded_link" ref={ref}>
      <Player {...{ width, height, src: props.src }} />
      {(props.editable !== undefined || props.editable) && (
        <div className="mbx-embedded_link_cover" onClick={props.onClick} />
      )}
      {(props.editable === undefined || props.editable) && (
        <div className="flex justify-between">
          <div className="thumbnail">
            <img src={props.thumbnail} className="mkt_mask_items" alt="" />
            <p>印刷用サムネイル</p>
          </div>
          <div className="space-x-16 my-16 text-right">
            <BaseButton
              theme="link"
              size="s"
              iconName="Placeholder_image"
              onClick={props.onSetThumbnailClick}
            >
              印刷用サムネイルを設定
            </BaseButton>

            <BaseButton theme="link" size="s" iconName="Trash" onClick={props.onRemove}>
              埋め込みリンクを削除
            </BaseButton>
          </div>
        </div>
      )}
    </section>
  );
}
