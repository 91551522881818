import '@/styles/common/CareerHistory/project.scss';

import React from 'react';

import { Career } from '@/utils/api-client/api';

export const CompanyContent: React.VFC<{
  className?: string;
  careers: Career[] | undefined;
}> = (props) => {
  const period = (start: string, end: string | undefined): string => {
    const s = start.split('-');
    const e = end ? `${end.split('-')[0]}年${end.split('-')[1]}月` : '';
    return `${s[0]}年${s[1]}月〜${e}`;
  };

  return (
    <table className={`${props.className} w-full mbx-career-history-project mb-12`}>
      <thead className="bg-gray-100 text-11_12 font-bold text-gray-800">
        <tr>
          <td className="w-160">期間</td>
          <td>会社名</td>
        </tr>
      </thead>
      <tbody className="text-10_16">
        {props.careers?.map((career, index) => {
          if(career.c_visible) {
            return (
              <tr key={index}>
                <td className="mkt_mask_items">{period(career.c_join_date, career.c_leave_date)}</td>
                <td className="mkt_mask_items">{career.c_company_name}</td>
              </tr>
            )
          }
        })}
      </tbody>
    </table>
  );
};
