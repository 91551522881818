import React, { useState, useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Ed50 = (): React.ReactElement => {
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if (isAuth) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0050");
    if (!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g, "/")} 12:00:00`);
    const now = new Date;
    if (now > deadlineDate) setDeadline(true);
  }, []);

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16"> <img className={style.img}
          src="../images/Top/event/event_0050_mv.jpg" width="978" height="504" alt="" /> </div>
        <h1 className="mt-56 sm:mt-30 text-29_46 sm:text-18_24 font-bold text-center">7/20（木）20:00 -<br />
          MATCHBOX活用ワークショップ<br />
          自分のキャリアを効果的に<br className="md:hidden lg:hidden" />アピールするには<br />
          ～実例紹介＆質問会～</h1>
        {deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <section>
          <section className="mt-80 sm:mt-55">
            <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">以下に該当する方はオススメです。</h2>
            <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
              <ul className="text-16_28 sm:text-14_21">
                <li className="flex mb-4"><span><i
                  className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>
                    自分が作成したポートフォリオについてプロのデザイナーからアドバイスが欲しい。</span></li>
                <li className="flex mb-4"><span><i
                  className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>
                    もうちょっとで完成するはずのポートフォリオ。素材はあるけどどこまでこだわればいいのか知りたい。</span></li>
                <li className="flex mb-4"><span><i
                  className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>
                    そもそも自分以外のデザイナーやディレクターはどうやってポートフォリオを作成しているのか知りたい。</span></li>
                <li className="flex mb-4"><span><i
                  className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>
                    ポートフォリオをブラッシュアップしたい。客観的なアドバイスが欲しい。</span></li>
                <li className="flex mb-4"><span><i
                  className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>
                    自分以外のデザイナーのポートフォリオを見てみたい。</span></li>
                <li className="flex mb-4"><span><i
                  className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>
                    ポートフォリオ作成後、転職の相談をしたい。</span></li>
                <li className="flex"><span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>
                  MATCHBOXの効果的な使い方を知りたい。</span></li>
              </ul>
            </div>
          </section>
          {!deadline && !isLogin &&
            <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
              <div className='flex flex-col items-center justify-center'>
                <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
                <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
              </div>
            </section>
          }
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">ワークショップについて</h2>
          <div className="mb-30">
            <img src="../images/Top/event/event_0021_image.jpg" width="980" height="374" alt="" />
          </div>
          <p className="text-16_28 sm:text-14_21">
            20：00～、オンラインにてWebデザイナーの方向けの実例紹介＆質問会を行います！<br />
            前半はポートフォリオ作成・ブラッシュアップのための講義行います。後半は講義を踏まえて、ご自身のポートフォリオを改善するためのワークを行いその後ポートフォリオの作成やお悩みについての質問会を行います。<br />
            <br />
            講師はWebデザイナー/エンジニアの庄司美雪氏です。<br />
            当日はポートフォリオ作成のポイントや庄司氏がどのような意図でポートフォリオを作成されたか実際ご自身のポートフォリオを参考にお話しいただきます。また、ポートフォリオに関するアドバイスを受ける機会でもあり、同じデザイナーとして転職を志す方のポートフォリオを見る機会にもなりますので、興味がある方はぜひお早めにご応募ください。（PCでのご参加を推奨させていただきます）
          </p>
          <section className="mt-64 sm:mt-48">
            <h3 className="text-18_24 sm:text-14_21 font-bold">参加者の声</h3>
            <div className="mt-30 mb-15 sm:mt-16 sm:mb-8 p-40 sm:p-24 box-line text-left">
              <ul className="text-16_28 sm:text-14_21">
                <li className="flex mb-4"><span>・</span><span>ポートフォリオを作成中に客観視できていないところも、言語化していただいて制作の進め方がわかった。</span></li>
                <li className="flex"><span>・</span><span>誰に・何を・どうやってアピールするかを意識すると良いということがわかって良かった。デザインした、だけでなくもっと細分化して載せた方がいいと教えて頂けたのも良かった。</span></li>
              </ul>
            </div>
            <p className="text-right text-16_28 sm:text-14_21">※参加者の声の一部を掲載しています</p>
          </section>
          <section className="mt-80 sm:mt-55 p-40 sm:p-24 bg-gray-50 text-left">
            <h3 className="text-18_24 sm:text-14_21 font-bold">注意点</h3>
            <ul className="text-16_28 sm:text-14_21 mt-16">
              <li className="flex">
                <span>・</span><span>今回のアドバイス会はすでにポートフォリオを作成済みの方、作成中の方のご参加を推奨しております。</span>
              </li>
              <li className="flex">
                <span>・</span><span>オンライン上ですが、積極的にコミュニケーションを取りたいと思いますので、できればカメラ・マイクオンでご参加ください。</span>
              </li>
              <li className="flex">
                <span>・</span><span>ワークショップヘの応募、Zoomへの入室時ですが、MATCHBOXログインIDに指定しているメールアドレスをご使用ください。</span>
              </li>
              <li className="flex">
                <span>・</span><span>当日は聞き専の方もご参加いただけます。</span>
              </li>
            </ul>
          </section>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">講師プロフィール</h2>
          <div className="event-instructor box-line sm:p-16">
            <div className="instructor-photo"><img src="../images/Top/event/event_0003_prof.jpg" alt="" /></div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">庄司 美雪<span className="inline-block">（MIYUKI SHOJI）</span>
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-40 sm:mb-18">Webデザイナー / エンジニア</dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                文系で大学を卒業後インフラエンジニアとして就職し、転職を通してWeb広告業界で営業や広告運用を経験。広告に関わる中でWebメディア制作に関心を持ち、エンジニアスクールに通った後にフロントエンドエンジニア兼Webデザイナーに転身。同時に某大手スクールにてWebデザインクラスのメンターとして教育やカリキュラム改善に関わるうちに、エンジニアやデザイナーの教育に大きく関心を持ち、現在は女性向けエンジニアスクールの立ち上げメンバーとしてサービス開発に取り組む。
              </dd>
            </dl>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap" rowSpan={2}><span
                  className="sm:block">20：00</span><span className="sm:block">～</span><span className="sm:block">22：00</span>
                </th>
                <td>＜前半＞<ul className="text-16_28 sm:text-14_21">
                  <li className="flex"><span>・</span><span>ワークショップ概要説明</span></li>
                  <li className="flex"><span>・</span><span>庄司氏による見本ポートフォリオの紹介とアドバイス</span></li>
                </ul>
                </td>
              </tr>
              <tr>
                <td>＜後半＞<ul className="text-16_28 sm:text-14_21">
                  <li className="flex"><span>・</span><span>ワークの時間</span></li>
                  <li className="flex"><span>・</span><span>ポートフォリオ作成についての質問会</span></li>
                  <li className="flex"><span>・</span><span>まとめ</span></li>
                </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2023/7/20（木）20:00～<br />
                  [入室開始] 19:45～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />
                  参加者の皆さまには別途URLをお送りします。
                </td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">対象者</th>
                <td>転職活動中、もしくは、転職準備中のWebデザイナーの方</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">準備物</th>
                <td>
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>ご自身のPC</span></li>
                    <li className="flex"><span>・</span><span>MATCHBOXのアカウントを作成しログイン状態でご参加ください。</span></li>
                    <li className="flex"><span>・</span><span>作成済み、もしくは、作成中の選考書類（履歴書・職務経歴書・ポートフォリオなど）</span></li>
                    <li className="flex"><span>・</span><span>ポートフォリオの作成に必要な素材など</span></li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.ワークショップ申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink" href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.ワークショップ当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'><Link className='button size-m theme-secondary flex items-center font-bold' to="/login">ログインしてセミナーに申し込む</Link></p>
              <p className='base-button isTextLink text-16_28 sm:text-14_21'><Link className='button size-m theme-link flex items-center font-bold' to="/entry">アカウントをお持ちでない方はこちら</Link></p>
            </div>
          </section>
        }
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">開催について</h3>
          <p className="mt-30 sm:mt-16 text-16_28 sm:text-14_21">
            Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2,000名を超える「0からのWebディレクション講座」を生み出したメンバーでもある一般社団法人ディレクションサポート協会 会長の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。
          </p>
        </section>
        <section className="mt-80 sm:mt-55 p-40 sm:p-24 bg-gray-50 text-left">
          <h3 className="text-18_24 sm:text-14_21 font-bold">注意点</h3>
          <p className="mt-16 text-16_28 sm:text-14_21">
            ZoomURLはセミナー前日・または当日にメールにてお送りします。<br />
            マッチボックス登録時のアドレス、またはセミナー申込み時に入力したアドレスにURLへ届いていない場合は<a className='textLink' href='https://survey.mynavi.jp/cre/?enq=2va0sJC992s%3d' target={'_blank'} rel="noreferrer">こちら<i className="mbx-icon mbx-icon-External_link"></i></a>からお問い合わせください。
          </p>
        </section>
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div>
    </div>
  );
};
