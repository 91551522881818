import React, { useMemo, useState } from 'react';

import { ModalDefault } from '@/components/common/Modal/Default';
import { ModalTabNavigation } from '@/components/common/Navigation/ModalTabNavigation';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { DirectCompanyModel, Offer } from '@/utils/api-client';
import { convertLineBreak, linkifyUrls } from '@/utils/string';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type TProps = {
  company?: DirectCompanyModel;
  offer?: Offer;
};

/**
 * 企業からのメッセージをポップアップで表示
 */
export const OffersModalMessageFromCompanyComponent = ({ company, offer }: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [showModal, setShowModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);

  // ====================================================================================================
  //
  // EVENT
  //
  // ====================================================================================================
  const onOpen: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.preventDefault();
    setActiveIndex(1);
    setShowModal(true);
  };

  const onClose: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.preventDefault();
    setShowModal(false);
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const companyFormatData = useMemo(() => {
    if (!company)
      return {
        prefecture: '',
        business_type: '',
        employee_amount: '',
      };
    const prefecture = FORM_OPTIONS.prefecture.find(
      (p) => p.value === company.prefecture?.toString()
    )?.children;
    const business_type = FORM_OPTIONS.business_type.find(
      (p) => p.value === company.business_type?.toString()
    )?.children;
    const employee_amount = FORM_OPTIONS.employee_amount.find(
      (a) => a.value === company.employee_amount?.toString()
    )?.children;

    return {
      prefecture,
      business_type,
      employee_amount,
    };
  }, [company]);

  const statusText = useMemo(() => {
    switch (offer?.status) {
      case 0:
        return '回答期限終了';
      case 5:
        return '見送り';
      case 6:
        return 'オファー応諾';
      case 7:
        return 'マイナビワークスに相談中';
      case 9:
        return 'オファー応諾';
      case 10:
        return '見送り';
      case 11:
        return 'マイナビワークスに相談中';
    }
  }, [offer?.status]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <>
      {company && (
        <>
          <i
            className="mbx-icon mbx-icon-Message text-icon text-blue-700 cursor-pointer"
            onClick={onOpen}
          ></i>
          <ModalDefault
            isOpen={showModal}
            onClose={onClose}
            className="w-2/3vw sm:w-screen rounded-modalContainer"
          >
            <section className="p-80 sm:p-30">
              <ModalTabNavigation
                menu={[
                  { title: '企業情報', index: 1 },
                  { title: 'リアクション履歴', index: 2 },
                ]}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                align='fit'
                className='mb-48'
              />
              {activeIndex === 1 && (
                <table className="w-full">
                  <tbody className="text-left border-b border-gray-300">
                    <tr className="border-t border-gray-300">
                      <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100 w-170 sm:w-114">
                        所在地
                      </th>
                      <td className="text-body_2 p-24 font-normal">
                        {companyFormatData.prefecture}
                        {company.address}
                      </td>
                    </tr>
                    <tr className="border-t border-gray-300">
                      <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100 w-170 sm:w-114">
                        業種
                      </th>
                      <td className="text-body_2 p-24 font-normal">
                        {companyFormatData.business_type}
                      </td>
                    </tr>
                    <tr className="border-t border-gray-300">
                      <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100 w-170 sm:w-114">
                        事業内容
                      </th>
                      <td className="text-body_2 p-24 font-normal">{company.description}</td>
                    </tr>
                    <tr className="border-t border-gray-300">
                      <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100 w-170 sm:w-114">
                        社員数
                      </th>
                      <td className="text-body_2 p-24 font-normal">
                        {companyFormatData.employee_amount}
                      </td>
                    </tr>
                    <tr className="border-t border-gray-300">
                      <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100 w-170 sm:w-114">
                        社員採用担当者からのメッセージ
                      </th>
                      <td
                        className="text-body_2 p-24 font-normal"
                        dangerouslySetInnerHTML={{
                          __html: convertLineBreak(linkifyUrls(company.message)),
                        }}
                      ></td>
                    </tr>
                  </tbody>
                </table>
              )}
              {activeIndex === 2 && (
                <>
                  {(offer?.status === 2 || offer?.status === 3 || offer?.status === 4 || offer?.status === 8) ? (
                    <div className="text-center	">
                      <div className="w-148 sm:w-114 m-auto">
                        <img src="/images/blank_matchbou.png" alt="" />
                      </div>
                      <p className="mbx-typography--heading_4 mt-24">まだリアクションしていません</p>
                      <p className="text-body_1 mt-24">この企業のオファーに対してリアクションするとその内容が表示されます。</p>
                    </div>
                  ) : (
                    <table className="w-full">
                      <tbody className="text-left border-b border-gray-300">
                        <tr className="border-t border-gray-300">
                          <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100 w-170 sm:w-114">
                            リアクション
                          </th>
                          <td className="text-body_2 p-24 font-normal">
                            {statusText}
                          </td>
                        </tr>
                        <tr className="border-t border-gray-300">
                          <th className="text-subhead_2 text-blueGray-800 p-24 bg-gray-100 w-170 sm:w-114">
                            マイナビワークスへのメッセージ
                          </th>
                          <td
                            className="text-body_2 p-24 font-normal"
                            dangerouslySetInnerHTML={{ __html: convertLineBreak(linkifyUrls(offer?.comment)) }}
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </>
              )}
            </section>
          </ModalDefault>
        </>
      )}
    </>
  );
};
