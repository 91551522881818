import React, { VFC } from 'react';

type TProps = {
  className?: string;
  addressFurigana?: string;
  address?: string;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=92%3A345
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=100%3A278
 */
export const PreviewResumeAddress: VFC<TProps> = ({ className, addressFurigana, address }) => {
  return (
    <div className={className}>
      <hr className="mbx-keyline-horizontal--thin"></hr>
      <div className="px-8 pt-12 pb-10">
        <p className="mbx-typography--body_2">{addressFurigana}</p>
        <p className="mbx-typography--body_2 mt-4">{address}</p>
      </div>
    </div>
  );
};
