import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { FormContainerDropdown } from '@/componentsAdmin/Parts/Form';
import { Pager } from '@/componentsAdmin/Parts/Navigation';
import { BoxEmpty } from '@/componentsAdmin/Parts/OtherComponents';
import { HeaderTitle } from '@/componentsAdmin/Parts/Title/HeaderTitle';
import { SearchConditionsForDirect, UserModule } from '@/componentsAdmin/Search';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { useDirectSearchCondition } from '@/hooksAdmin/useDirectSearchCondition';
import { setSearchConditionSavedId } from '@/reduxAdmin/modules/adminDirectSearchCondition';
import { notificationError } from '@/reduxAdmin/modules/utils';
import {
  ADMINApi,
  AdminUserSearchModel,
  DirectSearchConditionModel,
  ResponseError,
} from '@/utils/api-client';
import { getQueryParams } from '@/utils/utils';
import { getSortData } from '@/utils/search';

import { SaveSearchDirectConditionsModal } from '../../../componentsAdmin/Search/SaveSearchDirectConditionsModal';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
interface IFormInputs {
  sort_by: string;
}

/**
 * Admin 企業向けの求職者検索一覧
 * @author 北村
 */
function AdminDirectSearchResult(): React.ReactElement {
  // ====================================================================================================
  //
  // Const
  //
  // ====================================================================================================
  const PAGE_LIMIT = 10;
  const API = new ADMINApi();

  // ====================================================================================================
  //
  // Hooks
  //
  // ====================================================================================================
  const dispatch = useDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const {
    searchCondition,
    fetchSearchUsers,
    searchUsersResult,
    postAdminSearchCondition,
    getSearchCondition,
    searchConditionResult,
    saveSearchCondition,
    // setTestSearchResultData
  } = useDirectSearchCondition();

  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(localStorage.getItem('adminDirectPage') ? Number(localStorage.getItem('adminDirectPage')) : 0);
  const [pageList, setPageList] = useState<number[]>(localStorage.getItem('adminDirectPageList') ? JSON.parse(localStorage.getItem('adminDirectPageList') || '') : []);
  const [pageLength, setPageLength] = useState<number>(0);
  const [numberStart, setNumberStart] = useState<number>(0);
  const [numberEnd, setNumberEnd] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [saveSearchConditionsTitle, setSaveSearchConditionsTitle] = useState<string>('');
  const [searchConditionsSaved, setSearchConditionsSaved] = useState<boolean>(false);
  const [savedSearchCondition, setSavedSearchCondition] =
    useState<DirectSearchConditionModel | null>(null);

  // ====================================================================================================
  //
  // RFH
  //
  // ====================================================================================================
  const methods = useForm<IFormInputs>({
    defaultValues: {
      sort_by: localStorage.getItem('adminDirectSortBy') || 'registration_date-desc',
    },
  });
  const { watch } = methods;

  // ====================================================================================================
  //
  // Methods
  //
  // ====================================================================================================
  const getSearchUsers = (offset: number) => {
    fetchSearchUsers({
      limit: PAGE_LIMIT,
      offset,
      ...getSortData(methods.getValues('sort_by')),
    });
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    const { search_condition_id } = getQueryParams(search);

    if (!searchCondition && !search_condition_id) {
      // 検索条件が存在しない場合は検索条件入力ページにリダイレクト
      history.replace('/admin/direct/search/');
    } else if (search_condition_id) {
      getSearchCondition(search_condition_id);
    } else if (searchCondition) {
      setSavedSearchCondition(searchCondition);
    }
  }, []);

  useEffect(() => {
    if (searchConditionResult) {
      saveSearchCondition(searchConditionResult as DirectSearchConditionModel);
      setSaveSearchConditionsTitle(searchConditionResult.search_condition_name);
      setSavedSearchCondition(searchConditionResult as DirectSearchConditionModel);
      setSearchConditionsSaved(true);
    }
  }, [searchConditionResult]);

  useEffect(() => {
    if (searchUsersResult) {
      const total = Number(searchUsersResult.total);
      setTotal(total);
      setPageLength(Math.ceil(total / PAGE_LIMIT));
    }
  }, [searchUsersResult]);

  useEffect(() => {
    if (searchUsersResult) {
      setNumberStart(page * PAGE_LIMIT + 1);
      setNumberEnd(page * PAGE_LIMIT + searchUsersResult.users.length);
    }
  }, [page, searchUsersResult]);

  useEffect(() => {
    if (searchCondition) {
        const offset = page * PAGE_LIMIT;
        getSearchUsers(offset);
    }
  }, [page]);


  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'sort_by') {
        setPage(0);
        getSearchUsers(0);
        setPageList([]);
        setPageLength(0);
        localStorage.setItem('adminDirectSortBy', value.sort_by);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);


  useEffect(() => {
    localStorage.setItem('adminDirectPage', String(page));
    localStorage.setItem('adminDirectPageList', JSON.stringify(pageList));
  }, [page, pageList]);

  useEffect(() => {
    return () => {
      localStorage.removeItem('adminDirectCondition');
      localStorage.removeItem('adminDirectPage');
      localStorage.removeItem('adminDirectPageList');
      localStorage.removeItem('adminDirectSortBy');
    }
  }, []);

  // ====================================================================================================
  //
  // Events
  //
  // ====================================================================================================
  const onClickUserModule = async (matchbox_id: string, is_read: boolean) => {
    if (is_read === false) {
      try {
        await API.postAdminSearchUsersRead({ matchbox_id });
      } catch (e) {
        const error = e as AxiosError<ResponseError>;
        if (error.response) {
          dispatch(notificationError(error.response.data.error_message));
        }
      }
    }
  };

  const onClickChangeSearchConditions = () => {
    if (searchConditionResult) {
      saveSearchCondition(searchConditionResult as DirectSearchConditionModel);
    }

    history.push('/admin/direct/search?reedit');
  };

  const onClickSaveSearchConditions = async () => {
    const searchConditionId = await postAdminSearchCondition(saveSearchConditionsTitle);
    dispatch(setSearchConditionSavedId(searchConditionId));
    setSearchConditionsSaved(true);
    setModalOpen(false);
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const condition = useMemo(() => {
    const condition = cloneDeep(savedSearchCondition);
    if (condition?.offer_status?.includes(6 || 7)) {
      const status = condition.offer_status.filter((s) => {
        if (s !== 6 && s !== 7) {
          return true;
        }
      });
      condition.offer_status = status;
    }
    if (condition?.offer_status?.includes(5)) {
      const status = condition.offer_status.filter((s) => {
        if (s !== 5) {
          return true;
        }
      });
      condition.offer_status = status;
    }

    return condition;
  }, [savedSearchCondition]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <AdminPage header={<HeaderTitle title="求職者検索  結果一覧" subTitle="企業への共有向け" />}>
      <>
        {searchUsersResult && (
          <div className="py-24">
            <div className="flex justify-end">
              <Link
                to="/admin/direct/conditions"
                className="font-bold text-blue-700 text-12_14 mb-24"
              >
                保存した条件を見る
              </Link>
            </div>
            <SearchConditionsForDirect
              saved={searchConditionsSaved}
              title={saveSearchConditionsTitle}
              conditions={condition}
              onClickChange={onClickChangeSearchConditions}
              onClickSave={() => setModalOpen(true)}
            />
            <div className="flex items-center justify-between mt-46">
              <div className="flex items-center justify-start">
                {searchUsersResult.users.length > 0 && (
                  <div className="text-12_20 mr-40">
                    {numberStart}〜{numberEnd} / {total}件
                  </div>
                )}
                {searchUsersResult.users.length <= 0 && <div className="text-12_20">{total}件</div>}
                {searchUsersResult.users.length > 0 && pageLength > 0 && (
                  <Pager
                    page={page}
                    pageLength={pageLength}
                    setPage={setPage}
                    setPageList={setPageList}
                    pageList={pageList}
                  />
                )}
              </div>
              <FormProvider {...methods}>
                <FormContainerDropdown
                  name="sort_by"
                  className="w-250"
                  selectList={FORM_OPTIONS.search_users_sort_type}
                />
              </FormProvider>
            </div>
            <div className="my-14">
              {searchUsersResult.users.length > 0 &&
                searchUsersResult.users.map((item) => {
                  return (
                    <UserModule
                      type="direct"
                      item={item as AdminUserSearchModel}
                      key={item.matchbox_id}
                      onClick={() => onClickUserModule(item.matchbox_id, Boolean(item.is_read))}
                    />
                  );
                })}
              {searchUsersResult.users.length <= 0 && <BoxEmpty message="検索結果がありません。" />}
            </div>
            <div className="flex items-center justify-start">
              {searchUsersResult.users.length > 0 && (
                <div className="text-12_20 mr-40">
                  {numberStart}〜{numberEnd} / {total}件
                </div>
              )}
              {searchUsersResult.users.length <= 0 && <div className="text-12_20">{total}件</div>}
              {searchUsersResult.users.length > 0 && pageLength > 0 && (
                <Pager
                  page={page}
                  pageLength={pageLength}
                  setPage={setPage}
                  setPageList={setPageList}
                  pageList={pageList}
                />
              )}
            </div>
          </div>
        )}
        <SaveSearchDirectConditionsModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          searchConditions={condition}
          onClickSave={onClickSaveSearchConditions}
          onClickCancel={() => setModalOpen(false)}
          setSaveSearchConditionsTitle={setSaveSearchConditionsTitle}
        />
      </>
    </AdminPage>
  );
}

export default AdminDirectSearchResult;
