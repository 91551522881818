import { DirectSearchConditionModel } from '@/utils/api-client';

export type State = {
  condition: DirectSearchConditionModel | null;
  savedId: number | null;
};

export const initialState: State = {
  condition: localStorage.getItem('adminDirectCondition')
    ? (JSON.parse(
        localStorage.getItem('adminDirectCondition') as string
      ) as DirectSearchConditionModel)
    : null,
  savedId: null,
};
