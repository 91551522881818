import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { BaseButton, ButtonIconButton } from '@/componentsDirect/Parts/Button/';
import { FormToggleButton } from '@/componentsDirect/Parts/Form/';
import { useOfferPositions } from '@/hooksDirect/useOfferPositions';
import { convertLineBreak, linkifyUrls, noDataText } from '@/utils/string';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

type TProps = {
  data: {
    id: string;
    name: string;
    body: string;
    active: boolean;
  };
  onDelete: (id: string) => boolean;
};

/**
 * ポジションリストデータ
 * TableContainerと共に利用する
 * https://www.figma.com/file/U3CKCcmEc7eIjxkbYoCZx7/UI?type=design&node-id=11781-162244&mode=dev
 */
export const TableBodyOfferPositionList = ({ data, onDelete }: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const history = useHistory();
  const [reactiveData, setReactiveData] = useState(data);
  const { patchOfferPositionId } = useOfferPositions();
  const { listeners, setNodeRef, transform, transition } = useSortable({ id: data.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  // ====================================================================================================
  //
  // method
  //
  // ====================================================================================================
  const onClick = () => {
    history.push({
      pathname: `/direct/offer_positions/${data.id}`,
    });
  };

  /**
   * 選択表示の切り替え
   * */
  const onClickToggleButton = () => {
    const patchData = {
      name: reactiveData.name,
      body: reactiveData.body,
      active: !reactiveData.active,
    };
    patchOfferPositionId(data.id + '', patchData);
    setReactiveData({
      id: reactiveData.id,
      name: reactiveData.name,
      body: reactiveData.body,
      active: !reactiveData.active,
    });
  };

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    setReactiveData(data);
  }, [data]);

  // ====================================================================================================
  //
  // jsx
  //
  // ====================================================================================================
  return (
    <tr className="text-left border-b border-gray-200" ref={setNodeRef} style={style}>
      {/* ボジション名 */}
      <td className="pl-16 py-14 font-bold text-subhead_2 whitespace-nowrap">
        {noDataText(data.name)}
      </td>
      {/* ポジション詳細 */}
      <td
        className="pl-16 py-14 text-subhead_2 pr-16"
        dangerouslySetInnerHTML={{
          __html: convertLineBreak(noDataText(linkifyUrls(data.body)) as string),
        }}
      ></td>

      {/* 削除機能 */}
      <td className="text-right pr-16 flex py-14">
        <div className="w-84">
          <BaseButton size="s" theme="secondary" className="w-full" onClick={onClick}>
            編集
          </BaseButton>
        </div>
        <FormToggleButton
          className="mbx-list_item__toggle"
          label="選択表示"
          checked={reactiveData.active}
          onClick={() => onClickToggleButton()}
        />
        <span className="dragHandle" {...listeners} data-cypress="draggable-handle">
          <ButtonIconButton type="gray" iconName="Sortable" />
        </span>
        <ButtonIconButton
          type="gray"
          iconName="Clear"
          onClick={(e) => {
            e.preventDefault();
            if (data.id) onDelete(data.id + '');
          }}
        />
      </td>
    </tr>
  );
};
