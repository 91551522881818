import React, { VFC } from 'react';

type TProps = {
  children: string;
  className?: string;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=1%3A92
 */
export const FormFootnote: VFC<TProps> = ({ children, className = '' }) => {
  return (
    <div className={className}>
      <p className="text-caption_1 py-8">*{children}</p>
    </div>
  );
};
