import { AxiosError } from 'axios';
import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { UserModule } from '@/components/common/Admin/SearchResult/UserModule';
import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { AdminPage } from '@/componentsDirect/Layout/Page';
import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { Pager } from '@/componentsDirect/Parts/Navigation/Page/Pager';
import { BoxEmpty } from '@/componentsDirect/Parts/OtherComponents/BoxEmpty';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { PickUpModal } from '@/componentsDirect/Users/Detail/PickUpModal';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { useUserTag } from '@/hooksDirect/useUserTag';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import { CandidateData } from '@/types/DataLayerType';
import { DirectApi, DirectUserSearchModel, Offer, ResponseError } from '@/utils/api-client';
import { genCandidateDataFromDirectUserSearchModel } from '@/utils/utils';

import { getSortData } from '../../utils/search';

interface IFormInputs {
  sort_by: string;
}

/**
 * タグ検索 結果画面 /direct/tag/{userTagId}
 * @ui https://www.figma.com/file/U3CKCcmEc7eIjxkbYoCZx7/UI?type=design&node-id=13050-108574&mode=design&t=XlHNSEBOL8TR89GA-4
 * @author s2 masa
 */
export const DirectTagResult = (): React.ReactElement => {
  // ====================================================================================================
  //
  // Variables
  //
  // ====================================================================================================
  const PAGE_LIMIT = 10;
  const API = new DirectApi();

  // ====================================================================================================
  //
  // Hooks
  //
  // ====================================================================================================
  const { userTagPath } = useParams<{ userTagPath: string }>();
  const history = useHistory();
  const { searchUsersResult, userTag, getUserTagSearchUsers, getUserTagPath } = useUserTag();

  // ====================================================================================================
  //
  // Context
  //
  // ====================================================================================================
  const dispatch = useDispatch();
  const { push } = React.useContext(DataLayerContext);

  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageList, setPageList] = useState<number[]>([]);
  const [pageLength, setPageLength] = useState<number>(0);
  const [numberStart, setNumberStart] = useState<number>(0);
  const [numberEnd, setNumberEnd] = useState<number>(0);
  const [pickupModalOpen, setPickupModalOpen] = useState<boolean>(false);
  const [selectedMatchboxId, setSelectedMatchboxId] = useState<string>('');
  const [users, setUsers] = useState<DirectUserSearchModel[]>([]);
  const [offerId, setOfferId] = useState<number | undefined>(undefined);
  const [doneIsOpen, setDoneIsOpen] = useState<boolean>(false);

  // ====================================================================================================
  //
  // RHF
  //
  // ====================================================================================================
  const methods = useForm<IFormInputs>({
    defaultValues: {
      sort_by: 'registration_date-desc',
    },
  });

  const { watch } = methods;

  // ====================================================================================================
  //
  // Methods
  //
  // ====================================================================================================
  useEffect(() => {
    getSearchUsers(0);
    getUserTagPath(userTagPath);
  }, []);

  useEffect(() => {
    if (searchUsersResult?.users) {
      setUsers(searchUsersResult.users);
      const candidates = searchUsersResult.users.map((user) =>
        genCandidateDataFromDirectUserSearchModel(user)
      );

      push({
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'page_view',
        pagination: `${page + 1}`,
        candidates: candidates.filter(
          (candidate: CandidateData | undefined) => candidate !== undefined
        ) as CandidateData[],
      });
    }
  }, [searchUsersResult]);

  /**
   * ソートが変更されたら再検索
   */
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'sort_by') {
        setPage(0);
        getSearchUsers(0);
        setPageList([]);
        setPageLength(0);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (searchUsersResult) {
      const total = Number(searchUsersResult.total);
      setTotal(total);
      setPageLength(Math.ceil(total / PAGE_LIMIT));
    }
  }, [searchUsersResult]);

  useEffect(() => {
    if (searchUsersResult) {
      setNumberStart(page * PAGE_LIMIT + 1);
      setNumberEnd(page * PAGE_LIMIT + searchUsersResult.users.length);
    }
  }, [page, searchUsersResult]);

  useEffect(() => {
    const offsetNum = page * PAGE_LIMIT;
    getSearchUsers(offsetNum);
  }, [page]);

  // ====================================================================================================
  //
  // Methods
  //
  // ====================================================================================================
  /**
   * ユーザー検索条件取得
   */
  const getSearchUsers = (offsetNum: number) => {
    (async () => {
      try {
        await getUserTagSearchUsers(userTagPath, {
          limit: PAGE_LIMIT,
          offset: offsetNum,
          ...getSortData(methods.getValues('sort_by')),
        });
      } catch (e) {
        //
      }
    })();
  };

  // ====================================================================================================
  //
  // Events
  //
  // ====================================================================================================
  const onSetPage = (page: number) => {
    setPage(page);
    push({
      event: 'pageView',
      actionType: 'page_view',
      actionName: 'page_view',
      pagination: `${page + 1}`,
    });
  };

  const onClickUserModule = async (matchbox_id: string, is_read: boolean) => {
    if (is_read === false) {
      try {
        await API.postDirectSearchUsersRead({ matchbox_id });
      } catch (e) {
        const error = e as AxiosError<ResponseError>;
        if (error.response) {
          dispatch(notificationError(error.response.data.error_message));
        }
      }
    }

    window.open(`/direct/users/${matchbox_id}`);
  };

  const onClickOfferPickup = (matchbox_id: string) => {
    setSelectedMatchboxId(matchbox_id);
    setPickupModalOpen(true);
  };

  const pickupCallback = async () => {
    dispatch(toggleLoading(true));
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const res: any = await new DirectApi().getDirectUserMatchboxId(selectedMatchboxId);
      const offer: Offer[] = res.data.user_admin_offers;
      const copyData = cloneDeep(users);
      const user = copyData.find((c) => c.matchbox_id === selectedMatchboxId);
      if (user?.offers) user.offers = offer;
      setUsers(copyData);
    } catch (e) {
      const error = e as AxiosError<ResponseError>;
      if (error.response) {
        dispatch(notificationError(error.response.data.error_message));
      }
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const doneOffer = (offerId: number) => {
    setOfferId(offerId);
    setDoneIsOpen(true);
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const selectedCandidate = useMemo<CandidateData | undefined>(() => {
    const user = users.find((user) => user.matchbox_id === selectedMatchboxId);
    if (!user) return;
    return genCandidateDataFromDirectUserSearchModel(user);
  }, [users, selectedMatchboxId]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <AdminPage
      header={<HeaderTitle title={`${userTag?.name ? userTag?.name : ''} 求職者一覧`} />}
      disableDataLayerPush
    >
      <>
        {searchUsersResult && (
          <div className="py-24">
            <div className="flex items-center justify-between mt-46">
              <div className="flex items-center justify-start">
                {searchUsersResult.users.length > 0 && (
                  <div className="text-12_20 mr-40">
                    {numberStart}〜{numberEnd} / {total}件
                  </div>
                )}
                {searchUsersResult.users.length <= 0 && <div className="text-12_20">{total}件</div>}
                {searchUsersResult.users.length > 0 && pageLength > 0 && (
                  <Pager
                    page={page}
                    pageLength={pageLength}
                    setPage={onSetPage}
                    setPageList={setPageList}
                    pageList={pageList}
                  />
                )}
              </div>
              <FormProvider {...methods}>
                <FormContainerDropdown
                  name="sort_by"
                  placeholder="選択してください"
                  className="w-250"
                  selectList={FORM_OPTIONS.search_users_sort_type}
                />
              </FormProvider>
            </div>
            <div className="my-14">
              {users.length > 0 &&
                users.map((item) => {
                  return (
                    <UserModule
                      type="direct"
                      item={item}
                      key={item.matchbox_id}
                      onClick={() => onClickUserModule(item.matchbox_id, Boolean(item.is_read))}
                      onClickOfferPickup={() => onClickOfferPickup(item.matchbox_id)}
                    />
                  );
                })}
              {searchUsersResult.users.length <= 0 && <BoxEmpty message="検索結果がありません。" />}
            </div>
            <div className="flex items-center justify-start">
              {searchUsersResult.users.length > 0 && (
                <div className="text-12_20 mr-40">
                  {numberStart}〜{numberEnd} / {total}件
                </div>
              )}
              {searchUsersResult.users.length <= 0 && <div className="text-12_20">{total}件</div>}
              {searchUsersResult.users.length > 0 && pageLength > 0 && (
                <div className="flex items-center justify-center">
                  <Pager
                    page={page}
                    pageLength={pageLength}
                    setPage={onSetPage}
                    setPageList={setPageList}
                    pageList={pageList}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        <PickUpModal
          modalIsOpen={pickupModalOpen}
          setModalIsOpen={setPickupModalOpen}
          matchboxId={selectedMatchboxId}
          asyncCallback={pickupCallback}
          additionalData={selectedCandidate}
          doneOffer={doneOffer}
        />
        <ModalDefault
          isOpen={doneIsOpen}
          onClose={() => setDoneIsOpen(false)}
          className="mbx-direct-users-detail-modal"
        >
          <div className="w-624 py-80">
            <p className="text-16_28 font-bold">ピックアップが完了しました。</p>
            <p className="mt-8 text-12_20">続けてアクションする場合は、このまま候補者管理ページへお進みください。</p>
            <p className="mt-2 text-12_20">※アクションとは「求職者へのオファー」や「マイナビワークスへの相談」などができる機能です。</p>

            <div className="flex justify-between space-x-16 mt-24 px-140">
              <BaseButton
                className="flex-1"
                size="m"
                theme="secondary"
                type="button"
                onClick={() => setDoneIsOpen(false)}
              >
                キャンセル
              </BaseButton>
              <BaseButton
                className="flex-1"
                size="m"
                theme="primary"
                type="button"
                onClick={() => history.push(`/direct/candidates/${offerId}`)}
              >
                候補者管理ページへ
              </BaseButton>
            </div>
          </div>
        </ModalDefault>
      </>
    </AdminPage>
  );
};
