import '@/styles/common/Direct/MyNaviSelectForm.scss';

import React from 'react';
import { AxiosError } from 'axios';
import { OnboardingPageWrapper } from './PageWrapper';
import { FormProvider, useForm, UseFormProps } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { PageDots } from './PageDots';
import {
    notificationError, toggleLoading
} from '@/redux/index';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormContainerTextArea,
  FormLabel,
  FormLayoutFieldset,
} from '@/componentsDirect/Parts/Form/';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ButtonTextLink } from '@/componentsDirect/Parts/Button/TextLink';
import { PLACEHOLDER } from '@/definitionDirect/PLACEHOLDER';
import { TEMPLATE } from '@/definitionDirect/TEMPLATE';
import {
  DirectCompanyModel,
  ResponseError
} from '@/utils/api-client';

type TStep = {
  step1: boolean,
  step2: boolean,
  step3: boolean,
  step4: boolean,
  step5: boolean,
  step6: boolean,
};

type Props = {
  page: number;
  step: TStep;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalDots: number;
  company: DirectCompanyModel | undefined;
  patchCompany: (request: DirectCompanyModel) => void;
  onNext: (number: number) => void;
  onPrev: (number: number) => void;
};

export const OnboardingPage_5 = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    message: YUP_VALIDATION.required,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const useFormProps: UseFormProps<any> = {
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: props.company,
  };

  const methods = useForm(useFormProps);

  const { handleSubmit, setValue } = methods;

  // 送信処理
  const onSubmit = async (data: DirectCompanyModel) => {
    if (!data) return;

    dispatch(toggleLoading(true));
    try {
      await props.patchCompany(data);
    } catch (e) {
      const error = e as AxiosError<ResponseError>;
      if (error.response) dispatch(notificationError(error.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
      props.onNext(5);
    }
  };

  return (
    <DataLayerPushContainer
      data={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'modal_open',
        virtualPageName: 'on-boarding_step_5'
      }}
    >
    <div>
      <OnboardingPageWrapper
        description={
          <span>求職者へのメッセージ</span>
        }
      >
        <div>
          <p className="mt-24 text-14_20 w-624 -ml-80">
            オファー時に通知できるメッセージを入力しましょう。<br />
            こちらを準備することで求職者（候補者）がオファーに応諾しやすくなります。<br />
            先輩社員のインタビュー記事など、アピール用のWebページURLも入力可能です。
            <p className="mt-8 text-11_16">
              ※求職者（候補者）から貴社へ直接応募できる導線URLを入力することはお控えください。
            </p>
          </p>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
              <div className="mt-26 flex justify-center">
                <FormLayoutFieldset className='w-full'>
                  <FormLabel label="求職者へのメッセージ" type="small">
                    <ButtonTextLink
                      onClick={(e) => {
                        e.preventDefault();
                        setValue("message", TEMPLATE.position_template.default);
                      }}
                      className="text-11_12 ml-8"
                      bold
                    >
                      テンプレートから⼊⼒する
                    </ButtonTextLink>
                  </FormLabel>
                  <FormContainerTextArea
                    name="message"
                    rows={8}
                    maxLength={1000}
                    placeholder={PLACEHOLDER.company_modal_message}
                    className="mkt_mask_items"
                  />
                </FormLayoutFieldset>
              </div>
              <div>
                <PageDots page={props.page} setPage={props.setPage} step={props.step} totalDots={props.totalDots} unClick className="mt-48" />
                <div className="w-224 m-auto mt-48">
                  <BaseButton
                    theme="primary"
                    size="m"
                    className="w-full"
                  >
                    保存して次へ
                  </BaseButton>
                </div>
              </div>
            </form>
          </FormProvider>
          <div className="w-224 m-auto mt-24 mb-20">
            <BaseButton
              theme="secondary"
              size="m"
              className="w-full"
              onClick={() => props.onPrev(5)}
            >
              もどる
            </BaseButton>
          </div>
        </div>
      </OnboardingPageWrapper>
    </div>
    </DataLayerPushContainer>
  );
};
