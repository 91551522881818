import { MasterApi } from '@/utils/api-client';
import { AdminMasterCp2Model, Portfolio, Status } from '@/utils/api-client/api';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

export const getAdminMasterCp2 = createAsyncThunk<AdminMasterCp2Model>(
  'adminMaster/cp2',
  async () => {
    const data = await new MasterApi().getAdminMasterCp2();
    return {
      cp2_consultation_status: data.data.cp2_consultation_status,
      cp2_registration_status: data.data.cp2_registration_status,
      cp2_charge_name: data.data.cp2_charge_name,
    };
  }
);

type TState = {
  portfolio: Portfolio & Status | undefined; //公開ポートフォリオのデータを保持する（ページ遷移時にパスワード認証状態を維持するため）
  cp2: AdminMasterCp2Model;
};

const initialState: TState = {
  portfolio: undefined,
  cp2: {
    cp2_consultation_status: [
      {
        value: '',
        children: '',
      },
    ],
    cp2_charge_name: [
      {
        value: '',
        children: '',
      },
    ],
    cp2_registration_status: [
      {
        value: '',
        children: '',
      },
    ],
  },
};

/**
 * APIから取得する定義情報
 */
export const adminMasterModule = createSlice({
  name: 'admin_master',
  initialState,
  reducers: {
    setPortfolio: (state: TState, action: PayloadAction<Portfolio & Status | undefined>): TState => {
      return { ...state, portfolio: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminMasterCp2.fulfilled, (state, action) => {
      state.cp2 = action.payload;
    });
    builder.addCase(getAdminMasterCp2.rejected, (state) => {
      state.cp2 = {
        cp2_consultation_status: [
          {
            value: 'test',
            children: 'APIエラー',
          },
        ],
        cp2_charge_name: [
          {
            value: 'test',
            children: 'APIエラー',
          },
        ],
        cp2_registration_status: [
          {
            value: 'test',
            children: 'APIエラー',
          },
        ],
      };
    });
  },
});

export const { setPortfolio } = adminMasterModule.actions;