import React from 'react';
import '@/styles/common/OtherComponents/CirclePlaceholder.scss';
import cn from 'classnames';

export type Props = {
  src?: string;
  className?: string;
};

function CirclePlaceholder(props: Props): React.ReactElement {
  return (
    <div
      className={ cn("mbx-circle-placeholder", props.className)}
      style={{backgroundImage: `url(${props.src ?? '/images/components/other_components/Circle-placeholder.png'})`}}
    />
  );
}

export default CirclePlaceholder;
