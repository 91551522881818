import React from 'react';
import { useSelector } from 'react-redux';

import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { UserCondition } from '@/componentsDirect/Parts/UserCondition/Forms';
import { RootState } from '@/reduxAdmin';

/**
 * 検索条件フォーム 就業状況
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65909
 */
export const AdminSearchFormSectionMWStatus = (): React.ReactElement => {
  const CP2MASTER = useSelector((state: RootState) => state.admin_master.cp2);
  return (
    <UserCondition title="マイナビワークス担当状況">
      <div className="mbx-utils-stack-v--22">
        <FormLayoutFieldset className="ml-24 w-376">
          <FormLabel label="マイナビワークス担当者" type="small"></FormLabel>
          <FormContainerDropdown
            name="mw_user"
            placeholder="選択してください"
            selectList={CP2MASTER.cp2_charge_name}
          />
        </FormLayoutFieldset>
      </div>
    </UserCondition>
  );
};
