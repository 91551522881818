export type State = {
  loading: boolean;
  forceLoading: boolean; // 強制ローディング表示用フラグ
  errorMessage: string | null;
  successMessage: string | null;
  pathAfterLogin: string | undefined; // ログイン成功後の遷移先
};

export const initialState: State = {
  loading: false,
  forceLoading: false,
  errorMessage: null,
  successMessage: null,
  pathAfterLogin: undefined,
};
