import React from 'react';

export const Ed9 = (): React.ReactElement => {
  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16">
          <img className={style.img} src="../images/Top/event/event_0009_mv.png" width="978" height="504" alt="" />
        </div>
        <h1 className="mt-56 sm:mt-30 text-29_46 sm:text-18_24 font-bold text-center">12/14（水）20:00 - <br />
          MATCHBOX活用ワークショップ <br />
          ～ 転職活動・書類作成の要！<br className="md:hidden lg:hidden" />プロフィール作成編 ～</h1>
        <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
          <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
            <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
            <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
          </div>
        </section>
        <section>
          <section>
            <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">以下に該当する方はオススメです。</h2>
            <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
              <ul className="text-16_28 sm:text-14_21">
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>転職活動の準備が進まない。何から始めたらよいか、どう考えれば良いか、どの形で進めれば良いかわからない。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>転職活動を始めているけど、書類選考が通過しない。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>選考書類をブラッシュアップしたい。客観的なアドバイスが欲しい。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>他の人のキャリアのまとめ方を知りたい。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>ポートフォリオ作成後、転職の相談をしたい。</span>
                </li>
                <li className="flex">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>MATCHBOXの効果的な使い方を知りたい。</span>
                </li>
              </ul>
            </div>
          </section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">ワークショップについて</h2>
          <div className="mb-30">
            <img src="../images/Top/event/event_0009_image.jpg" width="980" height="374" alt="" />
          </div>
          <p className="text-16_28 sm:text-14_21">
            現在Webディレクター、Webデザイナー職で転職を検討している方、転職活動の準備段階でご自分の経歴をどうやってまとめたらいいのかよくわからないという方に朗報です。履歴書、職務経歴書、ポートフォリオに書かなければならないプロフィール作成に焦点を当て、この度ワークショップを行います。このワークショップはプロフィールを作成するためにご自分の経歴を棚卸しする機会となります。 実施はオンライン（Zoom使用）での開催となります。 <br />
            <br />
            当日の前半は、助田氏からプロフィール作成のポイントや採用担当者の視点などの解説を中心にお話いただきます。その上で後半は、ご自身のキャリアをより伝わるプロフィールに展開するために、棚卸フォーマットを使ってソロワークをする時間を取ります。<br />
            個別に転職活動準備に関するアドバイスを受ける機会でもあり、同じWebディレクター・Webデザイナーとして転職を志す方のキャリアの伝え方を見ることができる貴重な機会にもなりますので、興味がある方はぜひお早めにご応募ください（PCでのご参加を推奨させていただきます）。
          </p>
          <section className="mt-80 sm:mt-55 p-40 sm:p-24 bg-gray-50 text-left">
            <h3 className="text-18_24 sm:text-14_21 font-bold">注意点</h3>
            <ul className="text-16_28 sm:text-14_21 mt-16">
              <li className="flex">
                <span>・</span><span>こちらのワークショップは流れとしてMATCHBOXをベースにプロフィールとしてご経歴をまとめていただく流れを取ります。そのため参加の条件として、MATCHBOXのアカウントをご準備ください。</span>
              </li>
              <li className="flex">
                <span>・</span><span>オンライン上ですが、積極的にコミュニケーションを取りたいと思いますので、できればカメラ・マイクオンでご参加ください。</span>
              </li>
              <li className="flex">
                <span>・</span><span>ソロワーク後、講義を踏まえて発表いただきます。ご了承いただいた上でご参加ください。</span>
              </li>
              <li className="flex">
                <span>・</span><span>ワークショップヘの応募、Zoomへの入室時ですが、MATCHBOXログインIDに指定しているメールアドレスをご使用ください。</span>
              </li>
            </ul>
          </section>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">講師プロフィール</h2>
          <div className="event-instructor box-line sm:p-16">
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0007_prof.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                助田 正樹<span className="inline-block">（MASAKI SUKEDA）</span>
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-40 sm:mb-18">
                SPEC. 代表 <br />
                一般社団法人 日本ディレクション協会 ファウンダー <br />
                株式会社ジュニ 顧問
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                桜美林大学経済学部を卒業後、メーカーでシックスシグマというフレームワークのR＆Dセクションに所属。2005年からインターネット系ベンチャー企業中心にWebディレクターとして数社経験。その後フリーランスとしてWebディレクション業務中心に活動。地域コミュニティサイト、採用サイト、モバイル＆デジタルサイネージなど様々なWebサイト、システムの構築・運用から、新規事業プロジェクト企画、立ち上げを経験。2012年6月に人材紹介＆コワーキングスペース事業を立ち上げ、取締役で8年間従事。Webディレクター中心に累計3,000名近くのキャリア相談を行う。日本ディレクション協会創設メンバーの1人。2020年4月より個人事業SPEC.の活動を開始、デジタル系企業数社の顧問を務める。
              </dd>
            </dl>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap" rowSpan={2}>
                  <span className="sm:block">20：00</span><span className="sm:block">～</span><span className="sm:block">22：00</span>
                </th>
                <td>
                  ＜前半＞
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>ワークショップ概要説明</span></li>
                    <li className="flex"><span>・</span><span>ポートフォリオのポイント説明</span></li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>
                  ＜後半＞
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>ソロワーク</span></li>
                    <li className="flex"><span>・</span><span>プレゼンテーション</span></li>
                    <li className="flex"><span>・</span><span>プレゼンに対するフィードバック</span></li>
                    <li className="flex"><span>・</span><span>ブリーフィング（まとめ）</span></li>
                    <li className="flex"><span>・</span><span>質疑応答</span></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <p>※ ポートフォリオ・転職相談については後日承ります。</p>
        </section>
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2022/12/14（水）20:00～ <br />
                  [入室開始] 19:45～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">定員</th>
                <td>20名</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">対象者</th>
                <td>転職活動中、もしくは、転職準備中のWebディレクター・デザイナーの方</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">準備物</th>
                <td>
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>ご自身のPC</span></li>
                    <li className="flex"><span>・</span><span>MATCHBOXのアカウントを作成しログイン状態でご参加ください。</span></li>
                    <li className="flex"><span>・</span><span>作成済み、もしくは、作成中の選考書類（履歴書・職務経歴書・ポートフォリオなど）</span></li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.ワークショップ申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink" href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.ワークショップ当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">開催について</h3>
          <p className="mt-30 sm:mt-16 text-16_28 sm:text-14_21">
            Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2,000名を超える「0からのWebディレクション講座」を生み出したメンバーでもあるSPEC.代表の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。          </p>
        </section>
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div>
    </div>
  );
};
