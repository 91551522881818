import cn from 'classnames';
import _ from 'lodash';
import React, { MouseEventHandler, useCallback, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { CSSTransition } from 'react-transition-group';

import { iconNameList } from '@/assets/fonts/manifest';
import { ButtonIconButton } from '@/componentsAdmin/Parts/Button/IconButton';
import { FormCounter } from '@/componentsAdmin/Parts/Form/Counter';
import { FormFootnote } from '@/componentsAdmin/Parts/Form/Footnote';
import { FormLayoutUnitDivider } from '@/componentsAdmin/Parts/Form/Layout/UnitDivider';
import { IconBase } from '@/componentsAdmin/Parts/Icon/';
import { delay } from '@/utils/delay';
import { addClass, removeClass } from '@/utils/dom';

type TProps = {
  name: string;
  id?: string;
  type?: 'text' | 'email' | 'password' | 'submit' | 'url'; // numberはクリアアイコンとnumberの操作UIが衝突するので使用しないようにする
  autocapitalize?: 'off' | 'none' | 'on' | 'sentences' | 'words' | 'characters';
  placeholder?: string;
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
  debugError?: boolean;
  defaultValue?: string; // 動的にフィールドを増やす際にはdefaultValueを呼び出し元から渡す
  onClick?: MouseEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  height?: 'large' | 'normal';
  unit?: string;
  fieldWidthClass?: string; // tailwindの w-{number} を指定
  disabledError?: boolean; // コンポーネントないでエラー表示をさせたくない場合に指定
  maxLength?: number;
  iconName?: typeof iconNameList[number];
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=325%3A32193
 *
 * default の値はhook-formのuseFormで指定
 * https://react-hook-form.com/api/useform
 *
 * typeは使いそうなものを一旦設定してますが基本textだけの利用になります。
 *
 */
export const FormContainerTextfield = ({
  id,
  autocapitalize = 'off',
  placeholder,
  name,
  type = 'text',
  className = '',
  readOnly = false,
  disabled = false,
  debugError = false,
  defaultValue,
  onClick,
  onBlur,
  height = 'normal',
  unit,
  fieldWidthClass = 'w-full',
  iconName,
  maxLength,
  disabledError = false,
}: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const errorRef = useRef(null);
  const methods = useFormContext();
  const watchValue = methods.watch(name);
  const defaultClass = cn('mbx-formContainer mbx-formContainer--textfield', {
    'mbx-formContainer--textfield--low': height === 'normal',
    'mbx-formContainer--with-icon': iconName,
  });
  const error = methods.formState.errors[name];
  const register = methods.register(name);

  // ====================================================================================================
  //
  // methods
  //
  // ====================================================================================================
  /**
   * 値をクリア
   */
  const onClear: MouseEventHandler<HTMLButtonElement> = () => {
    methods.setValue(register.name, '', {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  // cssのfocusだとclearボタンをクリックしようとする直前にfocusが外れてボタンがクリックできないので
  // onFocusとonBlurでclassを付けて対応
  const onFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
    addClass(e.currentTarget, 'focus');
  };
  const onFocusOut: React.FocusEventHandler<HTMLInputElement> = (e) => {
    const target = e.currentTarget;
    delay(500).then(() => {
      removeClass(target, 'focus');
    });
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    register.onChange(e);
  };

  const debouncedChangeHandler = useCallback(_.debounce(onChange, 100), []);
  return (
    <div className={className}>
      {readOnly ? (
        <div className="mbx-formContainer mbx-formContainer--textfield--readonly break-all">
          {watchValue}&nbsp;
        </div>
      ) : (
        <>
          <div
            className={cn('flex mbx-formContainer--textfield-wrapper', {
              'mbx-formContainer--error': error || debugError,
            })}
          >
            <div className={cn('relative', fieldWidthClass)}>
              {iconName && (
                <div className="absolute left-8 top-8">
                  <IconBase iconName={iconName} fontSize="20_20" />
                </div>
              )}
              <input
                type={type}
                className={cn(defaultClass)}
                id={id}
                onBlur={(e) => {
                  register.onBlur(e);
                  onFocusOut(e);
                  if (onBlur) {
                    onBlur(e);
                  }
                }}
                onFocus={onFocus}
                defaultValue={defaultValue}
                autoCapitalize={autocapitalize}
                onChange={debouncedChangeHandler}
                ref={register.ref}
                name={register.name}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
                onClick={onClick}
                maxLength={maxLength}
              />
              {watchValue && !disabled && (
                <div className="mbx-formContainer--textfield-clear">
                  <ButtonIconButton
                    type="delete"
                    iconName="Clear"
                    onClick={onClear}
                    hitArea="mini"
                    focus={false}
                  ></ButtonIconButton>
                </div>
              )}
            </div>
            {unit && (
              <FormLayoutUnitDivider className="flex-shrink-0 ml-8">{unit}</FormLayoutUnitDivider>
            )}
          </div>

          {maxLength && maxLength > 0 ? (
            <div className="flex flex-row">
              <FormCounter
                current={watchValue ? watchValue.length : 0}
                total={maxLength}
                className="flex-shrink ml-auto"
              ></FormCounter>
            </div>
          ) : null}

          {!disabledError && (
            <CSSTransition
              in={!!error || debugError}
              timeout={100}
              nodeRef={errorRef}
              unmountOnExit
              classNames="mbx-anime-error"
            >
              <div className="flex flex-row whitespace-nowrap" ref={errorRef}>
                <FormFootnote className="text-error-700">
                  {error ? error.message : debugError ? debugError : ''}
                </FormFootnote>
              </div>
            </CSSTransition>
          )}
        </>
      )}
    </div>
  );
};
