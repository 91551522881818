import React, { VFC, useEffect } from 'react';
import cn from 'classnames';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import '@/styles/common/Navigation/motivation-gauge.scss';

type TProps = {
  sp?: boolean;
  direct: string;
  value: 0 | 1 | 2 | 3 | 4 | 5;
};

export const MotivationGauge: VFC<TProps> = ({ value = 0, direct, sp }) => {
  const message = FORM_OPTIONS.change_date.map((d) => {
    return d.children;
  });
  useEffect(() => {
    //
  }, []);

  return (
    <div className={cn('mbx-motivation-gauge', { sp: sp })}>
      <ul>
        {[...Array(5)]
          .map((_, i) => i)
          .reverse()
          .map((item, key) => {
            return (
              <li key={key} className={cn({ active: item >= value })}>
                <img src="/images/navi-match.png" alt="" />
              </li>
            );
          })}
      </ul>
      <section>
        <h3>転職希望時期：</h3>
        <p className={`value-${value}`}>{message[value]}</p>
      </section>
      <section>
        <h3>オファー機能：</h3>
        <p>{ direct }</p>
      </section>
    </div>
  );
};
