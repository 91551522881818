import cn from 'classnames';
import React, { useState } from 'react';

import Modal from '@/components/common/Modal';
import MatchImg from '@/images/navi-match.png';

type TProps = {
  position?: Array<string>;
  className?: string;
  message: React.ReactNode;
  children: React.ReactNode;
  widthClassName?: string;
};

/**
 * Tooltip/Message/Matchbou
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=493%3A4422
 *
 * - positionはchildrenの要素の左上からの[left, top]の値
 *
 */
export const TooltipMessageMatchbou = ({
  position = ['0', '-94px'],
  className = '',
  message = '',
  children = '',
  widthClassName = 'w-288',
}: TProps): React.ReactElement => {
  const [hover, setHover] = useState(false);
  const [touch, setTouch] = useState(false);
  const itemHover = (flag: boolean): void => {
    const winW = window.innerWidth;
    if (winW > 768) {
      setHover(flag);
    }
  };
  const itemTouch = (): void => {
    const winW = window.innerWidth;
    if (winW <= 768) {
      setTouch(true);
    }
  };
  return (
    <>
      <div
        className={className}
        onPointerEnter={() => itemHover(true)}
        onPointerLeave={() => itemHover(false)}
        onClick={itemTouch}
      >
        <div
          className={cn('mbx-tooltip mbx-tooltip--matchbou mbx-tooltip--pc', widthClassName, {
            'mbx-tooltip--hover': hover,
          })}
          style={{ transform: 'translate(' + position[0] + ',' + position[1] + ')' }}
        >
          <i className="mbx-tooltip-icon">
            <img src={MatchImg} alt="" />
          </i>
          <p className="mbx-tooltip-message">{message}</p>
        </div>
        {children}
      </div>
      <Modal isOpen={touch} className={'mbx-tooltip-modal'} isClose={() => setTouch(false)}>
        <div className={cn('mbx-tooltip mbx-tooltip--matchbou mbx-tooltip--touch')}>
          <i className="mbx-tooltip-icon">
            <img src={MatchImg} alt="" />
          </i>
          <p className="mbx-tooltip-message">{message}</p>
        </div>
      </Modal>
    </>
  );
};
