import cn from 'classnames';
import React, { ReactNode } from 'react';

type TProps = {
  select?: boolean;
  className?: string;
  value: string | number;
  children: ReactNode;
  onClick?: (value: string) => void;
  disabled?: boolean;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=393%3A40447
 */
export const FormDropdownSelectionsItem = ({
  select = false,
  className,
  value,
  children,
  onClick,
  disabled = false,
}: TProps): React.ReactElement => {
  return (
    <li
      className={cn(className, 'mbx-dropdown-selections-item', 'text-14_20', 'px-10', 'py-14', {
        'is-select': select,
        'text-gray-400': disabled,
        'cursor-not-allowed': disabled,
      })}
      onClick={() => {
        if (disabled) return;
        if (onClick) onClick(value + '');
      }}
      data-value={value}
    >
      <i className="mbx-icon mbx-icon-Check"></i>
      <p>{children}</p>
    </li>
  );
};
