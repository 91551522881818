import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ContentsBlank } from '@/components/common/ContentsBlank';
import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { Filters } from '@/componentsAdmin/Offers/Filters';
import { OffersListTitle } from '@/componentsAdmin/Offers/ListTitle';
import { MatchingList } from '@/componentsAdmin/Offers/MatchingList';
import { PagerNoNumber } from '@/componentsDirect/Parts/Navigation/Page/PagerNoNumber';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import { ADMINApi, Offer, ResponseError } from '@/utils/api-client';

export const OffersTop = (): React.ReactElement => {
  const dispatch = useDispatch();

  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [offers, setOffers] = useState<Offer[]>();
  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [pending, setPending] = useState<boolean>(true);
  const [filterValue, setFilterValue] = useState({
    company_name: '',
    matchbox_id: '',
    user_name: '',
    job: '',
    status: '',
    mw_status: '',
    sort: '2',
  });

  // ====================================================================================================
  //
  // API
  //
  // ====================================================================================================
  const getOffer = async () => {
    dispatch(toggleLoading(true));
    try {
      console.log(sort);
      const res = await new ADMINApi().getAdminOffers(
        '10',
        offset.toString(),
        sort.by,
        sort.order,
        filterValue.status ? [Number(filterValue.status)] : [2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        undefined,
        Number(filterValue.job),
        filterValue.matchbox_id,
        Number(filterValue.mw_status) ? (Number(filterValue.mw_status) as 1 | 2 | 3) : undefined,
        filterValue.company_name,
        filterValue.user_name,
        undefined
      );
      if (res.data.offers) {
        setOffers(res.data.offers);
      }
      if (res.data.total !== undefined) {
        setTotal(res.data.total);
      }
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
      setPending(false);
    }
  };

  const onUpdate = async (id: number, status: number, mwStatus: number) => {
    const param = {
      id: id,
      status: status,
      is_read: true,
      mw_status: mwStatus,
    };
    dispatch(toggleLoading(true));
    try {
      await new ADMINApi().patchAdminOffer(param);
      getOffer();
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const offset = useMemo(() => {
    if (page === 0) return 0;
    return page * 10;
  }, [page]);

  const pageLength = useMemo(() => {
    if (total === 0) return 0;
    return Math.ceil(total / 10);
  }, [total]);

  const sort = useMemo(() => {
    switch (filterValue.sort) {
      case '1':
        return {
          order: 'desc',
          by: 'created_at',
        };
      case '2':
        return {
          order: 'desc',
          by: 'updated_at',
        };
      case '3':
        return {
          order: 'asc',
          by: 'updated_at',
        };
    }
    return {
      order: '',
      by: '',
    };
  }, [filterValue.sort]);

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    getOffer();
  }, []);

  useEffect(() => {
    if (pending) return;
    getOffer();
  }, [filterValue, page]);

  useEffect(() => {
    setPage(0);
  }, [filterValue]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <AdminPage header={<HeaderTitle title="マッチング履歴" />}>
      <section className="pb-146">
        <section className="mt-54">
          <Filters setFilterValue={setFilterValue} />
        </section>
        <p className="mbx-typography--body_2 mt-28">
          {page === 0 ? page + 1 : page + '1'}〜{page === pageLength - 1 ? total : page + 1 + '0'} /{' '}
          {total}件
        </p>
        <div className="mt-14">
          <OffersListTitle
            columns={[
              { title: 'ピックアップ日', className: 'w-84' },
              { title: 'MB ID', className: 'w-64' },
              { title: '求職者名', className: 'w-108' },
              { title: '企業名', className: 'w-122' },
              { title: 'ポジション', className: 'w-140' },
              { title: 'ステータス', className: 'w-199' },
              { title: '対応状況', className: 'w-40 pl-37 pr-37' },
            ]}
          />
          {offers?.length === 0 ? (
            <ContentsBlank
              className="mt-50"
              title="マッチングがまだ発生していません。"
              text={
                <span>
                  マイナビワークスから、
                  <br />
                  企業・求職者にコンタクトが必要な案件が表示されます。
                </span>
              }
            />
          ) : (
            offers?.map((offer: Offer, index: number) => {
              return (
                <div key={index}>
                  <MatchingList offer={offer} onUpdate={onUpdate} />
                </div>
              );
            })
          )}
        </div>
        {total !== 0 && (
          <PagerNoNumber className="mt-26" page={page} total={total} setPage={setPage} />
        )}
      </section>
    </AdminPage>
  );
};
