import '@/styles/pages/Account/Login.scss';

import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BaseButton from '@/components/common/Button/BaseButton';
import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { FormContainerTextfield } from '@/components/common/Form/Container/Textfield';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import * as Page from '@/components/common/Page';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import { auth, isLoggedIn, notificationError, toggleLoading, userInfo } from '@/redux/index';
import { DataLayerType } from '@/types/DataLayerType';
import { AuthApi, ResponseError, UserApi } from '@/utils/api-client';

type IFormInputs = {
  id: string;
  password: string;
};

function Login(): React.ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const methods = useForm<IFormInputs>({
    defaultValues: {
      id: '',
      password: '',
    },
  });
  const [input, setInput] = useState<IFormInputs>({ id: '', password: '' });
  const { handleSubmit, watch } = methods;
  const { push } = useContext(DataLayerContext);

  useEffect(() => {
    setInput({
      id: watch('id'),
      password: watch('password'),
    });
  }, [watch('id'), watch('password')]);

  const onLogin = async () => {
    if(location.search){
      history.push(location.pathname + location.search);
    } else {
      history.push(location.pathname);
    }
    dispatch(toggleLoading(true));
    const param: DataLayerType = {
      event: 'loggedIn',
      actionType: 'logged_in',
    };
    try {
      await new AuthApi().postAuthLogin(input.id, input.password);
      dispatch(isLoggedIn());
      const user = await new UserApi().getUser();
      if (user.data?.user_info) {
        dispatch(userInfo(user.data?.user_info));
      }

      dispatch(auth(true));
      param.actionName = 'logged_in_success';
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      param.actionName = 'logged_in_failure';
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      push(param);
      dispatch(toggleLoading(false));
    }
  };

  return (
    <section className="login">
      <Page.Wrapper
        header={
          <HeaderTitle title={'ログイン'}>
            <></>
          </HeaderTitle>
        }
      >
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(() => {
              /** */
            })}
          >
            <div className="login-form w-308 sm:w-full m-auto mt-48 sm:mt-24">
              <FormLayoutFieldset>
                <FormLabel label="ログインID (メールアドレス)" type="small" />
                <FormContainerTextfield name="id" className="mkt_mask_items" />
              </FormLayoutFieldset>
              <FormLayoutFieldset className="mt-16">
                <FormLabel label="パスワード" type="small" />
                <FormContainerTextfield
                  name="password"
                  type="password"
                  className="mkt_mask_items"
                />
              </FormLayoutFieldset>
              <BaseButton theme="primary" size="m" className="btn mt-48" onClick={onLogin}>
                ログイン
              </BaseButton>
              <div className="flex flex-col justify-center sm:pb-64">
                <BaseButton theme="link" size="m" href="/entry" className="mt-64">
                  アカウント登録
                </BaseButton>
                <BaseButton theme="link" size="m" href="/login/reminder" className="mt-40">
                  パスワードを忘れた方はこちら
                </BaseButton>
              </div>
            </div>
          </form>
        </FormProvider>
      </Page.Wrapper>
    </section>
  );
}

export default Login;
