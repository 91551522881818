import React from 'react';
import { Route } from 'react-router-dom';

import { AdminPage } from '@/componentsDirect/Layout/Page';
import { TabNavigationMatchUrl } from '@/componentsDirect/Parts/Navigation/TabNavigationMatchUrl';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { DirectUserAccount } from '@/pagesDirect/User/Account';
import { DirectUserProfile } from '@/pagesDirect/User/Profile';

/**
 * Direct 個人設定 リンクページ
 *
 * 下層部分の処理はindexDirect.tsxのRouter側で処理
 */
export const DirectUserMain = (): React.ReactElement => {
  const menu = [
    {
      title: 'プロフィール設定',
      url: '/direct/user/profile',
    },
    {
      title: 'アカウント設定',
      url: '/direct/user/account',
    },
  ];

  return (
    <AdminPage header={<HeaderTitle title="個人設定" />} disableDataLayerPush>
      <>
        <TabNavigationMatchUrl
          align="fit"
          menu={menu}
          className="w-624 mt-24 mb-48 mx-auto"
        ></TabNavigationMatchUrl>
        <Route path="/direct/user/profile" exact component={DirectUserProfile}></Route>
        <Route path="/direct/user/account" exact component={DirectUserAccount}></Route>
      </>
    </AdminPage>
  );
};
