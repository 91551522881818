import React, { useState } from 'react';

export const TableHeader = (): React.ReactElement => {
  const [toolTip, setTooltip] = useState({ everyone: false, myself: false, action: false });

  return (
    <section className="table-header pl-16 text-10_24 text-blue-700 bg-gray-200 border-b border-gray-300">
      <div className="flex items-center h-40">
        <div className="w-84">
          <p>ピックアップ日</p>
        </div>
        <div className="w-62 ml-16">
          <p>MB ID</p>
        </div>
        <div className="ml-16 w-135">
          <p>職種</p>
        </div>
        <div className="ml-16 w-80">
          <p>ステータス</p>
        </div>
        <div className="ml-16 w-60">
          <p>ポジション</p>
        </div>
        <div className="ml-16 text-center w-40">
          <p>マッチ度</p>
        </div>
        <div
          className="w-60 ml-16 cursor-pointer"
          onMouseOver={() => setTooltip({ everyone: true, myself: false, action: false })}
          onMouseOut={() => setTooltip({ everyone: false, myself: false, action: false })}
        >
          <p>みんなの意見</p>
          <i className="mbx-icon mbx-icon-Info"></i>
          {toolTip.everyone && (
            <div className="tool-tip w-195">
              <p className="mbx-typography--caption_2">社内で意見した人数を表示します。</p>
            </div>
          )}
        </div>
        <div
          className="w-60 cursor-pointer ml-34"
          onMouseOver={() => setTooltip({ everyone: false, myself: true, action: false })}
          onMouseOut={() => setTooltip({ everyone: false, myself: false, action: false })}
        >
          <p>じぶんの意見</p>
          <i className="mbx-icon mbx-icon-Info"></i>
          {toolTip.myself && (
            <div className="tool-tip w-211">
              <p className="mbx-typography--caption_2">自分が意見したかどうかを表示します。</p>
            </div>
          )}
        </div>
        <div
          className="w-50 ml-auto cursor-pointer"
          onMouseOver={() => setTooltip({ everyone: false, myself: false, action: true })}
          onMouseOut={() => setTooltip({ everyone: false, myself: false, action: false })}
        >
          <p>アクション</p>
          <i className="mbx-icon mbx-icon-Info"></i>
          {toolTip.action && (
            <div className="tool-tip w-280">
              <p className="mbx-typography--caption_2">
                「みんなの意見」を元に管理者が「オファーする」「マイナビワークスに相談する」「見送る」のいずれかのアクションを起こしたかどうかを表示します。
              </p>
            </div>
          )}
        </div>
        <div className="w-24 ml-auto pr-10"></div>

        <div className="w-66 ml-auto pr-10"></div>
      </div>
    </section>
  );
};
