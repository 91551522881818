import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Draggable, OnDropCallback } from 'react-smooth-dnd';

import { ListItem } from '@/components/common/Admin/FavoriteConditions/ListItem';
import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { BoxEmpty } from '@/componentsDirect/Parts/OtherComponents/BoxEmpty';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { useSearchCondition } from '@/hooksAdmin/useSearchCondition';
import { notificationError, notificationSuccess } from '@/reduxAdmin/modules/utils';
import { TConditionsFormValues } from '@/types/Pages/SearchConditions';
import {
    ADMINApi, AdminSearchConditionModel, AdminSearchConditionResponse, ResponseError
} from '@/utils/api-client';

// import { testConditionsData } from '@/debug/adminConditions';

export const AdminConditions = (): React.ReactElement => {
  const AdminAPI = new ADMINApi();
  const dispatch = useDispatch();
  const history = useHistory();
  const [conditions, setConditions] = useState<AdminSearchConditionResponse[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const { saveSearchCondition } = useSearchCondition();

  const methods = useForm<TConditionsFormValues>({
    defaultValues: {
      conditions,
    },
  });

  const { fields, move, remove } = useFieldArray<TConditionsFormValues, 'conditions', 'uid'>({
    control: methods.control,
    name: 'conditions',
    keyName: 'uid',
  });

  useEffect(() => {
    AdminAPI.getAdminSearchConditions()
      .then((res) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { conditions } = res.data as any;
        setConditions(conditions);

        // ↓ 一旦テストデータで確認できるように
        // setTestData();

        setLoaded(true);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) {
          dispatch(notificationError(error.response.data.error_message));
        }
      });
  }, []);

  useEffect(() => {
    methods.setValue('conditions', conditions);
  }, [conditions]);

  const onDrop: OnDropCallback = ({ removedIndex, addedIndex }) => {
    if (removedIndex !== null && addedIndex !== null) {
      move(removedIndex, addedIndex);

      const conditions: AdminSearchConditionResponse[] = methods.getValues(
        `conditions`
      ) as AdminSearchConditionResponse[];
      const ids = conditions.map((item) => {
        return item.id;
      });

      AdminAPI.patchAdminSearchConditions({ ids })
        .then(() => {
          dispatch(notificationSuccess('保存しました。'));
        })
        .catch((error: AxiosError<ResponseError>) => {
          if (error.response) {
            dispatch(notificationError(error.response.data.error_message));
          }
        });
    }
  };

  const onRemove = (index: number, id: number) => {
    console.log('onRemove', index, methods.getValues('conditions'));
    remove(index);

    AdminAPI.deleteAdminSearchConditionId(id)
      .then(() => {
        dispatch(notificationSuccess('保存しました。'));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) {
          dispatch(notificationError(error.response.data.error_message));
        }
      });
  };

  const onClickEditButton = (id: number) => {
    // 保存した検索条件の編集画面に遷移
    history.push(`/admin/conditions/${id}`);
  };

  const onClickSearchButton = (index: number, search_condition_id: number) => {
    const condition = methods.getValues(`conditions`)[index];
    saveSearchCondition(condition as AdminSearchConditionModel);
    history.push(`/admin/search/result?search_condition_id=${search_condition_id}`);
  };

  const onChangeDirectMail = (index: number) => {
    const condition = methods.getValues(`conditions`)[index];

    if (condition) {
      AdminAPI.patchAdminDirectMailId(condition.id, {
        direct_mail: condition.direct_mail as boolean,
      })
        .then(() => {
          dispatch(notificationSuccess('保存しました。'));
        })
        .catch((error: AxiosError<ResponseError>) => {
          if (error.response) {
            dispatch(notificationError(error.response.data.error_message));
          } else {
            dispatch(notificationError('保存に失敗しました'));
          }
        });
    }
  };

  // テストデータ
  // const setTestData = () => {
  //   setConditions(testConditionsData());
  // };

  return (
    <AdminPage header={<HeaderTitle title="保存した検索条件" />}>
      <div className="mt-48 mb-80">
        {loaded && fields.length <= 0 && <BoxEmpty message="検索条件がありません。" />}
        {loaded && fields.length > 0 && (
          <FormProvider {...methods}>
            <Container
              onDrop={onDrop}
              dragHandleSelector=".dragHandle"
              lockAxis="y"
              render={(ref) => {
                return (
                  <div ref={ref} className="">
                    {fields.map((item, index) => (
                      <Draggable key={item.uid} className="">
                        <ListItem
                          key={item.uid}
                          index={index}
                          item={item}
                          type="admin"
                          onClickRemoveButton={() => onRemove(index, item.id)}
                          onClickSearchButton={() => {
                            onClickSearchButton(index, item.id);
                          }}
                          onClickEditButton={() => onClickEditButton(item.id)}
                          onChangeDirectMail={() => onChangeDirectMail(index)}
                        />
                      </Draggable>
                    ))}
                  </div>
                );
              }}
            />
          </FormProvider>
        )}
      </div>
    </AdminPage>
  );
};
