import cn from 'classnames';
import React, { useMemo } from 'react';
import { FieldArrayWithId } from 'react-hook-form';

import { ButtonIconButton } from '@/components/common/Button/';
import {
    FormContainerTextfield, FormLayoutFieldset, FormLayoutUnitDivider
} from '@/components/common/Form/index';
import { TQualificationFormValues } from '@/types/Pages/CareerHistory';
import { QualificationQTypeEnum } from '@/utils/api-client';

type TFieldProps = {
  index: number;
  item: FieldArrayWithId<TQualificationFormValues, 'qualifications', 'uid'>;
  onVisible?: React.MouseEventHandler<HTMLButtonElement>;
  onRemove?(): void;
};

export const Qualification: React.VFC<TFieldProps> = ({ index, item, onVisible, onRemove }) => {
  const title = useMemo(() => {
    switch (item.q_type) {
      case QualificationQTypeEnum.Text:
        return '';
      case QualificationQTypeEnum.Toeic:
        return 'TOEIC';
      case QualificationQTypeEnum.Toefl:
        return 'TOEFL';
    }
  }, [item.q_type]);

  return (
    <FormLayoutFieldset>
      <></>
      <div className="md:flex lg:flex md:space-x-8 lg:space-x-8 sm:space-y-8">
        <div className={cn(
            'flex space-x-8',
            {
              'opacity-20': !item.q_visible,
            }
          )}
        >
          <FormContainerTextfield
            type="number"
            name={`qualifications.${index}.q_date_year`}
            placeholder="yyyy"
            className="w-80"
          />
          <FormLayoutUnitDivider>年</FormLayoutUnitDivider>
          <FormContainerTextfield
            type="number"
            name={`qualifications.${index}.q_date_month`}
            placeholder="mm"
            className="w-80"
          />
          <FormLayoutUnitDivider className="w-auto">月</FormLayoutUnitDivider>
        </div>

        <div className="flex space-x-8 flex-1">
          {title !== '' && (
            <FormLayoutUnitDivider className={cn(
                'w-auto',
                {
                'opacity-20': !item.q_visible,
                }
              )}
            >{title}</FormLayoutUnitDivider>
          )}

          {item.q_type !== QualificationQTypeEnum.Text ? (
            <>
              <FormContainerTextfield
                name={`qualifications.${index}.q_score`}
                placeholder={item.q_type === QualificationQTypeEnum.Toeic ? '例）780' : '例）80'}
                className={cn(
                  'flex-1 mkt_mask_items',
                  {
                    'opacity-20': !item.q_visible,
                  }
                )}
              />

              <FormLayoutUnitDivider className={cn(
                  'w-auto',
                  {
                    'opacity-20': !item.q_visible,
                  }
                )}
              >点取得</FormLayoutUnitDivider>
            </>
          ) : (
            <FormContainerTextfield
              name={`qualifications.${index}.q_title`}
              placeholder="例）秘書技能検定 2級取得"
              className={cn(
                'flex-1 mkt_mask_items',
                {
                  'opacity-20': !item.q_visible,
                }
              )}
            />
          )}
          <div className="flex sm:hidden">
            <ButtonIconButton type="gray" iconName="Sortable" className={cn(
                'dragHandle',
                {
                  'opacity-20': !item.q_visible,
                }
              )}
            />
            <ButtonIconButton
              type="gray"
              iconName={item.q_visible ? 'Visible' : 'Invisible'}
              onClick={onVisible}
              focus={false}
            ></ButtonIconButton>
            {item.q_type === QualificationQTypeEnum.Text ? (
              <ButtonIconButton type="gray" iconName="Clear" onClick={onRemove} />
            ) : (
              <ButtonIconButton type="gray" iconName="Clear" className="opacity-10" />
            )}
          </div>
        </div>
      </div>
    </FormLayoutFieldset>
  );
};
