import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pageList } from '@/definition/PAGE_LIST';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { setDataLayer } from '@/redux/index';
import { State } from '@/redux/state';
import { DataLayerType, DeviceCategory } from '@/types/DataLayerType';
import { Repeat } from '@/types/Util';
import {
    CareerHistoryApi, MyNaviApi, PortfolioApi, ResumeApi, UserInfo, UserPatchRequest
} from '@/utils/api-client/api';
import { getOptionChildrenFromValue } from '@/utils/optionValueToChildren';
import {
    basicInfoFromUserInfo, percentFromProgress, progressFromBasicInfo, progressFromCareerHistory,
    progressFromMyNavi, progressPercent
} from '@/utils/utils';

import { useWindowSize } from './useWindowSize';

const pickUpPage = (virtualPageName?: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isPathMatched = (page: any) => {
    if (!page.path) return false;
    if (typeof page.path === 'string') {
      return page.path === window.location.pathname;
    } else {
      return (page.path as RegExp).test(window.location.pathname + window.location.search);
    }
  };

  if (virtualPageName) {
    const vPageCandidate = pageList.filter((page) => page.virtualPage === virtualPageName);
    if (vPageCandidate.length === 1) {
      return vPageCandidate[0];
    } else if (vPageCandidate.length > 2) {
      const candidate = vPageCandidate.filter(isPathMatched);
      if (candidate.length === 1) {
        return candidate[0];
      }
    }
  }

  const pageCandidate = pageList.filter(isPathMatched);
  if (pageCandidate.length >= 1) {
    return pageCandidate[0];
  } else {
    return undefined;
  }
};

const pageCategoryFromPathName = (virtualPageName: string | undefined): string => {
  const page = pickUpPage(virtualPageName);
  return page?.category || '';
};

const pageTitleFromPathName = (virtualPageName: string | undefined): string => {
  const page = pickUpPage(virtualPageName);
  return page?.title || '';
};

const pageVirtualPageNameFromPathName = (): string => {
  const page = pickUpPage();
  return page?.virtualPage || 'N/A';
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let onReady: (result: DataLayerType) => void, onError: any;
let pushIsReady: Promise<DataLayerType>;

const pushDataLayer = (additionalData: DataLayerType) => {
  const data: DataLayerType = {
    userType: 'consumer',
    url: window.location.href,
    pageCategory: pageCategoryFromPathName(additionalData.virtualPageName),
    pageTitle: pageTitleFromPathName(additionalData.virtualPageName),
    virtualPageName: pageVirtualPageNameFromPathName(),
    errorStatus: 'N/A',
    pagination: 'N/A',

    //   'event': '{[MKT_イベント一覧_toC]シート参照}',
    //   'actionType': '{[MKT_イベント一覧_toC]シート参照}',
    //   'actionName': '{[MKT_イベント一覧_toC]シート参照}',
  };
  Object.keys(additionalData).forEach((keyString) => {
    const key = keyString as keyof DataLayerType;
    const value = additionalData[key];
    if (value !== 'N/A') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data[key] = value as any;
    }
  });

  dataLayer.push(data);
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useDataLayer() {
  const dispatch = useDispatch();
  const isAuth: boolean | undefined = useSelector((state: State) => state.isAuth);
  const isLoggedIn: boolean = useSelector((state: State) => state.isLoggedIn);
  const isLoggedOut: boolean = useSelector((state: State) => state.isLoggedOut);
  const dataLayer: DataLayerType = useSelector((state: State) => state.dataLayer);
  const dataLayerChangedBy: string | null = useSelector((state: State) => state.dataLayerChangedBy);
  const user: UserInfo | null = useSelector((state: State) => state.user);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [resumeProgressData, setResumeProgress] = useState<any | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [result, setResult] = useState<DataLayerType>();

  const mq = useMBXMediaQuery();
  const windowSize = useWindowSize();

  const deviceCategory = useMemo<DeviceCategory>(() => {
    if (mq.lg) {
      return 'desktop';
    }
    if (mq.md) {
      return 'tablet';
    }
    return 'mobile';
  }, [windowSize]);

  const unResolve = () => {
    pushIsReady = new Promise<DataLayerType>((resolve, reject) => {
      onReady = resolve;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onError = reject;
    });
  };

  useEffect(() => {
    // リロードしたとき
    unResolve();
  }, []);

  useEffect(() => {
    if (isLoggedOut) {
      // ログアウト状態に変わったとき
      setResumeProgress(undefined);
      unResolve();
    }
  }, [isLoggedOut]);

  useEffect(() => {
    if (isLoggedIn) {
      // ログイン状態に変わったとき
      unResolve();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    // dataLayerが更新されたとき
    if (dataLayerChangedBy === null) return;
    unResolve();
  }, [dataLayerChangedBy]);

  const refreshAllData = async (user_id: string) => {
    //MyNavi関連情報
    const res1 = await new MyNaviApi().getMynaviCreator();
    const myNavi = res1.data.mynavi_creator;
    dispatch(
      setDataLayer({
        timeToCareerChange: getOptionChildrenFromValue(
          'change_date',
          myNavi?.mc_preferred_change_date
        ),
        conditionCompleteness: `${myNavi ? progressFromMyNavi(myNavi) : 0}`,
      })
    );

    //Portfolio関連情報
    const res2 = await new PortfolioApi().getPortfolioEdit(user_id);
    const portfolio = res2.data;
    const items = portfolio?.items ? Array.from(portfolio?.items) : [];
    const progress = [];
    for (const item of items) {
      const res = await new PortfolioApi().getPortfolioProgressDetail(user_id, `${item.i_id}`);
      progress.push(progressPercent(res.data.progress));
    }
    dispatch(
      setDataLayer({
        portfolioItemNum: `${items.length}`,
        portfolioCompleteness: `${progress?.join('|')}`,
      })
    );

    //Resume進捗関連情報
    const res3 = await new ResumeApi().getResumeProgress();
    setResumeProgress(res3.data);

    //CareerHistory進捗関連情報
    const res4 = await new CareerHistoryApi().getCareerHistory(user_id);
    const careerHistory = res4.data;
    const careerHistoryProgress = progressFromCareerHistory(careerHistory);
    dispatch(
      setDataLayer({
        workHistoryCompleteness: `${percentFromProgress(careerHistoryProgress)}`,
      })
    );
  };

  useEffect(() => {
    (async () => {
      if (user && user?.user_id && isAuth) {
        await refreshAllData(user?.user_id);
      }
    })();
  }, [user, isAuth]);

  const resumeProgressPercent = useMemo<number | undefined>(() => {
    if (resumeProgressData === undefined) return undefined;
    const progress: Repeat<0 | 1, 4 | 10> = resumeProgressData.progress;
    return percentFromProgress(progress);
  }, [resumeProgressData]);

  useEffect(() => {
    dispatch(
      setDataLayer({
        resumeCompleteness: `${resumeProgressPercent}`,
      })
    );
  }, [resumeProgressPercent]);

  const basicInfo = useMemo<UserPatchRequest>(() => basicInfoFromUserInfo(user), [user]);

  const userData = useMemo<DataLayerType>(
    () => ({
      userId: user?.matchbox_id,
      mbOfferStatus: user?.use_direct ? 'on' : 'off',
      profileCompleteness: `${progressFromBasicInfo(basicInfo, user)}`,
    }),
    [user, basicInfo]
  );

  useEffect(() => {
    let result: DataLayerType = {};
    if (isAuth === undefined) return; //ログイン状態がわからないときはpromiseを解決しない
    if (isAuth) {
      if (!user || !user?.user_id) return;
      if (resumeProgressPercent === undefined) return;
      if (dataLayer.timeToCareerChange === undefined) return;
      if (!dataLayer.conditionCompleteness) return;
      if (!dataLayer.portfolioItemNum) return;
      if (dataLayer.portfolioCompleteness === undefined) return;
      if (!dataLayer.workHistoryCompleteness) return;
      if (!dataLayer.resumeCompleteness) return;
    }

    if (!isAuth) {
      result = {
        userId: '',
        deviceCategory,
        conditionCompleteness: '',
        mbOfferStatus: 'off',
        portfolioCompleteness: '',
        portfolioItemNum: '',
        profileCompleteness: '',
        resumeCompleteness: '',
        timeToCareerChange: '',
        workHistoryCompleteness: '',
      };
    } else {
      result = {
        deviceCategory,
        ...userData,
        ...dataLayer,
      };
    }

    setResult((prev) => {
      if (
        prev?.userId !== result.userId ||
        prev?.mbOfferStatus !== result.mbOfferStatus || // {オファー機能利用ステータス}
        prev?.timeToCareerChange !== result.timeToCareerChange || // {転職希望時期}
        prev?.portfolioItemNum !== result.portfolioItemNum || // {ポートフォリオ作品登録数}
        prev?.portfolioCompleteness !== result.portfolioCompleteness || // {ポートフォリオ完成度（全作品）}
        prev?.workHistoryCompleteness !== result.workHistoryCompleteness || // {職務経歴書完成度}
        prev?.resumeCompleteness !== result.resumeCompleteness || // {履歴書完成度}
        prev?.profileCompleteness !== result.profileCompleteness || // {基本情報完成度}
        prev?.conditionCompleteness !== result.conditionCompleteness // {経験・希望条件完成度}
      ) {
        //結果が違う（更新された）場合のみ解決する
        onReady(result);
      }
      return result;
    });
  }, [userData, resumeProgressPercent, deviceCategory, isAuth, dataLayer, isLoggedIn]);

  const push = (additionalData: DataLayerType) => {
    (async () => {
      const basicInfo = await pushIsReady;
      pushDataLayer({ ...basicInfo, ...additionalData });
    })();
  };

  return { push };
}
