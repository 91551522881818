import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { MESSAGE } from '@/definitionDirect/MESSAGE';
import { YUP_VALIDATION } from '@/definitionDirect/VALIDATION';
import { useUser } from '@/hooksDirect/useUser';
import { PasswordChangeParam } from '@/utils/api-client/api';
import { yupResolver } from '@hookform/resolvers/yup';

/**
 * 個人設定 アカウント メールアドレス変更
 */
export const DirectUserSectionChangePassword = (): React.ReactElement => {
  const { postPasswordChange } = useUser();
  const { push } = React.useContext(DataLayerContext);

  /**
   * validation 設定
   */
  const schema = yup.object().shape({
    password_current: YUP_VALIDATION.password,
    password_new: YUP_VALIDATION.password,
    password_new_re: YUP_VALIDATION.re_password('password_new'),
  });

  const methods = useForm<PasswordChangeParam>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const { handleSubmit, reset } = methods;

  const onSubmit: SubmitHandler<PasswordChangeParam> = (data) => {
    (async () => {
      await postPasswordChange(data);
      push({
        event: 'pwSetting',
        actionType: 'pw_setting',
        actionName: 'pw_setting',
      });
      reset();
    })();
  };
  return (
    <section className="w-308 mx-auto pb-80">
      <header className="text-center mb-16">
        <h1 className="text-heading_4 font-bold mt-48 mb-24">パスワードの変更</h1>
        <p className="text-body_1 whitespace-pre">{MESSAGE.password_specifications}</p>
        <p className="text-11_20">
          {MESSAGE.password_specification_supplement}
        </p>
      </header>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mbx-utils-stack-v--8">
            <FormContainerTextfield
              height="large"
              type="password"
              name="password_current"
              placeholder="現在のパスワードを入力"
              className="w-full mkt_mask_items"
            />
            <FormContainerTextfield
              height="large"
              type="password"
              name="password_new"
              placeholder="新しいパスワードを入力"
              className="w-full mkt_mask_items"
            />
            <FormContainerTextfield
              height="large"
              type="password"
              name="password_new_re"
              placeholder="確認のため、もう1度入力してください"
              className="w-full mkt_mask_items"
            />
          </div>
          <BaseButton size="m" theme="primary" type="submit" className="w-full mt-24">
            パスワードを変更する
          </BaseButton>
        </form>
      </FormProvider>
    </section>
  );
};
