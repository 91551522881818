import { AxiosError } from 'axios';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { RootState } from '@/reduxAdmin';
import { setLogin } from '@/reduxAdmin/modules/adminAuth';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import { ADMINApi, ResponseError } from '@/utils/api-client';

type IFormInputs = {
  id: string;
  password: string;
};

export const AdminLogin = (): React.ReactElement => {
  const dispatch = useDispatch();
  const pathAfterLogin = useSelector((state: RootState) => state.utils.pathAfterLogin);
  const history = useHistory();
  const methods = useForm<IFormInputs>({
    defaultValues: {
      id: '',
      password: '',
    },
  });

  const onLogin: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    new ADMINApi()
      .postAdminLogin(methods.getValues().id, methods.getValues().password)
      .then(() => {
        dispatch(setLogin(true));
        if (pathAfterLogin) {
          history.push(pathAfterLogin);
        } else {
          history.push('/admin/search');
        }
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) {
          dispatch(notificationError(error.response.data.error_message));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  return (
    <AdminPage header={<HeaderTitle title="ログイン" />}>
      <FormProvider {...methods}>
        <form>
          <div className="login-form w-308 sm:w-full m-auto mt-48 sm:mt-24 sm:px-24">
            <FormLayoutFieldset>
              <FormLabel label="ログインID (メールアドレス)" type="small" className="pt-6" />
              <FormContainerTextfield name="id" height="large" />
            </FormLayoutFieldset>
            <FormLayoutFieldset className="mt-16">
              <FormLabel label="パスワード" type="small" className="pt-6" />
              <FormContainerTextfield name="password" type="password" height="large" />
            </FormLayoutFieldset>
            <BaseButton theme="primary" size="m" className="btn mt-48" onClick={onLogin}>
              ログイン
            </BaseButton>
          </div>
        </form>
      </FormProvider>
    </AdminPage>
  );
};
