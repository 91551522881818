import React from 'react';
import cn from 'classnames';
import '@/styles/common/Navigation/tab_navigation.scss';

type Menu = {
  title: string;
  index: number;
}

type Props = {
  menu: Menu[];
  align: 'fit' | 'left' | 'right',
  className?: string,
  itemClassName?: string,
  activeIndex: number;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>,
};

/**
 * Tab Navigation
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=354%3A933
 *
 * アイテム要素のサイズを指定したいなどの場合は itemClassName でクラス名を指定してそのクラスに対してサイズを設定してください
 *
 */
export function ModalTabNavigation(props: Props): React.ReactElement {
  return (
    <section className={cn('mbx-tab_navigation', props.className)}>
      <ul className={cn('mbx-tab_navigation__list', `mbx-tab_navigation__list--${props.align}`)}>
        {props.menu.map((item: Menu) => {
          return (
            <li className={cn(props.itemClassName, 'mbx-tab_navigation__item', {'is-active': item.index === props.activeIndex})} onClick={() => props.setActiveIndex(item.index)} key={item.index}>
              <p className='mbx-tab_navigation__link cursor-pointer'>{item.title}</p>
            </li>
          );
        })}
      </ul>
    </section>
  );
}
