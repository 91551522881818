import { AxiosError } from 'axios';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ImgIcon from '@/assets/images/profile/circle-placeholder.svg';
import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { UserItem } from '@/componentsDirect/Parts/Navigation/User/Item';
import { RootState } from '@/reduxAdmin';
import { isLoggedOut, setLogin } from '@/reduxAdmin/modules/directAuth';
import { notificationError, setPathAfterLogin, toggleLoading } from '@/reduxAdmin/modules/utils';
import { DirectApi } from '@/utils/api-client';
import { ResponseError } from '@/utils/api-client/';

// ====================================================================================================
//
// Types
//
// ====================================================================================================
type TProps = {
  nodeRef: React.MutableRefObject<null>;
};

/**
 * Direct メニュー要素
 * @author s2 masa
 */
export const AdminMenu = ({ nodeRef }: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // variables
  //
  // ====================================================================================================
  const API = new DirectApi();

  // ====================================================================================================
  //
  // hooks
  //
  // ====================================================================================================
  const dispatch = useDispatch();
  const isLogin = useSelector((state: RootState) => state.direct_auth.isLogin);
  const history = useHistory();
  const userInfo = useSelector((state: RootState) => state.direct_auth.userInfo);
  const { push } = React.useContext(DataLayerContext);

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const menu = useMemo(() => {
    if (!userInfo?.user_info.user_type) return [];

    // ダイレクトの管理者用メニュー
    if (/^direct_manager$/.test(userInfo?.user_info.user_type)) {
      return [
        { title: '企業情報設定', url: '/direct/company' },
        { title: 'グループ管理', url: '/direct/user_groups' },
        { title: '社内ユーザー管理', url: '/direct/users' },
        { title: 'ポジション管理', url: '/direct/offer_positions' },
        { title: '個人設定', url: '/direct/user/profile' },
        { title: 'メール配信設定', url: '/direct/conditions' },
      ];
    }

    // 管理者以外の社内ユーザー用メニュー
    if (/[direct_user|direct_hr]/.test(userInfo?.user_info.user_type)) {
      return [
        { title: '個人設定', url: '/direct/user/profile' },
        { title: 'グループ管理', url: '/direct/user_groups' },
        { title: '社内ユーザー管理', url: '/direct/users' },
        { title: 'メール配信設定', url: '/direct/conditions' },
      ];
    }

    return [];
  }, [userInfo]);

  // ====================================================================================================
  //
  // Events
  //
  // ====================================================================================================
  const onLogout: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    history.push(location.pathname);
    dispatch(toggleLoading(true));
    API.getDirectLogout()
      .then(() => {
        dispatch(isLoggedOut());
        dispatch(setLogin(false));
        dispatch(setPathAfterLogin(undefined));
        push({
          event: 'logout',
          actionType: 'logout',
          actionName: 'logout',
        });
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================

  // 未ログインでユーザー情報を取得してなければ何も表示しない
  if (!isLogin && !userInfo) {
    return <></>;
  }

  return (
    <div className="mbx-admin-menu" ref={nodeRef}>
      <section className="flex items-center h-112 pl-24 border-b border-gray-300">
        <div className="w-64 h-64 mkt_mask_items">
          <img
            className="rounded-full"
            src={userInfo?.user_info.photo ? userInfo?.user_info.photo : ImgIcon}
            alt={userInfo?.user_info.name ? userInfo?.user_info.name : ''}
          />
        </div>
        <div className="ml-12">
          <p className="mbx-typography--body_2 mkt_mask_items">
            {userInfo?.user_info.post ? userInfo?.user_info.post : ''}
          </p>
          <p className="mbx-typography--heading_4 mkt_mask_items">
            {userInfo?.user_info.name ? userInfo?.user_info.name : ''}
          </p>
        </div>
      </section>
      {menu?.map((m, index) => (
        <UserItem key={index} to={m.url}>
          {m.title}
        </UserItem>
      ))}
      <UserItem onClick={onLogout}>ログアウト</UserItem>
    </div>
  );
};
