import { TDictionary } from '@/types/Util';

const position_template: TDictionary<string> = {
  default: `●●●や、●●●をお任せします。
●●●としてのご活躍を期待しております。

【具体的な業務】
 ・●●●
 ・●●●
 ・●●● など

【ポジションの魅力】
 ●●●な環境です。
 ●●●なことも魅力の一つです。`,
};

const message_template: TDictionary<string> = {
  default: `当社は「●●」のビジョンのもと、●●●●を中心とした事業を展開しています。

◆当社の魅力◆
・●●●●
・●●●●
・●●●●

ぜひ、●●●●な環境で、●●●●な仲間と共に、ご自身のスキルや市場価値を高めていきませんか。`,
};

export const TEMPLATE = {
  position_template,
  message_template,
};
