import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { RootState } from '@/redux';

/**
 * redux の loadingの値検知でローダーを表示をする汎用ローダー
 */
export const GlobalLoading = (): React.ReactElement => {
  const loading = useSelector((state: RootState) => state.loading);
  const loadingRef = useRef(null);
  return (
    <CSSTransition
      in={loading}
      timeout={550}
      nodeRef={loadingRef}
      unmountOnExit
      classNames="mbx-anime-modal--overlay"
    >
      <div
        className="flex items-center justify-center z-400 w-full h-full bg-white bg-opacity-75 fixed left-0 top-0"
        ref={loadingRef}
      >
        <svg width="48" height="48" className="mbx-loader">
          <circle cx="24" cy="24" r="22" className="mbx-loader-base" />
          <circle cx="24" cy="24" r="22" className="mbx-loader-border" />
        </svg>
      </div>
    </CSSTransition>
  );
};
