import { Portfolio, Question } from './api-client';

export const extractAnswersFromPortfolioQuestions = (
  portfolio: Portfolio,
  qaId: 'b_v2_2' | 'b_v2_7' | 'b_v2_8'
): string[] => {
  if (!portfolio?.items) return [];
  const items = Array.from(portfolio?.items?.values());
  const pages = items?.map((i) => i.i_pages);
  const box: string[] = [];
  pages.forEach((page) => {
    if (Array.from(page).length) {
      const contents = Array.from(page).find((p) => p.p_page_layout === 'questions')
        ?.p_contents as unknown;
      const question = Array.from(contents as Question[]).find((c) => c.qa_id === qaId);
      question?.qa_answer.forEach((a) => {
        if (a) {
          box.push(a);
        }
      });
    }
  });
  const result = box.filter((ele, pos) => {
    return box.indexOf(ele) === pos;
  });

  return result;
};
