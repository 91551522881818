
export const IMAGE_SIZE  = {
  pf_main: {width: 680*2, height: 375*2},
  pf_profile: {width: 116*2, height: 116*2},
  style_2: {width: 330*2, height: 990*2},
  style_4: {width: 330*2, height: 462*2},
  style_8: {width: 330*2, height: 198*2},
  style_v2: {width: 680*2, height: 453*2},
  header_image: {width: 1280*2, height: 1280/5*2},
  mobile: {width: 132*2, height: 232*2},
  responsive_sp: {width: 144*2, height: 248*2},
  responsive_tablet: {width: 360*2, height: 473*2},
  responsive_pc: {width: 821*2, height: 506*2},
};