import '@/styles/common/Direct/StopDirectModal.scss';

import cn from 'classnames';
import React from 'react';

import BaseButton from '../Button/BaseButton';
import { DataLayerPushContainer } from '../DataLayerPushContainer';
import Modal from '../Modal';
import WarningList from './WarningList';

const warnings = [
  'オファー機能を停止すると、MATCHBOXスカウトサービス参画企業は、あなたの経歴やポートフォリオを直接閲覧できなくなります。',
  'オファー機能以外の機能は、引き続き利用可能です。（履歴書・職務経歴書・ポートフォリオの作成など）',
  'オファー機能停止後、再度企業からのオファーを受け取りたい場合は、改めてオファー機能に登録してください。',
  'これまでのオファー履歴は、オファー機能を停止後も保持されます。オファー機能を再度利用した場合、過去の履歴を閲覧できます。',
];

type Props = {
  isOpen: boolean;
  isClose: React.Dispatch<React.SetStateAction<boolean>>;
  onStop: () => Promise<void>;
};

function StopDirectModal(props: Props): React.ReactElement {
  const isSp = window.innerWidth < 768;
  return (
    <Modal isOpen={props.isOpen} isClose={() => props.isClose(false)}>
      <DataLayerPushContainer
        data={{
          event: 'pageView',
          actionType: 'page_view',
          actionName: 'modal_open',
          virtualPageName: 'stop-matchbox-direct'
        }}
      >
        <section className="stop-direct_modal">
          <div className="w-624 m-auto sm:w-auto sm:px-24">
            <h2 className={cn(isSp ? 'mbx-typography--heading_3' : 'mbx-typography--heading_2')}>
              オファー機能を停止
            </h2>
            <p className="mbx-typography--body_1 mt-24">
              オファー機能はいつでも停止できます。
              <br />
              転職活動が終了した際など、企業からのオファーが不要な場合は
              {!isSp && <br />}
              オファー機能を停止してください。
            </p>
            <div className="warning">
              <WarningList warnings={warnings} className="mt-48" />
            </div>
            <div className="flex justify-center mt-48">
              <BaseButton theme="primary" size="m" className="modal-btn" onClick={props.onStop}>
                オファー機能を停止する
              </BaseButton>
            </div>
          </div>
        </section>
      </DataLayerPushContainer>
    </Modal>
  );
}

export default StopDirectModal;
