import '@/styles/common/Mypage/uploadfile.scss';

import React from 'react';

import { ButtonTextLink } from '../../Button/TextLink';

type TButtonTextLinkProps = React.ComponentProps<typeof ButtonTextLink>;

type TProps = {
  className?: string;
  date: string;
  name: string;
  size: string;
  fileDelete: TButtonTextLinkProps['onClick'];
  pdfDl: string;
};

/**
 *
 * PC : https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=348%3A30301
 *
 * SP : https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=735%3A6941
 *
 */
export const MypageUploadfileList = ({
  className = '',
  date,
  name,
  size,
  fileDelete,
  pdfDl,
}: TProps): React.ReactElement => {
  return (
    <>
      <hr className="mbx-keyline-horizontal--thin"></hr>
      <div
        className={
          'mbx-uploadfile-list lg:flex md:flex pt-8 lg:pr-8 md:pr-8 lg:pb-8 md:pb-8 text-body_2 ' +
          className
        }
      >
        <div className="flex lg:mr-16 md:mr-16">
          <p className="mr-16">{date}</p>
          <p className="mkt_mask_items">
            {name} ({size})
          </p>
        </div>
        <div className={'mbx-uploadfile-list-button flex text-right sm:text-12_40'}>
          <ButtonTextLink className="mr-16" onClick={fileDelete}>
            削除
          </ButtonTextLink>
          <ButtonTextLink href={pdfDl}>ダウンロード</ButtonTextLink>
        </div>
      </div>
    </>
  );
};
