import React from 'react';

import { BasicInfoLeftUser } from '@/componentsAdmin/Company/Detail/BasicInfoLeftUser';
import { DescriptionWrapper } from '@/componentsAdmin/Company/Detail/DescriptionWrapper';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';
import { CompanyUser } from '@/utils/api-client';

type Props = {
  user: CompanyUser;
  setUserModal: (value: React.SetStateAction<boolean>) => void;
};

export const UserModalSection = (props: Props): React.ReactElement => {
  return (
    <section className="w-784 p-80">
      <BasicInfoLeftUser user={props.user} />
      <DescriptionWrapper
        title="ログインID"
        titleWidth={170}
        className="mt-24 border-t border-gray-300 text-left"
      >
        <p className="mbx-typography--body_2 text-left">{props.user.email}</p>
      </DescriptionWrapper>
      <DescriptionWrapper title="採用時に見るポイント" titleWidth={170} className="text-left">
        <p className="mbx-typography--body_2 text-left">
          {
            DIRECT_FORM_OPTIONS.point.find((p) => p.value === props.user.point?.toString())
              ?.children
          }
        </p>
      </DescriptionWrapper>
      <DescriptionWrapper title="所属グループ" titleWidth={170} className="text-left">
        <ul className="mbx-typography--body_2 text-left">
          {props.user.groups?.map((i, index) => {
            return <li key={index}>{i}</li>;
          })}
        </ul>
      </DescriptionWrapper>
    </section>
  );
};
