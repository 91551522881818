import { AxiosError, AxiosResponse } from 'axios';
import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { MESSAGE } from '@/definitionDirect/MESSAGE';
import { RootState } from '@/reduxAdmin';
import { setUserInfo } from '@/reduxAdmin/modules/directAuth';
import { notificationError, notificationSuccess, toggleLoading } from '@/reduxAdmin/modules/utils';
import {
    CompanyUser, DirectApi, DirectUserParam, PasswordChangeParam, ResponseError, UserEmailChange,
    UserEmailChangeToken
} from '@/utils/api-client';

type TReturn = {
  companyUser: CompanyUser | undefined;
  getCompanyUser: () => void;
  patchCompanyUser: (request: DirectUserParam) => void;
  postPasswordChange: (request: PasswordChangeParam) => Promise<void>;
  postEmailChange: (request: UserEmailChange) => Promise<void>;
  emailChangeResponse: AxiosResponse | undefined;
  postEmailChangeValidateToken: (request: UserEmailChangeToken) => Promise<void>;
};

/**
 * ユーザー情報設定関連
 */
export const useUser = (): TReturn => {
  const API = new DirectApi();
  const dispatch = useDispatch();

  const [emailChangeResponse, setemailChangeResponse] = useState<AxiosResponse>();

  const companyUser = useSelector((state: RootState) => state.direct_auth.userInfo?.user_info);
  const { push } = useContext(DataLayerContext);

  /**
   * ユーザー情報取得
   * ログアウト時などにエラーになってしまうのでエラー通知はせずに成功時のみ処理
   */
  const getCompanyUser = () => {
    API.getDirectUser().then((res) => {
      dispatch(setUserInfo(res.data));
    });
  };

  /**
   * ユーザー情報編集
   */
  const patchCompanyUser = (request: DirectUserParam) => {
    dispatch(toggleLoading(true));
    API.patchDirectUser(request)
      .then((res) => {
        push({
          event: 'profileUpdate',
          actionType: 'profile_update',
          actionName: 'profile_update',
        });
        dispatch(setUserInfo(res.data));
        dispatch(notificationSuccess(MESSAGE.saving_completed_for_user_profile));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * パスワード変更
   */
  const postPasswordChange = (request: PasswordChangeParam) => {
    dispatch(toggleLoading(true));
    return API.postDirectPasswordChange(request)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.saving_completed_for_password_change));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * メールアドレス変更
   */
  const postEmailChange = (request: UserEmailChange) => {
    dispatch(toggleLoading(true));
    return API.postDirectEmailChange(request)
      .then((res) => {
        setemailChangeResponse(res);
        dispatch(notificationSuccess(MESSAGE.saving_completed_for_email_change));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) {
          setemailChangeResponse(error.response);
          dispatch(notificationError(error.response.data.error_message));
        }
      })
      .finally(() => {
        dispatch(toggleLoading(false));
      });
  };

  /**
   * メールアドレス変更
   */
  const postEmailChangeValidateToken = (request: UserEmailChangeToken) => {
    return API.postDirectEmailChangeValidateToken(request)
      .then(() => {
        dispatch(notificationSuccess(MESSAGE.saving_completed_for_email_change_validate_token));
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      });
  };

  return {
    companyUser,
    getCompanyUser,
    patchCompanyUser,
    postPasswordChange,
    postEmailChange,
    emailChangeResponse,
    postEmailChangeValidateToken,
  };
};
