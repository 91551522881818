import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { DataLayerPushContainer } from '@/componentsDirect/common/DataLayerPushContainer';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { ProfileImageCircle } from '@/componentsDirect/Parts/OtherComponents/ProfileImageCircle';
import { DIRECT_FORM_OPTIONS } from '@/definitionDirect/FORM_OPTIONS';
import { useUser } from '@/hooksDirect/useUser';
import { CompanyUser, DirectUserParam } from '@/utils/api-client';

/**
 * Direct 個人設定 プロフィールページ
 */
export const DirectUserProfile = (): React.ReactElement => {
  const { companyUser, patchCompanyUser } = useUser();
  const methods = useForm<CompanyUser>({
    defaultValues: companyUser,
  });
  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<DirectUserParam> = (data) => {
    patchCompanyUser(data);
  };

  useEffect(() => {
    if (companyUser) setDefaultValues(companyUser);
  }, [companyUser]);

  // データを取得した後にRHFに値をsetValueする
  const setDefaultValues = (res: CompanyUser) => {
    (Object.keys(res) as (keyof CompanyUser)[]).forEach((v) => {
      if (res?.[v]) {
        methods.setValue(v, res?.[v]);
      }
    });
  };

  return (
    <DataLayerPushContainer
      data={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'page_view',
      }}
    >
      <section className="w-308 mx-auto pb-80">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-22">
              <ProfileImageCircle
                name="photo"
                iconName="Person"
                iconSize={70}
                animeClass="mbx-anime-img--none"
                className="mkt_mask_items"
              />
            </div>
            <div className="mbx-utils-stack-v--22">
              <FormLayoutFieldset>
                <FormLabel label="氏名" type="small"></FormLabel>
                <FormContainerTextfield name="name" className="w-full mkt_mask_items" />
              </FormLayoutFieldset>
              <FormLayoutFieldset>
                <FormLabel label="部署名" type="small"></FormLabel>
                <FormContainerTextfield name="section" className="w-full mkt_mask_items" />
              </FormLayoutFieldset>
              <FormLayoutFieldset>
                <FormLabel label="役職名" type="small"></FormLabel>
                <FormContainerTextfield name="post" className="w-full mkt_mask_items" />
              </FormLayoutFieldset>
              <FormLayoutFieldset>
                <div className="flex mbx-status_clients__title">
                  <FormLabel label="採用時に見るポイント" type="small"></FormLabel>
                  <i className="mbx-icon mbx-icon-Info -ml-16 pb-6" />
                  <div className="mbx-admin_tooltip" style={{ top: 40, left: 0 }}>
                    一番優先度の高い項目を選択してください。マイナビワークスが採用支援を行う際の参考にさせていただきます。
                  </div>
                </div>
                <FormContainerDropdown
                  name="point"
                  className="w-full mkt_mask_items"
                  placeholder="選択してください"
                  selectList={DIRECT_FORM_OPTIONS.point}
                ></FormContainerDropdown>
              </FormLayoutFieldset>
            </div>
            <BaseButton size="m" theme="primary" type="submit" className="w-full mt-48">
              変更を保存
            </BaseButton>
          </form>
        </FormProvider>
      </section>
    </DataLayerPushContainer>
  );
};
