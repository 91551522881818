import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ButtonIconButton } from '@/componentsDirect/Parts/Button/IconButton';
import { ButtonTextLink } from '@/componentsDirect/Parts/Button/TextLink';
import { useDownloadPDFForPortfolio } from '@/hooksAdmin/useDownloadPDFForPortfolio';

type TProps = {
  status: number | null; // 0:PDF生成リクエスト完了、1:PDF生成中、2:PDF生成完了、3:PDFダウンロード済
};

/**
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=5332%3A102628
 */
export const NotificationPDFDownloadSnackBar = ({ status = null }: TProps): React.ReactElement => {
  // !see https://github.com/reactjs/react-transition-group/issues/668
  const nodeRef = React.useRef(null);
  const [showState, setShow] = useState(false);
  const [isMini, setIsMini] = useState(false);
  const { downloadPDF } = useDownloadPDFForPortfolio();

  const callBacks = {
    onEnter: () => console.log('show success'),
    onExited: () => console.log('hide success'),
  };

  const checkStatus = () => {
    if (status === 0 || status === 1) {
      return 'progressive';
    }
    if (status === 2 || status === 3) {
      return 'done';
    }
  };

  /**
   * 生成中のSnackBarを縮小化
   */
  const onClickMini = (val: boolean) => {
    setIsMini(val);
  };

  const onClickClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (status === null) {
      setShow(false);
    }
    if (status === 0 || status === 1 || status === 2) {
      // 生成中は準備中表示
      setShow(true);
    }
  }, [status]);
  return (
    <CSSTransition
      in={showState}
      timeout={550}
      nodeRef={nodeRef}
      {...callBacks}
      unmountOnExit
      classNames="mbx-anime-notification"
    >
      <div
        ref={nodeRef}
        className={`mbx-notification mbx-notification--pdf-download ${
          isMini && 'mbx-notification--mini'
        }`}
      >
        <div className="mbx-notification-inner">
          {checkStatus() === 'progressive' && (
            <div className="flex items-center">
              <div className="mbx-notification-pre-icon">
                <div className="mbx-loader_sm">
                  <div className="mbx-loader_sm_core"></div>
                </div>
              </div>
              <div className={'mbx-notification-message'}>
                <h1>ポートフォリオPDFのダウンロードを準備しています</h1>
              </div>
            </div>
          )}
          {checkStatus() === 'done' && (
            <div className="flex items-center">
              <div className="mbx-notification-pre-icon">
                <div className="mbx-icon mbx-icon-Done text-green-600"></div>
              </div>
              <div className={'mbx-notification-message'}>
                <h1>
                  ポートフォリオPDFダウンロードの準備が完了しました！{' '}
                  <ButtonTextLink bold={true} onClick={downloadPDF} noneUnderline={true}>
                    PDFをダウンロード
                  </ButtonTextLink>
                </h1>
              </div>
            </div>
          )}
          <div className={'mbx-notification-icon'}>
            {checkStatus() === 'progressive' && !isMini && (
              <ButtonIconButton
                type="white"
                iconName="Arrow_Down"
                hitArea="medium"
                onClick={() => onClickMini(true)}
              ></ButtonIconButton>
            )}
            {checkStatus() === 'progressive' && isMini && (
              <ButtonIconButton
                type="white"
                iconName="Arrow_Up"
                hitArea="medium"
                onClick={() => onClickMini(false)}
              ></ButtonIconButton>
            )}

            {checkStatus() === 'done' && (
              <ButtonIconButton
                type="white"
                iconName="Menu-close"
                hitArea="medium"
                onClick={() => onClickClose()}
              ></ButtonIconButton>
            )}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
