/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';

import { setPathAfterLogin } from '@/redux';
import { State } from '@/redux/state';

type TProps = {
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> | undefined;
  path?: string | undefined;
  exact?: boolean | undefined;
};

export function RetentionRoute({ exact, path, component }: TProps): React.ReactElement {
  const isAuth = useSelector((state: State) => state.isAuth);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  // 未ログインの場合は遷移元のパスを保持
  // ログイン成功後に保持した遷移先に移動
  if (isAuth) {
    dispatch(setPathAfterLogin(undefined));
  } else {
    dispatch(setPathAfterLogin(location.pathname));
  }

  return <Route exact={exact} path={path} component={component} />;
}
