import { AxiosError } from 'axios';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { notificationError, notificationSuccess, toggleLoading } from '@/reduxAdmin/modules/utils';
import { ADMINApi, AdminMatchboxUserRequest, ResponseError } from '@/utils/api-client';

export const Account = (props: {
  matchboxId?: string;
  authorId?: string;
  companyId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user?: any;
  asyncCallback?: () => Promise<void>;
}): React.ReactElement => {
  const dispatch = useDispatch();
  const statusMethods = useForm({ defaultValues: { status: '', pf_password: '' } });

  const setStatusValue = () => {
    if (props.user?.status === '非公開') {
      statusMethods.setValue('status', 'closed');
    }
    if (props.user?.status === 'パスワード付き公開') {
      statusMethods.setValue('status', 'password');
    }
    if (props.user?.status === '公開') {
      statusMethods.setValue('status', 'public');
    }
    statusMethods.setValue('pf_password', props.user?.portfolio_password);
  };

  const userStatus = useMemo(() => {
    console.log(props.user?.user_status);
    return props.user?.user_status;
  }, [props.user?.user_status]);

  useEffect(() => {
    setStatusValue();
  }, [props.user]);

  const onUserStatus = async (key: 'active' | 'inactive' | 'before_delete') => {
    let message = '';
    if (key === 'active') message = '復活';
    if (key === 'inactive') message = '停止';
    if (key === 'before_delete') message = '退会';
    if (!props.matchboxId) return;
    dispatch(toggleLoading(true));
    try {
      await new ADMINApi().patchAdminUserId(props.matchboxId, {
        user_status: key,
      });
      dispatch(notificationSuccess(`このアカウントを${message}しました。`));
      if (props.asyncCallback) {
        await props.asyncCallback();
      }
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const updateStatus = async (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();
    if (!props.matchboxId) return;
    const values = statusMethods.getValues();
    const param: AdminMatchboxUserRequest = {
      portfolio_status: values.status,
      portfolio_password: values.status === 'password' ? values.pf_password : '',
    };
    await new ADMINApi().patchAdminUserId(props.matchboxId, param);
    dispatch(notificationSuccess('公開ステータスを更新しました。'));
  };

  return (
    <section className="my-24 w-624 mx-auto space-y-72">
      {userStatus === 'before_delete' || userStatus === 'deleted' ? (
        <p className="text-center mbx-typography--body_1 mt-48">アカウント削除が完了しました。</p>
      ) : (
        <div>
          <label htmlFor="" className="text-12_18 font-bold">
            停止・退会
          </label>

          <div className="flex space-x-16">
            {userStatus === 'inactive' && (
              <BaseButton
                className="flex-1"
                theme="secondary"
                size="s"
                onClick={() => onUserStatus('active')}
              >
                このアカウントを復活
              </BaseButton>
            )}
            {userStatus === 'active' && (
              <BaseButton
                className="flex-1"
                theme="secondary"
                size="s"
                onClick={() => onUserStatus('inactive')}
              >
                このアカウントを停止
              </BaseButton>
            )}
            <BaseButton
              className="flex-1"
              theme="secondary"
              size="s"
              onClick={() => onUserStatus('before_delete')}
            >
              このアカウントを退会
            </BaseButton>
          </div>
        </div>
      )}
      <div>
        <label htmlFor="" className="text-12_18 font-bold">
          ポートフォリオの公開ステータスの更新
        </label>
        <div className="flex space-x-16">
          <FormProvider {...statusMethods}>
            <form className="flex w-full space-x-8 items-center" onSubmit={updateStatus}>
              <FormContainerDropdown
                className="flex-1"
                name="status"
                selectList={FORM_OPTIONS.portfolio_status}
              />
              <FormContainerTextfield className="flex-1" name="pf_password" type="password" />
              <BaseButton theme="primary" size="s">
                更新
              </BaseButton>
            </form>
          </FormProvider>
        </div>
      </div>
    </section>
  );
};
