import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { BlockList } from '@/componentsAdmin/Company/DataBase/BlockList';
import { CompanyCheckForm } from '@/componentsAdmin/Company/DataBase/CompanyCheckForm';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import {
    ADMINApi, BlockCompaniesPatchParam, BlockCompany, BlockCompanyPostParam, ResponseError,
    ResponseSuccess
} from '@/utils/api-client';

type TInput = {
  name: string;
};

type Props = {
  isBlockList?: boolean;
  blockCompanies?: BlockCompany[];
  companies?: BlockCompany[];
  onSubmit: (payload: BlockCompany[]) => Promise<void>;
};

export const BlockModalContainer = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const [isCheckList, setIsCheckList] = useState<boolean>(false);
  const [allCompanies, setAllCompanies] = useState<BlockCompany[]>([]);
  const [blockCompanies, setBlockCompanies] = useState<BlockCompany[]>([]);
  const [companies, setCompanies] = useState<BlockCompany[]>([]);
  const [companiesArray, setCompaniesArray] = useState<BlockCompany[][]>();
  const [page, setPage] = useState<number>(0);
  const [pageList, setPageList] = useState<number[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [pageLength, setPageLength] = useState<number>(0);
  const methods = useForm<TInput>({
    defaultValues: {
      name: '',
    },
  });
  const { handleSubmit } = methods;
  const arrayChunk = ([...array], size = 1) => {
    return array.reduce(
      (acc, value, index) => (index % size ? acc : [...acc, array.slice(index, index + size)]),
      []
    );
  };

  const exclusionCompanies = (companies: BlockCompany[], blockCompanies: BlockCompany[]) => {
    const ids = blockCompanies.map((b) => b.id);
    return companies.filter((c) => !ids.includes(c.id));
  };

  useEffect(() => {
    if (!props.companies || !props.blockCompanies) return;
    setAllCompanies(exclusionCompanies(props.companies, props.blockCompanies));
    setCompaniesArray(arrayChunk(exclusionCompanies(props.companies, props.blockCompanies), 10));
    setCompanies(arrayChunk(exclusionCompanies(props.companies, props.blockCompanies), 10)[0]);
    setTotal(exclusionCompanies(props.companies, props.blockCompanies).length);
  }, [props.companies, props.blockCompanies]);

  useEffect(() => {
    if (props.blockCompanies) {
      setBlockCompanies(props.blockCompanies);
    }
  }, [props.blockCompanies]);

  useEffect(() => {
    const arr = Array(total)
      .fill(0)
      .map((v, i) => {
        return i;
      });
    setPageLength(arrayChunk(arr, 10).length);
  }, [total]);

  useEffect(() => {
    if (companiesArray) {
      setCompanies(companiesArray[page]);
    }
  }, [page]);

  const onSearch = async (data: TInput) => {
    if (data.name === '') return;
    type Response = {
      companies: BlockCompany[];
      total: number;
    };
    const param: BlockCompanyPostParam = {
      limit: 9999,
      offset: 0,
      name: data.name,
    };
    dispatch(toggleLoading(true));
    try {
      const res = await new ADMINApi().postAdminBlockCompanies(param);
      const data = res.data as Response & ResponseSuccess;
      setAllCompanies(exclusionCompanies(data.companies, blockCompanies));
      setCompaniesArray(arrayChunk(exclusionCompanies(data.companies, blockCompanies), 10));
      setCompanies(arrayChunk(exclusionCompanies(data.companies, blockCompanies), 10)[0]);
      setTotal(exclusionCompanies(data.companies, blockCompanies).length);
      setIsCheckList(true);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
    }
  };

  const onSubmit = (payload: BlockCompaniesPatchParam) => {
    props.onSubmit(
      allCompanies.filter((c) => {
        return payload.ids?.includes(c.id);
      })
    );
  };

  return (
    <section className="w-784 min-h-768 py-80 px-78">
      <FormProvider {...methods}>
        <form className="flex items-end" onSubmit={handleSubmit((data) => onSearch(data))}>
          <FormLayoutFieldset className="w-full">
            <FormLabel label="紐付ける企業名" type="small" />
            <FormContainerTextfield name="name" height="large" />
          </FormLayoutFieldset>
          <div className="w-88 ml-8 flex-shrink-0">
            <BaseButton theme="primary" size="m" className="w-full">
              検索
            </BaseButton>
          </div>
        </form>
      </FormProvider>
      {props.isBlockList ? (
        <section>
          {isCheckList ? (
            <CompanyCheckForm
              total={total}
              companies={companies}
              blockCompanies={blockCompanies}
              page={page}
              pageLength={pageLength}
              setPage={setPage}
              onSubmit={onSubmit}
              setPageList={setPageList}
              pageList={pageList}
            />
          ) : (
            <div className="mt-48">
              <BlockList blockCompanies={blockCompanies} isDelete={false} />
            </div>
          )}
        </section>
      ) : (
        <section>
          <CompanyCheckForm
            total={total}
            companies={companies}
            blockCompanies={blockCompanies}
            page={page}
            pageLength={pageLength}
            setPage={setPage}
            onSubmit={onSubmit}
            setPageList={setPageList}
            pageList={pageList}
          />
        </section>
      )}
    </section>
  );
};
