import cn from 'classnames';
import _ from 'lodash';
import React, { ChangeEventHandler, useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { CSSTransition } from 'react-transition-group';

import { FormCounter } from '@/componentsDirect/Parts/Form/Counter';
import { FormFootnote } from '@/componentsDirect/Parts/Form/Footnote';

type TProps = {
  name: string;
  rows?: number;
  id?: string;
  placeholder?: string;
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
};
/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=12%3A1522
 *
 * default の値はhook-formのuseFormで指定
 * https://react-hook-form.com/api/useform
 *
 * maxLength : 値があればカウンターが表示
 *
 * validation周りの設定方法についてはもう少しフォームコンポーネント作ってから改めてドキュメント書きます。
 */
export const FormContainerTextArea = ({
  rows = 2,
  id,
  placeholder,
  name,
  className = '',
  readOnly = false,
  disabled = false,
  maxLength = 0,
  onChange,
}: TProps): React.ReactElement => {
  const textareaRef = useRef<HTMLTextAreaElement>();
  const errorRef = useRef(null);
  const methods = useFormContext();
  const watchValue = methods.watch(name);
  const defaultClass = 'mbx-formContainer mbx-formContainer--textarea overflow-y-hidden';
  const error = methods.formState.errors[name];
  const { ref, ...rest } = methods.register(name);

  const rfhOnChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    rest.onChange(e);
  };
  const debouncedChangeHandler = useCallback(_.debounce(rfhOnChange, 100), []);

  useEffect(() => {
    if (!textareaRef.current) return;
    const textarea = textareaRef.current;
    if (textarea.offsetHeight > textarea.scrollHeight) {
      textarea.setAttribute('rows', rows.toString());
    }
    while (textarea.offsetHeight < textarea.scrollHeight) {
      const currentRows = textarea.getAttribute('rows');
      if (currentRows) textarea.setAttribute('rows', Number(currentRows) + 1 + '');
    }
  }, [watchValue]);
  return (
    <div className={className}>
      {readOnly ? (
        <div className="mbx-formContainer mbx-formContainer--textarea--readonly">{watchValue}</div>
      ) : (
        <div
          className={cn({
            'mbx-formContainer--error': error,
          })}
        >
          <textarea
            className={`${defaultClass}`}
            id={id}
            {...rest}
            ref={(e: HTMLTextAreaElement) => {
              ref(e);
              textareaRef.current = e; // you can still assign to ref
            }}
            maxLength={maxLength}
            placeholder={placeholder}
            rows={rows}
            disabled={disabled}
            readOnly={readOnly}
            onChange={onChange ? onChange : debouncedChangeHandler}
          />

          <div className="flex flex-row">
            <CSSTransition
              in={!!error}
              timeout={550}
              nodeRef={errorRef}
              unmountOnExit
              classNames="mbx-anime-error"
            >
              <div ref={errorRef}>
                <FormFootnote className="text-error-700">
                  {error ? error.message : null}
                </FormFootnote>
              </div>
            </CSSTransition>

            {maxLength > 0 ? (
              <FormCounter
                current={watchValue ? watchValue.length : 0}
                total={maxLength}
                className="flex-shrink ml-auto"
              ></FormCounter>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};
