import React from 'react';

export const Ed2 = (): React.ReactElement => {
  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16"> <img className={style.img}
          src="../images/Top/event/event_0002_mv.jpg" width="978" height="504" alt="" /> </div>
        <h1 className="mt-56 sm:mt-30 text-29_46 sm:text-18_24 font-bold text-center">10/20（木）20:00 -<br />
          UIUXデザイナーの<br className="md:hidden lg:hidden" />キャリアの出発点</h1>
        <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
          <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
            <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
            <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">以下に該当する方はオススメです。</h2>
          <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4"><span><i
                className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>
                  UI・UXデザイナーへの転職を成功させたい方</span></li>
              <li className="flex mb-4"><span><i
                className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>転職活動の準備を始めたデザイナー</span>
              </li>
              <li className="flex mb-4"><span><i
                className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>転職活動準備のヒントが欲しいデザイナー</span>
              </li>
              <li className="flex mb-4"><span><i
                className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>自身の今後のキャリアの方向性を定めたい方</span>
              </li>
              <li className="flex"><span><i
                className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span><span>セミナーを踏まえて転職の相談をしたい方</span>
              </li>
            </ul>
          </div>
        </section>
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">イベント内容</h3>
          <div className="mt-30 sm:mt-16 mb-30"> <img src="../images/Top/event/event_seminar_image.jpg" width="980" height="374"
            alt="" /> </div>
          <p className="text-16_28 sm:text-14_21 mt-30">
            昨今キャリアアップのための手段として、”転職”はポピュラーになっています。ただ、日頃業務をこなす中での転職活動準備を並行して行うことはなかなか容易ではなく、「時間がかかる」、「何をすればよいかわからない」「そもそもキャリアアップできるのか」などのお声をたくさん伺ってきました。<br />
            <br />
            そこで今回転職活動準備の支援のため、セミナーを10/20（木）20:00
            ～、オンライン開催いたします。今回は転職を考えているデザイナーの皆さま向けに、同じくデザイナーへの転職を成功された現役UI/UXデザイナーの大倉
            慶子氏からキャリアについてのお話をお届けします。転職活動をどのように考え、そのためにどんな転職書類を作り、どのような行動をしたのか。実際に内定を獲るための戦略をお話いただきます。<br />
            <br />
            興味がある方はお早めにご応募ください。
          </p>
        </section>
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">参加者の声</h3>
          <div className="mt-30 mb-15 sm:mt-16 sm:mb-8 p-40 sm:p-24 box-line text-left">
            <ul className="text-16_28 sm:text-14_21">
              <li className="flex mb-4"><span>・</span><span>自分以外の人のキャリアパスを聞くことによって、今後の自分のキャリアを考えるきっかけになった。<br />
                （28歳 制作会社Webデザイナー5年目）</span></li>
              <li className="flex mb-4"><span>・</span><span>漠然と転職を考えていたが、今後目指すキャリアをイメージすることができた。<br />
                （25歳 制作会社 グラフィックデザイナー 3年目）</span></li>
              <li className="flex mb-4"><span>・</span><span>実業務にもつながるWeb・IT業界で働いている人からの話が聞けてとても参考になった<br />
                （27歳 広告代理店Webデザイナー 4年目）</span></li>
              <li className="flex"><span>・</span><span>質疑応答で聞きたいことが聞けて、思考がクリアになった。<br />
                （30歳 事業会社 UIデザイナー）</span></li>
            </ul>
          </div>
          <p className="text-right text-16_28 sm:text-14_21">※参加者の声の一部を掲載しています</p>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">講師プロフィール</h2>
          <div className="event-instructor box-line sm:p-16">
            <div className="instructor-photo"><img src="../images/Top/event/event_0002_prof.jpg" alt="" /></div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">大倉 慶子<span className="inline-block">（KEIKO OKURA）</span>
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-40 sm:mb-18">アンカーデザイン株式会社 <br
                className="md:hidden lg:hidden" />サービスデザイナー</dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">東京都出身イギリス在住。明治大学理工学部応用化学科卒業。<br />
                The Glasgow School of ArtにてDesign Innovation and Service
                Design修了。学生時代にアメリカへの交換留学を経験後に、新卒で専門商社に入社。その後、株式会社マイナビにて新卒媒体営業、事業推進を経験。正社員として働く傍ら独学でデザインを勉強し、2020年にUIUXデザイナーに転身。2021年に留学のためにイギリスに移住。
              </dd>
            </dl>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap"><span
                  className="sm:block">20：00</span><span className="sm:block">～</span><span className="sm:block">21：20</span>
                </th>
                <td>
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>1. </span><span>自己紹介（キャリア遍歴）</span></li>
                    <li className="flex"><span>2. </span><span>キャリアチェンジを目指したきっかけ</span></li>
                    <li className="flex"><span>3. </span><span>UI/UXデザイン、サービスデザインの定義</span></li>
                    <li className="flex"><span>4. </span><span>苦労したこと</span></li>
                    <li className="flex"><span>5. </span><span>私が内定を3つもらえたポイント</span></li>
                    <li className="flex"><span>6. </span><span>実際に行った準備</span></li>
                    <li className="flex"><span>7. </span><span>キャリアチェンジをしてみてどうだったか</span></li>
                    <li className="flex"><span>8. </span><span>まとめ</span></li>
                    <li className="flex"><span>9. </span><span>質疑応答</span></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2022/10/20（木）20:00～<br />
                  [入室開始] 19:45～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />
                  参加者の皆さまには別途URLをお送りします。
                </td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">参加費</th>
                <td>無料</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">対象者</th>
                <td>転職を実際に考えている、もしくは、今後のキャリア形成について考えているWebデザイナー</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.参加申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink"
                      href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.開催当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">開催について</h3>
          <p className="text-16_28 sm:text-14_21 mt-30 sm:mt-16">
            Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2,000名を超える「0からのWebディレクション講座」を生み出したメンバーでもあるSPEC.代表の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。
          </p>
        </section>
        <div className='back_event-list flex flex-col items-center justify-center mt-120 sm:mt-55'>
          <div className="base-button isTextLink">
            <a className="button size-s theme-link flex items-center" href="./"><i className="mbx-icon mbx-icon-Arrow_Left mr-2"></i><span className="font-bold label">イベント一覧へ</span></a>
          </div>
        </div>
      </div>
    </div>
  );
};
