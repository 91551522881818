import '@/styles/common/Direct/MyNaviSelectForm.scss';

import React, { useEffect } from 'react';
import { OnboardingPageWrapper } from './PageWrapper';
import { FormProvider, useForm, UseFormProps } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';
import { PageDots } from './PageDots';
import {
    notificationError, toggleLoading
} from '@/redux/index';
import { State } from '@/redux/state';
import { MyNaviApi, MyNavi } from '@/utils/api-client';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormContainerDropdown } from '@/components/common/Form/Container/Dropdown';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
import BaseButton from '@/components/common/Button/BaseButton';
import { ButtonIconButton } from '@/components/common/Button/IconButton';

type MyNaviItem = {
  mc_experienced_job: number,
  mc_experienced_year: number
};

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  myNavi?: MyNavi;
};

export const OnboardingPage_1 = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();

  /**
   * validation 設定
   */
  const schema = yup.object().shape({
    mc_experienced_job: YUP_VALIDATION.required,
    mc_experienced_year: YUP_VALIDATION.required,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const useFormProps: UseFormProps<any> = {
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      mc_experienced_job: props.myNavi?.mc_experienced_job || undefined,
      mc_experienced_year: props.myNavi?.mc_experienced_year || undefined,
    },
  };

  const methods = useForm(useFormProps);

  const { handleSubmit } = methods;

  // 送信処理
  const onSubmit = async (data: MyNaviItem) => {
    if (!data) return;

    dispatch(toggleLoading(true));
    const convertedData: any = {};
    (Object.keys(data) as (keyof MyNaviItem)[]).forEach((key) => {
      convertedData[key] = Number(data[key]);
    });

    try {
      await new MyNaviApi().patchMynaviCreator(convertedData);
    } catch (e) {
      dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
      props.setPage(2);
    }
  };

  return (
    <DataLayerPushContainer
      data={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'modal_open',
        virtualPageName: 'on-boarding_step_1'
      }}
    >
    <div>
      <OnboardingPageWrapper
        description={
          <span>
            ポートフォリオサービス
            <br />
            MATCHBOXへようこそ
          </span>
        }
      >
        <div>
          <p className="mt-14 text-15_32">
            はじめに、あなたについてお聞きします。
            <br />
            これまでの経験に近しい職種を1つ選択してください。
            <p className="mt-7 text-12_20">※この項⽬はあとから変更できます。</p>
          </p>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
              <div className="mt-26 flex justify-center">
                <FormLayoutFieldset>
                  <FormLabel label="経験職種" type="small">
                    <TooltipMessageMatchbou
                      className="-ml-12"
                      message="キャリアの中で一番経験の長い職種を選択してください。"
                      position={['0', '-94px']}
                    >
                      <ButtonIconButton hitArea="mini" iconName="Info" type="gray" focus={false} />
                    </TooltipMessageMatchbou>
                  </FormLabel>
                  <FormContainerDropdown
                    className="select mkt_mask_items w-308"
                    name="mc_experienced_job"
                    placeholder="選択してください"
                    selectList={FORM_OPTIONS.preferred_job_type}
                  ></FormContainerDropdown>
                </FormLayoutFieldset>
              </div>
              <div className="mt-26 flex justify-center">
                <FormLayoutFieldset>
                  <FormLabel label="上記で選択した職種の経験年数" type="small"></FormLabel>
                  <FormContainerDropdown
                    className="select mkt_mask_items w-308"
                    name="mc_experienced_year"
                    placeholder="選択してください"
                    selectList={FORM_OPTIONS.experienced_year}
                  ></FormContainerDropdown>
                </FormLayoutFieldset>
              </div>
              <div className="pb-20 mb-48 sm:mb-72">
                <PageDots page={props.page} setPage={props.setPage} unClick className="mt-48" />
                <div className="w-188 m-auto mt-48">
                  <BaseButton
                    theme="primary"
                    size="m"
                    className="w-full"
                  >
                    次へ
                  </BaseButton>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </OnboardingPageWrapper>
    </div>
    </DataLayerPushContainer>
  );
};
