import React from 'react';

import BaseButton from '@/components/common/Button/BaseButton';

type Props = {
  onClick: React.MouseEventHandler<HTMLDivElement>;
  children: string;
};

function PageBack(props: Props): React.ReactElement {
  return (
    <section className="page_back text-center h-64">
      <div className="page_back_inner lg:w-960 text-left m-auto flex items-center h-64">
        <BaseButton theme="link" size="s" iconName="Arrow_Left" onClick={props.onClick}>
          {props.children}
        </BaseButton>
      </div>
    </section>
  );
}

export default PageBack;
