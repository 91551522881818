import React, { useEffect } from 'react';
import { FieldArrayWithId, useFieldArray, useFormContext } from 'react-hook-form';

import * as CH from '@/components/CareerHistory/';
import { useMBXMediaQuery } from '@/hooks/useMBXMediaQuery';
import { TProjectFormValues } from '@/types/Pages/CareerHistory';
import { moveArray } from '@/utils/utils';
import {
    closestCenter, DndContext, KeyboardSensor, PointerSensor, TouchSensor, useSensor, useSensors
} from '@dnd-kit/core';
import {
    SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy
} from '@dnd-kit/sortable';

export const Projects: React.VFC<{
  index: number;
  onSort(
    result: FieldArrayWithId<TProjectFormValues, `careers.${number}.c_projects`, 'uid'>[]
  ): void;
  onAppend(): void;
  onRemove(id: number): void;
  onBlur?(
    name: string,
    e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
  ): void;
  onFocus?(e: React.FormEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>): void;
}> = ({ index, onSort, onAppend, onRemove, onBlur, onFocus }) => {
  const mq = useMBXMediaQuery();
  const { control, watch } = useFormContext<TProjectFormValues>();

  const { fields, move, remove } = useFieldArray<
    TProjectFormValues,
    `careers.${number}.c_projects`,
    'uid'
  >({
    control,
    name: `careers.${index}.c_projects` as `careers.${number}.c_projects`,
    keyName: 'uid',
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const touchSensors = useSensors(
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement
      activationConstraint: {
        delay: 150,
        tolerance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    const subscription = watch(() => {
      // console.log(value)
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragEnd = ({ active, over }: any) => {
    if (active.id !== over.id) {
      const oldIndex = fields.map((field) => field.uid).indexOf(active.id);
      const newIndex = fields.map((field) => field.uid).indexOf(over.id);
      move(oldIndex, newIndex);
      onSort(moveArray(fields, oldIndex, newIndex));
    }
  };

  return (
    <DndContext
      sensors={mq.sm ? touchSensors : sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      autoScroll={false}
    >
      <SortableContext
        items={fields.map((field) => ({ id: field.uid }))}
        strategy={verticalListSortingStrategy}
      >
        <div className="mb-8 mbx-utils-stack-v--16">
          <div>
            {fields.map((item: any, ind) => (
              <div key={item.uid} className="mbx-formUnit-education-box">
                <CH.DraggableProject
                  key={item.uid}
                  item={item}
                  parentIndex={index}
                  index={ind}
                  onRemove={() => {
                    remove(ind);
                    if (item.id) onRemove(item.id);
                  }}
                  onBlur={onBlur}
                  onFocus={onFocus}
                />
              </div>
            ))}
          </div>

          <CH.DraggableSection nested={true} className="" title="業務内容" sortable={false}>
            <CH.AddButton onClick={() => onAppend()} />
          </CH.DraggableSection>
        </div>
      </SortableContext>
    </DndContext>
  );
};
