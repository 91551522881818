import React from 'react';

type Props = {
  title: string;
  children: React.ReactElement;
};

/**
 * 検索条件表示用のカラム
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65909
 */
export const UserCondition = ({ title, children }: Props): React.ReactElement => {
  return (
    <section className="flex border-b border-gray-300">
      <header className="w-144 bg-gray-100 flex-shrink-0">
        <h1 className="p-24 text-subhead_2 text-blueGray-700 font-bold">{title}</h1>
      </header>
      <div className="pr-24 pt-22 pb-24 flex-grow">{children}</div>
    </section>
  );
};
