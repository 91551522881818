import React, { useMemo } from 'react';
import cn from 'classnames';

type Props = {
  className?: string;
  total: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const PagerNoNumber = (props: Props): React.ReactElement => {
  const pageLength = useMemo(() => {
    if (props.total === 0) return 0;
    return Math.ceil(props.total / 10);
  }, [props.total]);

  const onPrevClick = () => {
    if (props.page === 0) return;
    props.setPage(props.page - 1);
    window.scrollTo(0, 0);
  };

  const onNextClick = () => {
    if (props.page === pageLength - 1) return;
    props.setPage(props.page + 1);
    window.scrollTo(0, 0);
  };

  return (
    <section className={props.className}>
      <ul className="flex items-center px-18 text-blue-700">
        <li className={cn('mr-24', { 'opacity-20': props.page === 0 })}>
          <p className="flex items-center cursor-pointer" onClick={() => {
            props.setPage(0);
            window.scrollTo(0, 0);
          }}>
            <i className="mbx-icon mbx-icon-First_Page text-12_48"></i>
          </p>
        </li>
        <li className={cn('mr-48', { 'opacity-20': props.page === 0 })}>
          <p className="flex items-center cursor-pointer" onClick={onPrevClick}>
            <i className="mbx-icon mbx-icon-Arrow_Left text-24_48"></i>
          </p>
        </li>
        <li className={cn('mr-24', { 'opacity-20': props.page === pageLength - 1 })}>
          <p className="flex items-center cursor-pointer" onClick={onNextClick}>
            <i className="mbx-icon mbx-icon-Arrow_Right text-24_48"></i>
          </p>
        </li>
        <li className={cn({ 'opacity-20': props.page === pageLength - 1 })}>
          <p
            className="flex items-center cursor-pointer"
            onClick={() => {
              props.setPage(pageLength - 1);
              window.scrollTo(0, 0);
            }}
          >
            <i className="mbx-icon mbx-icon-Last_Page text-12_48"></i>
          </p>
        </li>
      </ul>
    </section>
  );
};
