import '../styles/App.scss';

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { State } from '@/redux/state';
import { UserInfo } from '@/utils/api-client/';

import * as Page from '../components/common/Page';

function App(): React.ReactElement {
  const user: UserInfo | null = useSelector((state: State) => state.user);
  const WORK_ID = 1;

  useEffect(() => {
    //
  }, []);

  return (
    <Page.Wrapper header={<></>} footer={<></>}>
      <Page.Column
        main={
          <>
            <h1 className="mbx-typography--heading_1 my-24">ページリスト</h1>

            <h2 className="mbx-typography--heading_2 my-24">トップ</h2>

            <ul className="mb-24">
              <li className="">
                <Link to="/">ログイン前トップ</Link>
              </li>
            </ul>

            <h2 className="mbx-typography--heading_2 my-24">アカウント登録〜ログイン</h2>

            <ul className="mb-24">
              <li>
                <Link to="/entry">アカウント登録</Link>
              </li>
              <li>
                <Link to="/login">ログインページ</Link>
              </li>
              <li>
                <Link to="/login/reminder">パスワードを忘れた方</Link>
              </li>
              <li className="not-available">
                <Link to="/login/reminder">
                  パスワード再設定（メールからトークン付きURLで遷移する必要あり）
                </Link>
              </li>
            </ul>

            <h2 className="mbx-typography--heading_2 my-24">マイページ</h2>

            <ul className="mb-24">
              <li>
                <Link to="/mypage">TOP</Link>
              </li>
              <li>
                <Link to="/mypage/account">アカウント編集</Link>
              </li>
              <li>
                <Link to="/mypage/profile">プロフィール編集</Link>
              </li>
              <li>
                <Link to="/mypage/condition">希望条件編集</Link>
              </li>
            </ul>

            <h2 className="mbx-typography--heading_2 my-24">MATCH BOX DIRECT</h2>

            <ul className="mb-24">
              <li>
                <Link to="/mypage/direct">MATCH BOX DIRECT（申請ページ1,2,登録完了）</Link>
              </li>
              <li className="not-available">
                <Link to="/mypage/direct_status">オファー状況確認</Link>
              </li>
              <li className="not-available">
                <Link to="/mypage/block_list">ブロックする企業設定（ブロックした企業一覧）</Link>
              </li>
              <li className="not-available">
                <Link to="/mypage/block_list">ブロックする企業検索</Link>
              </li>
            </ul>

            <h2 className="mbx-typography--heading_2 my-24">ポートフォリオ</h2>

            <h3 className="mbx-typography--heading_3 my-24">公開</h3>

            <ul className="mb-24">
              <li className="">
                <Link to={`/test`}>TOPページ</Link>
              </li>
              <li className="not-available">
                <Link to={`/test/${WORK_ID}`}>作品詳細ページ</Link>
              </li>
              <li className="">
                <Link to={`/test/profile`}>プロフィール</Link>
              </li>
            </ul>

            <h3 className="mbx-typography--heading_3 my-24">プレビュー</h3>

            <ul className="mb-24">
              <li className="">
                <Link to={`/mypage/portfolio/${user?.user_id}`}>TOPページ</Link>
              </li>
              <li className="not-available">
                <Link to={`/mypage/portfolio/${user?.user_id}/${WORK_ID}`}>作品詳細ページ</Link>
              </li>
              <li className="">
                <Link to={`/mypage/portfolio/${user?.user_id}/profile`}>プロフィール</Link>
              </li>
              <li className="not-available">公開完了（公開設定完了後にモーダル表示される）</li>
            </ul>

            <h3 className="mbx-typography--heading_3 my-24">編集</h3>

            <ul className="mb-24">
              <li className="">
                <Link to="/mypage/portfolio/edit">TOPページ</Link>
              </li>
              <li className="not-available">
                <Link to={`/mypage/portfolio/${user?.user_id}/${WORK_ID}/edit`}>
                  作品詳細ページ
                </Link>
              </li>
              <li className="">
                <Link to={`/mypage/portfolio/${user?.user_id}/profile/edit`}>プロフィール</Link>
              </li>
              <li className="">
                <Link to="/mypage/portfolio/setting">設定</Link>
              </li>
            </ul>

            <h2 className="mbx-typography--heading_2 my-24">履歴書</h2>

            <ul className="mb-24">
              <li>
                <Link to="/mypage/resume/edit">履歴書編集</Link>
              </li>
              <li>
                <Link to="/mypage/resume">履歴書確認</Link>
              </li>
            </ul>
            <h2 className="mbx-typography--heading_2 my-24">職務経歴書</h2>
            <ul className="mb-24">
              <li className="">
                <Link to="/mypage/cv">職務経歴書確認</Link>
              </li>
              <li className="sm:mb-212">
                <Link to="/mypage/cv/edit">職務経歴書編集</Link>
              </li>
            </ul>
          </>
        }
        menu={<></>}
      />
    </Page.Wrapper>
  );
}

export default App;
