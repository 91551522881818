import React, { useContext, useEffect } from 'react';

import { DataLayerContext } from '@/components/common/DataLayerProviderContainer';
import { DataLayerType } from '@/types/DataLayerType';

export function DataLayerPushContainer({
  children,
  data,
}: {
  children: React.ReactNode;
  data: DataLayerType;
}): React.ReactElement {
  const { push } = useContext(DataLayerContext);

  useEffect(() => {
    push(data);
  }, []);

  return <>{children}</>;
}
