import React, { useState, useEffect } from 'react';
import cn from 'classnames';

type TStep = {
  step1: boolean,
  step2: boolean,
  step3: boolean,
  step4: boolean,
  step5: boolean,
  step6: boolean,
};

type Props = {
  className: string;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  step: TStep;
  totalDots: number;
  unClick?: boolean;
};

export const PageDots = (props: Props): React.ReactElement => {
  const dots = [...Array(props.totalDots)].map((_, i) => i + 1);
  const [currentDot, setCurrentDot] = useState(0);

  useEffect(() => {
    conversionPageForDot(props.page);
  }, [props.page]);

  const conversionPageForDot = (page: number) => {
    const visiblePages = Object.keys(props.step).filter((step) => props.step[step as keyof TStep]);
    const dotIndex = visiblePages.indexOf(`step${page}`) + 1;
    setCurrentDot(dotIndex);
  }

  const conversionDotForPage = (dot: number) => {
    const visiblePages = Object.keys(props.step).filter((step) => props.step[step as keyof TStep]);
    const pageIndex = Number(visiblePages[dot - 1].split('step')[1]);
    props.setPage(pageIndex);
    setCurrentDot(dot);
  }

  return (
    <div className={cn('flex', 'justify-center', props.className)}>
      {dots.map((item, n) => {
        return (
          <p
            key={n}
            className={cn(
              'w-8',
              'h-8',
              'rounded-full',
              {'cursor-pointer': !props.unClick},
              item === currentDot ? 'bg-red-700' : 'bg-gray-200',
              { 'ml-14': n !== 0 }
            )}
            onClick={() => {
              if (props.unClick) return;
              conversionDotForPage(item);
            }}
          ></p>
        );
      })}
    </div>
  );
};
