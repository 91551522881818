import '@/styles/pages/Direct/Users/Detail/index.scss';

import { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { BasicInfoComponent } from '@/components/common/Admin/UserDetail/BasicInfo';
import { StatusClients } from '@/components/common/Admin/UserDetail/StatusClients';
import BackButton from '@/components/common/Button/BackButton';
import { TabNavigation } from '@/components/common/Navigation/TabNavigation';
import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { AdminPage } from '@/componentsDirect/Layout/Page';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import { PickUpModal } from '@/componentsDirect/Users/Detail/PickUpModal';
import { PickUpPatchModal } from '@/componentsDirect/Users/Detail/PickUpPatchModal';
import { DataLayerType } from '@/hooksDirect/common/useDataLayer';
import { RootState } from '@/reduxAdmin';
import { notificationError, toggleLoading } from '@/reduxAdmin/modules/utils';
import { CandidateData } from '@/types/DataLayerType';
import {
  Career,
  DirectApi,
  DirectUserResponse,
  LastLogin,
  MyNavi,
  Offer,
  Portfolio,
  ResponseError,
  UserResumeInfo,
} from '@/utils/api-client';
import { getDirectOptionChildrenFromValue } from '@/utils/optionValueToChildren';
import { genCandidateData } from '@/utils/utils';

import { Detail } from './detail';
import { Notes } from './notes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const genUserData = (data: any): CandidateData | undefined => {
  if (!data) return undefined;
  const user: UserResumeInfo = data.user_info;
  const lastLogin: LastLogin[] = data.last_login;
  const registration: string = data.registration_date;
  const mc: MyNavi = data.mc;
  return genCandidateData(
    user.matchbox_id,
    user,
    lastLogin[0],
    registration,
    mc,
    data.user_admin_offers,
    data.user_admin_reactions
  );
};

/**
 * 社内ユーザー管理 /direct/users
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A66093
 *
 * 利用責任者は1人以上必要
 */
export const DirectUser = (): React.ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const user: DirectUserResponse | null = useSelector(
    (state: RootState) => state.direct_auth.userInfo
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [userInfo, setUserInfo] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [portfolio, setPortfolio] = useState<any>();
  const [searchPortfolio, setSearchPortfolio] = useState<Portfolio>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [careerHistory, setCareerHistory] = useState<any>();
  const [ownOffer, setOwnOffer] = useState<Offer>();
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [patchModal, setPatchModal] = useState<boolean>(false);
  const [offerId, setOfferId] = useState<number | undefined>(undefined);
  const [doneIsOpen, setDoneIsOpen] = useState<boolean>(false);
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const matchboxId = useMemo(() => location.pathname.split('/')[3], [location]);

  const { push } = React.useContext(DataLayerContext);

  const getUser = async () => {
    let param: DataLayerType = {
      event: 'pageView',
      actionType: 'page_view',
      actionName: 'page_view',
    };
    try {
      // const res: AxiosResponse = await new DirectApi().postDirectSearchUsers();
      // if (!res.data) return;
      const userRes: AxiosResponse = await new DirectApi().getDirectUserMatchboxId(matchboxId);
      setUserInfo(userRes.data);
      const candidate = genUserData(userRes.data);
      param = {
        ...param,
        candidates: candidate ? [candidate] : [],
      };
      const pfRes: AxiosResponse = await new DirectApi().getDirectPortfolio(matchboxId);
      setPortfolio(pfRes.data);
      const careerHistoryRes: AxiosResponse = await new DirectApi().getDirectCareerHistory(
        matchboxId
      );
      const p = await new DirectApi().postDirectSearchUsers({
        conditions: { matchbox_id: matchboxId },
      });
      setSearchPortfolio(p.data.users[0].portfolio);
      setCareerHistory(careerHistoryRes.data);
      const offer: Offer = userRes.data.user_admin_offers[0];
      if (offer) {
        setOwnOffer(offer);
        param.matchingDegree = getDirectOptionChildrenFromValue('offer_mw_status', offer.rate);
      } else {
        setOwnOffer(undefined);
        param.matchingDegree = 'N/A';
      }

      push(param);
    } catch (error) {
      const e = error as AxiosError<ResponseError>;
      if (e.response) dispatch(notificationError(e.response.data.error_message));
    }
  };

  const userData = useMemo<CandidateData | undefined>(() => genUserData(userInfo), [userInfo]);

  useEffect(() => {
    const lastPath = location.pathname.split('/').reverse()[0];
    setActiveIndex(lastPath === 'notes' ? 1 : 0);
  }, [location]);

  const doneOffer = (offerId: number) => {
    setOfferId(offerId);
    setDoneIsOpen(true);
  }

  const removeOffer = async () => {
    dispatch(toggleLoading(true));
    // Offerの削除
    if (!ownOffer?.id) return;
    await new DirectApi().deleteDirectOffer(`${ownOffer?.id}`);
    await getUser();
    setAlertIsOpen(false);
    dispatch(toggleLoading(false));
  };

  const rate = useMemo<string>(() => {
    return getDirectOptionChildrenFromValue('offer_mw_status', ownOffer?.rate);
  }, [ownOffer]);

  const backButton = useMemo(() => {
    const normal = {
      href: '/direct/search/',
      text: '一覧へ戻る',
    };
    if (!history.location.state) return normal;
    type TReferrer = {
      referrer: string;
      offer_id?: string;
    };
    const state = history.location.state as TReferrer;
    if (state.referrer === 'candidates') {
      if (state.offer_id) {
        if (ownOffer) {
          return {
            href: `/direct/candidates/${state.offer_id}`,
            text: '戻る',
          };
        } else {
          return {
            href: '/direct/candidates',
            text: '一覧へ戻る',
          };
        }
      }
      return {
        href: '/direct/candidates',
        text: '一覧へ戻る',
      };
    }
    return normal;
  }, [history.location.state, ownOffer]);

  const onClickViewDetail = () => {
    setPatchModal(true);
    push({
      event: 'pageView',
      actionType: 'page_view',
      actionName: 'modal_open',
      virtualPageName: 'pickup',
      matchingDegree: rate,
    });
  };

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <AdminPage header={<HeaderTitle title="求職者情報" />} disableDataLayerPush>
      <>
        <div className="mbx-direct-users-detail">
          <section className="h-48 flex items-center">
            {backButton.href !== '/direct/search/' && (
              <BackButton href={backButton.href}>{backButton.text}</BackButton>
            )}
          </section>
          <div className="mbx-direct-users-detail-user">
            {userInfo && careerHistory && (
              <BasicInfoComponent
                matchboxId={userInfo.matchbox_id}
                lastLogin={userInfo.last_login[0]}
                registrationDate={userInfo.registration_date}
                mcInfo={userInfo.mc}
                userInfo={userInfo.user_info}
                career={careerHistory.careers?.find((career: Career) => !career.c_leave_flag)}
                isDirect
              />
            )}
            <StatusClients
              offer={ownOffer}
              authorEmail={user?.user_info.email}
              onClickViewDetail={onClickViewDetail}
              onClick={() => {
                if (ownOffer) {
                  setAlertIsOpen(true);
                } else {
                  setModalIsOpen(true);
                }
              }}
            />
          </div>

          <section className="mt-12">
            <TabNavigation
              align="fit"
              menu={[
                { title: '求職者詳細', url: `/direct/users/${matchboxId}` },
                { title: '社内用メモ', url: `/direct/users/${matchboxId}/notes` },
              ]}
              activeIndex={activeIndex}
            />
          </section>
          {activeIndex === 0 && (
            <Detail {...{ userInfo, careerHistory, portfolio, searchPortfolio, getUser }} />
          )}
          {activeIndex === 1 && (
            <Notes
              matchboxId={matchboxId}
              authorId={user?.user_info.matchbox_id}
              candidate={userData}
            />
          )}
        </div>
        {ownOffer ? (
          <PickUpPatchModal
            isOpen={patchModal}
            onClose={setPatchModal}
            offer={ownOffer}
            asyncCallback={getUser}
            doneOffer={doneOffer}
          />
        ) : (
          <PickUpModal
            {...{
              modalIsOpen,
              setModalIsOpen,
              matchboxId,
              asyncCallback: getUser,
              offer: ownOffer,
              additionalData: userData,
              doneOffer,
            }}
          />
        )}
        <ModalDefault
          isOpen={doneIsOpen}
          onClose={() => setDoneIsOpen(false)}
          className="mbx-direct-users-detail-modal"
        >
          <div className="w-624 py-80">
            <p className="text-16_28 font-bold">ピックアップが完了しました。</p>
            <p className="mt-8 text-12_20">続けてアクションする場合は、このまま候補者管理ページへお進みください。</p>
            <p className="mt-2 text-12_20">※アクションとは「求職者へのオファー」や「マイナビワークスへの相談」などができる機能です。</p>

            <div className="flex justify-between space-x-16 mt-24 px-140">
              <BaseButton
                className="flex-1"
                size="m"
                theme="secondary"
                type="button"
                onClick={() => setDoneIsOpen(false)}
              >
                キャンセル
              </BaseButton>
              <BaseButton
                className="flex-1"
                size="m"
                theme="primary"
                type="button"
                onClick={() => history.push(`/direct/candidates/${offerId}`)}
              >
                候補者管理ページへ
              </BaseButton>
            </div>
          </div>
        </ModalDefault>
        <ModalDefault
          isOpen={alertIsOpen}
          onClose={() => setAlertIsOpen(false)}
          className="mbx-direct-users-detail-modal"
          dataLayer={{
            event: 'pageView',
            actionType: 'page_view',
            actionName: 'modal_open',
            virtualPageName: 'release',
            matchingDegree: rate,
          }}
        >
          <div className="w-624 py-80">
            {ownOffer?.status === 1 ? (
              <>
                <p className="text-14_20 px-150">この候補者のピックアップを解除しますか？</p>
                <p className="mt-8 text-12_20">※解除するとピックアップ後に追加した情報はすべてリセットされます。</p>
              </>
            ) : (
              <>
                <p className="text-14_20 px-150">この候補者へのアクションをリセットしますか？</p>
                <p className="mt-8 text-12_20">※ピックアップ後に追加した情報はすべてリセットされピックアップする前の状態に戻ります。</p>
              </>
            )}
            <div className="flex justify-between space-x-16 mt-24 px-150">
              <BaseButton
                className="flex-1"
                size="m"
                theme="secondary"
                type="button"
                onClick={() => setAlertIsOpen(false)}
              >
                キャンセル
              </BaseButton>
              <BaseButton
                className="flex-1"
                size="m"
                theme="primary"
                type="button"
                onClick={removeOffer}
              >
                解除する
              </BaseButton>
            </div>
          </div>
        </ModalDefault>
      </>
    </AdminPage>
  );
};
