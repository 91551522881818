import React from 'react';

type TColumn = {
  title: string;
  className?: string;
};

type TProps = {
  columns: Array<TColumn>;
};
export const OffersListTitle = ({ columns }: TProps): React.ReactElement => {
  return (
    <section>
      <div className="flex text-10_24 text-blueGray-700 bg-gray-200 border-b border-gray-300 text-left font-bold">
        {columns.map((column, index) => (
          <div key={index} className={`box-content pl-16 pt-8 pb-9 ${column.className}`}>
            {column.title}
          </div>
        ))}
      </div>
    </section>
  );
};
