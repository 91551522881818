import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';

import { MessageForm } from '@/components/common/Admin/UserDetail/MessageForm';
import { MessageFormSent } from '@/components/common/Admin/UserDetail/MessageFormSent';
import Modal from '@/components/common/Modal';
import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { useWindowSize } from '@/hooks/common/useWindowSize';
import { CandidateData } from '@/types/DataLayerType';
import { DirectApi, Note, NotesResponse } from '@/utils/api-client';

export const Notes = (props: {
  matchboxId?: string;
  authorId?: string;
  companyId?: string;
  candidate: CandidateData | undefined;
}): React.ReactElement => {
  const windowSize = useWindowSize();
  const [height, setHeight] = useState<number>(0);
  const [noteId, setNoteId] = useState<number>();
  const [notes, setNotes] = useState<Note[]>([]);
  const [comment, setComment] = useState<string>('');
  const [alertIsOpen, setAlertIsOpen] = useState<boolean>(false);
  const { push } = React.useContext(DataLayerContext);

  useEffect(() => {
    getNotes();
  }, []);

  useEffect(() => {
    setHeight(windowSize.height - 550);
  }, [windowSize]);

  const getNotes = async () => {
    const res: AxiosResponse<NotesResponse> = await new DirectApi().getDirectNotes(
      props.matchboxId
    );
    if (!res.data.notes) return;
    setNotes(res.data.notes);
  };

  const onChange = (comment: string) => {
    setComment(comment);
  };

  const onRemove = (id: number) => {
    setNoteId(id);
    setAlertIsOpen(true);
  };

  const removeNote = () => {
    (async () => {
      if (!noteId) return;
      await new DirectApi().deleteDirectNote(noteId);
      await getNotes();
      setAlertIsOpen(false);
      setNoteId(undefined);
    })();
  };

  const send = async () => {
    const param = {
      content: comment,
      matchbox_id: props.matchboxId,
    };
    await new DirectApi().postDirectNote(param);
    await getNotes();
    setComment('');
  };

  useEffect(() => {
    if (!props.candidate) return;
    push({
      event: 'pageView',
      actionType: 'page_view',
      actionName: 'page_view',
      candidates: [props.candidate],
    });
  }, [props.candidate]);

  return (
    <section className="mbx-direct-users-detail-notes mb-24 w-624 mx-auto flex-1">
      <div className="outer" style={{ height }}>
        <div className="inner">
          {notes.map((note: Note, index: number) => (
            <MessageFormSent
              note={note}
              key={index}
              onRemove={onRemove}
              authorId={props.authorId}
            />
          ))}
        </div>
      </div>
      <MessageForm onChange={onChange} onSend={send} comment={comment} />
      <Modal
        isOpen={alertIsOpen}
        isClose={() => setAlertIsOpen(false)}
        className="mbx-direct-users-detail-modal"
      >
        <div className="w-624 py-80 px-150">
          <p className="text-14_20">メモを削除しますか？</p>

          <div className="flex justify-between space-x-16 mt-24">
            <BaseButton
              className="flex-1"
              size="m"
              theme="secondary"
              type="button"
              onClick={() => setAlertIsOpen(false)}
            >
              キャンセル
            </BaseButton>
            <BaseButton
              className="flex-1"
              size="m"
              theme="primary"
              type="button"
              onClick={removeNote}
            >
              削除する
            </BaseButton>
          </div>
        </div>
      </Modal>
    </section>
  );
};
