import '@/styles/common/CareerHistory/add-button.scss';

import React from 'react';

export const AddButton: React.VFC<{ onClick?: React.MouseEventHandler<HTMLDivElement> }> = ({
  onClick,
}) => {
  return (
    <div
      className="mbx-add_button flex justify-center items-center p-16 bg-white"
      onClick={onClick}
    >
      <div className="mbx-icon mbx-icon-Large32_Plus" />
    </div>
  );
};
