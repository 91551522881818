import { createSlice } from '@reduxjs/toolkit';

import { reducers } from './reducer';
import { initialState } from './state';

/**
 * Admin の企業向け検索条件
 */
export const adminDirectSearchConditionModule = createSlice({
  name: 'admin_direct_search_condition',
  initialState,
  reducers,
});

export const { setSearchCondition, setSearchConditionSavedId } =
  adminDirectSearchConditionModule.actions;
