import '@/styles/common/Preview/preview.scss';

import React from 'react';

import { ResumePreview } from '@/components/common/Preview/ResumePreview';
import { PreviewResumeText } from '@/components/common/Preview/ResumeText';
import { PreviewResumeTitle } from '@/components/common/Preview/ResumeTitle';
import { ResumePreviewSectionCareers } from '@/components/Resume/Preview/SectionCareers';
import { ResumePreviewSectionEducations } from '@/components/Resume/Preview/SectionEducations';
import {
    ResumePreviewSectionQualifications
} from '@/components/Resume/Preview/SectionQualifications';
import {
    CareerSummaryModel, EducationData, MBResumeResponse, Qualification
} from '@/utils/api-client/api';

type TProps = {
  date: string;
  fetchData?: MBResumeResponse;
  educations?: EducationData[];
  careers?: CareerSummaryModel[];
  qualifications?: Qualification[];
  firstPageEducationsItemLength?: number;
};

export const ResumePreviewPage2 = ({
  date,
  fetchData,
  educations,
  careers,
  qualifications,
  firstPageEducationsItemLength,
}: TProps): React.ReactElement => {
  return (
    <ResumePreview date={date} page={2}>
      <>
        <ResumePreviewSectionEducations
          educations={educations}
          careers={careers}
        ></ResumePreviewSectionEducations>
        <ResumePreviewSectionCareers
          educations={educations}
          careers={careers}
          firstPageEducationsItemLength={firstPageEducationsItemLength}
        ></ResumePreviewSectionCareers>
        <ResumePreviewSectionQualifications
          educations={educations}
          careers={careers}
          qualifications={qualifications}
        ></ResumePreviewSectionQualifications>
        {fetchData?.resume?.statement ? (
          <div className="flex">
            <PreviewResumeTitle>志望動機</PreviewResumeTitle>
            <div className="w-full h-180">
              <PreviewResumeText>{fetchData?.resume?.statement}</PreviewResumeText>
            </div>
          </div>
        ) : null}
        {fetchData?.resume?.interest_things ? (
          <div className="flex">
            <PreviewResumeTitle>趣味・特技</PreviewResumeTitle>
            <div className="w-full h-120">
              <PreviewResumeText>{fetchData?.resume?.interest_things}</PreviewResumeText>
            </div>
          </div>
        ) : null}
        {fetchData?.resume?.hope_job ? (
          <div className="flex">
            <PreviewResumeTitle>本人希望</PreviewResumeTitle>
            <div className="w-full h-120">
              <PreviewResumeText>{fetchData?.resume?.hope_job}</PreviewResumeText>
            </div>
          </div>
        ) : null}
        <div className="flex">
          <PreviewResumeTitle>家族について</PreviewResumeTitle>
          <div className="w-full">
            <PreviewResumeText>
              {fetchData?.resume?.dependent_family != null
                ? `扶養家族数（配偶者を除く）：${fetchData?.resume?.dependent_family}人`
                : '扶養家族数（配偶者を除く）：'}
            </PreviewResumeText>
            <div className="flex">
              <PreviewResumeText className="w-full">
                {`配偶者：${fetchData?.resume?.partner ? '有' : '無'}`}
              </PreviewResumeText>
              <PreviewResumeText className="w-full">
                {`配偶者の扶養義務：${fetchData?.resume?.duty_of_support ? '有' : '無'}`}
              </PreviewResumeText>
            </div>
          </div>
        </div>
      </>
    </ResumePreview>
  );
};
