import { AxiosError } from 'axios';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  forceLoadingOff,
  forceLoadingOn,
  notificationError,
  toggleLoading,
} from '@/reduxAdmin/modules/utils';
import {
  DirectApi,
  DirectSearchUsersResponse,
  ResponseError,
  UserTagDirect,
} from '@/utils/api-client';

type TReturn = {
  searchUsersResult: DirectSearchUsersResponse | undefined;
  userTag: UserTagDirect | undefined;
  getUserTagPath: (path: string) => Promise<void>;
  getUserTagSearchUsers: (path: string, query: SearchQuery) => Promise<void>;
};

type SearchQuery = {
  limit: number;
  offset: number;
  sort_by: string;
  sort_order?: string;
};

/**
 * タグ関連
 * @author s2 masa
 */
export const useUserTag = (): TReturn => {
  const API = new DirectApi();
  const dispatch = useDispatch();

  const [searchUsersResult, setSearchUsersResult] = useState<DirectSearchUsersResponse>();
  const [userTag, setUserTag] = useState<UserTagDirect>();

  /**
   * タグに紐づく求職者一覧情報取得
   */
  const getUserTagSearchUsers = (path: string, query: SearchQuery) => {
    dispatch(forceLoadingOn());
    dispatch(toggleLoading(true));
    return API.getDirectTagSearchUsersId(
      path,
      query.limit,
      query.offset,
      query.sort_by,
      query.sort_order
    )
      .then((res) => {
        setSearchUsersResult(res.data);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      })
      .finally(() => {
        dispatch(forceLoadingOff());
        dispatch(toggleLoading(false));
      });
  };

  /**
   * 詳細情報取得
   */
  const getUserTagPath = (path: string) => {
    return API.getDirectUserTagPath(path)
      .then((res) => {
        setUserTag(res.data.data);
      })
      .catch((error: AxiosError<ResponseError>) => {
        if (error.response) dispatch(notificationError(error.response.data.error_message));
      });
  };

  return {
    searchUsersResult,
    userTag,
    getUserTagSearchUsers,
    getUserTagPath,
  };
};
