import cn from 'classnames';

import React, { MouseEventHandler, useState, useRef, forwardRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { CSSTransition } from 'react-transition-group';
import DatePicker, { registerLocale } from 'react-datepicker'
import ja from 'date-fns/locale/ja';
import 'react-datepicker/dist/react-datepicker.css'

import { ButtonIconButton } from '@/componentsDirect/Parts/Button/IconButton';
import { FormFootnote } from '@/componentsDirect/Parts/Form/Footnote';
import { FormLayoutUnitDivider } from '@/componentsDirect/Parts/Form/Layout/UnitDivider';
import { delay } from '@/utils/delay';
import { addClass, removeClass } from '@/utils/dom';
import { formatDate } from '@/utils/utils';

type TProps = {
  name: string;
  id?: string;
  autocapitalize?: 'off' | 'none' | 'on' | 'sentences' | 'words' | 'characters';
  autoComplete?: 'off'
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  debugError?: boolean;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  height?: 'large' | 'normal';
  unit?: string;
  fieldWidthClass?: string; // tailwindの w-{number} を指定
  disabledError?: boolean; // コンポーネントないでエラー表示をさせたくない場合に指定
  minYesr?: number // 選択できる年の最小
};

type IProps = {
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  name?: string;
  placeholder?: string;
  onClick?: MouseEventHandler<HTMLInputElement>;
  autoComplete?: string;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
};

//react-datepickerの日本語設定
registerLocale('ja', ja);

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=325%3A32193
 *
 * default の値はhook-formのuseFormで指定
 * https://react-hook-form.com/api/useform
 *
 */
export const FormContainerDatepicker = ({
  id,
  autocapitalize = 'off',
  autoComplete = "off",
  placeholder,
  name,
  className = '',
  disabled = false,
  debugError = false,
  onBlur,
  height = 'normal',
  unit,
  fieldWidthClass = 'w-full',
  disabledError = false,
  minYesr,
}: TProps): React.ReactElement => {
  //選択最小日時
  const [minDate, setMinDate] = useState<Date | undefined>(minYesr ? new Date(minYesr, 0, 1) : undefined);
  const inputRef = useRef<HTMLInputElement>(null);
  const hiddenRef = useRef<HTMLInputElement>(null);
  const errorRef = useRef(null);
  const methods = useFormContext();
  const watchValue = methods.watch(name);
  const defaultClass = cn('mbx-formContainer mbx-formContainer--textfield', {
    'mbx-formContainer--textfield--low': height === 'normal',
  });
  const error = methods.formState.errors[name];
  const register = methods.register(name);
  const years:number[] = [];
  if(minYesr){
    const now = Number(formatDate(new Date(), "YYYY"));
    for (let i = minYesr; i < now; i++) {
      years.push(i);
    }
    years.push(now);
  }

  /**
   * 値をクリア
   */
  const onClear: MouseEventHandler<HTMLButtonElement> = () => {
    methods.setValue(register.name, '', {
      shouldValidate: true,
      shouldDirty: true,
    });
  };
  // cssのfocusだとclearボタンをクリックしようとする直前にfocusが外れてボタンがクリックできないので
  // onFocusとonBlurでclassを付けて対応
  const onFocus: React.FocusEventHandler<HTMLInputElement> = () => {
    if(hiddenRef.current) addClass(hiddenRef.current, 'focus');
  };
  const onFocusOut: React.FocusEventHandler<HTMLInputElement> = () => {
    const target = hiddenRef.current;
    delay(500).then(() => {
      if(target) removeClass(target, 'focus');
    });
  };

  const minCheck = (date: Date): boolean => {
    if(!minDate) return false;
    return formatDate(date, "YYYY/MM") === formatDate(minDate, "YYYY/MM") ? true : false;
  }

  const CustomInput = forwardRef(function Base(props: IProps, ref){
    return (
      <input
        className={cn(defaultClass)}
        id={id}
        onBlur={(e) => {
          register.onBlur(e);
          onFocusOut(e);
          if (onBlur) {
            onBlur(e);
          }
        }}
        onFocus={onFocus}
        value={props.value}
        autoCapitalize={autocapitalize}
        onChange={props.onChange}
        name={props.name}
        placeholder={props.placeholder}
        disabled={disabled}
        readOnly={true}
        onClick={props.onClick}
        autoComplete={props.autoComplete}
      />
    )
  });

  return (
    <div className={className}>
      <div
        className={cn('flex mbx-formContainer--textfield-wrapper mbx-formContainer--datepicker-wrapper', {
          'mbx-formContainer--error': error || debugError,
        })}
      >
        <div className={cn('relative', fieldWidthClass)}>
          <Controller
            control={methods.control}
            name={register.name}
            render={({ field }) => (
              <DatePicker
                {...field}
                dateFormat="yyyy/MM/dd"
                placeholderText={placeholder}
                onChange={(date: Date) => field.onChange(formatDate(date, "YYYY/MM/DD"))}
                selected={field.value? new Date(field.value): undefined}
                autoComplete={autoComplete}
                customInput={<CustomInput inputRef={inputRef} />}
                locale='ja'
                minDate={minDate}
                renderCustomHeader={({date, decreaseMonth, increaseMonth, changeYear}) => (
                  <div className="react-datepicker__header ">
                    {!minCheck(date) &&
                    <button type="button"
                      className="react-datepicker__navigation react-datepicker__navigation--previous"
                      aria-label="Previous Month"
                      onClick={decreaseMonth}
                    >
                      <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">Previous</span>
                    </button>
                    }
                    <button type="button"
                      className="react-datepicker__navigation react-datepicker__navigation--next"
                      aria-label="Next Month"
                      onClick={increaseMonth}
                    >
                      <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">Next</span>
                    </button>
                    <div className="react-datepicker__current-month flex justify-center">
                      {minDate ? (
                        <>
                          <select
                            value={Number(formatDate(date, "YYYY"))}
                            onChange={({ target: { value } }) => changeYear(Number(value))}
                          >
                            {years.map((year, index) => (
                              <option key={index} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>年
                        </>
                      ) : (
                        <p>{formatDate(date, "YYYY")}年</p>
                      )}
                      <p className="pl-8">{Number(formatDate(date, "MM"))}月</p>
                    </div>
                    <div className="react-datepicker__header__dropdown react-datepicker__header__dropdown--scroll"></div>
                  </div>
                )}
              />
            )}
          />
          <input
            className={cn(defaultClass)}
            type="hidden"
            ref={hiddenRef}
          />
          {watchValue && !disabled && (
            <div className="mbx-formContainer--textfield-clear">
              <ButtonIconButton
                type="delete"
                iconName="Clear"
                onClick={onClear}
                hitArea="mini"
                focus={false}
              ></ButtonIconButton>
            </div>
          )}
        </div>
        {unit && (
          <FormLayoutUnitDivider className="flex-shrink-0 ml-8">{unit}</FormLayoutUnitDivider>
        )}
      </div>

      {!disabledError && (
        <CSSTransition
          in={!!error || debugError}
          timeout={100}
          nodeRef={errorRef}
          unmountOnExit
          classNames="mbx-anime-error"
        >
          <div className="flex flex-row whitespace-nowrap" ref={errorRef}>
            <FormFootnote className="text-error-700">
              {error ? error.message : debugError ? debugError : ''}
            </FormFootnote>
          </div>
        </CSSTransition>
      )}
    </div>
  );
};
