import React, { useContext, useEffect } from 'react';

import { DataLayerContext } from '@/componentsDirect/common/DataLayerProviderContainer';
import { DataLayerType } from '@/hooksDirect/common/useDataLayer';

export function DataLayerPushContainer({
  children,
  data,
}: {
  children: React.ReactNode;
  data?: DataLayerType;
}): React.ReactElement {
  const { push } = useContext(DataLayerContext);

  useEffect(() => {
    if (data) push(data);
  }, []);

  return <>{children}</>;
}
