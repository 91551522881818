import '@/styles/pages/Portfolio/TopPreview.scss';

import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { BaseButton } from '@/components/common/Button/';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
import { FormContainerTextfield, FormLabel } from '@/components/common/Form/';
import * as Page from '@/components/common/Page';
import { PreviewHeader } from '@/components/common/Portfolio/';

export function Password(props: { getPortfolio(password: string): void }): React.ReactElement {
  const [password, setPassword] = useState<string>();
  const methods = useForm({});

  useEffect(() => {
    const subscription = methods.watch((value) => {
      setPassword(value.password);
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  const submit: React.MouseEventHandler<HTMLDivElement> = () => {
    if (!password) return;
    props.getPortfolio(password);
  };

  return (
    <Page.Wrapper
      presentation={true}
      disableDataLayerPush
      header={
        <PreviewHeader>
          <></>
        </PreviewHeader>
      }
    >
      <DataLayerPushContainer
        data={{
          event: 'pageView',
          actionType: 'page_view',
          actionName: 'page_view',
          virtualPageName: 'input-pw',
        }}
      >
        <div className="lg:w-624 md:w-624 mx-auto my-48">
          <p className="text-14_23 text-center">
            こちらのポートフォリオを閲覧するにはパスワードが必要です。
          </p>
          <FormProvider {...methods}>
            <section className="lg:w-308 md:w-308 lg:space-y-48 md:space-y-48 sm:space-y-24 mx-auto my-30">
              <div>
                <FormLabel label="パスワード" type="small" />
                <FormContainerTextfield
                  className=""
                  name="password"
                  type="password"
                  placeholder="例）Match Tarou"
                />
              </div>
              <div className="flex flex-col">
                <BaseButton theme="primary" size="m-for-sp" onClick={submit}>
                  ポートフォリオを見る
                </BaseButton>
              </div>
            </section>
          </FormProvider>
        </div>
      </DataLayerPushContainer>
    </Page.Wrapper>
  );
}
