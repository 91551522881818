import React from 'react';

import { FormContainerDropdown } from '@/componentsDirect/Parts/Form/Container/Dropdown';
import {
  FormContainerDropdownMultiple
} from '@/componentsDirect/Parts/Form/Container/DropdownMultiple';
import { FormContainerTextfield } from '@/componentsDirect/Parts/Form/Container/Textfield';
import {
    FormContainerTextfieldMultiple
} from '@/componentsDirect/Parts/Form/Container/TextfieldMultiple';
import { FormLabel } from '@/componentsDirect/Parts/Form/Label';
import { FormLayoutFieldset } from '@/componentsDirect/Parts/Form/Layout/Fieldset';
import { UserCondition } from '@/componentsDirect/Parts/UserCondition/Forms';
import { FORM_OPTIONS } from '@/definition/FORM_OPTIONS';

/**
 * 検索条件フォーム 資格と学歴
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65909
 */
export const DirectSearchFormSectionQualifications = (): React.ReactElement => {
  return (
    <UserCondition title="資格と学歴">
      <div className="mbx-utils-stack-v--22">
        <FormLayoutFieldset className="pl-24 w-full">
          <FormLabel label="資格・免許" type="small"></FormLabel>
          <FormContainerTextfieldMultiple
            name="q_title"
            placeholder="例）秘書技能検定（エンターキーで区切って複数入力可能）"
            className="mkt_non_mask_items"
          />
        </FormLayoutFieldset>

        <div className="flex">
          <FormLayoutFieldset className="pl-24">
            <FormLabel label="TOEIC点数" type="small"></FormLabel>
            <div className="flex mbx-utils-stack-h--8 flex-auto">
              <FormContainerTextfield
                name="q_toeic_score"
                placeholder="例）700"
                unit="点以上〜"
                fieldWidthClass="w-136"
                className="mkt_non_mask_items"
              ></FormContainerTextfield>
            </div>
          </FormLayoutFieldset>

          <FormLayoutFieldset className="pl-24">
            <FormLabel label="TOEFL点数" type="small"></FormLabel>
            <div className="flex mbx-utils-stack-h--8 flex-auto">
              <FormContainerTextfield
                name="q_toefl_score"
                placeholder="例）80"
                unit="点以上〜"
                fieldWidthClass="w-136"
                className="mkt_non_mask_items"
              ></FormContainerTextfield>
            </div>
          </FormLayoutFieldset>

          <FormLayoutFieldset className="ml-24 w-176">
            <FormLabel label="英語力" type="small"></FormLabel>
            <FormContainerDropdown
              name="mc_english_skills"
              placeholder="選択してください"
              selectList={FORM_OPTIONS.english_skills}
              className="mkt_non_mask_items"
            ></FormContainerDropdown>
          </FormLayoutFieldset>
        </div>

        <FormLayoutFieldset className="ml-24">
          <FormLabel label="最終学歴" type="small"></FormLabel>
          <FormContainerDropdownMultiple
            name="final_education"
            placeholder="選択してください（複数選択可）"
            selectList={FORM_OPTIONS.final_education}
            className="mkt_non_mask_items"
          ></FormContainerDropdownMultiple>
        </FormLayoutFieldset>

        <FormLayoutFieldset className="ml-24 flex-grow">
          <FormLabel label="学校名" type="small"></FormLabel>
          <FormContainerTextfieldMultiple
            name="e_school_name"
            placeholder="例）マイナビ大学（エンターキーで区切って複数入力可能）"
            className="mkt_non_mask_items"
          />
        </FormLayoutFieldset>
      </div>
    </UserCondition>
  );
};
