import React from 'react';

export const Id2 = (): React.ReactElement => {
  const style = {
    img: 'mt-80 md:-mx-16 sm:-mx-16 sm:mt-40',
    text_l: 'mt-160 text-24_24 font-bold sm:mt-80 sm:text-18_24',
    text_m: 'text-18_28 font-bold mt-40 sm:text-15_22 sm:mt-20',
    text_s: 'text-16_28 sm:text-14_21',
  };
  return (
    <section>
      <div className="text-center pt-90 sm:pt-50">
        <p className="inline-block pb-8 text-18_18 font-bold border-b border-black sm:text-14_14">
          <span>02.</span>
          <span className="ml-18 sm:ml-14">3DCGデザイナー L.H.さん</span>
        </p>
        <h2 className="mt-18 text-29_46 font-bold sm:text-21_31">
          ポートフォリオづくりで大切にした「自分らしさ」は、見る人にも必ず届く
        </h2>
      </div>
      <div className={style.img}>
        <img src="../images/Top/interview/interview02_image1.jpg" alt="" />
      </div>
      <div className="mt-40 sm:mt-20">
        <p className={style.text_s}>
          建築家だった父の仕事の関係で、中学から高校までをカタールで過ごしたL.H.さん。慣れないフランス語での生活の中で、息抜きとして自分を楽しませてくれたのが、ゲームや映画だったという。「自分もいつか、ものづくりで誰かを喜ばせたい」。プレイヤーから作り手への興味がわいたL.H.さんはCGデザイナーを目指し、辿り着いた先はフェイシャルアーティストだった。1社目でさまざまな経験を積んだL.H.さんは、さらなる成長のため転職に挑む。転職活動をする中で作成したデモリールは、第一志望の企業へ転職するための大きな手がかりとなった。
        </p>
      </div>
      <h3 className={style.text_l}>ストーリー性を意識して編集した2分間に「自分らしさ」を表現</h3>
      <p className={style.text_m}>
        ――
        以前は、映像業界のデベロッパーにお勤めだったと伺いました。今回、転職を決意された理由を教えてください。
      </p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          前職では、セリフに合わせてキャラクターの口の動きを合わせるリップシンクや顔の演技全般、映画、アニメ、ゲームのカットシーンなど、映像業界のさまざまな案件をひと通り経験できたこともあり、次は大好きなゲーム業界の仕事によりぐっと踏み込んでみたいと思ったんです。また、周りのメンバーが皆とても優秀だったので、現状に満足し、どこか甘えている自分がいるような気がして、そこから抜け出したいという思いもありました。
          <br />
          <br />
          自分をもっと成長させるために、あえてフェイシャルチームがないところに行って、1人でなにができるか試したい、フェイシャルの分野を引っ張る存在になりたい。そう思って、フェイシャルアーティストを求めている企業への転職を目指しました。
        </p>
      </div>
      <div className={style.img}>
        <img src="../images/Top/interview/interview02_image2.jpg" alt="" />
      </div>
      <p className={style.text_m}>
        ―― フェイシャルアーティストとして転職活動をするにあたり、ポートフォリオは作成されましたか？
      </p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          これまでの実績を動画で見せるために、デモリールを作成しました。アニメーターとして活躍している方のSNSなどを参考にして、長さはだいたい2分程度。見る人の印象に残りやすくなるように、自分が2番目に気に入っている作品を冒頭に、いちばん気に入っている作品を最後に持ってくるよう編集しました。
        </p>
      </div>
      <p className={style.text_m}>―― ほかにもこだわった点があれば教えてください。</p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          2分間のデモリールが「1本の作品」として成立するように意識しました。淡々とただ作品同士を繋げるのではなく、デモリール全体を通してストーリーが感じられるような構成にしたんです。そのため、編集の際には作品の順番をどうするか、かなり考えましたね。たとえば前のカットが「爆弾を投げ込むシーン」なら、次のカットは別の作品から「転がってきた爆弾が爆発するシーン」を選んで繋げるといった具合です。ただ、それに該当するデータを探すのが大変で......。
          <br />
          働きながらということもあり、2分間のデモリールではありますが、完成させるまでには3カ月ほどかかってしまいました。しかし、企業がそのデモリールに興味を持ち、自分に会いたいと思ってくれたと思うと、大変ではありましたが手を抜かずに作成してよかったと思います。
        </p>
      </div>
      <p className={style.text_m}>
        ―― L.H.さんにとって、デモリールやポートフォリオを作るうえで最も大切なことは何でしょうか。
      </p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          やはり、自分らしさを出すことではないでしょうか。私の場合、それはデモリールにストーリー性を持たせ、1つの作品として仕上げることでした。結果的に、デモリールが企業の目に留まり、今回の転職にも繋がったと思います。
        </p>
      </div>
      <h3 className={style.text_l}>いつかフェイシャルアニメーションの第一人者に</h3>
      <p className={style.text_m}>
        ―― 第一志望の企業に転職されて、今はどんな業務をされていますか。
      </p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          フェイシャルアーティストとして、ゲーム内のカットシーンにおけるフェイシャルを一任されています。現在2つのプロジェクトを同時に任されているのですが、制作においてこれまで使ってきたツールも違うし、ゲームエンジンもオリジナルのものを使っているので、まだ慣れない部分もあり大変ですね。
        </p>
      </div>
      <div className={style.img}>
        <img src="../images/Top/interview/interview02_image3.jpg" alt="" />
      </div>
      <p className={style.text_m}>―― 改めて、フェイシャルアニメーションの魅力を教えてください。</p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          キャラクターに感情を与えられるところでしょうか。たとえば「悲しい」という感情にもいろいろ種類があって、仲間を亡くしたときの「悲しい」と新天地に向けて旅立つ仲間と別れなければならないときの「悲しい」は表情がまったく違う。「悲しい」という文字だけでは表現しきれないキャラクターの感情を、表情を通して伝えることで、ストーリーはより深まるはずです。
          <br />
          目線の動き、皴の出方など、ちょっとした表情の変化で感情を伝えるのは、難しくもあり、非常に魅力的だと思いますね。
        </p>
      </div>
      <p className={style.text_m}>―― 今後はどのように活躍の場を広げていきたいとお考えですか。</p>
      <div className="mt-30 sm:mt-20">
        <p className={style.text_s}>
          個人的にはリギングの精度をもっと上げていきたいです。また映像制作に関わる人のフェイシャルアニメーションに対する意識を上げることで、チームとしてよりクオリティの高いものづくりができたらうれしいですね。
          <br />
          <br />
          最近はゲームの中に映画のようなカットシーンを挿入することで、ストーリーを伝えようとする作品も増えてきました。顔の表現は、作品に没入するうえでも重要な要素ですから、今後のゲーム業界において、よりフェイシャルアニメーションの重要性は高まってくるのではないでしょうか。
          <br />
          <br />
          いつかは自分も日本におけるフェイシャルアニメーションを支える第一人者になりたいですね。フェイシャル分野で活躍している方は、前職にも何人かいますが、自分もいつかその人たちと肩を並べるくらいのフェイシャルアーティストになれたらと思います。
        </p>
      </div>
    </section>
  );
};
