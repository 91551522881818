import dayjs from 'dayjs';
import React, { useMemo } from 'react';

import { PortfolioPDFDownloadButton } from '@/componentsAdmin/Portfolio/PDFDownloadButton';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { AdminMatchboxUserResponse, Portfolio, PortfolioItem, Status } from '@/utils/api-client';

// ====================================================================================================
//
// TYPES
//
// ====================================================================================================
type TProps = {
  userInfo?: AdminMatchboxUserResponse;
  portfolio?: Portfolio & Status;
  matchboxId: string;
};

/**
 * 管理者 : ユーザー詳細ページ - ポートフォリオ
 * https://matchbox-aws.s2factory.co.jp/admin/users/C18790999
 */
export const AdminUserDetailPortfolio = ({
  userInfo,
  matchboxId,
  portfolio,
}: TProps): React.ReactElement => {
  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const pfLength = useMemo(() => {
    if (!userInfo?.portfolio_item_count === undefined || !portfolio?.items) {
      return { all: 0, visible: 0 };
    }
    const items = portfolio?.items as unknown as PortfolioItem[];
    return {
      all: userInfo?.portfolio_item_count,
      published: items.length,
    };
  }, [userInfo, portfolio]);

  const pfImages = useMemo(() => {
    const items = portfolio?.items as unknown as PortfolioItem[];
    if (items) {
      return new Array(Math.max(items.length, 5)).fill(null).map((v, index) => {
        const item = items[index];
        if (item) {
          return item.i_image.f_thumbnail;
        } else {
          return '/images/image-placeholder.svg';
        }
      });
    } else {
      return new Array(5).fill(null).map(() => '/images/image-placeholder.svg');
    }
  }, [portfolio]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <tr>
      <th className="mbx-admin-form-title">
        一般向け
        <br />
        ポートフォリオ
      </th>
      <td>
        <div className="flex relative">
          <dl className="">
            <dt>更新日</dt>
            <dd>
              {/* 更新日と一般公開を一度もしていない場合は「未作成」 */}
              {userInfo?.last_update_portfolio && userInfo?.portfolio?.publish
                ? dayjs(userInfo?.last_update_portfolio).format('YYYY/M/D')
                : '未作成'}
            </dd>
            <dt>作品数</dt>
            <dd>
              {pfLength.all}作品（
              {pfLength.published}作品公開）
            </dd>
            {/* <dd>
              {userInfo?.portfolio_item_count_contained_invisible}作品（
              {userInfo?.portfolio_item_count}作品公開）
            </dd> */}
            <dt>公開状態</dt>
            <dd>{userInfo?.status ? userInfo?.status : ''}</dd>
            <dt>URL</dt>
            <dd className="mkt_mask_items">
              https://matchbox.work/portfolio/{userInfo?.user_info.user_id}
            </dd>
          </dl>
          {/* 0作品の場合はリンクを表示しない */}
          {pfLength?.published !== 0 && pfLength.published && (
            <div className="w-224 absolute right-0">
              <BaseButton
                className="w-full"
                theme="secondary"
                size="s"
                onClick={() => {
                  window.open(`/admin/portfolio/${userInfo?.user_info.user_id}`);
                }}
              >
                詳細を見る
              </BaseButton>
              <PortfolioPDFDownloadButton type="detail" matchboxId={matchboxId} />
            </div>
          )}
        </div>
        {pfLength?.published !== 0 && pfLength.published && (
          <div className="grid grid-cols-5 gap-x-12 gap-y-12 my-12">
            {pfImages.map((image: string, index: number) => (
              <div className="w-144 h-81" key={index}>
                <img src={image} alt="" className="h-full object-cover mkt_mask_items" />
              </div>
            ))}
          </div>
        )}
      </td>
    </tr>
  );
};
