import { MESSAGE as BASE } from '@/definition/MESSAGE';
import { TDictionary } from '@/types/Util';

export const MESSAGE: TDictionary<string> = {
  ...BASE,
  add_tag: 'タグを追加しました',
  patch_tag: 'タグを保存しました',
  patch_tags: 'タグを並び替えました',
  deleted_tag: 'タグを削除しました',
  patch_comment: 'コメントを保存しました',
};
