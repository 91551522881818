import '@/styles/common/Direct/MyNaviSelectForm.scss';

import React, { useEffect } from 'react';
import { OnboardingPageWrapper } from './PageWrapper';
import { FormProvider, useForm, UseFormProps } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { PageDots } from './PageDots';
import {
    notificationError, toggleLoading
} from '@/redux/index';
import { MyNaviApi, MyNavi } from '@/utils/api-client';
import { YUP_VALIDATION } from '@/definition/VALIDATION';
import { CAREER_HISTORY_TEMPLATE } from '@/definition/CAREER_HISTORY_TEMPLATE';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormContainerDropdown } from '@/components/common/Form/Container/Dropdown';
import { FormContainerTextArea } from '@/components/common/Form/';
import { FormLabel } from '@/components/common/Form/Label';
import { FormLayoutFieldset } from '@/components/common/Form/Layout/Fieldset';
import { DataLayerPushContainer } from '@/components/common/DataLayerPushContainer';
import BaseButton from '@/components/common/Button/BaseButton';
import { TooltipMessageMatchbou } from '@/components/common/Tooltip/Matchbou';
import { ButtonIconButton } from '@/components/common/Button/IconButton';

type MyNaviItem = {
  mc_description: string
};

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  myNavi?: MyNavi;
};

export const OnboardingPage_4 = (props: Props): React.ReactElement => {
  const dispatch = useDispatch();

  /**
   * validation 設定
   */
  const schema = yup.object().shape({
    mc_description: YUP_VALIDATION.required,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const useFormProps: UseFormProps<any> = {
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      mc_description: props.myNavi?.mc_description || undefined,
    },
  };

  const methods = useForm(useFormProps);

  const { handleSubmit, setValue } = methods;

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (name !== "template") return;
      if (!value[name]) {
        setValue("mc_description", "");
      } else {
        setValue("mc_description", CAREER_HISTORY_TEMPLATE.template[value[name]]);
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  // 送信処理
  const onSubmit = async (data: MyNaviItem) => {
    if (!data) return;

    dispatch(toggleLoading(true));
    const convertedData: any = {};
    (Object.keys(data) as (keyof MyNaviItem)[]).forEach((key) => {
      if(key === "mc_description") convertedData[key] = data[key];
    });

    try {
      await new MyNaviApi().patchMynaviCreator(convertedData);
    } catch (e) {
      dispatch(notificationError(e.response.data.error_message));
    } finally {
      dispatch(toggleLoading(false));
      props.setPage(5);
    }
  };

  return (
    <DataLayerPushContainer
      data={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'modal_open',
        virtualPageName: 'on-boarding_step_4'
      }}
    >
    <div>
      <OnboardingPageWrapper
        description={
          <p className="mt-48">
            <span>
              最後に、あなたの職務経歴の概要を
              <br />
              教えてください。
            </span>
          </p>
        }
      >
        <div>
          <p className="mt-14 text-15_32">
            テンプレートを使って簡単に⼊⼒しましょう。
            <p className="mt-7 text-12_20">※この項⽬はあとから変更できます。</p>
          </p>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
              <div className="mt-26 flex justify-center">
                <FormLayoutFieldset
                  className="w-530 sm:w-308"
                >
                  <FormLabel label="テンプレートを選択してください" type="small">
                    <div className="flex items-center -ml-12">
                      <TooltipMessageMatchbou
                        className=""
                        message="オファー機能ご利用時、「職務経歴概要」は企業に開示されます。そのため自作のポートフォリオサイトなどのURLを入力すると、リンク先のページも企業は閲覧できます。氏名などの個人情報を企業に開示したくない場合は、ご自身で必ずリンク先のページを確認・修正の上、ご入力をお願いします。"
                        position={['0', '-94px']}
                        widthClassName="w-376"
                      >
                        <ButtonIconButton
                          hitArea="mini"
                          iconName="Info"
                          type="gray"
                          focus={false}
                        />
                      </TooltipMessageMatchbou>
                    </div>
                  </FormLabel>
                  <FormContainerDropdown
                    className="select mkt_mask_items w-530 sm:w-308"
                    name="template"
                    placeholder="選択してください"
                    selectList={CAREER_HISTORY_TEMPLATE.template_job}
                  ></FormContainerDropdown>
                  <FormContainerTextArea
                    className="mkt_mask_items mt-26"
                    rows={6}
                    maxLength={300}
                    name="mc_description"
                    placeholder="例）専門学校を卒業し、株式会社マッチワークスに入社。Webデザイナーとして受託案件のWeb制作に4年間従事。ページデザインやHTML、CSS、JavaScript、PHPでのサイト構築を一通り経験した後、UI/UX領域にスキルの幅を広げるため、株式会社マッチラボに転職。現在は、Webデザイナー経験を活かしながら、アプリ開発のUI/UXデザイナーに従事しています。"
                  />
                </FormLayoutFieldset>
              </div>
              <div className="pb-20 mb-48 sm:mb-72">
                <PageDots page={props.page} setPage={props.setPage} unClick className="mt-48" />
                <div className="w-188 m-auto mt-48">
                  <BaseButton
                    theme="primary"
                    size="m"
                    className="w-full"
                  >
                    次へ
                  </BaseButton>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </OnboardingPageWrapper>
    </div>
    </DataLayerPushContainer>
  );
};
