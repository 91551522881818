import React, { useState } from 'react';
import { useFormContext, UseFormReset } from 'react-hook-form';

import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { AdminSearchConditionModel } from '@/utils/api-client';

type TProps = {
  reset: UseFormReset<AdminSearchConditionModel>;
};

/**
 * 検索条件フォーム データをリセット
 * https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=4694%3A65909
 */
export const AdminSearchFormReset = ({ reset }: TProps): React.ReactElement => {
  const methods = useFormContext();
  const [showModal, setShowModal] = useState(false);
  const onReset: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    reset();
    // 複数選択テキストは内部でuseStateを利用してタグの表示制御しているためclear時にリアクティブに表示が切り替わらないので明示的に値をclearしてあげる
    methods.setValue('keyword', []);
    methods.setValue('career_history_companies', []);
    methods.setValue('career_history_section', []);
    methods.setValue('career_history_post', []);
    methods.setValue('qa_answer', []);
    methods.setValue('career_history_tools', []);
    methods.setValue('q_title', []);
    methods.setValue('e_school_name', []);
    setShowModal(false);
  };

  const onOpen: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const onClose: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement> = (e) => {
    e.preventDefault();
    setShowModal(false);
  };

  return (
    <>
      <BaseButton size="s" theme="link" className="mr-32" onClick={onOpen}>
        条件をリセット
      </BaseButton>
      <ModalDefault isOpen={showModal} onClose={onClose} className="w-624 rounded-modalContainer">
        <section className="py-80">
          <h1 className="text-subhead_1 mb-8">設定した条件をすべてリセットしますか？</h1>
          <form className="px-80" onSubmit={onReset}>
            <div className="flex justify-center mt-24">
              <div className="w-146 mr-8">
                <BaseButton size="m" theme="secondary" className="w-full" onClick={onClose}>
                  キャンセル
                </BaseButton>
              </div>
              <div className="w-146 ml-8">
                <BaseButton size="m" theme="primary" className="w-full" type="submit">
                  リセットする
                </BaseButton>
              </div>
            </div>
          </form>
        </section>
      </ModalDefault>
    </>
  );
};
