import '@/styles/common/back_button.scss';

import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  children: string;
  href: string;
};

/**
 * !see https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-and-Design-system?node-id=746%3A3199
 */
function BackButton(props: Props): React.ReactElement {
  return (
    <span className="back-btn">
      <Link to={props.href}>
        <i className="mbx-icon mbx-icon-Arrow_Left text-24_24"></i>
        <span className="link-text">{props.children}</span>
      </Link>
    </span>
  );
}

export default BackButton;
