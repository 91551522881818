/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

import { State } from '@/redux/state';

export function PublicRoute(props: any): React.ReactElement {
  const isAuth: boolean | undefined = useSelector((state: State) => state.isAuth);
  const pathAfterLogin = useSelector((state: State) => state.pathAfterLogin);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const path = useMemo(() => {
    return pathAfterLogin !== undefined
      ? `${pathAfterLogin}${location.search}`
      : `/mypage${location.search}`;
  }, [pathAfterLogin, location.search]);

  return isAuth ? <Redirect to={path} /> : <Route {...props} />;
}
