import React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { State } from '@/redux/state';

type Props = {
  className: string;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  unClick?: boolean;
};

export const PageDots = (props: Props): React.ReactElement => {
  const user = useSelector((state: State) => state.user);
  const dots = user?.v2_agreement ? [1, 2, 3, 4, 5, 6] : [0, 1, 2, 3, 4, 5, 6];
  return (
    <div className={cn('flex', 'justify-center', props.className)}>
      {dots.map((item, n) => {
        return (
          <p
            key={n}
            className={cn(
              'w-8',
              'h-8',
              'rounded-full',
              {'cursor-pointer': !props.unClick},
              item === props.page ? 'bg-red-700' : 'bg-gray-200',
              { 'ml-14': n !== 0 }
            )}
            onClick={() => {
              if (props.unClick) return;
              props.setPage(item);
            }}
          ></p>
        );
      })}
    </div>
  );
};
