import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { NotificationMatchbou } from '@/components/common/Notification/Matchbou';
import { Wrapper } from '@/components/common/Page';
import HeaderTitle from '@/components/common/Title/HeaderTitle';
import { SectionProfile } from '@/components/Mypage/Profile/SectionProfile';
import { State } from '@/redux/state';
import { UserPatchRequest } from '@/utils/api-client/api';
import { basicInfoFromUserInfo, progressFromBasicInfo } from '@/utils/utils';

/**
 * # マイページ基本情報 /mypage/profile
 * UI PC https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=487%3A5764
 * UI SP https://www.figma.com/file/tDn9C162xYWTXkvFMaipAn/Final-Design-%26-Design-system?node-id=735%3A7571
 * API
 */
export const MypageProfile = (): React.ReactElement => {
  // ====================================================================================================
  //
  // Redux
  //
  // ====================================================================================================
  const user = useSelector((state: State) => state.user);

  // ====================================================================================================
  //
  // useState
  //
  // ====================================================================================================
  const [progressDigit, setProgressDigit] = useState(0);
  const [notificationMatchbouShow, setNotificationMatchbouShow] = useState(false);

  // ====================================================================================================
  //
  // CONST
  //
  // ====================================================================================================
  const matchbouStickyClass = cn('sticky top-0 z-20 mt-16 sm:mt-0', {
    'pointer-events-none': !notificationMatchbouShow,
  });

  // ====================================================================================================
  //
  // METHODS
  //
  // ====================================================================================================
  const calcNotificationProgress = () => {
    setProgressDigit(progressFromBasicInfo(basicInfo, user));
  };

  const onHideNotificationMatchbou = () => {
    setNotificationMatchbouShow(false);
  };

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const basicInfo = useMemo<UserPatchRequest>(() => basicInfoFromUserInfo(user), [user]);

  // ====================================================================================================
  //
  // useEffect
  //
  // ====================================================================================================
  useEffect(() => {
    calcNotificationProgress();
  }, [user]);

  useEffect(() => {
    setNotificationMatchbouShow(progressDigit < 100);
  }, [progressDigit]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <Wrapper
      header={
        <>
          <HeaderTitle title={'基本情報管理'}>
            <></>
          </HeaderTitle>
          {user && (
            <div className={matchbouStickyClass}>
              <NotificationMatchbou
                digit={progressDigit}
                show={notificationMatchbouShow}
                onClick={onHideNotificationMatchbou}
              >
                基本情報を入力すると、企業の目に留まりやすくなります。情報を埋めて完成させよう！
              </NotificationMatchbou>
            </div>
          )}
        </>
      }
    >
      <>
        <div className={'w-308 m-auto mb-80 sm:mb-188 sm:w-full'}>
          {user && <SectionProfile></SectionProfile>}
        </div>
      </>
    </Wrapper>
  );
};
