import React, { useMemo, useState } from 'react';

import CirclePlaceholder from '@/assets/images/profile/circle-placeholder.svg';
import { UserModalSection } from '@/componentsAdmin/Company/Detail/UserModal';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { ModalDefault } from '@/componentsDirect/Parts/Modal/Default';
import { CompanyUser } from '@/utils/api-client';

type Props = {
  user: CompanyUser;
};

export const CompanyStaffList = ({ user }: Props): React.ReactElement => {
  // ====================================================================================================
  //
  // const
  //
  // ====================================================================================================
  const [userModal, setUserModal] = useState<boolean>(false);

  // ====================================================================================================
  //
  // useMemo
  //
  // ====================================================================================================
  const accountType = useMemo(() => {
    if (user.user_type === 'direct_manager') return '利用責任者';
    if (user.user_type === 'direct_user') return '一般利用者';
    if (user.user_type === 'direct_hr') return '採用担当者';
  }, [user]);

  // ====================================================================================================
  //
  // JSX
  //
  // ====================================================================================================
  return (
    <section className="flex items-center py-20 px-16 border-b border-gray-300">
      <div className="w-24 h-24">
        <img src={user.photo ? user.photo : CirclePlaceholder} alt="" />
      </div>
      <div className="w-168 ml-8">
        <p className="mbx-typography--subhead_2">{user.name}</p>
        <p className="text-10_16 text-gray-600 truncate">{user.matchbox_id}</p>
        <p className="text-10_16 text-gray-600 truncate">{user.email}</p>
      </div>
      <p className="w-140 ml-16 text-12_18 truncate">{user.section}</p>
      <p className="w-72 ml-16 text-12_18">{accountType}</p>
      <p className="w-234 ml-16 text-12_18">
        <span className="line-clamp-1">
          {user.groups?.map((i, index) => {
            return (
              <span
                key={index}
                className="bg-blue-200 rounded-label text-11_12 py-11 px-8 font-medium whitespace-nowrap inline-block mr-8"
              >
                {i}
              </span>
            );
          })}
        </span>
      </p>
      <div className="w-48 ml-16">
        <BaseButton
          theme="secondary"
          size="ss"
          className="w-full whitespace-nowrap"
          onClick={() => setUserModal(true)}
        >
          詳細
        </BaseButton>
      </div>
      <ModalDefault
        isOpen={userModal}
        onClose={() => setUserModal(false)}
        className="rounded-modalContainer"
      >
        <UserModalSection user={user} setUserModal={setUserModal} />
      </ModalDefault>
    </section>
  );
};
