import React, { useState, useEffect } from 'react';

import '@/styles/common/footer.scss';
import '@/stylesDirect/pages/Top.scss';

import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import ImgLogo from '@/assets/images/common/admin_logo.svg';
import { DataLayerPushContainer } from '@/componentsDirect/common/DataLayerPushContainer';
import { Application } from '@/componentsDirect/common/Modal/Application';
import { GlobalLoading } from '@/componentsDirect/Global/Loading';
import { GlobalNotificationErrorPortal } from '@/componentsDirect/Global/NotificationErrorPortal';

export const Top = (): React.ReactElement => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const onClick = () => {
    setModalIsOpen(true);
  }

  useEffect(() => {
    if (/^#demo-entry$/.test(location.hash)) {
      setModalIsOpen(true);
    }
  }, []);

  return (
    <DataLayerPushContainer
      data={{
        event: 'pageView',
        actionType: 'page_view',
        actionName: 'page_view',
      }}
    >
      <section className="direct">
        <header className="mbx-admin-header grid bg-white border-b border-gray-300 content-center px-16">
          <div className="container mx-auto">
            <div className="flex items-center justify-between">
              <div className="w-100 md:w-160 lg:w-160">
                <Link to="/direct/">
                  <img src={ImgLogo} alt="" />
                </Link>
              </div>
              <ul className="hidden md:flex lg:flex mbx-typography--subhead_1 items-center">
                <li className='mr-25'><Link to="/direct/login">ご利用中の方はこちら</Link></li>
                <li className='mr-25'><a href="https://survey.mynavi.jp/cre/?enq=hsIgNL7%2fius%3d" target="_blank" rel="noreferrer" className='button bt_fill'>話を聞いてみる</a></li>
                <li><p className='button bt_frame cursor-pointer' onClick={onClick}>デモ画面を見てみる</p></li>
              </ul>
            </div>
          </div>
        </header>
        <section className="mainvisual grid content-end md:content-center lg:content-center px-16">
          <div className="container mx-auto">
            <div className='mv-contents'>
              <p className='mb-10 md:mb-30 lg:mb-40'>配信数に制限なし。<br className='md:hidden lg:hidden' /><em>完全成果報酬型</em>スカウトサービス</p>
              <h1 className='my-10 md:my-30 lg:my-40'>数あるポートフォリオから、<br />
                <em>即戦力</em>へ<em>直接オファー</em></h1>
              <p className='my-10 md:my-30 lg:my-40'>面接調整も代行。<em>クリエイティブ人材の採用を徹底的に効率化</em></p>
              <ul className='md:flex lg:flex my-30 md:my-50 lg:my-60'>
                <li className='mb-15 md:mb-0 lg:mb-0 md:mr-30 lg:mr-30'><a href="https://survey.mynavi.jp/cre/?enq=hsIgNL7%2fius%3d" target="_blank" rel="noreferrer" className='button bt_fill'>まずは話を聞いてみる</a></li>
                <li><p className='button bt_frame cursor-pointer' onClick={onClick}>まずはデモ画面を見てみる</p></li>
              </ul>
            </div>
          </div>
        </section>
        <section className="solution px-16 mt-30 md:mt-0 lg:mt-30">
          <div className="container mx-auto">
            <h2 className="mb-30 md:mb-60 lg:mb-60">
              こんなお悩みをお持ちなら、
              <br />
              MATCHBOXにお任せください。
            </h2>
            <ul className="md:grid lg:grid md:gap-x-30 lg:gap-x-30 grid-cols-3">
              <li className="mb-60 md:mb-0 lg:mb-0">
                <dl>
                  <dt className="mb-45 md:mb-60 lg:mb-60">
                    求めるデザインスキル。
                    <br />
                    求人票にうまく書けない
                  </dt>
                  <dd className="mb-15 lg:mb-30">
                    <span>
                      デザインを直接見れるから、
                      <br className="md:hidden lg:hidden xl:block" />
                      わざわざ言葉にする必要なし
                    </span>
                  </dd>
                  <dd>
                    <img
                      className="solution-img01"
                      src="/images/Direct/Top/direct_solution-img01.svg"
                      width=""
                      height=""
                      alt=""
                    />
                  </dd>
                </dl>
              </li>
              <li className="mb-60 md:mb-0 lg:mb-0">
                <dl>
                  <dt className="mb-45 md:mb-60 lg:mb-60">
                    もっと探したい求職者。
                    <br />
                    だけど時間がない
                  </dt>
                  <dd className="mb-15 lg:mb-30">
                    <span>
                      通知を受けたら、すき間時間に
                      <br className="md:hidden lg:hidden xl:block" />
                      ポチッとするだけ
                    </span>
                  </dd>
                  <dd>
                    <img
                      className="solution-img02"
                      src="/images/Direct/Top/direct_solution-img02.svg"
                      width=""
                      height=""
                      alt=""
                    />
                  </dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt className="mb-45 md:mb-60 lg:mb-60">
                    ツール導入？広告？
                    <br />
                    そんな費用出せない
                  </dt>
                  <dd className="mb-15 lg:mb-30">
                    <span>
                      初期費・運用費、無料。
                      <br className="md:hidden " />
                      まずは触ってみる感覚で
                    </span>
                  </dd>
                  <dd>
                    <img
                      className="solution-img03"
                      src="/images/Direct/Top/direct_solution-img03.svg"
                      width=""
                      height=""
                      alt=""
                    />
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
        </section>
        <section className="inqu mt-60 md:mt-120 lg:mt-120 pt-37 md:pt-75 lg:pt-75 px-16 pb-45 md:pb-90 lg:pb-90">
          <div className="container mx-auto">
            <h3 className='mb-30'>お気軽にご相談ください。</h3>
            <ul className='md:flex lg:flex justify-center'>
              <li className='mb-15 md:mb-0 lg:mb-0 md:mx-30 lg:mx-60'><a href="https://survey.mynavi.jp/cre/?enq=hsIgNL7%2fius%3d" target="_blank" rel="noreferrer" className='button bt_fill'>まずは話を聞いてみる</a></li>
              <li className='md:mx-30 lg:mx-60'><p className='button bt_frame cursor-pointer' onClick={onClick}>まずはデモ画面を見てみる</p></li>
            </ul>
          </div>
        </section>
        <section className="about mt-60 md:mt-120 lg:mt-120 px-16">
          <div className="container mx-auto">
            <h2 className="mb-20 md:mb-40 lg:mb-40">MATCHBOXスカウトサービスとは</h2>
            <h3 className="mb-20 md:mb-40 lg:mb-40">
              ポートフォリオでマッチングする
              <br className="md:hidden lg:hidden" />
              採用の新しい形
            </h3>
            <p className="text-center mb-30 md:mb-60 lg:mb-60">
              デザイナーを中心としたクリエイティブ人材が、毎月200名以上、
              <br className="hidden md:block lg:hidden" />
              新規に登録している求職者データベース。
              <br />
              MATCHBOXなら、この求職者に、
              <br className="hidden md:block lg:hidden" />
              求人企業が直接「面接オファー」できます。
            </p>
            <img
              className="block m-auto w-auto"
              src="/images/Direct/Top/direct_about-img.png"
              width=""
              height=""
              alt=""
            />
          </div>
        </section>
        <section className="feature mt-20 md:mt-40 lg:mt-40 px-16">
          <div className="container mx-auto">
            <h2 className="mb-30 md:mb-60 lg:mb-60">MATCHBOXの4つの「できる」</h2>
            <ol>
              <li className="md:grid lg:grid grid-cols-12 items-center my-30 md:my-60 lg:my-60">
                <dl className="col-span-5 mb-30 md:mb-0 lg:mb-0">
                  <dt className="mb-30 md:mb-40 lg:mb-40">
                    ポートフォリオと職務経歴を
                    <br className="hidden xl:block" />
                    実際に見てオファーできる
                  </dt>
                  <dd>
                    MATCHBOXでは、クリエイティブ人材のポートフォリオと職務経歴を実際に見ることができ、マッチする人材がいれば、その場ですぐに直接オファーが可能。そのためまだ本格的に転職活動していない求職者に対して早期にアプローチができます。
                  </dd>
                </dl>
                <div className="col-end-13 col-span-6">
                  <img
                    src="/images/Direct/Top/direct_feature-img01.png"
                    width=""
                    height=""
                    alt=""
                  />
                </div>
              </li>
              <li className="md:grid lg:grid grid-cols-12 items-center my-60">
                <dl className="col-span-5 col-end-13 mb-30 md:mb-0 lg:mb-0">
                  <dt className="mb-30 md:mb-40 lg:mb-40">
                    採用条件を設定しておくだけで、
                    <br className="hidden xl:block" />
                    いつでも求職者情報を受信できる
                  </dt>
                  <dd>
                    MATCHBOXの求職者検索画面で、事前に採用条件を設定しておけば、条件に合う求職者が新規に登録したり、情報を更新したりするタイミングで自動で通知を受けることが可能。そのためアプローチのタイミングを見逃さない安心した状況で、さまざまな採用業務を行うことができます。
                  </dd>
                </dl>
                <div className="col-span-6 order-first">
                  <img
                    src="/images/Direct/Top/direct_feature-img02.png"
                    width=""
                    height=""
                    alt=""
                  />
                </div>
              </li>
              <li className="md:grid lg:grid grid-cols-12 items-center my-60">
                <dl className="col-span-5 mb-30 md:mb-0 lg:mb-0">
                  <dt className="mb-30 md:mb-40 lg:mb-40">
                    MATCHBOXの利用自体は無料。
                    <br className="hidden xl:block" />
                    しかも無制限にオファーできる
                  </dt>
                  <dd>
                    マイナビワークスとの人材紹介契約を締結していれば、MATCHBOXスカウトサービスのご利用は無料。候補者が入社に至った時点で初めて手数料が発生します。しかもオファー数に制限はありません。したがって費用に関する心配をせずに、積極的にMATCHBOXに登録している求職者達にアプローチできます。
                  </dd>
                </dl>
                <div className="col-end-13 col-span-6">
                  <img
                    src="/images/Direct/Top/direct_feature-img03.png"
                    width=""
                    height=""
                    alt=""
                  />
                </div>
              </li>
              <li className="md:grid lg:grid grid-cols-12 items-center my-60">
                <dl className="col-span-5 col-end-13 mb-30 md:mb-0 lg:mb-0">
                  <dt className="mb-30 md:mb-40 lg:mb-40">
                    オファー後の求職者とのやりとりは、
                    <br className="hidden xxl:block" />
                    マイナビワークスに任せることができる
                  </dt>
                  <dd>
                    一般的なスカウトサービスは、オファーしたらした分だけ、その後の候補者管理に手間がかかってしまいがちです。MATCHBOXではオファー後の求職者とのやり取りを全てマイナビワークス担当者に一本化。日程調整の代行も行うため、求人企業は実際の面接や選考など、採用活動のコアな業務に時間を充てることができます。
                  </dd>
                </dl>
                <div className="col-span-6 order-first">
                  <img
                    src="/images/Direct/Top/direct_feature-img04.png"
                    width=""
                    height=""
                    alt=""
                  />
                </div>
              </li>
            </ol>
          </div>
        </section>
        <section className="step mt-30 md:mt-60 lg:mt-60 px-16">
          <div className="container mx-auto">
            <h2 className="mb-20 md:mb-40 lg:mb-40">ご利用の流れ</h2>
            <h3 className="mb-20 md:mb-40 lg:mb-40">
              「Web」と「人」による
              <br className="lg:hidden" />
              両輪のサポートで
              <br className="md:hidden lg:hidden" />
              求人企業の採用活動を
              <br className="hidden md:block" />
              徹底的に効率化
            </h3>
            <ol className="md:grid lg:grid grid-cols-11">
              <li className="col-span-2 mb-60 md:mb-0 lg:mb-0">
                <dl>
                  <dt className="mt-auto mb-10 lg:mb-25">
                    MATCHBOX
                    <br />
                    アカウント開設
                  </dt>
                  <dd className="mb-15 lg:mb-45">
                    <img src="/images/Direct/Top/direct_step01-img.svg" width="" height="" alt="" />
                  </dd>
                  <dd>マイナビワークスと人材紹介契約を締結後、MATCHBOXアカウントを開設します。</dd>
                </dl>
              </li>
              <li className="col-start-4 col-span-2 mb-60 md:mb-0 lg:mb-0">
                <dl>
                  <dt className="mt-auto mb-10 lg:mb-25">
                    候補者へ
                    <br />
                    面接確約
                    <br className="hidden md:block" />
                    オファー
                  </dt>
                  <dd className="mb-15 lg:mb-45">
                    <img src="/images/Direct/Top/direct_step02-img.svg" width="" height="" alt="" />
                  </dd>
                  <dd>
                    MATCHBOXの検索機能でマッチする人材を探し、候補者へ実際にオファーを出しましょう。
                  </dd>
                </dl>
              </li>
              <li className="col-start-7 col-span-2 mb-60 md:mb-0 lg:mb-0">
                <dl>
                  <dt className="mt-auto mb-10 lg:mb-25">面接日程調整</dt>
                  <dd className="mb-15 lg:mb-45">
                    <img src="/images/Direct/Top/direct_step03-img.svg" width="" height="" alt="" />
                  </dd>
                  <dd>候補者がオファーに応諾したら、マイナビワークスが面接日程を調整します。</dd>
                </dl>
              </li>
              <li className="col-span-2 col-end-12">
                <dl>
                  <dt className="mt-auto mb-10 lg:mb-25">
                    選考・内定
                    <br className="hidden md:block" />
                    ・入社
                  </dt>
                  <dd className="mb-15 lg:mb-45">
                    <img src="/images/Direct/Top/direct_step04-img.svg" width="" height="" alt="" />
                  </dd>
                  <dd>
                    最終的に候補者の入社意思が確認できるまでマイナビワークスが間に立ってサポートします。
                  </dd>
                </dl>
              </li>
            </ol>
          </div>
        </section>
        <section className="inqu mt-60 md:mt-120 lg:mt-120 pt-37 md:pt-75 lg:pt-75 px-16 pb-45 md:pb-90 lg:pb-90">
          <div className="container mx-auto">
            <h3 className='mb-30'>お気軽にご相談ください。</h3>
            <ul className='md:flex lg:flex justify-center'>
              <li className='mb-15 md:mb-0 lg:mb-0 md:mx-30 lg:mx-60'><a href="https://survey.mynavi.jp/cre/?enq=hsIgNL7%2fius%3d" target="_blank" rel="noreferrer" className='button bt_fill'>まずは話を聞いてみる</a></li>
              <li className='md:mx-30 lg:mx-60'><p className='button bt_frame cursor-pointer' onClick={onClick}>まずはデモ画面を見てみる</p></li>
            </ul>
          </div>
        </section>
        <footer className="footer px-16">
          <div className="container mx-auto">
            <div className="footer-inner">
              <p className="copyright">
                Copyright &copy; Mynavi Works Corporation All Rights Reserved.
              </p>

              <ul>
                <li>
                  <a href="https://matchbox.work/" target="_blank" rel="noreferrer">
                    MATCHBOXトップ
                  </a>
                </li>
                <li className="mr-25 md:hidden lg:hidden">
                  <Link to="/direct/login">ご利用中の方はこちら</Link>
                </li>
                <li>
                  <a href="https://mynavi-works.jp/" target="_blank" rel="noreferrer">
                    運営会社
                  </a>
                </li>
                <li>
                  <a
                    href="https://survey.mynavi.jp/cre/?enq=fJlHCQO1mWY%3d"
                    target="_blank"
                    rel="noreferrer"
                  >
                    お問い合わせ
                  </a>
                </li>
                <li>
                  <Link to="/direct/terms_of_use">利用規約</Link>
                </li>
                <li>
                  <HashLink to="/direct/terms_of_use#privacy">個人情報の取り扱い</HashLink>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </section>
      <Application
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      />
      <GlobalLoading />
      <div className="sticky bottom-0 z-400 mx-auto">
        <GlobalNotificationErrorPortal />
      </div>
    </DataLayerPushContainer>
  );
};
