import React, { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';

import { AdminPage } from '@/componentsAdmin/Layout/Page';
import { BaseButton } from '@/componentsDirect/Parts/Button/BaseButton';
import { HeaderTitle } from '@/componentsDirect/Parts/Title/HeaderTitle';
import {
    DirectSearchFormBasicInfo, DirectSearchFormSectionDesired,
    DirectSearchFormSectionEmploymentStatus, DirectSearchFormSectionFreeWord,
    DirectSearchFormSectionOffer, DirectSearchFormSectionQualifications,
    DirectSearchFormSectionTitle, DirectSearchFormSectionUpdates,
    DirectSearchFormSectionWorkExperience
} from '@/componentsDirect/Search';
import { useDirectSearchCondition } from '@/hooksAdmin/useDirectSearchCondition';
import {
    useDirectSearchConditionValidation, validationSchemaForEdit
} from '@/hooksAdmin/useDirectSearchConditionValidation';
import { DirectSearchConditionRequest, DirectSearchConditionResponse } from '@/utils/api-client';
import { yupResolver } from '@hookform/resolvers/yup';

/**
 * Admin 企業向け検索条件の編集
 */
export const AdminDirectConditionsEdit = (): React.ReactElement => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const { getSearchCondition, searchConditionResult, patchSearchCondition } =
    useDirectSearchCondition();
  const { initValues, isChanged, searchConditionChangeDetection } =
    useDirectSearchConditionValidation();

  const methods = useForm<DirectSearchConditionResponse>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchemaForEdit),
    defaultValues: {
      search_condition_name: '',
      ...initValues,
    } as DirectSearchConditionResponse,
  });
  const { handleSubmit, watch, control } = methods;
  const { isValid } = useFormState({ control });

  /**
   * 詳細データをフォームに反映
   * ただし mc_experienced_job は useFieldArray で反映するので
   * コンポーネント側で対応
   */
  useEffect(() => {
    getSearchCondition(Number(params.id));
  }, []);

  useEffect(() => {
    if (searchConditionResult) setDefaultValues(searchConditionResult);
  }, [searchConditionResult]);

  /**
   * 保存
   */
  const onSubmit: SubmitHandler<DirectSearchConditionRequest> = (data) => {
    (async () => {
      await patchSearchCondition(Number(params.id), data);
      history.push('/admin/direct/conditions');
    })();
  };

  /**
   * データの変更検知
   */
  useEffect(() => {
    const subscription = watch((value) => {
      searchConditionChangeDetection(value);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  /**
   * データをRHFに値をsetValueする
   */
  const setDefaultValues = (res: DirectSearchConditionResponse) => {
    (Object.keys(res) as (keyof DirectSearchConditionResponse)[]).forEach((v) => {
      if (res?.[v] !== undefined && res?.[v] !== null) {
        if (v !== 'mc_experienced_job') {
          methods.setValue(v, res?.[v], { shouldValidate: true });
        }
      }
    });
  };

  return (
    <AdminPage
      header={<HeaderTitle title="保存した条件の編集" subTitle="企業への共有向け" />}
      sticky={<StickyButton isValid={isChanged && isValid} />}
    >
      <FormProvider {...methods}>
        <section className="pt-30">
          <form className="pb-80" onSubmit={handleSubmit(onSubmit)} id="FORM">
            <DirectSearchFormSectionTitle />
            <div className="border-t border-gray-300">
              <DirectSearchFormSectionFreeWord />
              <DirectSearchFormSectionOffer />
              <DirectSearchFormBasicInfo searchConditionResult={searchConditionResult} />
              <DirectSearchFormSectionEmploymentStatus />
              <DirectSearchFormSectionUpdates />
              <DirectSearchFormSectionWorkExperience />
              <DirectSearchFormSectionQualifications />
              <DirectSearchFormSectionDesired />
            </div>
          </form>
        </section>
      </FormProvider>
    </AdminPage>
  );
};

type TPropsButton = {
  isValid: boolean;
};

const StickyButton = ({ isValid }: TPropsButton): React.ReactElement => {
  return (
    <div className="sticky bottom-10 z-30">
      <div className="absolute right-20 bottom-12 flex">
        <div className="w-155 mr-16">
          <BaseButton
            size="m"
            theme="secondary"
            type="submit"
            className="w-full"
            href="/admin/direct/conditions"
          >
            キャンセル
          </BaseButton>
        </div>
        <BaseButton size="m" theme="primary" type="submit" form="FORM" disabled={!isValid}>
          この内容で保存する
        </BaseButton>
      </div>
    </div>
  );
};
